import { httpService } from '@logz-pkg/frontend-services';
import { GlobalSearchResultModel, SearchSource, GrafanaAlertResultModel } from '@logz-pkg/models';

class MetricsAlertsApiService {
  async getGrafanaAlerts(query: string): Promise<GlobalSearchResultModel[]> {
    const { data } = await httpService.get(`/grafana-app/api/alerts`);

    const results: GlobalSearchResultModel[] = data
      .filter((result: GrafanaAlertResultModel) => result.name.toLowerCase().includes(query.toLowerCase()))
      .map((result: GrafanaAlertResultModel) => ({
        source: SearchSource.GrafanaAlert,
        title: result.name,
        link: result.url,
      }));

    return results;
  }
}

export const metricsAlertsApiService = new MetricsAlertsApiService();
