import { UIView } from '@uirouter/react';
import { AppStateDeclaration } from 'states/AppStateDecleration';

export const triggersRoutes: AppStateDeclaration[] = [
  {
    name: 'dashboard.triggers',
    redirectTo: 'dashboard.triggers.alert-definitions',
    url: '/triggers',
    data: {
      breadcrumb: { skip: true },
    },
    component: UIView,
  },
];
