import { FunctionComponent } from 'react';
import { Group, Img, Text, Title } from '@logz-ui/styleguide';
import { IDataSentModalDetails } from './Details';

export const DataSentModalContent: FunctionComponent<IDataSentModalDetails> = ({
  title,
  subTitle,
  image,
  ExploreSection,
}) => {
  return (
    <Group vertical alignItems={'center'} width={'100%'} gap={4}>
      <Title size={24}>🎉</Title>
      <Group vertical gap={0} alignItems={'center'}>
        <Title size={24} pb={2}>
          {title}
        </Title>
        {subTitle && (
          <Title size={24} pb={2}>
            {subTitle}
          </Title>
        )}
      </Group>
      <Img width={390} src={image} />
      <Text>You can now monitor and analyze your data!</Text>
      <ExploreSection />
    </Group>
  );
};
