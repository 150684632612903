import React from 'react';
import { AICopilotSubjects } from '@logz-pkg/enums';
import { Drawer } from '@logz-ui/styleguide';
import styled, { css } from 'styled-components';
import { useAiState } from '../../state/ai-state.hook';
import { AiChatError } from '../Error';
import { AiDrawerContent } from './Content';
import { ErrorBoundary } from 'ui/components/shared/ErrorBoundary/ErrorBoundary';

const DRAWER_WIDTH_DEFAULT_SIZE = '654px';

const DrawerWithBorders = styled(Drawer)`
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  height: calc(100dvh - var(--top-offset, 104px));
  @supports (anchor-name: --ai-drawer-wrapper) {
    height: unset;
    top: anchor(--drawer-wrapper top);
    bottom: 0;
  }
  ${({ open }) =>
    open &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.royalBlue[200]};
    `}
`;

export const AiChatDrawer: React.FC<{ displayClearChat?: boolean }> = ({ displayClearChat = true }) => {
  const { isDrawerOpen } = useAiState();

  return (
    <DrawerWithBorders
      zIndex={9999}
      hideBackdrop
      direction={'right'}
      subject={AICopilotSubjects.Drawer}
      name={AICopilotSubjects.Drawer}
      open={isDrawerOpen}
      width={DRAWER_WIDTH_DEFAULT_SIZE}
    >
      <ErrorBoundary errorContext={'AI/Drawer'} fallbackAsFunc={({ error }) => <AiChatError error={error} />}>
        <AiDrawerContent hideClearChat={!displayClearChat} />
      </ErrorBoundary>
    </DrawerWithBorders>
  );
};
