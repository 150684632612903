import { Group, Text, Icon, Flex } from '@logz-ui/styleguide';
import React from 'react';
import { AiChatGeneralActionButtons } from '../General/ActionsButtons';
import { AiChatGeneralMarkdown } from 'ui/components/AI/Components/Messages/General/Markdown';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';
import { AiChatGeneralResultsInfo } from 'ui/components/AI/Components/Messages/General/ResultsInfo';

export const AiChatAssistantResponse: React.FC<MessageType> = ({
  content,
  outOfContext = false,
  isDoneContent = false,
  isLast,
}) => (
  <Group px={8} gap={4} vertical>
    <Group gap={1} alignItems={'center'}>
      <Flex width={'20px'} height={'20px'} justifyContent={'center'} alignItems={'center'}>
        <Icon icon={'sparkle-solid'} color={'royalBlue.1000'} size={16} />
      </Flex>
      <Text color={'royalBlue.1000'} size={14} weight={600}>
        Answer
      </Text>
    </Group>

    <Group pl={6} width={'100%'} flexDirection={'column'}>
      <AiChatGeneralMarkdown content={content} outOfContext={outOfContext} />
      {isDoneContent && isLast && <AiChatGeneralResultsInfo />}
      {isDoneContent && <AiChatGeneralActionButtons content={content} isLast={isLast} />}
    </Group>
  </Group>
);
