import { createGlobalStyle } from 'styled-components';

export const GlobalStyling = createGlobalStyle`
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
  }
  
  #root {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
