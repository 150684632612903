import { LogzioTheme } from '@logz-pkg/enums';
import { appStateService } from '@logz-pkg/frontend-services';

const themeSchemaName = 'theme';

const getState = async () => appStateService.get(themeSchemaName);

const getTheme = async (): Promise<LogzioTheme> => {
  const { theme } = await getState();

  return theme;
};

const setState = async state => {
  const currentState = await getState();
  const newState = { ...currentState, ...state };

  return appStateService.set(themeSchemaName, newState);
};
const setTheme = async (theme: LogzioTheme) => setState({ theme });

export const themeAppStateService = {
  get: getTheme,
  set: setTheme,
};
