import { useState } from 'react';
import { useDebounce } from 'react-debounce-hook';
import { useSelectState } from '../../../SmartSelect.provider';
import { useValidation } from './validation-hook';

export type UseValidate = {
  isInputValid: boolean;
  isFilterValid: boolean;
  isLoading: boolean;
};

export const useSmartValidate = (inputValue: string): UseValidate => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);
  const { isFilterValidO } = useSelectState();

  useDebounce(inputValue, () => setDebouncedValue(inputValue), 350);

  const { isValid: isInputValid, isFetching: isFetchingInput } = useValidation(debouncedValue);

  const shouldRetryWithQuotes = !isInputValid && !isFetchingInput;

  const { isValid: isFilterValid, isFetching: isFetchingFilter } = useValidation(
    shouldRetryWithQuotes ? `"${debouncedValue}"` : debouncedValue,
  );

  const isDebouncing = debouncedValue !== inputValue;
  const isFetching = isFetchingInput || isFetchingFilter || isDebouncing;

  isFilterValidO.set(isFilterValid);

  return { isLoading: isFetching, isInputValid, isFilterValid };
};
