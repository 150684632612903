import { appStateService } from '@logz-pkg/frontend-services';

const JAEGER_APP_STATE_SCHEMA_NAME = 'jaeger';

export interface IJaegerAppState {
  selectedTracingAccountId: number;
}

const getState = (): Promise<IJaegerAppState> => appStateService.get(JAEGER_APP_STATE_SCHEMA_NAME);

const setState = async (state: Partial<IJaegerAppState>) => {
  const currentState = await getState();
  const newState = { ...currentState, ...state };

  return appStateService.set(JAEGER_APP_STATE_SCHEMA_NAME, newState);
};

const setLastSelectedAccountId = (selectedTracingAccountId: number): Promise<IJaegerAppState> =>
  setState({ selectedTracingAccountId });

export const jaegerAppStateService = {
  getState,
  setState,
  setLastSelectedAccountId,
};
