import React, { useRef } from 'react';
import { SelectStateService } from './state/select-state.service';
import { SmartSelectOptions } from './types';

const StateContext = React.createContext<SelectStateService>(undefined);
const OptionsContext = React.createContext<SmartSelectOptions>(undefined);

export const useSelectState = () => React.useContext(StateContext);
export const useSelectOptions = () => React.useContext(OptionsContext);

export const SmartSelectProvider: React.FC<React.PropsWithChildren<{ options: SmartSelectOptions }>> = ({
  options,
  children,
}) => {
  const ref = useRef<SelectStateService>(new SelectStateService());

  return (
    <StateContext.Provider value={ref.current}>
      <OptionsContext.Provider value={options}>{children}</OptionsContext.Provider>
    </StateContext.Provider>
  );
};
