/* eslint-disable max-lines */
import _ from 'lodash';
import '../../app/jquery';

import {
  httpService,
  searchableIndexesService,
  searchedIndexesService,
  authService,
  LoggerService,
} from '@logz-pkg/frontend-services';
import { jsonUtils } from '@logz-pkg/utils';
import {
  confirmAggregationDialogId,
  triggerSavedDialogId,
} from '../../app/dashboard/triggers/optimizer-wizard/alerts.templates.config';
import { upsertNotificationEndpointId } from '../../directives/directives.templates.config';
import UpsertNotificationEndpointController from '../../directives/upsert-notification-endpoint/upsert-notification-endpoint.controller';
import { kibanaRouteService } from '../../app/osd/osd-route.service';
import { getAngularService } from '../../app/angular/injector-provider';
import { timelessAccountsService } from '../../app/dashboard/settings/manage-accounts/timeless-accounts-card/timeless-accounts-service';
import triggerConfigService from './trigger-config.service';
import { triggerSearchableAccountsService } from './trigger-searchable-accounts.service';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { appRouter } from 'services/router/router.service';
import { upgradeService } from 'services/upgrade-service/upgrade-service';
import { appModeService } from 'services/identity/app-mode/app-mode.service';

const { toAngularJson } = jsonUtils;
const _key = '__alertWizardData'; // local storage key

const DIALOG_WIDTH = '624px';
const TriggerConfigService = triggerConfigService();

function setNullValuesOnNotRelevantValuesForSpesificTriggerType(triggerType, copyTriggerData) {
  if (triggerType === TriggerConfigService.ALERT_NAME || triggerType === TriggerConfigService.SECURITY_NAME) {
    copyTriggerData.output.target.timelessIndex = null;
  } else {
    copyTriggerData.search.periodInMinutes = null;
    copyTriggerData.triggerOn.aggregation = null;
    copyTriggerData.triggerOn.comparisonOperator = null;
    copyTriggerData.triggerOn.threshold = null;
    copyTriggerData.output.target.severity = null;
  }
}

function handleTriggerAggregation(copyTriggerData, triggerType) {
  const isGroupBy = _.size(copyTriggerData.search.groupBy);
  const numOfMetricsAggregationFields = _.size(copyTriggerData.search.metricsAggregations);

  if (
    triggerType === TriggerConfigService.CONTINUOUS_AGGREGATION_NAME &&
    isGroupBy &&
    numOfMetricsAggregationFields === 0
  ) {
    copyTriggerData.search.metricsAggregations = [
      {
        aggregationType: TriggerConfigService.AGGREGATION_VALUES.COUNT_VALUE,
        fieldToAggregateOn: null,
      },
    ];
  }

  const {
    triggerOn: { aggregation },
  } = copyTriggerData;

  if (isGroupBy && aggregation.aggregationType === TriggerConfigService.AGGREGATION_VALUES.NONE_VALUE) {
    aggregation.aggregationType = TriggerConfigService.AGGREGATION_VALUES.COUNT_VALUE;
  }
}

function handleTriggerSeverities(copyTriggerData) {
  copyTriggerData.output.target.severity = null; // backwards compatibility

  copyTriggerData.triggerOn.severities = _.chain(copyTriggerData.triggerOn.severities)
    .filter(item => item.isShown)
    .map(item => _.pick(item, ['severity', 'threshold']))
    .value();
}

function mergeCustomFilters(searchData) {
  const filters = JSON.parse(searchData.filter);
  const logzioFilterJson = JSON.parse(searchData.logzioExtension || null);

  // If contains custom filters, merge them.
  if (logzioFilterJson) {
    filters.bool = _.mergeWith(filters.bool, logzioFilterJson.filters, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    });
  }

  return filters;
}

function adjustTriggerDataObjectForServer(triggerData, triggerType) {
  // adjusting data because of legacy code in server :/
  const copyTriggerData = _.cloneDeep(triggerData);

  copyTriggerData.search.filter = toAngularJson(triggerData.search.filter);

  if (triggerType === TriggerConfigService.SECURITY_NAME) {
    copyTriggerData.search.logzioExtension = toAngularJson(triggerData.search.logzioExtension);
  }

  copyTriggerData.output.format.fieldsConfig = toAngularJson(triggerData.output.format.fieldsConfig);
  copyTriggerData.output.target.emailNotifications = toAngularJson(triggerData.output.target.emailNotifications);

  const aggMode = isAggMode(triggerData);

  if (triggerData.output.format.type === 'JSON' || !_.size(triggerData.output.format.fieldsConfig) || aggMode) {
    copyTriggerData.output.format.fieldsConfig = null;
  }

  handleTriggerAggregation(copyTriggerData, triggerType);
  handleTriggerSeverities(copyTriggerData);
  setNullValuesOnNotRelevantValuesForSpesificTriggerType(triggerType, copyTriggerData);

  return copyTriggerData;
}

const getData = () => {
  return JSON.parse(localStorage.getItem(_key));
};

const clearData = () => {
  localStorage.removeItem(_key);
};

const getTriggerDataById = (triggerId, triggerType = '') => {
  return httpService.get(`/triggers/${triggerType}${triggerId}`);
};

const isMultipleConditions = triggerData => _.size(triggerData.severityThresholdTiers) !== 1;

const isSeverityTypeColorShown = (severityLevel, triggerData) =>
  !isMultipleConditions(triggerData) && triggerData.severityThresholdTiers[0].severity === _.toUpper(severityLevel);

const isAlertType = triggerType => triggerType === TriggerConfigService.ALERT_NAME;

const getTimelessIndicesAndTypes = () =>
  timelessAccountsService.getAllSearchableTimelessIndices().then(data => {
    const timelessIndices = _.map(data, timeless => ({
      text: timeless.name,
      value: timeless.accountId,
      type: timeless.name,
    }));
    const timelessIndicesTypesMap = _.zipObject(_.map(timelessIndices, 'value'), _.map(timelessIndices, 'type'));

    return {
      timelessIndices,
      timelessIndicesTypesMap,
    };
  });

const storeData = data => {
  localStorage.setItem(_key, JSON.stringify(data));
};

const storeTriggerWizardData = (data, triggerType) => {
  // In case the user will cancel operation he will be redirected to the previous state
  data.senderState = appRouter.stateService.current.name;
  data.triggerType = triggerType || 'ALERT';
  storeData(data);
};

const parseMinutesToTimeUnitAndAmount = (minutes, unit, amount) => {
  if (Math.floor(minutes / 1440)) {
    unit.value = 'day';
    amount.value = minutes / 1440;
  } else if (Math.floor(minutes / 60)) {
    unit.value = 'hour';
    amount.value = minutes / 60;
  } else {
    unit.value = 'minute';
    amount.value = minutes;
  }
};

const addNewEndpoint = async (endpoints, selectedRecipients) => {
  const [$rootScope, ngDialog] = await Promise.all([getAngularService('$rootScope'), getAngularService('ngDialog')]);
  const dialogScope = $rootScope.$new();

  dialogScope.notificationEndpoints = [];

  const dialogOptions = {
    template: upsertNotificationEndpointId,
    controller: UpsertNotificationEndpointController,
    width: '765px',
    scope: dialogScope,
    showClose: false,
  };

  return ngDialog.openConfirm(dialogOptions).then(notificationEndpoints => {
    if (!notificationEndpoints.length) return;

    const newEndpoint = {
      data: dialogScope.notificationEndpoints[0],
      name: dialogScope.notificationEndpoints[0].title,
    };

    endpoints.push(newEndpoint);
    selectedRecipients.push(newEndpoint);
  });
};

const setNotificationEndpointsAndEmails = recipientsData => {
  let [endpoints] = _.partition(recipientsData, recipient => !!recipient.data);
  let [, emails] = _.partition(recipientsData, recipient => !!recipient.data);

  emails = _.map(emails, email => ({
    address: email.name,
    type: 'EMAIL',
  }));
  endpoints = _.map(endpoints, 'data');

  return [endpoints, emails];
};

const getGroupByFieldsList = aggGroupByFields => {
  const fieldList = [];

  _.forEach(aggGroupByFields, value => {
    if (value.value) fieldList.push(value.value);
  });

  return fieldList;
};

const openTriggerSavedDialog = async (triggerData, triggerTypeName) => {
  const ngDialog = await getAngularService('ngDialog');

  ngDialog.open({
    template: triggerSavedDialogId,
    width: DIALOG_WIDTH,
    showClose: false,
    // we use data and not scope because this dialog is shown after a state change,
    // and the parent scope is destroyed
    data: {
      triggerTypeName,
      actionSuccessName: _.get(triggerData, 'id') ? 'updated' : 'created',
      triggerName: triggerData.name,
    },
  });
};

const initializeAlertWizardData = async (query, filter, customFilter, accountIds = []) => {
  const [appMode, planDetails, searchableIndexes, searchedAccountsIds] = await Promise.all([
    appModeService.getCurrentMode(),
    upgradeService.getPlanDetails(),
    searchableIndexesService.getCachedSearchableIndexes(),
    searchedIndexesService.getSearchedAccountIds(),
  ]);

  let selectedAccountIds = _.isArray(accountIds) ? accountIds : [accountIds];

  if (_.size(selectedAccountIds) === 0 && _.size(searchedAccountsIds) > 0) {
    selectedAccountIds = searchedAccountsIds;
  }

  const accountsToBeAlerted = _.map(selectedAccountIds, accountId => _.find(searchableIndexes, { accountId })).map(
    account => {
      account.data = account.accountId;

      return account;
    },
  );
  const sourceAccounts = await triggerSearchableAccountsService.serializeChosenAccountsToApiStructure(
    accountsToBeAlerted,
    searchableIndexes,
  );
  const maxAlerts = planDetails.data.maxAlertsDefinition;

  if (maxAlerts === 0) {
    return upgradeService.showAlertsNotAvailableForPlanDialog();
  }

  let logzioExtension = null;

  if (appMode === AppModes.SECURITY) {
    logzioExtension = { filters: customFilter };
  }

  return {
    enabled: true,
    search: {
      queryString: query,
      filter,
      logzioExtension,
      source: sourceAccounts,
    },
  };
};
const storeDataAndOpenAlertWizard = function storeDataAndOpenAlertWizard(data) {
  // In case the user will cancel operation he will be redirected to the previous state
  storeTriggerWizardData(data, TriggerConfigService.ALERT_NAME);
  appRouter.stateService.go('dashboard.triggers.alert-wizard');
};

const storeDataAndOpenOptimizerWizard = function storeDataAndOpenOptimizerWizard(data) {
  // In case the user will cancel operation he will be redirected to the previous state
  storeTriggerWizardData(data, TriggerConfigService.CONTINUOUS_AGGREGATION_NAME);
  appRouter.stateService.go('dashboard.triggers.optimizer-wizard');
};

// /**
//  * Receives the building blocks of creating an alert.
//  * if accountIds are supplied by the user, it will use them,
//  * otherwise, it tries to grab it from the appState. If that will resolve with an empty account list,
//  * it will assume the user meant to search on all accounts.
//  */
const openCreateAlertWizard = (query, filter, customFilter, accountIds = [], triggerType) =>
  initializeAlertWizardData(query, filter, customFilter, accountIds).then(alertInitialData => {
    storeDataAndOpenAlertWizard(alertInitialData, triggerType);
  });

const openCreateOptimizerWizard = (
  query,
  filter,
  customFilter,
  accountIds = [],
  triggerType,
  isNewOptimizersPageFFEnabled,
) => {
  if (isNewOptimizersPageFFEnabled) {
    appRouter.stateService.go('dashboard.triggers.optimizers-new', { query, accountIds });
  } else {
    initializeAlertWizardData(query, filter, customFilter, accountIds).then(alertInitialData => {
      storeDataAndOpenOptimizerWizard(alertInitialData, triggerType);
    });
  }
};

const openCreateSecurityRule = (query, filter, customFilter, accountIds = []) => {
  appRouter.stateService.go('dashboard.security.rules.definitions.wizard', {
    triggerDataCreate: initializeAlertWizardData(query, filter, customFilter, accountIds),
  });
};

const openConfirmAggregationDialog = async function openConfirmAggregationDialog(shouldDisplayDialog) {
  if (!shouldDisplayDialog) {
    return Promise.resolve();
  }

  const ngDialog = await getAngularService('ngDialog');

  return ngDialog.openConfirm({
    template: confirmAggregationDialogId,
    width: DIALOG_WIDTH,
    showClose: false,
  });
};

const isAggMode = function isAggMode(triggerData, triggerType = TriggerConfigService.ALERT_NAME) {
  // optimizers do not support non aggregation mode in the backend yet.
  // this is a patch to force optimizers always be in aggMode even if its not
  if (triggerType === TriggerConfigService.CONTINUOUS_AGGREGATION_NAME) return true;

  return _.size(triggerData.search.groupBy) > 0 || !!triggerData.triggerOn.aggregation.fieldToAggregateOn;
};

const getNumOfAggregatedFields = triggerData => {
  const numOfGroupByFields = _.size(triggerData.search.groupBy);
  let numOfTriggerOnFields = 1;

  if (triggerData.triggerOn.aggregation.aggregationType === TriggerConfigService.AGGREGATION_VALUES.NULL_VALUE) {
    numOfTriggerOnFields = 0;
  }

  const numOfMetricsAggregationFields = _.size(triggerData.search.metricsAggregations);

  const NumOfAggregatedFields = numOfGroupByFields + numOfTriggerOnFields + numOfMetricsAggregationFields;

  return NumOfAggregatedFields;
};

const getNumOfNonAggregatedFields = function getNumOfNonAggregatedFields(triggerData) {
  return _.size(triggerData.output.format.fieldsConfig);
};

const parseTriggerData = function parseTriggerData(triggerData) {
  const {
    output: {
      target: { emailNotifications },
    },
  } = triggerData;

  /*
                Here we try to solve 2 issues regarding email notifications value.
                Because this value holds JSON as a string, we might (and have) encounter an invalid JSON.
                The best we can do, is try to parse it, and if it's invalid, resolve to a initial value.
                If we can't seem to parse it correctly, and when resolving to the initial value we lose data - Good luck.
                https://logzio.atlassian.net/browse/DEV-9529
                https://logzio.atlassian.net/browse/DEV-11598
            */
  try {
    triggerData.output.target.emailNotifications = JSON.parse(emailNotifications);
  } catch (error) {
    LoggerService.logError({
      message: `There was an error parsing the JSON value: ${emailNotifications}`,
      error,
    });
    triggerData.output.target.emailNotifications = { notifications: [] };
  }

  if (_.isString(triggerData.search.filter)) {
    triggerData.search.filter = JSON.parse(triggerData.search.filter);
  }

  if (_.isString(triggerData.search.logzioExtension)) {
    triggerData.search.logzioExtension = JSON.parse(triggerData.search.logzioExtension);
  }

  if (!triggerData.output.format) {
    triggerData.output.format = { type: TriggerConfigService.JSON_FORMAT_NAME };
  }

  return triggerData;
};

const getTableSamples = function getTableSamples(triggerData, triggerType) {
  let samplesUrl = '/triggers/alerts/samples';

  if (triggerData.name === '') {
    triggerData = _.omit(triggerData, 'name');
  }

  if (triggerType === TriggerConfigService.CONTINUOUS_AGGREGATION_NAME) {
    samplesUrl = '/triggers/samples';
  }

  return httpService.post(samplesUrl, adjustTriggerDataObjectForServer(triggerData, triggerType));
};

const submitTrigger = function submitTrigger(triggerData, triggerType) {
  let triggerPostType = '/triggers';

  if (triggerType === TriggerConfigService.ALERT_NAME) {
    triggerPostType = '/triggers/alerts';
  } else if (triggerType === TriggerConfigService.SECURITY_NAME) {
    triggerPostType = '/security/rules';
  }

  if (_.get(triggerData, 'id')) {
    // Protected Rule Edit
    if (_.get(triggerData, 'protected')) {
      triggerPostType += `/protected/${_.get(triggerData, 'id')}`;

      const adjustedObject = adjustTriggerDataObjectForServer(triggerData, triggerType);

      const protectedTriggerData = {
        ruleId: adjustedObject.id,
        enabled: adjustedObject.enabled,

        description: adjustedObject.description,
        tags: adjustedObject.tags,

        source: adjustedObject.search.source,

        periodInMinutes: adjustedObject.search.periodInMinutes,
        triggerOnCondition: adjustedObject.triggerOn,

        output: adjustedObject.output,
      };

      return httpService.put(triggerPostType, protectedTriggerData).then(() => {
        storeData(triggerData);
      });
    }

    // Regular Rule Edit
    triggerPostType += `/${_.get(triggerData, 'id')}`;

    return httpService.put(triggerPostType, adjustTriggerDataObjectForServer(triggerData, triggerType)).then(() => {
      storeData(triggerData);
    });
  }

  return httpService.post(triggerPostType, adjustTriggerDataObjectForServer(triggerData, triggerType)).then(res => {
    if (_.get(res.data, 'enabled') === false) {
      upgradeService.showAlertDisabledDialog(true, 'create');
    }

    storeData(triggerData);
  });
};

const viewInKibana = (
  eventTimestamp,
  timeRangeInMinutes,
  alertDefinitionId,
  triggersEndPoint = '/triggers/alerts/',
  getTriggersErrMsg = null,
) => {
  const triggersUrl = triggersEndPoint + alertDefinitionId;

  const to = parseInt(eventTimestamp, 10);
  const from = eventTimestamp - 1000 * 60 * parseInt(timeRangeInMinutes, 10);

  getTriggersErrMsg =
    getTriggersErrMsg || 'We could not get event for this alert, \nthis might indicate that the alert has been deleted';

  return httpService.get(triggersUrl).then(async res => {
    if (res.data && res.data.code && res.data.code === 500) {
      const ngDialog = await getAngularService('ngDialog');

      ngDialog.open({
        template: `<p style="padding: 5px;">${getTriggersErrMsg}</p>`,
        width: '655px',
        plain: true,
        showClose: false,
      });

      return Promise.resolve();
    }

    return authService.getUser().then(async user => {
      const searchData = res.data.search;
      const filters = mergeCustomFilters(searchData);

      const kibanaRouteParams = await kibanaRouteService.buildRouteForDiscover({
        query: searchData.queryString,
        from,
        to,
        filters,
        indexPrefix: user.data.indexPrefix,
      });

      const accountIds = await triggerSearchableAccountsService
        .getAllAlertableAccounts()
        .then(results =>
          triggerSearchableAccountsService
            .parseChosenSearchableAccounts(results.data, results.currAccount.data, searchData.source)
            .data.map(account => account.data),
        );

      appModeService.goToModeKibanaState({
        ...kibanaRouteParams,
        accountIds,
      });
    });
  });
};

export const triggerWizardService = {
  handleTriggerAggregation,
  handleTriggerSeverities,
  mergeCustomFilters,
  adjustTriggerDataObjectForServer,
  getData,
  clearData,
  getTriggerDataById,
  isMultipleConditions,
  isSeverityTypeColorShown,
  isAlertType,
  getTimelessIndicesAndTypes,
  storeData,
  storeTriggerWizardData,
  parseMinutesToTimeUnitAndAmount,
  addNewEndpoint,
  setNotificationEndpointsAndEmails,
  getGroupByFieldsList,
  openTriggerSavedDialog,
  initializeAlertWizardData,
  storeDataAndOpenAlertWizard,
  storeDataAndOpenOptimizerWizard,
  openCreateAlertWizard,
  openCreateOptimizerWizard,
  openCreateSecurityRule,
  openConfirmAggregationDialog,
  isAggMode,
  getNumOfAggregatedFields,
  getNumOfNonAggregatedFields,
  parseTriggerData,
  getTableSamples,
  submitTrigger,
  viewInKibana,
};
// /* eslint-disable max-lines */
