import React from 'react';
import { Flex } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { FooterControls } from './FooterControls';
import { MultilineInput } from './MultilineInput';
import { useAiFetchData } from 'ui/components/AI/state/fetch-data/fetch-data.hook';
import { useAiState } from 'ui/components/AI/state/ai-state.hook';

const StyledDiv = styled(Flex)<{ isLoading: boolean }>`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  ${({ isLoading, theme }) => isLoading && `background-color: ${theme.colors.gray[300]}`};
`;

export const AiUserInput: React.FC = () => {
  const { isLoading } = useAiFetchData();
  const { isSessionExpired } = useAiState();

  return (
    <Flex px={6}>
      <StyledDiv isLoading={isSessionExpired || isLoading} flexDirection="column" width="100%">
        <MultilineInput />
        <FooterControls />
      </StyledDiv>
    </Flex>
  );
};
