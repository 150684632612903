import { to } from 'await-to-js';
import { useBoolean } from '@logz-pkg/react-hooks';
import React, { useEffect, useState } from 'react';
import { Group, If, Link, Spinner, Text } from '@logz-ui/styleguide';
import { AuthenticationTypes, LogzioSupportedBucketTypes, Permissions } from '@logz-pkg/enums';
import { AssumeRoleDetailsModel } from '@logz-pkg/models';
import { authService } from '@logz-pkg/frontend-services';
import { ChooseAuthTypeModal } from './components';
import { IAMSettings } from './IAMSettings';
import { SecretAccessSettings } from './SecretAccessSettings';
import { createBucketObject, getBucketEndpoint, getDataByBucketType } from './utils';
import { IAMBucketSettings, ISecretAccessBucketSettings } from './interfaces';
import { BucketSettingsService } from './bucket-settings.service';
import { AddBucketButton } from './components/AddBucketButton';
import { useUserPermissions } from 'ui/state/hooks';

interface IBucketsContainerProps {
  bucketType: LogzioSupportedBucketTypes;
}

export const BucketsContainer: React.FunctionComponent<IBucketsContainerProps> = ({ bucketType }): JSX.Element => {
  const isAuthenticated = authService.isAuthenticated();
  const [buckets, setBucketList] = useState([]);
  const [isErred, setIsErred] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [assumeRoleDetails, setAssumeRoleDetails] = useState<AssumeRoleDetailsModel>({
    logzioAWSAccountId: '',
    assignedExternalId: '',
  });
  const [supportedRegions, setRegionsList] = useState(null);
  const [supportedLogTypes, setSupportedLogTypes] = useState(null);
  const {
    value: isAuthTypeSelectionModalOpen,
    setTrue: openAuthTypeSelectionModal,
    setFalse: closeAuthTypeSelectionModal,
  } = useBoolean(false);

  const hasSendYourDataPermission = useUserPermissions(Permissions.SEND_YOUR_DATA_PAGE);

  const endpoint = getBucketEndpoint(bucketType);

  const fetchData = async () => {
    const [err, data] = await to(getDataByBucketType(bucketType, endpoint));

    if (err) {
      setIsLoading(false);
      setIsErred(true);

      return;
    }

    const [bucketList, assumeRoleInformation, regions, logTypes] = data;

    setBucketList(bucketList);
    setAssumeRoleDetails(assumeRoleInformation);

    if (regions) {
      const regionSelectOptions = regions.map(region => ({ value: region.region, label: region.name.toUpperCase() }));

      setRegionsList(regionSelectOptions);
    }

    if (logTypes) {
      const logTypeSelectOptions = logTypes
        .concat({ type: 'other' })
        .map(logType => ({ value: logType.type, label: logType.type }));

      setSupportedLogTypes(logTypeSelectOptions);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated && hasSendYourDataPermission) fetchData();
  }, [isAuthenticated, hasSendYourDataPermission]);

  const handleAuthTypeSelected = (authenticationType: AuthenticationTypes) => {
    closeAuthTypeSelectionModal();

    const newBucketObject = createBucketObject(authenticationType, {
      regions: supportedRegions,
      logTypes: supportedLogTypes,
      addS3ObjectKeyAsLogField: true,
    });

    setBucketList([...buckets, newBucketObject]);
  };

  const handleSaveBucket = async ({ type, ...bucketSettings }: IAMBucketSettings | ISecretAccessBucketSettings) => {
    const { id } = bucketSettings;

    if (id) {
      const updatedBucket = await BucketSettingsService.updateBucket(bucketSettings, id, endpoint);

      if (!updatedBucket) return false;

      const updatedBucketIndex = buckets.findIndex(bucket => bucket.id === id);

      buckets[updatedBucketIndex] = bucketSettings;
    } else {
      if (!bucketSettings.logsType) bucketSettings.logsType = bucketType;

      const saveBucketResult = await BucketSettingsService.saveBucket(bucketSettings, endpoint);

      if (!saveBucketResult) return false;
    }

    await fetchData();

    return true;
  };

  const handleDeleteBucket = async id => {
    const isBucketDeleted = await BucketSettingsService.deleteBucket(id, endpoint);

    if (isBucketDeleted) {
      const deletedBucketIndex = buckets.findIndex(bucket => bucket.id === id);

      buckets.splice(deletedBucketIndex, 1);
      setBucketList([...buckets]);

      return true;
    }

    return false;
  };

  const handleCancel = appOnlyId => {
    const cancelledBucketIndex = buckets.findIndex(bucket => bucket.appOnlyId === appOnlyId);

    buckets.splice(cancelledBucketIndex, 1);
    setBucketList([...buckets]);
  };

  if (!isAuthenticated)
    return (
      <Link currentTab href={'#/login'}>
        Login to configure {bucketType}
      </Link>
    );

  if (!hasSendYourDataPermission)
    return (
      <Text>
        You need to have the relevant user permissions to edit these settings.
        <br />
        Switch to an account for which you have the required user role or contact your admin for the relevant
        permissions.
      </Text>
    );

  return (
    <>
      {isLoading && <Spinner size="xl" />}
      <If condition={!isLoading && !isErred}>
        <Group vertical width={'100%'} alignItems={'stretch'}>
          {buckets.map(bucket =>
            bucket.type === AuthenticationTypes.IAM ? (
              <IAMSettings
                onSave={values => handleSaveBucket(values)}
                onDelete={(id, logType) => handleDeleteBucket(id)}
                onCancel={handleCancel}
                bucketType={bucketType}
                settings={bucket}
                assumeRoleDetails={assumeRoleDetails}
                regions={supportedRegions}
                supportedLogTypes={supportedLogTypes}
                key={bucket.id || bucket.appOnlyId}
              />
            ) : (
              <SecretAccessSettings
                onSave={values => handleSaveBucket(values)}
                onCancel={handleCancel}
                bucketType={bucketType}
                onDelete={(id, logType) => handleDeleteBucket(id)}
                settings={bucket}
                regions={supportedRegions}
                supportedLogTypes={supportedLogTypes}
                key={bucket.id || bucket.appOnlyId}
              />
            ),
          )}
          <span>
            <AddBucketButton onClick={openAuthTypeSelectionModal} />
          </span>
        </Group>
      </If>
      <If condition={isAuthTypeSelectionModalOpen}>
        <ChooseAuthTypeModal onAuthMethodSelected={handleAuthTypeSelected} onClose={closeAuthTypeSelectionModal} />
      </If>
    </>
  );
};
