import { appStateService } from '@logz-pkg/frontend-services';

const livetailSearchedSchema = 'livetail';

const setLivetailFiltersState = async (regexFilters, kibanaFilters, mode, outputFields) => {
  const filtersState = {
    regexFilters,
    kibanaFilters,
    mode,
    outputFields,
  };

  await appStateService.set(livetailSearchedSchema, filtersState);
};

const getLivetailFiltersState = () => {
  return appStateService.get(livetailSearchedSchema);
};

export const LivetailStateService = {
  setLivetailFiltersState,
  getLivetailFiltersState,
};
