import { UIRouterReact } from '@uirouter/react';
import { sessionStateService } from '@logz-pkg/frontend-services';
import { userHistoryStateService } from 'ui/state/user-history.state.service';
import { getProductForState } from 'ui/components/App/Navigation/utils';

export const registerHistoryTracking = (appRouter: UIRouterReact) => {
  appRouter.transitionService.onSuccess({}, transition => {
    const session = sessionStateService.session.get();

    if (!session.loggedInUser) {
      return;
    }

    const { appMode } = session;
    const fromProduct = getProductForState({ state: transition.from().name, appMode: appMode.value, stateParams: {} });
    const toProduct = getProductForState({ state: transition.to().name, appMode: appMode.value, stateParams: {} });

    if (fromProduct) {
      userHistoryStateService.trackEvent(fromProduct);
    }

    if (toProduct) {
      userHistoryStateService.trackEvent(toProduct);
    }
  });
};
