import { useMemo } from 'react';
import { Options, Series } from 'highcharts';
import { defaultLegendOptions } from '../../../constants';
import { CustomPoint, IGetOptionsProps } from '../../../types';

type UseLegendOptions = {
  legend: IGetOptionsProps['legend'];
  onClick: IGetOptionsProps['onClick'];
  duplicatesMenuInSideBar: IGetOptionsProps['duplicatesMenuInSideBar'];
};

export const useLegendOptions = ({ legend, onClick, duplicatesMenuInSideBar }: UseLegendOptions) => {
  const legendOptions: Options['legend'] = useMemo(
    () => ({
      ...defaultLegendOptions,
      events: {
        ...(duplicatesMenuInSideBar &&
          onClick && {
            itemClick(e) {
              const series = e.legendItem as Series;

              const context = series.data.find((point: CustomPoint) => point.data?.product === series.name);

              onClick({
                menuData: {
                  title: series.name,
                  value: series.index,
                  location: { x: e.browserEvent.clientX, y: e.browserEvent.clientY },
                  hideHeaderValue: true,
                  context,
                },
              });

              return false;
            },
          }),
      },
      ...legend,
    }),
    [legend, duplicatesMenuInSideBar, onClick],
  );

  return legendOptions;
};
