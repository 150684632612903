import {
  LoggerService,
  appStateService,
  metricsSearchApiService,
  osdSearchApiService,
  sessionStateService,
} from '@logz-pkg/frontend-services';
import { GlobalSearchResultModel, SearchSource } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { PendingPromiseCache } from '@logz-pkg/utils';
import { debounce, get, throttle } from 'lodash';
import { getDashboardId } from './bookmark.state.service';
import { featureFlagStateService } from './feature-flag.state.service';
import { ErrorHandlingService } from 'services/error-handling.service';
import { appRouter } from 'services/router/router.service';
import { viewDashboardRouteName } from 'states/dashboards-hub/dashboards-hub.routes';

const STATE_NAME = 'recent-visits';
const RECENT_VISITS_LIST_SIZE = 50;

const pendingPromise = new PendingPromiseCache();

const trackableRecentStates = ['dashboard.osd', 'dashboard.metrics', 'dashboard.view-dashboard'];
const validateOsDashboardPath = (path: string | null) => (path ?? '').match(/^dashboards\/view/);
const validateGrafanaDashboardPath = (path: string | null) => (path ?? '').match(/^d\//);
const validateUnifiedDashboardPath = (params: { dashboardId: string; dashboardTitle: string } | null) => {
  const { dashboardId, dashboardTitle: encodedDashboardTitle } = params;
  const dashboardTitle = encodedDashboardTitle ? decodeURIComponent(encodedDashboardTitle) : encodedDashboardTitle;
  const path = appRouter.stateService.href(viewDashboardRouteName, {
    dashboardId,
    dashboardTitle,
  });

  return (path ?? '').match(/dashboards-hub\/view/);
};
const trackableStatesValidators = {
  [SearchSource.OsdDashboard]: (currentState: GlobalSearchResultModel) =>
    validateOsDashboardPath(get(currentState, 'state.params.resource', '')),
  [SearchSource.GrafanaDashboard]: (currentState: GlobalSearchResultModel) =>
    validateGrafanaDashboardPath(get(currentState, 'state.params.resource', '')),
  [SearchSource.UnifiedDashboard]: (currentState: GlobalSearchResultModel) =>
    validateUnifiedDashboardPath(get(currentState, 'state.params', '')),
};
const fetchStateTitleHandlers = {
  [SearchSource.OsdDashboard]: async (currentState: GlobalSearchResultModel) => {
    const searchResultModels = await osdSearchApiService.bulkGet([getDashboardId(currentState)]);

    if (searchResultModels.length === 1) return searchResultModels[0].title;

    return currentState.title;
  },
  [SearchSource.GrafanaDashboard]: async (currentState: GlobalSearchResultModel) => {
    try {
      const dashboard = await metricsSearchApiService.getGrafanaObject(getDashboardId(currentState));

      return dashboard.title;
    } catch {
      return currentState.title;
    }
  },
  [SearchSource.UnifiedDashboard]: async (currentState: GlobalSearchResultModel) => {
    try {
      const { dashboardTitle: encodedDashboardTitle } = currentState?.state?.params;
      const dashboardTitle = encodedDashboardTitle ? decodeURIComponent(encodedDashboardTitle) : encodedDashboardTitle;

      return dashboardTitle;
    } catch {
      return currentState.title;
    }
  },
};

export class RecentVisitsStateService {
  recentVisited = new Observable<GlobalSearchResultModel[]>([]);
  private debouncedAddOperation: (GlobalSearchResultModel) => void;
  private delayedSync: () => Promise<void>;

  constructor() {
    this.delayedSync = throttle(this.sync, 10000);
    this.debouncedAddOperation = debounce(async (currentState: GlobalSearchResultModel) => {
      const existingStateIndex = this.getAlreadyRecordedStateIndex(currentState);
      const updatedHistory = this.recentVisited.get();

      const title = await this.fetchStateTitle(currentState);
      let visitCount = 1;

      if (existingStateIndex > -1) {
        const existingState = updatedHistory[existingStateIndex];

        visitCount = existingState.visitCount + 1;

        updatedHistory.splice(existingStateIndex, 1);
      }

      if (currentState.source === SearchSource.GrafanaDashboard) {
        if (!currentState.state.params.id) {
          const dashboardUid = getDashboardId(currentState);
          const dashboard = await metricsSearchApiService.getGrafanaObject(dashboardUid);

          if (!dashboard) {
            LoggerService.logWarn({
              message: `Recent Visits - Dashboard with uid (${dashboardUid}) was not found.
most likely, a user switched-account/logged-out before reaching this point`,
            });

            return;
          }

          currentState.state.params = { ...currentState.state.params, id: dashboard.id, uid: dashboardUid };
        }
      }

      updatedHistory.unshift({ ...currentState, title, visitCount });

      this.recentVisited.set(updatedHistory.slice(0, RECENT_VISITS_LIST_SIZE));

      this.delayedSync();
    }, 4000);
  }
  fetch = () => {
    return pendingPromise.get(STATE_NAME, async () => {
      const savedHistory = await appStateService.get(STATE_NAME);

      const recent = savedHistory?.states || [];

      const filteredRecent = this.filterDashboards(recent);

      this.recentVisited.set(filteredRecent);
    });
  };

  private filterDashboards = (recent: GlobalSearchResultModel[]): GlobalSearchResultModel[] => {
    const {
      userSession: { accountId },
    } = sessionStateService.session.get();
    const isUnifiedDashboardsEnabled = featureFlagStateService.isFeatureEnabled('UnifiedDashboards');

    return recent.filter(item => {
      if (item.source === SearchSource.OsdDashboard) {
        return true;
      }

      if (item.source === SearchSource.UnifiedDashboard) {
        return isUnifiedDashboardsEnabled;
      }

      // We filter out grafana dashboards that are not owned by the account because we
      // can't get that object details without changing the account DEV-39092
      if (item.source === SearchSource.GrafanaDashboard) {
        return parseInt(item.state.params.switchToAccountId, 10) === accountId;
      }

      return false;
    });
  };

  private fetchStateTitle = async (currentState: GlobalSearchResultModel) => {
    try {
      return await fetchStateTitleHandlers[currentState.source](currentState);
    } catch (err) {
      return currentState.title;
    }
  };

  private getAlreadyRecordedStateIndex = (currentState: GlobalSearchResultModel): number =>
    this.recentVisited
      .get()
      .findIndex(
        state =>
          currentState.source === state.source &&
          currentState.state.params?.resource === state.state.params?.resource &&
          currentState.state.params?.switchToAccountId === state.state.params?.switchToAccountId,
      );

  isStateWhitelisted = state => trackableRecentStates.includes(state.name);

  add = async (currentState: GlobalSearchResultModel): Promise<void> => {
    const trackableStateValidator = trackableStatesValidators[currentState.source];
    const trackableStateResult = trackableStateValidator?.call(this, currentState);

    if (!trackableStateResult) return;

    if (!currentState.state.params.switchToAccountId) {
      LoggerService.logError({ message: `unable to set recent (${currentState.title}) without accountId` });

      return;
    }

    await this.fetch();

    this.debouncedAddOperation(currentState);
  };

  private sync = async () => {
    try {
      await appStateService.set(STATE_NAME, { states: this.recentVisited.get() });
    } catch (error) {
      LoggerService.logError({ message: 'History sync failed', error });
    }
  };

  clear = async () => {
    try {
      await appStateService.set(STATE_NAME, { states: [] });
      this.recentVisited.set([]);
    } catch (error) {
      ErrorHandlingService.handleApiError({
        title: 'History lost',
        error,
      });
    }
  };
}

export const recentVisitsStateService = new RecentVisitsStateService();
