import axios from 'axios';
import { Env } from '@logz-pkg/frontend-services';

(function setupAxiosInterceptors() {
  axios.interceptors.request.use(requestSuccess, requestFail);
  axios.interceptors.response.use(responseSuccess, responseFail);
})();

const debug = Env.configs.debugHttpInterceptors;

const pendingRequestsCount: Record<string, number> = {
  app: 0,
};
const getTotal = () => {
  return Object.values(pendingRequestsCount).reduce((acc, v) => acc + v, 0);
};

function log(id: string, type: string, config: { url: any } | null = null) {
  const total = getTotal();

  if (!debug) return;

  const pre = `[HttpInterceptorsService][${Date.now()}][${id.padEnd(10)}][${type.padEnd(15)}]`;

  if (!config) {
    console.error(`${pre}`);
  } else {
    console.error(`${pre}${new Array(total).fill('-').join('')}${total} | ${config.url}`);
  }
}

const subscribers: any[] = [];
export function subscribeOnPendingRequestsChange(fn) {
  if (subscribers.find(() => fn)) return;

  subscribers.push(fn);
}

function publish({ config, increase }) {
  subscribers.forEach((fn: ({}) => void) => fn({ config, increase, pendingRequestsCount: getTotal() }));
}

function updatePendingRequests({ config, increase, id }) {
  const change = increase ? 1 : -1;

  if (typeof pendingRequestsCount[id] === 'number') {
    pendingRequestsCount[id] += change;

    if (pendingRequestsCount[id] < 0) {
      pendingRequestsCount[id] = 0;
    }
  } else {
    pendingRequestsCount[id] = increase ? 1 : 0;
  }

  publish({ config, increase });
}

const clearPendingRequests = (type: string) => {
  pendingRequestsCount[type] = 0;

  publish({ increase: false, config: null });
};

function requestSuccess(config) {
  updatePendingRequests({ config, increase: true, id: 'app' });
  log('app', 'requestSuccess', config);

  return config;
}

function requestFail(rejection) {
  updatePendingRequests({ config: rejection.config, increase: false, id: 'app' });
  log('app', 'requestFail', rejection.config);

  return Promise.reject(rejection);
}

function responseSuccess(response) {
  updatePendingRequests({ config: response.config, increase: false, id: 'app' });
  log('app', 'responseSuccess', response.config);

  return response;
}

function responseFail(error) {
  updatePendingRequests({ config: error.config, increase: false, id: 'app' });
  log('app', 'responseFail', error.config);

  return Promise.reject(error);
}

export const httpInterceptorService = {
  updatePendingRequests,
  clearPendingRequests,
  log,
};
