import { useObservable } from '@logz-pkg/observable';
import { welcomeStateService } from '../welcome.state.service';

export const useWelcomeState = () => {
  const sentData = useObservable(welcomeStateService.sentData);

  return {
    isInWelcomeFlow: useObservable(welcomeStateService.isInWelcomeFlow),
    isFocusMode: useObservable(welcomeStateService.isFocusMode),
    isDataSentModalOpen: useObservable(welcomeStateService.isDataSentModalOpen),
    shouldDisplayBar: useObservable(welcomeStateService.isBarDisplayed),
    sentData,
    setFocusMode: welcomeStateService.setFocusMode,
    setIsDataSentModalOpen: welcomeStateService.setIsDataSentModalOpen,
    didSendData: welcomeStateService.didSendData(),
  };
};
