import { Field } from 'formik';
import { Input } from '@logz-ui/styleguide';
import { AwsS3BucketSubject } from '@logz-pkg/enums';

export const ARNField = ({ isDisabled = false }) => (
  <Field name="arn">
    {({ field, form }) => (
      <Input
        subject={AwsS3BucketSubject.BucketArnInput}
        disabled={isDisabled}
        mb={0}
        label="Role ARN"
        placeholder="arn:aws:iam::123456780912:role/S3Access"
        disableAutoComplete={true}
        error={form.touched.arn && form.errors.arn}
        {...field}
      />
    )}
  </Field>
);
