import { LoggerService, Env } from '@logz-pkg/frontend-services';

export const logWarn = msg => LoggerService.logWarn({ message: `[ProductIframe] ${msg}` });

export const logError = msg =>
  // provide with error in case its missing, for stack trace.
  LoggerService.logError({ message: `[ProductIframe] ${msg}`, error: new Error(msg) });

const report = ({ msg, metadata = {}, level = 'log' }) => {
  if (!Env.configs.debugProductIframe) return;

  if (Env.configs.logObjectToConsole) {
    console[level](`[ProductIframe] | ${Date.now()} | ${msg}`, metadata);
  } else {
    console.error(`[ProductIframe] | ${Date.now()} | ${msg}`, JSON.stringify(metadata));
  }
};

export const reportInfo = (stateName: string, msg, metadata = {}) => {
  report({ msg: `INFO: [${stateName}] ${msg}`, metadata, level: 'info' });
};
export const reportWarn = (stateName: string, msg, metadata = {}) => {
  report({ msg: `WARNING: [${stateName}] ${msg}`, metadata, level: 'warn' });
};
export const reportError = (stateName: string, msg, error) => {
  report({ msg: `ERROR: [${stateName}] ${msg}\n${error.message}`, metadata: error, level: 'error' });
};
