import { FunctionComponent } from 'react';
import { Button, Input, Text, Group } from '@logz-ui/styleguide';
import { FastField } from 'formik';
import { FilebeatConfWizSubjects } from '@logz-pkg/enums';

interface IFieldConditionProps {
  name: string;
  nameError: string;
  valueError: string;
  index: number;
  isRemovable: boolean;
  handleRemove: (index: number) => {};
}

export const FieldCondition: FunctionComponent<IFieldConditionProps> = ({
  name,
  nameError,
  valueError,
  index,
  isRemovable,
  handleRemove,
}): JSX.Element => (
  <FastField
    name={name}
    render={({ field, form }) => {
      const { fieldName, fieldValue } = field.value;

      return (
        <>
          <Group alignItems={'center'} fullWidth>
            <Input
              subject={`${name}.fieldName`}
              error={nameError}
              name={`${name}.fieldName`}
              placeholder={'Environment'}
              value={fieldName || ''}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              my={0}
            />
            <Text size={16} color={'gray.1000'} mb={3}>
              =
            </Text>
            <Input
              subject={`${name}.fieldValue`}
              error={valueError}
              name={`${name}.fieldValue`}
              placeholder={'Production'}
              value={fieldValue || ''}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              my={0}
            />
            {isRemovable && (
              <Button
                round
                variant={'tertiary'}
                onClick={() => handleRemove(index)}
                icon={'xmark-regular'}
                subject={FilebeatConfWizSubjects.RemoveField}
              />
            )}
          </Group>
        </>
      );
    }}
  />
);
