import { colorsV2 } from '@logz-ui/styleguide';
import { HelpQuestionsType } from '../../AI/types';

export const userGuideHelpQuestions: HelpQuestionsType = {
  start: [
    {
      color: colorsV2.green[600],
      iconName: 'magnifying-glass-regular',
      text: 'How to set up SSO in Logz.io?',
    },
    {
      color: colorsV2.green[600],
      iconName: 'magnifying-glass-regular',
      text: 'How to create a Slack endpoint in Logz.io for alerts?',
    },
    {
      color: colorsV2.green[600],
      iconName: 'magnifying-glass-regular',
      text: 'How to reduce data volume sent to Logz.io?',
    },
  ],
};

export const shippingHelpQuestions: HelpQuestionsType = {
  start: [
    {
      color: colorsV2.green[600],
      iconName: 'magnifying-glass-regular',
      text: 'How to send Kubernetes logs and metrics to Logz.io?',
    },
    {
      color: colorsV2.green[600],
      iconName: 'magnifying-glass-regular',
      text: 'How to ship Linux system logs to Logz.io?',
    },
    {
      color: colorsV2.green[600],
      iconName: 'magnifying-glass-regular',
      text: 'How to ship Java application logs to Logz.io?',
    },
  ],
};
