import React, { ReactNode } from 'react';
import { Text } from '@logz-ui/styleguide';
import { UxType } from '@logz-pkg/enums';
import { LoggerService } from '@logz-pkg/frontend-services';

const caughtRequests = {};

interface IErrorBoundaryProps {
  errorContext: string;
  fallback: ReactNode;
  fallbackAsFunc?: ({ error }: { error: string }) => ReactNode;
  children: React.ReactNode;
}
interface IErrorBoundaryState {
  hasError: boolean;
  error: string;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  static defaultProps = {
    errorContext: '',
    fallback: <Text mb={0}>Something went wrong.</Text>,
  };

  constructor(props) {
    super(props);
    this.state = { error: '', hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (!caughtRequests[this.props.errorContext]) {
      this.setState({
        error: error.message,
        hasError: true,
      });
      LoggerService.logError({
        message: `Error caught in ErrorBoundary: ${this.props.errorContext}\n${error.message}\n${errorInfo?.componentStack}`,
        error,
        uxType: UxType.IN_PAGE,
        extra: { errorContext: this.props.errorContext },
      });
      caughtRequests[this.props.errorContext] = true;
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props?.fallbackAsFunc?.({ error: this.state.error }) || this.props.fallback;
    }

    return this.props.children;
  }
}
