import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const welcomeRouteState = 'dashboard.welcome';

export const welcomeRoute: AppStateDeclaration = {
  name: welcomeRouteState,
  url: '/welcome',
  component: props => (
    <LazyReact
      lazyImport={() => import('ui/components/WelcomeCenter/WelcomePage/WelcomePage')}
      resolver={({ WelcomePage }) => WelcomePage}
      {...props}
    />
  ),
  data: { authenticate: true, title: 'Welcome to Logz.io', breadcrumb: { label: 'Welcome' } },
};
