import { Link } from '@logz-ui/styleguide';
import { FunctionComponent, ReactElement } from 'react';
import { show as showIntercom } from 'services/common/intercom.service';
import { useSessionState } from 'ui/state/hooks/session.state.hook';

interface IContactSupportProps {
  text?: string | ReactElement;
  handleClick?(): void;
}

export const ContactSupport: FunctionComponent<IContactSupportProps> = ({ handleClick, text = 'contact Support.' }) => {
  const { integrations } = useSessionState();

  const onClick = () => {
    showIntercom();

    if (handleClick) handleClick();
  };

  return integrations?.intercom?.isDisabled ? (
    <Link href="mailto:support@logz.io" onClick={handleClick}>
      {text}
    </Link>
  ) : (
    <Link onClick={onClick}>{text}</Link>
  );
};
