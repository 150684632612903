import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { DefaultTheme, ThemeProvider, useTheme } from 'styled-components';
import { merge } from 'lodash';
import { graphLightV2Theme } from './themes/light-v2.theme';
import { graphDarkV2Theme } from './themes/dark-v2.theme';
import { GraphService } from './graph.service';
import { IGraphProps } from './types';

const GraphContext = createContext<GraphService | null>(null);

export const useGraphContext = () => useContext(GraphContext);

export const GraphProvider: React.FC<React.PropsWithChildren<IGraphProps>> = ({ children, ...props }) => {
  const ref = useRef(new GraphService());
  const { name } = useTheme();

  useEffect(() => {
    ref.current.updateProps(props);
  }, [props]);

  const handleTheme = useCallback(
    (theme: DefaultTheme): DefaultTheme => {
      return {
        ...theme,
        graph: name === 'light' ? graphLightV2Theme : merge({}, graphLightV2Theme, graphDarkV2Theme),
      };
    },
    [name],
  );

  return (
    <ThemeProvider theme={handleTheme}>
      <GraphContext.Provider value={ref.current}>{children}</GraphContext.Provider>
    </ThemeProvider>
  );
};
