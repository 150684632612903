import { GrafanaTheme, LogzioTheme } from '@logz-pkg/enums';
import { isNil } from 'lodash';
import { httpService, sessionApiService, sessionStateService, grafanaApiService } from '@logz-pkg/frontend-services';
import { darkModeService } from '@logz-ui/styleguide';
import { kibanaThemeService } from '../../app/osd/osd-theme.service';
import { metricsService } from '../metrics/metrics.service';
import { themeAppStateService } from './app-state';
import { themeStateService } from 'ui/state/theme.state.service';

// DEV-27501:
// This function and the one below arent in use, since we're going for unified Dark Mode they will be deleted.
const initGrafanaThemeState = async () => {
  let theme;

  try {
    const isGrafanaActive = await metricsService.isGrafanaActive();

    if (isGrafanaActive) {
      ({ theme } = await grafanaApiService.getUserPreferences());
    }
  } catch {
  } finally {
    themeStateService.setGrafanaThemeState(isNil(theme) || theme === '' ? GrafanaTheme.Dark : theme);
  }
};

const initKibanaThemeState = async () => {
  themeStateService.setKibanaThemeState(await kibanaThemeService.getKibanaTheme());
};

const initTheme = async (userSettings): Promise<LogzioTheme> => {
  const { kibanaTheme } = userSettings;

  await themeAppStateService.set(kibanaTheme);

  // We fetch the latest session to store the new user theme there
  await sessionStateService.fetch({ fresh: true });

  return kibanaTheme;
};

const setDarkReaderForTheme = async (theme: LogzioTheme) => {
  if (theme === LogzioTheme.Dark) {
    await darkModeService.enable();
  } else if (theme === LogzioTheme.Light) {
    await darkModeService.disable();
  }
};

const setKibanaTheme = async (currentUserSettings, newKibanaTheme) => {
  const newUserSettings = {
    ...currentUserSettings,
    kibanaTheme: newKibanaTheme,
  };

  await httpService.post('/user-settings', newUserSettings);
};

const setLogzioTheme = async ({ currentUserSettings, newUserTheme }) => {
  await setKibanaTheme(currentUserSettings, newUserTheme);
  await themeAppStateService.set(newUserTheme);
  // We dont fetch the new session because we refresh the page, but still need to delete cache
  sessionApiService.clearCache();
  window.location.reload();
};

const clearTheme = () => darkModeService.disable();

export const themeService = {
  initGrafanaThemeState,
  initKibanaThemeState,
  initTheme,
  clearTheme,
  setLogzioTheme,
  setDarkReaderForTheme,
};
