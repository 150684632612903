import { FunctionComponent, memo } from 'react';
import styled from 'styled-components';
import { Box, SecondarySidenav } from '@logz-ui/styleguide';
import { ProductKeys, SidenavSubjects } from '@logz-pkg/enums';
import { ISidenavItemConfig } from '../item-configs';
import { useNavigationDisplayCondition } from '../../utils/use-navigaition-display-condition';
import { ExpandedNavItemsGroup } from './ItemsGroup';
import { ExpandedSidenavHeader } from './Header';
import { LogoutButton } from './Components/LogoutButton';

interface IExpandedSidenavProps {
  open: boolean;
  hoveredMainNavItem: ISidenavItemConfig;
  currentProduct: ProductKeys;
}

const ItemsContainer = styled(Box)`
  overflow: auto;
`;

interface IExpandedNavGroupsProps {
  hoveredMainNavItem: ISidenavItemConfig;
  currentProduct: ProductKeys;
}

const ExpandedNavGroups: FunctionComponent<IExpandedNavGroupsProps> = ({ hoveredMainNavItem, currentProduct }) => {
  const [allowedExpandedNavGroups] = useNavigationDisplayCondition(hoveredMainNavItem?.expandedNavGroups);

  return (
    <ItemsContainer flexGrow={1} py={2}>
      {allowedExpandedNavGroups?.map((expandedNavGroup, index) => {
        return (
          <ExpandedNavItemsGroup
            key={expandedNavGroup.title || hoveredMainNavItem.key + index}
            productName={hoveredMainNavItem.expandedSidenavTitle}
            productKey={hoveredMainNavItem.key}
            navGroup={expandedNavGroup}
            appMode={hoveredMainNavItem.mode}
            currentProduct={currentProduct}
          />
        );
      })}
    </ItemsContainer>
  );
};

export const ExpandedSidenav: FunctionComponent<IExpandedSidenavProps> = memo(
  ({ hoveredMainNavItem, open, currentProduct }) => {
    return (
      <SecondarySidenav
        isOpen={open && hoveredMainNavItem?.expandedNavGroups.length > 0}
        name={'nav'}
        direction={'left'}
        width={'240px'}
        subject={SidenavSubjects.ExpandedSidenavContainer}
      >
        <ExpandedSidenavHeader hoveredMainNavItem={hoveredMainNavItem} />
        <ExpandedNavGroups hoveredMainNavItem={hoveredMainNavItem} currentProduct={currentProduct} />
        {hoveredMainNavItem?.key === 'settings' && <LogoutButton />}
      </SecondarySidenav>
    );
  },
);

ExpandedSidenav.displayName = 'ExpandedSidenav';
