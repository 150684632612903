import { cacheProvider } from '@logz-pkg/frontend-services';
import { Observable } from '@logz-pkg/observable';

class AiFeedbackService {
  readonly aiReasons = ['Data accuracy', 'Response time', 'Amount of information', '+ Other feedback'];
  readonly feedbackTextMap: Record<number, string> = {
    1: `Sorry to hear that! We're always looking to improve.
What can we do better?`,
    2: `Sorry to hear that! We're always looking to improve.
What can we do better?`,
    3: `Thanks for your feedback! We're always looking to improve.
What can we do better?`,
    4: `Thanks for your feedback! We're always looking to improve.
What can we do better?`,
    5: `We're glad to hear that! What did you like most?`,
  };

  readonly shouldShowFeedback = new Observable<boolean>(false);
  readonly rate = new Observable<number>(0);

  setShouldShowFeedback = async (shouldShowFeedback: boolean = true): Promise<void> => {
    const shouldShowFeedbackFromCache = !(await cacheProvider.getPersistentTtl('dont-show-ai-feedback'));

    this.setRate(0);

    if (shouldShowFeedback && shouldShowFeedbackFromCache) {
      this.shouldShowFeedback.set(true);
      await cacheProvider.setPersistentTtl('dont-show-ai-feedback', {}, 1000 * 60 * 60 * 24 * 7); // 7 days
    } else {
      await this.shouldShowFeedback.set(false);
    }
  };

  setRate = async (rate: number): Promise<void> => {
    this.rate.set(rate);
  };
}

export const aiFeedbackService = new AiFeedbackService();
