import isNil from 'lodash/isNil';

const removeQueryFromMetricsHeader = (metrics: string): string => {
  if (!metrics) return '';

  const parsedMetrics = JSON.parse(metrics);

  parsedMetrics.forEach(metric => {
    if (metric?.metadata?.url) {
      metric.metadata.url = metric.metadata.url.replace(/(\?.*)/g, '');
    }
  });

  return JSON.stringify(parsedMetrics);
};

export const interceptIntercomRequests = intercomWindow => {
  const { send: sendXMLHttpRequest, isLogzioOverride } = intercomWindow.XMLHttpRequest.prototype;
  const { send: sendWebSocket } = intercomWindow.WebSocket.prototype;

  if (isLogzioOverride) return;

  intercomWindow.WebSocket.prototype.send = function sendProxy(...args) {
    //  clean websocket events from sensitive query data before sending it to intercom
    const overrideArgs = args.map(webSocketEvent => {
      const event = JSON.parse(webSocketEvent);

      if (event?.eventData?.current_page) {
        event.eventData.current_page = event.eventData.current_page.replace(/(\?.*)/g, '');
      }

      return JSON.stringify(event);
    });

    return sendWebSocket.apply(this, overrideArgs);
  };

  intercomWindow.XMLHttpRequest.prototype.send = function sendProxy(...args) {
    //  clean referer and metrics headers from sensitive query data before sending it to intercom
    if (!isNil(args[0]) && typeof args[0] === 'string') {
      const searchParams = new URLSearchParams(args[0]);
      const metrics = searchParams.get('metrics');

      if (metrics) {
        searchParams.set('metrics', removeQueryFromMetricsHeader(metrics));
      }

      const referer = searchParams.get('referer');

      if (referer) {
        searchParams.set('referer', referer.replace(/(\?.*)/g, ''));
      }

      args[0] = searchParams.toString();
    }

    return sendXMLHttpRequest.apply(this, args);
  };

  intercomWindow.XMLHttpRequest.prototype.isLogzioOverride = true;
};
