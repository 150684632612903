import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const homeDashboardRouteName = 'dashboard.home';

export const homeDashboardRoute: AppStateDeclaration = {
  name: homeDashboardRouteName,
  url: '/home',
  component: props => (
    <LazyReact
      lazyImport={() => import('ui/components/HomeDashboard/HomeDashboard')}
      resolver={({ HomeDashboard }) => HomeDashboard}
      {...props}
    />
  ),
  data: {
    authenticate: true,
    title: 'Home Dashboard',
    breadcrumb: { label: 'Home Dashboard' },
  },
};
