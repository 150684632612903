import { PlanAndBillingState } from '@logz-pkg/enums';
import { AppStateDeclaration } from './AppStateDecleration';
import { redirectIfNotAdmin } from './utils';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const planAndBilling: AppStateDeclaration[] = [
  {
    name: PlanAndBillingState.PlanAndBilling,
    url: '/plan-and-billing',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/PlanAndBillingV2020/PlanAndBilling')}
        resolver={({ PlanAndBilling }) => PlanAndBilling}
        {...props}
      />
    ),
    data: { authenticate: true, title: 'Plan and usage', breadcrumb: { label: 'Plan And Billing' } },
    redirectTo: PlanAndBillingState.Plan,
    resolve: [redirectIfNotAdmin],
  },
  {
    name: PlanAndBillingState.Plan,
    url: '/plan?productToNavigateTo',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/PlanAndBillingV2020/Plan/Plan')}
        resolver={({ PlanAndBillingPlan }) => PlanAndBillingPlan}
        {...props}
      />
    ),
    data: { authenticate: true, breadcrumb: { label: 'Plan' } },
    dynamic: true,
    params: {
      productToNavigateTo: {
        inherit: false,
        value: null,
      },
    },
  },
  {
    name: PlanAndBillingState.Usage,
    url: '/usage',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/PlanAndBillingV2020/Usage/Usage')}
        resolver={({ PlanAndBillingUsage }) => PlanAndBillingUsage}
        {...props}
      />
    ),
    data: { authenticate: true, breadcrumb: { label: 'Usage' } },
  },
];
