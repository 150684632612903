import type { EventName, FeatureName } from '@logz-pkg/analytics';
import { analyticsApiService, authService, Env } from '@logz-pkg/frontend-services';
import { cloneDeep, once } from 'lodash';
import {
  enrichEventData,
  formatEventName,
  getAccountProps,
  getIsAccountDisabled,
  getUserProps,
  isUserAccountIdentified,
  reportInternalEvent,
} from './utils';

type UserProps = Awaited<ReturnType<typeof getUserProps>>;

const removeUserPii = (userProps: UserProps): UserProps => {
  const clonedUserProps = cloneDeep(userProps);

  delete clonedUserProps.username;

  return clonedUserProps;
};

const identifyUserAccount = async () => {
  const userProps = removeUserPii(await getUserProps());
  const accountProps = getAccountProps();

  if (Env.configs.captureAnalytics === true) {
    await analyticsApiService.identifyUser(userProps);
    await analyticsApiService.identifyAccount(accountProps);
  }

  if (Env.configs.logToConsole === true) {
    console.log('Analytics user account identified:', { userProps, accountProps });
  }
};

const ensureUserAccountIdentified = once(() => {
  if (isUserAccountIdentified()) return;

  return identifyUserAccount();
});

const capture = async <F extends FeatureName, E extends EventName<F>>(
  featureName: F,
  eventName: E,
  eventProps?: Record<string, unknown>,
) => {
  if (!authService.isAuthenticated()) return;

  const isAccountDisabled = await getIsAccountDisabled();

  if (isAccountDisabled) return;

  await ensureUserAccountIdentified();

  const eventData = enrichEventData(featureName, eventProps);

  if (Env.configs.captureAnalytics === true) {
    analyticsApiService.capture(featureName, eventName, eventData);
  }

  if (Env.configs.reportUserAnalytics === true) {
    reportInternalEvent(featureName, eventName, eventData);
  }

  if (Env.configs.logToConsole === true) {
    console.log('Analytics event captured:', formatEventName(featureName, eventName), eventData);
  }
};

export const analyticsService = { capture, identifyUserAccount };
