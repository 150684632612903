import React from 'react';
import { Button, Group, Text, Tooltip } from '@logz-ui/styleguide';
import { SearchHit } from '@opensearch-project/opensearch/api/types';
import moment from 'moment-timezone';
import { GraphEvents } from '@logz-ui/graphs';
import { useObservable } from '@logz-pkg/observable';
import { dateService } from '@logz-pkg/frontend-services';
import { analyticsService } from 'services/analytics/analytics.service';
import { exploreRouteHelpers } from 'ui/components/Explore/router/router.helpers';
import { DeploymentMarkerEvent } from 'ui/components/Explore/types';
import { exploreUtils } from 'ui/components/Explore/utils/explore-utils';
import { explorePreferencesStateService } from 'ui/components/Explore/state/app-state/explore-preferences-states.service';

const TimestampCell: React.FC<{ value: Date }> = ({ value }) => {
  const { timezone } = useObservable(explorePreferencesStateService.state).generalSettings;
  const fixedDate = moment(value);
  const date = fixedDate.tz(timezone ?? dateService.getBrowserTimeZone());

  return (
    <Text size={12} ellipsis={{ maxLines: 2 }}>
      {date.format('DD/MM/YY HH:mm')}
    </Text>
  );
};

const TextCell: React.FC<{ value: string }> = ({ value }) => {
  return (
    <Text size={12} ellipsis={{ maxLines: 2, wordBreak: 'normal' }}>
      {value}
    </Text>
  );
};

const ActionsCell: React.FC<{ value: SearchHit<DeploymentMarkerEvent> }> = ({ value }) => {
  const logId = value._id;

  const handleClick = (action: 'single' | 'surrounding') => {
    analyticsService.capture('Explore graph', action === 'single' ? 'View single log' : 'View surrounding logs');

    if (action === 'single') {
      const href = exploreRouteHelpers.buildExploreUrl({
        routeName: 'single',
        params: { logId: exploreUtils.formatLogId(logId) },
      });

      window.open(href, '_blank');
    } else {
      exploreUtils.handleSurroundingDocs(logId);
    }
  };

  return (
    <Group width={'100%'} height={'100%'} alignItems="center" justifyContent="center">
      <Tooltip title={'View single log'} openDelayInMs={300} placement="top">
        <Button size="s" icon={'eye-regular'} variant={'secondary'} onClick={() => handleClick('single')} />
      </Tooltip>
      <Tooltip title={'View surrounding logs'} openDelayInMs={300} placement="top">
        <Button
          size="s"
          icon={'bars-staggered-regular'}
          onClick={() => handleClick('surrounding')}
          variant={'secondary'}
        />
      </Tooltip>
    </Group>
  );
};

export const graphEventColumns: GraphEvents<SearchHit<DeploymentMarkerEvent>>['columns'] = [
  {
    accessorFn: event => event._source['@timestamp'],
    header: 'Time',
    size: 100,
    enableResizing: false,
    cell: ({ getValue }) => <TimestampCell value={getValue() as Date} />,
  },
  {
    accessorFn: event => event._source.__logzio_marker_title,
    header: 'Title',
    enableResizing: false,
    cell: ({ getValue }) => <TextCell value={getValue() as string} />,
  },
  {
    accessorFn: event => event._source.__logzio_marker_description,
    header: 'Description',
    enableResizing: false,
    cell: ({ getValue }) => <TextCell value={getValue() as string} />,
  },
  {
    accessorFn: event => event,
    id: 'actions',
    header: '',
    size: 80,
    showCellOnRowHover: true,
    enableResizing: false,
    enableSorting: false,
    cell: ({ getValue }) => <ActionsCell value={getValue() as SearchHit<DeploymentMarkerEvent>} />,
  },
];
