import { Observable } from '@logz-pkg/observable';
import { ConfirmModal } from '@logz-ui/styleguide';
import { ComponentProps, ReactNode } from 'react';

type ConfirmModalProps = Omit<ComponentProps<typeof ConfirmModal>, 'onConfirm' | 'onClose'>;

type ComfirmObservable = ConfirmModalProps & {
  text?: ReactNode;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const appConfirmModalObservable = new Observable<ComfirmObservable>({
  open: false,
  onConfirm: () => {},
  onCancel: () => {},
});

type ConfirmProps = ConfirmModalProps & {
  text?: ReactNode;
};

const confirm = async (props: ConfirmProps): Promise<boolean> => {
  return new Promise<void>((resolve, reject) => {
    appConfirmModalObservable.set({
      open: true,
      onConfirm: resolve,
      onCancel: reject,
      ...props,
    });
  })
    .then(() => true)
    .catch(() => false)
    .finally(() => {
      appConfirmModalObservable.set({
        open: false,
        onConfirm: () => {},
        onCancel: () => {},
      });
    });
};

export const dialogService = {
  confirm,
};
