import { Origin, UxType } from '@logz-pkg/enums';
import { LoggerService } from '@logz-pkg/frontend-services';
import {
  alertModelManipulator,
  ElasticsearchEnhancedFilterModel,
  ElasticsearchFilterModel,
  IOpensearchDsl,
  Column,
} from '@logz-pkg/models';
import { classToPlain, plainToClass } from 'class-transformer';
import rison from 'rison-node';
import { smartSelectUtils } from '../Components/SmartSearch/SmartSelect/helpers/utils';
import { setExploreSearchParam } from '../router/router';
import { fieldMappingsService } from '../state/field-mappings.service';
import { getLuceneFilter } from '../Components/SmartSearch/LuceneInput/helpers/utils';

const updateExploreSearchParam = (filters: ElasticsearchEnhancedFilterModel[]) => {
  setTimeout(
    () =>
      setExploreSearchParam(
        filters.length === 0 ? { query: null } : { query: rison.encode_array(classToPlain(filters)) },
      ),
    0,
  );
};

class UrlParamsService {
  columnsToUrlParam = (columns: Column[]): string => (columns.length > 0 ? rison.encode(columns) : null);
  urlParamToColumns = (params: string): Column[] => (params ? rison.decode(params) : []);
  dslToUrlParam = (opensearchDsl: IOpensearchDsl): string => rison.encode(opensearchDsl);
  urlParamToDsl = (params: string): IOpensearchDsl => rison.decode(params);
  filtersToUrlParam = (filters: ElasticsearchEnhancedFilterModel[]): string => rison.encode_array(classToPlain(filters));
  dslToExploreParams = (opensearchDsl: IOpensearchDsl): string => {
    const filtersModel: ElasticsearchFilterModel[] = alertModelManipulator.convertElasticToFilters(opensearchDsl);
    const enhancedFilters: ElasticsearchEnhancedFilterModel[] = filtersModel.map(({ field, negate, value, type }) => {
      const modifiedType = type === 'IS' ? 'IS_ONE_OF' : type;
      const modifiedValue: any = type === 'IS' ? [value] : value;

      return smartSelectUtils.buildEnhancedFilter({ field, negate, value: modifiedValue, type: modifiedType });
    });
    const query = rison.encode_array(classToPlain(enhancedFilters));

    return query;
  };

  urlParamToFilters = (params: string): ElasticsearchEnhancedFilterModel[] => {
    if (!params) return [];

    let filters: ElasticsearchEnhancedFilterModel[] = [];

    try {
      filters = plainToClass(ElasticsearchEnhancedFilterModel, rison.decode_array(params));
    } catch (error) {
      LoggerService.logError({
        origin: Origin.APP,
        message: 'Can not decode params from URL',
        error,
        uxType: UxType.IN_PAGE,
        extra: {
          args: params,
        },
      });

      updateExploreSearchParam(filters);
    }

    let shouldUpdateQueryParams = false;

    // https://logzio.atlassian.net/browse/DEV-45364
    filters.forEach(f => {
      if (!f.field && f.type === 'LUCENE') {
        shouldUpdateQueryParams = true;
        f.field = fieldMappingsService.getField(f.value as string);
      }
    });

    if (shouldUpdateQueryParams) {
      updateExploreSearchParam(filters);
    }

    return filters;
  };

  luceneFiltersToUrlParam = (filters: ElasticsearchEnhancedFilterModel[]): ElasticsearchEnhancedFilterModel[] => {
    const aggregatedFilters = filters.reduce(
      (acc, curr) => {
        if (curr.type === 'LUCENE') {
          if (!acc.lucene?.[0]) {
            acc.lucene[0] = getLuceneFilter(`${curr.value}`, curr.invalid, curr.negate, curr.isDisabled);
          } else {
            const firstBracketCondition = !`${acc.lucene[0]?.value}`.includes('(');

            acc.lucene[0] = getLuceneFilter(
              `${firstBracketCondition ? '(' : ''}${acc.lucene[0]?.value}${firstBracketCondition ? ')' : ''} AND (${
                curr.value
              })`,
            );
          }
        } else {
          acc?.smart?.push(curr);
        }

        return acc;
      },
      { lucene: [], smart: [] },
    );

    return [...aggregatedFilters.lucene, ...aggregatedFilters.smart];
  };
}

export const urlParamsService = new UrlParamsService();
