import { Divider, FlexColumn, Group, Text, colors } from '@logz-ui/styleguide';
import React from 'react';
import styled from 'styled-components';
import { MessageType } from '../MessageManager';
import { AiChatGeneralUserBadge } from './UserBadge';

const Wrapper = styled(FlexColumn)`
  width: 95%;
  display: inline-block;
  word-wrap: break-word;
`;

const StyledText = styled(Text)<{ outOfContext }>`
  ${({ outOfContext }) =>
    outOfContext &&
    `
      opacity: 0.6;
    `}
`;
export const AiChatGeneralUser: React.FC<MessageType> = ({ content, outOfContext, isFirst }) => {
  return (
    <Group vertical width={'100%'} px={8}>
      <Group width={'100%'} pb={3}>
        <AiChatGeneralUserBadge />
        <Wrapper>
          <StyledText size={14} color={'royalBlue.1000'} weight={600} outOfContext={outOfContext}>
            {content}
          </StyledText>
        </Wrapper>
      </Group>
      {<Divider color={colors.gray[300]} pb={3} />}
    </Group>
  );
};
