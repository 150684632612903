import { FunctionComponent } from 'react';
import { Drawer } from '@logz-ui/styleguide';
import { HeaderSubjects } from '@logz-pkg/enums';
import { WhatsNew } from './WhatsNew';
import { useSessionState, useWhatsNewState } from 'ui/state/hooks';

export const WhatsNewDrawer: FunctionComponent = () => {
  const { isWhatsNewOpen, toggleWhatsNew } = useWhatsNewState();
  const { loggedInUser } = useSessionState();

  return (
    <Drawer
      name={'whatNew'}
      onBackdropClick={toggleWhatsNew}
      open={isWhatsNewOpen}
      direction={'right'}
      subject={HeaderSubjects.WhatsNewContainer}
    >
      <WhatsNew open={Boolean(loggedInUser) && isWhatsNewOpen} />
    </Drawer>
  );
};
