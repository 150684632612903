import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Card, Divider, Flex, Grid, If, Title, Toggle, Tooltip, LearnMoreLink } from '@logz-ui/styleguide';
import { useBoolean } from '@logz-pkg/react-hooks';
import { AuthenticationTypes, AwsS3BucketSubject, SendYourDataTestSubject } from '@logz-pkg/enums';
import { IAMBucketSettings, IAMBucketSettingsProps } from './interfaces';
import { getValidationByBucketType } from './validation';
import {
  ARNField,
  AssumeRoleInformation,
  DeleteModal,
  IAMBucketField,
  PolicyChangedMessage,
  PrefixField,
  RolePolicyModal,
  SelectLogTypeField,
  SelectRegionField,
  ViewAndCopyPolicyButton,
} from './components';
import { IncludeSourcePathField } from './components/IncludeSourcePathField';

export const IAMSettings: React.FunctionComponent<IAMBucketSettingsProps> = ({
  settings,
  bucketType,
  regions,
  supportedLogTypes,
  assumeRoleDetails,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [logtype, setLogtype] = useState(settings.logsType);
  const [isPolicyCopied, setIsPolicyCopied] = useState(false);
  const [shouldDisplayCopyMessage, setShouldDisplayCopyMessage] = useState(false);
  const isSaved = Boolean(settings.id);
  const validationSchema = getValidationByBucketType(bucketType, AuthenticationTypes.IAM);
  const { value: isDeleteModalOpen, setTrue: openDeleteModal, setFalse: closeDeleteModal } = useBoolean(false);
  const { value: isPolicyModalOpen, setTrue: openPolicyModal, setFalse: closePolicyModal } = useBoolean(false);

  const handleFormSubmission = async (values, actions): Promise<void> => {
    await onSave(values);
    actions.setSubmitting(false);
  };

  const handleBucketNameChanged = (handleChange, event) => {
    if (isPolicyCopied) {
      setShouldDisplayCopyMessage(true);
    }

    handleChange(event);
  };

  const handlePolicyCopied = () => {
    closePolicyModal();
    setIsPolicyCopied(true);

    if (shouldDisplayCopyMessage) {
      setShouldDisplayCopyMessage(false);
    }
  };

  const handleIsActiveToggled = async (isActiveValue: boolean, formValues: IAMBucketSettings, setFieldValue) => {
    // This isn't a Formik Field because our Toggle doesn't play well with its onChange.
    setFieldValue('active', isActiveValue);

    if (isSaved) {
      // Manually change field until setFieldValue will return a promise https://github.com/jaredpalmer/formik/issues/529
      formValues.active = isActiveValue;

      await onSave(formValues);
    }
  };

  return (
    <Card
      p={6}
      className={`log-shipping__${bucketType}-settings`}
      context={AwsS3BucketSubject.BucketSettingsCard}
      subject={settings.bucket}
    >
      <Formik onSubmit={handleFormSubmission} initialValues={settings} validationSchema={validationSchema}>
        {({ errors, values, touched, handleChange, setFieldValue, submitForm, isSubmitting }) => (
          <>
            <Form>
              <Grid columns={12}>
                <Grid.Cell span={9}>
                  <Title variant={'deprecatedDialogSmall'} inline mr={1} my={0}>
                    IAM role configuration
                  </Title>
                  <LearnMoreLink about={'https://docs.logz.io/user-guide/give-aws-access-with-iam-roles/'} />
                </Grid.Cell>
                <Grid.Cell last>
                  <Flex justifyContent={'flex-end'}>
                    <Toggle
                      name={'active'}
                      label={values.active ? 'Active' : 'Inactive'}
                      value={values.active}
                      subject={AwsS3BucketSubject.BucketActiveToggle}
                      onChange={isActive => handleIsActiveToggled(isActive, values, setFieldValue)}
                    />
                  </Flex>
                </Grid.Cell>
                <Grid.Cell span={12}>
                  <Divider dashed />
                </Grid.Cell>
                <If condition={!isSaved}>
                  <Grid.Cell span={12}>
                    <AssumeRoleInformation
                      logzioAWSAccountId={assumeRoleDetails.logzioAWSAccountId}
                      assignedExternalId={assumeRoleDetails.assignedExternalId}
                    />
                  </Grid.Cell>
                </If>
                <Grid.Cell span={6}>
                  <IAMBucketField
                    value={values.bucket}
                    touched={touched}
                    errors={errors}
                    isDisabled={isSaved}
                    onChange={event => handleBucketNameChanged(handleChange, event)}
                  />
                </Grid.Cell>
                <Grid.Cell span={6}>
                  <PrefixField
                    isDisabled={isSaved}
                    bucketType={bucketType}
                    authenticationType={AuthenticationTypes.IAM}
                  />
                </Grid.Cell>
                <If condition={!isSaved}>
                  <Grid.Cell span={4}>
                    <ViewAndCopyPolicyButton handleClick={openPolicyModal} isDisabled={!values.bucket} />
                  </Grid.Cell>
                  <If condition={shouldDisplayCopyMessage}>
                    <Grid.Cell>
                      <PolicyChangedMessage />
                    </Grid.Cell>
                  </If>
                </If>
                <Grid.Cell span={12}>
                  <ARNField isDisabled={isSaved} />
                </Grid.Cell>
                <If condition={Boolean(regions)}>
                  <Grid.Cell span={6}>
                    <SelectRegionField disabled={isSaved} options={regions} />
                  </Grid.Cell>
                </If>
                <If condition={Boolean(supportedLogTypes)}>
                  <SelectLogTypeField
                    isDisabled={isSaved}
                    setFieldValue={setFieldValue}
                    options={supportedLogTypes}
                    selectedLogType={logtype}
                    setLogType={setLogtype}
                  />
                </If>
                <Grid.Cell span={12}>
                  <IncludeSourcePathField isDisabled={isSaved} />
                </Grid.Cell>
                <If condition={!isSaved}>
                  <Grid.Cell>
                    <Button
                      onClick={submitForm}
                      loading={isSubmitting}
                      subject={SendYourDataTestSubject.SaveBucketButton}
                    >
                      Save
                    </Button>
                  </Grid.Cell>
                  <Grid.Cell>
                    <Button variant={'tertiary'} onClick={() => onCancel(settings.appOnlyId)}>
                      Cancel
                    </Button>
                  </Grid.Cell>
                </If>
                <If condition={isSaved}>
                  <Grid.Cell width={100 / 100}>
                    <Flex justifyContent={'flex-end'}>
                      <Tooltip title="Delete">
                        <Button
                          round
                          icon={'trash-can-regular'}
                          onClick={openDeleteModal}
                          subject={AwsS3BucketSubject.DeleteBucketButton}
                        />
                      </Tooltip>
                    </Flex>
                  </Grid.Cell>
                </If>
              </Grid>
            </Form>
            <If condition={isDeleteModalOpen}>
              <DeleteModal
                onConfirm={async () => {
                  closeDeleteModal();
                  await onDelete(settings.id, settings.logsType);
                }}
                onClose={closeDeleteModal}
                authType={AuthenticationTypes.IAM}
              />
            </If>
            <If condition={isPolicyModalOpen}>
              <RolePolicyModal
                onConfirm={handlePolicyCopied}
                onClose={closePolicyModal}
                bucketName={values.bucket}
                actions={['getObject', 'list', 'getLocation']}
              />
            </If>
          </>
        )}
      </Formik>
    </Card>
  );
};
