import { QueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        placeholderData: prev => prev,
        gcTime: moment.duration(1, 'minute').asMilliseconds(),
      },
    },
  });
}
