import { Button, Text, Group, Icon, If, Box } from '@logz-ui/styleguide';
import { useImpersonation } from 'ui/components/Impersonation/impersonation.hook';

export const ImpersonationBar = () => {
  const { username, editAccountUrl, isError, isImpersonating, stopImpersonation } = useImpersonation();
  const shouldShow = isImpersonating && username && !isError;
  const handleEditAccountSettingsClick = () => {
    window.open(editAccountUrl === null ? undefined : editAccountUrl, '_blank');
  };

  return (
    <If condition={shouldShow}>
      <Box subject={'impersonation-header'} px={4} py={2}>
        <Group justifyContent={'space-between'} alignItems={'center'} fullWidth>
          <Group alignItems={'center'}>
            <Icon icon={'diamond-exclamation-solid'} size={18} />
            <Text size={16} mb={0} color={'gray.1000'}>
              You are impersonating {username}
            </Text>
          </Group>
          <Group gap={3}>
            <Button variant={'danger'} icon={'stop-solid'} onClick={stopImpersonation} subject={'stop-impersonation'}>
              Stop Impersonation
            </Button>
            <Button variant={'danger'} icon={'wrench-regular'} onClick={handleEditAccountSettingsClick}>
              Edit Account Settings
            </Button>
          </Group>
        </Group>
      </Box>
    </If>
  );
};
