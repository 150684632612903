import { FC } from 'react';
import { colors, Flex, Icon, Text } from '@logz-ui/styleguide';
import styled from 'styled-components';

const BadgeWrapper = styled(Flex)`
  position: absolute;
  bottom: 10px;
  transform: translateX(50%);
  right: 50%;
  padding: 4px 8px;
  border-radius: 20px;
  cursor: pointer;
  color: ${colors.gray[200]};

  background-color: ${colors.gray[700]};
`;

interface IProps {
  onClick: () => void;
  isOpen: boolean;
  linesCount: number;
}

export const CollapsableCodeBadge: FC<IProps> = ({ onClick, isOpen, linesCount }) => {
  return (
    <BadgeWrapper opacity={0} onClick={onClick} alignItems={'center'}>
      <Icon icon={isOpen ? 'minus-regular' : 'plus-regular'} />
      <Text color={colors.gray[200]} ml={2}>
        {isOpen ? 'Collapse' : ` Click to expand (${linesCount} lines)`}
      </Text>
    </BadgeWrapper>
  );
};
