import { Text, Icon, Group } from '@logz-ui/styleguide';

export const PolicyChangedMessage = () => (
  <Group alignItems="center">
    <Icon icon={'triangle-exclamation-solid'} color={'orange.400'} />
    <Text color={'red.700'} mb={0}>
      This policy changed since you last copied it
    </Text>
  </Group>
);
