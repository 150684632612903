export const jaegerUrlQueryParams = [
  'limit',
  'lookback',
  'maxDuration',
  'minDuration',
  'operation',
  'service',
  'start',
  'end',
  'tags',
  'cohort',
  'traceID',
  'endTs',
  'quantile',
  'ratePer',
  'step',
] as const;
