import { FunctionComponent, useEffect, useState } from 'react';
import { ISelectOption, Select, SelectDecoratedOption } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { Icon } from '@logz-ui/styleguide/components';
import { AccountModel } from '@logz-pkg/models';
import { dashboardHeaderService } from '../../../../../../app/dashboard/dashboard-header/dashboard-header.service';
import { SelectSearchIndicator } from './components/SearchIndicator';
import { inputCss, menuCss } from './account-select.styles';
import { AccountPin, HoverWrapper } from './AccountPin';
import { useSessionState } from 'ui/state/hooks';

interface IAccountSelectProps {
  accounts: AccountModel[];
  pinnedAccounts: AccountModel['id'][];
  onChange(): void;
  onPinChange(accountId: AccountModel['id'], pin: boolean): void;
}

const StyledSelect = styled(Select)`
  background: ${({ theme }) => theme.colors.gray[100]};
  ${inputCss};
  ${menuCss};
`;

const StyledSpan = styled.span`
  margin-left: ${({ theme }) => theme.space[3]}px;
`;

const mapAccountsToSelectOptions = (accounts: AccountModel[]): ISelectOption<AccountModel['id']>[] =>
  accounts.map(account => ({ label: account.name, value: account.id }));

const sortByLabel = (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

const isPinnedForSort = (accountId: number, starred) => (starred?.includes(accountId) ? 1 : -1);

const getSorterByPinned = starred => (a, b) => isPinnedForSort(b.value, starred) - isPinnedForSort(a.value, starred);

export const AccountSelect: FunctionComponent<IAccountSelectProps> = ({
  accounts,
  pinnedAccounts,
  onChange,
  onPinChange,
}) => {
  const { loggedInAccount } = useSessionState();
  const [selectedAccount, setSelectedAccount] = useState<ISelectOption<number> | { value: null; label: null }>({
    value: null,
    label: null,
  });
  const [accountOptions, setAccountOptions] = useState<ISelectOption<AccountModel['id']>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isPinned = accountId => pinnedAccounts?.includes(accountId);

  useEffect(() => {
    setSelectedAccount({
      value: loggedInAccount?.id,
      label: loggedInAccount?.name,
    });
  }, [loggedInAccount]);

  useEffect(() => {
    if (!accounts) {
      return;
    }

    const options = mapAccountsToSelectOptions(accounts);
    const sortedOptions = options.sort(sortByLabel).sort(getSorterByPinned(pinnedAccounts));

    setAccountOptions(sortedOptions);
  }, [accounts, pinnedAccounts]);

  const handleChange = async (option: ISelectOption<number>) => {
    setIsLoading(true);
    await dashboardHeaderService.switchAccount({ accountId: option.value });

    setIsLoading(false);
    setSelectedAccount(option);
    onChange();
  };

  const OptionElement = ({ label, value }) => (
    <HoverWrapper justifyContent={'space-between'} alignItems={'center'} width={'100%'} py={2} pl={3} pr={4}>
      <SelectDecoratedOption
        label={label}
        decoration={value === selectedAccount.value ? <Icon icon={'check-solid'} /> : <StyledSpan />}
        ellipsis
      />
      <AccountPin accountId={value} isPinned={isPinned(value)} onChange={onPinChange} />
    </HoverWrapper>
  );

  return (
    <StyledSelect
      isLoading={isLoading}
      subject={'account-select'}
      value={selectedAccount}
      options={accountOptions}
      onChange={handleChange}
      noOptionsMessage={() => 'No accounts found'}
      size={'medium'}
      placeholder={'Search for an account'}
      menuIsOpen
      autoFocus
      OptionElement={OptionElement}
      controlShouldRenderValue={false}
      components={{ DropdownIndicator: SelectSearchIndicator }}
    />
  );
};
