import React from 'react';
import { Group, Flex } from '@logz-ui/styleguide';
import { isEmpty } from 'lodash';
import { useAiInitialQuestions } from '../../state/initial-questions/initial-questions.hook';
import { useAiAnalytics } from '../../hooks/analytics.hook';
import { useAiFetchData } from '../../state/fetch-data/fetch-data.hook';
import { useAiState } from '../../state/ai-state.hook';
import { AiHotkeys } from './AiHotkeys';
import { QuestionSection } from 'ui/components/AI/Components/EmptyState/QuestionSection';
import { AiChatGeneralResultsInfo } from 'ui/components/AI/Components/Messages/General/ResultsInfo';

export const HelpSection: React.FC = () => {
  const { initialQuestions, refetchDynamicQuestions, isLoadingDynamicQuestions } = useAiInitialQuestions();
  const { report } = useAiAnalytics();
  const { handleSubmit } = useAiFetchData();
  const { isDrawerOpen } = useAiState();

  const onClickQuestionCard = async (questionText: string) => {
    report('Clicked help question', { question: questionText });

    handleSubmit({ prompt: questionText });
  };

  if (isEmpty(initialQuestions)) return null;

  return (
    <>
      <Flex width={'100%'} justifyContent={'center'}>
        <Group vertical flexWrap={'wrap'} justifyContent={'center'} gap={10} width={'340px'}>
          {Object.keys(initialQuestions).map(questionSection => {
            return (
              <QuestionSection
                handleRefreshDynamicQuestions={refetchDynamicQuestions}
                isFetching={isLoadingDynamicQuestions}
                questionSection={questionSection}
                key={questionSection}
                questionInformationArray={initialQuestions[questionSection]}
                onClickQuestionCard={onClickQuestionCard}
              />
            );
          })}

          <AiChatGeneralResultsInfo />
        </Group>
      </Flex>
      {isDrawerOpen && <AiHotkeys initialQuestions={initialQuestions} onClickQuestionCard={onClickQuestionCard} />}
    </>
  );
};
