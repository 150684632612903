import { ServerStreamDataChunk, ChatResponse } from '@logz-pkg/models';
import { serverStreamApiService, eventEmitter, EVENTS } from '@logz-pkg/frontend-services';

type ServerStreamParams = {
  additionalData?: {
    [key: string]: any;
    message?: {
      content?: unknown;
      [key: string]: any;
    };
  };
  streamUrl?: string;
  streamSseUrl?: string;
  handleResponse: ({
    response,
    reqIndex,
    shouldStart,
  }: {
    response: ChatResponse;
    reqIndex?: number;
    shouldStart?: boolean;
  }) => Promise<{ shouldStop: boolean; reqIndex: number }>;
  sessionId?: string;
  tabSessionId?: string;
  forceStopEventName?: string;
  initialPayload?: Record<string, any>;
  onRequest?: ({ reqIndex }: { reqIndex: number }) => Record<string, any>;
  onData?: (data: ServerStreamDataChunk, isDone?: boolean, isConclusion?: boolean) => void;
  onEnd?: (reason?: ServerStreamDataChunk) => void;
  onError?: (error: ServerStreamDataChunk) => void;
  sse?: boolean;
};

const startPolling = async ({ additionalData, streamUrl, handleResponse }: ServerStreamParams) => {
  let reqIndex = 0;
  let shouldStart = true;
  let forceStop = false;

  const stopPolling = () => (forceStop = true);

  eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopPolling);

  const poll = async () => {
    let content, noContentMessage;

    if (additionalData?.message?.content) {
      ({ content, ...noContentMessage } = additionalData.message);
    } else {
      noContentMessage = additionalData.message || {};
    }

    const response = await serverStreamApiService.doPolling({
      streamUrl,
      payload: JSON.stringify({
        ...additionalData,
        message: { ...noContentMessage, ...(shouldStart ? { content } : {}) },
        shouldStart,
        reqIndex,
      }),
    });

    if (forceStop) return;

    shouldStart = false;

    const { shouldStop, reqIndex: newReqIndex } = await handleResponse({
      shouldStart,
      response,
      reqIndex,
    });

    reqIndex = newReqIndex;

    if (!shouldStop) await poll();
  };

  try {
    await poll();
  } finally {
    eventEmitter.off(EVENTS.STOP_LAST_REQUEST, stopPolling);
  }
};

const startSse = async ({
  streamUrl,
  sessionId,
  tabSessionId,
  onRequest,
  onData,
  onError,
  onEnd,
}: ServerStreamParams): Promise<string[]> => {
  const content = [];

  try {
    const payload = onRequest ? onRequest({ reqIndex: 0 }) : {};

    await serverStreamApiService.doSse({
      streamUrl,
      payload: { ...payload, sessionId, tabSessionId },
      onData: (chunk: ServerStreamDataChunk) => {
        onData({ status: 'data', ...chunk });
        content.push(chunk.message);
      },
    });

    onEnd({ status: 'done' });
  } catch (error) {
    const { message, type } = error;

    type === 'timeout' ? onEnd({ status: 'timeout', message }) : onError({ status: 'error', message });
  }

  return content;
};

export const serverStreamUtils = {
  startPolling,
  startSse,
};
