import { PerformanceService, PerformanceEventMeta } from '@logz-pkg/utils';
import { PerformanceEventName } from './types';
import { analyticsService } from 'services/analytics/analytics.service';

class PerformanceAnalyticsService extends PerformanceService<PerformanceEventName> {
  private lastSentEvents = {};

  async stopAndSendAmplitudeEvent(name: PerformanceEventName, sendMeta?: PerformanceEventMeta) {
    const data = this.getMeasurement(name, sendMeta);

    if (!data) return;

    this.lastSentEvents[name] = data;

    analyticsService.capture('Global', 'App performance measured', data);
  }

  isSent(name: PerformanceEventName) {
    return !!this.lastSentEvents[name];
  }
}

export const performanceAnalyticsService = new PerformanceAnalyticsService();
