import { FunctionComponent, useEffect, useState } from 'react';
import { Title, Modal, Text, Group, Button } from '@logz-ui/styleguide';
import { WelcomePageTestSubject } from '@logz-pkg/enums';
import { usePlaygroundState } from 'ui/state/hooks/playground.state.hook';

export const PlaygroundModal: FunctionComponent = () => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const { isPlayground, setPlaygroundModalOpen } = usePlaygroundState();

  useEffect(() => {
    if (isPlayground) setDisplayModal(true);
  }, [isPlayground]);

  if (!displayModal) return null;

  return (
    <Modal
      open={true}
      onClose={() => setDisplayModal(false)}
      noCloseButton
      dontCloseOnOverlayClick
      subject={WelcomePageTestSubject.PlaygroundModal}
    >
      <Modal.Header>
        <Title size={24} color={'blue.1000'}>
          Welcome to Logz.io’s sandbox! 👋
        </Title>
      </Modal.Header>
      <Modal.Body flexDirection={'column'}>
        <Group gap={4} vertical>
          <Text size={16} color={'gray.800'}>
            Experience Logz.io through our interactive sandbox.
          </Text>
          <Text size={16} color={'gray.800'}>
            Monitor demo logs, metrics, and traces and gain insights into our made-up issues and scenarios.
          </Text>
          <Text size={16} color={'gray.800'}>
            Enjoy!
          </Text>
        </Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setPlaygroundModalOpen(false)}>Let me explore</Button>
      </Modal.Footer>
    </Modal>
  );
};
