import { AIDataSource } from '@logz-pkg/enums';
import { StateDeclaration } from '@uirouter/react';
import { HelpQuestionsType } from '../../types';
import { shippingHelpQuestions, userGuideHelpQuestions } from 'ui/components/Integrations/hooks/integrations-ai.service';
import { exploreRouteNames } from 'states/explore/explore.route.names';
import { exploreHelpQuestions } from 'ui/components/Explore/constants';
import { k8sHelpQuestions } from 'ui/kube360/constants';
import { observabilityRoutesNames } from 'states/observability/observability.routes.names';
import { spmRouteNames } from 'states/spm.routes';
import { serviceOverviewHelpQuestions } from 'ui/components/Spm/constants';
import { grafanaHelpQuestions } from 'ui/components/Grafana/constants';

export const aiDataSourceBasedQuestions: Partial<
  Record<AIDataSource, Partial<Record<StateDeclaration['name'] | 'default', HelpQuestionsType>>>
> = {
  [AIDataSource.CurrentPageSearch]: {
    [exploreRouteNames.explore]: exploreHelpQuestions.assistant,
    [observabilityRoutesNames.k8s360]: k8sHelpQuestions.assistant,
    [spmRouteNames.baseRoute]: serviceOverviewHelpQuestions,
    [spmRouteNames.serviceOverview]: serviceOverviewHelpQuestions,
  },
  [AIDataSource.CurrentPageAgent]: {
    [exploreRouteNames.explore]: exploreHelpQuestions.assistant,
    [observabilityRoutesNames.k8s360]: k8sHelpQuestions.assistant,
    [spmRouteNames.baseRoute]: serviceOverviewHelpQuestions,
    [spmRouteNames.serviceOverview]: serviceOverviewHelpQuestions,
  },
  [AIDataSource.AllAvailableData]: {
    [exploreRouteNames.explore]: exploreHelpQuestions.pro,
    [observabilityRoutesNames.k8s360]: k8sHelpQuestions.pro,
  },
  [AIDataSource.KnowledgeBaseShipping]: {
    default: shippingHelpQuestions,
  },
  [AIDataSource.KnowledgeBaseUserGuide]: {
    default: userGuideHelpQuestions,
  },
  [AIDataSource.MetricsDashboard]: {
    default: grafanaHelpQuestions.pro,
  },
};
