import { Spinner } from '@logz-ui/styleguide';
import { AppStateDeclaration } from '../AppStateDecleration';
import {
  forgotPasswordRouteName,
  forgotPasswordValidateRouteName,
  loginRouteName,
  multiFactorLoginRouteName,
  registerRouteName,
  ssoLoginRouteName,
  validateResetPasswordRouteName,
  signupSuccessRouteName,
} from './auth.routes.names';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { appRouter } from 'services/router/router.service';
import { logoutService } from 'core/auth/services/logout.service';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';

export const loginRouteState: AppStateDeclaration = {
  name: loginRouteName,
  url: '/login?accountId&id&token&timestamp&email&error&awsMarketplaceToken&redirect',
  component: props => (
    <LazyReact
      lazyImport={() => import('ui/components/Auth/Login/UserAndPasswordLoginForm')}
      resolver={({ UserAndPasswordLoginForm }) => UserAndPasswordLoginForm}
      {...props}
    />
  ),
  data: {
    onlyAnonymous: true,
  },
  params: {
    messageTitle: null,
    messageBody: null,
    accountId: null,
    id: null,
    token: null,
    timestamp: null,
    email: null,
    error: null,
    awsMarketplaceToken: null,
    redirect: null,
  },
};

export const multiFactorLoginRouteState: AppStateDeclaration = {
  name: multiFactorLoginRouteName,
  url: '/mfa?accountId&id&token&timestamp&error&awsMarketplaceToken&redirect',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() =>
        import('ui/components/Auth/Login/MultiFactorLoginForm').then(({ MultiFactorLoginForm }) => MultiFactorLoginForm)
      }
    />
  ),
  data: {
    onlyAnonymous: true,
  },
  params: {
    messageTitle: null,
    messageBody: null,
    id: null,
    token: null,
    timestamp: null,
    email: null,
    password: null,
    error: null,
    awsMarketplaceToken: null,
    qrCodeUrl: null,
    redirect: null,
  },
};

export const ssoLoginRouteState: AppStateDeclaration = {
  name: ssoLoginRouteName,
  url: '/sso-login?accountId&email&error&awsMarketplaceToken&redirect',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() => import('ui/components/Auth/Sso/SsoLogin')}
      resolver={({ SsoLogin }) => SsoLogin}
    />
  ),
  data: {
    onlyAnonymous: true,
  },
  params: {
    messageTitle: null,
    messageBody: null,
    redirect: null,
    email: null,
  },
};

export const registerRouteState: AppStateDeclaration = {
  name: registerRouteName,
  url: '/signup?socialSignup?email&password&fullName&company&phone&jwt&error&awsMarketplaceToken',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() => import('ui/components/Auth/Register/Register')}
      resolver={({ Register }) => Register}
    />
  ),
  data: {
    onlyAnonymous: true,
  },
  params: {
    messageTitle: null,
    messageBody: null,
  },
};

export const forgotPasswordRouteState: AppStateDeclaration = {
  name: forgotPasswordRouteName,
  url: '/forgot-password?email&awsMarketplaceToken',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() =>
        import('ui/components/Auth/ForgotPassword/ForgotPassword').then(({ ForgotPassword }) => ForgotPassword)
      }
    />
  ),
  params: {
    messageTitle: null,
    messageBody: null,
  },
  data: {
    onlyAnonymous: true,
  },
};

export const forgotPasswordValidateRouteState: AppStateDeclaration = {
  name: forgotPasswordValidateRouteName,
  url: '/forgot-password/validate/:token?awsMarketplaceToken',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() =>
        import('ui/components/Auth/ForgotPassword/ValidateResetPassword').then(
          ({ ValidateResetPassword }) => ValidateResetPassword,
        )
      }
    />
  ),
  params: {
    token: null,
  },
  resolve: [
    {
      token: 'isNewUser',
      resolveFn: () => {
        return false;
      },
    },
  ],
  data: {
    onlyAnonymous: true,
  },
};

export const validateResetPasswordState: AppStateDeclaration = {
  name: validateResetPasswordRouteName,
  url: '/new-user/validate/:token?email&awsMarketplaceToken',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() =>
        import('ui/components/Auth/ForgotPassword/ValidateResetPassword').then(
          ({ ValidateResetPassword }) => ValidateResetPassword,
        )
      }
    />
  ),
  params: {
    token: null,
    email: null,
  },
  resolve: [
    {
      token: 'isNewUser',
      resolveFn: () => {
        return true;
      },
    },
  ],
  data: {
    onlyAnonymous: true,
  },
};

export const logoutState: AppStateDeclaration = {
  name: 'dashboard.logout',
  url: '/logout',
  data: { allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value] },
  component: () => <Spinner fullscreen title={'Logging out'} />,
  redirectTo: () => {
    if (!logoutService.authFlags.isLogoutInProgress) {
      return appRouter.globals.current.name;
    }
  },
};

export const verifyInvitationState: AppStateDeclaration = {
  name: 'verify-invitation',
  url: '/verify-invitation/:token',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() =>
        import('ui/components/Auth/VerifyInvitation/VerifyInvitation').then(({ VerifyInvitation }) => VerifyInvitation)
      }
    />
  ),
  params: {
    token: null,
  },
};

export const signupSuccessState: AppStateDeclaration = {
  name: signupSuccessRouteName,
  url: '/signup-success',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() =>
        import('ui/components/Auth/VerifyEmail/SignupSuccess').then(({ SignupSuccess }) => SignupSuccess)
      }
    />
  ),
  data: {
    onlyAnonymous: true,
  },
};

export const verifyEmailState: AppStateDeclaration = {
  name: 'verify-email',
  url: '/verify-email/:token',
  component: props => (
    <LazyReact
      {...props}
      lazyImport={() => import('ui/components/Auth/VerifyEmail/VerifyEmail').then(({ VerifyEmail }) => VerifyEmail)}
    />
  ),
  params: {
    token: null,
  },
  data: {
    onlyAnonymous: true,
  },
};
