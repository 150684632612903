import { Button, Group, INotificationOptions, NotificationService } from '@logz-ui/styleguide';
import { broadcasterService, BroadcastMessage } from '@logz-pkg/frontend-services';
import { ExploreWarmTier } from '@logz-pkg/enums';
import { isTabActive } from 'ui/hooks/tabVisibility.hook';
import { analyticsService } from 'services/analytics/analytics.service';

const eventTypeMap: Record<
  BroadcastMessage['data']['type'],
  Extract<keyof typeof NotificationService, 'success' | 'danger'>
> = {
  success: 'success',
  error: 'danger',
  timeout: 'danger',
};

const eventNotificationMapper: (
  exploreLink: string,
) => Record<BroadcastMessage['data']['type'], INotificationOptions> = exploreLink => {
  const successNotification: INotificationOptions = {
    title: 'Warm Data successfully retrieved',
    message: 'Your query results and data are now available.',
    autoHide: true,
    duration: 8,
    children: (
      <Button
        subject={ExploreWarmTier.WarmDataSuccessfullyRetrieveNotification}
        icon="arrow-up-right-regular"
        onClick={() => {
          window.location.href = exploreLink;

          analyticsService.capture(
            'Warm tier notification',
            'Viewed data in Explore after a successful Warm tier query',
          );

          NotificationService.destroy();
        }}
      >
        View data in Explore
      </Button>
    ),
  };

  const genericErrorNotification: INotificationOptions = {
    title: 'An error occurred while retrieving data',
    message: 'Something went wrong while processing your query. Try again or contact support if the issue persists.',
    autoHide: true,
    duration: 8,
    children: (
      <Group alignItems={'center'}>
        <Button
          icon="arrow-up-right-regular"
          onClick={() => {
            analyticsService.capture(
              'Warm tier notification',
              'Adjusted and retried after a Warm tier query general error',
            );

            NotificationService.destroy();
            window.open(exploreLink, '_blank');
          }}
        >
          Adjust and retry in Explore
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            analyticsService.capture('Warm tier notification', 'Dismissed Warm tier query general error message');

            NotificationService.destroy();
          }}
        >
          Dismiss
        </Button>
      </Group>
    ),
  };

  const timeoutNotification: INotificationOptions = {
    title: 'Warm Data retrieval timed out',
    message: 'The query took too long to process. Try adjusting the time range or edit your query',
    autoHide: true,
    duration: 8,
    children: (
      <Group alignItems={'center'}>
        <Button
          icon="arrow-up-right-regular"
          onClick={() => {
            analyticsService.capture('Warm tier notification', 'Adjusted and retried after a Warm tier query timeout');

            NotificationService.destroy();
            window.open(exploreLink, '_blank');
          }}
        >
          Adjust and retry in Explore
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            analyticsService.capture('Warm tier notification', 'Dismissed Warm tier query timeout message');

            NotificationService.destroy();
          }}
        >
          Dismiss
        </Button>
      </Group>
    ),
  };

  return {
    success: successNotification,
    error: genericErrorNotification,
    timeout: timeoutNotification,
  };
};

export const bootstrapBroadcastChannel = () => {
  broadcasterService.createChannel('explore');
  broadcasterService.setOnMessageHandler('explore', event => {
    if (isTabActive) {
      const eventType = eventTypeMap[event.data.type];
      const notificationServiceConfig = eventNotificationMapper(event.data.exploreLink)[event.data.type];

      NotificationService[eventType](notificationServiceConfig);
    }
  });
};
