import moment from 'moment';
import { timeFrameInterval } from '@logz-pkg/enums';
import { dateToAbsolute, intervalCalculatorService, RelativeValue } from '@logz-pkg/utils';

export type HistogramPoint = {
  key?: number;
  time: string;
  count: number;
  unique?: number;
};

export const timerangeQueryHelpers = {
  getRangeFilter: (since: string) => ({
    range: {
      '@timestamp': {
        gte: `now-${since}`,
        lte: 'now',
      },
    },
  }),
  getDateHistogram: (since: string) => ({
    date_histogram: {
      field: '@timestamp',
      calendar_interval: intervalCalculatorService.step({
        start: dateToAbsolute(`now-${since}` as RelativeValue),
        end: dateToAbsolute('now'),
      }),
      min_doc_count: 0,
      extended_bounds: {
        min: `now-${since}`,
        max: 'now',
      },
    },
  }),
  getUniquesCount: (field: string) => ({
    aggs: {
      uniques: {
        cardinality: {
          field,
        },
      },
    },
  }),
  flattenHistogramPoints: buckets => {
    return buckets.map(({ doc_count: count, key, key_as_string: time, ...rest }) => {
      const histogramPoint: HistogramPoint = {
        key,
        time,
        count,
      };

      const { uniques } = rest;

      if (uniques) {
        histogramPoint.unique = uniques.value;
      }

      return histogramPoint;
    });
  },
  getStartEndDates: (since: string): { start: number; end: number } => {
    const sinceNumber = since.toString().replace(/[^0-9]/g, '');

    const endT = moment().startOf('minutes');
    const interval = timeFrameInterval[since].replace(/[^0-9]/g, '');
    const subtractedMinutes = endT.minute() % parseInt(interval, 10);
    const neededEnd = endT.subtract(subtractedMinutes, 'minutes');

    return {
      end: neededEnd.unix(),
      start: moment(neededEnd).subtract(sinceNumber, 'hours').unix(),
    };
  },
};
