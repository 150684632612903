import { FunctionComponent } from 'react';
import { Text, Group } from '@logz-ui/styleguide';
import { HeaderSubjects } from '@logz-pkg/enums';

export const WhatsNewAnnouncementEmptyState: FunctionComponent = () => (
  <Text size={18} variant={'faded'} mb={5} subject={HeaderSubjects.WhatNewEmptyState}>
    <Group vertical alignItems={'center'}>
      <span>No announcements found</span>
    </Group>
  </Text>
);
