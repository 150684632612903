import { FunctionComponent } from 'react';
import { Bar, Button } from '@logz-ui/styleguide';
import { PlanAndBillingState, Product } from '@logz-pkg/enums';
import { tracingRouteStates } from '../../../../app/states/tracing/tracing-sampling.routes.names';
import { ViewTutorialButton } from './ViewTutorialButton';
import { appRouter } from 'services/router/router.service';
import { useTracingState } from 'ui/state/hooks';
import { tracingService } from 'services/tracing/tracing.service';
import { FilterTagNames, goToIntegrationsPage } from 'ui/components/Integrations/utils';

export const TracingTrialBar: FunctionComponent = () => {
  const handleUpgrade = () => {
    appRouter.stateService.go(PlanAndBillingState.Plan, { productToNavigateTo: Product.Tracing });
  };

  const { tracingBudgetState } = useTracingState();

  const handleUpload = () => {
    goToIntegrationsPage({ tags: FilterTagNames.TRACING });
  };

  const handleViewTutorial = () => {
    appRouter.stateService.go(tracingRouteStates.jaeger);
  };

  return (
    <Bar expanded>
      <Bar.LeftActions>
        <ViewTutorialButton onClick={handleViewTutorial} />
      </Bar.LeftActions>
      <Bar.Title>Tracing trial is active</Bar.Title>
      <Bar.CallToAction>
        <Bar.TrialDaysText daysLeft={tracingService.getTrialDaysLeft(tracingBudgetState)} />
        <Button variant={'secondary'} onClick={handleUpload}>
          Upload data
        </Button>
        <Button variant={'secondary'} onClick={handleUpgrade}>
          Upgrade
        </Button>
      </Bar.CallToAction>
    </Bar>
  );
};

TracingTrialBar.displayName = 'TracingTrialBar';
