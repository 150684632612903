import { BillingType } from '@logz-pkg/enums';
import { highchartsLazyLoadService } from '@logz-ui/graphs';
import { cacheProvider } from '@logz-pkg/frontend-services';
import { LazyAngular } from './LazyAngular';
import { redirectIfNoPermission, redirectIfNotAdmin } from './utils';
import { settingsRoutesStates } from './settings.routes.names';
import { AppStateDeclaration } from './AppStateDecleration';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { accountPlanStateService } from 'ui/state/account-plan.state.service';

const LazyGeneralSettings = props => (
  <LazyReact
    lazyImport={() => import('ui/components/GeneralSettings/GeneralSettings')}
    resolver={({ GeneralSettings }) => GeneralSettings}
    {...props}
  />
);

const LazyUserManagement = props => (
  <LazyReact
    lazyImport={() => import('ui/components/UserManagement/UserManagement')}
    resolver={({ UserManagement }) => UserManagement}
    {...props}
  />
);

const LazyLogsDropFilters = props => (
  <LazyReact
    lazyImport={() => import('ui/components/LogsDropFilters/LogsDropFilters')}
    resolver={({ LogsDropFilters }) => LogsDropFilters}
    {...props}
  />
);

const LazyAuditTrail = props => (
  <LazyReact
    lazyImport={() => import('../../ui/components/AuditTrail/AuditTrail')}
    resolver={({ AuditTrailContainer }) => AuditTrailContainer}
    {...props}
  />
);

const LazyManageConsumptionAccounts = props => (
  <LazyReact
    lazyImport={() => import('ui/components/ManageConsumptionAccounts/ManageAccounts')}
    resolver={({ ManageAccounts }) => ManageAccounts}
    {...props}
  />
);

const LazyManageAccounts = props => (
  <LazyReact
    lazyImport={() => import('ui/components/ManageAccounts/ManageAccountsPage')}
    resolver={({ ManageAccountsPage }) => ManageAccountsPage}
    {...props}
  />
);

export const generalSettingsRoute: AppStateDeclaration = {
  name: 'dashboard.settings.general',
  url: '/general',
  component: LazyGeneralSettings,
  data: {
    authenticate: true,
    allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
    title: 'General settings',
    breadcrumb: { label: 'General' },
  },
};

export const userManagementRoute: AppStateDeclaration = {
  name: 'dashboard.settings.manage-users',
  url: '/manage-users',
  component: LazyUserManagement,
  resolve: [redirectIfNotAdmin],
  data: {
    authenticate: true,
    allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
    title: 'Manage users',
    breadcrumb: { label: 'Manage users' },
  },
};

export const logsDropFiltersStateName = 'dashboard.tools.logs-drop-filters';
export const dropFiltersStateName = 'dashboard.tools.drop-filters';

export const dropFilters: AppStateDeclaration = {
  name: dropFiltersStateName,
  url: '/drop-filters',
  redirectTo: logsDropFiltersStateName,
  data: { authenticate: true },
};

export const logsDropFilters: AppStateDeclaration = {
  name: logsDropFiltersStateName,
  url: '/logs-drop-filters',
  component: LazyLogsDropFilters,
  resolve: [redirectIfNotAdmin],
  data: {
    authenticate: true,
    allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
    title: 'Drop filters',
    breadcrumb: { label: 'Drop filters' },
  },
};

export const auditTrailRoute: AppStateDeclaration = {
  name: 'dashboard.settings.audit-trail',
  url: '/general/audit-trail',
  component: LazyAuditTrail,
  data: {
    authenticate: true,
    allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
    title: 'Audit trail',
    breadcrumb: { label: 'Audit trail' },
  },
  resolve: [redirectIfNotAdmin],
};

export const manageAccountRoute: AppStateDeclaration = {
  name: settingsRoutesStates.manageAccounts,
  url: '/manage-accounts?createMetricAccount&product',
  component: props => {
    const accountPlan = accountPlanStateService?.accountPlan?.get();

    if (
      featureFlagStateService.isFeatureEnabled('ManageConsumptionAccounts') &&
      accountPlan?.billingType === BillingType.CONSUMPTION
    ) {
      return <LazyManageConsumptionAccounts {...props} />;
    }

    if (
      featureFlagStateService.isFeatureEnabled('ManageAccountsRedesign') ||
      cacheProvider.getPersistent('manage-accounts-redesign') === 'true'
    ) {
      return <LazyManageAccounts {...props} />;
    }

    const getManageAccountsComponent = async () => {
      const [manageAccountHtml] = await Promise.all([
        import('../dashboard/settings/manage-accounts/manage-accounts.html?raw'),
      ]);

      return {
        template: manageAccountHtml,
      };
    };

    return <LazyAngular name="angularManageAccounts" componentGetter={getManageAccountsComponent} />;
  },
  data: { authenticate: true, title: 'Manage accounts', breadcrumb: { label: 'Manage accounts' } },
  resolve: [
    redirectIfNoPermission({ isAdmin: true, isAccountOwner: true, isNotHeroku: true }),
    {
      token: 'highcharts',
      resolveFn: highchartsLazyLoadService.lazyLoadHighcharts,
    },
  ],
  params: {
    product: {
      type: 'string',
      value: 'Logs',
      dynamic: true,
    },
    createMetricAccount: {
      value: null,
    },
    showMetricsGuide: {
      value: null,
    },
  },
};
