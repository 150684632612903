import { Observable } from '@logz-pkg/observable';

class InputStateService {
  state = new Observable<string>('');
  setState = (text: string) => {
    this.state.set(text);
  };

  clear = () => {
    this.state.set('');
  };
}

export const inputStateService = new InputStateService();
