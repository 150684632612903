import { PaymentMethod, Product, ProductType } from '@logz-pkg/enums';
import { planAndBillingApiService, sessionStateService } from '@logz-pkg/frontend-services';
import { PlanInfoModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';

export class AccountPlanStateService {
  readonly accountPlan = new Observable<PlanInfoModel | undefined>();
  readonly pendingAccountPlan = new Observable<PlanInfoModel>();

  setAccountPlan = this.accountPlan.set;
  setPendingAccountPlan = this.pendingAccountPlan.set;

  fetch = async () => {
    const { current, pending } = await planAndBillingApiService.getPlan();

    const { userSummary } = sessionStateService.session.get();

    // DEV-30427 When plan/v2 will support MarketPlaceAZURE replace userSummary dependency
    if (userSummary?.liftrAccount) {
      current.paymentMethod = PaymentMethod.MarketPlaceAZURE;

      const logAnalyticsProductIndex = current.products.find(({ name }) => name === Product.LogAnalytics);

      if (logAnalyticsProductIndex) {
        logAnalyticsProductIndex.type = ProductType.OnDemand;
      }
    }

    this.accountPlan.set(current);
    this.pendingAccountPlan.set(pending);
  };

  clear = () => {
    this.accountPlan.set(null);
    this.pendingAccountPlan.set(null);
  };
}

export const accountPlanStateService = new AccountPlanStateService();
