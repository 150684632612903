import { urlHashParams, KibanaQueryUtils } from '@logz-pkg/utils';

const DrillDownParamName = 'drilldown';

const isDrillDown = (url = null) => {
  const str = url ? url : window.location.href;

  return str.includes(`${DrillDownParamName}=`);
};

const combineQuery = (queryStr: any, newValue: string) => {
  // Avoid condition duplicate
  const isDuplicate = RegExp(`^(${newValue} AND|${newValue})`);

  if (isDuplicate.test(queryStr)) {
    return queryStr;
  }

  return `${newValue}${queryStr.length > 0 ? ` AND (${queryStr})` : ''}`;
};

const clearDrilldownUrlParam = () => {
  window.history.replaceState({}, '', window.location.href.replace(/&?drilldown(=[^&]*)?/, ''));
};

const drilldown = (query: any, updateQuery = KibanaQueryUtils.K5Query.update) => {
  if (!isDrillDown()) return query;

  const drilldownParam = String(urlHashParams(window.location.href).drilldown);
  const currQueryStr = KibanaQueryUtils.K6Query.getQueryString(query);
  const newQueryStr = combineQuery(currQueryStr, drilldownParam);

  clearDrilldownUrlParam();

  const updatedQuery = updateQuery(query, newQueryStr);

  return updatedQuery;
};

const k5Dashboard = (query: any) => drilldown(query, KibanaQueryUtils.K5Query.update);

const k6Dashboard = (query: any) => drilldown(query, KibanaQueryUtils.K6Query.update);

/**
 * Used by kibana generated anchor,
 * returns false to interrupt the link action or true to proceed.
 * @param url
 * @returns {boolean}
 */
const handleDrillDownClick = url => {
  if (isDrillDown(url)) {
    window.location = url;

    return false;
  }

  return true;
};

export const drilldownService = {
  isDrillDown,
  combineQuery,
  drilldown,
  k5Dashboard,
  k6Dashboard,
  handleDrillDownClick,
};
