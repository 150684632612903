import { Input } from '@logz-ui/styleguide';
import { AwsS3BucketSubject } from '@logz-pkg/enums';

export const IAMBucketField = ({ touched, errors, onChange, value, isDisabled = false }) => (
  <Input
    subject={AwsS3BucketSubject.BucketNameInput}
    value={value}
    name="bucket"
    disabled={isDisabled}
    label="S3 bucket name"
    placeholder="your-bucket-name"
    error={touched.bucket && errors.bucket}
    onChange={onChange}
    mb={0}
  />
);
