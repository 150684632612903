import { UserSession } from '@logz-pkg/models';
import { authTokenService, clearAllCache, httpService } from '@logz-pkg/frontend-services';
import { IAppModeValue } from '@logz-pkg/enums';
import { Observable } from '@logz-pkg/observable';
import { switchModeFlow } from 'core/auth-flows';

export const handleSessionReplace = async ({ data }: { data: UserSession }): Promise<void> => {
  const adminAccountId = authTokenService.getAdminAccountId();

  clearAllCache();

  // inSupportAccess is a sign of impersonation
  const userAccountIdToSet = data.inSupportAccess ? `i-${data.accountId}` : data.accountId;

  authTokenService.setCurrentAccountId(userAccountIdToSet);

  if (data.admin || data.inSupportAccess) {
    const adminAccountIdToSet = data.inSupportAccess ? adminAccountId : data.accountId;

    authTokenService.setAdminAccountId(adminAccountIdToSet);
  }

  await switchModeFlow();
};

/**
 * Available also by adding `accountId` to the url
 * @param accountId
 */

const knownChangeAccountId = new Observable<number | null>(null);

const switchAccount = async (accountId: number): Promise<UserSession | void> => {
  try {
    const result = await httpService.get(`/replace-user-session/${accountId}`).then(handleSessionReplace);

    return result;
  } finally {
  }
};

const switchMode = (appMode: IAppModeValue) =>
  httpService.post(`/switch-system-mode/${appMode.value}`).then(handleSessionReplace);

export const switchSessionService = {
  switchAccount,
  switchMode,
  getKnownChange: knownChangeAccountId.get,
  setKnownChange: knownChangeAccountId.set,
};
