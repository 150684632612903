/* eslint-disable max-lines */
import './upsert-notification-endpoint.less';
import _ from 'lodash';
import { jsonUtils } from '@logz-pkg/utils';
import { documentationLinks } from '../../ui/components/NotificationEndpoints/endpoint-form-config';

const { toAngularJson, fromAngularJson } = jsonUtils;
export default [
  '$scope',
  '$timeout',
  'logzPatterns',
  'NotificationEndpointService',
  function UpsertNotificationEndpointController($scope, $timeout, logzPatterns, NotificationEndpointService) {
    const createNoteWithLink = (templateName, address, fieldName) => ({
      beforeLink: 'Please refer to the',
      link: {
        address,
        text: [templateName, 'documentation'].join(' '),
      },
      afterLink: ['to learn how to set up the', fieldName].join(' '),
    });

    $scope.notificationNamePattern = logzPatterns.accountName;
    $scope.urlNgPattern = logzPatterns.url;

    $scope.testState = {};

    $scope.templatesData = {
      Custom: {
        templateUrlSuffix: 'custom',
        userData: {
          method: 'GET',
          bodyTemplate: toAngularJson(
            JSON.parse(
              '{"alert_title":"{{alert_title}}",' +
                '"alert_description":"{{alert_description}}","alert_severity":"{{alert_severity}}",' +
                '"alert_event_samples":"{{alert_samples}}"}',
            ),
            true,
          ),
        },
        urlPlaceHolder: 'eg: https://my.third-party-service.com/integration-key/KSJ329aB8F',
      },
      Slack: {
        templateUrlSuffix: 'slack',
        userData: {},
        uiControls: [
          {
            name: 'URL',
            placeholder: 'eg: https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXX',
            modelPath: 'userData',
            modelPropertyName: 'url',
            note: createNoteWithLink('Slack', documentationLinks.SLACK, 'URL'),
            ngPattern: $scope.urlNgPattern,
          },
        ],
      },
      PagerDuty: {
        templateUrlSuffix: 'pager-duty',
        userData: {},
        uiControls: [
          {
            name: 'Service Key',
            placeholder: 'eg: 9c0f784f97a14f269b299756fc062b66',
            modelPath: 'userData',
            modelPropertyName: 'serviceKey',
            note: createNoteWithLink('PagerDuty', documentationLinks.PAGER_DUTY, 'service key'),
          },
        ],
      },
      Datadog: {
        templateUrlSuffix: 'data-dog',
        userData: {},
        uiControls: [
          {
            name: 'API Key',
            placeholder: 'eg: ea8a6fe8021e1cdfe87614089b0a3b38',
            modelPath: 'userData',
            modelPropertyName: 'apiKey',
            note: createNoteWithLink('Datadog', documentationLinks.DATA_DOG, 'api key'),
          },
        ],
      },
      BigPanda: {
        templateUrlSuffix: 'big-panda',
        userData: {},
        uiControls: [
          {
            name: 'Token',
            placeholder: 'eg: ccc0d7a2ce50b365ffca653e80d52ceb',
            modelPath: 'userData',
            modelPropertyName: 'apiToken',
            note: createNoteWithLink('BigPanda', documentationLinks.BIG_PANDA, 'token'),
          },
          {
            name: 'App Key',
            placeholder: 'eg: 72ea2ed8ae331fbc80c7bdaffabc8f76',
            modelPath: 'userData',
            modelPropertyName: 'appKey',
            note: createNoteWithLink('BigPanda', documentationLinks.BIG_PANDA, 'app key'),
          },
        ],
      },
      VictorOps: {
        templateUrlSuffix: 'victorops',
        userData: {},
        uiControls: [
          {
            name: 'Service API Key',
            modelPath: 'userData',
            modelPropertyName: 'serviceApiKey',
          },
          {
            name: 'Message Type',
            modelPath: 'userData',
            modelPropertyName: 'messageType',
          },
          {
            name: 'Routing Key',
            modelPath: 'userData',
            modelPropertyName: 'routingKey',
            note: createNoteWithLink(
              'VictorOps',
              'https://help.victorops.com/knowledge-base/routing-keys/',
              'routing key',
            ),
          },
        ],
      },
    };

    const clonedNotificationEndpointToEdit = _.cloneDeep($scope.notificationEndpointToEdit);

    if (clonedNotificationEndpointToEdit) {
      $scope.title = clonedNotificationEndpointToEdit.title;
      $scope.description = clonedNotificationEndpointToEdit.description;
      $scope.bodyTemplate = fromAngularJson(clonedNotificationEndpointToEdit.bodyTemplate);

      $scope.endpointType = clonedNotificationEndpointToEdit.endpointType;
      $scope.chosenEndpointType = $scope.endpointType;
      $scope.templatesData[$scope.endpointType].userData = clonedNotificationEndpointToEdit;

      if ($scope.endpointType === 'Custom' && $scope.templatesData[$scope.endpointType].userData.bodyTemplate) {
        // Model to JSON string, in custom mode the user configure the body
        $scope.templatesData[$scope.endpointType].userData.bodyTemplate = toAngularJson(
          $scope.templatesData[$scope.endpointType].userData.bodyTemplate,
          true,
        );
      }
    }

    const forceTemplateTypesToShow = $scope.forceTemplateTypesToShow || [];

    NotificationEndpointService.getAllNotificationEndpointTemplates().then(templateTypes => {
      $scope.availableTemplateTypes = [...templateTypes];

      if (forceTemplateTypesToShow.length) {
        $scope.availableTemplateTypes = forceTemplateTypesToShow;
      }
    });

    // Set each UI control modelPath to the object it self (string dotnotation to object reference)
    // We can't combine modelPath and modelPropertyName to one object reference because it's a string value
    // and JS doesn't pass it as reference but by value
    // (in this following array node example, not by passing to function)
    const templateNames = Object.keys($scope.templatesData);

    _.forEach(templateNames, templateName => {
      _.forEach($scope.templatesData[templateName].uiControls, uiControl => {
        const nodes = uiControl.modelPath ? uiControl.modelPath.split('.') : [];

        uiControl.modelPath = $scope.templatesData[templateName];
        _.forEach(nodes, node => {
          uiControl.modelPath = uiControl.modelPath[node];
        });
      });
    });

    let saveInProcess = false;

    $scope.saveNotificationEndpoint = isTest => {
      $scope.testState.notificationMessage = false;
      _.forEach($scope.upsertNotificationEndpoint.$error, field => {
        _.forEach(field, errorField => {
          if (errorField.$error.required && errorField.$modelValue && errorField.$modelValue.length >= 0) {
            errorField.$setValidity('required', true);

            return;
          }

          errorField.$setTouched();
        });
      });

      if ($scope.upsertNotificationEndpoint.$invalid) {
        return;
      }

      if (saveInProcess) {
        return;
      }

      saveInProcess = true;
      $scope.testState.notificationTestFailure = false;
      $scope.testState.testFinished = false;
      $scope.testState.isTest = isTest;
      $scope.testState.notificationMessage = '';
      $scope.show = false;

      const templateData = $scope.templatesData[$scope.endpointType];

      let upsertEndpointData = _.merge(clonedNotificationEndpointToEdit, templateData.userData);

      upsertEndpointData = _.cloneDeep(upsertEndpointData); // To avoid manipulation effecting the UI

      upsertEndpointData.endpointType = $scope.endpointType;
      upsertEndpointData.title = $scope.title;
      upsertEndpointData.description = $scope.description;
      upsertEndpointData.bodyTemplate = templateData.userData.bodyTemplate
        ? JSON.parse(templateData.userData.bodyTemplate)
        : null;

      return NotificationEndpointService.saveNotificationEndpoint(
        upsertEndpointData,
        templateData.templateUrlSuffix,
        isTest,
      )
        .then(response => {
          if (isTest) {
            $scope.testState.notificationTestFailure = false;
            $scope.testState.notificationMessage = 'Test message sent.' + ' Please verify message received as expected.';
            $scope.testState.testFinished = true;

            saveInProcess = false;

            return;
          }

          return NotificationEndpointService.getNotificationEndpoint(response.data.id).then(res => {
            if (!clonedNotificationEndpointToEdit) {
              $scope.notificationEndpoints.push(res.data);

              return $scope.confirm($scope.notificationEndpoints);
            }

            _.forEach($scope.notificationEndpoints, (notificationEndpoint, index) => {
              if (notificationEndpoint.id === clonedNotificationEndpointToEdit.id) {
                $scope.notificationEndpoints[index] = res.data;
              }
            });

            return $scope.confirm($scope.notificationEndpoints);
          });
        })
        .catch(e => {
          saveInProcess = false;

          if (e.data.message) {
            $scope.testState.notificationMessage = e.data.message;
          } else {
            $scope.testState.notificationMessage =
              'Error occurred while saving your notification endpoint.' + ' Please check your input and try again.';
          }

          if (isTest) {
            $scope.testState.testErrorMessage = $scope.testState.notificationMessage;
            $scope.testState.notificationMessage =
              'Failed to send a test message.' + ' Please verify the endpoint settings and try again';
            $scope.testState.testFinished = true;
            $scope.testState.notificationTestFailure = true;
          }

          console.error(e.data);
        });
    };

    $scope.cancel = () => {
      $scope.dialogNotificationEndpoint = {};
      $scope.closeThisDialog('cancel');
    };

    let switchTemplateTimer;
    let switchTemplateTimerRunning = false;
    const watchSelectTemplate = $scope.$watch('chosenEndpointType', (newValue, oldValue) => {
      if (newValue === oldValue) {
        return;
      }

      if (oldValue !== undefined && !$scope.shouldAddTransitionDelay) {
        // When moving between templates and not choosing one for the first time,
        // we need to add a transition delay for closing & then opening effect (one after another)
        $scope.shouldAddTransitionDelay = true;
      }

      // We keep endpointType and chosenEndpointType as separated models due to the 400 ms $timeout
      if (!$scope.shouldAddTransitionDelay) {
        $scope.endpointType = $scope.chosenEndpointType;

        return;
      }

      // Wait for the hide transition of of user data to be applied before changing the template value
      // (which will change the user data in the UI)
      if (switchTemplateTimerRunning) {
        $timeout.cancel(switchTemplateTimer);
      }

      switchTemplateTimerRunning = true;
      switchTemplateTimer = $timeout(() => {
        $scope.endpointType = $scope.chosenEndpointType;
        switchTemplateTimerRunning = false;
      }, 400);
    });

    const customEndpointBodyValidator = $scope.$watch('templatesData.Custom.userData.bodyTemplate', () => {
      if (
        !$scope.upsertNotificationEndpoint ||
        !$scope.upsertNotificationEndpoint['templatesData.Custom.userData.bodyTemplate']
      ) {
        return;
      }

      let valid = true;

      if (typeof $scope.templatesData.Custom.userData.bodyTemplate !== 'string') {
        valid = true;
      } else {
        try {
          JSON.parse($scope.templatesData.Custom.userData.bodyTemplate);
        } catch (e) {
          valid = false;
        }
      }

      $scope.upsertNotificationEndpoint['templatesData.Custom.userData.bodyTemplate'].$setValidity('validJson', valid);
    });

    $scope.$on('$destroy', () => {
      if (watchSelectTemplate) watchSelectTemplate();

      if (customEndpointBodyValidator) customEndpointBodyValidator();
    });
  },
];
