import { FunctionComponent } from 'react';
import { Flex, Button } from '@logz-ui/styleguide';

interface ILoadMoreButtonProps {
  isLoading: boolean;
  onClick(): void;
}

export const WhatsNewAnnouncementLoadMoreButton: FunctionComponent<ILoadMoreButtonProps> = ({ isLoading, onClick }) => (
  <Flex py={5} alignItems={'center'} justifyContent={'center'}>
    <Button variant={'secondary'} loading={isLoading} onClick={onClick} subject={'load-more-announcements-button'}>
      Load More
    </Button>
  </Flex>
);
