import { GlobalSearchResultModel, SearchSource } from '@logz-pkg/models';

const SEARCH_HISTORY_LOCAL_STORAGE_KEY_PREFIX = 'SEARCH_HISTORY_LOCAL_STORAGE_KEY';

function getAccountSearchHistoryKey(accountId) {
  return `${SEARCH_HISTORY_LOCAL_STORAGE_KEY_PREFIX}_${accountId}`;
}

class PlatformSearchHistoryApiService {
  accountItemsCache: Record<number, GlobalSearchResultModel[]> = {};

  async getSearchHistory(accountId: number): Promise<GlobalSearchResultModel[]> {
    if (!this.accountItemsCache[accountId]) {
      this.accountItemsCache[accountId] = this.getStoredItems(accountId);
    }

    // we want to filter out any old sources that are not supported.
    return this.accountItemsCache[accountId]?.filter(i => Boolean(SearchSource[i.source]));
  }

  async addItemToSearchHistory(searchResult: any, accountId: number) {
    const historyItems = await this.getSearchHistory(accountId);
    const itemsToUpdate = historyItems.filter(it => it.title !== searchResult.title);

    itemsToUpdate.unshift(searchResult);

    this.storeItems(itemsToUpdate, accountId);
  }

  private storeItems(items, accountId: number) {
    this.accountItemsCache[accountId] = items;
    localStorage.setItem(getAccountSearchHistoryKey(accountId), JSON.stringify(items));
  }

  private getStoredItems(accountId: number) {
    const storedItems = localStorage.getItem(getAccountSearchHistoryKey(accountId));

    return storedItems ? JSON.parse(storedItems) : [];
  }

  hasSearchHistory(accountId: number): boolean {
    const hasHistory =
      !!this.accountItemsCache[accountId] || !!localStorage.getItem(getAccountSearchHistoryKey(accountId));

    return hasHistory;
  }
}

export const platformSearchHistoryApiService = new PlatformSearchHistoryApiService();
