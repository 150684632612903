import { colorService } from '@logz-pkg/utils';
import {
  GradientColorObject,
  PatternObject,
  SeriesAreaOptions,
  SeriesColumnOptions,
  SeriesBarOptions,
  SeriesLineOptions,
  SeriesPieOptions,
  SeriesFunnelOptions,
  SeriesAreasplineOptions,
} from 'highcharts';
import { Color, colors } from '@logz-ui/styleguide';
import { get } from 'lodash';

interface IGetSeriesProps {
  id: string;
  color?: Color | (string & { fromColor?: any });
  graphHexColor?: string | GradientColorObject | PatternObject;
  name?: string;
}

type GetLineSeries = {
  series: SeriesLineOptions['data'];
} & IGetSeriesProps &
  Omit<SeriesLineOptions, 'type'>;

const getLineSeries = ({ id, series, color, graphHexColor, name, ...props }: GetLineSeries): SeriesLineOptions => {
  const hexColor = get(colors, color);

  return {
    type: 'line',
    color: graphHexColor || hexColor,
    name,
    data: series,
    className: `${id}-line-graph`,
    ...props,
  };
};

type GetAreaSeries = {
  series: SeriesAreaOptions['data'];
  shouldFillColor?: boolean;
} & IGetSeriesProps &
  Omit<SeriesAreaOptions, 'type'>;

const getAreaSeries = ({
  id,
  series,
  color,
  graphHexColor,
  name,
  shouldFillColor = false,
  ...props
}: GetAreaSeries): SeriesAreaOptions => {
  const hexColor = get(colors, color);

  return {
    type: 'area',
    color: graphHexColor || hexColor,
    lineWidth: 1,
    name,
    data: series,
    ...(shouldFillColor && {
      fillColor: (graphHexColor || hexColor) && {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, colorService.convertHexToRgba(graphHexColor || hexColor, 0.4)],
          [1, colorService.convertHexToRgba(graphHexColor || hexColor, 0)],
        ],
      },
    }),
    className: `${id}-area-graph`,
    ...props,
  };
};

type GetHorizontalBarSeries = {
  series: SeriesBarOptions['data'];
  yAxis?: number;
} & IGetSeriesProps &
  Omit<SeriesBarOptions, 'type'>;

const getHorizontalBarsSeries = ({
  id,
  series,
  color,
  name,
  graphHexColor,
  yAxis = 0,
  ...props
}: GetHorizontalBarSeries): SeriesBarOptions => {
  const hexColor = get(colors, color);

  return {
    type: 'bar',
    yAxis,
    color: graphHexColor || hexColor,
    name,
    data: series,
    className: `${id}-bar-graph`,
    ...props,
  };
};

type GetBarSeries = {
  series: SeriesColumnOptions['data'];
  yAxis?: number;
} & IGetSeriesProps &
  Omit<SeriesColumnOptions, 'type'>;

const getBarSeries = ({
  id,
  series,
  color,
  name,
  graphHexColor,
  yAxis = 0,
  ...props
}: GetBarSeries): SeriesColumnOptions => {
  const hexColor = get(colors, color);

  return {
    type: 'column',
    yAxis,
    color: graphHexColor || hexColor,
    name,
    data: series,
    stacking: 'normal',
    className: `${id}-column-graph`,
    ...props,
  };
};

type GetPieSeries = {
  series: SeriesPieOptions['data'];
} & IGetSeriesProps &
  Omit<SeriesPieOptions, 'type'>;

const getPieSeries = ({ id, series, color, name, graphHexColor, ...props }: GetPieSeries): SeriesPieOptions => {
  const hexColor = get(colors, color);

  return {
    type: 'pie',
    color: graphHexColor || hexColor,
    name,
    data: series,
    className: `${id}-pie-graph`,
    ...props,
  };
};

type GetFunnelSeries = {
  series: SeriesFunnelOptions['data'];
} & IGetSeriesProps &
  Omit<SeriesFunnelOptions, 'type'>;

const getFunnelSeries = ({ id, series, color, name, ...props }: GetFunnelSeries): SeriesFunnelOptions => {
  return {
    type: 'funnel',
    color,
    name,
    data: series,
    className: `${id}-funnel-graph`,
    ...props,
  };
};

type GetAreaSplitSeries = {
  series: SeriesAreasplineOptions['data'];
} & IGetSeriesProps &
  Omit<SeriesAreasplineOptions, 'type'>;

const getAreaSplineSeries = ({ id, series, color, name, ...props }: GetAreaSplitSeries): SeriesAreasplineOptions => {
  const hexColor = get(colors, color) ?? color;

  return {
    type: 'areaspline',
    color: hexColor,
    name,
    data: series,
    className: `${id}-funnel-graph`,
    ...props,
  };
};

export const getSeries = {
  bar: getBarSeries,
  horizontalBars: getHorizontalBarsSeries,
  area: getAreaSeries,
  line: getLineSeries,
  pie: getPieSeries,
  funnel: getFunnelSeries,
  areaSpline: getAreaSplineSeries,
};
