import React, { useMemo, useState } from 'react';
import { IAiConversationModel } from '@logz-pkg/models';
import { useHover } from '@logz-pkg/react-hooks';
import styled from 'styled-components';
import { Text, CrudTableRecordCustomActions, Grid, Group, HighlightedText, Icon, Flex } from '@logz-ui/styleguide';
import { ICrudTableProps } from '@logz-ui/styleguide/components/table/Crud/Crud.types';
import moment from 'moment';

const StyledGrid = styled(Grid)`
  grid-template-columns: 1fr auto;
  border-radius: 8px;
  cursor: pointer;
  height: 32px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[100]};
  }
`;
const voidFunction = () => {};
export const ConversationRow: React.FC<{
  onSelect: (conversation: IAiConversationModel) => void;
  onDelete: (conversation: IAiConversationModel) => void;
  onEdit: (conversation: IAiConversationModel) => void;
  conversation: IAiConversationModel;
  filter: string;
}> = ({ filter, onDelete, onEdit, onSelect, conversation }) => {
  const [isHoveringActions, setIsHoveringActions] = useState(false);

  const { ref, isHovering: isHoveringRow } = useHover<HTMLDivElement>();
  const actions: ICrudTableProps['customActions'] = [
    {
      label: 'Rename',
      icon: 'pen-regular',
      handleClick() {
        onEdit(conversation);
        setIsHoveringActions(false);
      },
    },
    {
      label: 'Delete',
      icon: 'trash-can-regular',
      handleClick: () => {
        onDelete(conversation);
        setIsHoveringActions(false);
      },
    },
  ];

  const iconSettings = useMemo(() => {
    let tooltip, icon;

    if (conversation.triggerRole) {
      tooltip = { title: conversation.triggerRole === 'Proactive' ? 'Automatic RCA' : '', placement: 'top' };
      icon = `${conversation.triggerRole === 'Proactive' ? 'bell' : 'clock'}-regular`;
    }

    return { tooltip, icon };
  }, [conversation.triggerRole]);

  return (
    <StyledGrid
      ref={ref}
      key={conversation.id}
      gridTemplateColumns={'1fr auto'}
      cellGap={2}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'start'}
    >
      <Group width={'100%'} onClick={() => onSelect(conversation)} px={2} py={1} gap={2} alignItems="center">
        <Group flex={'0 0 50px'} justifyContent={'flex-start'}>
          <Text textAlign={'end'} size={10} color={'royalBlue.700'}>
            {moment(conversation.createdAt).format('HH:mm A')}
          </Text>
        </Group>
        {iconSettings?.icon && <Icon {...iconSettings} color={'royalBlue.300'} />}
        <HighlightedText textToHighlight={filter} ellipsis>
          {conversation.title}
        </HighlightedText>
      </Group>
      {(isHoveringRow || isHoveringActions) && (
        <Flex onMouseEnter={() => setIsHoveringActions(true)} onMouseLeave={() => setIsHoveringActions(false)}>
          <CrudTableRecordCustomActions
            appendToBody={true}
            onSearch={voidFunction}
            onFinishLoading={voidFunction}
            onStartLoading={voidFunction}
            actions={actions}
            record={conversation}
          />
        </Flex>
      )}
    </StyledGrid>
  );
};
