import {
  AuthenticationTypes,
  BucketEndpoints,
  BucketSettingsNotificationSubject,
  LogzioSupportedBucketTypes,
} from '@logz-pkg/enums';
import { NotificationService } from '@logz-ui/styleguide';
import { assumeRoleDetailsApiService, httpService } from '@logz-pkg/frontend-services';
import { AssumeRoleDetailsModel } from '@logz-pkg/models';
import { isIAMBucket } from './utils';
import { IBaseBucketSettings } from './interfaces';
import { ErrorHandlingService } from 'services/error-handling.service';

export namespace BucketSettingsService {
  export const logTypesWithPage = Object.values(LogzioSupportedBucketTypes);

  export const getBuckets = async (bucketEndpoint = 's3-buckets', logType): Promise<IBaseBucketSettings[]> => {
    try {
      const { data } = await httpService.get(`/log-shipping/${bucketEndpoint}`);

      let bucketList = data;

      if (logType === LogzioSupportedBucketTypes.S3) {
        bucketList = bucketList.filter(bucket => logTypesWithPage.indexOf(bucket.logsType.toLowerCase()) === -1);
      } else if (bucketEndpoint === BucketEndpoints.S3) {
        bucketList = bucketList.filter(bucket => bucket.logsType.toLowerCase() === logType);
      }

      bucketList = bucketList.map(bucket => {
        bucket.type = isIAMBucket(bucket) ? AuthenticationTypes.IAM : AuthenticationTypes.SecretAccess;

        return bucket;
      });

      return bucketList;
    } catch ({ data: error }) {
      ErrorHandlingService.handleApiError({
        title: 'Failed to get saved buckets',
        subject: BucketSettingsNotificationSubject.GetBuckets,
        unexpectedErrorLogMessage: 'Fetch buckets error',
        error,
      });

      throw error;
    }
  };

  export const saveBucket = async (
    bucket: IBaseBucketSettings,
    bucketType = 's3-buckets',
  ): Promise<IBaseBucketSettings | boolean> => {
    try {
      if (!bucket.prefix) {
        delete bucket.prefix;
      }

      delete bucket.appOnlyId;

      const { data: updatedBucket } = await httpService.post(`/log-shipping/${bucketType}`, bucket);

      updatedBucket.type = isIAMBucket(bucket) ? AuthenticationTypes.IAM : AuthenticationTypes.SecretAccess;

      NotificationService.success({
        subject: BucketSettingsNotificationSubject.CreateSuccess,
        message: 'AWS Bucket created successfully',
      });

      return updatedBucket;
    } catch ({ data: error }) {
      ErrorHandlingService.handleApiError({
        title: 'Failed to save bucket configuration',
        subject: BucketSettingsNotificationSubject.CreateFailure,
        unexpectedErrorLogMessage: 'Save AWS bucket error',
        error,
      });

      return false;
    }
  };

  export const updateBucket = async (
    bucket: IBaseBucketSettings,
    id: number,
    bucketEndpoint = 's3-buckets',
  ): Promise<IBaseBucketSettings | boolean> => {
    try {
      if (!bucket.prefix) {
        delete bucket.prefix;
      }

      const { data } = await httpService.put(`/log-shipping/${bucketEndpoint}/${id}`, bucket);

      NotificationService.success({
        subject: BucketSettingsNotificationSubject.EditSuccess,
        message: 'AWS Bucket saved successfully',
      });

      return data as IBaseBucketSettings;
    } catch ({ data: error }) {
      ErrorHandlingService.handleApiError({
        title: 'Failed to update AWS settings',
        subject: BucketSettingsNotificationSubject.EditFailure,
        unexpectedErrorLogMessage: 'Update AWS bucket error',
        error,
      });

      return false;
    }
  };

  export const deleteBucket = async (id: number, bucketType = 's3-buckets') => {
    try {
      const deleteRequest = await httpService.del(`/log-shipping/${bucketType}/${id}`);

      NotificationService.success({
        subject: BucketSettingsNotificationSubject.DeleteSuccess,
        message: 'AWS Bucket deleted successfully',
        duration: 4,
      });

      return deleteRequest;
    } catch ({ data: error }) {
      ErrorHandlingService.handleApiError({
        title: 'Failed to delete AWS Bucket',
        subject: BucketSettingsNotificationSubject.DeleteFailure,
        unexpectedErrorLogMessage: 'Delete AWS bucket error',
        error,
      });

      return false;
    }
  };

  export const getRegions = async () => {
    try {
      const { data } = await httpService.get('/aws/regions');

      return data;
    } catch ({ data: error }) {
      ErrorHandlingService.handleApiError({
        title: 'Failed to get supported Regions',
        subject: BucketSettingsNotificationSubject.RegionGetFailure,
        unexpectedErrorLogMessage: 'Get regions error',
        error,
      });

      throw error;
    }
  };

  export const getSupportedLogTypes = async () => {
    try {
      const { data } = await httpService.get('/supported-log-types');

      return data.filter(logType => logTypesWithPage.indexOf(logType.type.toLowerCase()) === -1);
    } catch ({ data: error }) {
      ErrorHandlingService.handleApiError({
        title: 'Failed to get supported log types',
        subject: BucketSettingsNotificationSubject.LogTypesGetFailure,
        unexpectedErrorLogMessage: 'Get supported log types error',
        error,
      });

      throw error;
    }
  };

  export const getAssumeRoleDetails = async (): Promise<AssumeRoleDetailsModel> => {
    try {
      return assumeRoleDetailsApiService.get();
    } catch ({ data: error }) {
      ErrorHandlingService.handleApiError({
        title: `Sorry, the AWS external ID didn't load`,
        subject: BucketSettingsNotificationSubject.AccountIdGetFailure,
        unexpectedErrorLogMessage: 'Get logzio account id error',
        error,
        userMessage: `Please refresh the page. If that doesn't work, please chat with Support.`,
      });

      return {
        logzioAWSAccountId: '',
        assignedExternalId: '',
      };
    }
  };
}
