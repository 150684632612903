import '../utils/polyfills';
import { bootstrap as bootstrapStyleguide, NotificationService } from '@logz-ui/styleguide';
import { LoggerService, regionsService, sessionStateService, setDalLogger } from '@logz-pkg/frontend-services';
import { registerWindowErrorHandlers } from '../hooks/error.hooks';
import { registerRequestHook } from '../hooks/request.hook';
import { registerStateChangeHooks } from '../hooks/state-change.hook';
import { overrideConsole } from '../modules/override-console';
import { bootstrapKibanaConfig } from './osd/osd-settings.service';
import { bootstrapProgressBar } from './components/nprogress/nprogress.overrides';
import { bootstrapBroadcastChannel } from 'services/broadcast-channel/bootstrap';
import { bootstrapAuthTokenCollection } from 'core/auth/services/bootstrap.auth';
import { ACCOUNT_TYPE_TO_MODE } from 'services/identity/app-mode/app-mode.service';

export const bootstrap = async () => {
  registerRequestHook();
  overrideConsole();
  sessionStateService.initAppModes(ACCOUNT_TYPE_TO_MODE);
  regionsService.init(NotificationService);
  await bootstrapAuthTokenCollection();
  bootstrapProgressBar();
  bootstrapStyleguide({ shouldLoadFonts: false });
  bootstrapKibanaConfig();
  registerStateChangeHooks();
  registerWindowErrorHandlers();
  setDalLogger(LoggerService);
  bootstrapBroadcastChannel();
};
