import { useCallback } from 'react';
import { useObservable } from '@logz-pkg/observable';
import { AIDataSource } from '@logz-pkg/enums';
import { bedrockUtils, RecordType, SearchChatRecord } from '@logz-pkg/models';
import { useAiState } from '../ai-state.hook';
import { useAiDataSource } from '../data-source/data-source.hook';
import { aiUtils } from '../../utils';
import { aiStateService } from '../ai-state.service';
import { aiFetchDataService } from './fetch-data.service';
import { useAiHistory } from 'ui/components/AI/state/history/history.hook';

export const useAiFetchData = () => {
  const isLoading = useObservable(aiFetchDataService.isLoading);
  const { sessionId, context, setUserInput, toggleDrawer } = useAiState();
  const { dataSource, setDataSource } = useAiDataSource();
  const { history } = useAiHistory();

  const handleSubmit = useCallback<
    ({
      prompt,
      forcedContext,
      forcedDataSource,
      componentType,
      meta,
    }: {
      prompt: string;
      forcedContext?: unknown;
      forcedDataSource?: AIDataSource;
      componentType?: RecordType;
      meta?: SearchChatRecord[];
    }) => void
  >(
    async ({ prompt, forcedContext, forcedDataSource, componentType, meta }) => {
      setUserInput('');

      const selectedSessionId = forcedDataSource ? bedrockUtils.getNewSessionId() : sessionId;

      if (forcedDataSource) {
        aiStateService.setSessionId(selectedSessionId);
        setDataSource(forcedDataSource, false);
        toggleDrawer(true);
      }

      const selectedDataSource = forcedDataSource || dataSource;

      const selectedContext = forcedContext ?? context;

      if (history.length > 0) {
        aiUtils.loggerInfo('User input after conclusion', {
          sessionId: aiStateService.sessionId.get(),
          history,
          prompt,
        });
      }

      aiUtils.reportUserInput({
        prompt,
        dataSource: selectedDataSource,
        context: selectedContext,
        componentType,
        meta,
      });

      if (
        [
          AIDataSource.AllAvailableData,
          AIDataSource.CurrentPageAgent,
          AIDataSource.RCA,
          AIDataSource.MetricsDashboard,
        ].includes(selectedDataSource)
      ) {
        await aiFetchDataService.agent({
          prompt,
          context: selectedContext,
          agentType: selectedDataSource,
          componentType,
          sessionId: selectedSessionId,
        });

        return;
      }

      if ([AIDataSource.KnowledgeBaseShipping, AIDataSource.KnowledgeBaseUserGuide].includes(selectedDataSource)) {
        await aiFetchDataService.knowledgeBase({ prompt, dataSource: selectedDataSource, sessionId: selectedSessionId });

        return;
      }

      await aiFetchDataService.assistant({
        prompt,
        context,
        meta,
      });
    },
    [history, dataSource, sessionId, context],
  );

  return {
    handleSubmit,
    isLoading,
    setIsLoading: aiFetchDataService.setLoading,
  };
};
