import { Button, Group, NotificationService, Text } from '@logz-ui/styleguide';
import { analyticsService } from 'services/analytics/analytics.service';

export const broadcastWarmTierQuery = () =>
  NotificationService.inProgress({
    title: 'Fetching warm data',
    message: (
      <>
        <Text weight="700">This process can take a few minutes</Text>
        <br />
        <Text>You can continue using Explore in a separate tab</Text>
      </>
    ),
    autoHide: false,
    children: (
      <Group alignItems={'center'}>
        <Button
          icon={'arrow-up-right-from-square-regular'}
          onClick={() => {
            window.open(location.href.split('?')[0], '_blank');
            analyticsService.capture('Warm tier notification', 'Continued in Explore while Warm tier query in progress');
          }}
        >
          New Explore Tab
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            location.href = location.href.split('?')[0];
            NotificationService.destroy();
            analyticsService.capture('Warm tier notification', 'Canceled query while Warm tier query in progress');
          }}
        >
          Cancel query
        </Button>
      </Group>
    ),
  });
