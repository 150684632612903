import { dateToAbsolute, intervalCalculatorService, isRelativeDate } from '@logz-pkg/utils';
import { opensearchtypes } from '@opensearch-project/opensearch';
import moment from 'moment';
import { ProductKeys } from '@logz-pkg/enums';
import { ChatResponse, InvocationAgentChatRecord } from '@logz-pkg/models';
import { ExploreSearchParams } from 'ui/components/Explore/router/router';
import { exploreRouteHelpers } from 'ui/components/Explore/router/router.helpers';
import { urlParamsService } from 'ui/components/Explore/services/url-params.service';
import { graphUtils } from 'ui/components/Explore/ContentPane/Graphs/utils/graph-utils';
import { seriesUtils } from 'ui/components/Explore/ContentPane/Graphs/utils/series.utils';
import { goToKibanaWithParams } from 'states/osd/osd.route.helpers';
import { ErrorHandlingService } from 'services/error-handling.service';
import {
  GetGraphSeriesType,
  GoToOtherPageFunction,
  TimeAndQueryStringType,
} from 'ui/components/AI/Components/Messages/Agent/invocation.type';
import { getLuceneFilter } from 'ui/components/Explore/Components/SmartSearch/LuceneInput/helpers/utils';

const getTimeAndQueryString = (params: InvocationAgentChatRecord['parameters']): TimeAndQueryStringType => {
  return params.reduce((acc, param) => {
    acc[param.name] = param.value;

    if (param.name === 'start_and_end_time') {
      try {
        const timeArray = JSON.parse(param.value);

        acc.start_time = timeArray[0];
        acc.end_time = timeArray[1];
      } catch (error) {
        ErrorHandlingService.logError(`Ai chat - invocation message error, not a valid JSON - ${param.value}`, error);
        acc.start_time = null;
        acc.end_time = null;
      }
    }

    if (param.name.includes('time')) {
      acc[param.name] = isNaN(Number(param.value)) ? param.value : Number(param.value);
    }

    return acc;
  }, {} as TimeAndQueryStringType);
};

const goToExplore = async ({ startTime, endTime, query, field }: GoToOtherPageFunction) => {
  const queryValues = getLuceneFilter(query);

  const exploreSearchParams: ExploreSearchParams = {
    from: moment(startTime).utc().valueOf(),
    to: moment(endTime).utc().valueOf(),
    ...(query && { query: urlParamsService.filtersToUrlParam([queryValues]) }),
    ...(field && { groupBy: field }),
  };

  await exploreRouteHelpers.goToExplore({
    params: { ...exploreSearchParams },
    openInNewTab: true,
  });
};

const getColorsForGroupByAndSeries = ({ byTerm, aggregations, logs, groupBy }: GetGraphSeriesType) => {
  const groups = graphUtils.getGroupByState(byTerm as opensearchtypes.AggregationsFiltersAggregate, aggregations);

  const mappedColors = graphUtils.getMappedGraphColors(groups);

  const getValueColor = (key: string) => (key ? mappedColors?.[key.toLocaleLowerCase()] : undefined);

  return seriesUtils.getGroupByStateSeries({
    groupByState: logs || [],
    graphQueryRunning: false,
    getValueColor,
    groupBy,
  });
};

const getKubeStringBadge = ({ workloadName, namespace, k8sCluster, workloadType }) => {
  if (!workloadName || !workloadType) return [];

  const badges = [`${workloadType.charAt(0).toUpperCase() + workloadType.slice(1)}: ${workloadName}`];

  if (namespace) badges.push(`Namespace: ${namespace}`);

  if (k8sCluster) badges.push(`Cluster: ${k8sCluster}`);

  return badges;
};

const getGoToPreview = {
  [ProductKeys.Siem]: async ({ query, startTime, endTime, field }) => {
    await goToKibanaWithParams({
      query,
      fromDate: startTime,
      toDate: endTime,
      subAccountsIds: [],
      allSubAccounts: true,
      columns: [field],
      openInNewTab: true,
    });
  },
  [ProductKeys.Explore]: async ({ query, startTime, endTime, field }) => {
    await goToExplore({ startTime, endTime, query, field });
  },
};

const isTherePreviewFunction = ({
  currentProduct,
  startTime,
  endTime,
  query,
  field,
}: GoToOtherPageFunction & { currentProduct: ProductKeys }) => {
  if (!getGoToPreview[currentProduct]) return false;

  if ([ProductKeys.Siem, ProductKeys.Explore].includes(currentProduct) && !startTime && !endTime && !query && !field) {
    return false;
  }

  return true;
};

const getBadgesFromParams = (parameters: ChatResponse['actionGroupInvocationInput']['parameters']) => {
  const params = getTimeAndQueryString(parameters);

  const { workload_type, workload_name, namespace, k8s_cluster } = params;

  return getKubeStringBadge({
    workloadName: workload_name,
    workloadType: workload_type,
    k8sCluster: k8s_cluster,
    namespace,
  });
};

const getInvocationInformation = ({
  functionName,
  parameters,
  currentProduct,
}: InvocationAgentChatRecord & { currentProduct: ProductKeys }) => {
  const params = getTimeAndQueryString(parameters);
  const { query, field, start_time, end_time, workload_type, workload_name, namespace, k8s_cluster } = params;

  if (!start_time || !end_time)
    return {
      query,
      interval: null,
      field,
      goToPreviewFunction: null,
      badges: [],
    };

  const interval = intervalCalculatorService.findBucket({
    start: moment(isRelativeDate(start_time) ? dateToAbsolute(start_time) * 1000 : start_time)
      .utc()
      .valueOf(),
    end: moment(isRelativeDate(end_time) ? dateToAbsolute(end_time) * 1000 : end_time)
      .utc()
      .valueOf(),
    maxBuckets: 25,
  });

  if (['get-workload-list', 'get-workload-metrics'].includes(functionName)) currentProduct = ProductKeys.K8S360;

  const goToPreviewFunction = isTherePreviewFunction({
    currentProduct,
    startTime: interval.start,
    endTime: interval.end,
    query,
    field,
    namespace,
    workloadName: workload_name,
    workloadType: workload_type,
    k8sCluster: k8s_cluster,
  })
    ? () =>
        getGoToPreview[currentProduct]({
          currentProduct,
          startTime: interval.start,
          endTime: interval.end,
          query,
          field,
          namespace,
          workloadName: workload_name,
          workloadType: workload_type,
          k8sCluster: k8s_cluster,
        })
    : null;

  const badges = getKubeStringBadge({
    workloadName: workload_name,
    workloadType: workload_type,
    k8sCluster: k8s_cluster,
    namespace,
  });

  return {
    query,
    interval,
    field,
    goToPreviewFunction,
    badges,
  };
};
export const agentInvocationMessageUtils = {
  getColorsForGroupByAndSeries,
  getInvocationInformation,
  getBadgesFromParams,
};
