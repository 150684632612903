import { FunctionComponent } from 'react';
import { Badge, Icon, Group, Text, Link } from '@logz-ui/styleguide';
import { GuidedWalkthroughSubject } from '@logz-pkg/enums';
import { HeaderButton } from 'ui/components/App/Navigation/Header/Button';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';
import { WalkthroughTasks } from 'ui/components/GuidedWalkthrough/Tasks';
import { useWhatsNewState } from 'ui/state/hooks';
import { whatsNewStateService } from 'ui/state/whats-new.state.service';
import { useAiState } from 'ui/components/AI/state/ai-state.hook';

const ClipboardIconWithBadge: FunctionComponent = () => (
  <Badge variant={'danger'} subject={GuidedWalkthroughSubject.UnseenTasksBadge} size={'xxs'}>
    <Icon icon={'clipboard-regular'} size={18} color={'royalBlue.500'} />
  </Badge>
);

export const GuidedWalkthroughBadge: FunctionComponent = () => {
  const { isDrawerOpen, setIsDrawerOpen, seenTasksIds, allTasks, doneTasksIds } = useGuidedWalkthrough();
  const { isWhatsNewOpen } = useWhatsNewState();
  const { isDrawerOpen: isAiDrawerOpen, toggleDrawer } = useAiState();

  const progressLabel = `${allTasks.filter(task => doneTasksIds?.includes(task.id))?.length}/${allTasks?.length}`;
  const hasUnseenTasks = !!(seenTasksIds?.length < Object.keys(WalkthroughTasks)?.length);

  const handleOnClick = () => {
    if (isWhatsNewOpen) whatsNewStateService.toggleWhatsNew();

    if (isAiDrawerOpen) toggleDrawer(false);

    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Link onClick={handleOnClick} subject={GuidedWalkthroughSubject.HeaderBadge}>
      <HeaderButton active={isDrawerOpen} icon={hasUnseenTasks ? <ClipboardIconWithBadge /> : 'clipboard-regular'}>
        <Group gap={1}>
          <Text size={12}>Guide</Text>
          <Text size={12} weight={700} subject={GuidedWalkthroughSubject.ProgressLabel}>
            {progressLabel}
          </Text>
        </Group>
      </HeaderButton>
    </Link>
  );
};
