import type { auto } from 'angular';

export let injector: auto.IInjectorService | null = null;

export async function getWebAppNgInjectorProvider(): Promise<auto.IInjectorService | null> {
  if (injector) {
    return Promise.resolve(injector);
  }

  const angular = await import('angular');

  return new Promise(resolve => {
    // wait for DOM and angular to load
    angular.element(() => {
      const element = document.querySelector('#rootAngular');

      injector = angular.element(element!).injector();
      resolve(injector);
    });
  });
}

export const getAngularService = async <T = any>(injectName: string): Promise<T | null> => {
  const injector = await getWebAppNgInjectorProvider();

  return injector!.get(injectName);
};
