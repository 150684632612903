import { LoggerService, sessionApiService, authTokenService, configProvider } from '@logz-pkg/frontend-services';
import { loadScript } from '../../utils/load-static-resources';
import { preScript } from './pendo.prescript';
import { pendoTours } from './pendo.config';
import { IStartSessionParams, ITourService, TourType } from './types';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { getSessionTourParams } from 'services/tours/utils';
import { ErrorHandlingService } from 'services/error-handling.service';

let _PendoAPI;

const isDisabled = async () => {
  const {
    integrations: {
      pendo: { isDisabled: isDisabledForAccount },
    },
  } = await sessionApiService.get();

  return !featureFlagStateService.isFeatureEnabled('Pendo') || isDisabledForAccount || !authTokenService.hasAuthToken();
};

const initializeParams = async ({ sanitizeUrl = (url: string) => url, api = _PendoAPI }) => {
  const {
    userId,
    params: {
      accountId,
      planType,
      accountCreatedDate,
      role,
      featureFlags,
      isMetricsActive,
      metricsAccountCreateDate,
      daysLeftForTracingTrial,
      tracingPlanType,
      isTracingActive,
      isLiftr,
    },
  } = await getSessionTourParams();

  api.initialize({
    sanitizeUrl,
    visitor: {
      id: userId,
      role,
      featureFlags,
    },
    account: {
      id: accountId,
      accountCreatedDate: accountCreatedDate.toISOString ? accountCreatedDate.toISOString() : accountCreatedDate,
      metricsAccountCreateDate,
      planType,
      tracingPlanType,
      isMetricsActive,
      isTracingActive,
      daysLeftForTracingTrial,
      isLiftr,
    },
  });
};

const loadExternalScript = async (targetWindow: Window) => {
  const apiKey = await configProvider.getValue('pendo.apiKey');
  const scriptUrl = `https://content.guides.logz.io/agent/static/${apiKey}/pendo.js`;

  try {
    await loadScript({ src: scriptUrl, targetWindow, cache: false });
  } catch (error) {
    LoggerService.logError({ message: `Could not load pendo script with src: ${scriptUrl}`, error });
  }
};

const startSession = async ({ targetWindow = window, sanitizeUrl, isMainWindow }: IStartSessionParams) => {
  if ((await isDisabled()) || (targetWindow as any).pendo) return;

  preScript(targetWindow);

  await loadExternalScript(targetWindow);

  try {
    const api = getPendoAPI({ targetWindow, isMainWindow });

    await initializeParams({ sanitizeUrl, api });
  } catch (error) {
    LoggerService.logError({ message: 'Could not initialize pendo params', error });
  }
};

const updateTracingParams = async (daysLeftForTracingTrial, tracingPlanType, isTracingActive) => {
  _PendoAPI.updateOptions({
    daysLeftForTracingTrial,
    tracingPlanType,
    isTracingActive,
  });
};

const getPendoAPI = ({ targetWindow = window, isMainWindow }: { targetWindow: Window; isMainWindow?: boolean }) => {
  if (isMainWindow) {
    _PendoAPI = (targetWindow as any).pendo;
  }

  return (targetWindow as any).pendo;
};

// Pendo loads all its guides each navigation and when we navigate and want to show guide, it doesn't work since
// the guides aren't loaded yet. The function tries to fetch all guides again and waits for load finishing to make sure
// all guides have already been loaded
const waitForPendoGuidesLoaded = () => {
  _PendoAPI.loadGuides();

  return new Promise((resolve, reject) => {
    _PendoAPI.events.one('guidesLoaded', resolve);
    _PendoAPI.events.one('guidesFailed', reject);
  });
};

const showTour = async ({ tourType, isTriggeredByUser }: { tourType: TourType; isTriggeredByUser?: boolean }) => {
  await waitForPendoGuidesLoaded();

  const guideConfig = pendoTours[tourType];
  const guide = _PendoAPI.findGuideById(guideConfig?.id);

  guide?.show();

  if (!guide) {
    const error = new Error('Guide not found');
    const errorMessage = 'Failed to show tour';

    if (isTriggeredByUser) {
      ErrorHandlingService.handleUiError({
        title: "Can't show tour",
        unexpectedErrorLogMessage: errorMessage,
        error,
      });
    } else {
      ErrorHandlingService.logError(errorMessage, error);
    }
  }
};

export const pendoTourService = {
  isDisabled,
  startSession,
  updateTracingParams,
  showTour,
} as ITourService;
