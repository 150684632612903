import { Observable } from '@logz-pkg/observable';

class TableCollapseStateService {
  state = new Observable<string[]>([]);

  setState = (newId: string) => {
    const currentState = this.state.get();

    if (currentState.some(id => id === newId)) {
      this.state.set(currentState.filter(id => id !== newId));
    } else {
      this.state.set([...currentState, newId]);
    }
  };

  reset = () => this.state.set([]);
}

export const tableCollapseStateService = new TableCollapseStateService();
