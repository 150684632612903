import { ElasticsearchFieldType } from '@logz-pkg/enums';
import { useObservable } from '@logz-pkg/observable';
import { SelectFilter, SelectSizes } from '@logz-ui/styleguide';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { ignoredFields, specialFields } from '../../../../../../constants';
import { exploreSearchParamsService } from '../../../../../../router/router';
import { filtersStateService } from '../../../../../../state/filters-state.service';
import { useLogsState } from '../../../../../../state/logs-state.hook';
import { graphStateService } from 'ui/components/Explore/state/graph-state.service';

export const GroupBySelect = ({ name }) => {
  const { logsMap, isInitialized } = useLogsState();

  const groupBy = useObservable(exploreSearchParamsService.groupBy);
  const dictionary = useObservable(filtersStateService.dictionary);
  const isValidatingGroupBy = useObservable(graphStateService.isValidatingGroupBy);

  const shownFilters = useMemo(() => {
    if (!logsMap) return [];

    const unwantedFields = [...ignoredFields, ...specialFields];

    const filters = Array.from(logsMap.entries())
      .filter(
        ([log, value]) =>
          value.field.isAggregatable &&
          value.field.type !== ElasticsearchFieldType.Number &&
          !unwantedFields.some(reg => reg.test(log)),
      )
      .map(([log]) => ({ value: log, label: filtersStateService.getFieldLabel(log) }));

    if (!isNil(groupBy) && !filters.find(f => f.value === groupBy)) filters.push({ value: groupBy, label: groupBy });

    return filters;
  }, [logsMap, groupBy, dictionary]);

  const sortedShownFilters = useMemo(() => shownFilters.sort((a, b) => a.label.localeCompare(b.label)), [shownFilters]);

  return (
    <SelectFilter
      placeholder="Select a field to group by"
      maxLabelWidth={200}
      minLabelWidth={200}
      minButtonWidth={180}
      size={SelectSizes.Large}
      name={name}
      label={'Select'}
      showLabel={false}
      showLabelColon={false}
      autoFocus={true}
      options={sortedShownFilters ?? []}
      clearable={true}
      disabled={!isInitialized || isValidatingGroupBy}
    />
  );
};
