import { FieldMappingsTabs } from '@logz-pkg/enums';
import { AppStateDeclaration } from './AppStateDecleration';
import { redirectIfNotAdmin } from './utils';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const fieldMapping: AppStateDeclaration[] = [
  {
    name: FieldMappingsTabs.Base,
    url: '/field-mapping',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/FieldMappings/FieldMappings')}
        resolver={({ FieldMappings }) => FieldMappings}
        {...props}
      />
    ),
    data: { authenticate: true, title: 'Field mappings', breadcrumb: { skip: true } },
    redirectTo: FieldMappingsTabs.Mappings,
  },
  {
    name: FieldMappingsTabs.Mappings,
    url: '',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/FieldMappings/MappingConfiguration/MappingConfiguration')}
        resolver={({ MappingsConfiguration }) => MappingsConfiguration}
        {...props}
      />
    ),
    data: { authenticate: true, breadcrumb: { label: 'Field mappings' } },
    resolve: [redirectIfNotAdmin],
  },
  {
    name: FieldMappingsTabs.Errors,
    url: '/errors',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/FieldMappings/MappingErrors/MappingErrors')}
        resolver={({ MappingErrors }) => MappingErrors}
        {...props}
      />
    ),
    resolve: [redirectIfNotAdmin],
    data: { authenticate: true, breadcrumb: { label: 'Field Mapping Errors' } },
  },
];
