import { fetchInterceptor } from '@logz-pkg/frontend-services';
import { httpInterceptorService } from 'core/http/services/http-interceptors.service';

function updateOnRequestDone({ config, increase, id, type }) {
  httpInterceptorService.updatePendingRequests({ config, increase, id });
  httpInterceptorService.log(id, type, config);

  return config;
}

const mapIdToInterceptor = new Map<string, ReturnType<ReturnType<typeof fetchInterceptor>['register']>>();

const subscribe = ({ id, targetWindow }: { id: string; targetWindow: Window }) => {
  httpInterceptorService.clearPendingRequests(id);

  const currentInterceptor = mapIdToInterceptor.get(id);

  if (currentInterceptor) {
    currentInterceptor();
  }

  const interceptor = fetchInterceptor(targetWindow, id);

  mapIdToInterceptor.set(
    id,
    interceptor.register({
      request: ({ url, config = {} }) => {
        updateOnRequestDone({ config, increase: true, id, type: `requestSuccess` });

        return { url, config };
      },
      requestError: err => updateOnRequestDone({ config: err, id, type: `requestFail`, increase: false }),
      response: response => updateOnRequestDone({ config: response, id, type: `responseSuccess`, increase: false }),
      responseError: response => updateOnRequestDone({ config: response, id, type: `responseFail`, increase: false }),
    }),
  );
  httpInterceptorService.log(id, `subscribe`);
};

const unsubscribe = ({ id }: { id: string }) => {
  const currentInterceptor = mapIdToInterceptor.get(id);

  if (!currentInterceptor) return;

  currentInterceptor();
  mapIdToInterceptor.delete(id);
  httpInterceptorService.log(id, 'unsubscribe');
};

export const fetchProgressBarInterceptor = {
  subscribe,
  unsubscribe,
  clear: httpInterceptorService.clearPendingRequests,
};
