import { Permissions, SecurityRulesStates } from '@logz-pkg/enums';
import { ruleApiService } from '@logz-pkg/frontend-services';
import { Text } from '@logz-ui/styleguide';
import { UIView } from '@uirouter/react';
import { DEFAULT_QUERY_FILTERS } from '../dashboard/triggers/table-data-fetcher/query-generator';
import { AppStateDeclaration } from './AppStateDecleration';
import { redirectIfNoPermission } from './utils';
import { RULE_DEFINITIONS_PAGE_FILTERS_IDS } from 'ui/components/AlertDefinitions/table/page-filter-ids';
import { LazyReact } from 'ui/components/shared/LazyReact';

const params = [...DEFAULT_QUERY_FILTERS, ...Object.values(RULE_DEFINITIONS_PAGE_FILTERS_IDS)];
const redirectIfNoReadRulesPermission = redirectIfNoPermission({
  hasPermission: Permissions.READ_SECURITY_RULES_PERMISSION,
});
const ruleDefinitionsTitle: JSX.Element = (
  <Text>
    Logz.io offers a set of updated preconfigured rules. Refine them by editing thresholds and triggers, duplicate for
    full editing access, or create new rules by clicking the New Rule button below.
  </Text>
);

export const rulesRoutes: AppStateDeclaration[] = [
  {
    name: 'dashboard.security.rules',
    url: '/rules',
    redirectTo: 'dashboard.security.rules.definitions',
    data: {
      breadcrumb: { label: 'Rules' },
    },
    component: UIView,
    resolve: [redirectIfNoReadRulesPermission],
  },
  {
    name: 'dashboard.security.rules.definitions',
    url: `/rule-definitions?${params.join('&')}`,
    params: {
      sortBy: 'updatedAt',
      sortOrder: 'DESC',
    },
    dynamic: true,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/AlertDefinitions/Container')}
        resolver={({ Container }) => Container}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Rule definitions',
      duplicatedStateName: SecurityRulesStates.Duplicate,
      editStateName: SecurityRulesStates.Edit,
      addStateName: SecurityRulesStates.New,
      apiService: ruleApiService,
      modelName: 'rule',
      subHeader: ruleDefinitionsTitle,
      breadcrumb: { label: 'Rule Definitions' },
    },
    resolve: [redirectIfNoReadRulesPermission],
  },
];
