import { SearchFilterType, SeverityLevel } from '@logz-pkg/enums';

export const ALERT_DEFINITIONS_PAGE_FILTERS_IDS = {
  severities: 'severities',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy',
  activeState: 'activeState',
  tags: 'tags',
};

export const RULE_DEFINITIONS_PAGE_FILTERS_IDS = {
  severities: 'severities',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy',
  activeState: 'activeState',
  tags: 'tags',
};

export const CrudSeveritiesFilter = {
  type: SearchFilterType.MultiSelect,
  props: {
    label: 'Severity',
    name: 'severities',
    options: [
      { value: SeverityLevel.Info, label: 'Info' },
      { value: SeverityLevel.Low, label: 'Low' },
      { value: SeverityLevel.Medium, label: 'Medium' },
      { value: SeverityLevel.High, label: 'High' },
      { value: SeverityLevel.Severe, label: 'Severe' },
    ],
  },
};
