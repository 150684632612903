import { useRouter as useUiRouter, useCurrentStateAndParams } from '@uirouter/react';
import { appRouter } from 'services/router/router.service';

export const useRouter = () => {
  const { stateService } = useUiRouter();

  const { state, params } = useCurrentStateAndParams();

  return { currentState: state.name, goToState: stateService.go, params };
};

type ParamType = Record<string, string | string[]>;

const setSearchParam = <Params extends ParamType>(params: Params) => {
  appRouter.stateService.go('.', params);
};

export const useSearchParams = <Params extends ParamType>() => {
  const params = [...Object.entries(appRouter.locationService.search())].reduce((acc, [k, v]) => {
    if (typeof v === 'string' && appRouter?.globals?.$current?.params?.[k]?.array === true) v = [v];

    acc[k] = Array.isArray(v) ? v?.map(item => decodeURIComponent(item)) : decodeURIComponent(v);

    return acc;
  }, {}) as Params;

  return {
    params,
    setParams(params: Params) {
      setSearchParam(params);
    },
  };
};
