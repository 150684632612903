import { FeatureFlags, ProductKeys } from '@logz-pkg/enums';
import { ConditionType, ISidenavItemConfig } from './item-configs';
import { ExtendedLogos } from 'ui/components/shared/Logo/Logo';
import { integrationsRouteStates } from 'states/integrations/route-config';

export const integrationsItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'Integrations',
  targetStateName: integrationsRouteStates.page,
  expandedSidenavTitle: '',
  key: ProductKeys.Integrations,
  logo: ExtendedLogos.Integrations,
  expandedNavGroups: [],

  displayConditions: [{ type: ConditionType.HasFeatureFlag, value: FeatureFlags.IntegrationsPage }],
};
