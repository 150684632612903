import { SecurityIOCFeedsRoutingStates as States, Permissions } from '@logz-pkg/enums';
import { threatsFeedsApiService } from '@logz-pkg/frontend-services';
import { LazyReact } from '../../../ui/components/shared/LazyReact';
import { AppStateDeclaration } from '../AppStateDecleration';
import { redirectIfNoPermission } from '../utils';

const redirectIfNoEditThreatsPermission = redirectIfNoPermission({
  hasPermission: Permissions.THREATS_FEEDS_PERMISSION,
});
const redirectIfNoReadThreatsPermission = redirectIfNoPermission({
  hasPermission: Permissions.READ_THREATS_FEEDS_PERMISSION,
});

const LazyPrivateFeedsForm = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Siem/Feeds/Form/Container')}
    resolver={({ PrivateFeedsFormContainer }) => PrivateFeedsFormContainer}
    {...props}
  />
);
export const threatsFeeds: AppStateDeclaration[] = [
  {
    name: States.Table,
    url: '/threat-intelligence-feeds',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Siem/Feeds/Container')}
        resolver={({ ThreatsFeedsContainer }) => ThreatsFeedsContainer}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Threat intelligence feeds',
      breadcrumb: {
        label: 'Threat intelligence feeds',
        parent: 'dashboard.security',
      },
    },
    resolve: [redirectIfNoReadThreatsPermission],
  },
  {
    name: States.New,
    url: '/threat-intelligence-feeds/create',
    component: LazyPrivateFeedsForm,
    data: { authenticate: true, title: 'New feed', breadcrumb: { label: 'Create', parent: States.Table } },
    resolve: [
      redirectIfNoEditThreatsPermission,
      {
        token: 'validatePrivateFeedCount',
        deps: ['$transition$'],
        resolveFn: transition =>
          threatsFeedsApiService.getPrivateFeedsCount().then(privateFeedCount => {
            if (privateFeedCount?.current >= privateFeedCount.max) {
              transition.go(States.Table);
            }
          }),
      },
    ],
  },
  {
    name: States.Edit,
    url: '/threat-intelligence-feeds/:id',
    component: LazyPrivateFeedsForm,
    data: { authenticate: true, title: 'Edit a feed', breadcrumb: { label: 'Edit', parent: States.Table } },
    resolve: [redirectIfNoEditThreatsPermission],
  },
];
