import { FunctionComponent } from 'react';
import { Modal, Title } from '@logz-ui/styleguide';
import { OperatingSystem } from '@logz-pkg/enums';
import { FilebeatConfWizForm } from './Form';

export interface IFilebeatConfWizModalProps {
  os: OperatingSystem;
  onClose: () => void;
}

export const FilebeatConfWizModal: FunctionComponent<IFilebeatConfWizModalProps> = ({ onClose, os }) => {
  return (
    <Modal open onClose={onClose}>
      <Modal.Header>
        <Title weight={700} size={24}>
          Filebeat wizard
        </Title>
      </Modal.Header>
      <FilebeatConfWizForm onClose={onClose} os={os} />
    </Modal>
  );
};
