import { FunctionComponent, useState } from 'react';
import { Popover, Text, Menu } from '@logz-ui/styleguide';
import { HeaderSubjects } from '@logz-pkg/enums';
import { HeaderButton } from './Button';
import { show as showIntercom } from 'services/common/intercom.service';

interface IMenuContentProps {
  closePopover?(): void;
}

const MenuContent: FunctionComponent<IMenuContentProps> = ({ closePopover: onChoose }) => {
  const handleDocsClick = () => {
    onChoose?.();
  };

  const handleChatWithSupportClick = () => {
    showIntercom();
    onChoose?.();
  };

  const handleSupportTicket = () => {
    onChoose?.();
  };

  return (
    <Menu subject={HeaderSubjects.HelpMenu}>
      <Menu.Item
        href={'https://docs.logz.io/'}
        target="_blank"
        onClick={handleDocsClick}
        icon={'life-ring-regular'}
        subject={'docs'}
      >
        <Text>Logz.io Docs</Text>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        href={'https://logzio-support.atlassian.net/servicedesk/customer/portals'}
        target="_blank"
        icon={'pen-regular'}
        onClick={handleSupportTicket}
      >
        <Text>Open a support ticket</Text>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={handleChatWithSupportClick} icon={'comment-regular'}>
        <Text>Chat with support</Text>
      </Menu.Item>
    </Menu>
  );
};

export const HelpMenu: FunctionComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();

  return (
    <Popover
      trigger={'hover'}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      Content={{ Component: MenuContent }}
      closeDelayInMs={200}
      placement={'bottom-end'}
      wrapChildWithRef
    >
      <HeaderButton active={Boolean(isMenuOpen)} subject={HeaderSubjects.HelpButton} icon={'life-ring-regular'}>
        <Text size={12} whiteSpace="nowrap">
          Help
        </Text>
      </HeaderButton>
    </Popover>
  );
};
