import { css } from 'styled-components';
import { classNamePrefix } from '@logz-ui/styleguide/components/inputs/Select/Select.styles';

export const inputCss = css`
  .${classNamePrefix}__control {
    margin: ${({ theme }) => theme.space[2]}px;
    width: initial;
    background-color: ${({ theme }) => theme.colors.gray[0]};

    &:hover:not(.${classNamePrefix}__control--is-focused) {
      border-color: ${({ theme }) => theme.colors.blue[700]};
    }
    .${classNamePrefix}__placeholder {
      color: ${({ theme }) => theme.colors.gray[500]};
    }
  }
`;

export const menuCss = css`
  .${classNamePrefix}__menu {
    position: relative;
    box-shadow: none;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[300]};
    border-radius: 0;

    .${classNamePrefix}__menu-list {
      background: ${({ theme }) => theme.colors.gray[100]};
      padding-bottom: ${({ theme }) => theme.space[2]}px;

      .${classNamePrefix}__option {
        padding: 0;
      }
    }
  }
`;
