import { Observable } from '@logz-pkg/observable';
import { findLast } from 'lodash';

export type TableOfContent = {
  id: string;
  idx: number;
  name: string;
  level: number;
  slug: string;
  anchor: string;
  ref?: any;
};

type TableOfContentItem = Omit<TableOfContent, 'id' | 'idx' | 'slug' | 'anchor'>;

const replaceLastValue = (value: string, replacer: number) => {
  const retVal = [...value];

  retVal[value.length - 1] = replacer.toString();

  return retVal.join('');
};

class TableOfContentService {
  readonly tableOfContent = new Observable<TableOfContent[]>([]);
  readonly tableOfContentReady = new Observable<boolean>(false);

  setTableOfContent = (ref, headerCounter, value: TableOfContentItem): void => {
    const isReady = this.tableOfContentReady.get();

    if (isReady) {
      const currToc = this.tableOfContent.get();

      if (headerCounter in currToc) {
        currToc[headerCounter].ref = ref;

        this.tableOfContent.set(currToc);
      }

      return;
    }

    const currToc = this.tableOfContent.get();
    const slug = value.name
      .replace(/\s+/g, '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .toLowerCase();

    const initTableOfContent = currToc.length === 0;

    if (initTableOfContent) {
      const id = Array(value.level).fill(1).join('-');

      currToc.push({
        id,
        name: value.name,
        level: value.level,
        slug,
        idx: 1,
        anchor: `${id}-${slug}`,
        ref,
      });
      this.tableOfContent.set(currToc);

      return;
    }

    const lastItem = currToc[currToc.length - 1];

    const isSecondTraversal = currToc.length > 1 && currToc[0].name === value.name && currToc[0].level === value.level;

    if (isSecondTraversal) {
      if (!isReady) {
        this.tableOfContentReady.set(true);
      }

      return;
    }

    const newItem = {
      id: undefined,
      idx: undefined,
      name: value.name,
      level: value.level,
      slug,
      anchor: undefined,
      ref,
    };

    const isSameLevelHeader = lastItem.level === value.level;

    if (isSameLevelHeader) {
      newItem.id = replaceLastValue(lastItem.id, lastItem.idx + 1);
      newItem.idx = lastItem.idx + 1;
    }

    const isTraversalInDepth = lastItem.level < value.level;

    if (isTraversalInDepth) {
      newItem.id = `${lastItem.id}-${Array(value.level - lastItem.level)
        .fill(1)
        .join('-')}`;
      newItem.idx = 1;
    }

    const isTraversalToTheTop = lastItem.level > value.level;

    if (isTraversalToTheTop) {
      const lastItemOfSameLevel = findLast(currToc, c => {
        return c.level === value.level;
      });

      if (lastItemOfSameLevel) {
        newItem.id = replaceLastValue(lastItemOfSameLevel.id, lastItemOfSameLevel.idx + 1);
        newItem.idx = lastItemOfSameLevel.idx + 1;
      } else {
        let lastItemOfLowerLevel;

        for (let i = currToc.length - 1; i >= 0; i -= 1) {
          if (currToc[i].level < value.level) {
            lastItemOfLowerLevel = currToc[i];
          }
        }

        const id = lastItemOfLowerLevel ? `${lastItemOfLowerLevel.id}-1` : Array(value.level).fill(1).join('-');

        newItem.id = id;
        newItem.idx = 1;
      }
    }

    const existingElement = currToc.find(c => c.id === newItem.id && c.name === value.name);

    if (existingElement) {
      return;
    }

    newItem.anchor = `${newItem.id}-${slug}`;

    currToc.push(newItem);

    this.tableOfContent.set([...currToc]);
  };

  clearTableOfContent = () => {
    this.tableOfContent.set([]);
    this.tableOfContentReady.set(false);
  };
}

export const tableOfContentService = new TableOfContentService();
