import React, { forwardRef } from 'react';
import { Card, Flex, Menu, Text } from '@logz-ui/styleguide';
import { LogzGraphTestSubjects } from '@logz-pkg/enums';
import { ActionMenuProps } from '../types';
import { formatNumbers } from '../utils/utils';

export const SeriesContextMenu: React.FC<ActionMenuProps> = forwardRef<HTMLDivElement, ActionMenuProps>(
  ({ menuData, menuActions, onClose }, ref) => {
    return (
      <Card subject={LogzGraphTestSubjects.SeriesContextMenuSubject}>
        <Menu ref={ref} width={'250px'}>
          <Flex p={1} justifyContent={'space-between'}>
            <Text mb={'8px'} weight={500} ellipsis>
              {menuData.context?.data?.label ?? menuData.title}
            </Text>
            {!menuData.hideHeaderValue && <Text weight={700}>{formatNumbers(menuData.value)}</Text>}
          </Flex>
          {menuActions.map(actionItem => (
            <Menu.Item
              icon={actionItem.icon ?? 'circle-info-regular'}
              disabled={actionItem.isDisabled ? actionItem.isDisabled(menuData) : false}
              onClick={() => {
                actionItem.handleClick(menuData);
                onClose();
              }}
              key={actionItem.label}
            >
              <Text>{actionItem.label}</Text>
            </Menu.Item>
          ))}
        </Menu>
      </Card>
    );
  },
);

SeriesContextMenu.displayName = 'SeriesContextMenu';
