import { Observable } from '@logz-pkg/observable';
import { EventPopupData, IGraphProps, MenuData } from './types';

export class GraphService {
  isMenuOpenO = new Observable(false);
  menuDataO = new Observable<MenuData | null>(null);
  isEventMenuOpenO = new Observable(false);
  eventMenuDataO = new Observable<EventPopupData | null>(null);
  props: IGraphProps;

  updateProps = (props: IGraphProps) => {
    this.props = props;
  };

  handleCloseMenu = () => {
    this.menuDataO.set(null);
    this.isMenuOpenO.set(false);
  };

  handleClick = ({ menuData }: { menuData: MenuData }) => {
    if (menuData?.context?.data?.isNull) return;

    this.handleCloseMenu();
    this.menuDataO.set(menuData);
    this.isMenuOpenO.set(true);
  };

  handleCloseEventMenu = () => {
    this.eventMenuDataO.set(null);
    this.isEventMenuOpenO.set(false);
  };

  handleEventMenuClick = (data: EventPopupData) => {
    this.props?.onEventClick?.();
    this.isEventMenuOpenO.set(true);
    this.eventMenuDataO.set(data);
  };
}
