import { useObservable } from '@logz-pkg/observable';
import { logsStateService } from './logs-state.service';

export const useLogsState = () => {
  const state = useObservable(logsStateService.state);
  const logsMap = useObservable(logsStateService.logMap);
  const isInitialized = useObservable(logsStateService.isLogMapInitialized);

  return { state, logsMap, isInitialized };
};
