const BLACKLIST_DOMAINS = [
  'elastic.co',
  'cdn.pendo.io',
  'data.guides.logz.io',
  'content.guides.logz.io',
  'data.pendo.io',
  'app.pendo.io',
  'pendo-io-static.storage.googleapis.com',
  'pendo-static-6219552081182720.storage.googleapis.com',
];

export const isAllowedToAddHeaders = url => !BLACKLIST_DOMAINS.filter(domain => url.includes(domain)).length;
