import { AccountType, AppMode, ProductKeys, UserRole } from '@logz-pkg/enums';
import { settingsRoutesStates } from '../../../../../../app/states/settings.routes.names';
import { notificationEndpointsNavigationItem } from './shared-items';
import { ConditionType, ISidenavItemConfig, SidenavComponents } from './item-configs';
import { ExtendedLogos } from 'ui/components/shared/Logo/Logo';

export const settingsItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'Settings',
  expandedSidenavTitle: 'Settings',
  key: ProductKeys.Settings,
  logo: ExtendedLogos.Settings,
  targetStateName: 'dashboard.settings.general',
  expandedNavGroups: [
    {
      items: [
        { icon: 'gear-regular', text: 'General settings', targetStateName: 'dashboard.settings.general' },
        { icon: 'heart-pulse-regular', text: 'System status', targetStateName: 'dashboard.tools.system-status' }, // CHECK
        notificationEndpointsNavigationItem,
      ],
    },
    {
      title: 'Admin Zone',
      displayConditions: [{ type: ConditionType.UserRole, value: UserRole.Admin }],
      items: [
        { text: 'Manage users', icon: 'user-gear-regular', targetStateName: 'dashboard.settings.manage-users' },
        { text: 'Audit trail', icon: 'arrow-progress-regular', targetStateName: 'dashboard.settings.audit-trail' },
        { text: 'Manage tokens', icon: 'key-regular', targetStateName: 'dashboard.settings.manage-tokens.shared' },
        {
          text: 'Manage accounts',
          icon: 'regular-user-circle-check-custom',
          targetStateName: settingsRoutesStates.manageAccounts,
          displayConditions: [
            { type: ConditionType.AppMode, value: AppMode.OPERATIONS },
            { type: ConditionType.AccountOfType, value: AccountType.Owner },
          ],
        },
        {
          text: 'Plan and usage',
          icon: 'file-invoice-regular',
          displayConditions: [{ type: ConditionType.AppMode, value: AppMode.OPERATIONS }],
          targetStateName: 'dashboard.settings.plan-and-billing.plan',
          innerStates: ['dashboard.settings.plan-and-billing.usage'],
        },
      ],
    },
    {
      title: 'Super Admin Tools',
      displayConditions: [{ type: ConditionType.IsSysAdmin, value: true }],
      items: [
        {
          text: 'Admin panel',
          icon: 'wrench-regular',
          targetStateName: 'dashboard.admin',
          openInNewTab: true,
          targetStateParams: { switchToAccountId: null },
        },
        { text: 'Style Guide', icon: 'code-regular', externalUrl: 'https://app.logz.io/styleguide', openInNewTab: true },
      ],
    },
    {
      title: 'Appearance',
      items: [{ component: SidenavComponents.ThemePickerButton, id: SidenavComponents.ThemePickerButton }],
    },
  ],
};
