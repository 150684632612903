import React, { FunctionComponent } from 'react';
import { Group, colors, Spinner, If, FlexColumn } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { AnnouncementModel } from '@logz-pkg/models';
import { HeaderSubjects } from '@logz-pkg/enums';
import { WhatsNewAnnouncement as Announcement } from './Announcement';
import { WhatsNewAnnouncementEmptyState as EmptyState } from './EmptyState';
import { WhatsNewAnnouncementLoadMoreButton as LoadMoreButton } from 'ui/components/WhatsNew/LoadMore';

const Container = styled(Group)`
  flex-grow: 1;
  overflow: auto;
  background-color: ${colors.gray[100]};
  position: relative;
`;

const CenteredContainer: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
  <FlexColumn justifyContent={'center'} alignItems={'center'} flex={'1 0 auto'}>
    {children}
  </FlexColumn>
);

interface IWhatsNewAnnouncementsListProps {
  announcements: AnnouncementModel[];
  hasMore: boolean;
  onLoadMore(): void;
  isLoadingFirstPage: boolean;
  isLoadingMore: boolean;
}

export const WhatsNewAnnouncementsList: FunctionComponent<IWhatsNewAnnouncementsListProps> = ({
  announcements,
  hasMore,
  onLoadMore,
  isLoadingFirstPage,
  isLoadingMore,
}) => (
  <>
    <If condition={!isLoadingFirstPage && announcements.length > 0}>
      <Container vertical fullWidth subject={HeaderSubjects.AnnouncementList} className="styled-scrollbar">
        {announcements.map(announcement => (
          <Announcement announcement={announcement} key={announcement.id} />
        ))}
        {/* Since hasMore is known before the response is returned and we still want to show the spinner */}
        {(isLoadingMore || hasMore) && <LoadMoreButton onClick={onLoadMore} isLoading={isLoadingMore} />}
      </Container>
    </If>
    <If condition={!isLoadingFirstPage && announcements.length === 0}>
      <CenteredContainer>
        <EmptyState />
      </CenteredContainer>
    </If>
    {isLoadingFirstPage && (
      <CenteredContainer>
        <Spinner size={'xl'} subject={HeaderSubjects.LoadingAnnouncements} />
      </CenteredContainer>
    )}
  </>
);
