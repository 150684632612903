import { FunctionComponent } from 'react';
import { Group } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { GuidedWalkthroughSubject } from '@logz-pkg/enums';
import { GuidedWalkthroughTask } from '@logz-pkg/models';
import { Phase } from './Phase';
import { Phases } from 'ui/components/GuidedWalkthrough/Tasks';
import { walkthroughUtils } from 'ui/components/GuidedWalkthrough/walkthrough-utils';

const PhasesContainer = styled(Group)`
  height: 75%;
  overflow-y: auto;
  cursor: default;
`;

interface IPhaseListProps {
  tasks: GuidedWalkthroughTask[];
}

export const PhaseList: FunctionComponent<IPhaseListProps> = ({ tasks }) => {
  const tasksIds = tasks.map(task => task.id);
  const phases = walkthroughUtils.getFilteredPhases({ phases: Phases, tasks: tasksIds });

  if (!phases?.length) return null;

  return (
    <PhasesContainer vertical subject={GuidedWalkthroughSubject.PhasesList} className="styled-scrollbar" width={'100%'}>
      {phases.map(({ name, tasks: phaseTasks, isOpenByDefault }) => {
        return (
          <Phase
            key={name}
            name={name}
            isOpenByDefault={isOpenByDefault}
            tasks={phaseTasks.filter(taskId => tasksIds.includes(taskId))}
          />
        );
      })}
    </PhasesContainer>
  );
};
