import { fetchInterceptor, httpService } from '@logz-pkg/frontend-services';
import { updateAppKibanaVersion } from '../app/osd/osd-settings.service';
import { isAllowedToAddHeaders } from './request-override-common';

export function overrideFetch(targetWindow, id: string = targetWindow.document.baseURI) {
  const { fetch: fetchOrig } = targetWindow;

  if (fetchOrig.isLogzioOverride) return;

  const interceptor = fetchInterceptor(targetWindow, id);

  interceptor.register({
    request: ({ url, config = {} }) => {
      const headers = isAllowedToAddHeaders(url) ? httpService.getDefaultHeaders() : {};

      return { url, config, headers };
    },
    responseError: error => {
      if (error.status === 409 && error.message === 'refresh kibana') updateAppKibanaVersion(error);
      else return Promise.reject(error);
    },
  });

  targetWindow.fetch.isLogzioOverride = true;
}
