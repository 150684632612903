import { useObservable } from '@logz-pkg/observable';
import type { ColumnDef, Row } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { SearchResultRecord } from '../../Table/types';
import { StringCell } from './StringCell';
import { exceptionsService } from 'ui/components/Explore/state/exceptions.service';

interface IStringColumnProps {
  value: string;
  row: Row<SearchResultRecord>;
  columnDef: ColumnDef<SearchResultRecord>;
}

export const ExceptionCell: React.FC<IStringColumnProps> = ({ value, row, columnDef }) => {
  const exceptions = useObservable(exceptionsService.exceptions);
  const alias = useMemo(() => {
    const insight = exceptions.results.find(i => i.id === value);

    return insight?.title ?? value;
  }, [exceptions, value]);

  return <StringCell value={value} alias={alias} row={row} columnDef={columnDef} />;
};
