import { plainToClass } from 'class-transformer';
import { FilebeatLogTypeModel } from '@logz-pkg/models';
import { ErrorHandlingService } from 'services/error-handling.service';
import { LogShippersApiService } from 'services/api/log-shippers/log-shippers.api.service';

const FETCH_FILEBEAT_LOG_TYPES = 'LogShippersApiService - fetch filebeat log types';

export const getFilebeatWizardLogTypes = async (): Promise<FilebeatLogTypeModel[]> => {
  try {
    const data = await LogShippersApiService.generateLogTypes();

    return plainToClass(FilebeatLogTypeModel, data);
  } catch (error) {
    ErrorHandlingService.handleApiError({
      title: FETCH_FILEBEAT_LOG_TYPES,
      error,
    });

    throw error;
  }
};
