import { CopyToClipboard } from '@logz-ui/styleguide';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  copyOnClick?: boolean;
  text?: string;
  onCopy?: () => void;
}

export const CodeWrapper: FC<PropsWithChildren<IProps>> = ({ copyOnClick, children, text, onCopy }) => {
  if (copyOnClick) {
    return (
      <CopyToClipboard text={text} onCopy={onCopy}>
        {children}
      </CopyToClipboard>
    );
  }

  return <div>{children}</div>;
};
