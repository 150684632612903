import { array, object, string, boolean } from 'yup';
import { OperatingSystem, Format, FilebeatConfWizSubjects } from '@logz-pkg/enums';

const fieldConditionSchema = object().shape(
  {
    fieldName: string().when('fieldValue', {
      is: fieldValue => fieldValue && fieldValue.length > 0,
      then: string().required('needs to fill both name and value'),
      otherwise: string(),
    }),
    fieldValue: string().when('fieldName', {
      is: fieldName => fieldName && fieldName.length > 0,
      then: string().required('needs to fill both name and value'),
      otherwise: string(),
    }),
  },
  ['fieldName', 'fieldValue'],
);

export const validationSchema = object().shape({
  logTypeArr: array()
    .of(
      object().shape({
        path: string().required('Path is required'),
        logType: string().required('LogType is required'),
        logTypeOther: string().when('logType', {
          is: logType => logType === 'Other',
          then: string().required('Other value is required'),
          otherwise: string(),
        }),
        multiline: boolean(),
        multilineRegex: string().when(FilebeatConfWizSubjects.Multiline, {
          is: multiline => multiline === true,
          then: string().required('multilineRegex is required when multiline checked'),
          otherwise: string(),
        }),
        fieldConditions: array().of(fieldConditionSchema),
      }),
    )
    .min(1),
});

export const OSOptions = [
  { value: OperatingSystem.Linux, label: 'Linux' },
  { value: OperatingSystem.Windows, label: 'Windows' },
];
export const formatOptions = [
  { value: Format.Plain, label: 'Plain' },
  { value: Format.Json, label: 'Json' },
];

export const getInitialFormValues = (os = OperatingSystem.Linux) => ({
  os,
  logTypeArr: [
    {
      path: '',
      logType: '',
      format: Format.Plain,
      multiline: false,
      multilineRegex: '',
      fieldConditions: [
        {
          fieldName: '',
          fieldValue: '',
        },
      ],
    },
  ],
});

export const logTypeTooltips = {
  path: 'The full path to your log files for a single log type. Use * for wildcard values.',
  type: "This identifies the log type to parse your logs. Logs of the same type are parsed the same way. You can set a custom value by choosing 'Other' from the list.",
  multiline:
    'Choose this option if your log messages span multiple lines. You’ll need to give a regex that identifies the beginning line of each log.',
};

export const osUrls = {
  windows: 'C:\\inetpub\\logs\\LogFiles\\W3SVC1\\u_ex*.log',
  linux: '/var/log/*/*.log',
};

export interface IFormValues {
  os: OperatingSystem;
  logTypeArr: {
    path: string;
    logType: string;
    logTypeOther?: string;
    format: Format;
    multiline: boolean;
    multilineRegex: string;
    fieldConditions: { fieldName: string; fieldValue: string }[];
  }[];
}
