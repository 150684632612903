import { AppMode, ProductKeys } from '@logz-pkg/enums';
import { Button, Flex, Group, Icon, Tag, Text, darkV2Theme } from '@logz-ui/styleguide';
import { UISref, useCurrentStateAndParams, useSref } from '@uirouter/react';
import { FunctionComponent, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigationDisplayCondition } from '../../utils/use-navigaition-display-condition';
import { IExpandedNavItemConfig, IExpandedNavSubItemConfig } from '../item-configs';
import { useNavItemState } from 'ui/components/App/Navigation/utils';
import { Logo } from 'ui/components/shared/Logo/Logo';
import { useUrlParamsStateService } from 'ui/state/hooks/url-params.state.hook';

interface IExpandedNavItemProps {
  config: IExpandedNavItemConfig | IExpandedNavSubItemConfig;
  appMode: AppMode | undefined;
  productName: string;
  productKey: ProductKeys;
  subItem?: boolean;
  currentProduct: ProductKeys;
  subject?: string;
}

interface IStyledIconContainerProps {
  subItem?: boolean;
}

interface IItemContainerProps {
  isActive?: boolean;
  isItemHovering?: boolean;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

export const IconContainer = styled.div<IStyledIconContainerProps>`
  ${({ subItem }) =>
    subItem &&
    css`
      visibility: hidden;
    `};
`;

export const LinkWrapper = styled.a`
  text-decoration: none;
`;

const ItemContainer = styled(Group)<IItemContainerProps>`
  transition: background-color 0.1s ease;
  cursor: pointer;

  ${({ isItemHovering, isActive, theme }) =>
    isItemHovering &&
    !isActive &&
    css`
      // DEV-30252 - implement colors from theme's semantic colors
      background-color: ${theme.name === darkV2Theme.name ? theme.colors.gray[400] : theme.colors.gray[200]};
    `};

  ${({ isActive, theme }) =>
    isActive &&
    css`
      // DEV-30252 - implement colors from theme's semantic colors
      background-color: ${theme.name === darkV2Theme.name ? theme.colors.blue[800] : theme.colors.blue[200]};
    `};
`;

const ItemAction = ({
  config,
  itemAppMode,
  onClick,
}: {
  config: IExpandedNavItemConfig['action'];
  itemAppMode: AppMode | undefined;
  onClick?(): void;
}) => {
  const { name, params } = useNavItemState(config, itemAppMode);
  const [passesConditions] = useNavigationDisplayCondition(config ? [config] : []);

  if (!passesConditions.length) {
    return null;
  }

  return (
    <UISref to={name} params={params}>
      <Button onClick={onClick} subject={config?.subject} size={'xs'} icon={'plus-regular'} round />
    </UISref>
  );
};

export const ExpandedSidenavItem: FunctionComponent<IExpandedNavItemProps> = ({
  config,
  appMode: itemAppMode,
  subItem = false,
  productName,
  productKey,
  currentProduct,
  subject = undefined,
  ...props
}) => {
  const [isItemHovering, setIsItemHovering] = useState(false);
  const { name, params } = useNavItemState(config, itemAppMode);
  const savedParams = useUrlParamsStateService(name);
  const { onClick: onLinkClick, href: routerHref } = useSref(name, savedParams ?? params);
  const href = config.externalUrl ?? routerHref;

  const {
    state: { name: stateName },
  } = useCurrentStateAndParams();

  const isItemCurrentState = useMemo(() => {
    if (currentProduct !== productKey) return false;

    return (
      stateName === config.targetStateName || stateName === (config as IExpandedNavItemConfig)?.action?.targetStateName
    );
  }, [stateName]);

  const handleMouseLeave = () => {
    setIsItemHovering(false);
  };

  const handleMouseEnter = () => {
    setIsItemHovering(true);
  };

  const handleClick = event => {
    if (config.externalUrl) {
      window.open(config.externalUrl, `${config.openInNewTab ? '_blank' : ''}`);
    } else {
      // Need to add this manually becauֵse the user clicks on the div, not the link
      event.target.attributes.target = config.openInNewTab ? '_blank' : undefined;

      // Navigate to state only if the nav item has a state name
      if (config.targetStateName) {
        onLinkClick(event);
      }
    }

    const expandedItemConfig = config as IExpandedNavItemConfig;

    if (expandedItemConfig.onClick) {
      expandedItemConfig.onClick();
    }
  };

  return (
    <LinkWrapper href={href} {...(config.openInNewTab ? { target: '_blank' } : {})} onClick={handleClick}>
      <ItemContainer
        width={'100%'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isActive={isItemCurrentState}
        isItemHovering={isItemHovering}
        justifyContent={'space-between'}
        {...props}
      >
        <Group gap={3} px={5} py={3} alignItems={'center'} fullWidth>
          <IconContainer subItem={subItem}>
            {config.icon && <Icon size={18} color={'royalBlue.500'} icon={config.icon} />}
          </IconContainer>
          {subItem && (
            <IconContainer onClick={handleClick}>
              <Icon size={18} color={'royalBlue.500'} icon={'subitem-light-custom'} />
            </IconContainer>
          )}
          <Text subject={subject} color={'royalBlue.1000'}>
            {config.text}

            {config.beta && (
              <Tag variant={'beta'} my={0} mx={2}>
                BETA
              </Tag>
            )}
          </Text>
          {(config as IExpandedNavItemConfig)?.action && (
            <ItemAction config={(config as IExpandedNavItemConfig).action} itemAppMode={itemAppMode} />
          )}
        </Group>
        {config.productIcon && (
          <Flex px={2}>
            <Logo logo={config.productIcon} alt={`${productName} logo`} mini active={false} />
          </Flex>
        )}
      </ItemContainer>
    </LinkWrapper>
  );
};
