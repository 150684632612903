import { isNumber } from 'lodash';
import moment from 'moment';
import { dateToAbsolute, RelativeValue } from '@logz-pkg/utils';

const formatTimeRange = (from, to) => {
  const formattedDate = { start: '', end: '' };

  if (isNumber(from)) formattedDate.start = moment(from as number).toISOString();
  else formattedDate.start = from as RelativeValue;

  if (isNumber(to)) formattedDate.end = moment(to as number).toISOString();
  else formattedDate.end = to as RelativeValue;

  return formattedDate;
};

const getStartEnd = (from: RelativeValue | number, to: RelativeValue | number) => {
  const start = !isNumber(from) ? dateToAbsolute(from as RelativeValue) * 1000 : (from as number);
  const end = !isNumber(to) ? dateToAbsolute(to as RelativeValue) * 1000 : (to as number);

  return { start, end };
};

export const queryTimeUtils = {
  formatTimeRange,
  getStartEnd,
};
