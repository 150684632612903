import { assignQueryOptions, createQueryString, getUrlResourceParam } from '../../product/route-helpers/route-helpers';
import { LazyReact } from '../../../ui/components/shared/LazyReact';
import { AppStateDeclaration } from '../AppStateDecleration';
import { drilldownFromGrafana } from './explore-in-osd.route';
import { grafanaSnapshotStates } from './grafana-snapshot.route';
import { grafanaUrlQueryParams } from './grafana-route-base';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { getMetricState, metricsService } from 'services/metrics/metrics.service';

export const grafanaResourceUrlParam = 'resource';
export const grafanaResourceUrlParamDefinition = getUrlResourceParam(grafanaResourceUrlParam);
export const grafanaQueryString = createQueryString(grafanaUrlQueryParams);
// DEV-20369 Add dynamic to all routes
export const params = assignQueryOptions({}, ['resource', ...grafanaUrlQueryParams]);
export const grafanaStateName = 'dashboard.grafana';

export const metricsRouteName = 'dashboard.metrics';
export const grafana: AppStateDeclaration[] = [
  ...grafanaSnapshotStates,
  {
    name: metricsRouteName,
    url: `/metrics${grafanaResourceUrlParamDefinition}${grafanaQueryString}`,
    params,
    views: {
      grafana: {
        component: props => (
          <LazyReact
            {...props}
            lazyImport={() => import('ui/components/Grafana/Grafana')}
            resolver={({ GrafanaComponent }) => GrafanaComponent}
          />
        ),
      },
    },
    data: { authenticate: true, title: 'Infrastructure monitoring', breadcrumb: { label: 'Metrics' } },
    sticky: true,
    resolve: [
      {
        token: 'featureEnabled',
        resolveFn: async () => {
          const isGrafanaEnabled = await metricsService.isGrafanaEnabled();

          if (!isGrafanaEnabled) {
            await appModeService.goToModeRootState();
          }
        },
      },
      {
        token: 'metricsState',
        resolveFn: getMetricState,
      },
    ],
    dynamic: true,
  },
  {
    name: grafanaStateName,
    url: `/grafana${grafanaResourceUrlParamDefinition}${grafanaQueryString}`,
    params,
    redirectTo: 'dashboard.metrics',
    data: { authenticate: true, breadcrumb: { label: 'Metrics' } },
    sticky: true,
    dynamic: true,
  },
  {
    name: 'explore-in-kibana',
    data: { authenticate: true },
    url: '/explore-kibana-from-grafana?from&to&query',
    resolve: [
      {
        token: 'drilldownFromGrafana',
        deps: ['$transition$'],
        resolveFn: transition => drilldownFromGrafana(transition.params()),
      },
    ],
  },
];
