import { PrimarySidenav } from '@logz-ui/styleguide';
import { FunctionComponent, memo } from 'react';
import { SidenavSubjects, ProductKeys } from '@logz-pkg/enums';
import { ISidenavItemConfig } from '../item-configs';
import { FixedNavItemList } from './ItemList';

interface IFixedSidenavProps {
  onNavItemHover?(SelectedNavItem: ISidenavItemConfig): void;
  onNavItemLeave?(): void;
  currentProduct: ProductKeys;
  isHoveringOnMenu: boolean;
  isMinified: boolean;
  onSpacerHoverChange?(hover: boolean): void;
}

export const FixedSidenav: FunctionComponent<IFixedSidenavProps> = memo(
  ({ onNavItemHover, onNavItemLeave, currentProduct, isHoveringOnMenu, isMinified, onSpacerHoverChange }) => {
    return (
      <PrimarySidenav isMinified={isMinified} py={4} subject={SidenavSubjects.FixedSidenavContainer}>
        <FixedNavItemList
          onNavItemHover={onNavItemHover as (SelectedNavItem: ISidenavItemConfig) => void}
          onNavItemLeave={onNavItemLeave}
          currentProduct={currentProduct}
          isHoveringOnMenu={isHoveringOnMenu}
          isMinified={isMinified}
          onSpacerHoverChange={onSpacerHoverChange}
        />
      </PrimarySidenav>
    );
  },
);

FixedSidenav.displayName = 'FixedSidenav';
