import { useCurrentStateAndParams } from '@uirouter/react';
import { useCallback, useMemo } from 'react';
import { AIDataSource } from '@logz-pkg/enums';
import { useObservable } from '@logz-pkg/observable';
import { useAiState } from '../ai-state.hook';
import { useAiAnalytics } from '../../hooks/analytics.hook';
import { aiDataSourceList } from './data-sources';
import { aiDataSourceService } from './data-source.service';
import { grafanaStateName, metricsRouteName } from 'states/grafana/grafana.route';
import { useFeatureFlagState } from 'ui/state/hooks';
import { exploreRouteNames } from 'states/explore/explore.route.names';

export const useAiDataSource = () => {
  const dataSource = useObservable(aiDataSourceService.dataSource);
  const {
    state: { name: stateName },
  } = useCurrentStateAndParams();
  const { clear } = useAiState();
  const { report } = useAiAnalytics();

  const { isFeatureEnabled: isAiKnowledgeBase } = useFeatureFlagState('IntegrationsPageGuidanceChat');
  const { isFeatureEnabled: isAllAvailableData } = useFeatureFlagState('aiAgentSearchPro');
  const { isFeatureEnabled: isAgentInsteadAssistant } = useFeatureFlagState('aiAllAgent');

  const dataSources = useMemo<{ value: AIDataSource; label: string; disabled?: boolean }[]>(() => {
    const options = [];

    if ([grafanaStateName, metricsRouteName].includes(stateName)) {
      options.push(aiDataSourceList[AIDataSource.MetricsDashboard]);

      return options;
    }

    if (isAllAvailableData && [exploreRouteNames.explore].includes(stateName)) {
      options.push(aiDataSourceList[AIDataSource.AllAvailableData]);
    }

    if (isAgentInsteadAssistant && stateName === exploreRouteNames.explore) {
      options.push(aiDataSourceList[AIDataSource.CurrentPageAgent]);
    } else {
      options.push(aiDataSourceList[AIDataSource.CurrentPageSearch]);
    }

    if (isAiKnowledgeBase)
      options.push(
        aiDataSourceList[AIDataSource.KnowledgeBaseShipping],
        aiDataSourceList[AIDataSource.KnowledgeBaseUserGuide],
      );

    return options;
  }, [stateName, dataSource, isAiKnowledgeBase, isAllAvailableData, isAgentInsteadAssistant]);

  const setDataSource = useCallback(
    (option: AIDataSource, shouldInitSessionId = true) => {
      clear(shouldInitSessionId);
      aiDataSourceService.setDataSource(option);

      report('Switched data source', { dataSource: option });
    },
    [isAgentInsteadAssistant, stateName],
  );

  const initializeDataSource = useCallback(() => {
    if ([grafanaStateName, metricsRouteName].includes(stateName)) {
      aiDataSourceService.setDataSource(AIDataSource.MetricsDashboard);

      return;
    }

    if (isAllAvailableData && stateName === exploreRouteNames.explore) {
      aiDataSourceService.setDataSource(AIDataSource.AllAvailableData);

      return;
    }

    if (isAgentInsteadAssistant && stateName === exploreRouteNames.explore) {
      aiDataSourceService.setDataSource(AIDataSource.CurrentPageAgent);
    } else {
      aiDataSourceService.setDataSource(AIDataSource.CurrentPageSearch);
    }
  }, [isAgentInsteadAssistant, stateName]);

  const isAgent = useMemo(
    () =>
      [
        AIDataSource.AllAvailableData,
        AIDataSource.CurrentPageAgent,
        AIDataSource.RCA,
        AIDataSource.MetricsDashboard,
      ].includes(dataSource),
    [dataSource],
  );

  return {
    isAgent,
    dataSource,
    setDataSource,
    dataSources,
    initializeDataSource,
  };
};
