import { useMemo } from 'react';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import { aiApiService } from '@logz-pkg/frontend-services';
import { AiChatRoles } from '@logz-pkg/models';
import { AIDataSource, TimeConstants } from '@logz-pkg/enums';
import { markdownToText } from '@logz-pkg/frontend-services/src/dal/utilities/utilities';
import { colorsV2 } from '@logz-ui/styleguide';
import { useAiDataSource } from '../data-source/data-source.hook';
import { HelpQuestionsCardInformationType, HelpQuestionsType } from '../../types';
import { useAiState } from '../ai-state.hook';
import { aiDataSourceBasedQuestions } from './questions';
import { useFeatureFlagState } from 'ui/state/hooks';

export const useAiInitialQuestions = () => {
  const { dataSource } = useAiDataSource();
  const { context } = useAiState();

  const {
    state: { name: stateName },
  } = useCurrentStateAndParams();
  const { isFeatureEnabled: isDynamicQuestionsEnabled } = useFeatureFlagState('AIDynamicQuestions');

  const {
    data: dynamicQuestions,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [`fetch-dynamic-questions`, stateName],
    queryFn: async () => {
      const questions = await aiApiService.aiSearch([
        {
          role: AiChatRoles.User,
          content: `This following data relates to all the following questions:
          ${typeof context === 'string' ? context : JSON.stringify(context)}
          `,
          type: 'dynamicQuestions',
        },
      ]);

      const parsedQuestions: string[] = JSON.parse(`[${questions?.[0]?.message?.content.split('[')[1]}`);

      return parsedQuestions.map<HelpQuestionsCardInformationType>(question => ({
        text: markdownToText(question),
        iconName: 'sparkle-solid',
        color: colorsV2.yellow[600],
      }));
    },
    enabled: dataSource === AIDataSource.AllAvailableData && isDynamicQuestionsEnabled,
    staleTime: TimeConstants.MillisecondsInMinute * 5,
  });

  const initialQuestions = useMemo<HelpQuestionsType>(() => {
    const questions =
      aiDataSourceBasedQuestions?.[dataSource]?.[stateName] ?? aiDataSourceBasedQuestions?.[dataSource]?.default ?? {};

    if (dataSource === AIDataSource.AllAvailableData && !isFetching && isDynamicQuestionsEnabled) {
      questions.dynamic = dynamicQuestions;
    }

    let index = -1;
    const indexedQuestions: HelpQuestionsType = Object.keys(questions).reduce((acc, sectionKey) => {
      if (!questions[sectionKey]) return acc;

      acc[sectionKey] = questions[sectionKey].map<HelpQuestionsType>(question => {
        index += 1;

        return { ...question, index };
      });

      return acc;
    }, {});

    return indexedQuestions;
  }, [dataSource, stateName, dynamicQuestions, isFetching, isDynamicQuestionsEnabled]);

  return {
    initialQuestions,
    refetchDynamicQuestions: refetch,
    isLoadingDynamicQuestions: isFetching,
  };
};
