export const throwAnErrorMock = number => {
  function anotherFunction() {
    throw new Error(`errorId_${number}`);
  }

  function someOtherFunction() {
    anotherFunction();
  }

  someOtherFunction();
};
