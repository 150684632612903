import { Box, Link } from '@logz-ui/styleguide';
import React from 'react';

interface IBooleanCellProps {
  href: string;
  linkText: string;
}

export const LinkCell: React.FC<IBooleanCellProps> = ({ href, linkText }) => {
  return (
    <Box>
      <Link size={14} href={href} onClick={e => e.stopPropagation()}>
        {linkText}
      </Link>
    </Box>
  );
};
