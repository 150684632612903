import { redirectIfFFDisabled, redirectIfNotAdmin, redirectIfNotOwner } from '../utils';
import { AppStateDeclaration } from '../AppStateDecleration';
import { customContentPagesRoute, redirectToCustomIfCustomPagesEnabled } from './content-pages.route';
import { integrationsRouteStates, integrationsRouteUrls } from './route-config';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { appRouter } from 'services/router/router.service';

const redirectIfTelemetryAgentDisabled = redirectIfFFDisabled({
  featureFlag: 'TelemetryAgent',
  toState: integrationsRouteStates.page,
});

const redirectIfTelemetryAgentFleetDisabled = redirectIfFFDisabled({
  featureFlag: 'TelemetryAgentFleet',
  toState: integrationsRouteStates.collectors,
});

const redirectIfEditDisabled = redirectIfFFDisabled({
  featureFlag: 'TelemetryAgentEdit',
  toState: integrationsRouteStates.collectors,
});

const LazyIntegrationsPageContainer = props => (
  <LazyReact
    {...props}
    lazyImport={() => import('ui/components/Integrations/Hub/Layout')}
    resolver={({ IntegrationsHubLayout }) => IntegrationsHubLayout}
  />
);

const LazyIntegrationPageContainer = props => (
  <LazyReact
    {...props}
    lazyImport={() => import('ui/components/Integrations/Single/Layout')}
    resolver={({ IntegrationLayout }) => IntegrationLayout}
  />
);

const LazyTelemetryAgent = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Integrations/Agent/Wizard/WizardContainer')}
    resolver={({ AgentWizardContainer }) => AgentWizardContainer}
    {...props}
  />
);

const LazyTelemetryAgentInstallation = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Integrations/Agent/Installation/Container')}
    resolver={({ TelemetryAgentInstallationContainer }) => TelemetryAgentInstallationContainer}
    {...props}
  />
);
const LazyTelemetryAgentFleet = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Integrations/Agent/Fleet/Container')}
    resolver={({ TelemetryAgentFleetContainer }) => TelemetryAgentFleetContainer}
    {...props}
  />
);
export const integrationsRoutes: AppStateDeclaration[] = [
  {
    name: integrationsRouteStates.page,
    url: integrationsRouteUrls.page,
    component: props => (
      <LazyReact
        {...props}
        lazyImport={() => import('ui/components/Integrations/Container')}
        resolver={({ IntegrationsContainer }) => IntegrationsContainer}
      />
    ),
    redirectTo: integrationsRouteStates.collectors,
    data: {
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
    },
  },
  {
    name: integrationsRouteStates.collectors,
    url: `${integrationsRouteUrls.collectors}?tags&query`,
    params: {
      tags: [],
      query: null,
    },
    dynamic: true,
    component: LazyIntegrationsPageContainer,
    data: {
      breadcrumb: { label: 'Integration hub' },
      title: 'Integration hub',
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
    },
  },
  {
    name: integrationsRouteStates.newCollector,
    resolve: [
      {
        token: 'redirectToNewCollector',
        resolveFn: () => appRouter.stateService.go(integrationsRouteStates.collectors, { tags: 'Quick Setup' }),
      },
    ],
  },
  customContentPagesRoute,
  {
    name: integrationsRouteStates.content,
    url: `${integrationsRouteUrls.content}?anchor`,
    params: {
      anchor: null,
    },
    dynamic: true,
    component: LazyIntegrationPageContainer,
    resolve: [redirectToCustomIfCustomPagesEnabled],
    data: {
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      breadcrumb: {
        label: 'Collectors',
        parent: integrationsRouteStates.collectors,
        calculateLabel: params => params?.content ?? 'Collectors',
      },
    },
  },
  {
    name: integrationsRouteStates.fleet,
    url: `/fleet`,
    component: LazyTelemetryAgentFleet,
    resolve: [
      redirectIfTelemetryAgentDisabled,
      redirectIfTelemetryAgentFleetDisabled,
      redirectIfNotOwner,
      redirectIfNotAdmin,
    ],
    data: {
      breadcrumb: { label: 'Telemetry collector', parent: integrationsRouteStates.collectors },
      title: 'Telemetry collectors',
      authenticate: true,
    },
  },
  {
    name: integrationsRouteStates.create,
    url: '/new',
    component: LazyTelemetryAgent,
    resolve: [redirectIfTelemetryAgentDisabled, redirectIfNotOwner, redirectIfNotAdmin],
    data: {
      breadcrumb: { label: 'Telemetry collector', parent: integrationsRouteStates.collectors },
      title: 'Telemetry collector',
      authenticate: true,
    },
  },
  {
    name: integrationsRouteStates.edit,
    url: '/edit/:id',
    component: LazyTelemetryAgent,
    resolve: [redirectIfTelemetryAgentDisabled, redirectIfNotOwner, redirectIfNotAdmin, redirectIfEditDisabled],
    data: {
      breadcrumb: { label: 'Telemetry collector', parent: integrationsRouteStates.collectors },
      title: 'Telemetry collector',
      authenticate: true,
    },
  },
  {
    name: integrationsRouteStates.installation,
    url: '/installation/:id',
    component: LazyTelemetryAgentInstallation,
    resolve: [redirectIfTelemetryAgentDisabled, redirectIfNotOwner, redirectIfNotAdmin],
    data: {
      breadcrumb: { label: 'Telemetry collector', parent: integrationsRouteStates.collectors },
      title: 'Telemetry collector',
      authenticate: true,
    },
    dynamic: true,
    params: {
      install: {
        inherit: false,
        value: null,
      },
    },
  },
];
