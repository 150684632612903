import { FunctionComponent } from 'react';
import { Badge, Icon, Group, Text, Link } from '@logz-ui/styleguide';
import { HeaderSubjects } from '@logz-pkg/enums';
import { HeaderButton } from 'ui/components/App/Navigation/Header/Button';
import { useWhatsNewState } from 'ui/state/hooks';
import { whatsNewStateService } from 'ui/state/whats-new.state.service';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';
import { useAiState } from 'ui/components/AI/state/ai-state.hook';

export const WhatsNew: FunctionComponent = () => {
  const { unreadAnnouncementsCount, isWhatsNewOpen } = useWhatsNewState();
  const { isDrawerOpen, setIsDrawerOpen } = useGuidedWalkthrough();
  const { toggleDrawer, isDrawerOpen: isDrawerAiOpen } = useAiState();
  const handleOnClick = () => {
    if (isDrawerOpen) setIsDrawerOpen(false);

    if (isDrawerAiOpen) toggleDrawer(false);

    whatsNewStateService.toggleWhatsNew();
  };

  return (
    <Link onClick={handleOnClick} subject={HeaderSubjects.WhatsNewButton}>
      <HeaderButton
        active={isWhatsNewOpen}
        icon={
          unreadAnnouncementsCount > 0 ? (
            <Badge variant={'danger'} subject={HeaderSubjects.NotificationBadge} size={'xxs'}>
              <Icon icon={'star-shooting-regular'} size={18} color={'yellow.600'} />
            </Badge>
          ) : (
            <Icon icon={'star-shooting-regular'} size={18} color={'yellow.600'} />
          )
        }
      >
        <Group gap={1}>
          <Text size={12}>What's New</Text>
        </Group>
      </HeaderButton>
    </Link>
  );
};
