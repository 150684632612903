import { SidenavSubjects } from '@logz-pkg/enums';
import { Link } from '@logz-ui/styleguide';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { useNavItemState } from '../../utils';
import { ISidenavItemConfig } from '../item-configs';
import { highlightColor } from '../config';
import { urlParamsStateService } from 'ui/state/url-params.state.service';
import { useNavigationState } from 'ui/state/hooks';
import { Logo } from 'ui/components/shared/Logo/Logo';
import { appRouter } from 'services/router/router.service';

interface IHomeNavigationItemProps {
  isMinified: boolean;
  highlighted: boolean;
}

const NavigationItemSize = {
  Base: { height: 32, width: 64 },
  Minimized: { height: 24, width: 24 },
};

export const HomeNavigationItem = styled.div<IHomeNavigationItemProps>`
  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
  cursor: pointer;

  ${({ highlighted }) => highlighted && `background-color: ${highlightColor}`};
  ${({ isMinified }) => {
    const sizePropName = isMinified ? 'Minimized' : 'Base';
    const borderRadius = isMinified ? '4px' : '0px';

    return css`
      width: ${NavigationItemSize[sizePropName].width}px;
      height: ${NavigationItemSize[sizePropName].height}px;
      border-radius: ${borderRadius};
    `;
  }}
  &:hover {
    background-color: ${highlightColor};
  }
`;

export interface IHomeSidenavItemProps {
  navigationItem: ISidenavItemConfig;
  active?: boolean;
  selected: boolean;
  beta?: boolean;
}
export const HomeSidenavItem: FunctionComponent<IHomeSidenavItemProps> = ({
  navigationItem,
  selected,
  beta,
  ...props
}) => {
  const { name, params } = useNavItemState(navigationItem, navigationItem.mode);

  const { isMinified } = useNavigationState();

  const handleClick = () => {
    const urlParams = urlParamsStateService.urlParams.get();
    const savedParams = urlParams[name];

    appRouter.stateService.go(name, savedParams ?? params);
  };

  return (
    <Link
      {...props}
      onClick={handleClick}
      currentTab
      subject={`${SidenavSubjects.MainItemLinkPrefix}${navigationItem.key}`}
      className={selected ? 'active' : ''}
    >
      <HomeNavigationItem isMinified={isMinified} highlighted={selected}>
        <Logo logo={navigationItem.logo} alt={navigationItem.fixedSidenavText} active={selected} mini={isMinified} />
      </HomeNavigationItem>
    </Link>
  );
};
