import { StickyStatesPlugin } from '@uirouter/sticky-states';
import { eventEmitter, EVENTS, sessionStateService } from '@logz-pkg/frontend-services';
import { IAppModeValue } from '@logz-pkg/enums';
import { NotificationService } from '@logz-ui/styleguide';
import { isRegionRedirectError } from '../../../hooks/request.hook';
import { switchModeRouteState } from '../../states/switch-mode.routes';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { appRouter } from 'services/router/router.service';
import { switchSessionService } from 'core/auth/services/switch-session.service';

async function tryExitStickyStates() {
  try {
    return appRouter.stateService.go(
      '.',
      { ...appRouter.globals.params },
      {
        exitSticky: (appRouter.getPlugin('sticky-states') as StickyStatesPlugin).inactives(),
      },
    );
  } catch (e) {}
}

const setSwitchToAccountIdParam = accountId =>
  appRouter.stateService.go(
    '.',
    { ...appRouter.globals.params, switchToAccountId: accountId },
    {
      location: 'replace',
      reload: false,
      inherit: true,
    },
  );

const switchAccount = async account => {
  eventEmitter.emit(EVENTS.PRE_KILL_PRODUCT_IFRAMES);
  switchSessionService.setKnownChange(account.accountId);
  await setSwitchToAccountIdParam(account.accountId);

  try {
    await switchSessionService.switchAccount(account.accountId);
  } catch (e) {
    // we should be redirected to login anyway
    if (e.status === 401) return;

    if (e.status === 403) {
      NotificationService.unexpectedError(e.data.message);

      return;
    }

    if (isRegionRedirectError(e)) {
      // detected region-redirect
      // stop switch account flow, wait for redirect account to happen
      return;
    }
  }

  await tryExitStickyStates();
  eventEmitter.emit(EVENTS.RELOAD_PRODUCTS);
  // we want to make sure we clean the params after switching account.
  // in case it contains kibana params or even if it's an account in another region (which invokes an error)
  appRouter.stateService.go(
    '.',
    {},
    {
      location: 'replace',
      reload: true,
      inherit: false,
    },
  );
};

type NextState = { name: string; params: object };

async function goToSwitchedModeState(mode: IAppModeValue, nextState?: NextState) {
  await appRouter.stateService
    .go(
      nextState?.name || mode.rootState,
      { ...nextState?.params },
      {
        location: 'replace',
        reload: true,
        inherit: false,
      },
    )
    .catch(() => {});
}

const switchMode = async (mode: IAppModeValue, nextState?: NextState) => {
  const currentMode = await appModeService.getCurrentMode();

  if (currentMode.value === mode.value && appRouter.globals.current.name !== switchModeRouteState.name) {
    return goToSwitchedModeState(mode, nextState);
  }

  eventEmitter.emit(EVENTS.PRE_KILL_PRODUCT_IFRAMES);

  try {
    await switchSessionService.switchMode(mode);
  } catch (e) {
    // we should be redirected to login anyway
    if (e.status === 401) return;

    if (isRegionRedirectError(e)) {
      // detected region-redirect
      // stop switch account flow, wait for redirect account to happen
      return;
    }

    if (e.status === 403) {
      NotificationService.unexpectedError(e.data.message);
      await tryExitStickyStates();
      await appModeService.goToModeRootState();

      return;
    }
  }

  await tryExitStickyStates();
  await goToSwitchedModeState(mode, nextState);
  eventEmitter.emit(EVENTS.ACCOUNT_SWITCH_MODE, mode);
  await sessionStateService.fetch();
};

export const dashboardHeaderService = {
  switchAccount,
  switchMode,
};
