import { useEffect } from 'react';
import { navigationAppStateService } from 'ui/components/App/Navigation/app-state';
import { useNavigationState, useSessionState } from 'ui/state/hooks';

export const useInitSideNavigation = () => {
  const { loggedInUser } = useSessionState();
  const { setIsMinified } = useNavigationState();

  useEffect(() => {
    if (!loggedInUser?.id) return;

    (async () => {
      const { minified: isNewNavigationMinified } = (await navigationAppStateService.getState()) || {};

      setIsMinified(isNewNavigationMinified);
    })();
  }, [loggedInUser]);
};
