import { GlobalNotificationSubjects, GlobalNotificationType } from '@logz-pkg/enums';
import { authService, globalNotificationsApiService } from '@logz-pkg/frontend-services';
import { GlobalNotificationModel } from '@logz-pkg/models';
import { Button, NotificationService } from '@logz-ui/styleguide';

let pollIntervalId;
let intervalInSeconds: number;
export const timeToFirstNotification = 3000;

export const stopNotifications = () => {
  clearInterval(pollIntervalId);
  // Clearing variable in order to be able to start global-notification once again
  pollIntervalId = undefined;
};

const showGlobalNotifications = (notifications: GlobalNotificationModel[]) => {
  notifications.forEach(notification => {
    const { id, message } = notification;
    const isClosable = notification.type !== GlobalNotificationType.CRITICAL;

    NotificationService[notification.type]({
      subject: GlobalNotificationSubjects.Notification,
      key: id,
      message,
      autoHide: false,
      closable: isClosable,
      onClose: isClosable ? () => globalNotificationsApiService.dismiss(id) : undefined,
      children: !isClosable ? (
        <Button
          onClick={() => {
            NotificationService.closeByKey(id);
            globalNotificationsApiService.dismiss(id);
          }}
        >
          Got it
        </Button>
      ) : undefined,
    });
  });
};

const fetchAndShowGlobalNotifications = async () => {
  if (!authService.isAuthenticated()) {
    stopNotifications();

    return;
  }

  const { notifications, timeToNextPoll } = await globalNotificationsApiService.get();

  showGlobalNotifications(notifications);

  if (timeToNextPoll !== intervalInSeconds) {
    intervalInSeconds = timeToNextPoll;
    clearInterval(pollIntervalId);
    pollIntervalId = setInterval(fetchAndShowGlobalNotifications, intervalInSeconds * 1000);
  }
};

export const startNotificationPolling = () => {
  if (pollIntervalId) return;

  setTimeout(fetchAndShowGlobalNotifications, timeToFirstNotification);
};

export const GlobalNotificationService = {
  startNotificationPolling,
  stopNotifications,
};
