import { redirectIfFFDisabled, redirectIfNotAdmin } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

const queryParams = ['metricNames', 'accountIds', 'pageNumber', 'pageSize'];

export const metricsRollupsRouteName = 'dashboard.tools.metrics-rollup';

const redirectMetricsRollupsPageDisabled = redirectIfFFDisabled({
  featureFlag: 'MetricsRollups',
});

const LazyMetricsRollups = props => (
  <LazyReact
    lazyImport={() => import('ui/components/MetricsRollups/MetricsRollups')}
    resolver={({ MetricsRollups }) => MetricsRollups}
    {...props}
  />
);

export const metricsRollupsRoutes: AppStateDeclaration[] = [
  {
    name: metricsRollupsRouteName,
    url: `/metrics-rollups?${queryParams.join('&')}`,
    component: LazyMetricsRollups,
    dynamic: true,
    data: {
      authenticate: true,
      title: 'Metrics Rollup',
      breadcrumb: { label: 'Metrics Rollup' },
    },
    resolve: [redirectMetricsRollupsPageDisabled, redirectIfNotAdmin],
    params: {
      accountIds: {
        type: 'int',
        array: true,
        value: [],
      },
      metricNames: {
        type: 'string',
        array: true,
        value: [],
      },
      pageNumber: {
        type: 'int',
        value: null,
      },
      pageSize: {
        type: 'int',
        value: null,
      },
    },
  },
];
