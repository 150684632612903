import { useObservable } from '@logz-pkg/observable';
import { guidedWalkthroughStateService } from '../guided-walkthrough.state.service';

export const useGuidedWalkthrough = () => ({
  doneTasksIds: useObservable(guidedWalkthroughStateService.doneTasksIds),
  seenTasksIds: useObservable(guidedWalkthroughStateService.seenTasksIds),
  isActivated: useObservable(guidedWalkthroughStateService.isActivated),
  isDrawerOpen: useObservable(guidedWalkthroughStateService.isDrawerOpen),
  allTasks: guidedWalkthroughStateService.allTasks,
  setIsDrawerOpen: guidedWalkthroughStateService.setIsDrawerOpen,
});
