import { InjectorProxy } from '@logz-pkg/utils';
import { FeatureFlags, UserRole } from '@logz-pkg/enums';
import _ from 'lodash';
import { sessionStateService, sessionApiService, authService } from '@logz-pkg/frontend-services';
import { timelessAccountsService } from '../../../app/dashboard/settings/manage-accounts/timeless-accounts-card/timeless-accounts-service';
import { loadAngularApp } from '../../../app/states/LazyAngular';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { logError, logWarn, reportInfo } from 'ui/components/ProductIframe/report';
import { injectServicesToWindow } from 'services/injectServicesToWindow';
import { services } from 'services';

type FFs = keyof typeof FeatureFlags;

const parseFeatureFlags = function (featureFlags: string[] = []): { [name: string]: FFs } {
  return featureFlags.reduce((result, currFlag) => {
    const featureFlagKey = _.findKey(FeatureFlags, falgVal => falgVal === currFlag);

    if (featureFlagKey) {
      result[featureFlagKey] = currFlag;
    }

    return result;
  }, {});
};

export const getLogzioPresets = async ({ stateName, configGetter, onProductResolve, onPreLoad, stateData }) => {
  injectServicesToWindow(services);

  const isInPublicMode = stateData ? !stateData.authenticate : false;
  const isUserAuthenticatedState = !authService.hasShareToken() && !isInPublicMode;

  reportInfo(stateName, 'getting LogzioPresets', { isUserAuthenticatedState });

  const [{ $injector }, sessionContext, userSession, mode, timelessAccounts = [], config] = await Promise.all([
    loadAngularApp(),
    isUserAuthenticatedState && sessionApiService.get(),
    isUserAuthenticatedState && authService.getUserSession(),
    isUserAuthenticatedState && appModeService.getCurrentMode(),
    isUserAuthenticatedState ? timelessAccountsService.getAllSearchableTimelessIndices() : [],
    configGetter && configGetter(),
    onPreLoad && onPreLoad(),
  ]);

  const presets = {
    configs: {
      featureFlags: parseFeatureFlags(sessionStateService.session.get().featureFlags),
      config,
      account: {
        accountId: sessionContext?.account?.id,
        planType: sessionContext?.plan?.planType,
        currentMode: mode?.value,
        isAdminRole: userSession?.role === UserRole.Admin,
        userRole: userSession?.role,
        hasTimelessAccount: timelessAccounts.length > 0,
      },
      isStateActive: true,
    },
    services: InjectorProxy({
      injector: $injector.get,
      onGetMissingService: logError,
      onGetMissingServiceProperty: logWarn,
      onSetMissingServiceProperty: logWarn,
      onCallMissingServiceMethod: logError,
    }),
    productLoaded: {
      resolve: () => {
        onProductResolve();
      },
    },
  };

  reportInfo(stateName, 'LogzioPresets Done', { presets: presets.configs });

  return presets;
};
