import type { ISidenavItemConfig } from './item-configs';
import { k8sLuiItemConfig } from './kube360-lui';
import { logsItemConfig } from './logs';
import { metricsItemConfig } from './metrics';
import { siemItemConfig } from './siem';
import { tracingItemConfig } from './tracing';
import { app360ItemConfig } from 'ui/components/App/Navigation/Sidenav/item-configs/app-360';

export { dataHubItemConfig } from './data-hub';
export { homeItemConfig } from './home-dashboard';
export { collectionIdToProduct, ProductCollectionIds } from './item-configs';
export type {
  IDisplayCondition,
  IExpandedNavGroup,
  IExpandedNavItemConfig,
  IExpandedNavSubItemConfig,
  ISidenavItemConfig,
  IStateParams,
} from './item-configs';
export { settingsItemConfig } from './settings';
export const SidenavItemConfigs: ISidenavItemConfig[] = [
  logsItemConfig,
  metricsItemConfig,
  tracingItemConfig,
  siemItemConfig,
];
export const App360SideNavConfigs: ISidenavItemConfig[] = [k8sLuiItemConfig, app360ItemConfig];
