import { LogzioSupportedBucketTypes, BucketEndpoints, AuthenticationTypes } from '@logz-pkg/enums';
import { ISelectOption } from '@logz-ui/styleguide';
import { BucketSettingsService } from './bucket-settings.service';
import { IAMBucketSettings, ISecretAccessBucketSettings } from './interfaces';

export const isIAMBucket = bucket => Boolean(bucket.arn);

const bucketTypeToEndpointMapping = {
  cloudtrail: BucketEndpoints.CloudTrail,
  s3: BucketEndpoints.S3,
};

export const getBucketEndpoint = (bucketType: LogzioSupportedBucketTypes) =>
  bucketTypeToEndpointMapping[bucketType] || bucketTypeToEndpointMapping.s3;

export const getDataByBucketType = async (bucketType: string, bucketEndpoint: string) => {
  const apiRequests = [];

  apiRequests.push(
    BucketSettingsService.getBuckets(bucketEndpoint, bucketType.toLowerCase()),
    BucketSettingsService.getAssumeRoleDetails(),
  );

  if (bucketType !== LogzioSupportedBucketTypes.CloudTrail) {
    apiRequests.push(BucketSettingsService.getRegions());
  }

  if (bucketType === LogzioSupportedBucketTypes.S3) {
    apiRequests.push(BucketSettingsService.getSupportedLogTypes());
  }

  return Promise.all(apiRequests);
};

interface IBucketsSettings {
  regions?: ISelectOption<string>;
  logTypes?: ISelectOption<string>;
  addS3ObjectKeyAsLogField?: boolean;
}

const createIAMBucketObject = ({ regions, logTypes, addS3ObjectKeyAsLogField }: IBucketsSettings) => {
  const newBucket: IAMBucketSettings = {
    bucket: '',
    prefix: '',
    arn: '',
    active: true,
    appOnlyId: Math.floor(Math.random() * 100000000),
    type: AuthenticationTypes.IAM,
    addS3ObjectKeyAsLogField,
  };

  if (regions) {
    newBucket.region = regions[0].value;

    if (logTypes) {
      newBucket.logsType = logTypes[0].value;
    }
  }

  return newBucket;
};

const createSecretAccessBucketObject = ({ regions, logTypes, addS3ObjectKeyAsLogField }: IBucketsSettings) => {
  const newBucket: ISecretAccessBucketSettings = {
    bucket: '',
    prefix: '',
    secretKey: '',
    accessKey: '',
    active: true,
    appOnlyId: Math.floor(Math.random() * 100000000),
    type: AuthenticationTypes.SecretAccess,
    addS3ObjectKeyAsLogField,
  };

  if (regions) {
    newBucket.region = regions[0].value;

    if (logTypes) {
      newBucket.logsType = logTypes[0].value;
    }
  }

  return newBucket;
};
export const createBucketObject = (authenticationType: AuthenticationTypes, bucketSetting: IBucketsSettings) => {
  if (authenticationType === AuthenticationTypes.IAM) {
    return createIAMBucketObject(bucketSetting);
  }

  return createSecretAccessBucketObject(bucketSetting);
};
