import { capitalize } from 'lodash';
import { StateDeclaration } from '@uirouter/react';
import type { EventName } from '@logz-pkg/analytics';
import { aiDataSourceService } from '../state/data-source/data-source.service';
import { analyticsService } from 'services/analytics/analytics.service';
import { homeDashboardRouteName } from 'states/home.routes';
import { exploreRouteNames } from 'states/explore/explore.route.names';
import { observabilityRoutesNames } from 'states/observability/observability.routes.names';
import { spmRouteNames } from 'states/spm.routes';
import { securityEventManagementRouteName } from 'states/security/security.routes.names';
import { appRouter } from 'services/router/router.service';

const getEventSource = (stateName: StateDeclaration['name']) => {
  if (
    [
      'dashboard.event-management',
      homeDashboardRouteName,
      exploreRouteNames.explore,
      observabilityRoutesNames.k8s360,
      spmRouteNames.baseRoute,
      securityEventManagementRouteName,
    ].includes(stateName)
  ) {
    return capitalize(stateName.replace('dashboard.', '').replace('.', ' ').replace('_', ' ').replace('-', ' '));
  }

  return 'Observability';
};

export const report = async (eventName: EventName<'AI Copilot'>, extraData: Record<string, any> = {}) => {
  const stateName = appRouter?.globals?.$current?.name;

  await analyticsService.capture('AI Copilot', eventName, {
    invokingFeature: getEventSource(stateName),
    dataSource: aiDataSourceService.dataSource.get(),
    stateName,
    ...extraData,
  });
};
