import { Group, Text } from '@logz-ui/styleguide';
import { EmptyStateActionBox } from '../EmptyStateActionBox';
import { ContactSupport } from 'ui/components/shared/ContactSupport/ContactSupport';

export const GetStarted = () => {
  return (
    <Group vertical gap={3} fullWidth>
      <Text weight={700} size={20} variant={'dark'}>
        Ready to send your data?
      </Text>
      <Group vertical gap={1} fullWidth>
        <EmptyStateActionBox
          title={'Instrument your code and deploy to send traces'}
          contentText={'If you’re ready, we’re ready!'}
          variant={'primary'}
          href={'https://app.logz.io/#/ddashboard/integrations/collectors?tags=Tracing'}
          buttonText={'Get started'}
        />
        <Text color={'gray.800'}>
          Having issues with sending your Traces?&nbsp;
          <ContactSupport text={'Contact Support'} />
        </Text>
      </Group>
    </Group>
  );
};
