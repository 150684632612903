export const preScript = (targetWindow: Window = window) => {
  (function (window, pendo) {
    let i, funcsAmount;

    if (window[pendo] === undefined) {
      window[pendo] = {};
    }

    window[pendo] = window[pendo] || {};

    const obj = window[pendo];

    obj._q = obj._q || [];

    const funcNames = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];

    for (i = 0, funcsAmount = funcNames.length; i < funcsAmount; i += 1)
      (function (funcName) {
        obj[funcName] =
          obj[funcName] ||
          function () {
            obj._q[funcName === funcNames[0] ? 'unshift' : 'push']([funcName].concat([].slice.call(arguments, 0)));
          };
      })(funcNames[i]);
  })(targetWindow, 'pendo');
};
