import { FunctionComponent, useState } from 'react';
import { Box, Button, Link } from '@logz-ui/styleguide';
import { OperatingSystem } from '@logz-pkg/enums';
import { authService } from '@logz-pkg/frontend-services';
import { FilebeatConfWizModal } from './Modal';

interface IFilebeatConfWizProps {
  os: OperatingSystem;
}

export const FilebeatConfWiz: FunctionComponent<IFilebeatConfWizProps> = ({ os }) => {
  const isAuthenticated = authService.isAuthenticated();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return isAuthenticated ? (
    <Box my={4}>
      <Button onClick={openModal} subject={'filebeat-conf-wiz'}>
        Configuration wizard
      </Button>
      {isModalOpen && <FilebeatConfWizModal onClose={handleModalClose} os={os} />}
    </Box>
  ) : (
    <Link currentTab href={'#/login'}>
      Login to configure Filebeat
    </Link>
  );
};
