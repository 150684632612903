import { TracingProductActivationState } from '@logz-pkg/enums';
import { TracingBudgetModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { SearchableAccount } from 'services/tracing/tracing.service';

export class TracingStateService {
  readonly tracingBudgetState = new Observable<TracingBudgetModel | null>(null);

  setTracingBudgetState = (value: TracingBudgetModel) => this.tracingBudgetState.set(value);

  readonly selectedAccountIdState = new Observable<number>(0);

  setSelectedAccountIdState = (value: number) => this.selectedAccountIdState.set(value);

  readonly tracingProductActivationState = new Observable<TracingProductActivationState | null>(null);

  setTracingProductActivationState = (value: TracingProductActivationState | null) =>
    this.tracingProductActivationState.set(value);

  readonly searchableTracingAccounts = new Observable<SearchableAccount[]>([]);

  setSearchableTracingAccounts = value => this.searchableTracingAccounts.set(value);
}
export const tracingStateService = new TracingStateService();
