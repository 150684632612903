import { format } from 'url';
import { stringify } from 'query-string';
import { chain, pick, identity, isArray } from 'lodash';
import { urlParser } from '@logz-pkg/utils';
import { StateParams } from '@uirouter/react';

const anyParam = param => `{${param}:any}`;

const defaultQueryOptions: any = {
  value: null,
  squash: true,
  raw: false,
};

export interface IProductRouteOptions {
  viewUrlPrefix?: string;
  shouldSync?: boolean;
  appQueryParams?: string[];
  dynamicSearchParamsRegexp?: string;
  ignoreRouteUpdate?: (params: { newProductURL: string; appURL }) => boolean;
  refreshOnRoute?: () => boolean;
  shouldNavigate?: (currIframeRoute: string, nextIframeRoute: string) => boolean;
  /**
   * Add switchToAccountId param so you can share the url between different accounts
   */
  addSwitchToAccountId?: boolean;
  /**
   * declare whether the product is using hash routing (eg. angular) or html5 routing (eg. react-router)
   */
  listenOnHashRouting?: boolean;
  listenOnHistoryRouting?: boolean;
  /**
   * removed the "true" from the url param:true to ...paramA=a&param&...
   */
  hideBooleanParams?: boolean;
  productPathPrefix?: string;
  productQueryParams?: readonly string[];
  buildBasePath?: (stateParams?: any) => string;
  getAppPathname?: ({ parsedIframeUrl, pathname }: { parsedIframeUrl: any; pathname: string }) => string;
  getProductPathname?: (params: { resource: string }) => string;
}

export interface IMergeProductToAppUri {
  newProductURL: string;
  appURL: string;
  switchToAccountId?: string;
  appQueryParams?: IProductRouteOptions['appQueryParams'];
  viewUrlPrefix?: IProductRouteOptions['viewUrlPrefix'];
  productPathPrefix?: IProductRouteOptions['productPathPrefix'];
  listenOnHashRouting?: IProductRouteOptions['listenOnHashRouting'];
  getAppPathname?: IProductRouteOptions['getAppPathname'];
}

export const assignQueryOptions = (paramsObj, resourceParams, queryOptions = defaultQueryOptions) =>
  resourceParams.reduce((acc, param) => {
    acc[param] = queryOptions;

    return acc;
  }, paramsObj);

export const getUrlResourceParam = urlParam => `/${anyParam(urlParam)}`;

export const createQueryString = (queryParams, additionalQueryParams = []) =>
  `?${queryParams
    .map(p => anyParam(p))
    .concat(additionalQueryParams)
    .join('&')}`;

/**
 * From angular.js $location code
 * This method is intended for encoding *key* or *value* parts of query component. We need a custom
 * method because encodeURIComponent is too aggressive and encodes stuff that doesn't have to be
 * encoded per http://tools.ietf.org/html/rfc3986:
 *    query         = *( pchar / "/" / "?" )
 *    pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
 *    unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
 *    pct-encoded   = "%" HEXDIG HEXDIG
 *    sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
 *                     / "*" / "+" / "," / ";" / "="
 */
function encodeUriQuery(val) {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%3B/gi, ';')
    .replace(/%20/g, '%20');
}

const replaceSlashes = str => str.replace(/(%2F)/g, (match, capturedGroup) => encodeURIComponent(capturedGroup));

export const createProductIframeRoute = ({
  $stateParams,
  resourceParam,
  queryParams,
  hideBooleanParams,
  parentWindowHref,
  dynamicSearchParamsRegexp,
  getProductPathname,
}: {
  $stateParams: StateParams | {};
  resourceParam: string;
  queryParams: readonly string[];
  hideBooleanParams?: boolean;
  parentWindowHref?: string;
  dynamicSearchParamsRegexp?: string;
  getProductPathname?: IProductRouteOptions['getProductPathname'];
}) => {
  const resourceParamValue = ($stateParams[resourceParam] as string) || '';
  const pathname = resourceParamValue.split('/').map(encodeUriQuery).map(replaceSlashes).join('/');
  let iframeHref: string;

  if (getProductPathname) {
    iframeHref = getProductPathname({ resource: pathname });
  } else {
    iframeHref = pathname ? `/${pathname}` : '';
  }

  const query = chain($stateParams)
    .pick(queryParams)
    .pickBy(identity) // Remove empty items
    .value();

  if (dynamicSearchParamsRegexp) {
    const urlSearchParams = urlParser(parentWindowHref ?? '').hashQuery;
    const regex = new RegExp(dynamicSearchParamsRegexp);

    const matchingParams = chain(urlSearchParams)
      .omit(Object.keys(query))
      .pickBy((value, key) => key.match(regex))
      .value();

    Object.assign(query, matchingParams);
  }

  const currentQuery = Object.entries(query)
    .map(([k, v]) => {
      if (hideBooleanParams && v === true) return k;

      if (isArray(v)) return v.map(item => `${k}=${encodeUriQuery(item)}`).join('&');

      return `${k}=${encodeUriQuery(v)}`;
    })
    .join('&');

  iframeHref += currentQuery ? `?${currentQuery}` : '';

  return iframeHref;
};

export function mergeProductToAppUri({
  newProductURL,
  appURL,
  appQueryParams = [],
  switchToAccountId,
  productPathPrefix,
  listenOnHashRouting,
  getAppPathname,
}: IMergeProductToAppUri) {
  const parsedIframeUrl = urlParser(newProductURL, false);
  const parsedAppUrl = urlParser(appURL);

  const pathname = listenOnHashRouting
    ? replaceSlashes(parsedIframeUrl.hashPathname)
    : parsedIframeUrl?.parsedUrl?.pathname?.replace(productPathPrefix ?? '', '');

  let newAppUrl = format({
    ...parsedAppUrl.parsedUrl,
    hash: format({
      pathname: getAppPathname?.({ parsedIframeUrl, pathname }),
    }),
  });

  const hasIframeQueryParams =
    Object.keys(listenOnHashRouting ? parsedIframeUrl.hashQuery : parsedIframeUrl.parsedQuery).length > 0;

  if (hasIframeQueryParams) {
    if (listenOnHashRouting) {
      const [, hash] = newProductURL.split(`#${parsedIframeUrl.hashPathname}`);

      newAppUrl += hash;
    } else {
      newAppUrl += parsedIframeUrl.parsedUrl.search;
    }
  }

  const updatedAppQueryParams = {
    ...parsedAppUrl.hashQuery,
    ...(switchToAccountId ? { switchToAccountId } : {}),
  };
  const queryParamsToPick = switchToAccountId ? [...appQueryParams, 'switchToAccountId'] : appQueryParams;
  const formattedAppQueryParams = stringify(pick(updatedAppQueryParams, queryParamsToPick));

  if (formattedAppQueryParams) {
    const sign = hasIframeQueryParams ? '&' : '?';

    newAppUrl += `${sign}${formattedAppQueryParams}`;
  }

  return newAppUrl;
}

export const createRouteOptions = ({
  shouldSync = false,
  viewUrlPrefix,
  appQueryParams = [],
  dynamicSearchParamsRegexp,
  ignoreRouteUpdate,
  shouldNavigate = (currIframeRoute, nextIframeRoute) => currIframeRoute !== nextIframeRoute,
  refreshOnRoute = () => false,
  addSwitchToAccountId = false,
  listenOnHashRouting = false,
  listenOnHistoryRouting = false,
  productPathPrefix = '',
  productQueryParams = [],
  buildBasePath,
  hideBooleanParams = false,
  getAppPathname,
  getProductPathname,
}: IProductRouteOptions): IProductRouteOptions => ({
  shouldSync,
  viewUrlPrefix,
  appQueryParams,
  dynamicSearchParamsRegexp,
  ignoreRouteUpdate,
  shouldNavigate,
  addSwitchToAccountId,
  refreshOnRoute,
  listenOnHashRouting,
  listenOnHistoryRouting,
  productPathPrefix,
  productQueryParams,
  buildBasePath,
  hideBooleanParams,
  getAppPathname: getAppPathname
    ? getAppPathname
    : ({ pathname }) => {
        return `${viewUrlPrefix}${pathname}`;
      },
  getProductPathname,
});

export const IFRAME_TYPES = {
  KIBANA: {
    attribute: 'kibana-iframe',
    type: 'KIBANA',
  },
  GRAFANA: {
    attribute: 'grafana-iframe',
    type: 'GRAFANA',
  },
  JAEGER: {
    attribute: 'jaeger-iframe',
    type: 'JAEGER',
  },
};
