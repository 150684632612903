import { XhrInterceptor, httpService } from '@logz-pkg/frontend-services';
import { NotificationService } from '@logz-ui/styleguide';
import { isAllowedToAddHeaders } from './request-override-common';

export const overrideXhr = currentWindow => {
  const { isLogzioOverride } = currentWindow.XMLHttpRequest.prototype;

  if (isLogzioOverride) return;

  const interceptor = XhrInterceptor(currentWindow);

  interceptor.register({
    request: ({ url }) => {
      if (!isAllowedToAddHeaders(url)) {
        // Don't apply our headers to elastic maps request, otherwise we will it will fail due to CORS
        return { url, headers: {} };
      }

      return { url, headers: httpService.getDefaultHeaders() };
    },
  });

  interceptor.register({
    response: data => {
      let queryErrorHeader;
      const logzQueryErrorHeaderName = 'logzio-query-error';

      if (data.xhr.getAllResponseHeaders().indexOf(logzQueryErrorHeaderName) >= 0) {
        queryErrorHeader = data.xhr.getResponseHeader(logzQueryErrorHeaderName);
      }

      if (queryErrorHeader === 'SMART_TIER_PARTIAL_DATA' || queryErrorHeader === 'SMART_TIER_POSSIBLE_PARTIAL_DATA') {
        NotificationService.warning({
          key: 'SMART_TIER_POSSIBLE_PARTIAL_DATA',
          title: 'Your search results may be partial',
          message:
            'Your search results may be partial because your data is temporarily unavailable. Please try again later.\n',
          closable: true,
        });
      }

      return data;
    },
  });

  currentWindow.XMLHttpRequest.prototype.isLogzioOverride = true;
};

export default overrideXhr;
