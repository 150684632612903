import { Permissions } from '@logz-pkg/enums';
import { redirectIfNoPermission } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const SCHEDULED_REPORTS_NEW_UI_STATE = 'dashboard.scheduled-reports-new';
export const SCHEDULED_REPORTS_EDIT_UI_STATE = 'dashboard.scheduled-reports-edit';

const LazyScheduledReportsForm = props => (
  <LazyReact
    lazyImport={() => import('ui/components/ScheduledReports/Form/Container')}
    resolver={({ ScheduledReportsFormContainer }) => ScheduledReportsFormContainer}
    {...props}
  />
);

const redirectIfNoEditReportsPermission = redirectIfNoPermission({ hasPermission: Permissions.SCHEDULED_REPORTS });

export const scheduledReportsForm: AppStateDeclaration[] = [
  {
    name: SCHEDULED_REPORTS_NEW_UI_STATE,
    url: '/scheduled-reports/create',
    component: LazyScheduledReportsForm,
    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      title: 'Create a report',
      breadcrumb: { label: 'New Report', parent: 'dashboard.scheduled-reports' },
    },
    params: { dashboardData: null },
    resolve: [redirectIfNoEditReportsPermission],
  },
  {
    name: SCHEDULED_REPORTS_EDIT_UI_STATE,
    url: '/scheduled-reports/:id',
    component: LazyScheduledReportsForm,
    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      title: 'Edit a report',
      breadcrumb: { label: 'Edit Report', parent: 'dashboard.scheduled-reports' },
    },
    resolve: [redirectIfNoEditReportsPermission],
  },
];
