import React, { useMemo, useRef, useState } from 'react';
import { Input, Group, Title, NotificationService, ConfirmModal, Text } from '@logz-ui/styleguide';
import { IAiConversationModel } from '@logz-pkg/models';
import { aiApiService } from '@logz-pkg/frontend-services';
import moment from 'moment';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useAiDataSource } from '../../state/data-source/data-source.hook';
import { AiConversationsSkeleton } from './ConversationsSkeleton';
import { AiConversationsErrorState } from './ConversationsErrorState';
import { AiConversationsEmptyState } from './ConversationsEmptyState';
import { ConversationRow } from './ConversationRow';
import { useAiConversations } from 'ui/components/AI/state/conversations/conversations.hook';

const formattedConversationTitleDate = (date: Date) => {
  if (!date) return null;

  const startOfDay = moment(date).startOf('day');

  return `${startOfDay.calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY',
  })} - ${startOfDay.format('dddd, MMMM D, YYYY')}`;
};

export const AiConversations: React.FC = () => {
  const {
    setFilter,
    filter,
    isLoadingConversations,
    conversations,
    setConversation,
    isConversationsError,
    fetchConversations,
  } = useAiConversations();
  const { isAgent } = useAiDataSource();
  const [editingConversation, setEditingConversation] = useState<IAiConversationModel>(null);
  const [deletingConversation, setDeletingConversation] = useState<IAiConversationModel>(null);
  const editingConversationModalPlaceholder = useMemo(() => {
    return editingConversation?.title || '';
  }, [editingConversation?.id]);

  const handleSelectConversation = async item => {
    try {
      await setConversation({ item, isAgent });
    } catch (error) {
      NotificationService.danger({ title: 'Unable to load selected conversation' });
    } finally {
      setFilter(null);
    }
  };
  const handleUpdateConversation = async () => {
    try {
      await aiApiService.aiUpdateConversation(editingConversation);
      NotificationService.success({ title: 'Chat renamed successfully' });
      fetchConversations();
    } catch (error) {
      NotificationService.danger({ title: 'Unable to update conversation' });
    } finally {
      setEditingConversation(null);
    }
  };
  const deleteConversation = async () => {
    try {
      await aiApiService.aiDeleteConversation(deletingConversation.id);
      NotificationService.success({ title: 'Chat deleted' });
      fetchConversations();
    } catch (error) {
      NotificationService.danger({ title: 'Unable to delete conversation' });
    } finally {
      setDeletingConversation(null);
    }
  };

  const flatList = useMemo(() => {
    if (!Array.isArray(conversations) || !conversations.length) return [];

    const filteredConversations = conversations.filter(({ title }) =>
      filter ? title.toLowerCase().includes(filter.toLowerCase()) : true,
    );

    return filteredConversations.reduce((acc, conversation, index, arr) => {
      const formattedDate = formattedConversationTitleDate(conversation.createdAt);
      const prevFormattedDate = formattedConversationTitleDate(arr?.[index - 1]?.createdAt);

      const isNewGroup = index === 0 || formattedDate !== prevFormattedDate;

      if (isNewGroup) {
        acc.push({ type: 'header', text: formattedDate });
      }

      acc.push({ type: 'conversation', data: conversation });

      return acc;
    }, []);
  }, [conversations, filter]);

  const parentRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: flatList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: index => (flatList[index].type === 'header' ? 64 : 32),
    overscan: 3,
  });

  if (isConversationsError) {
    return <AiConversationsErrorState onRefetch={fetchConversations} />;
  }

  if (!isLoadingConversations && conversations?.length === 0) {
    return <AiConversationsEmptyState />;
  }

  if (isLoadingConversations) {
    return <AiConversationsSkeleton />;
  }

  return (
    <>
      {editingConversation && (
        <ConfirmModal
          onClose={() => setEditingConversation(null)}
          onConfirm={handleUpdateConversation}
          isConfirmDisabled={editingConversation.title === ''}
          isOpen={!!editingConversation}
          size={'m'}
          title={'Rename'}
        >
          <Input
            placeholder={editingConversationModalPlaceholder}
            value={editingConversation.title}
            onChange={e => setEditingConversation({ ...editingConversation, title: e.target.value })}
          />
        </ConfirmModal>
      )}
      {deletingConversation && (
        <ConfirmModal
          isOpen={!!deletingConversation}
          size={'m'}
          variant={'danger'}
          title={'Delete chat?'}
          onClose={() => setDeletingConversation(null)}
          onConfirm={deleteConversation}
        >
          <Text>
            This will delete <Text weight={700}>{deletingConversation.title}</Text>
          </Text>
        </ConfirmModal>
      )}

      <Group
        vertical
        justifyContent="flex-start"
        alignItems="stretch"
        gap={0}
        width={'100%'}
        height={'1fr'}
        px={8}
        overflow={'auto'}
        ref={parentRef}
      >
        <div style={{ height: rowVirtualizer.getTotalSize(), position: 'relative' }}>
          {rowVirtualizer.getVirtualItems().map(virtualItem => {
            const item = flatList[virtualItem.index];

            return (
              <div
                key={virtualItem.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualItem.start}px)`,
                  padding: 0,
                }}
              >
                {item.type === 'header' ? (
                  <Title size={14} weight={600} color={'royalBlue.600'} mt={8} mb={4} px={0}>
                    {item.text}
                  </Title>
                ) : (
                  <ConversationRow
                    filter={filter}
                    key={item.data.id}
                    onSelect={handleSelectConversation}
                    onDelete={setDeletingConversation}
                    onEdit={setEditingConversation}
                    conversation={item.data}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Group>
    </>
  );
};
