import { userService } from '@logz-pkg/frontend-services';
import { params } from '../../osd/osd-route-params';
import { kibanaVersion } from '../../osd/versions/osd-versions.strategy';
import { LazyReact } from '../../../ui/components/shared/LazyReact';
import { AppStateDeclaration } from '../AppStateDecleration';
import { securitySummaryRouteName } from './security.routes.names';

const LazySummaryComponent = props => (
  <LazyReact
    {...props}
    page={'summary'}
    lazyImport={() => import('./SiemDashboard')}
    resolver={({ SiemDashboard }) => SiemDashboard}
  />
);

export const summaryRoute: AppStateDeclaration[] = [
  {
    name: securitySummaryRouteName,
    url: `/security/summary`,
    params: {
      ...params,
      forceShowQueryBar: {
        value: 'true',
      },
    },
    views: {
      summary: {
        component: LazySummaryComponent,
      },
    },
    resolve: {
      userSummary: async () => (await userService.getSummary()).data,
      baseKibanaPath: () => kibanaVersion.getBaseUrl(),
    },
    sticky: true,
    dynamic: true,
    data: { authenticate: true, title: 'Summary', breadcrumb: { label: 'Summary' } },
  },
];
