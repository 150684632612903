import url from 'url';
import {
  cookiesProvider,
  authTokens,
  cacheProvider,
  authTokenService,
  httpService,
  LoggerService,
  sessionApiService,
  accountService,
} from '@logz-pkg/frontend-services';
import { UxType } from '@logz-pkg/enums';

const ADMIN_STOP_IMPERSONATION_URL = 'logz-admin-stop-impersonation-url';

// Since we operate it only between impersonate button clicked and navigation to the app, we don't need to store it in a more persistent storage
let isDebugMode: boolean;

const _clearPersonalCacheValues = () => {
  [
    cacheProvider.CacheKeys.PLAN_PRICES,
    cacheProvider.CacheKeys.SESSION_CONTEXT,
    cacheProvider.CacheKeys.KIBANA_MULTI_INDEX,
    cacheProvider.CacheKeys.KIBANA_SEARCHED_ACCOUNTS_STATE,
    cacheProvider.CacheKeys.SEARCHABLE_ACCOUNTS,
    cacheProvider.CacheKeys.SUB_ACCOUNTS,
    cacheProvider.CacheKeys.ACCOUNTS_INDEX_MAP,
    cacheProvider.CacheKeys.SHARED_TOKENS,
    cacheProvider.CacheKeys.SHARED_TOKENS_FILTERS,
    cacheProvider.CacheKeys.KIBANA_CONFIG,
    cacheProvider.CacheKeys.OSD_CONFIG,
  ].forEach(cacheProvider.clear);

  accountService.clearAccountListCache();
  sessionApiService.clearCache();
  cacheProvider.clearByPrefix('/account/');
};

const impersonate = async (accountId: number, username: string) => {
  cookiesProvider.withEnv.set(ADMIN_STOP_IMPERSONATION_URL, window.location.href);

  const impersonateUrl = `/__admin__/impersonate?accountId=${accountId}`;
  const { data } = await httpService.post(impersonateUrl, { username });

  _clearPersonalCacheValues();

  const currentAccountId = authTokenService.getCurrentAccountId();

  authTokenService.setCurrentAccountId(`i-${data.accountId}`);
  authTokenService.setAdminAccountId(currentAccountId);

  // Trigger redirect when the user region is not the admin panel region
  await sessionApiService.get({ fresh: true });
  window.location.href = window.location.origin;
};

const impersonateWithDebug = async (accountId: number, username: string) => {
  isDebugMode = true;

  await impersonate(accountId, username);
};

const getRedirectUrl = (redirectUrl: string): string => {
  if (isDebugMode) {
    const parsedUrl = url.parse(redirectUrl);
    const debugHost = parsedUrl?.host?.replace(/\.logz\.io$/, '.internal.logz.io'); // E.g. "app-eu.logz.io" -> "app-eu.internal.logz.io"

    return url.format({
      ...parsedUrl,
      host: debugHost,
    });
  }

  return redirectUrl;
};

const stopImpersonation = async () => {
  _clearPersonalCacheValues();

  try {
    await httpService.post('/app/impersonation/stop');
  } catch (err) {}

  const adminStopImpersonationUrl = cookiesProvider.withEnv.get(ADMIN_STOP_IMPERSONATION_URL);

  cookiesProvider.withEnv.remove(ADMIN_STOP_IMPERSONATION_URL);

  const adminAccountId = authTokenService.getAdminAccountId();

  authTokenService.clearCurrentAccountId();

  if (!adminStopImpersonationUrl) {
    // Probably due to double click on stop impersonation
    window.location.reload();

    return;
  }

  const stopImpersonationParsedUrl = url.parse(adminStopImpersonationUrl);

  if (stopImpersonationParsedUrl.host === window.location.host) {
    window.location.href = adminStopImpersonationUrl;

    if (window.location.pathname === stopImpersonationParsedUrl.pathname) {
      window.location.reload();
    }
  } else {
    // we need to redirect to another region, so we pass the token by cookie.
    cookiesProvider.withEnv.set(authTokens.CURRENT_ACCOUNT_ID_COOKIE, adminAccountId);

    window.location.href = adminStopImpersonationUrl;
  }
};

const isImpersonating = (): boolean => {
  const isActuallyImpersonating = authTokenService.getCurrentAccountId()?.includes('i-');
  const isAdminWithTwoTokens =
    authTokenService.hasAdminAccountId() &&
    authTokenService.getCurrentAccountId() !== authTokenService.getAdminAccountId();

  if (!isActuallyImpersonating) {
    if (isAdminWithTwoTokens) {
      const logMessage = `User has current account id and admin token but he's not impersonating`;

      LoggerService.logError({ message: logMessage, error: '', uxType: UxType.DIALOG });
      authTokenService.clearCurrentAccountId();
      authTokenService.clearAdminAccountId();
      _clearPersonalCacheValues();
    }

    return false;
  }

  if (!isAdminWithTwoTokens) {
    _clearPersonalCacheValues();

    return false;
  }

  return true;
};

export const impersonationService = {
  impersonate,
  impersonateWithDebug,
  getRedirectUrl,
  stopImpersonation,
  isImpersonating,
};
