import { sessionStateService } from '@logz-pkg/frontend-services';
import { GuidedWalkthroughTask, GuidedWalkthroughTaskId } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { ErrorHandlingService } from 'services/error-handling.service';
import { WalkthroughTasks } from 'ui/components/GuidedWalkthrough/Tasks';
import { guidedWalkthroughAppStateService } from 'ui/components/GuidedWalkthrough/app-state.service';
import { walkthroughUtils } from 'ui/components/GuidedWalkthrough/walkthrough-utils';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

class GuidedWalkthroughService {
  readonly doneTasksIds = new Observable<GuidedWalkthroughTaskId[]>([]);
  readonly seenTasksIds = new Observable<GuidedWalkthroughTaskId[]>([]);
  readonly isActivated = new Observable<boolean>(false);
  readonly isDrawerOpen = new Observable<boolean>(false);

  startGuidedWalkthrough = async ({ openDrawer = false } = {}): Promise<void> => {
    const isFeatureEnabled = featureFlagStateService.isFeatureEnabled('GuidedWalkthrough');

    if (!isFeatureEnabled || this.isActivated.get()) return;

    try {
      const { done, seen } = await guidedWalkthroughAppStateService.getTasksStatus();

      const { loggedInUser } = sessionStateService.session.get();
      const authorizedDoneTasks: GuidedWalkthroughTaskId[] = Object.values(WalkthroughTasks)
        .filter(({ id, userRoles }) => done.includes(id) && userRoles.includes(loggedInUser.role))
        .map(({ id }) => id);

      this.doneTasksIds.set(authorizedDoneTasks);
      this.seenTasksIds.set(seen);
      this.isActivated.set(true);

      if (openDrawer) {
        this.isDrawerOpen.set(true);
      }
    } catch (error) {
      ErrorHandlingService.handleApiError({
        title: "Couldn't get tasks status",
        subject: 'guided-walkthrough',
        error,
      });
    }
  };

  markTaskDone = (taskId: GuidedWalkthroughTaskId): void => {
    if (this.doneTasksIds.get().includes(taskId)) return;

    guidedWalkthroughAppStateService.setTaskDone(taskId, WalkthroughTasks[taskId].entityLevel);

    this.doneTasksIds.set(this.doneTasksIds.get().concat(taskId));
  };

  handleCalledEvent = (eventName: string): void => {
    if (!guidedWalkthroughStateService.isActivated.get()) return;

    if (eventName in walkthroughUtils.eventToTaskIdMap) {
      try {
        guidedWalkthroughStateService.markTaskDone(walkthroughUtils.eventToTaskIdMap[eventName]);
      } catch (error) {
        ErrorHandlingService.handleApiError({
          title: "Couldn't mark task as done",
          subject: 'guided-walkthrough',
          extra: { eventName },
          error,
        });
      }
    }
  };

  stop = (): void => {
    if (!this.isActivated.get()) return;

    this.clearStates();
  };

  private clearStates = (): void => {
    this.isActivated.set(false);
    this.isDrawerOpen.set(false);
    this.doneTasksIds.set(null);
    this.seenTasksIds.set(null);
  };

  setIsDrawerOpen = (isOpen: boolean): void => {
    this.isDrawerOpen.set(isOpen);

    if (isOpen) return;

    const newSeenTasks = Object.keys(WalkthroughTasks) as GuidedWalkthroughTaskId[];

    this.setSeenTasks(newSeenTasks);
  };

  setSeenTasks = (tasks: GuidedWalkthroughTaskId[]): void => {
    try {
      guidedWalkthroughAppStateService.setSeenTasks(tasks);
    } catch (error) {
      ErrorHandlingService.handleApiError({
        title: "Couldn't set seen tasks",
        subject: 'guided-walkthrough',
        error,
      });
    }

    this.seenTasksIds.set(tasks);
  };

  get allTasks(): GuidedWalkthroughTask[] {
    const { loggedInUser } = sessionStateService.session.get();

    if (!loggedInUser) return;

    const authorizedTasks = Object.values(WalkthroughTasks).filter(task => task.userRoles.includes(loggedInUser.role));

    return authorizedTasks;
  }
}

export const guidedWalkthroughStateService = new GuidedWalkthroughService();
