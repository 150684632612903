import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { usePopper } from 'react-popper';
import { useObservable } from '@logz-pkg/observable';
import { Chart } from 'highcharts';
import { colorService } from '@logz-pkg/utils';
import { debounce, get } from 'lodash';
import ClickAwayListener from 'react-click-away-listener';
import { createPortal } from 'react-dom';
import { useGraphContext } from '../GraphTheme.provider';
import { colors } from '../../../../../components';
import { IGetOptionsProps } from '../types';
import { EventActionMenu } from './EventActionsMenu';

const MAX_SCROLL_DISTANCE = 50;

interface IGraphEventMenuProps {
  chart: Chart;
  columns: IGetOptionsProps['events']['columns'];
}

const GraphEventMenuContent: React.FC<IGraphEventMenuProps> = ({ chart, columns }) => {
  const { eventMenuDataO, handleCloseEventMenu } = useGraphContext();
  const data = useObservable(eventMenuDataO);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const referenceElement = useMemo(() => data?.pointerEvent?.target as Element, [data?.pointerEvent?.target]);
  const initialScrollPosition = useRef<number | null>(null);

  const autoCloseOnScroll = useCallback(
    debounce(() => {
      if (!popperElement) return;

      const rect = popperElement.getBoundingClientRect();

      if (initialScrollPosition.current === null) {
        initialScrollPosition.current = rect.top;
      }

      if (Math.abs(initialScrollPosition.current - rect.top) > MAX_SCROLL_DISTANCE) {
        handleCloseEventMenu();
      }
    }, 100),
    [popperElement, handleCloseEventMenu],
  );

  useEffect(() => {
    autoCloseOnScroll();
  });

  useEffect(() => {
    if (!data) return;

    initialScrollPosition.current = null;

    data.plotline.data?.svgElem?.attr({
      stroke: colorService.shadeColor(get(colors, data.plotline.color), -50),
    });

    return () => {
      data.plotline.data?.svgElem?.attr({ stroke: get(colors, data.plotline.color) });
    };
  }, [data]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      {
        name: 'flip',
        options: {
          boundary: document.body,
          padding: 8,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const popoverElement = useMemo(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (!chart.container.contains(e.target as Node)) {
        handleCloseEventMenu();

        return;
      }

      const labelElements = chart.container.getElementsByClassName('highcharts-plot-line-label');
      const plotlineElements = chart.container.getElementsByClassName('highcharts-hitbox-plotline');

      const isClickingLabel = Array.from(labelElements).some(element => element.contains(e.target as Node));
      const isClickingPlotline = Array.from(plotlineElements).some(element => element.contains(e.target as Node));

      if (!isClickingLabel && !isClickingPlotline) {
        handleCloseEventMenu();
      }
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div ref={setPopperElement} style={{ ...styles.popper }} {...attributes.popper}>
          <EventActionMenu data={data.events} columns={columns} chart={chart} />
        </div>
      </ClickAwayListener>
    );
  }, [handleCloseEventMenu, chart, columns, data.events, styles, attributes]);

  if (!referenceElement) return null;

  return createPortal(popoverElement, document.body);
};

export const GraphEventMenu: React.FC<IGraphEventMenuProps> = ({ chart, columns }) => {
  const { isEventMenuOpenO } = useGraphContext();
  const isOpen = useObservable(isEventMenuOpenO);

  return isOpen && <GraphEventMenuContent chart={chart} columns={columns} />;
};
