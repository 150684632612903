import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Flex, Group, lightV2Theme, LogzioThemeProvider, Tooltip } from '@logz-ui/styleguide';
import { useTracingState } from 'ui/state/hooks';
import { jaegerAppStateService } from 'services/tracing/state.service';

const Wrapper = styled(Group)`
  width: 350px;
  margin: 0 auto;
`;

const JaegerSelect = styled.select`
  height: 30px;
  min-width: 200px;
  color: white;
  background-color: black;
  border-radius: 3px;
  align-self: center;
`;

interface IJaegerAccountSelector {
  productWindow: any;
}

const tooltipText = 'Choose from which source to display your Tracing data';

export const JaegerAccountSelector: FunctionComponent<IJaegerAccountSelector> = ({ productWindow }) => {
  const { searchableTracingAccounts, selectedAccountIdState, setSelectedAccountId } = useTracingState();
  const onSelectionChange = async e => {
    const selectedAccountId = e.target.value;

    setSelectedAccountId(selectedAccountId);
    await jaegerAppStateService.setLastSelectedAccountId(selectedAccountId);

    // DEV-25288 Use event emitter to reload iframe
    productWindow.document.location.reload();
  };

  return (
    <LogzioThemeProvider theme={lightV2Theme}>
      <Wrapper gap={2}>
        <span>Source:</span>
        <JaegerSelect className={'jaeger-account-selector'} onChange={onSelectionChange} value={selectedAccountIdState}>
          {searchableTracingAccounts.map(tracingAccount => (
            <option key={tracingAccount.accountId} value={tracingAccount.accountId}>
              {tracingAccount.name}
            </option>
          ))}
        </JaegerSelect>
        <Tooltip title={tooltipText} appendToBody={false}>
          <Flex className="anticon anticon-info-circle" />
        </Tooltip>
      </Wrapper>
    </LogzioThemeProvider>
  );
};
