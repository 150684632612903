import { Permissions } from '@logz-pkg/enums';
import { AppStateDeclaration } from './AppStateDecleration';
import { redirectIfNoPermission } from './utils';
import { alertsRoutesStates } from './alerts.routes';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { LazyReact } from 'ui/components/shared/LazyReact';

const redirectIfNoEditAlertPermission = redirectIfNoPermission({ hasPermission: Permissions.ALERTS });
const redirectIfNoEditEndpointPermission = redirectIfNoPermission({ hasPermission: Permissions.ENDPOINT_PERMISSION });

const LazyAlertEdit = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Alerts/Edit/Container')}
    resolver={({ AlertsEditContainer }) => AlertsEditContainer}
    {...props}
  />
);

export const alerts: AppStateDeclaration[] = [
  {
    name: 'dashboard.alerts.endpoints',
    url: '/endpoints',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/NotificationEndpoints/NotificationEndpoints')}
        resolver={({ NotificationEndpoints }) => NotificationEndpoints}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      title: 'Notification endpoints',
      breadcrumb: { label: 'Notification endpoints' },
    },
    resolve: [redirectIfNoEditEndpointPermission],
  },
  {
    name: alertsRoutesStates.v219New,
    url: '/alerts/v2019/new',
    component: LazyAlertEdit,
    params: {
      query: null,
      filters: null,
      accountIds: null,
    },
    data: {
      authenticate: true,
      title: 'Create an alert',
      breadcrumb: { label: 'Create an alert', parent: alertsRoutesStates.alertDefinition },
    },
    resolve: [redirectIfNoEditAlertPermission],
  },
  {
    name: 'dashboard.alerts-v2019-edit',
    url: '/alerts/v2019/:id',
    component: LazyAlertEdit,
    data: {
      authenticate: true,
      title: 'Edit an alert',
      breadcrumb: { label: 'Edit Alert', parent: alertsRoutesStates.alertDefinition },
    },
    resolve: [redirectIfNoEditAlertPermission],
  },
  {
    name: 'dashboard.alerts-v2019-duplicate',
    url: '/alerts/v2019/:id/duplicate',
    component: LazyAlertEdit,
    params: {
      isDuplicated: true,
    },
    data: {
      authenticate: true,
      title: 'Duplicate an alert',
      breadcrumb: { label: 'Duplicate an alert', parent: alertsRoutesStates.alertDefinition },
    },
    resolve: [redirectIfNoEditAlertPermission],
  },
];
