import React from 'react';
import { Group, Skeleton } from '@logz-ui/styleguide';

export const AiConversationsSkeleton: React.FC = () => (
  <Group width={'fullWidth'} vertical justifyContent="flex-start" alignItems="center" mt={10} gap={10}>
    {Array(5)
      .fill(0)
      .map((_, index) => (
        <Group key={index} vertical>
          <Skeleton height={'14px'} width={'138px'} />
          <Skeleton height={'16px'} width={'324px'} />
          <Skeleton height={'16px'} width={'272px'} />
          <Skeleton height={'16px'} width={'306px'} />
        </Group>
      ))}
  </Group>
);
