import { Permissions, PLAN_TYPE } from '@logz-pkg/enums';
import { AppStateDeclaration } from './AppStateDecleration';
import { redirectIfNoPermission, redirectIfNotPlanType } from './utils';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const archiveAndRestoreRouteState = 'dashboard.tools.archive-and-restore';
const redirectIfNoArchivePermission = redirectIfNoPermission({
  hasPermission: Permissions.ARCHIVE,
});

export const archiveAndRestoreRoute: AppStateDeclaration = {
  name: archiveAndRestoreRouteState,
  url: '/archive-and-restore',
  component: props => (
    <LazyReact
      lazyImport={() => import('../dashboard/tools/archive-and-restore/ArchiveAndRestoreContainer')}
      resolver={({ ArchiveAndRestoreContainer }) => ArchiveAndRestoreContainer}
    />
  ),
  data: {
    authenticate: true,
    title: 'Archive and restore',
    breadcrumb: { label: 'Archive and restore' },
  },
  resolve: [redirectIfNoArchivePermission, redirectIfNotPlanType({ notAllowedAccountPlanTypes: [PLAN_TYPE.FREE] })],
};
