/* eslint-disable max-lines */
import { UxType } from '@logz-pkg/enums';
import { LoggerService, dateService } from '@logz-pkg/frontend-services';
import { ElasticsearchEnhancedFilterModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { opensearchtypes } from '@opensearch-project/opensearch/.';
import { SearchHit, SearchResponse } from '@opensearch-project/opensearch/api/types';
import { SeriesColumnOptions } from 'highcharts';
import { isNil } from 'lodash';
import moment from 'moment';
import {
  CONTEXT_MAX_LOGS,
  CONTEXT_MAX_TOKENS,
  CONTEXT_TOKEN_INCREMENT,
  MESSAGE_FIELD_LENGTH,
  additionalPromptInfo,
} from '../constants';
import { exploreSearchParamsService } from '../router/router';
import { SimplifiedData, SimplifiedFilter, SimplifiedGraphData, SimplifiedGroupByGraphData } from '../types';
import { GroupByIdSeries } from './graph-state.service';

class DataCollectionService {
  contextO = new Observable<string>('');
  contextLogCountO = new Observable<number>(0);
  contextTokenSizeO = new Observable<number>(0);
  logStep = 10;
  logOffset = 0;
  private groupByLegendItems = 0;

  private processLogs<TDocument>({
    hits,
    startIndex,
    endIndex,
  }: {
    hits: SearchHit<TDocument>[];
    startIndex: number;
    endIndex: number;
  }): (TDocument & {
    logId: string;
  })[] {
    {
      return hits.slice(startIndex, endIndex).map(log => {
        const logId = log._id;
        const simplifiedLog = { ...log._source, logId };
        const timestamp = simplifiedLog?.['@timestamp'];

        if (!isNil(timestamp)) {
          simplifiedLog['@timestamp'] = dateService.format({
            date: timestamp,
            format: 'MMM DD, YYYY @ HH:mm:ss.SSS',
          });
        }

        if (typeof simplifiedLog === 'object' && simplifiedLog !== null && 'message' in simplifiedLog) {
          const { message } = simplifiedLog as { message: string };

          if (message.length > MESSAGE_FIELD_LENGTH) {
            (simplifiedLog as { message: string }).message = message.slice(0, MESSAGE_FIELD_LENGTH);
          }
        }

        return simplifiedLog;
      });
    }
  }

  collectSimplifiedGroupByData(groupByState: GroupByIdSeries): SimplifiedGroupByGraphData[] {
    const simplifiedGroupByGraphData = groupByState
      .filter(item => item.key !== 'Missing')
      .map(item => {
        const { key, count, series } = item;
        const graphSeries = series.map(({ time, count }) => ({ time, count }));

        return { count, key, graphSeries };
      });

    this.groupByLegendItems = simplifiedGroupByGraphData.length + 1;

    return simplifiedGroupByGraphData;
  }

  collectSimplifiedGraphData(logsSeries: SeriesColumnOptions[]): SimplifiedGraphData[] {
    const simplifiedGraphData = logsSeries[0].data.map(item => {
      if (typeof item !== 'object' || Array.isArray(item)) return { time: '', count: 0 };

      const { x, y } = item;
      const formattedDate: string = moment(x).format('MM/DD/YYYY, h:mm:ss.SSS a');

      return { time: formattedDate, count: y };
    });

    return simplifiedGraphData;
  }

  collectSimplifiedFilters(filters: ElasticsearchEnhancedFilterModel[]): SimplifiedFilter[] {
    const currentFilters = filters.map(filter => {
      const simplifiedFilter: SimplifiedFilter = { filterType: '' };

      if (filter.type === 'LUCENE' && !filter.invalid) {
        simplifiedFilter.filterType = filter.field.type;
        simplifiedFilter.searchedText = filter.value as string;

        return simplifiedFilter;
      }

      simplifiedFilter.filterType = filter.type;
      simplifiedFilter.searchedField = filter.field.name;
      simplifiedFilter.searchedValues = filter.value;

      return simplifiedFilter;
    });

    return currentFilters;
  }

  collectSimplifiedLogs<TDocument>(state: SearchResponse<TDocument>, specifiedLogIndex: number): string {
    const { hits } = state?.hits;

    const specifiedLogId = hits[specifiedLogIndex]?._id;

    const specifiedLog = { ...(hits[specifiedLogIndex]?._source || {}), logId: specifiedLogId };
    let logCount = 1;
    let outgoingContextData: string;
    let stringifiedCurrentContext = JSON.stringify(specifiedLog, this.removeDoubleQuotesReplacer);

    if (exploreSearchParamsService.mode.get() === 'SURROUNDING_LOGS')
      stringifiedCurrentContext = `This indicates that the 'Surrounding Logs' mode is active. In this mode, the specified log is the center of attention, with surrounding logs providing context before and after it. This is the current specified log: <specifiedLog>${stringifiedCurrentContext}</specifiedLog>.`;

    let startReached = false;
    let endReached = false;
    let currentTokenSize = this.calculateTokenSize(stringifiedCurrentContext);
    // Shorten context token limit depending on the number of group by legend items

    let tokenLimit = CONTEXT_MAX_TOKENS - CONTEXT_TOKEN_INCREMENT;

    if (exploreSearchParamsService.groupBy.get()) tokenLimit -= this.groupByLegendItems * 1000;

    while (currentTokenSize <= tokenLimit) {
      const newerLogsStartIndex = Math.max(
        specifiedLogIndex - this.logOffset - this.logStep,
        0,
        specifiedLogIndex - CONTEXT_MAX_LOGS,
      );

      const newerLogsEndIndex = Math.max(specifiedLogIndex - this.logOffset, newerLogsStartIndex);

      const olderLogsEndIndex = Math.min(
        specifiedLogIndex + this.logOffset + this.logStep + 1,
        hits.length,
        specifiedLogIndex + CONTEXT_MAX_LOGS,
      );
      const olderLogsStartIndex = Math.min(specifiedLogIndex + this.logOffset + 1, olderLogsEndIndex);

      const additionalOlderLogs = endReached
        ? ''
        : this.processLogs({
            hits,
            startIndex: olderLogsStartIndex,
            endIndex: olderLogsEndIndex,
          });

      const additionalNewerLogs = startReached
        ? ''
        : this.processLogs({
            hits,
            startIndex: newerLogsStartIndex,
            endIndex: newerLogsEndIndex,
          });

      startReached = newerLogsStartIndex <= 0 || newerLogsStartIndex <= specifiedLogIndex - CONTEXT_MAX_LOGS;
      endReached = olderLogsEndIndex >= hits.length || olderLogsEndIndex >= specifiedLogIndex + CONTEXT_MAX_LOGS;

      const stringifiedOlderLogs = JSON.stringify(additionalOlderLogs, this.removeDoubleQuotesReplacer);
      const stringifiedNewerLogs = JSON.stringify(additionalNewerLogs, this.removeDoubleQuotesReplacer);

      stringifiedCurrentContext = stringifiedNewerLogs + stringifiedCurrentContext + stringifiedOlderLogs;

      const updatedTokenSize = this.calculateTokenSize(stringifiedCurrentContext);

      if (updatedTokenSize > CONTEXT_MAX_TOKENS) {
        LoggerService.logError({
          message: `Outgoing data token size is too big ${updatedTokenSize}`,
          error: {
            message: `Outgoing data token size is too big ${updatedTokenSize}`,
            tokenSize: updatedTokenSize,
            tokenLimit: CONTEXT_MAX_TOKENS,
          },
          uxType: UxType.IN_PAGE,
        });
      } else if (startReached && endReached) {
        logCount += additionalOlderLogs.length + additionalNewerLogs.length;

        outgoingContextData = stringifiedCurrentContext;
        currentTokenSize = updatedTokenSize;
        break;
      }

      this.logOffset += this.logStep;
      logCount += additionalOlderLogs.length + additionalNewerLogs.length;

      outgoingContextData = stringifiedCurrentContext;
      currentTokenSize = updatedTokenSize;
    }

    this.logOffset = 0;

    this.contextLogCountO.set(logCount);

    return outgoingContextData;
  }

  removeDoubleQuotesReplacer(key, value: string): string {
    if (typeof value === 'string') {
      return value.replace(/"/g, '');
    }

    return value;
  }

  collectInitialAiCopilotValue({ simplifiedFilters, groupBy, currentGraphData }: SimplifiedData): string {
    const stringifiedContextData = JSON.stringify(
      [
        additionalPromptInfo,
        'Specified chosen filters: ',
        { searchedFilters: simplifiedFilters ? simplifiedFilters : 'No filters' },
        'The field name to group by: ',
        { groupByField: groupBy ? groupBy : 'No group by field' },
        'The relevant graph data: ',
        { graphData: currentGraphData ? currentGraphData : 'No graph data' },
      ],
      this.removeDoubleQuotesReplacer,
    );

    return stringifiedContextData;
  }

  calculateTokenSize(data: string): number {
    return Math.ceil(data.length / 4);
  }

  calculateAndSetTokenSize(data: string) {
    const tokenSize = this.calculateTokenSize(data);

    if (tokenSize > CONTEXT_MAX_TOKENS) {
      LoggerService.logError({
        message: `Initial data token size is too big ${tokenSize}`,
        error: {
          message: `Initial data token size is too big ${tokenSize}`,
          tokenSize,
          tokenLimit: CONTEXT_MAX_TOKENS,
        },
        uxType: UxType.IN_PAGE,
      });
    }

    this.contextTokenSizeO.set(tokenSize);
  }

  handleOutgoingContext(
    { simplifiedFilters, groupBy, currentGraphData }: SimplifiedData,
    state: opensearchtypes.SearchResponse,
  ) {
    let outgoingData: string;
    const data = this.collectInitialAiCopilotValue({
      simplifiedFilters,
      groupBy,
      currentGraphData,
    });

    if (exploreSearchParamsService.mode.get() === 'SURROUNDING_LOGS') {
      const specifiedLogId = exploreSearchParamsService.contextLogId.get();
      const specifiedLogIndex = state.hits.hits.findIndex(log => log._id === specifiedLogId);
      const surroundingLogs = dataCollectionService.collectSimplifiedLogs(state, specifiedLogIndex);

      outgoingData = `${data} The relevant logs data (${this.contextLogCountO.get()} Logs): ${surroundingLogs}`;
    } else {
      const simplifiedLogs = dataCollectionService.collectSimplifiedLogs(state, 0);

      outgoingData = `${data} The relevant logs data (${this.contextLogCountO.get()} Logs): ${simplifiedLogs}`;
    }

    return outgoingData;
  }

  setExploreContext = (context: string) => {
    this.contextO.set(context);
  };

  clear = () => {
    this.contextO.set('');
    this.contextLogCountO.set(0);
    this.contextTokenSizeO.set(0);
    this.logStep = 10;
    this.logOffset = 0;
    this.groupByLegendItems = 0;
  };
}

export const dataCollectionService = new DataCollectionService();
