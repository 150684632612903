import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Flex, Icon, Input, Link, Modal } from '@logz-ui/styleguide';
import { LoadingResultsSpinner } from './EmptyResultsContainer';
import { useSessionState } from 'ui/state/hooks';
import { services } from 'services/index';

const SearchBar = styled(Modal.Header)`
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  border-radius: 5px 5px 0px 0px;
`;

function LazyResults(props) {
  const Component = useMemo(
    () => React.lazy(() => import('./Results').then(module => ({ default: module.Results }))),
    [],
  );

  return (
    <React.Suspense fallback={<LoadingResultsSpinner />}>
      <Component {...props} />
    </React.Suspense>
  );
}

type GlobalSearchModalProps = {
  open: boolean;
  onClose?: () => void;
  offsetTop?: number;
};

export const GlobalSearchModal: React.FC<GlobalSearchModalProps> = ({ open, onClose, offsetTop }) => {
  const [query, setQuery] = useState('');
  const { loggedInAccount } = useSessionState();
  const shouldShowResults = query || services.platformSearchHistoryApiService.hasSearchHistory(loggedInAccount.id);

  const handleClose = () => {
    setQuery('');
    onClose?.();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      noCloseButton={true}
      alignItems={'flex-start'}
      subject={'global-search-modal'}
      mt={offsetTop ?? 0}
    >
      <SearchBar p={'16px'}>
        <Flex alignItems="center">
          <Icon icon={'magnifying-glass-regular'} size={14} color={'gray.700'} />
          <Input
            autoFocus
            mb={0}
            borderless
            style={{ borderColor: 'transparent' }}
            value={query}
            fontSize={'big'}
            placeholder={`Search ${loggedInAccount.name} account`}
            onChange={({ target }) => setQuery(target.value)}
            context={'global-search-input'}
          />
          {query ? (
            <Link onClick={() => setQuery('')}>
              <Icon size={12} icon={'xmark-regular'} />
            </Link>
          ) : null}
        </Flex>
      </SearchBar>
      {shouldShowResults ? (
        <Modal.Body maxHeight={'600px'} p={'8px'} className={'barakak'}>
          <LazyResults query={query} onSelectedResult={handleClose} />
        </Modal.Body>
      ) : null}
    </Modal>
  );
};
