import { ProductKeys } from '@logz-pkg/enums';
import { useObservable } from '@logz-pkg/observable';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useEffect, useMemo } from 'react';
import { navigationStateService } from '../navigation.state.service';
import { useSessionState } from './session.state.hook';
import { getProductForState } from 'ui/components/App/Navigation/utils';

export const useCurrentProduct = () => {
  const { state, params: stateParams } = useCurrentStateAndParams();
  const { appMode } = useSessionState();
  const appModeValue = appMode?.value;

  const memoizedProductForState = useMemo(
    () => getProductForState({ state: state.name, appMode: appModeValue, stateParams }),
    [state.name, stateParams],
  );

  useEffect(() => {
    if (memoizedProductForState || memoizedProductForState === ProductKeys.None)
      navigationStateService.setCurrentProduct(memoizedProductForState);
  }, [memoizedProductForState]);

  return {
    currentProduct: useObservable(navigationStateService.currentProduct),
    setCurrentProduct: navigationStateService.setCurrentProduct,
  };
};
