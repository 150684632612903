import { Text, CopyToClipboard, Group, Icon, Info } from '@logz-ui/styleguide';

const externalIdTooltip = `Paste this value in the IAM role's trust policy. This works with the account ID to authenticate Logz.io
in your AWS account.`;

const logzioAccountIdTooltip = `Paste this value in the IAM role's trust policy. This work with the external ID to authenticate
Logz.io in your AWS account`;

export const AssumeRoleInformation = ({ assignedExternalId, logzioAWSAccountId }) => (
  <Group vertical>
    <Group subject="assigned-aws-account-id-field-group">
      <Group gap={1}>
        <Text variant={'faded'} mb={0}>
          Account ID
        </Text>
        <Info tooltip={logzioAccountIdTooltip} iconSize={12} />
      </Group>
      <CopyToClipboard text={logzioAWSAccountId} subject={'copy-to-clipboard-account-id'}>
        <Group gap={1}>
          <Text mb={1}>{logzioAWSAccountId}</Text>
          <Icon icon={'copy-regular'} size={14} subject={'copy-account-id'} />
        </Group>
      </CopyToClipboard>
    </Group>
    <Group subject="assigned-external-id-field-group">
      <Group gap={1}>
        <Text variant={'faded'} mb={0}>
          External ID
        </Text>
        <Info tooltip={externalIdTooltip} iconSize={12} />
      </Group>
      <CopyToClipboard text={assignedExternalId} subject={'copy-to-clipboard-external-id'}>
        <Group gap={1}>
          <Text mb={1}>{assignedExternalId}</Text>
          <Icon icon={'copy-regular'} size={14} subject={'copy-external-id'} />
        </Group>
      </CopyToClipboard>
    </Group>
  </Group>
);
