import { Options, Point } from 'highcharts';
import { useCallback, useMemo } from 'react';
import { clone, isNil } from 'lodash';
import { IGetOptionsProps } from '../../../types';

type UsePlotOptions = {
  mainChart: IGetOptionsProps['mainChart'];
  enableMouseTracking: IGetOptionsProps['enableMouseTracking'];
  onClick: IGetOptionsProps['onClick'];
  onMouseOver: IGetOptionsProps['onMouseOver'];
  showMarkerOnHover: IGetOptionsProps['showMarkerOnHover'];
  appTheme: IGetOptionsProps['appTheme'];
};

export const usePlotOptions = ({
  mainChart,
  enableMouseTracking,
  onMouseOver,
  showMarkerOnHover,
  onClick,
  appTheme,
}: UsePlotOptions) => {
  const handleHoverPie = useCallback(
    (point: Point, opacity: number) => {
      mainChart?.series
        ?.find(item => item.name === point.name)
        ?.points?.forEach(point => {
          if (point.graphic) {
            point.graphic.attr({
              opacity,
            });
          }
        });
    },
    [mainChart],
  );

  const plotOptions: Options['plotOptions'] = useMemo(
    () => ({
      line: {
        lineWidth: 1,
      },
      area: {
        lineWidth: 0,
        threshold: null,
      },
      column: {
        borderWidth: 0,
        pointPadding: 0.12,
        groupPadding: 0,
        borderRadius: 0,
        crisp: false, // setting to false help to space dencly packed columns
      },
      pie: {
        ...(mainChart
          ? {
              point: {
                events: {
                  mouseOver() {
                    handleHoverPie(this, 0.3);
                  },
                  mouseOut() {
                    handleHoverPie(this, 1);
                  },
                },
              },
            }
          : {}),
        dataLabels: {
          enabled: !mainChart,
          style: { fontSize: '12px', textOutline: null },
          distance: '10',
          format: '<b>{point.name}</b>: {point.y}',
        },
      },
      series: {
        stickyTracking: true,
        enableMouseTracking,
        ...(onClick ? { cursor: 'pointer' } : {}),
        point: {
          ...(onClick || onMouseOver
            ? {
                events: {
                  ...(!isNil(onClick) && {
                    click(event) {
                      onClick({
                        menuData: {
                          title: this.series.name,
                          value: this.y,
                          location: { x: event.clientX, y: event.clientY },
                          context: clone(this),
                        },
                      });
                    },
                  }),
                  ...(!isNil(onMouseOver) && {
                    mouseOver() {
                      if (onMouseOver) {
                        onMouseOver(this.series.name);
                      }
                    },
                  }),
                },
              }
            : {}),
        },
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 1,
          states: {
            hover: {
              enabled: showMarkerOnHover,
              fillColor: appTheme.graph.markerOnHoverColor,
            },
          },
        },
      },
    }),
    [mainChart, enableMouseTracking, onClick, onMouseOver, showMarkerOnHover, appTheme],
  );

  return plotOptions;
};
