import { impersonationService } from 'services/identity/impersonation/impersonation.service';
import { useSessionState } from 'ui/state/hooks';

export const useImpersonation = () => {
  const { loggedInAccount, loggedInUser, fetchSessionError, appAdminPanelUrl } = useSessionState();

  return {
    username: loggedInUser?.username,
    editAccountUrl: loggedInAccount?.id ? `${appAdminPanelUrl}#/accounts/${loggedInAccount?.id}` : null,
    isError: fetchSessionError,
    isImpersonating: impersonationService.isImpersonating(),
    stopImpersonation: impersonationService.stopImpersonation,
  };
};
