import { ReactStateDeclaration, UIView } from '@uirouter/react';
import { AppStateDeclaration } from './AppStateDecleration';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { DashboardComponent } from 'ui/components/App/Dashboard';
import { playgroundStateService } from 'ui/state/playground.state.service';

export const dashboardRouteState: AppStateDeclaration = {
  abstract: true,
  name: 'dashboard',
  url: '/dashboard?switchToAccountId&awsMarketplaceToken&theme',
  component: DashboardComponent,
  params: {
    switchToAccountId: {
      value: null,
      dynamic: true,
    },
  },
};

export const settingsRouteState: AppStateDeclaration = {
  name: 'dashboard.settings',
  url: '/settings',
  component: UIView,
  redirectTo: 'dashboard.settings.general',
  data: { authenticate: true, breadcrumb: { label: 'Settings' } },
};

export const toolsRouteState: AppStateDeclaration = {
  name: 'dashboard.tools',
  url: '/tools',
  component: UIView,
  redirectTo: 'dashboard.settings.general',
  data: { authenticate: true, breadcrumb: { label: 'Tools' } },
};

export const playgroundRoute: ReactStateDeclaration = {
  name: 'dashboard.sandbox',
  url: '/sandbox',
  data: { authenticate: true },
  resolve: [
    {
      token: 'RedirectToRootState',
      resolveFn: () => {
        playgroundStateService.setPlaygroundModalOpen(true);

        return appModeService.goToModeRootState();
      },
    },
  ],
};
