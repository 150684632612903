import { minBy } from 'lodash';
import {
  cacheProvider,
  authService,
  metricsApiBudgetService,
  metricsApiPlanService,
  userService,
  metricsAccountApiService,
  sessionApiService,
} from '@logz-pkg/frontend-services';
import { AccountType, UserRole } from '@logz-pkg/enums';
import {
  accountModelManipulator,
  MetricsShippingTokensModel,
  MetricsAccountPlanModel,
  MetricsCompactAccountsSummaryModel,
} from '@logz-pkg/models';
import { ErrorHandlingService } from 'services/error-handling.service';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { IMetricsTourParams } from 'services/tours/types';

const METRICS_ACCOUNTS_CACHE_KEY = 'metrics-accounts';

const clearMetricsAccountsCache = () => {
  cacheProvider.clear(METRICS_ACCOUNTS_CACHE_KEY);
};

const getAdminDetailedMetricsAccounts = () => metricsApiBudgetService.getDetailed();
const getAuthorizedMetricsAccounts = () => metricsAccountApiService.getAll();

const get = ({ id }: { id: number }): Promise<MetricsAccountPlanModel> => metricsApiPlanService.get(id);

const create = (metricsAccountPlan: MetricsAccountPlanModel): Promise<{ id: number }> => {
  clearMetricsAccountsCache();

  return metricsApiPlanService.createAccount(metricsAccountPlan);
};

const update = ({ id }, metricsAccountPlan: MetricsAccountPlanModel): Promise<{ id: number }> => {
  clearMetricsAccountsCache();

  return metricsApiPlanService.update(metricsAccountPlan, { id });
};

const remove = ({ id }): Promise<MetricsAccountPlanModel> => {
  clearMetricsAccountsCache();

  return metricsApiPlanService.delete(id);
};

async function isLoggedInToOwnerAccount() {
  const { data: userSummary } = await userService.getSummary();

  return accountModelManipulator.deprecatedIsOwner(userSummary.accountType);
}

const getState = async ({ cache = true } = {}) => {
  try {
    const [isOwner, isAdmin] = await Promise.all([isLoggedInToOwnerAccount(), authService.isAdminRole()]);

    const { active, searchableAccounts: metricsAccounts } = await getCompactMetricsAccountsSummary({ cache });

    const hasMetricsAccount = metricsAccounts && metricsAccounts.length > 0;
    const isNewToGrafana = !hasMetricsAccount || !active;
    const canCreateMetricsAccount = isAdmin && isOwner;

    return {
      hasMetricsAccount,
      isNewToGrafana,
      canCreateMetricsAccount,
    };
  } catch (error) {
    await ErrorHandlingService.logError('Failed to get metrics sub accounts data', error);

    return {
      hasMetricsAccount: true,
      isNewToGrafana: false,
      canCreateMetricsAccount: false,
    };
  }
};

export const getMetricState = async () => {
  const currentState = await getState();

  if (!currentState.isNewToGrafana) return currentState;

  return getState({ cache: false });
};

const createFreeMetricsAccount = async (): Promise<{ id: number }> => {
  clearMetricsAccountsCache();

  return metricsApiBudgetService.createFreeTrial();
};

const getCompactMetricsAccountsSummary = ({ cache = true } = {}): Promise<MetricsCompactAccountsSummaryModel> => {
  if (!cache) {
    clearMetricsAccountsCache();
  }

  return cacheProvider
    .get(METRICS_ACCOUNTS_CACHE_KEY, () => metricsApiPlanService.getCompactMetricsAccountsSummary())
    .catch(error => {
      ErrorHandlingService.logError('Failed to get authorized metrics accounts', error);

      return { active: false, searchableAccounts: [] };
    });
};

async function isDeprecatedElasticsearchProductEnabled() {
  const isOwnerAccount = await isLoggedInToOwnerAccount();

  if (isOwnerAccount) return true;

  const { searchableAccounts } = await getCompactMetricsAccountsSummary();

  return searchableAccounts.length > 0;
}

async function isGrafanaEnabled() {
  const isMetricsProductEnabled = featureFlagStateService.isFeatureEnabled('metricsProduct');
  const isM3dbMetricsAccount = featureFlagStateService.isFeatureEnabled('M3dbMetricsAccount');

  if (!isMetricsProductEnabled) return false;

  if (isM3dbMetricsAccount) return true;

  return isDeprecatedElasticsearchProductEnabled();
}

async function getTourParams(): Promise<IMetricsTourParams> {
  let isMetricsActive = false;
  let metricsAccountCreateDate;

  if (await isGrafanaEnabled()) {
    ({ active: isMetricsActive } = await getCompactMetricsAccountsSummary());

    const {
      user: { role: userRole },
      account: { type: accountType },
    } = await sessionApiService.get();

    const isAdmin = userRole === UserRole.Admin;
    const isOwnerAccount = accountType === AccountType.Owner;

    if (isOwnerAccount && isAdmin) {
      try {
        const { metricsAccounts = [] } = await getAdminDetailedMetricsAccounts();

        if (metricsAccounts.length) {
          metricsAccountCreateDate = minBy(metricsAccounts, 'createdAt')?.createdAt;
        }
      } catch (error) {
        ErrorHandlingService.logError('Failed to get tour detailed metrics account summary', error);
      }
    }
  }

  return {
    isMetricsActive,
    metricsAccountCreateDate,
  };
}

const isGrafanaActive = async (): Promise<boolean> => (await isGrafanaEnabled()) && (await getState()).hasMetricsAccount;

const getMetricsTokens = async (): Promise<MetricsShippingTokensModel> => {
  if (!(await isGrafanaEnabled()) || !(await authService.isAdminRole()) || !(await isLoggedInToOwnerAccount()))
    return {};

  const { metricsAccounts } = await getAdminDetailedMetricsAccounts();

  const m3dbAccount = metricsAccounts.find(metricAccount => metricAccount.dataStoreType === 'M3DB');
  const esMetricsAccount = metricsAccounts.find(metricAccount => metricAccount.dataStoreType === 'ES');

  return {
    metricsPrometheusToken: m3dbAccount ? m3dbAccount.token : null,
    metricsElasticsearchToken: esMetricsAccount ? esMetricsAccount.token : null,
  };
};

export const metricsService = {
  getAdminDetailedMetricsAccounts,
  getAuthorizedMetricsAccounts,
  get,
  create,
  update,
  remove,
  getState,
  createFreeMetricsAccount,
  getCompactMetricsAccountsSummary,
  isGrafanaEnabled,
  getTourParams,
  isGrafanaActive,
  getMetricsTokens,
};
