import { LoggerService } from '@logz-pkg/frontend-services';
import { AccountModel } from '@logz-pkg/models';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { useEffect, useState } from 'react';
import { navigationAppStateService } from 'ui/components/App/Navigation/app-state';

export const usePinnedAccounts = () => {
  const [pinnedAccounts, setPinnedAccounts] = useState<AccountModel['id'][]>();
  const { action: getNavigationState } = useAsyncAction(navigationAppStateService.getState, {
    onSuccess: data => {
      setPinnedAccounts(data?.pinnedAccounts);
    },
  });

  useEffect(() => {
    getNavigationState();
  }, []);

  const update = (accountIds: AccountModel['id'][]) => {
    setPinnedAccounts(accountIds);
    navigationAppStateService.setPinnedAccounts(accountIds);
  };

  const pinAccount = (id: AccountModel['id']) => {
    if (pinnedAccounts) {
      const updatedStarredAccounts = [...pinnedAccounts, id];

      update(updatedStarredAccounts);
    } else LoggerService.logError({ message: 'Pinned Accounts are undefined' });
  };
  const unPinAccount = (id: AccountModel['id']) => {
    if (pinnedAccounts) {
      const updatedStarredAccounts = pinnedAccounts.filter(accountId => accountId !== id);

      update(updatedStarredAccounts);
    } else LoggerService.logError({ message: 'Pinned Accounts are undefined' });
  };

  return { pinnedAccounts, pinAccount, unPinAccount };
};
