import { ISelectedAccount } from './Types';

export const genericGetKnownStringsToReplaceMap = [
  { match: /\[\[(\w+ \d+)]]/g, to: '**$1: **' },
  { match: /^---[\s\S\n]*?---/gms, to: '' },
  { match: /{:\.override\.btn-img}\n/g, to: '' },
  { match: /{:\.[\w-/]+}\n/g, to: '' },
  { match: /{:\.paramlist}\n/g, to: '' },
  { match: /{:\.no_toc}\n/g, to: '' },
  { match: /{: \.tasklist \.firstline-headline }\n/g, to: '' },
  { match: /<div class="tasklist">/g, to: '' },

  // DEV-23861 remove regex of html removal when markdown will be clean
  { match: /{% include [\w/-]+.html\s*(:?\w+='\w+')?\s+%}(:?\n\s)?/g, to: '' },
  { match: /<div.*?>\n\s*/g, to: '' },
  { match: /<\/?div>\n?/g, to: '' },
  { match: /<!-- tabContainer:start -->/g, to: '' },

  { match: /{{site.baseurl}}/, to: 'https://docs.logz.io' },
];

export const getKnownStringsToReplaceMap = ({ account, apiToken, listenerAddress, regionCode }: ISelectedAccount) => [
  ...genericGetKnownStringsToReplaceMap,
  { match: /<<LOG-SHIPPING-TOKEN>>/g, to: account?.token },
  { match: /<<API-TOKEN>>/g, to: apiToken },
  { match: /<<PROMETHEUS-METRICS-SHIPPING-TOKEN>>/g, to: account?.token },
  { match: /<<METRICS-SHIPPING-TOKEN>>/g, to: account?.token },
  { match: /<<TRACING-SHIPPING-TOKEN>>/g, to: account?.token },
  { match: /<<SPM-METRICS-SHIPPING-TOKEN>>/g, to: account?.additionalToken?.value },
  { match: /<<LISTENER-HOST>>/g, to: listenerAddress },
  { match: /<<LOGZIO_ACCOUNT_REGION_CODE>>/g, to: regionCode },
];
