import { Button, Container, Group, ShadowScroll, Text } from '@logz-ui/styleguide';
import { Cell } from '@tanstack/react-table';
import copy from 'copy-to-clipboard';
import React, { useRef } from 'react';
import { useObservable } from '@logz-pkg/observable';
import { TableCell } from '../TableCell';
import { tableColumnStateService } from 'ui/components/Explore/state/table-columns.service';

interface IExpandCellProps {
  cell: Cell<any, unknown>;
  fieldName: string;
  closePopover?: () => void;
}

export const ExpandCell = React.memo<IExpandCellProps>(({ cell, fieldName, closePopover }) => {
  const tableRef = useObservable(tableColumnStateService.tableRef);
  const containerRef = useRef(null);
  const value = cell.getValue() as string;
  const field = cell.column.columnDef.header.toString();

  return (
    <ShadowScroll parentRef={containerRef}>
      <Container
        minWidth={'200px'}
        minHeight={'50px'}
        width={'500px'}
        maxHeight={'500px'}
        border
        borderRadius="sm"
        variant="neutral"
        ref={containerRef}
        overflow="auto"
        px={2}
        pb={2}
        resize="both"
        shadow="large"
      >
        <Container
          data-action-item-drag-handle
          style={{ cursor: 'grab' }}
          variant="neutral"
          borderBottom
          sticky
          fullWidth
        >
          <Group alignItems="center" gap={0} px={2} py={1} fullWidth justifyContent="space-between">
            <Text weight={600}>{field}</Text>
            <Group>
              <Button
                size="xs"
                variant="tertiary"
                icon="magnifying-glass-regular"
                onClick={() => tableRef?.scrollToRow(cell.row.index, true)}
                aria-label="Scroll to row"
              />
              <Button
                size="xs"
                variant="tertiary"
                icon="copy-regular"
                onClick={() => copy(value)}
                aria-label="Copy value"
              />
              <Button
                size="xs"
                variant="tertiary"
                icon="xmark-regular"
                onClick={closePopover}
                aria-label="Close expand"
              />
            </Group>
          </Group>
        </Container>
        <TableCell cell={cell} isExpanded={true} fieldName={fieldName} />
      </Container>
    </ShadowScroll>
  );
});

ExpandCell.displayName = 'ExpandCell';
