import { Observable } from '@logz-pkg/observable';

class UrlParamsStateService {
  readonly urlParams = new Observable<Record<string, object>>({});

  clear = () => this.urlParams.set({});
  set = (name: string, params: object) => {
    this.urlParams.set({ ...this.urlParams.get(), [name]: params });
  };
}

export const urlParamsStateService = new UrlParamsStateService();
