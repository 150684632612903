import { SeriesOptionsType } from 'highcharts';
import moment from 'moment-timezone';
import { DateFormat, GraphEvents, ValidOptionsType, GenericEventDetails } from '../types';

export const formatNumbers = (number: number, options?: Intl.NumberFormatOptions): string => {
  const local = 'en-US';

  if (options) return new Intl.NumberFormat([], options).format(number);

  if (number === 0) return '0';

  if (number < 0.00001)
    return new Intl.NumberFormat(local, {
      maximumSignificantDigits: 2,
    }).format(number);

  if (number < 100) {
    return new Intl.NumberFormat(local, {
      maximumSignificantDigits: 3,
    }).format(number);
  }

  if (number < 1000) {
    return new Intl.NumberFormat(local, {
      maximumSignificantDigits: 4,
    }).format(number);
  }

  return new Intl.NumberFormat(local, {
    maximumFractionDigits: 0,
  }).format(number);
};

export const getBrowserTimeZone = (): string => {
  if (!Intl) {
    return 'GMT';
  }

  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDate = ({
  date = moment(),
  format = 'MMM DD, YYYY',
  timezone = getBrowserTimeZone(),
}: DateFormat): string => moment.tz(date, timezone).format(format);

export const getMockSeriesData = (type: ValidOptionsType = 'column'): SeriesOptionsType[] => {
  const timeSeries = Array.from({ length: 24 }, (_, i) => ({ x: i, y: i + 1 }));

  return [{ type, data: timeSeries }];
};

export const getMockEventsData = (): GraphEvents<GenericEventDetails> => {
  return {
    columns: [{ accessorKey: 'event', header: 'MockEvent' }],
    data: [
      {
        dashStyle: 'Solid',
        icon: 'flag-pennant-regular',
        iconColor: 'gray.700',
        lineColor: 'gray.400',
        value: +new Date('2023-01-01'),
        details: { event: 'random-mock-event' },
      },
    ],
  };
};
