import { Permissions } from '@logz-pkg/enums';
import { redirectIfNoPermission } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const SCHEDULED_REPORTS_TABLE_UI_STATE = 'dashboard.scheduled-reports';
export const scheduledReportsRoutes: AppStateDeclaration[] = [
  {
    name: SCHEDULED_REPORTS_TABLE_UI_STATE,
    url: '/scheduled-reports',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/ScheduledReports/ScheduledReports')}
        resolver={({ ScheduledReports }) => ScheduledReports}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      title: 'Scheduled reports',
      breadcrumb: { label: 'Reports' },
    },
    resolve: [redirectIfNoPermission({ hasPermission: Permissions.SCHEDULED_REPORTS })],
  },
];
