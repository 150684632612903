import { fetchInterceptor } from '@logz-pkg/frontend-services';
import { Headers } from '@logz-pkg/enums';
import { tracingStateService } from 'ui/state/tracing.state.service';

export function overrideJaegerFetch(targetWindow) {
  const { fetch: fetchOrig } = targetWindow;

  if (fetchOrig.isLogzioJaegerOverride) return;

  const interceptor = fetchInterceptor(targetWindow, 'dashboard.jaeger');

  interceptor.register({
    request: ({ url, config = {} }) => {
      const headers = {
        [Headers.TracingAccountId]: tracingStateService.selectedAccountIdState.get(),
      };

      return { url, config, headers };
    },
  });

  targetWindow.fetch.isLogzioJaegerOverride = true;
}
