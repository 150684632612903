import { Field } from 'formik';
import { Input } from '@logz-ui/styleguide';
import { AuthenticationTypes, AwsS3BucketSubject, LogzioSupportedBucketTypes } from '@logz-pkg/enums';

const getPrefixPlaceholder = (
  isDisabled,
  bucketType: LogzioSupportedBucketTypes,
  authenticationType: AuthenticationTypes,
) => {
  if (isDisabled) return '';

  if (bucketType === LogzioSupportedBucketTypes.CloudTrail && authenticationType === AuthenticationTypes.IAM) {
    return 'e.g., AWSLogs/123456789012/CloudTrail';
  }

  return 'e.g., path/to/log-files';
};

export const PrefixField = ({ isDisabled = false, bucketType, authenticationType }) => (
  <Field name="prefix">
    {({ field, form }) => (
      <Input
        subject={AwsS3BucketSubject.BucketPrefixInput}
        error={form.touched.prefix && form.errors.prefix}
        disabled={isDisabled}
        label={bucketType === LogzioSupportedBucketTypes.CloudTrail ? 'Prefix' : 'Prefix (optional)'}
        placeholder={getPrefixPlaceholder(isDisabled, bucketType, authenticationType)}
        mb={0}
        {...field}
      />
    )}
  </Field>
);
