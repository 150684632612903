import { useEffect, useRef, useCallback } from 'react';
import { TelemetryStatus } from '@logz-pkg/models';
import { telemetryDataStatusService, LoggerService, sessionStateService } from '@logz-pkg/frontend-services';
import { waitFor } from 'poll-until-promise';
import { PLAN_TYPE } from '@logz-pkg/enums';
import { useSessionState } from 'ui/state/hooks';
import { welcomeStateService } from 'ui/state/welcome.state.service';

export const TELEMETRY_POLLING_INTERVAL = 10_000;

export const fetchTelemetryStatus = async () => {
  return telemetryDataStatusService.getTelemetriesStatus();
};

const allDataTypesSent = (telemetries: TelemetryStatus): boolean => Object.values(telemetries).every(({ sent }) => sent);

export const useTelemetryStatusPolling = () => {
  const {
    plan: { planType },
  } = useSessionState();
  const currentAccountId = sessionStateService.session.get().loggedInAccount?.id;

  const stopPolling = useRef(false);

  const startPolling = useCallback(() => {
    if (planType !== PLAN_TYPE.TRIAL) return;

    stopPolling.current = false;

    waitFor(
      async () => {
        if (stopPolling.current) return true;

        const telemetries = await fetchTelemetryStatus();

        await welcomeStateService.handleTelemetriesStatus(telemetries);

        return allDataTypesSent(telemetries);
      },
      { interval: TELEMETRY_POLLING_INTERVAL, backoffFactor: 1.5, timeout: Infinity },
    ).catch(error => {
      LoggerService.logError({
        message: 'Unable to complete telemetry status polling',
        error,
      });
    });
  }, [currentAccountId, planType]);

  useEffect(() => {
    startPolling();

    return () => {
      stopPolling.current = true;
    };
  }, [currentAccountId, planType]);
};
