import { useAiHotkeysNavigation } from '../../state/hotkey-navigation/hotkeys-navigation.hook';
import { HelpQuestionsType } from '../../types';

interface IAiHotkeysProps {
  initialQuestions: HelpQuestionsType;
  onClickQuestionCard: (value: string) => void;
}

export const AiHotkeys: React.FC<IAiHotkeysProps> = ({ initialQuestions, onClickQuestionCard }) => {
  useAiHotkeysNavigation(initialQuestions, onClickQuestionCard);

  return null;
};
