import { useEffect } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { Flex, Text, Button, Container, ButtonGroup, Card, Group, Modal } from '@logz-ui/styleguide';
import { OperatingSystem, FilebeatConfWizSubjects } from '@logz-pkg/enums';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { LogTypeItem } from './LogTypeItem';
import { getInitialFormValues, validationSchema, OSOptions } from './form-config';
import { filebeatService } from 'ui/components/FilebeatConfWiz/filebeat/filebeat.service';
import { getFilebeatWizardLogTypes } from 'ui/components/FilebeatConfWiz/log-types';

interface IFilebeatConfWizFormProps {
  os: OperatingSystem;
  onClose: () => void;
}

export const FilebeatConfWizForm = ({ os, onClose }: IFilebeatConfWizFormProps) => {
  const {
    action: getLogTypes,
    data: logTypes,
    isPending: isLoadingLogTypes,
  } = useAsyncAction(() =>
    getFilebeatWizardLogTypes().then(results => [
      { label: 'Other', value: 'Other' },
      ...results.map(res => ({ label: res.name, value: res.value })),
    ]),
  );

  useEffect(() => {
    getLogTypes();
  }, []);

  const renderLogTypes = (logTypeArr, logTypeArrHelpers) =>
    logTypeArr.map((logType, logTypeInd) => (
      <Card key={logTypeInd} p={6} mt={logTypeInd === 0 ? 4 : 0}>
        <LogTypeItem
          showRemoveBtn={logTypeArr.length > 1 && !!logTypeInd}
          logType={logType}
          logTypeInd={logTypeInd}
          logTypeArrHelpers={logTypeArrHelpers}
          logTypes={logTypes}
          isLoadingLogTypes={isLoadingLogTypes}
        />
      </Card>
    ));
  const handleSubmit = async (values, { setSubmitting }) => {
    await filebeatService.generateConfFile(values);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialFormValues(os)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, submitForm }) => (
        <>
          <Modal.Body flexDirection={'column'}>
            <Form>
              <Container mb={2}>
                <Flex
                  flexDirection={'row'}
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                  justifyContent={'space-between'}
                  mb={1}
                  mt={1}
                >
                  <Text>Operating system</Text>
                </Flex>
                <Field
                  name="os"
                  render={({ field, form }) => (
                    <ButtonGroup
                      value={values.os}
                      options={OSOptions}
                      onChange={value => {
                        form.setFieldValue(field.name, value);
                      }}
                    />
                  )}
                />
              </Container>
              <FieldArray
                name="logTypeArr"
                render={logTypeArrHelpers => (
                  <Group vertical alignItems={'stretch'} width={'100%'}>
                    {renderLogTypes(values.logTypeArr, logTypeArrHelpers)}
                    <span>
                      <Button
                        variant={'secondary'}
                        onClick={() => logTypeArrHelpers.push(getInitialFormValues().logTypeArr[0])}
                        icon={'plus-regular'}
                        subject={FilebeatConfWizSubjects.AddLogType}
                      >
                        Add a log type
                      </Button>
                    </span>
                  </Group>
                )}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'tertiary'} onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button subject={'create'} onClick={submitForm} loading={isSubmitting} disabled={isSubmitting}>
              Make the config file
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
