import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ExplorePageSubjects } from '@logz-pkg/enums';
import { savedObjectsApiService, searchedIndexesService } from '@logz-pkg/frontend-services';
import { SavedObjectModel, SavedObjectRequestModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { Link, NotificationService } from '@logz-ui/styleguide';
import rison from 'rison-node';
import { exploreSearchParamsService } from '../router/router';
import { logsStateService } from './logs-state.service';
import { filtersStateService } from './filters-state.service';
import { show as showIntercom } from 'services/common/intercom.service';

const DEFAULT_DATA: ICollectionResponse<SavedObjectModel> = {
  results: [],
  total: 0,
  pagination: { pageNumber: 1, pageSize: 100 },
};
const errorMessage = (
  <>
    Please try one more time, and contact the{' '}
    <Link size={16} onClick={showIntercom}>
      Support team
    </Link>
    if this happens again.
  </>
);

class SavedObjectsService {
  private lastSearchRequest: ISearchRequestObject = {};
  data = new Observable<ICollectionResponse<SavedObjectModel>>(DEFAULT_DATA);
  isLoading = new Observable(true);
  activeSavedObject = new Observable<SavedObjectModel | null>(null);

  create = async (values: Omit<SavedObjectRequestModel, 'osdData'>) => {
    try {
      const request = new SavedObjectRequestModel({ ...values, osdData: this.getOsdData() });

      this.isLoading.set(true);

      const response = await savedObjectsApiService.create(request);

      this.data.set({ results: [...this.data.get().results, response], total: this.data.get().total + 1 });

      NotificationService.success({
        title: 'Create Saved Search',
        message: 'Created a new Saved Search',
        subject: ExplorePageSubjects.SavedSearchCreateNotification,
      });

      return response;
    } catch (e) {
      NotificationService.unexpectedError('Failed to create Saved Search', { message: errorMessage });
    } finally {
      this.isLoading.set(false);
    }
  };

  delete = async (id: string, name: string) => {
    const isDeletingActive = this.activeSavedObject.get()?.id === id;

    const prevData = { ...this.data.get() };

    if (isDeletingActive) this.activeSavedObject.set(null);

    this.data.set({
      results: prevData.results.filter(res => res.id !== id),
      total: prevData.total - 1,
    });

    try {
      await savedObjectsApiService.delete(id);
      NotificationService.success({
        title: 'Delete Saved Search',
        message: `Deleted "${name}"`,
        subject: ExplorePageSubjects.SavedSearchDeleteNotification,
      });
    } catch (e) {
      this.data.set(prevData);
      NotificationService.unexpectedError('Failed to delete your Saved Search', { message: errorMessage });
    }
  };

  getById = async (id: string): Promise<SavedObjectModel | undefined> => {
    try {
      this.isLoading.set(true);

      return savedObjectsApiService.get(id);
    } catch (e) {
      NotificationService.unexpectedError('Failed to get Saved Search', { message: errorMessage });
    } finally {
      this.isLoading.set(false);
    }

    return undefined;
  };

  searchWithLastRequest = () => {
    return this.search(this.lastSearchRequest);
  };

  search = async (searchRequestObject: ISearchRequestObject = {}) => {
    try {
      this.isLoading.set(true);
      this.lastSearchRequest = searchRequestObject;

      const response = await savedObjectsApiService.search(searchRequestObject);

      this.data.set(response);

      return response;
    } catch (e) {
      NotificationService.unexpectedError('Failed to search for Saved Searches', { message: errorMessage });
    } finally {
      this.isLoading.set(false);
    }
  };

  migrate = async () => {
    try {
      await savedObjectsApiService.migrate();
    } catch (e) {
      throw new Error('Issue importing Saved Searches from OSD');
    }
  };

  update = async (values: Omit<SavedObjectRequestModel, 'osdData'>) => {
    const request = new SavedObjectRequestModel({ ...values, osdData: this.getOsdData() });
    const prevResults = this.data.get().results;
    const prevSavedObject = this.activeSavedObject.get();
    const isModifyingActive = prevSavedObject.id === values.id;
    const modifiedSavedObject = prevResults.find(res => res.id === values.id);

    const updatedSavedObject = isModifyingActive
      ? { ...prevSavedObject, ...values }
      : { ...modifiedSavedObject, ...values };

    if (isModifyingActive) {
      this.activeSavedObject.set(updatedSavedObject);
    }

    if (modifiedSavedObject) {
      this.data.set({
        results: this.data.get().results.map(res => (res.id === modifiedSavedObject.id ? updatedSavedObject : res)),
        total: this.data.get().total,
      });
    }

    try {
      await savedObjectsApiService.update(request);
      NotificationService.success({
        title: 'Update Saved Search',
        message: `Updated "${values.name}"`,
        subject: ExplorePageSubjects.SavedSearchUpdateNotification,
      });
    } catch (e) {
      this.activeSavedObject.set(prevSavedObject);
      this.data.set({ ...this.data.get(), results: prevResults });
      NotificationService.unexpectedError('Failed to update Saved Search', { message: errorMessage });
    }
  };

  favorite = async (id: string) => {
    const prevData = this.data.get();
    const prevSavedObject = this.activeSavedObject.get();

    if (this.activeSavedObject.get() && id === this.activeSavedObject.get().id) {
      this.activeSavedObject.set({ ...this.activeSavedObject.get(), isFavorite: true });
    }

    this.data.set({
      results: this.data.get().results.map(res => (res.id === id ? { ...res, isFavorite: true } : res)),
      total: this.data.get().total,
    });

    try {
      await savedObjectsApiService.favorite(id);
    } catch (e) {
      this.activeSavedObject.set(prevSavedObject);
      this.data.set(prevData);
      NotificationService.unexpectedError('Failed to favorite Saved Search', { message: errorMessage });
    }
  };

  unfavorite = async (id: string) => {
    const prevData = this.data.get();
    const prevSavedObject = this.activeSavedObject.get();

    if (this.activeSavedObject.get() && id === this.activeSavedObject.get().id) {
      this.activeSavedObject.set({ ...this.activeSavedObject.get(), isFavorite: false });
    }

    this.data.set({
      results: this.data.get().results.map(res => (res.id === id ? { ...res, isFavorite: false } : res)),
      total: this.data.get().total,
    });

    try {
      await savedObjectsApiService.unfavorite(id);
    } catch (e) {
      this.activeSavedObject.set(prevSavedObject);
      this.data.set(prevData);
      NotificationService.unexpectedError('Failed to unfavorite Saved Search', { message: errorMessage });
    }
  };

  clear = () => {
    this.data.set(DEFAULT_DATA);
    this.activeSavedObject.set(null);
  };

  private getOsdData = (): SavedObjectRequestModel['osdData'] => {
    const columns = exploreSearchParamsService.columns.get();
    const sort = exploreSearchParamsService.sort.get();
    const logState = logsStateService.state.get();
    const accounts = exploreSearchParamsService.accounts.get();

    return {
      hits: logState.hits.hits.length,
      columns: rison.decode(columns).map(c => c.id),
      sort: sort.map<[string, string]>((s: string) => {
        return [s.replace('-', ''), s.startsWith('-') ? 'desc' : 'asc'];
      }),
      exploreFilterState: filtersStateService.state.get(),
      excludedAccounts: searchedIndexesService.getAccountsWithPrefix(accounts),
    };
  };
}

export const savedObjectsService = new SavedObjectsService();
