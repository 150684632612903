import { escapeRegExp, flatten, isRegExp, isString, isNil } from 'lodash';
import { ReactElement } from 'react';

interface IStringReplacer {
  match: RegExp;
  to: string;
}

interface IReactReplacer {
  match: RegExp;
  to: (m: string, i: number, source?: string) => ReactElement;
}

interface IReplacer {
  source: string;
  stringToString?: IStringReplacer[];
  stringToReact?: IReactReplacer[];
}

function replaceStringWithFn(source = '', match: RegExp | string, fn: IReactReplacer['to']) {
  if (source === '') {
    return source;
  } else if (!source || !isString(source)) {
    throw new TypeError('First argument must be a string');
  }

  const regExp = isRegExp(match) ? match : new RegExp(`(${escapeRegExp(match)})`, 'gi');

  const result = source.split(regExp);

  return result.reduce((acc, string, i) => {
    acc.push(i % 2 === 1 ? fn(string, i, source) : string);

    return acc;
  }, []);
}

export function reactReplace(source, match, fn) {
  const sources = Array.isArray(source) ? source : [source];

  return flatten(
    sources.map(function (s) {
      return isString(s) ? replaceStringWithFn(s, match, fn) : s;
    }),
  );
}

export const reactStringReplace = ({ source = '', stringToString = [], stringToReact = [] }: IReplacer) => {
  const replacedWithText = stringToString.reduce(
    (text, { match, to }) => (isNil(to) ? text : text.replace(match, to)),
    source,
  );

  return stringToReact.reduce((text, { match, to }) => reactReplace(text, match, to), [replacedWithText]);
};
