import { Card, Group, Title } from '@logz-ui/styleguide';
import React from 'react';

export const DefaultEmptyStateComponent: React.FC = () => {
  return (
    <Card width={'100%'} height={'100%'}>
      <Group vertical justifyContent="center" alignItems="center" width={'100%'} height={'100%'}>
        <Title weight={400} size={20}>
          Nothing to show
        </Title>
      </Group>
    </Card>
  );
};
