import { ElasticsearchEnhancedFilterModel, ElasticsearchEnhancedFilterOperator } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import type { Dictionary, FieldDictionary } from '../types';
import { exploreUtils } from '../utils/explore-utils';
import { urlParamsService } from '../services/url-params.service';
import { setExploreSearchParam } from '../router/router';

export const defaultFilter = new ElasticsearchEnhancedFilterModel();

defaultFilter.type = 'LUCENE';
defaultFilter.value = '*';

class FiltersStateService {
  state = new Observable<ElasticsearchEnhancedFilterModel[]>([]);
  reducedState = new Observable<Record<string, ElasticsearchEnhancedFilterModel[]>>({});
  dictionary = new Observable<Dictionary>({});

  updateFilters = (
    newFilters: ElasticsearchEnhancedFilterModel[],
    id: string,
    type?: ElasticsearchEnhancedFilterOperator,
  ) => {
    const currentFilters = this.state.get();
    const updatedFilters = exploreUtils.combineFilters({
      currentFilters,
      newFilters,
      currentId: id,
      currentType: type,
    });

    const query = urlParamsService.filtersToUrlParam(updatedFilters);

    setExploreSearchParam({ query });
  };

  addToDictionary = (field: string, fieldDictionary: FieldDictionary) => {
    this.dictionary.set({ ...this.dictionary.get(), [field]: fieldDictionary });
  };
  getFieldLabel = field => this.dictionary.get()[field]?.label ?? field;
  getFieldValueLabel = (field, value) => this.dictionary.get()[field]?.valuesDictionary?.[value] ?? value;

  clear = () => {
    this.state.set([]);
    this.reducedState.set({});
    this.dictionary.set({});
  };
}

export const filtersStateService = new FiltersStateService();
