import { Checkbox } from '@logz-ui/styleguide';
import { Field, FieldProps } from 'formik';
import { AwsS3BucketSubject } from '@logz-pkg/enums';
import { IBaseBucketSettings } from '../interfaces';

export const IncludeSourcePathField = ({ isDisabled = false }) => {
  return (
    <Field
      name="addS3ObjectKeyAsLogField"
      component={({ field, form }: FieldProps<IBaseBucketSettings['addS3ObjectKeyAsLogField'], IBaseBucketSettings>) => (
        <Checkbox
          subject={AwsS3BucketSubject.BucketSourcePathCheckbox}
          name={field.name}
          onChange={() => {
            form.setFieldValue(field.name, !field.value);
          }}
          label={'Include source file path'}
          checked={field.value}
          disabled={isDisabled}
        />
      )}
    />
  );
};
