import { FunctionComponent } from 'react';
import { TracingProductActivationState, TracingSubject, UserRole } from '@logz-pkg/enums';
import {
  Button,
  Group,
  If,
  InlineNotification,
  Link,
  NotificationService,
  Text,
  List,
  ListItem,
} from '@logz-ui/styleguide';
import { ErrorHandlingService } from 'services/error-handling.service';
import { tracingService } from 'services/tracing/tracing.service';
import { useSessionState, useTracingState } from 'ui/state/hooks';
import { ProductEmptyState } from 'ui/components/shared/templates/ProductEmptyState/ProductEmptyState';

interface ITracingNotActivated {
  showTellMeMore?: boolean;
  onSuccess?(): void;
}

export const TracingNotActivated: FunctionComponent<ITracingNotActivated> = ({ showTellMeMore, onSuccess }) => {
  const { userSession } = useSessionState();
  const isAdminAccount = userSession?.role === UserRole.Admin;

  const { tracingProductActivationState } = useTracingState();

  const onCreateTracingAccount = async () => {
    try {
      await tracingService.activateTrial();
      NotificationService.success({
        subject: 'tracing-on-boarding',
        message: `Your Distributed Tracing account is ready for you. Enjoy!`,
      });
      onSuccess?.();
    } catch (error) {
      const message =
        'Sorry, we had a problem creating the Distributed Tracing account. Please try again in a few moments.';

      ErrorHandlingService.handleApiError({
        title: message,
        unexpectedErrorLogMessage: message,
        error,
        subject: 'tracing-trial',
      });
    }
  };

  return (
    <Group vertical gap={5} fullWidth>
      <Group vertical gap={2}>
        <ProductEmptyState.Title>Get started with Logz.io Distributed Tracing!</ProductEmptyState.Title>
        <ProductEmptyState.Description>
          <Group vertical gap={3}>
            <Text color={'gray.800'} size={16}>
              Explore how a single transaction executes within your system
            </Text>
            <List variant={'check'} size={'large'}>
              <ListItem>Expose and monitor the sub-component interactions that make up a transaction</ListItem>
              <ListItem>Quickly investigate potential latency issues</ListItem>
              <ListItem>
                Leverage multiple data types to troubleshoot your incidents: Easily correlate metrics, logs and traces
              </ListItem>
              <ListItem>
                Managing your own Jaeger? With a minor configuration change, you can switch to a fully managed solution
              </ListItem>
            </List>
          </Group>
        </ProductEmptyState.Description>
      </Group>
      {userSession && isAdminAccount ? (
        <Button
          width={'220px'}
          onClick={onCreateTracingAccount}
          subject={TracingSubject.StartTrialButton}
          loading={tracingProductActivationState === TracingProductActivationState.PendingTrial}
        >
          Start using Distributed Tracing
        </Button>
      ) : (
        <InlineNotification
          variant={'info'}
          title={"You don't have access to a Distributed Tracing account yet"}
          subject={TracingSubject.NonAdminInlineNotification}
          noClose
        >
          Main account administrators can create Distributed Tracing accounts and grant access permissions from the
          Manage Accounts page. If you're not an admin, contact one to get started!
        </InlineNotification>
      )}
      <If condition={tracingProductActivationState === TracingProductActivationState.PendingTrial}>
        <Text color={'gray.800'} size={16}>
          This might take a couple of minutes, we will notify you when the account is ready.
        </Text>
      </If>
      {showTellMeMore && (
        <Link href={'https://docs.logz.io/user-guide/distributed-tracing/getting-started-tracing/'} currentTab={false}>
          Tell me more
        </Link>
      )}
    </Group>
  );
};
