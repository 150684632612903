import { Box, Text } from '@logz-ui/styleguide';
import type { ColumnDef, Row } from '@tanstack/react-table';
import React from 'react';
import { useObservable } from '@logz-pkg/observable';
import { dateService } from '@logz-pkg/frontend-services';
import { SearchResultRecord } from '../../../Table/types';
import { useCellProps } from '../cell-props.hook';
import { explorePreferencesStateService } from 'ui/components/Explore/state/app-state/explore-preferences-states.service';
import { exploreUtils } from 'ui/components/Explore/utils/explore-utils';

export const dateFormat = 'MMM DD HH:mm:ss.SSS';

interface IDateColumnProps {
  value: string | number | Date;
  row?: Row<SearchResultRecord>;
  columnDef?: ColumnDef<SearchResultRecord>;
}

export const DateCell: React.FC<IDateColumnProps> = ({ value, row, columnDef }) => {
  const { maxLines, isExpanded, rowHeight } = useCellProps({ columnDef, row });
  const { timezone } = useObservable(explorePreferencesStateService.state).generalSettings;
  const fixedDate = exploreUtils.getFixedDate(value);

  const date = fixedDate.tz(timezone ?? dateService.getBrowserTimeZone());

  return (
    <Box height={rowHeight && !isExpanded ? rowHeight : undefined} minHeight={rowHeight ? rowHeight : undefined}>
      <Text
        variant="code"
        size={12}
        ellipsis={
          maxLines ? { maxLines: isExpanded ? 999 : maxLines, noTooltip: true, wordBreak: 'break-all' } : undefined
        }
      >
        {date.format(dateFormat)}
      </Text>
    </Box>
  );
};
