import { AppMode, FeatureFlags, Permissions, Product, ProductKeys } from '@logz-pkg/enums';
import { ConditionType, ISidenavItemConfig } from './item-configs';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { FilterTagNames } from 'ui/components/Integrations/utils';

export const logsItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'Logs',
  expandedSidenavTitle: 'Log Management',
  key: ProductKeys.Logs,
  mode: AppMode.OPERATIONS,
  logo: Product.LogAnalytics,
  targetStateName: 'dashboard.osd',
  expandedNavGroups: [
    {
      items: [
        {
          icon: 'eye-regular',
          text: 'Explore',
          targetStateName: 'dashboard.explore',
        },
        {
          icon: 'bell-regular',
          text: 'Alerts',
          targetStateName: 'dashboard.triggers.alert-definitions',
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.READ_ALERTS }],
          action: {
            subject: 'new-alert',
            eventName: 'new alert',
            targetStateName: 'dashboard.alerts-v2019-new',
            displayConditions: [{ type: ConditionType.Permission, value: Permissions.ALERTS }],
          },
          subItems: [
            {
              text: 'Triggered alerts',
              targetStateName: 'dashboard.triggers.triggered-alerts',
              displayConditions: [
                { type: ConditionType.Permission, value: Permissions.READ_ALERTS },
                { type: ConditionType.HasFeatureFlag, value: FeatureFlags.DeprecatedTriggeredAlert },
              ],
            },
            {
              text: 'Event management',
              targetStateName: 'dashboard.event-management',
              displayConditions: [{ type: ConditionType.HasFeatureFlag, value: FeatureFlags.LogsEventManagment }],
            },
          ],
          innerStates: ['dashboard.alerts-v2019-duplicate', 'dashboard.alerts-v2019-edit', 'dashboard.alerts-v2019-new'],
        },
        {
          icon: 'chart-simple-regular',
          text: 'Dashboards',
          targetStateName: 'dashboard.dashboard',
          targetStateParams: { ['resource']: 'dashboard' },
          subItems: [
            {
              text: 'Visualize',
              targetStateName: 'dashboard.visualize',
            },
            {
              text: 'Dashboard',
              targetStateName: 'dashboard.dashboard',
            },
            {
              text: 'Discover',
              targetStateName: 'dashboard.discover',
            },
          ],
        },
        { icon: 'display-code-regular', text: 'Live Tail', targetStateName: 'dashboard.livetail' },
        {
          icon: 'book-sparkles-regular',
          text: 'Optimizers',
          targetStateName: 'dashboard.triggers.optimizer-definitions',
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.ALERTS }],
        },
        {
          icon: 'regular-memo-clock-custom',
          text: 'Reports',
          targetStateName: 'dashboard.scheduled-reports',
          action: { targetStateName: 'dashboard.scheduled-reports-new', eventName: 'new scheduled report' },
          innerStates: ['dashboard.scheduled-reports-new', 'dashboard.scheduled-reports-edit'],
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.SCHEDULED_REPORTS }],
        },
      ],
    },
    {
      title: 'manage data',
      items: [
        {
          icon: 'paper-plane-regular',
          text: 'Send your logs',
          targetStateName: integrationsRouteStates.collectors,
          targetStateParams: { tags: FilterTagNames.LOG_ANALYTICS },
        },
      ],
    },
  ],
};
