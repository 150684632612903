import { Field } from 'formik';
import { Input } from '@logz-ui/styleguide';

export const AccessKeyField = () => (
  <Field name="accessKey">
    {({ field, form }) => (
      <Input
        label="Access key"
        placeholder="your-access-key"
        disableAutoComplete={true}
        error={form.touched.accessKey && form.errors.accessKey}
        mb={0}
        {...field}
      />
    )}
  </Field>
);
