import { AppStateEntities } from '@logz-pkg/enums';
import { appStateService } from '@logz-pkg/frontend-services';
import { GuidedWalkthroughTaskId } from '@logz-pkg/models';

const guidedWalkthroughSchemas = {
  [AppStateEntities.Account]: 'guided-walkthrough-account',
  [AppStateEntities.User]: 'guided-walkthrough-user',
};

const version = 0;

export const guidedWalkthroughAppStateService = {
  async getState(schema): Promise<{
    seen?: GuidedWalkthroughTaskId[];
    done: GuidedWalkthroughTaskId[];
  }> {
    return appStateService.get(schema, version);
  },

  async setSeenTasks(taskIds: GuidedWalkthroughTaskId[]): Promise<void> {
    const { done: userDoneTasks = [] } = await this.getState(guidedWalkthroughSchemas[AppStateEntities.User]);

    await appStateService.set(
      guidedWalkthroughSchemas[AppStateEntities.User],
      { done: userDoneTasks, seen: taskIds },
      version,
    );
  },

  async setUserTaskDone(taskId: GuidedWalkthroughTaskId): Promise<GuidedWalkthroughTaskId[]> {
    const { done: userDoneTasks = [], seen: seenTasks = [] } = await this.getState(
      guidedWalkthroughSchemas[AppStateEntities.User],
    );

    if (userDoneTasks?.includes(taskId)) return userDoneTasks;

    const newUserDoneTasks = [...userDoneTasks, taskId];

    await appStateService.set(
      guidedWalkthroughSchemas[AppStateEntities.User],
      { done: newUserDoneTasks, seen: seenTasks },
      version,
    );

    return newUserDoneTasks;
  },

  async setAccountTaskDone(taskId: GuidedWalkthroughTaskId): Promise<GuidedWalkthroughTaskId[]> {
    const { done: accountDoneTasks = [] } = await this.getState(guidedWalkthroughSchemas[AppStateEntities.Account]);

    if (accountDoneTasks?.includes(taskId)) return accountDoneTasks;

    const newAccountDoneTasks = [...accountDoneTasks, taskId];

    await appStateService.set(
      guidedWalkthroughSchemas[AppStateEntities.Account],
      { done: newAccountDoneTasks },
      version,
    );

    return newAccountDoneTasks;
  },

  async setTaskDone(taskId: GuidedWalkthroughTaskId, entity: AppStateEntities): Promise<GuidedWalkthroughTaskId[]> {
    if (entity === AppStateEntities.User) {
      await this.setUserTaskDone(taskId);
    } else if (entity === AppStateEntities.Account) {
      await this.setAccountTaskDone(taskId);
    }

    const { done } = await this.getTasksStatus();

    return done;
  },

  async getTasksStatus(): Promise<{ done: GuidedWalkthroughTaskId[]; seen: GuidedWalkthroughTaskId[] }> {
    const { done: doneUserTasks = [], seen: seenTasks = [] } = await this.getState(
      guidedWalkthroughSchemas[AppStateEntities.User],
    );
    const { done: doneAccountTasks = [] } = await this.getState(guidedWalkthroughSchemas[AppStateEntities.Account]);

    return { done: doneAccountTasks.concat(doneUserTasks), seen: seenTasks };
  },
};
