import { FunctionComponent } from 'react';
import { Button, Card, Group, Link, Text } from '@logz-ui/styleguide';
import styled from 'styled-components';

export interface IEmptyStateActionProps {
  title: string;
  contentText: string;
  buttonText: string;
  onClick?: () => void;
  variant: 'primary' | 'secondary';
  href: string;
}

const ActionText = styled(Text)`
  width: 300px;
`;

export const EmptyStateActionBox: FunctionComponent<IEmptyStateActionProps> = ({
  title,
  contentText,
  buttonText,
  onClick,
  variant,
  href,
}) => (
  <Card px={6} py={4}>
    <Group alignItems={'center'} justifyContent={'space-between'} fullWidth>
      <Group vertical>
        <Text weight={700} variant={'dark'}>
          {title}
        </Text>
        <ActionText color={'gray.800'}>{contentText}</ActionText>
      </Group>
      <Link href={href} currentTab={false} onClick={onClick}>
        <Button variant={variant}>{buttonText}</Button>
      </Link>
    </Group>
  </Card>
);
