import { AppMode, Product, ProductKeys, UserRole } from '@logz-pkg/enums';
import { ConditionType, ISidenavItemConfig } from './item-configs';
import { notificationEndpointsNavigationItem } from './shared-items';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { FilterTagNames } from 'ui/components/Integrations/utils';

export const metricsItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'Metrics',
  expandedSidenavTitle: 'Infrastructure Monitoring',
  key: ProductKeys.Metrics,
  mode: AppMode.OPERATIONS,
  logo: Product.Metrics,
  targetStateName: 'dashboard.metrics',
  expandedNavGroups: [
    {
      items: [{ icon: 'metrics-prod-icon-solid-custom', text: 'Metrics', targetStateName: 'dashboard.metrics' }],
    },
    {
      title: 'manage data',
      items: [
        {
          text: 'Manage tokens',
          icon: 'key-regular',
          targetStateName: 'dashboard.settings.manage-tokens.data-shipping',
          targetStateParams: { product: 'metrics' },
          displayConditions: [{ type: ConditionType.UserRole, value: UserRole.Admin }],
        },
        {
          icon: 'paper-plane-regular',
          text: 'Send your metrics',
          targetStateName: integrationsRouteStates.collectors,
          targetStateParams: { tags: FilterTagNames.METRICS },
          innerStates: [integrationsRouteStates.content],
        },
        notificationEndpointsNavigationItem,
      ],
    },
    {
      title: 'learn more',
      items: [
        {
          text: 'Introduction to Metrics',
          icon: 'circle-question-solid',
          externalUrl: 'https://docs.logz.io/user-guide/infrastructure-monitoring',
          openInNewTab: true,
        },
        {
          text: 'Metrics API',
          icon: 'circle-question-solid',
          externalUrl: 'https://docs.logz.io/api/#tag/Metrics-gateway',
          openInNewTab: true,
        },
      ],
    },
  ],
};
