import { Field } from 'formik';
import { Input } from '@logz-ui/styleguide';

export const BucketField = () => (
  <Field name="bucket">
    {({ field, form }) => (
      <Input
        label="S3 bucket name"
        placeholder="your-bucket-name"
        error={form.touched.bucket && form.errors.bucket}
        mb={0}
        {...field}
      />
    )}
  </Field>
);
