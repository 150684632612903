import { useObservable } from '@logz-pkg/observable';
import { exploreSearchParamsService } from 'ui/components/Explore/router/router';

export const useExploreMode = () => {
  const mode = useObservable(exploreSearchParamsService.mode);
  const isClassicMode = mode === 'CLASSIC' || mode === 'WARM_TIER';
  const isWarmTier = mode === 'WARM_TIER';

  return {
    isClassicMode,
    isWarmTier,
  };
};
