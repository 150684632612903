const cachedResources = {};

export const loadScript = async ({
  src,
  targetWindow = window,
  cache = true,
}: {
  src: string;
  targetWindow?: Window;
  cache?: boolean;
}): Promise<void> => {
  if (cache && cachedResources[src]) return cachedResources[src];

  cachedResources[src] = new Promise((resolve, reject) => {
    const script = targetWindow.document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    targetWindow.document.head.appendChild(script);
  });

  return cachedResources[src];
};

export const loadCss = async (src: string): Promise<void> => {
  if (cachedResources[src]) return cachedResources[src];

  cachedResources[src] = new Promise((resolve, reject) => {
    const linkElement = document.createElement('link');

    linkElement.type = 'text/css';
    linkElement.href = src;
    linkElement.rel = 'stylesheet';
    linkElement.onload = resolve;
    linkElement.onerror = reject;
    document.head.appendChild(linkElement);
  });

  return cachedResources[src];
};
