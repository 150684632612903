/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { Origin } from '@logz-pkg/enums';
import { broadcasterService, LoggerService, opensearchApiService } from '@logz-pkg/frontend-services';
import { Observable } from '@logz-pkg/observable';
import {
  abortControllerService,
  durationStringToTuple,
  intervalCalculatorService,
  IntervalData,
  RelativeValue,
} from '@logz-pkg/utils';
import { NotificationService } from '@logz-ui/styleguide';
import { opensearchtypes } from '@opensearch-project/opensearch/.';
import { debounce } from 'lodash';
import moment from 'moment';
import { AggregationsAggregate } from '@opensearch-project/opensearch/api/types';
import { ElasticsearchEnhancedFilterModel } from '@logz-pkg/models';
import { DEPLOYMENT_MARKERS_QUERY_KEY, LONG_QUERY_DEBOUNCE_TIME, SHORT_QUERY_DEBOUNCE_TIME } from '../../constants';
import { ExploreSearchParams, exploreSearchParamsService, setExploreSearchParam } from '../../router/router';
import { filtersStateService } from '../filters-state.service';
import { logsStateService } from '../logs-state.service';
import { tableCollapseStateService } from '../table-collapse-state.service';
import { cacheByPayload } from '../../services/cache.service';
import { exploreRecentsStatesService } from '../app-state/explore-recents-states.service';
import { exceptionsService } from '../exceptions.service';
import { exploreGraphEventsService } from '../explore-graph-events.service';
import { queryUtils } from './query.utils';
import { GroupByQueryDetails, OtherValuesHistogramPayload, QueryDetails } from './types';
import { configureNotificationData } from './utils/configure-notification-data';
import { broadcastWarmTierQuery } from './broadcast-warm-tier-query';
import { graphStateService } from 'ui/components/Explore/state/graph-state.service';
import { analyticsService } from 'services/analytics/analytics.service';
import { queryClient } from 'ui/components/AppProvider/ReactQueryPersistProvider/ReactQueryPersistProvider';
import { performanceAnalyticsService } from 'services/performance/performance.service';

const buildAbortKeys = () => {
  const requestNames = ['logs', 'graph', 'compareGraph', 'groupBy', 'compareGroupBy'] as const;

  return requestNames.reduce((acc, prefix) => {
    acc[prefix] = abortControllerService.createUniqueKey(prefix);

    return acc;
  }, {} as Record<typeof requestNames[number], string>);
};

enum GroupQueryFunctions {
  GROUP_BY = 'groupBy',
  COMPARE_TO = 'compareTo',
  GRAPH = 'graph',
}

export type GraphQueryFunctionsRecord = Partial<Record<GroupQueryFunctions, boolean>>;

class QueryService {
  queryRunning = new Observable(true);
  graphQueryRunning = new Observable(true);
  isFetchingNextPage = new Observable(false);
  appliedTimeInterval = new Observable<IntervalData>(null);

  private lastGraphQueryDetails: QueryDetails = null;
  private lastGroupByQueryDetails: GroupByQueryDetails = null;
  private abortKeys = buildAbortKeys();

  getQueryState = () => {
    const elasticsearchFilters = filtersStateService.state.get();
    const from = exploreSearchParamsService.from.get();
    const to = exploreSearchParamsService.to.get();
    const mode = exploreSearchParamsService.mode.get();

    return {
      accounts: exploreSearchParamsService.accounts.get(),
      groupBy: exploreSearchParamsService.groupBy.get(),
      compareTo: exploreSearchParamsService.compareTo.get(),
      elasticsearchFilters,
      filteredFilters: elasticsearchFilters.filter(filter => !filter.invalid),
      from,
      to,
      mode,
      ...queryUtils.getStartEnd(from, to),
    };
  };

  getDocumentById = async docId => {
    const results = await opensearchApiService.searchLogs({
      query: { ids: { values: [docId] } },
    });

    return results.hits.hits[0];
  };

  private query = async (fetchNextPage = false) => {
    const { mode } = this.getQueryState();
    const isWarmTierMode = mode === 'WARM_TIER';
    const currentPageUrl = window.location.href;

    const importantQueries: Promise<any>[] = [];

    importantQueries.push(this.handleLogs(fetchNextPage));

    if (!fetchNextPage) importantQueries.push(this.handleGraph());

    exceptionsService.fetchExceptions('COUNT', exceptionsService.isOnlyNewExceptionsO.get());

    try {
      if (isWarmTierMode) {
        broadcastWarmTierQuery();

        if (!performanceAnalyticsService.isRunning('ExploreLoading')) {
          performanceAnalyticsService.start('WarmTierQuery');
        }
      }

      const [logsData, graphData] = await Promise.all(importantQueries);

      if (isWarmTierMode) {
        performanceAnalyticsService.stopAndSendAmplitudeEvent('WarmTierQuery');
        NotificationService.destroy();

        if (logsData?.isCached !== true) {
          broadcasterService.broadcast('explore', { type: 'success', exploreLink: currentPageUrl });
          NotificationService.success(configureNotificationData(currentPageUrl));
        }
      }
    } catch (error) {
      if (isWarmTierMode) {
        LoggerService.logError({ origin: Origin.APP, message: 'Error fetching warm-tier data', error });
        NotificationService.destroy();

        if (error?.statusCode === 504 || error?.statusCode === 408) {
          broadcasterService.broadcast('explore', { type: 'timeout', exploreLink: currentPageUrl });
        } else {
          broadcasterService.broadcast('explore', { type: 'error', exploreLink: currentPageUrl });
        }
      }
    }
  };

  private generateCacheKey = (
    {
      accounts,
      elasticsearchFilters,
      from,
      to,
    }: {
      accounts: string[];
      elasticsearchFilters: ElasticsearchEnhancedFilterModel[];
      from: number | RelativeValue;
      to: number | RelativeValue;
    },
    type: 'logs' | 'graph',
  ) => {
    return { type, elasticsearchFilters, accounts, from, to };
  };

  private handleLogs = async (fetchNextPage: boolean) => {
    const pageSize = fetchNextPage ? 100 : 300;
    const { accounts, from, to, mode, filteredFilters } = this.getQueryState();

    const dslFilters = queryUtils.getDslFilters(filteredFilters, queryUtils.formatTimeRange(from, to));

    queryClient.invalidateQueries({ queryKey: [DEPLOYMENT_MARKERS_QUERY_KEY] });

    this.isFetchingNextPage.set(true);
    this.queryRunning.set(true);

    exploreRecentsStatesService.addRecentFilter(filteredFilters);

    const queryMarkers = { n: 'logs', source: 'explore' as const };
    let isAborted = false;

    try {
      const logsData = await cacheByPayload<opensearchtypes.SearchResponse<any>>({
        fn: opensearchApiService.searchLogs,
        args: [
          queryUtils.buildLogsQuerySearchPayload(fetchNextPage, dslFilters),
          queryMarkers,
          { abortKey: this.abortKeys.logs },
        ],
        shouldCache: mode === 'WARM_TIER',
        keyPrefix: 'exploreWarmTierCache',
        customKeyObject: this.generateCacheKey({ accounts, elasticsearchFilters: filteredFilters, from, to }, 'logs'),
      });

      logsData.hits.hits = queryUtils.mapHitsToLogs(logsData.hits.hits);

      const total = typeof logsData.hits.total === 'object' ? logsData.hits.total.value : logsData.hits.total;

      if (fetchNextPage) {
        logsData.hits.hits = [...logsStateService.state.get().hits.hits, ...logsData.hits.hits];
        analyticsService.capture('Explore table', 'Fetched next page', {
          pageSize,
          currentLogSize: logsData.hits.hits.length,
          totalLogs: total,
        });
      }

      logsStateService.totalLogs.set(total);
      logsStateService.setState(logsData);
      logsStateService.buildLogMap();

      return logsData;
    } catch (error) {
      if (error?.config?.signal?.aborted) {
        isAborted = true;

        return undefined;
      }

      LoggerService.logError({ origin: Origin.APP, message: 'Error fetching logs', error });

      NotificationService.danger({
        title: 'Error fetching logs',
        message: 'Please try again, or contact support',
        duration: 3,
      });
    } finally {
      if (isAborted) return undefined;

      logsStateService.isStateInitialized.set(true);
      this.queryRunning.set(false);
      this.isFetchingNextPage.set(false);
      tableCollapseStateService.reset();
    }

    return undefined;
  };

  async handleGraph(
    enabledGraphQueryFunctions: GraphQueryFunctionsRecord = { compareTo: true, graph: true, groupBy: true },
  ) {
    const { accounts, groupBy, compareTo, filteredFilters, start, end } = this.getQueryState();

    if (enabledGraphQueryFunctions.graph) {
      this.lastGraphQueryDetails = {
        from: start,
        to: end,
        elasticsearchFilters: filteredFilters,
        accounts,
      };
    }

    if (enabledGraphQueryFunctions.groupBy) {
      this.lastGroupByQueryDetails = {
        from: start,
        to: end,
        elasticsearchFilters: filteredFilters,
        accounts,
        term: groupBy,
        limit: 10,
      };
    }

    const listGraphQueries: Promise<any>[] = [];

    if (enabledGraphQueryFunctions.graph) {
      listGraphQueries.push(
        this.executeGraphQuery(this.lastGraphQueryDetails).then(graphData => {
          graphStateService.setState(graphData);

          return graphData;
        }),
      );
    }

    if (enabledGraphQueryFunctions.groupBy && groupBy) {
      listGraphQueries.push(this.groupBy(groupBy));
    }

    if (enabledGraphQueryFunctions.compareTo && compareTo) {
      listGraphQueries.push(this.compareTo(compareTo));
    }

    exploreGraphEventsService.fetchEvents();

    let isAborted = false;

    try {
      this.graphQueryRunning.set(true);

      const graphData = await Promise.all(listGraphQueries);

      return graphData;
    } catch (error) {
      if (error?.config?.signal?.aborted) {
        isAborted = true;

        return undefined;
      }

      graphStateService.setState(undefined);
      NotificationService.danger({
        title: 'Error fetching graph data',
        message: 'Please try again, or contact support',
        duration: 3,
      });
      LoggerService.logError({ origin: Origin.APP, message: 'Error fetching graph data', error });
    } finally {
      if (isAborted) return undefined;

      this.graphQueryRunning.set(false);
    }

    return undefined;
  }

  private executeGraphQuery = async (queryDetails = this.lastGraphQueryDetails, isCompareTo?: boolean) => {
    const { from: start, to: end, elasticsearchFilters, accounts } = queryDetails;
    const { mode, from, to } = this.getQueryState();
    const intervalType = exploreSearchParamsService.timeInterval.get();
    const dslFilters = queryUtils.getDslFilters(elasticsearchFilters, queryUtils.formatTimeRange(start, end));
    const interval = intervalCalculatorService.findBucket({ start, end, intervalType });

    this.appliedTimeInterval.set(interval);

    const queryMarkers = { n: `graph${isCompareTo ? `-compare` : ''}`, source: 'explore' as const };
    const queryForOpenSearch = queryUtils.searchWithDateHistogramPayload({ filters: dslFilters, interval, accounts });

    const graphQueryData = await cacheByPayload<opensearchtypes.SearchResponse<any>>({
      fn: opensearchApiService.searchLogs,
      args: [
        queryForOpenSearch,
        queryMarkers,
        { abortKey: isCompareTo ? this.abortKeys.compareGraph : this.abortKeys.graph },
      ],
      shouldCache: mode === 'WARM_TIER',
      keyPrefix: 'exploreWarmTierCache',
      customKeyObject: this.generateCacheKey({ accounts, elasticsearchFilters, from, to }, 'graph'),
    });

    return graphQueryData;
  };

  longDebouncedQuery = debounce(this.query, LONG_QUERY_DEBOUNCE_TIME);
  shortDebouncedQuery = debounce((fetchNextPage?: boolean) => {
    this.longDebouncedQuery.cancel();
    this.query(fetchNextPage);
  }, SHORT_QUERY_DEBOUNCE_TIME);

  private executeGroupByQuery = async (
    { from, to, term, elasticsearchFilters, accounts, limit } = this.lastGroupByQueryDetails,
    isCompareTo?: boolean,
  ) => {
    const intervalType = exploreSearchParamsService.timeInterval.get();
    const { start, end } = queryUtils.getStartEnd(from, to);
    const interval = intervalCalculatorService.findBucket({ start, end, intervalType });
    const dslFilters = queryUtils.getDslFilters(elasticsearchFilters, interval);
    const payload = queryUtils.termHistogramPayload({ dslFilters, term, limit, interval, accounts });
    const termHistogramQueryMarkers = { n: `group-by${isCompareTo ? `-compare` : ''}`, source: 'explore' as const };

    const termHistogram = await opensearchApiService.searchLogs<AggregationsAggregate>(
      payload,
      termHistogramQueryMarkers,

      { abortKey: isCompareTo ? this.abortKeys.compareGroupBy : this.abortKeys.groupBy },
    );

    if (termHistogram?.hits?.total === 0) {
      return {
        termHistogram: undefined,
        otherValues: undefined,
      };
    }

    const otherValuesPayload: OtherValuesHistogramPayload = {
      payload: { dslFilters, interval, term, accounts },
      byTermAgg: termHistogram.aggregations.byTerm as opensearchtypes.AggregationsAutoDateHistogramAggregate,
    };

    const othersQueryMarkers = { n: `group-by-others${isCompareTo ? `compare` : ''}`, source: 'explore' as const };

    const otherValues = await opensearchApiService.searchLogs(
      queryUtils.otherValuesPayloadBuilder(otherValuesPayload),
      othersQueryMarkers,
      { abortKey: isCompareTo ? this.abortKeys.compareGroupBy : this.abortKeys.groupBy },
    );

    return { termHistogram, otherValues };
  };

  private async groupBy(term: string, lastGraphQueryDetails = this.lastGroupByQueryDetails) {
    if (!term) return undefined;

    try {
      const { otherValues, termHistogram } = await this.executeGroupByQuery(lastGraphQueryDetails);

      graphStateService.setGroupByState(termHistogram, otherValues);

      return { termHistogram, otherValues };
    } catch (error) {
      if (error?.config?.signal?.aborted) return undefined;

      graphStateService.setGroupByState(undefined, undefined);
      NotificationService.danger({
        title: `Can not get aggregation for ${term}`,
        message: 'Please try again, or contact support',
        duration: 3,
      });
      setExploreSearchParam({ groupBy: null });
      LoggerService.logError({ origin: Origin.APP, message: 'Can not get aggregation', error });
    }

    return undefined;
  }

  private async compareToGroupBy(
    compareTo: ExploreSearchParams['compareTo'],
    groupByQueryDetails = this.lastGroupByQueryDetails,
  ) {
    if (!compareTo || !groupByQueryDetails) return undefined;

    const [amount, unit] = durationStringToTuple(compareTo);
    const start = moment(groupByQueryDetails.from).subtract(amount, unit).valueOf();
    const end = moment(groupByQueryDetails.to).subtract(amount, unit).valueOf();

    try {
      const { termHistogram, otherValues } = await this.executeGroupByQuery(
        {
          ...groupByQueryDetails,
          from: start,
          to: end,
        },
        true,
      );

      graphStateService.setCompareToGroupByState(termHistogram, otherValues);

      return { termHistogram, otherValues };
    } catch (error) {
      if (error?.config?.signal?.aborted) return undefined;

      graphStateService.setCompareToGroupByState(undefined, undefined);
      LoggerService.logError({ origin: Origin.APP, message: 'Can not get compare to aggregation', error });
    }

    return undefined;
  }

  private async compareTo(compareTo: ExploreSearchParams['compareTo'], graphQueryDetails = this.lastGraphQueryDetails) {
    if (!compareTo || !graphQueryDetails) return undefined;

    const { groupBy } = this.getQueryState();

    const { from, to } = graphQueryDetails;

    if (groupBy) {
      const compareToGroupByData = await this.compareToGroupBy(compareTo);

      return compareToGroupByData;
    }

    const { end: orgEnd, start: orgStart } = queryUtils.getStartEnd(from, to);
    const [amount, unit] = durationStringToTuple(compareTo);

    const start = moment(orgStart).subtract(amount, unit).valueOf();
    const end = moment(orgEnd).subtract(amount, unit).valueOf();

    try {
      const compareToGraphData = await this.executeGraphQuery({ ...graphQueryDetails, from: start, to: end }, true);

      graphStateService.setCompareToState(compareToGraphData);

      return compareToGraphData;
    } catch (error) {
      if (error?.config?.signal?.aborted) return undefined;

      graphStateService.setCompareToState(undefined);
      LoggerService.logError({ origin: Origin.APP, message: 'Can not get compare to graph data', error });
    }

    return undefined;
  }

  clear = () => {
    this.queryRunning.set(true);
    this.graphQueryRunning.set(true);
    this.isFetchingNextPage.set(false);
    this.lastGraphQueryDetails = null;
    this.lastGroupByQueryDetails = null;
    Object.values(this.abortKeys).forEach(key => abortControllerService.delete(key));
    this.abortKeys = buildAbortKeys();
  };
}

export const queryService = new QueryService();
