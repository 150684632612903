/* eslint-disable max-lines */
import * as Yup from 'yup';
import { NotificationEndpointsSubjects, NotificationEndpointType } from '@logz-pkg/enums';
import { Input, Textarea, Select, SelectSizes, Group, FieldContainer } from '@logz-ui/styleguide';
import { Env } from '@logz-pkg/frontend-services';
import logzPatterns from '../../../modules/patterns';
import {
  ISlackNotificationPayload,
  IPagerDutyNotificationPayload,
  IOpsgenieNotificationPayload,
  IVictorOpsNotificationPayload,
  IBigPandaNotificationPayload,
  ICustomNotificationPayload,
  IDataDogNotificationPayload,
  IModalObject,
  IServiceNowNotificationPayload,
  IMicrosoftTeamsNotificationPayload,
  IMicrosoftTeamsWorkflowNotificationPayload,
} from './endpoint-form-config.interfaces';

const createCustomNoteWithLink = ({
  linkPrefix = 'Please refer to the',
  linkSuffix = 'to learn how to set up the',
  link,
  linkLabel,
  fieldName = '',
}) => (
  <Group gap={1}>
    <div>{linkPrefix}</div>
    <a rel="noopener noreferrer" target="_blank" href={link}>
      {linkLabel}
    </a>
    <div>
      {linkSuffix} {fieldName}
    </div>
  </Group>
);

export const pageDescription = `Integrate Logz.io into your regular routine!
Add your channels and webhooks to receive Logz.io alerts and insights in your usual workspace, and share dashboards over your preferred channels.`;

export const documentationLinks = {
  SLACK: 'https://api.slack.com/incoming-webhooks',
  PAGER_DUTY: 'https://support.pagerduty.com/hc/en-us/articles/' + '202830340-Creating-a-Generic-API-Integration',
  DATA_DOG: 'https://app.datadoghq.com/account/settings#api',
  BIG_PANDA: 'https://docs.bigpanda.io/docs/logzio',
  OPSGENIE: 'https://docs.opsgenie.com/docs/logz-io-integration',
  SERVICE_NOW: 'https://docs.logz.io/user-guide/integrations/servicenow',
  MICROSOFT_TEAMS:
    'https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook',
};

export const defaultFormValidations = {
  title: Yup.string().required("Don't forget the display name"),
  description: Yup.string().nullable(),
  endpointType: Yup.string(),
};

export const formValidationsConfig = {
  headers: Yup.string().nullable().matches(logzPatterns.httpHeaders, 'Headers should be valid'),
  url: (Env.configs.disableUrlValidation ? Yup.string() : Yup.string().url('Must be a valid url')).required(
    'Url is required',
  ),
  serviceKey: Yup.string().required('Service Key is required'),
  apiKey: Yup.string().required('Api Key is required'),
  apiToken: Yup.string().required('Api Token Key is required'),
  appKey: Yup.string().required('App Key is required'),
  serviceApiKey: Yup.string().required('Service Api Key is required'),
  messageType: Yup.string().required('Message Type is required'),
  routingKey: Yup.string().required('Routing Key is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  bodyTemplate: Yup.string()
    .nullable()
    .test('valid-json', 'Template must be a valid json', template => {
      if (!template) return true;

      try {
        JSON.parse(template);

        return true;
      } catch (e) {
        return false;
      }
    }),
};

const methods = ['GET', 'POST', 'PUT'].map(m => ({ label: m, value: m }));
export const templateData: Record<NotificationEndpointType, IModalObject> = {
  Custom: {
    templateUrlSuffix: 'custom',
    initialValues: {
      method: 'GET',
      bodyTemplate: JSON.stringify(
        {
          alert_title: '{{alert_title}}',
          alert_description: '{{alert_description}}',
          alert_severity: '{{alert_severity}}',
          alert_event_samples: '{{alert_samples}}',
        },
        null,
        4,
      ),
      url: '',
      headers: '',
    } as ICustomNotificationPayload,
    inputs: [
      {
        name: 'url',
        label: 'URL',
        placeholder: 'eg: https://my.third-party-service.com/integration-key/KSJ329aB8F',
        subject: NotificationEndpointsSubjects.EndpointUrlInput,
      },
      { name: 'method', label: 'Method' },
      {
        name: 'headers',
        label: 'Headers',
        helper: 'Request headers should be separated by a comma',
        placeholder: 'eg: content-type=application/json,x-token=67d2lke9af2d0t',
      },
      { name: 'bodyTemplate', label: 'Body (optional)', maxRows: 10, minRows: 6 },
    ],
    Component({
      handleChange,
      handleBlur,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      values,
      errors,
      touched,
      inputs,
    }) {
      const [url, method, headers, bodyTemplate] = inputs;

      return (
        <Group gap={3} vertical fullWidth>
          <Input
            {...url}
            key={url.name}
            value={values.url}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.url && errors.url}
            disabled={isSubmitting}
            mb={0}
          />
          <FieldContainer label={method.label}>
            <Select
              {...method}
              key={method.name}
              value={values.method && { label: values.method, value: values.method }}
              onChange={selected => {
                setFieldValue(method.name, selected.value);
                setFieldTouched(method.name, true);
              }}
              isClearable={false}
              size={SelectSizes.Small}
              onBlur={handleBlur}
              error={touched.method && errors.method}
              disabled={isSubmitting}
              options={methods}
            />
          </FieldContainer>
          <Input
            {...headers}
            key={headers.name}
            value={values.headers}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.headers && errors.headers}
            disabled={isSubmitting}
            mb={0}
          />
          {values.method && values.method !== 'GET' && (
            <Textarea
              {...bodyTemplate}
              key={bodyTemplate.name}
              value={values.bodyTemplate}
              onChange={event => handleChange(event)}
              onBlur={handleBlur}
              error={touched.bodyTemplate && errors.bodyTemplate}
              disabled={isSubmitting}
            />
          )}
        </Group>
      );
    },
  },
  Slack: {
    templateUrlSuffix: 'slack',
    initialValues: { url: '' } as ISlackNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'url',
        label: 'URL',
        placeholder: 'eg: https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXX',
        helper: createCustomNoteWithLink({
          link: documentationLinks.SLACK,
          linkLabel: 'Slack',
          fieldName: 'URL',
        }),
        subject: NotificationEndpointsSubjects.EndpointUrlInput,
      },
    ],
  },
  PagerDuty: {
    templateUrlSuffix: 'pager-duty',
    initialValues: { serviceKey: '' } as IPagerDutyNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'serviceKey',
        label: 'Service Key',
        placeholder: 'eg: 9c0f784f97a14f269b299756fc062b66',
        helper: createCustomNoteWithLink({
          link: documentationLinks.PAGER_DUTY,
          linkLabel: 'PagerDuty',
          fieldName: 'service key',
        }),
      },
    ],
  },
  Datadog: {
    templateUrlSuffix: 'data-dog',
    initialValues: { apiKey: '' } as IDataDogNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'apiKey',
        label: 'API Key',
        placeholder: 'eg: ea8a6fe8021e1cdfe87614089b0a3b38',
        helper: createCustomNoteWithLink({
          link: documentationLinks.DATA_DOG,
          linkLabel: 'Datadog',
          fieldName: 'api key',
        }),
      },
    ],
  },
  BigPanda: {
    templateUrlSuffix: 'big-panda',
    initialValues: { apiToken: '', appKey: '' } as IBigPandaNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'apiToken',
        label: 'Token',
        placeholder: 'eg: ccc0d7a2ce50b365ffca653e80d52ceb',
        helper: createCustomNoteWithLink({
          link: documentationLinks.BIG_PANDA,
          linkLabel: 'BigPanda',
          fieldName: 'token',
        }),
      },
      {
        Component: Input,
        name: 'appKey',
        label: 'App Key',
        placeholder: 'eg: 72ea2ed8ae331fbc80c7bdaffabc8f76',
        helper: createCustomNoteWithLink({
          link: documentationLinks.BIG_PANDA,
          linkLabel: 'BigPanda',
          fieldName: 'app key',
        }),
      },
    ],
  },
  VictorOps: {
    templateUrlSuffix: 'victorops',
    initialValues: { serviceApiKey: '', messageType: '', routingKey: '' } as IVictorOpsNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'serviceApiKey',
        label: 'Service API Key',
      },
      {
        Component: Input,
        name: 'messageType',
        label: 'Message Type',
      },
      {
        Component: Input,
        name: 'routingKey',
        label: 'Routing Key',
      },
    ],
  },
  Opsgenie: {
    templateUrlSuffix: 'ops-genie',
    initialValues: { apiKey: '' } as IOpsgenieNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'apiKey',
        label: 'Api Key',
        placeholder: 'eg: 23kj345kjloidfgdfpodf890',
        helper: createCustomNoteWithLink({
          link: documentationLinks.OPSGENIE,
          linkLabel: 'Opsgenie',
          fieldName: 'API key',
        }),
      },
    ],
  },
  ServiceNow: {
    templateUrlSuffix: 'service-now',
    initialValues: { url: '', username: '', password: '' } as IServiceNowNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'url',
        label: 'Instance URL',
        placeholder: 'eg: https://XXXXXXXXXX.service-now.com',
        helper: 'Provide your instance url to connect to your existing ServiceNow instance',
      },
      {
        Component: Input,
        name: 'username',
        label: 'Username',
        placeholder: 'Your dedicated user for this integration',
        helper: createCustomNoteWithLink({
          linkPrefix: 'Learn how to set up a user in',
          linkSuffix: 'for a third party integration',
          link: documentationLinks.SERVICE_NOW,
          linkLabel: 'ServiceNow',
        }),
      },
      {
        Component: Input,
        name: 'password',
        label: 'Password',
        type: 'password',
      },
    ],
  },
  ['Microsoft Teams']: {
    templateUrlSuffix: 'microsoft-teams',
    initialValues: { url: '', username: '', type: '' } as IMicrosoftTeamsNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'url',
        label: 'Webhook URL',
        placeholder: 'https://channelname.webhook.office.com/webhookb2/...',
        helper: createCustomNoteWithLink({
          linkPrefix: 'Refer to',
          linkSuffix: 'to learn how to set up the URL',
          link: documentationLinks.MICROSOFT_TEAMS,
          linkLabel: 'Microsoft documentation',
        }),
      },
    ],
  },
  ['Microsoft Teams Workflow']: {
    templateUrlSuffix: 'microsoft-teams-workflow',
    initialValues: { url: '' } as IMicrosoftTeamsWorkflowNotificationPayload,
    inputs: [
      {
        Component: Input,
        name: 'url',
        label: 'Webhook URL',
        placeholder: 'https://channelname.webhook.office.com/webhookb2/...',
        helper: createCustomNoteWithLink({
          linkPrefix: 'Refer to',
          linkSuffix: 'to learn how to set up the URL',
          link: documentationLinks.MICROSOFT_TEAMS,
          linkLabel: 'Microsoft documentation',
        }),
      },
    ],
  },
};
