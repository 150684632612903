import { Button, INotificationOptions, NotificationService } from '@logz-ui/styleguide';
import { analyticsService } from 'services/analytics/analytics.service';

export const configureNotificationData = (exploreLink: string): INotificationOptions => {
  const handleClick = () => {
    window.location.href = exploreLink;

    analyticsService.capture('Warm tier notification', 'Viewed data in Explore after a successful Warm tier query');

    NotificationService.destroy();
  };

  return {
    title: 'Warm Data successfully retrieved',
    message: 'Your query results and data are now available.',
    autoHide: true,
    duration: 8,
    children:
      window.location.href === exploreLink ? null : (
        <Button icon="arrow-up-right-regular" onClick={handleClick}>
          View data
        </Button>
      ),
  };
};
