import { AppMode, FeatureFlags, ProductKeys } from '@logz-pkg/enums';
import { spmRouteNames } from '../../../../../../app/states/spm.routes';
import { ConditionType, ISidenavItemConfig } from './item-configs';
import { ExtendedLogos } from 'ui/components/shared/Logo/Logo';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { FilterTagNames } from 'ui/components/Integrations/utils';

export const app360ItemConfig: ISidenavItemConfig = {
  key: ProductKeys.App360,
  fixedSidenavText: 'App 360',
  expandedSidenavTitle: 'App 360',
  targetStateName: spmRouteNames.servicesTable,
  mode: AppMode.OPERATIONS,
  logo: ExtendedLogos.App360,
  expandedNavGroups: [
    {
      items: [
        {
          icon: 'diagram-project-regular',
          text: 'Services',
          targetStateName: spmRouteNames.servicesTable,
          innerStates: [spmRouteNames.serviceOverview, spmRouteNames.operationOverview],
          subItems: [
            {
              text: 'Service Map',
              targetStateName: spmRouteNames.servicesMap,
              displayConditions: [
                {
                  type: ConditionType.HasFeatureFlag,
                  value: FeatureFlags.ServiceListMap,
                },
              ],
            },
            {
              text: 'Service List',
              targetStateName: spmRouteNames.servicesTable,
            },
            {
              text: 'Real User Monitoring',
              targetStateName: spmRouteNames.realUserMonitoring,
              displayConditions: [
                {
                  type: ConditionType.HasFeatureFlag,
                  value: FeatureFlags.RealUserMonitoring,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'manage data',
      items: [
        {
          icon: 'paper-plane-regular',
          text: 'Send your data',
          targetStateName: integrationsRouteStates.collectors,
          targetStateParams: { tags: FilterTagNames.LOG_ANALYTICS },
        },
      ],
    },
  ],
};
