import { Button, Flex, Group, Icon, Text } from '@logz-ui/styleguide';
import React, { useEffect } from 'react';
import { aiUtils } from '../utils';
import { appRouter } from 'services/router/router.service';
import { exploreRouteNames } from 'states/explore/explore.route';

export const AiChatError: React.FC<{ error?: string }> = ({ error }) => {
  useEffect(() => {
    if (error) {
      aiUtils.loggerError('App crashed', { error });
    }
  }, [error]);

  return (
    <Flex height="100%" width="100%" alignItems="center" justifyContent="center">
      <Group alignItems="center" vertical>
        <Icon icon={'sparkles-solid'} size={30} />
        <Text color={'royalBlue.1000'}>Oops! something went wrong.</Text>
        <Text size={12} color={'blue.600'}>
          You can try to reload the page, and play nice music, sometimes it helps.
        </Text>
        <Button
          variant="tertiary"
          round
          icon={'arrows-rotate-regular'}
          onClick={() => appRouter.stateService.go(exploreRouteNames.explore, {}, { reload: true })}
        />
      </Group>
    </Flex>
  );
};
