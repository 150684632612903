import { GuidedWalkthroughTaskId } from '@logz-pkg/models';
import { GuidedWalkthroughPhase, WalkthroughTasks } from 'ui/components/GuidedWalkthrough/Tasks';

const getFilteredPhases = ({
  phases,
  tasks,
}: {
  phases: GuidedWalkthroughPhase[];
  tasks: GuidedWalkthroughTaskId[];
}): GuidedWalkthroughPhase[] =>
  phases.filter(({ tasks: phaseTasks }) => phaseTasks.filter(taskId => tasks.includes(taskId)).length > 0);

const eventToTaskIdMap = Object.values(WalkthroughTasks).reduce((taskIds, task) => {
  if (task.completeEventName) {
    taskIds[task.completeEventName] = task.id;
  }

  return taskIds;
}, {});

export const walkthroughUtils = { getFilteredPhases, eventToTaskIdMap };
