import { components } from 'react-select';
import { Icon } from '@logz-ui/styleguide';

export const SelectSearchIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon icon={'magnifying-glass-regular'} size={12} color={'gray.500'} />
  </components.DropdownIndicator>
);

SelectSearchIndicator.displayName = 'SelectSearchIndicator';
