import { useEffect } from 'react';
import { recentVisitsStateService } from 'ui/state/recent-visits.state.service';
import { useSessionState } from 'ui/state/hooks/session.state.hook';

export const useHistoryTracker = () => {
  const { loggedInUser } = useSessionState();

  useEffect(() => {
    if (!loggedInUser?.id) return;

    recentVisitsStateService.fetch();
  }, [Boolean(loggedInUser)]);
};
