import { FeatureFlags } from '@logz-pkg/enums';
import { identityRouteResolver, IIdentityRouteResolver } from 'services/identity/identity-resolver.service';
import { redirectIfFeatureDisabled, redirectIfFeatureEnabled } from 'services/router/redirect-if-feature-flag';

interface IRedirectByFeature {
  featureFlag: keyof typeof FeatureFlags;
  toState?: string;
}

export const redirectIfFFDisabled = ({ featureFlag, toState = 'dashboard.settings.general' }: IRedirectByFeature) => ({
  token: 'redirectIfFeatureDisabled',
  resolveFn: () => redirectIfFeatureDisabled({ featureName: featureFlag, toState }),
});

export const redirectIfFFEnabled = ({ featureFlag, toState = 'dashboard.settings.general' }: IRedirectByFeature) => ({
  token: 'redirectIfFeatureEnabled',
  resolveFn: () => redirectIfFeatureEnabled({ featureName: featureFlag, toState }),
});

export const redirectIfNoPermission = (params: IIdentityRouteResolver) => ({
  token: 'IdentityResolver',
  resolveFn: identityRouteResolver({
    ...params,
  }),
});

export const redirectIfNotAdmin = redirectIfNoPermission({ isAdmin: true });
export const redirectIfNotOwner = redirectIfNoPermission({ isAccountOwner: true });

export const redirectIfNotPlanType = (params: IIdentityRouteResolver) => ({
  token: 'IdentityResolver',
  resolveFn: identityRouteResolver({
    ...params,
  }),
});
