import { FunctionComponent } from 'react';
import {
  GuidedWalkthroughActionSection,
  GuidedWalkthroughTask,
  GuidedWalkthroughTaskId,
  GuidedWalkthroughTaskStatus,
} from '@logz-pkg/models';
import { Card, Group, Text, Button, Info, Link } from '@logz-ui/styleguide';
import { GuidedWalkthroughSubject } from '@logz-pkg/enums';
import { StatusBadge } from 'ui/components/GuidedWalkthrough/Components/StatusBadge';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';

interface ITaskTitle {
  title: string;
  info?: string;
}

const TaskTitle: FunctionComponent<ITaskTitle> = ({ title, info }) => (
  <Group>
    <Text size={12} weight={700}>
      {title}
    </Text>
    {info ? <Info tooltip={info} /> : null}
  </Group>
);

interface ILearnMoreProps {
  link: string;
  taskId: GuidedWalkthroughTaskId;
}

const LearnMore: FunctionComponent<ILearnMoreProps> = ({ link, taskId }) => (
  <Link href={link}>
    <Button variant={'tertiary'} icon={'arrow-up-right-from-square-regular'} size={'s'}>
      <Text size={14} color={'blue.700'}>
        Learn more
      </Text>
    </Button>
  </Link>
);

interface IActionSection {
  action: GuidedWalkthroughActionSection;
  taskId: GuidedWalkthroughTaskId;
}

const ActionsSection: FunctionComponent<IActionSection> = ({ action, taskId }) => {
  const { setIsDrawerOpen } = useGuidedWalkthrough();
  const { callback, text, learnMoreUrl } = action;

  const handleOnClick = async () => {
    await callback();
    setIsDrawerOpen(false);
  };

  return (
    <Group>
      <Button variant={'secondary'} onClick={handleOnClick} size={'m'} subject={GuidedWalkthroughSubject.TaskAction}>
        <Text size={14}>{text}</Text>
      </Button>
      {learnMoreUrl && <LearnMore link={learnMoreUrl} taskId={taskId} />}
    </Group>
  );
};

interface ITaskProps {
  task: GuidedWalkthroughTask;
}

export const Task: FunctionComponent<ITaskProps> = ({ task }) => {
  const { id: currentTaskId, title, hint, actionSection, description, imageUrl } = task;
  const { doneTasksIds } = useGuidedWalkthrough();

  const status: GuidedWalkthroughTaskStatus = doneTasksIds?.includes(currentTaskId)
    ? GuidedWalkthroughTaskStatus.Done
    : GuidedWalkthroughTaskStatus.Todo;

  return (
    <Card width={'100%'} py={3} borderless subject={`${GuidedWalkthroughSubject.Task}${currentTaskId}`}>
      <Group>
        <StatusBadge status={status} />
        <Group vertical gap={2}>
          <TaskTitle title={title} info={hint} />
          <Text size={12}>{description}</Text>
          {/*  DEV-39235: populate the images and the links */}
          {actionSection ? <ActionsSection action={actionSection} taskId={currentTaskId} /> : null}
          {imageUrl ? <div>image goes here</div> : null}
        </Group>
      </Group>
    </Card>
  );
};
