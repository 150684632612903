import React from 'react';
import { AuthenticationTypes, AwsS3BucketSubject } from '@logz-pkg/enums';
import { Title, Button, Modal } from '@logz-ui/styleguide';

interface IChooseAuthTypeModalProps {
  onAuthMethodSelected: (string) => void;
  onClose: () => void;
}

export const ChooseAuthTypeModal: React.FunctionComponent<IChooseAuthTypeModalProps> = ({
  onAuthMethodSelected,
  onClose,
}) => (
  <Modal open onClose={onClose}>
    <Modal.Header>
      <Title weight={700} size={20}>
        How should Logz.io authenticate?
      </Title>
    </Modal.Header>
    <Modal.Body flexDirection={'column'}>
      We recommend using an IAM role for better security and centralized permissions management. If you have a simpler
      setup, you can use an access key with an IAM user.
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant={'secondary'}
        onClick={() => onAuthMethodSelected(AuthenticationTypes.SecretAccess)}
        subject={AwsS3BucketSubject.ChooseSecretAccessTypeButton}
      >
        Authenticate with an access key
      </Button>
      <Button
        onClick={() => onAuthMethodSelected(AuthenticationTypes.IAM)}
        subject={AwsS3BucketSubject.ChooseIAMRoleTypeButton}
      >
        Authenticate with a role
      </Button>
    </Modal.Footer>
  </Modal>
);
