export const logzPatterns = {
  email: /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*(\+[_A-Za-z0-9-]+)?@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/,
  accountName: /^[\w \-.]*$/, // allows letters, numbers, dash (-), dot(.), underscore (_) an space.
  url: /^(http|https):\/\/.*/,
  naturalNumber: /^(?:0|[1-9][0-9]*)$/,
  httpHeaders: /^(?:[^=,]+=(?:[^,]+)?(?:,(?=.)|$))+$/,
  noDoubleDashAndNoStartOrEndWithDash: /^(?!.*--)(?!-).*[^-]$/,
  elasticsearchFieldName: /^[^\s|,]*$/,
  uuid: /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i,
};

export default logzPatterns;
