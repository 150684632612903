import { Observable } from '@logz-pkg/observable';
import { bedrockUtils } from '@logz-pkg/models';

class AiStateService {
  readonly isDrawerOpen = new Observable<boolean>(false);
  readonly sessionId = new Observable<string>(bedrockUtils.getNewSessionId());
  readonly convId = new Observable<string>(null);
  readonly createdAt = new Observable<Date>(null);
  readonly isSessionExpired = new Observable<boolean>(false);
  readonly context = new Observable<unknown>('');
  readonly userInput = new Observable<string>('');
  readonly shouldSendContent = new Observable<boolean>(true);
  readonly rcaId = new Observable<string>(null);
  readonly shouldAutoScroll = new Observable<boolean>(true);

  toggleDrawer = (state: boolean): void => {
    this.isDrawerOpen.set(state);
  };

  setSessionId = (uniqueId?: string): void => {
    this.setShouldSendContent(true);

    if (uniqueId) {
      this.sessionId.set(uniqueId);

      return;
    }

    this.sessionId.set(bedrockUtils.getNewSessionId());
  };

  setContext = (context: unknown): void => {
    this.context.set(context);
  };

  setUserInput = (userInput: string): void => {
    this.userInput.set(userInput);
  };

  setShouldSendContent = (shouldSendContent: boolean): void => {
    this.shouldSendContent.set(shouldSendContent);
  };
}

export const aiStateService = new AiStateService();
