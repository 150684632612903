import { PlanAndBillingState } from '@logz-pkg/enums';
import { httpService, planDetailsApiService, cacheProvider, eventEmitter } from '@logz-pkg/frontend-services';
import { alertDisabledId, alertsNotAvailableForPlanId, s3ArchiveUpgradeId } from '../services.templates.config';
import { getAngularService } from '../../app/angular/injector-provider';

const planTypes = {
  free: 'FREE',
  trial: 'TRIAL',
  standard: 'STANDARD',
  pro: 'PRO',
  enterprise: 'ENTERPRISE',
};

const getPlanDetails = async function getPlanDetails() {
  return { data: await planDetailsApiService.get() };
};

const getRetentionDays = function getRetentionDays() {
  return getPlanDetails().then(planDetails => planDetails.data.retentionDays);
};

eventEmitter.addListener('plan-details-updated', () => {
  cacheProvider.clear(httpService.generateHttpResponseCacheKey(cacheProvider.CacheKeys.SESSION_CONTEXT));
});

let isAlertDialogOpen = false;
const showAlertsNotAvailableForPlanDialog = async () => {
  if (isAlertDialogOpen) {
    return;
  }

  const ngDialog = await getAngularService('ngDialog');

  isAlertDialogOpen = true;
  ngDialog.open({
    template: alertsNotAvailableForPlanId,
    width: '662px',
    closeByEscape: true,
    showClose: false,
    preCloseCallback() {
      isAlertDialogOpen = false;
    },
  });
};

const showAlertDisabledDialog = async (afterAlertWizard, dueToAlertCreation) => {
  if (isAlertDialogOpen) {
    return;
  }

  const [$rootScope, ngDialog] = await Promise.all([getAngularService('$rootScope'), getAngularService('ngDialog')]);
  const dialogScope = $rootScope.$new();

  dialogScope.afterAlertWizard = afterAlertWizard;
  dialogScope.dueToAlertCreation = dueToAlertCreation;

  isAlertDialogOpen = true;
  ngDialog.open({
    template: alertDisabledId,
    width: '583px',
    closeByEscape: true,
    showClose: false,
    scope: dialogScope,
    preCloseCallback() {
      isAlertDialogOpen = false;
    },
  });
};

const s3archiveDialog = async () => {
  const ngDialog = await getAngularService('ngDialog');

  ngDialog.open({
    template: s3ArchiveUpgradeId,
    controller: [
      '$scope',
      '$state',
      function controller($scope, $state) {
        $scope.upgradeNow = function upgradeNow() {
          $scope.closeThisDialog();
          $state.go(PlanAndBillingState.PlanAndBilling);
        };
      },
    ],
    width: '600px',
    closeByEscape: true,
    showClose: false,
  });
};

export const upgradeService = {
  getPlanDetails,
  showAlertsNotAvailableForPlanDialog,
  showAlertDisabledDialog,
  s3archiveDialog,
  planTypes,
  getRetentionDays,
};
