import { cacheProvider } from '@logz-pkg/frontend-services';
import { IKibanaVersion } from './osd-versions.interface';
import { OSD2_0 } from './osd-2-0';

export class KibanaVersionsStrategy implements IKibanaVersion {
  strategy: IKibanaVersion;
  constructor() {}

  async getProductPrefix() {
    await this.init();

    return this.strategy.getProductPrefix();
  }

  async getUrlQuerySearchPart(query) {
    await this.init();

    return this.strategy.getUrlQuerySearchPart(query);
  }

  async getBaseUrl() {
    await this.init();

    return this.strategy.getBaseUrl();
  }

  async getGotoUrl(urlId) {
    await this.init();

    return this.strategy.getGotoUrl(urlId);
  }

  async getVersion() {
    await this.init();

    return this.strategy.getVersion();
  }

  async getIndexPatternFields() {
    await this.init();

    return this.strategy.getIndexPatternFields();
  }

  async getElkAppQuery(objectId, objectType) {
    await this.init();

    return this.strategy.getElkAppQuery(objectId, objectType);
  }

  async getIndexPattern(): Promise<string> {
    await this.init();

    return this.strategy.getIndexPattern();
  }

  async getLuceneSyntaxLink() {
    await this.init();

    return this.strategy.getLuceneSyntaxLink();
  }

  async getConfig() {
    await this.init();

    return this.strategy.getConfig();
  }

  clearKibanaConfig() {
    cacheProvider.clear(cacheProvider.CacheKeys.KIBANA_CONFIG);
  }

  /**
   * In case we have multiple live versions (as we had with K5 & K6)
   * Use the feature flags to relay the strategies.
   *
   * Example:
   * const isKibana6 = this.FeatureFlagService.isFeatureEnabled(FeatureFlags.kibana6);
   * this.strategy = OsdVersionsEnum.Kibana_7_6 ? new Kibana7_6() : new OSD2_0();
   */
  async init({ forceUpdate = false } = {}) {
    if (!forceUpdate && this.strategy) return;

    this.strategy = new OSD2_0();
  }
}

export const kibanaVersion = new KibanaVersionsStrategy();
