import deepmerge from 'deepmerge';
import { ExplorePageDensityModes, ExplorePageGraphYAxisScaleTypes, ExploreTimeInterval } from '@logz-pkg/enums';
import { appStateService, configProvider, LoggerService } from '@logz-pkg/frontend-services';
import { Column, ElasticsearchEnhancedFilterModel, ExploreRecentsStateModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { cloneDeep } from 'lodash';
import { exploreUtils } from '../../utils/explore-utils';
import { savedObjectsService } from 'ui/components/Explore/state/saved-objects.service';

const VERSION = 3;
const STATE_NAME = 'explore-recents';
const defaultState: ExploreRecentsStateModel = {
  _migrated: true,
  filters: [],
  densityMode: ExplorePageDensityModes.TwoLines,
  inputMode: 'LUCENE',
  columns: [],
  accounts: [],
  visualization: {
    yAxisScale: ExplorePageGraphYAxisScaleTypes.Linear,
    timeInterval: ExploreTimeInterval.Auto,
  },
};

const getPreviousVersion = async (): Promise<ExploreRecentsStateModel> => {
  const prevSavedStateV1: ExploreRecentsStateModel = await appStateService.get(STATE_NAME, 1);
  const prevSavedStateV2: ExploreRecentsStateModel = await appStateService.get(STATE_NAME, 2);

  const prevSavedState = prevSavedStateV2?._migrated ? prevSavedStateV2 : prevSavedStateV1;

  const state: ExploreRecentsStateModel = cloneDeep(defaultState);

  if (prevSavedState?.accounts !== undefined) {
    state.accounts = prevSavedState.accounts;
  }

  if (prevSavedState?.densityMode !== undefined) {
    state.densityMode = prevSavedState.densityMode;
  }

  if (prevSavedState?.graphYAxisScaleType !== undefined) {
    state.graphYAxisScaleType = prevSavedState.graphYAxisScaleType;
  }

  if (prevSavedState?.filters !== undefined) {
    state.filters = prevSavedState.filters;
  }

  if (prevSavedState?.inputMode !== undefined) {
    state.inputMode = prevSavedState.inputMode;
  }

  // Moving from v0 to v1 - we changed the columns from string[] to Column[]
  if (prevSavedState?.columns !== undefined) {
    const formattedColumns = prevSavedState.columns.reduce<Column[]>((acc, column) => {
      const id = typeof column === 'string' ? column : column.id;

      if (id === '@timestamp') return acc;

      acc.push(typeof column === 'string' ? { id } : column);

      return acc;
    }, []);

    state.columns = formattedColumns;
  }

  // Moving from v2 to v3 - we added the visualization object and moved the yAxisScaleType to it
  if (prevSavedState?.graphYAxisScaleType !== undefined) {
    state.visualization = {
      yAxisScale: prevSavedState.graphYAxisScaleType,
      timeInterval: ExploreTimeInterval.Auto,
    };
  }

  LoggerService.logInfo({
    message: `explore migrated explore-recents from previous version`,
  });

  return state;
};

const filterDuplicates = queries => {
  return queries
    .filter((query, index, self) => {
      const queryIdentifier = exploreUtils.getQueryUniqueId(query);

      return index === self.findIndex(q => exploreUtils.getQueryUniqueId(q) === queryIdentifier);
    })
    .filter(q => q.length > 0);
};

class ExploreRecentsStatesService {
  state = new Observable<ExploreRecentsStateModel>(defaultState);

  clear = () => this.state.set(defaultState);

  resetRecents = async () => {
    await appStateService.set(STATE_NAME, { ...this.state.get(), recent: [] });
    this.clear();
  };

  private set = async (value: ExploreRecentsStateModel) => {
    if (savedObjectsService.activeSavedObject.get()) return;

    const current = this.state.get();
    const newState: ExploreRecentsStateModel = {
      _migrated: value._migrated ?? current._migrated ?? defaultState._migrated,
      inputMode: value.inputMode ?? current.inputMode ?? defaultState.inputMode,
      filters: value.filters ?? current.filters ?? defaultState.filters,
      columns: value.columns ?? current.columns ?? defaultState.columns,
      accounts: value.accounts ?? current.accounts ?? defaultState.accounts,
      densityMode: value.densityMode ?? current.densityMode ?? defaultState.densityMode,
      visualization: {
        yAxisScale:
          value.visualization?.yAxisScale ?? current.visualization.yAxisScale ?? defaultState.visualization.yAxisScale,
        timeInterval:
          value.visualization?.timeInterval ??
          current.visualization.timeInterval ??
          defaultState.visualization.timeInterval,
      },
    };

    this.state.set(value);
    appStateService.set(STATE_NAME, newState, VERSION);
  };

  get = async (): Promise<ExploreRecentsStateModel> => {
    const savedInAppState: ExploreRecentsStateModel = await appStateService.get(STATE_NAME, VERSION);
    let newState: ExploreRecentsStateModel;

    if (!savedInAppState?._migrated) {
      newState = await getPreviousVersion();
      this.set(newState);
    } else {
      newState = deepmerge(cloneDeep(defaultState), savedInAppState);
    }

    this.state.set(newState);

    return newState;
  };

  setInputMode = async (inputMode: ExploreRecentsStateModel['inputMode']) => {
    const newState = { ...(await this.get()), inputMode };

    await this.set(newState);
  };

  setColumns = async (columns: ExploreRecentsStateModel['columns']) => {
    const currentState = await this.get();

    const newColumns = columns.filter(column => column.id !== '@timestamp');

    const newState: ExploreRecentsStateModel = { ...currentState, columns: newColumns };

    await this.set(newState);
  };

  setAccounts = async (accounts: ExploreRecentsStateModel['accounts']) => {
    const newState = { ...(await this.get()), accounts };

    await this.set(newState);
  };

  setDensityMode = async (densityMode: ExplorePageDensityModes) => {
    const newState = { ...(await this.get()), densityMode };

    await this.set(newState);
  };

  setYAxisScale = async (yAxisScale: ExplorePageGraphYAxisScaleTypes) => {
    const currentState = await this.get();

    const newState: ExploreRecentsStateModel = {
      ...currentState,
      visualization: { ...currentState.visualization, yAxisScale },
    };

    await this.set(newState);
  };

  setTimeInterval = async (timeInterval: ExploreTimeInterval) => {
    const currentState = await this.get();

    const newState: ExploreRecentsStateModel = {
      ...currentState,
      visualization: { ...currentState.visualization, timeInterval },
    };

    await this.set(newState);
  };

  addRecentFilter = async (additionalQuery: ElasticsearchEnhancedFilterModel[]) => {
    const maxRecentFilters = await configProvider.getValue('explore.maxRecentFilters');

    let filters = filterDuplicates([additionalQuery, ...this.state.get().filters]);

    if (filters.length >= maxRecentFilters) {
      filters = filters.slice(0, maxRecentFilters);
    }

    const newState = { ...(await this.get()), filters };

    await this.set(newState);
  };
}

export const exploreRecentsStatesService = new ExploreRecentsStatesService();
