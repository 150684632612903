import { format } from 'url';
import { urlParser } from '@logz-pkg/utils';
import { grafanaUrlQueryParams } from './grafana-route-base';
import { metricsRouteName } from './grafana.route';
import { appRouter } from 'services/router/router.service';

type GrafanaParams = { [key in typeof grafanaUrlQueryParams[number]]?: any } & {
  resource?: string;
  switchToAccountId?: number;
};

const buildGrafanaUrl = ({
  params: { resource, ...params } = {},
  varParams = {},
}: {
  params?: GrafanaParams;
  varParams?: Record<string, string>;
}) => {
  const partialUrl = appRouter.stateService.href(
    metricsRouteName,
    {
      resource,
      ...params,
    },
    { inherit: false, absolute: true },
  );

  const partialParsed = urlParser(partialUrl);
  const varQueryParams = new URLSearchParams({
    ...(partialParsed.hashQuery as Record<string, string>),
    ...varParams,
  }).toString();

  const finalUrl = format({
    ...partialParsed.parsedUrl,
    hash: format({
      pathname: partialParsed.hashPathname,
      query: varQueryParams,
    }),
  });

  return finalUrl;
};

const getDashboardUrl = (dashboardId: string) => {
  return { resource: `d/${dashboardId}` };
};

const getDashboardsDirUrl = () => {
  return { resource: 'dashboards' };
};

export const grafanaRouteService = {
  buildGrafanaUrl,
  getDashboardUrl,
  getDashboardsDirUrl,
};
