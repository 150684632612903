import { UIRouterReact } from '@uirouter/react';
import { urlParamsStateService } from 'ui/state/url-params.state.service';

export const registerSaveParamsTransitions = (appRouter: UIRouterReact) => {
  appRouter.transitionService.onStart({}, function (transition) {
    if (transition.to()?.data?.saveParams) {
      const targetParamNames = Object.keys(transition.to()?.params ?? {});
      const params = Object.entries(transition.params()).reduce((acc, [name, value]) => {
        if (targetParamNames.includes(name)) {
          return { ...acc, [name]: value };
        }

        return acc;
      }, {});

      urlParamsStateService.set(transition.to()?.name ?? '', params);
    }
  });
};
