import React, { useState } from 'react';
import styled from 'styled-components';
import { Group, Link, Flex, Icon, Text, colorsV2, Divider, colors } from '@logz-ui/styleguide';
import { AICopilotChatMessageSubjects } from '@logz-pkg/enums';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';
import { AiChatAgentInvocation } from 'ui/components/AI/Components/Messages/Agent/Invocation';
import { AiChatAgentReadMore } from 'ui/components/AI/Components/Messages/Agent/ReadMore';

const StyledGroup = styled(Group)`
  &:hover {
    background: ${colorsV2.indigo[25]};
  }
`;

const Title: React.FC<{ subTitle: string; onClick: () => void }> = ({ subTitle, onClick }) => {
  return (
    <Group onClick={onClick} flexDirection={'column'} rowGap={1}>
      <Text size={12} color={'royalBlue.500'} weight={600}>
        {subTitle.toUpperCase()}
      </Text>
    </Group>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.collapse.styledLinkColor};
`;

export const AiChatAgentCollapse: React.FC<MessageType> = props => {
  const { subTitle, content, invocationFunctions } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleToggleCollapse = async () => {
    setIsOpen(prevState => !prevState);
  };

  if (!content) return null;

  return (
    <Group ml={10} pl={5} mr={8} fullWidth vertical gap={0}>
      <Flex context={'ai-collapse'} subject={AICopilotChatMessageSubjects.CollapseMessage} flexDirection={'column'}>
        <StyledGroup alignItems="center" py={3} px={2} gap={10} justifyContent={'space-between'} fullWidth>
          <Title onClick={handleToggleCollapse} subTitle={subTitle} />
          <StyledLink onClick={handleToggleCollapse} subject={'collapse'}>
            <Icon size={14} icon={isOpen ? 'chevron-down-regular' : 'chevron-right-regular'} />
          </StyledLink>
        </StyledGroup>
        {isOpen && (
          <Flex ml={2} mr={6} pr={1} mt={3} flexDirection={'column'}>
            <Group mb={5}>
              <AiChatAgentReadMore {...props} />
            </Group>

            {invocationFunctions &&
              invocationFunctions.map((invocationFunction, index) => {
                return (
                  <AiChatAgentInvocation {...invocationFunction} key={`${invocationFunction.functionName}-${index}`} />
                );
              })}
          </Flex>
        )}
      </Flex>
      {<Divider color={colors.gray[300]} />}
    </Group>
  );
};
