import { assignQueryOptions, createQueryString, getUrlResourceParam } from '../../product/route-helpers/route-helpers';
import { AppStateDeclaration } from '../AppStateDecleration';
import { LazyReact } from '../../../ui/components/shared/LazyReact';
import { grafanaUrlQueryParams } from './grafana-route-base';

const resourceUrlParam = getUrlResourceParam('resource');
const grafanaQueryString = createQueryString(grafanaUrlQueryParams);
const params = assignQueryOptions({}, ['resource', ...grafanaUrlQueryParams]);

export const grafanaSnapshotStates: AppStateDeclaration[] = [
  {
    name: 'dashboard.metrics-snapshot',
    url: `/metrics-snapshot${resourceUrlParam}${grafanaQueryString}`,
    params: {
      ...params,
      kiosk: {
        value: true,
      },
      id: {
        value: null,
      },
    },
    views: {
      grafana: {
        component: props => (
          <LazyReact
            {...props}
            lazyImport={() => import('ui/components/Grafana/GrafanaSnapshot')}
            resolver={({ GrafanaSnapshotComponent }) => GrafanaSnapshotComponent}
          />
        ),
      },
    },
    data: { authenticate: false },
    dynamic: true,
  },
  {
    name: 'dashboard.grafana-snapshot',
    url: `/grafana-snapshot${resourceUrlParam}${grafanaQueryString}`,
    redirectTo: 'dashboard.metrics-snapshot',
    params: {
      ...params,
      kiosk: {
        value: true,
      },
      id: {
        value: null,
      },
    },
    data: { authenticate: false },
    dynamic: true,
  },
];
