import { AccountType } from '@logz-pkg/enums';
import { accountModelManipulator } from '@logz-pkg/models';
import _ from 'lodash';
import { userService } from '@logz-pkg/frontend-services';
import { timelessAccountsService } from '../../app/dashboard/settings/manage-accounts/timeless-accounts-card/timeless-accounts-service';
import TriggerConfigService from './trigger-config.service';

const triggerConfigService = TriggerConfigService();

/**
 * Account type whitelist for Alerts and Optimisers
 */
const OptimizableAccountWhitelist = [AccountType.Owner, AccountType.Sub, AccountType.Security, AccountType.Metrics];
const AlertableAccountWhitelist = [
  AccountType.Owner,
  AccountType.Sub,
  AccountType.Security,
  AccountType.Metrics,
  AccountType.Timeless,
];

/**
 * Bind Chosen searchable accounts to total accounts collection
 * @return {object} chosen searchable accounts enriched with data that will create
 *         a binding with the total accounts collection
 */
const bindChosenAccountsToAllAccounts = (allAccountsCollection, chosenAccounts) =>
  allAccountsCollection.filter(account => _.some(chosenAccounts, { data: account.data }));

/**
 * Get all users searchable accounts
 * @return {Promise}
 */
const getAllSearchableAccounts = () =>
  Promise.all([userService.getSummary(), timelessAccountsService.getAllSearchableAccounts()]).then(data => {
    const [userSummaryRes, searchableAccounts] = data;

    return {
      data: _.map(searchableAccounts, account => ({
        data: account.accountId,
        type: account.type,
        name: account.name,
      })),
      noResults: 'No accounts found',
      currAccount: {
        data: [
          {
            data: userSummaryRes.data.accountId,
            name: userSummaryRes.data.accountName,
          },
        ],
      },
    };
  });

/**
 * Get all users searchable accounts for Optimizers
 * with filters out account types until new endpoint is created
 * @return {Promise}
 */
const filterOptimizableAccountsFromData = results => {
  results.data = results.data.filter(item => OptimizableAccountWhitelist.includes(item.type));

  return results;
};

const getAllOptimizableAccounts = () => getAllSearchableAccounts().then(filterOptimizableAccountsFromData);

/**
 * Get all users searchable accounts for Alerts
 * with filters out account types until new endpoint is created
 * @return {Promise}
 */
const filterAlertableAccountsFromData = results => {
  results.data = results.data.filter(item => AlertableAccountWhitelist.includes(item.type));

  return results;
};

const getAllAlertableAccounts = () => getAllSearchableAccounts().then(filterAlertableAccountsFromData);

/**
 * Parse chosen searchable accounts
 * @return {object} chosen searchable accounts
 */
const parseChosenSearchableAccounts = (accounts, currAccount, chosenAccounts) => {
  const { allSubAccounts, subAccountsIds, allTimelessIndices, timelessIndices } = chosenAccounts;

  const searchableAccountsIds = {
    data: accounts.filter(account => {
      const isTimelessAccount = accountModelManipulator.deprecatedIsTimeless(account.type);

      const isAllSubAccountsChosen = allSubAccounts && !isTimelessAccount;
      const isAllTimelessIndexesChosen = allTimelessIndices && isTimelessAccount;
      const isTimelessIndexChosen = _.indexOf(timelessIndices, account.data);
      const isSubAccountChosen = _.indexOf(subAccountsIds, account.data);

      return (
        isAllSubAccountsChosen || isAllTimelessIndexesChosen || isTimelessIndexChosen > -1 || isSubAccountChosen > -1
      );
    }),
  };

  if (!_.size(searchableAccountsIds.data)) {
    searchableAccountsIds.data.push(currAccount[0]);
  }

  searchableAccountsIds.data = bindChosenAccountsToAllAccounts(accounts, searchableAccountsIds.data);

  return searchableAccountsIds;
};

/**
 * @deprecated
 */
function getAllTimelessIndices(accounts) {
  return accounts.filter(account => accountModelManipulator.deprecatedIsTimeless(account.type));
}
/**
 * @deprecated
 */
function getAllSubAccounts(accounts) {
  return accounts.filter(account => !accountModelManipulator.deprecatedIsTimeless(account.type));
}

/**
 * Serialize Chosen searchable accounts according to API structure
 * @return {object} Chosen searchable accounts organized in an API structure suitable for server request
 */
const serializeChosenAccountsToApiStructure = (chosenAccounts, allAccounts) => {
  const searchableAccounts = triggerConfigService.getSearchableAccountsDefaultModel();

  const allTimelessIndices = getAllTimelessIndices(allAccounts);
  const allSubAccounts = getAllSubAccounts(allAccounts);
  const chosenTimelessIndices = getAllTimelessIndices(chosenAccounts);
  const chosenSubAccounts = getAllSubAccounts(chosenAccounts);

  searchableAccounts.timelessIndices = _.map(chosenTimelessIndices, 'data');
  searchableAccounts.subAccountsIds = _.map(chosenSubAccounts, 'data');

  // indices/accounts list should be empty if they all been chosen (according to the API)
  if (allTimelessIndices.length === chosenTimelessIndices.length) {
    searchableAccounts.allTimelessIndices = true;
    searchableAccounts.timelessIndices = [];
  }

  if (allSubAccounts.length === chosenSubAccounts.length) {
    searchableAccounts.allSubAccounts = true;
    searchableAccounts.subAccountsIds = [];
  }

  return searchableAccounts;
};

// This is a temporary check to verify that users will not create optimizer on timeless indexes.
// When this feature will be supported (if ever), we will remove this code and check.
const optimizeOnTimelessAccountsValidation = (chosenAccounts, triggerType) => {
  if (triggerType === triggerConfigService.ALERT_NAME || triggerType === triggerConfigService.SECURITY_NAME) {
    return Promise.resolve();
  }

  const chosenTimelessIndices = getAllTimelessIndices(chosenAccounts);

  if (chosenTimelessIndices.length === 0) return Promise.resolve();

  const accountNames = chosenTimelessIndices.map(acc => `'${acc.name}'`).join(', ');

  return Promise.reject({
    data: {
      message: `You can't create an Optimizer on Timeless Indexes accounts.
                          Please remove ${accountNames} from selection`,
    },
  });
};

export const triggerSearchableAccountsService = {
  getAllSearchableAccounts,
  parseChosenSearchableAccounts,
  getAllOptimizableAccounts,
  filterOptimizableAccountsFromData,
  getAllAlertableAccounts,
  filterAlertableAccountsFromData,
  serializeChosenAccountsToApiStructure,
  optimizeOnTimelessAccountsValidation,
};
