import { kibanaFilters } from '@logz-pkg/models';
import { startCase } from 'lodash';
import moment, { Moment } from 'moment';
import rison from 'rison-node';
import { kibanaVersion } from './versions/osd-versions.strategy';
import { appRouter } from 'services/router/router.service';

export const kibanaTimeModes = {
  absolute: 'absolute',
  relative: 'relative',
};

export const mapTime = {
  today: {
    from: 'now/d',
    to: 'now/d',
  },
  last15Minutes: {
    from: 'now-15m',
    to: 'now',
  },
  yesterday: {
    from: 'now-1d/d',
    to: 'now-1d/d',
  },
  last24Hours: {
    from: 'now-24h',
    to: 'now',
  },
  '2DaysAgo': {
    from: 'now-2d/d',
    to: 'now',
  },
  thisWeek: {
    from: 'now/w',
    to: 'now/w',
  },
  thisMonth: {
    from: 'now/M',
    to: 'now/M',
  },
};

const buildRouteForDiscover = async ({
  time,
  query,
  from,
  to,
  filters,
  columns = [],
  mode = kibanaTimeModes.absolute,
  index = 'logzioCustomerIndex*',
  skipSort = false,
  resource = '',
  switchToAccountId = null,
}: {
  query?: string;
  time?: keyof typeof mapTime;
  from?: string | number | Date | Moment;
  to?: string | number | Date | Moment;
  filters?: Record<string, any>;
  columns?: string[];
  mode?: string;
  index?: string;
  skipSort?: boolean;
  resource?: string;
  switchToAccountId?: number | null;
}) => {
  let endDate;
  let startDate;

  if (time) {
    startDate = mapTime[time].from;
    endDate = mapTime[time].to;
  } else if (mode === kibanaTimeModes.absolute) {
    startDate = moment(from).toISOString();
    endDate = moment(to).toISOString();
  } else {
    startDate = from;
    endDate = to;
  }

  const kibanaGlobalState = {
    refreshInterval: {
      display: 'Off',
      section: 0,
      value: 0,
    },
    time: {
      from: startDate,
      to: endDate,
      mode,
    },
  };

  const indexPattern = await kibanaVersion.getIndexPattern();
  const kibanaAppState = {
    columns: ['message', ...columns.filter(column => column !== 'message')],
    // Not mandatory
    filters: filters ? kibanaFilters.elasticToKibanaFilters(filters, indexPattern) : null,
    query: await kibanaVersion.getUrlQuerySearchPart(query),
    ...(skipSort ? {} : { sort: [['@timestamp', 'desc']] }),
    index,
  };

  return {
    _g: rison.encode(kibanaGlobalState),
    _a: rison.encode(kibanaAppState),
    resource: `discover${resource}`,
    ...(switchToAccountId === null ? {} : { switchToAccountId }),
  };
};

const hasQuery = () => {
  const globalParam = appRouter?.globals?.params?._a;

  if (!globalParam) return false;

  const decodedLocalUrl = rison.decode(globalParam);
  const { query } = decodedLocalUrl.query;

  return query !== '';
};

const extractTitle = (params: { [key: string]: any }): string => {
  const decodedLocalUrl = params?._a && rison.decode(params._a);
  const resource = params?.resource;
  const name = resource ? startCase(resource?.split('/')[0]) : 'OSD';

  return `${
    decodedLocalUrl?.title || decodedLocalUrl?.vis?.title || (resource?.includes('discover') ? 'Discover' : name)
  }`;
};

const redirectToAppState = async redirectTo => {
  return appRouter.stateService.go(redirectTo);
};

export const kibanaRouteService = {
  buildRouteForDiscover,
  redirectToAppState,
  hasQuery,
  extractTitle,
};
