import { NotificationService, Text, Spinner } from '@logz-ui/styleguide';
import { useEffect } from 'react';
import { AppMode as AppModesEnum, NavigationSubjects, ProductKeys } from '@logz-pkg/enums';
import { sessionApiService, sessionStateService, LoggerService as Logger } from '@logz-pkg/frontend-services';
import { dashboardHeaderService } from '../dashboard/dashboard-header/dashboard-header.service';
import { AppStateDeclaration } from './AppStateDecleration';
import {
  logsEmptyStateRouteName,
  metricsEmptyStateRouteName,
  siemEmptyStateRouteName,
  tracingEmptyStateRouteName,
} from './empty-states.routes';
import { kibanaRouteName } from './osd/osd.route.params';
import { securityRouteState } from './security/security.routes.names';
import { metricsRouteName } from './grafana/grafana.route';
import { tracingRouteStates } from './tracing/tracing-sampling.routes.names';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { SiemEmptyState } from 'ui/components/Siem/SiemEmptyState/SiemEmptyState';
import { LogManagementEmptyState } from 'ui/components/LogManagement/EmptyState';
import { getProductForState } from 'ui/components/App/Navigation/utils';
import { TracingEmptyState } from 'ui/components/Tracing';
import { GrafanaEmptyState } from 'ui/components/Grafana/Onboarding/GrafanaEmptyState';
import { appRouter } from 'services/router/router.service';

export const switchModeRouteName = 'dashboard.switch-mode';

const redirectIfModeAvailable = async (mode: AppModesEnum, toState: string) => {
  const { userSummary } = await sessionApiService.get();
  const modeIsAvailable = userSummary?.systemModes?.includes(mode);

  if (modeIsAvailable) {
    return appRouter.stateService.go(toState);
  }
};

const SwitchModeToast = ({ accountName }) => (
  <>
    You switched to the <Text weight={500}> {accountName}</Text> account
  </>
);

const productToEmptyStateRouteName = {
  [ProductKeys.Logs]: logsEmptyStateRouteName,
  [ProductKeys.Siem]: siemEmptyStateRouteName,
  [ProductKeys.Metrics]: metricsEmptyStateRouteName,
  [ProductKeys.Tracing]: tracingEmptyStateRouteName,
};

const switchModeAction = async $stateParams => {
  const nextAppMode = AppModes[$stateParams.mode];

  await dashboardHeaderService.switchMode(nextAppMode, {
    name: $stateParams.state,
    params: $stateParams.nextStateParams,
  });

  // Can't use the session state hook here because it's not updated in time.
  const accountName = sessionStateService.session.get().loggedInAccount?.name;

  NotificationService.success({
    key: `switch-account-${$stateParams.mode}`,

    message: <SwitchModeToast accountName={accountName} />,
    subject: NavigationSubjects.SwitchAccountToast,
  });
};

const SwitchModeContainer = ({ $stateParams }) => {
  useEffect(() => {
    (async () => {
      const modeToSwitchTo = $stateParams.mode;

      const { userSummary } = await sessionApiService.get();
      const hasSiem = userSummary?.systemModes?.includes(AppModesEnum.SECURITY);
      const hasLogs = userSummary?.systemModes?.includes(AppModesEnum.OPERATIONS);

      if (AppModes.SECURITY && AppModes.OPERATIONS) {
        if (modeToSwitchTo === AppModes.SECURITY.value && !hasSiem) {
          return appRouter.stateService.go(productToEmptyStateRouteName[ProductKeys.Siem], $stateParams);
        }

        if (modeToSwitchTo === AppModes.OPERATIONS.value && !hasLogs) {
          const product = getProductForState({
            state: $stateParams.state,
            appMode: AppModes.OPERATIONS.value,
            stateParams: $stateParams.nextStateParams,
          });

          return appRouter.stateService.go(productToEmptyStateRouteName[product], $stateParams);
        }
      } else {
        Logger.logError({ message: 'AppModes are not defined.' });
      }

      switchModeAction($stateParams);
    })();
  }, []);

  return <Spinner fullscreen />;
};

const SiemEmptyStateWrapper = ({ $stateParams }) => {
  return <SiemEmptyState onSuccess={() => switchModeAction($stateParams)} />;
};

const LogsEmptyStateWrapper = ({ $stateParams }) => {
  return <LogManagementEmptyState canCreateLogsAccount={false} />;
};

const TracingEmptyStateWrapper = ({ $stateParams }) => {
  return <TracingEmptyState />;
};

const MetricsEmptyStateWrapper = ({ $stateParams }) => {
  return <GrafanaEmptyState canCreateMetricsAccount={false} />;
};

export const switchModeRouteState: AppStateDeclaration = {
  name: switchModeRouteName,
  url: '/switch-mode?mode&state ',
  params: {
    mode: {
      inherit: false,
      value: AppModes.OPERATIONS?.value,
      dynamic: true,
    },
    state: null,
    nextStateParams: null,
  },
  data: { authenticate: true },
  component: SwitchModeContainer,
};

export const siemEmptyStateRouteState: AppStateDeclaration = {
  name: siemEmptyStateRouteName,
  url: '/dashboard/security-trial?mode&state',
  data: { authenticate: true, title: 'Cloud SIEM' },
  resolve: [
    {
      token: 'securityModeAvailable',
      resolveFn: () => redirectIfModeAvailable(AppModesEnum.SECURITY, securityRouteState),
    },
  ],
  component: SiemEmptyStateWrapper,
};

export const logsEmptyStateRouteState: AppStateDeclaration = {
  name: logsEmptyStateRouteName,
  url: '/dashboard/logs-trial',
  data: { authenticate: true, title: 'Log Management' },
  resolve: [
    {
      token: 'operationsModeAvailable',
      resolveFn: () => redirectIfModeAvailable(AppModesEnum.OPERATIONS, kibanaRouteName),
    },
  ],
  component: LogsEmptyStateWrapper,
};

export const tracingEmptyStateRouteState: AppStateDeclaration = {
  name: tracingEmptyStateRouteName,
  url: '/dashboard/tracing-trial',
  data: { authenticate: true, title: 'Distributed Tracing' },
  resolve: [
    {
      token: 'operationsModeAvailable',
      resolveFn: () => redirectIfModeAvailable(AppModesEnum.OPERATIONS, tracingRouteStates.jaeger),
    },
  ],
  component: TracingEmptyStateWrapper,
};

export const metricsEmptyStateRouteState: AppStateDeclaration = {
  name: metricsEmptyStateRouteName,
  url: '/dashboard/metrics-trial',
  data: { authenticate: true, title: 'Infrastructure Monitoring' },
  resolve: [
    {
      token: 'operationsModeAvailable',
      resolveFn: () => redirectIfModeAvailable(AppModesEnum.OPERATIONS, metricsRouteName),
    },
  ],
  component: MetricsEmptyStateWrapper,
};
