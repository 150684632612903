import { Permissions, SecurityRulesStates } from '@logz-pkg/enums';
import { redirectIfNoPermission } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

const LazyRulesEdit = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Rules/Edit/Container')}
    resolver={({ RulesEditContainer }) => RulesEditContainer}
    {...props}
  />
);
const redirectIfNoRulesPermission = redirectIfNoPermission({ hasPermission: Permissions.SECURITY_RULES_PERMISSION });
export const rules: AppStateDeclaration[] = [
  {
    name: SecurityRulesStates.New,
    url: '/rules/v2019/new',
    component: LazyRulesEdit,
    params: {
      query: null,
      filters: null,
      accountIds: null,
    },
    data: {
      authenticate: true,
      title: 'Create a rule',
      breadcrumb: { label: 'Create a rule', parent: 'dashboard.security.rules.definitions' },
    },
    resolve: [redirectIfNoRulesPermission],
  },
  {
    name: SecurityRulesStates.Edit,
    url: '/rules/v2019/:id',
    component: LazyRulesEdit,
    data: {
      authenticate: true,
      title: 'Edit a rule',
      breadcrumb: { label: 'Edit a rule', parent: 'dashboard.security.rules.definitions' },
    },
    resolve: [redirectIfNoRulesPermission],
  },
  {
    name: SecurityRulesStates.Duplicate,
    url: '/rules/v2019/:id/duplicate',
    component: LazyRulesEdit,
    params: {
      isDuplicated: true,
    },
    data: {
      authenticate: true,
      breadcrumb: { label: 'Duplicate a rule', parent: 'dashboard.security.rules.definitions' },
    },
    resolve: [redirectIfNoRulesPermission],
  },
];
