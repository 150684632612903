import React, { PropsWithChildren } from 'react';
import { Card, Flex, Text } from '@logz-ui/styleguide';
import { WidthProps } from 'styled-system';
import { formatDate } from '../utils/utils';
import { DateFormat } from '../types';

interface ITooltipContainerProps {
  date?: string | number;
  title?: string | number;
  subject?: string;
  format?: DateFormat['format'];
  vertical?: boolean;
  timezone?: string;
  width?: WidthProps['width'];
}

export const TooltipContainer: React.FC<PropsWithChildren<ITooltipContainerProps>> = ({
  children,
  date,
  timezone,
  format = 'MMM-DD-YYYY HH:mm:ss',
  title = formatDate({ date, format, timezone }) ?? '',
  subject,
  vertical = false,
  width,
}) => {
  return (
    <Card minWidth={vertical ? '120px' : '220px'} px={4} py={3} subject={subject} width={width}>
      <Flex flexDirection={'column'} width={'100%'}>
        {date && title && (
          <Text mb={'8px'} weight={500}>
            {title}
          </Text>
        )}
        {children}
      </Flex>
    </Card>
  );
};
