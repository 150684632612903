import { AccountType, PLAN_TYPE, UserRole } from '@logz-pkg/enums';
import type { ISessionState } from '@logz-pkg/frontend-services';
import { ConditionType, IDisplayCondition } from '../Sidenav/item-configs/item-configs';

export const conditionChecks = {
  [ConditionType.Permission]: (requiredPermission, { userSession }: ISessionState) =>
    userSession?.permissions?.includes(requiredPermission),
  [ConditionType.OneOfPermissions]: (requirePermissions, { userSession }: ISessionState) => {
    const userPermissions = new Set(userSession?.permissions);

    return requirePermissions.some(permission => userPermissions.has(permission));
  },
  [ConditionType.UserRole]: (requiredRole, { userSession }: ISessionState) => userSession?.role === requiredRole,
  [ConditionType.IsSysAdmin]: (requiredValue, { isSysAdmin }: ISessionState) => isSysAdmin === requiredValue,
  [ConditionType.HasFeatureFlag]: (requiredFeatureFlag, { featureFlags }: ISessionState) =>
    featureFlags?.includes(requiredFeatureFlag),
  [ConditionType.HasOneOfFeatureFlags]: (requiredFeatureFlags, { featureFlags }: ISessionState) =>
    requiredFeatureFlags.some(requiredFeatureFlag => featureFlags?.includes(requiredFeatureFlag)),
  [ConditionType.AppMode]: (requiredAppMode, { appMode }: ISessionState) => requiredAppMode === appMode?.value,
  [ConditionType.DoesntHaveFeatureFlag]: (targetFeatureFlag, { featureFlags }: ISessionState) =>
    !featureFlags?.includes(targetFeatureFlag),
  [ConditionType.AccountOfType]: (requiredAccountType: AccountType, { loggedInAccount }: ISessionState) =>
    loggedInAccount?.type === requiredAccountType,
  [ConditionType.OneOfNotAccountPlan]: (notAllowedPlanTypes: PLAN_TYPE[], { plan: { planType } }) =>
    !notAllowedPlanTypes.includes(planType),
  [ConditionType.OneOfUserRoles]: (requireRoles: UserRole[], { userSession }: ISessionState) =>
    requireRoles.includes(userSession?.role),
};

export const checkDisplayConditions = ({
  conditions,
  sessionState,
}: {
  conditions?: IDisplayCondition[];
  sessionState: ISessionState;
}): boolean => {
  if (!conditions || !conditions.length) {
    return true;
  }

  return conditions
    .map(condition => conditionChecks[condition.type](condition.value as any, sessionState))
    .every(result => Boolean(result));
};
