import React, { FunctionComponent, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Group, Icon, IIconProps, Text } from '@logz-ui/styleguide';

interface IHeaderButtonProps {
  isHovering?: boolean;
  icon: IIconProps['icon'] | JSX.Element;
  active: boolean;
  children: React.ReactNode;
  subject?: string;
}

const ButtonContainer = styled(Group)<Partial<IHeaderButtonProps>>`
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[200]};
    `}
`;

export const HeaderButton: FunctionComponent<IHeaderButtonProps> = forwardRef(
  ({ children, active, icon, subject }, ref) => (
    <ButtonContainer
      vertical
      alignItems={'center'}
      justifyContent={'center'}
      py={2}
      px={4}
      active={active}
      subject={subject}
    >
      {typeof icon === 'string' ? <Icon icon={icon} size={18} color={'royalBlue.500'} /> : icon}
      <Text color={'royalBlue.1000'} weight={500} size={12}>
        {children}
      </Text>
    </ButtonContainer>
  ),
);
