import { colorsV2 } from '@logz-ui/styleguide';
import { GroupPodsByType, WorkloadType } from 'ui/kube360/dal/types';
import { HelpQuestionsChatType } from 'ui/components/AI/types';

export const HelpQuestionsByType: Record<WorkloadType | 'node' | 'pod' | 'cluster', string[]> = {
  rollout: [],
  cluster: [
    'Is the cluster experiencing any resource shortages or bottlenecks based on node capacity, resource limits, or overall utilization?',
    "Are there potential misconfigurations in the cluster's settings that could impact performance, reliability, or security?",
    'Can you identify trends in resource usage across the cluster that suggest upcoming capacity requirements or scaling needs?',
  ],
  pod: [
    'Is this pod experiencing resource constraints or bottlenecks based on current and historical CPU, memory, and network usage compared to the configuration?',
    "Can you identify potential misconfigurations in the pod's YAML that could impact performance or reliability?",
    "Based on the data, what recommendations for optimizing the pod's resource allocation or configuration?",
  ],
  deployment: [
    "Are there issues with the deployment's replica management or scaling based on replica count, usage changes, and configuration?",
    "From analyzing the YAML, are there potential misconfigs that could impact deployment's performance or reliability?",
    "How does the deployment's resource usage across replicas compare to node capacity, any node-level constraints?",
  ],
  daemonSet: [
    'Based on the data, are there nodes with unhealthy DaemonSet pods, and how does usage across nodes compare to the configuration?',
    "Can you analyze DaemonSet's usage patterns across nodes and identify potential imbalances or load distribution issues?",
    "Any potential mismatches between DaemonSet's YAML settings and node characteristics like OS, architecture?",
  ],
  statefulSet: [
    'Are all StatefulSet replicas running healthily, and how does resource usage compare to YAML config?',
    'Can you analyze persistent volume usage trends and insights into potential storage issues or bottlenecks?',
    "Any potential issues with StatefulSet's update strategy or rolling update settings in the YAML config?",
  ],
  job: [
    'Has the Job completed successfully or any active/failed pods, and how does usage compare to the configuration?',
    "Can you analyze Job's resource usage patterns and insights into potential constraints or inefficiencies?",
    "Any issues with Job's parallelism, backoff limit or other settings in the YAML config?",
  ],
  node: [
    'Based on usage and capacity, is this node experiencing any resource constraints or capacity issues?',
    "Can you analyze node's usage trends and provide recommendations for optimal resource allocation or node pool sizing?",
    "Are there any potential issues with the node's kernel version, OS, or other system-level configurations?",
  ],
};

export const k8sHelpQuestions: HelpQuestionsChatType = {
  assistant: {
    help: [
      {
        text: 'Pinpoint top log issues and suggest fixes',
        iconName: 'magnifying-glass-regular',
        color: colorsV2.green[600],
      },
      {
        text: 'Identify root causes of critical errors in logs',
        iconName: 'magnifying-glass-regular',
        color: colorsV2.green[600],
      },
      {
        text: 'Spot trends indicating performance issues',
        iconName: 'magnifying-glass-regular',
        color: colorsV2.green[600],
      },
    ],
  },
  pro: {
    trends: [
      {
        text: 'Services’ error in the last 24 hours',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
      {
        text: 'Error trend per component over the last 2 days',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
    ],
    compare: [
      {
        text: 'Prod vs. staging error rate over the past 7 days',
        iconName: 'scale-unbalanced-regular',
        color: colorsV2.pink[600],
      },
      {
        text: 'Log count comparison: yesterday vs. today',
        iconName: 'scale-unbalanced-regular',
        color: colorsV2.pink[600],
      },
    ],
  },
};

export const MapK8sViewToDirectory: Record<GroupPodsByType, string> = {
  node: 'nodes',
  pod: 'pods',
  workloads: 'workloads',
  deployment: 'workloads.deployment',
  rollout: 'workloads.rollout',
  daemonSet: 'workloads.daemonSet',
  statefulSet: 'workloads.statefulSet',
  job: 'workloads.job',
};
