import { GuidedWalkthroughTask, GuidedWalkthroughTaskId } from '@logz-pkg/models';
import { AppStateEntities, UserRole } from '@logz-pkg/enums';
import { settingsRoutesStates } from '../../../app/states/settings.routes.names';
import { costManagementRouteName } from '../../../app/states/cost-management.route';
import { appRouter } from 'services/router/router.service';
import { tourService } from 'services/tours/tours';
import { TourType } from 'services/tours/types';
import { guidedWalkthroughStateService } from 'ui/state/guided-walkthrough.state.service';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { exploreRouteNames } from 'states/explore/explore.route.names';

export type GuidedWalkthroughTasks = Record<GuidedWalkthroughTaskId, GuidedWalkthroughTask>;
export type GuidedWalkthroughPhase = { name: string; tasks: GuidedWalkthroughTaskId[]; isOpenByDefault: boolean };

export const WalkthroughTasks: GuidedWalkthroughTasks = {
  [GuidedWalkthroughTaskId.SendData]: {
    id: GuidedWalkthroughTaskId.SendData,
    entityLevel: AppStateEntities.Account,
    userRoles: [UserRole.Admin],
    title: 'Send data',
    description:
      'Select all of your integrations and start effortlessly collecting and analyzing your logs, metrics, and traces data.',
    hint: 'Complete this step by connecting and sending your data to Logz.io.',
    actionSection: {
      callback: async () => {
        appRouter.stateService.go(integrationsRouteStates.page);
      },
      text: 'Start here',
    },
  },
  [GuidedWalkthroughTaskId.InviteTeamMember]: {
    id: GuidedWalkthroughTaskId.InviteTeamMember,
    entityLevel: AppStateEntities.Account,
    userRoles: [UserRole.Admin],
    title: 'Invite team members',
    description: 'Invite your team to Logz.io so that you can share insights, assign tasks, and collaborate.',
    hint: 'Complete this step by inviting one (or more!) team members to your Logz.io account.',
    actionSection: {
      callback: async () => {
        appRouter.stateService.go(settingsRoutesStates.manageUsers);
      },
      text: 'Invite users',
      learnMoreUrl: 'https://docs.logz.io/user-guide/users/',
    },
  },
  [GuidedWalkthroughTaskId.SearchInExplore]: {
    id: GuidedWalkthroughTaskId.SearchInExplore,
    entityLevel: AppStateEntities.User,
    userRoles: [UserRole.Admin, UserRole.User],
    title: 'Search your data',
    description:
      'Run a query in Explore to see which valuable information you can extract from your logs. For example, try searching for “error”.',
    actionSection: {
      callback: async () => {
        await appRouter.stateService.go(exploreRouteNames.explore);
        await tourService.showTour({ tourType: TourType.SearchInExplore });
      },
      text: 'Run a query',
      learnMoreUrl: 'https://docs.logz.io/docs/user-guide/new-explore/',
    },
  },
  [GuidedWalkthroughTaskId.SaveSearchInExplore]: {
    id: GuidedWalkthroughTaskId.SaveSearchInExplore,
    entityLevel: AppStateEntities.User,
    userRoles: [UserRole.Admin, UserRole.User],
    title: 'Save queries for quick access',
    description:
      'Your most important and critical queries and insights are always at your fingertips with personalized saved searches.',
    actionSection: {
      callback: async () => {
        await appRouter.stateService.go(exploreRouteNames.explore);
        await tourService.showTour({ tourType: TourType.SaveSearchInExplore });
      },
      text: 'Save a query',
    },
  },
  [GuidedWalkthroughTaskId.GroupByInExplore]: {
    id: GuidedWalkthroughTaskId.GroupByInExplore,
    entityLevel: AppStateEntities.User,
    userRoles: [UserRole.Admin, UserRole.User],
    title: 'Group Your Data for Easier Viewing',
    description:
      "Group your data for easier viewing - Click the 'Group by' option in Explore, select a relevant field, and filter data to support better decision-making.",
    actionSection: {
      callback: async () => {
        await appRouter.stateService.go(exploreRouteNames.explore);
        await tourService.showTour({ tourType: TourType.GroupByInExplore });
      },
      text: 'Group by fields',
    },
  },
  [GuidedWalkthroughTaskId.CreateAlert]: {
    id: GuidedWalkthroughTaskId.CreateAlert,
    entityLevel: AppStateEntities.Account,
    userRoles: [UserRole.Admin, UserRole.User],
    title: 'Configure your first alert',
    description:
      'Setting up alerts will inform you of critical issues and sensitive information in real time. You can configure an alert directly from your query search.',
    hint: 'Complete this step by creating an alert from the Log Analytics dashboard or navigating to Logs > Alerts > New Alert.',
    actionSection: {
      callback: async () => {
        await appRouter.stateService.go(exploreRouteNames.explore);
      },
      text: 'Create an alert',
      learnMoreUrl: 'https://docs.logz.io/user-guide/alerts/configure-an-alert.html',
    },
    imageUrl: null, // DEV-39235: Enhance when building the component
  },
  [GuidedWalkthroughTaskId.ManageData]: {
    id: GuidedWalkthroughTaskId.ManageData,
    entityLevel: AppStateEntities.User,
    userRoles: [UserRole.Admin, UserRole.User],
    title: 'Manage your data',
    description:
      'Take control of your data. From data retention policies to indexing and archiving options, you can tailor the data lifecycle to meet your specific needs.',
    hint: 'Complete this step by opening the Data Hub and viewing your current data usage.',
    actionSection: {
      callback: async () => {
        await appRouter.stateService.go(costManagementRouteName);
        guidedWalkthroughStateService.markTaskDone(GuidedWalkthroughTaskId.ManageData);
      },
      text: 'Meet Data Hub',
      learnMoreUrl: 'https://docs.logz.io/user-guide/accounts/cost-optimization.html',
    },
    imageUrl: null, // DEV-39235: Enhance when building the component
  },
  [GuidedWalkthroughTaskId.SetLandingPage]: {
    id: GuidedWalkthroughTaskId.SetLandingPage,
    entityLevel: AppStateEntities.User,
    userRoles: [UserRole.Admin, UserRole.User],
    title: 'Customize your landing page',
    description:
      "Customize your Logz.io experience by choosing a default page upon accessing the platform, whether it's the Home Dashboard, saved search, custom dashboard, or any other page.",
    hint: 'Complete this step by customizing Logz.io’s landing page.',
    actionSection: {
      callback: async () => {
        appRouter.stateService.go('dashboard.settings.general');
      },
      text: 'Set landing page',
      learnMoreUrl: 'https://docs.logz.io/user-guide/accounts/homepage.html',
    },
    imageUrl: null, // DEV-39235: Enhance when building the component
  },
};

export const Phases: GuidedWalkthroughPhase[] = [
  {
    name: 'SETUP',
    tasks: [GuidedWalkthroughTaskId.SendData, GuidedWalkthroughTaskId.InviteTeamMember],
    isOpenByDefault: true,
  },
  {
    name: 'FIRST STEPS',
    tasks: [
      GuidedWalkthroughTaskId.SearchInExplore,
      GuidedWalkthroughTaskId.SaveSearchInExplore,
      GuidedWalkthroughTaskId.GroupByInExplore,
      GuidedWalkthroughTaskId.CreateAlert,
    ],
    isOpenByDefault: false,
  },
  {
    name: 'MASTERING LOGZ.IO',
    tasks: [GuidedWalkthroughTaskId.ManageData, GuidedWalkthroughTaskId.SetLandingPage],
    isOpenByDefault: false,
  },
];
