import { Card, Group, Text } from '@logz-ui/styleguide';
import React from 'react';
import { LogzGraphTestSubjects } from '@logz-pkg/enums';
import { TooltipComponent } from '../types';
import { formatNumbers } from '../utils/utils';
import { TooltipContainer } from './TooltipContainer';
import { TooltipLabel } from './TooltipLabel';

export const DefaultTooltipComponent: React.FC<TooltipComponent> = ({
  x,
  points,
  format,
  nowHovered,
  maxRenderItems,
  vertical = false,
  unit,
  sortFn,
  timezone,
}) => {
  const sortedPoints = points.sort(sortFn || ((a, b) => b.y - a.y)).slice(0, maxRenderItems);

  return (
    <TooltipContainer
      vertical={vertical}
      date={x}
      format={format}
      timezone={timezone}
      subject={LogzGraphTestSubjects.TooltipComponent}
    >
      {sortedPoints.map((point, i) => {
        return (
          <Card
            highlight={points.length > 1 && point.name === nowHovered}
            justifyContent={'space-between'}
            alignItems={'baseline'}
            key={i}
            borderRadius={5}
            borderless
            width={'100%'}
            p={'3px 8px'}
          >
            <Group vertical={vertical} justifyContent={'space-between'} alignItems={'baseline'} width={'100%'}>
              <TooltipLabel vertical={vertical} point={point} />
              <Text>
                {formatNumbers(point.y)}
                {unit ? unit : ''}
              </Text>
            </Group>
          </Card>
        );
      })}
    </TooltipContainer>
  );
};
