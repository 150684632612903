import React, { ReactNode, FunctionComponent } from 'react';
import { lightV2Theme, LogzioThemeProvider } from '@logz-ui/styleguide';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import moment from 'moment';
import { ErrorBoundary } from '../shared/ErrorBoundary/ErrorBoundary';
import { GlitchPage } from './Fallback/Page';
import ILogzioStyleguideTheme from 'components/theme';

interface IAppProvider {
  name?: string;
  theme?: ILogzioStyleguideTheme['logzTheme'];
  fallback?: ReactNode;
  children: React.ReactNode;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      gcTime: moment.duration(1, 'minute').asMilliseconds(),
    },
  },
});

export const AppProvider: FunctionComponent<IAppProvider> = ({
  name = 'AppProvider',
  children,
  theme = lightV2Theme,
  fallback = <GlitchPage />,
}) => (
  <LogzioThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary errorContext={name} fallback={fallback}>
        {children}
      </ErrorBoundary>
    </QueryClientProvider>
  </LogzioThemeProvider>
);
