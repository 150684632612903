import { AppStateDeclaration } from '../AppStateDecleration';
import {
  securityDynamicLookupEditRouteName,
  securityDynamicLookupCreateRouteName,
  securityLookupsListEditRouteName,
  securityLookupsListRouteName,
} from './security.routes.names';
import { LazyReact } from 'ui/components/shared/LazyReact';

const dynamicLookupEditComponent = props => (
  <LazyReact
    {...props}
    lazyImport={() => import('ui/components/Lookups/LookupEdit/DynamicLookupEditPage')}
    resolver={({ DynamicLookupEditPage }) => DynamicLookupEditPage}
  />
);
export const lookupsRoutes: AppStateDeclaration[] = [
  {
    name: securityLookupsListRouteName,
    url: '/lookups',
    component: props => (
      <LazyReact
        {...props}
        lazyImport={() => import('ui/components/Lookups/Lookups')}
        resolver={({ Lookups }) => Lookups}
      />
    ),
    data: { authenticate: true, title: 'Lookups', breadcrumb: { label: 'Lookups' } },
  },
  {
    name: securityDynamicLookupCreateRouteName,
    url: '/lookups/dynamic/new',
    component: dynamicLookupEditComponent,
    data: {
      authenticate: true,
      title: 'Create a dynamic lookup',
      breadcrumb: { label: 'Create a dynamic lookup', parent: securityLookupsListRouteName },
    },
    params: {
      query: null,
      filters: null,
      accountIds: null,
    },
  },
  {
    name: securityDynamicLookupEditRouteName,
    url: '/lookups/dynamic/:lookupId',
    component: dynamicLookupEditComponent,
    data: {
      authenticate: true,
      title: 'Edit a dynamic lookup',
      breadcrumb: { label: 'Edit a dynamic lookup', parent: securityLookupsListRouteName },
    },
  },
  {
    name: securityLookupsListEditRouteName,
    url: '/lookups/:lookupId',
    component: props => (
      <LazyReact
        {...props}
        lazyImport={() => import('ui/components/Lookups/LookupEdit/LookupEdit')}
        resolver={({ LookupEdit }) => LookupEdit}
      />
    ),

    data: {
      authenticate: true,
      title: 'Edit a lookup',
      breadcrumb: { label: 'Edit a lookup', parent: securityLookupsListRouteName },
    },
  },
];
