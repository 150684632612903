/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Flex, Group, Page, Title } from '@logz-ui/styleguide';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import bubblesDouble from 'static/jaeger/bubblesDouble.png';
import { Video } from 'ui/components/shared/Video/Video';

const VideoContainer = styled(Flex)`
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio https://www.benmarshall.me/responsive-iframes/#too-long-to-read */
  position: relative;
  width: 100%;
`;

interface IEmptyStateMediaSection {
  src: string;
}

const BubbleFlex = styled(Flex)`
  background-image: url(${bubblesDouble});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  max-width: 700px;
`;

const PrimaryEmptyState: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
  <Page subject={'empty-state-page'} fullWidth>
    <Page.Body justifyContent={'center'} fullWidth>
      <Group flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'} fullWidth>
        {children}
      </Group>
    </Page.Body>
  </Page>
);

const EmptyStateTitle = ({ children }: { children: React.ReactNode }) => (
  <Title weight={700} size={24} subject={'empty-state-title'}>
    {children}
  </Title>
);

const Description = ({ children }: { children: React.ReactNode }) => <Box>{children}</Box>;

const CallToAction = ({ children }: { children: React.ReactNode }) => <Group alignItems="left">{children}</Group>;

const ContentSection = ({ children }: { children: React.ReactNode }) => (
  <Group justifyContent={'center'} vertical gap={7} flex={'2 0 290px'} maxWidth={'490px'}>
    {children}
  </Group>
);

const MediaSection = ({ src }) => (
  <Group gap={0} justifyContent={'center'} alignItems={'center'} flex={'1 0 340px'} maxWidth={'490px'}>
    <BubbleFlex justifyContent={'center'} alignItems={'center'} padding={'7%'}>
      <VideoContainer justifyContent={'center'} alignItems={'center'}>
        <Video src={src} />
      </VideoContainer>
    </BubbleFlex>
  </Group>
);

export type EmptyStateComponents = 'Title' | 'Description' | 'CallToAction' | 'ContentSection' | 'MediaSection';

type EmptyStateProps = typeof PrimaryEmptyState & {
  Title: FunctionComponent<{ children: React.ReactNode }>;
  Description: FunctionComponent<{ children: React.ReactNode }>;
  CallToAction: FunctionComponent<{ children: React.ReactNode }>;
  ContentSection: FunctionComponent<{ children: React.ReactNode }>;
  MediaSection: FunctionComponent<IEmptyStateMediaSection>;
} & Partial<Record<EmptyStateComponents, FunctionComponent>>;

Object.defineProperty(PrimaryEmptyState, 'Title', { value: EmptyStateTitle });
Object.defineProperty(PrimaryEmptyState, 'Description', { value: Description });
Object.defineProperty(PrimaryEmptyState, 'CallToAction', { value: CallToAction });
Object.defineProperty(PrimaryEmptyState, 'ContentSection', { value: ContentSection });
Object.defineProperty(PrimaryEmptyState, 'MediaSection', { value: MediaSection });

export const ProductEmptyState = PrimaryEmptyState as EmptyStateProps;
