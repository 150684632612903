import { parse, format } from 'url';
import { get } from 'lodash';
import { StateObject } from '@uirouter/react';
import { httpService, authTokenService, authTokens, clearAllCache } from '@logz-pkg/frontend-services';
import { HttpStatusCode } from '@logz-pkg/enums';
import { appRouter } from '../services/router/router.service';
import { impersonationService } from 'services/identity/impersonation/impersonation.service';
import { sessionExpiration } from 'services/errors/logz-session-expiration';

let redirecting = false;

type HandleRegionRedirectExceptionParams = {
  locationRedirectHeader: string;
  token: string;
  accountId: number;
};

export const handleRegionRedirectException = async ({
  locationRedirectHeader,
  token,
  accountId,
}: HandleRegionRedirectExceptionParams): Promise<StateObject | void> => {
  if (!locationRedirectHeader || !token || redirecting) return;

  redirecting = true;

  const impersonating = impersonationService.isImpersonating();
  let redirectUrl = locationRedirectHeader;

  if (impersonating) {
    authTokenService.setCurrentAccountIdCookie(authTokenService.getCurrentAccountId());
    authTokenService.setAdminAccountIdCookie(authTokenService.getAdminAccountId());
    redirectUrl = impersonationService.getRedirectUrl(locationRedirectHeader);
  }

  await appRouter.stateService
    .go('.', {}, { location: 'replace', reload: false, inherit: !impersonating })
    .finally(() => {
      const parsedRedirectUrl = parse(redirectUrl);
      const parsedCurrentLocation = parse(window.location.href);
      let locationRedirect;

      // first navigation after opening new tab
      // name is undefined when the route is not yet initialized
      if (appRouter.stateService.current.name === '') {
        locationRedirect = format({
          ...{ protocol: 'http' },
          ...parsedRedirectUrl,
          hash: parsedCurrentLocation.hash,
        });
      } else {
        locationRedirect = format({
          ...{ protocol: 'http' },
          ...parsedRedirectUrl,
          hash:
            // If you have awsMarketplaceToken in your state params you came from aws market place,
            // you don't have a redirect state param as well and no need to handle that case
            appRouter.globals.params.awsMarketplaceToken || !appRouter.globals.params.redirect
              ? appRouter.stateService.href('.', {}, { inherit: false })
              : decodeURIComponent(appRouter.globals.params.redirect),
        });
      }

      // we want to set the current account id cookie before we redirect to the new region
      // and remove all cache from the session storage
      // otherwise,  we will be redirected back to the account we came from
      if (accountId) {
        authTokenService.setCurrentAccountIdCookie(impersonating ? `i-${accountId}` : `${accountId}`);
        clearAllCache({ clearTokens: false });
      }

      window.location.href = locationRedirect;
    });
};

export const getRegionRedirectParams = err => {
  const { location, token, accountid: accountId } = get(err, 'headers', {});

  if (!location || !token) return {};

  return { location, token, accountId };
};

export const isRegionRedirectError = err => {
  const regionRedirectParams = getRegionRedirectParams(err);

  return regionRedirectParams.location && regionRedirectParams.token;
};

// We pass those because registerRequestHook is being used inside admin panel and app
// app is using code ui router and the admin panel is using angular ui router
export const registerRequestHook = () => {
  httpService.getInterceptors().response.use(undefined, async error => {
    if (!error.response) throw error;

    const { status: errorStatus } = error.response;

    if (errorStatus === HttpStatusCode.UNAUTHORIZED) {
      if (
        error.config &&
        error.config.url !== '/auth' &&
        !error.config.headers[authTokens.SHARE_TOKEN_HEADER] &&
        !error.config.headers[authTokens.USER_TOKEN_HEADER]
      ) {
        sessionExpiration.expire({ error });
        throw error.response;
      }
    } else if (errorStatus === HttpStatusCode.BAD_REQUEST) {
      const { location: locationRedirectHeader, token, accountId } = getRegionRedirectParams(error.response);

      await handleRegionRedirectException({ locationRedirectHeader, token, accountId });
      throw error.response;
    }

    // If we have the error response (might contain message, status etc.)
    // we will propagate it through the error chain
    throw error.response;
  });
};
