import { FunctionComponent } from 'react';
import { Icon, Badge } from '@logz-ui/styleguide';
import { GuidedWalkthroughTaskStatus } from '@logz-pkg/models';
import { GuidedWalkthroughSubject } from '@logz-pkg/enums';

const BadgeByStatus: Record<GuidedWalkthroughTaskStatus, FunctionComponent> = {
  [GuidedWalkthroughTaskStatus.Todo]: () => (
    <Badge subject={GuidedWalkthroughSubject.TodoTaskBadge} variant={'info'} outline dashed size={'xs'} />
  ),
  [GuidedWalkthroughTaskStatus.Done]: () => (
    <Badge
      subject={GuidedWalkthroughSubject.DoneTaskBadge}
      size={'xs'}
      value={<Icon icon={'check-solid'} color={'white'} size={12} />}
      color={'green.600'}
      p={0}
    />
  ),
  [GuidedWalkthroughTaskStatus.Locked]: () => (
    <Icon subject={GuidedWalkthroughSubject.LockedTaskBadge} icon={'lock-regular'} color={'red.700'} />
  ),
};

interface IStatusBadgeProps {
  status: GuidedWalkthroughTaskStatus;
}

export const StatusBadge: FunctionComponent<IStatusBadgeProps> = ({ status }) => {
  const Status: FunctionComponent = BadgeByStatus[status];

  return <Status />;
};
