import { Observable } from '@logz-pkg/observable';

class ExportLogsStateService {
  isOpen = new Observable(false);
  isLoading = new Observable(false);
  exportStatus = new Observable('');
  progressPercent = new Observable(0);
  currentRequest = new Observable(0);
  logsAmount = new Observable(500);
  percentPerRequest = new Observable(100);

  setIsOpen = (value?: boolean) => {
    this.isOpen.set(value);
  };
  setIsLoading = (value?: boolean) => {
    this.isLoading.set(value);
  };
  setExportStatus = (value?: string) => {
    this.exportStatus.set(value);
  };
  setProgressPercent = (value?: number) => {
    this.progressPercent.set(value);
  };
  setCurrentRequest = (value?: number) => {
    this.currentRequest.set(value);
  };
  setLogsAmount = (value?: number) => {
    this.logsAmount.set(value);
  };
  setPercentPerRequest = (value?: number) => {
    this.percentPerRequest.set(value);
  };

  clear = () => {
    this.isOpen.set(false);
    this.isLoading.set(false);
    this.exportStatus.set('');
    this.progressPercent.set(0);
    this.currentRequest.set(0);
    this.logsAmount.set(500);
    this.percentPerRequest.set(100);
  };
}

export const exportLogsStateService = new ExportLogsStateService();
