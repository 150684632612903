import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';

export const systemStatus: AppStateDeclaration[] = [
  {
    name: 'dashboard.tools.system-status',
    url: '/system-status',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/SystemStatus/SystemStatus')}
        resolver={({ SystemStatus }) => SystemStatus}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      title: 'System status',
      breadcrumb: { label: 'System status' },
    },
  },
];
