import { TooltipPoint } from '../types';
import { Flex, Icon, Text } from '../../../../../components';

export const TooltipLabel = ({ vertical, point }: { vertical: boolean; point: TooltipPoint }) => {
  return (
    <Flex flexDirection={vertical ? 'column' : 'initial'} alignItems={vertical ? 'left' : 'center'}>
      <Icon subject={'tooltip-icon'} icon={'stop-solid'} color={point.color.toString()} />
      <Text ellipsis>{point.label ?? point.name}</Text>
    </Flex>
  );
};
