import React, { useMemo } from 'react';
import { Badge, Button, Card, colors, Flex, Group, Icon, Text } from '@logz-ui/styleguide';
import moment from 'moment';
import styled from 'styled-components';
import { AICopilotChatMessageSubjects, AICopilotInvocationMessageSubjects } from '@logz-pkg/enums';
import { InvocationAgentChatRecord } from '@logz-pkg/models';
import { agentInvocationMessageUtils } from 'ui/components/AI/Components/Messages/Agent/utils';
import { useCurrentProduct } from 'ui/state/hooks/current-product.hook';
import { AiChatAgentInvocationGraph } from 'ui/components/AI/Components/Messages/Agent/InvocationGraph';
import { useAiAnalytics } from 'ui/components/AI/hooks/analytics.hook';
import { agentInvocationMessageMapUtils } from 'ui/components/AI/Components/Messages/Agent/invocationMap.utils';

const StyledButton = styled(Button)`
  background: ${colors.gray[0]};
`;

export const AiChatAgentInvocation: React.FC<InvocationAgentChatRecord> = ({ parameters, functionName }) => {
  const { report } = useAiAnalytics();

  const { currentProduct } = useCurrentProduct();

  const { interval, field, query, goToPreviewFunction, badges } = useMemo(
    () =>
      agentInvocationMessageUtils.getInvocationInformation({
        functionName,
        parameters,
        currentProduct,
      }),
    [functionName, parameters, currentProduct],
  );

  if (!interval) return null;

  const cardTitle = agentInvocationMessageMapUtils
    .getInvocationTitle(functionName)
    .replace('__fieldPlaceHolder__', field);

  if (!cardTitle) return null;

  const time = `${moment(interval.start).format('MMM DD, YYYY HH:mm:ss')} - ${moment(interval.end).format(
    'MMM DD, YYYY HH:mm:ss',
  )}`;

  const shouldDisplayGraph = !['get_logs_samples', 'get-workload-list', 'get-workload-metrics'].includes(functionName);

  const clickHandler = async () => {
    report('Clicked open preview button');

    goToPreviewFunction();
  };

  return (
    <Card borderRadius={'8px'} mb={4} minWidth={'100%'} subject={AICopilotChatMessageSubjects.InvocationMessage}>
      <Group vertical fullWidth gap={1} p={4}>
        <Group fullWidth justifyContent={'space-between'} alignItems={'center'} gap={0}>
          <Text
            color={'royalBlue.1000'}
            weight={600}
            size={14}
            subject={AICopilotInvocationMessageSubjects.InvocationTitle}
          >
            {cardTitle}
          </Text>
          {goToPreviewFunction ? (
            <StyledButton
              size={'s'}
              variant={'secondary'}
              onClick={clickHandler}
              endIcon={'arrow-up-right-from-square-regular'}
            >
              Open preview
            </StyledButton>
          ) : null}
        </Group>
        <Group vertical fullWidth rowGap={2}>
          <Flex>
            <Group alignItems={'center'} pr={3} justifyContent={'center'}>
              <Icon
                size={14}
                icon={'calendar-regular'}
                color={'royalBlue.700'}
                tooltip={{ title: 'Time range', placement: 'top' }}
              />
              <Flex mt={'2px'}>
                <Text
                  color={'royalBlue.700'}
                  lineHeight={'dense'}
                  weight={400}
                  size={12}
                  subject={AICopilotInvocationMessageSubjects.TimeRangeString}
                >
                  {time}
                </Text>
              </Flex>
            </Group>
            {field && (
              <Group alignItems={'center'}>
                <Icon
                  size={14}
                  icon={'chart-simple-regular'}
                  color={'royalBlue.700'}
                  tooltip={{ title: 'Group by', placement: 'top' }}
                />
                <Flex mt={'2px'}>
                  <Text
                    color={'royalBlue.700'}
                    subject={AICopilotInvocationMessageSubjects.FieldString}
                    lineHeight={'dense'}
                    weight={400}
                    size={12}
                  >
                    {field}
                  </Text>
                </Flex>
              </Group>
            )}
          </Flex>

          {badges.length > 0 && (
            <Group gap={1} flexWrap={'wrap'}>
              {badges.map(badge => {
                return (
                  <Badge
                    subject={AICopilotInvocationMessageSubjects.Badge}
                    key={badge}
                    square
                    value={
                      <Text size={12} weight={400} color={'gray.800'}>
                        {badge}
                      </Text>
                    }
                    color={'gray.200'}
                  />
                );
              })}
            </Group>
          )}

          {query && (
            <Group alignItems={'center'}>
              <Icon
                size={14}
                icon={'magnifying-glass-regular'}
                color={'royalBlue.700'}
                tooltip={{ title: 'Query', placement: 'top' }}
              />
              <Text
                color={'royalBlue.700'}
                subject={AICopilotInvocationMessageSubjects.QueryString}
                lineHeight={'dense'}
                weight={400}
                size={12}
                ellipsis
              >
                {query}
              </Text>
            </Group>
          )}
          {shouldDisplayGraph && (
            <AiChatAgentInvocationGraph
              shouldShowCustomLegend={functionName.includes('group')}
              functionName={functionName}
              interval={interval}
              field={field}
              query={query}
            />
          )}
        </Group>
      </Group>
    </Card>
  );
};
