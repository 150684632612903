import { useHotkeys } from 'react-hotkeys-hook';
import { useObservable } from '@logz-pkg/observable';
import { useEffect } from 'react';
import { hotkeysNavigationService } from 'ui/components/AI/state/hotkey-navigation/hotkeys-navigation.service';
import { helpQuestionPreFixId } from 'ui/components/AI/Components/EmptyState/QuestionSection';
import { HelpQuestionsCardInformationType, HelpQuestionsType } from 'ui/components/AI/types';

export const useAiHotkeysNavigation = (
  allQuestions: HelpQuestionsType,
  onClickQuestionCard: (value: string) => void,
) => {
  const highlight = useObservable(hotkeysNavigationService.highlightedIndex);
  const arrayOfQuestions: HelpQuestionsCardInformationType[] = Object.values(allQuestions).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );

  useEffect(() => {
    const itemElement = document.getElementById(`${helpQuestionPreFixId}-${highlight}`);

    if (itemElement) {
      itemElement.scrollIntoView({ behavior: 'instant', block: 'nearest' });
    }
  }, [highlight]);

  useHotkeys(
    ['down'],
    (event: KeyboardEvent) => {
      event.preventDefault();

      const newIndex = Math.min(highlight + 1, arrayOfQuestions.length - 1);

      hotkeysNavigationService.highlightedIndex.set(newIndex);
    },
    { enableOnFormTags: false },
  );

  useHotkeys(
    ['up'],
    (event: KeyboardEvent) => {
      event.preventDefault();

      const newIndex = Math.max(0, highlight - 1);

      hotkeysNavigationService.highlightedIndex.set(newIndex);
    },
    { enableOnFormTags: false },
  );

  useHotkeys(
    'enter',
    (event: KeyboardEvent) => {
      event.preventDefault();

      const questionInfo = arrayOfQuestions.find(questionInformation => questionInformation.index === highlight);

      if (questionInfo?.text) onClickQuestionCard(questionInfo?.text);

      hotkeysNavigationService.highlightedIndex.set(0);
    },
    { enableOnFormTags: false },
  );

  useHotkeys(
    ['esc'],

    (event: KeyboardEvent) => {
      event.preventDefault();
      hotkeysNavigationService.highlightedIndex.set(0);
    },
    { enableOnFormTags: true },
    [],
  );
};
