import { Flex, Group, Text } from '@logz-ui/styleguide';
import React from 'react';
import styled, { css } from 'styled-components';
import { MarkdownContent } from 'ui/components/Integrations/Single/Body/Content';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';

const Li = styled.li`
  font-size: 14px;
  ::marker {
    color: ${({ theme }) => theme.colors.royalBlue[1000]};
  }
`;

const paragraphRenderer = props => (
  <p style={{ margin: 0 }}>
    <Text {...props} color={'royalBlue.1000'} />
  </p>
);
const listItemRenderer = props => (
  <Li {...props} color={'royalBlue.1000'}>
    <Text color={'royalBlue.1000'}>{props.children}</Text>
  </Li>
);

export const renderers = {
  paragraph: paragraphRenderer,
  listItem: listItemRenderer,
};

export const MarkdownWrapper = styled(Flex)<{ outOfContext: boolean }>`
  display: inline-block;
  word-wrap: break-word;
  ${({ outOfContext, theme }) =>
    outOfContext &&
    css`
      * {
        opacity: 0.8;
      }
    `}
`;

export const AiChatGeneralMarkdown: React.FC<Omit<MessageType, 'type'>> = ({ content, outOfContext = false }) => {
  return (
    <Group width={'100%'}>
      <MarkdownWrapper outOfContext={outOfContext}>
        <Group pb={content ? 4 : 0}>
          <MarkdownContent markdown={content ?? ''} elementRenderers={renderers} shouldShowLoader={false} />
        </Group>
      </MarkdownWrapper>
    </Group>
  );
};
