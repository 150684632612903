import {
  AccountType,
  PLAN_TYPE,
  TracingAccountCardState,
  TracingProductActivationState,
  TracingServiceErrorCode,
} from '@logz-pkg/enums';
import { NotificationService } from '@logz-ui/styleguide';
import { TracingBudgetModel } from '@logz-pkg/models';
import {
  tracingAccountsApiService,
  tracingBudgetApiService,
  LoggerService,
  searchableIndexesService,
  sessionApiService,
} from '@logz-pkg/frontend-services';
import { tourService } from 'services/tours/tours';
import { tracingStateService } from 'ui/state/tracing.state.service';
import { IJaegerAppState, jaegerAppStateService } from 'services/tracing/state.service';
import { ITracingTourParams } from 'services/tours/types';
import { ErrorHandlingService } from 'services/error-handling.service';

export type SearchableAccount = {
  accountId: number;
  esIndexPrefix: string;
  name: string;
  requestingAccount: boolean;
  type: AccountType;
};

const getTracingBudget = async () => {
  let tracingBudget: TracingBudgetModel | null = null;

  const { activated } = await tracingAccountsApiService.isActivated();

  if (!activated) return null;

  try {
    tracingBudget = await tracingBudgetApiService.get();
  } catch (error) {
    if (error.errorCode !== TracingServiceErrorCode.TracingNotActivated) throw error;
  }

  return tracingBudget;
};

const activateTrial = async () => {
  try {
    tracingStateService.setTracingProductActivationState(TracingProductActivationState.PendingTrial);
    await tracingAccountsApiService.activateTrial();

    const tracingBudget = await tracingBudgetApiService.get();

    if (!tracingBudget) {
      throw new Error('No tracing budget found after account activation.');
    }

    tracingStateService.setTracingProductActivationState(TracingProductActivationState.ActivatedNotInUse);
    tracingStateService.setTracingBudgetState(tracingBudget);

    await tourService.updateTracingParams(getTrialDaysLeft(tracingBudget), tracingBudget.type, true);
  } catch (error) {
    tracingStateService.setTracingProductActivationState(TracingProductActivationState.NotActivated);

    throw error;
  }
};

const getTracingAccountState = (tracingBudget: TracingBudgetModel) => {
  if (tracingBudget === null) {
    return TracingAccountCardState.NotActivated;
  }

  if (tracingBudget.type === PLAN_TYPE.TRIAL) {
    return TracingAccountCardState.Trial;
  }

  if (tracingBudget.type === PLAN_TYPE.FREE) {
    return TracingAccountCardState.Free;
  }

  if (tracingBudget.type === PLAN_TYPE.PRO) {
    return TracingAccountCardState.Pro;
  }

  const accountStateErrorMessage = 'Cannot determine Tracing account state';

  LoggerService.logError({
    message: accountStateErrorMessage,
    error: new Error(accountStateErrorMessage),
    extra: { tracingBudget },
  });

  return null;
};

const getTrialDaysLeft = (tracingBudget: TracingBudgetModel) => {
  if (tracingBudget === null) {
    return 0;
  }

  const { expiresAt } = tracingBudget;

  const millisecondsInSecond = 1000;
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const expiredAtDateObj = new Date(expiresAt);

  const endOfLastTrialDay =
    new Date(expiredAtDateObj.getFullYear(), expiredAtDateObj.getMonth(), expiredAtDateObj.getDate()).getTime() -
    millisecondsInSecond;

  const millisecondsLeftToLastTrialDay = Date.now() - endOfLastTrialDay;

  return Math.ceil(Math.abs(millisecondsLeftToLastTrialDay) / millisecondsInDay);
};

const getTourParams = async (): Promise<ITracingTourParams | undefined> => {
  const {
    account: { type: accountType },
  } = await sessionApiService.get();
  const isOwnerAccount = accountType === AccountType.Owner;

  if (!isOwnerAccount) return;

  try {
    const [tracingBudget, isInUse] = await Promise.all([getTracingBudget(), isAnyAccountInUse()]);

    if (!tracingBudget) return;

    return {
      daysLeftForTracingTrial: getTrialDaysLeft(tracingBudget),
      tracingPlanType: tracingBudget.type,
      isTracingActive: isInUse,
    };
  } catch (err) {
    ErrorHandlingService.logError('Failed to get tracing tour params', err);
  }
};

const getSearchableTracingAccounts = async (): Promise<SearchableAccount[]> => {
  const searchableAccounts = await searchableIndexesService.getCachedSearchableIndexes();

  return searchableAccounts.filter(account => account.type === AccountType.Tracing);
};

const isAnyAccountInUse = async () => {
  const accountsInUse = await tracingAccountsApiService.getAccountsInUse();

  return accountsInUse && Object.values(accountsInUse).some(Boolean);
};

const getTracingProductActivationState = async () => {
  const [{ activated }, isAnyInUse] = await Promise.all([tracingAccountsApiService.isActivated(), isAnyAccountInUse()]);

  if (!activated) {
    return TracingProductActivationState.NotActivated;
  }

  if (!isAnyInUse) {
    return TracingProductActivationState.ActivatedNotInUse;
  }

  return TracingProductActivationState.ActivatedInUse;
};

const getFreshSearchableTracingAccounts = async () => {
  const searchableTracingAccounts = await getSearchableTracingAccounts();

  if (searchableTracingAccounts.length) {
    return searchableTracingAccounts;
  }

  searchableIndexesService.clearCachedSearchableIndexes();

  return getSearchableTracingAccounts();
};

const getSelectedTracingAccount = (jaegerAppState: IJaegerAppState | undefined, accounts) => {
  if (jaegerAppState === undefined) {
    return accounts[0];
  }

  return accounts.find(({ accountId }) => accountId === jaegerAppState.selectedTracingAccountId) ?? accounts[0];
};

const setTracingState = async (jaegerAppState: IJaegerAppState, searchableTracingAccounts: SearchableAccount[]) => {
  const selectedTracingAccount = getSelectedTracingAccount(jaegerAppState, searchableTracingAccounts);

  jaegerAppStateService.setLastSelectedAccountId(selectedTracingAccount.accountId);
  tracingStateService.setSearchableTracingAccounts(searchableTracingAccounts);
  tracingStateService.setSelectedAccountIdState(selectedTracingAccount.accountId);
};

const updateTracingStatus = async (): Promise<void> => {
  try {
    const [accounts, jaegerAppState] = await Promise.all([
      getFreshSearchableTracingAccounts(),
      jaegerAppStateService.getState(),
    ]);

    if (accounts.length !== 0) {
      setTracingState(jaegerAppState, accounts);
    }

    const activationState = await getTracingProductActivationState();

    tracingStateService.setTracingProductActivationState(activationState);
  } catch (error) {
    tracingStateService.setTracingProductActivationState(TracingProductActivationState.ActivatedNotInUse);

    return NotificationService.unexpectedError('We were unable to access your Tracing account');
  }
};

export const tracingService = {
  getTracingBudget,
  activateTrial,
  getTracingAccountState,
  TracingAccountCardState,
  getTrialDaysLeft,
  getTourParams,
  isAnyAccountInUse,
  getFreshSearchableTracingAccounts,
  updateTracingStatus,
};
