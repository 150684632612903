import { NotificationService } from '@logz-ui/styleguide';

import {
  eventEmitter,
  EVENTS,
  accountService,
  authService,
  LoggerService,
  cacheProvider,
  httpService,
} from '@logz-pkg/frontend-services';
import { kibanaVersion } from './versions/osd-versions.strategy';
import { appRouter } from 'services/router/router.service';

const clearAndLoadKibanaConfig = async () => {
  kibanaVersion.clearKibanaConfig();

  return kibanaVersion.getConfig();
};

export const bootstrapKibanaConfig = async () => {
  if (!authService.isIdentified()) return;

  if (await accountService.isAccountStateReady({ tryFresh: false })) {
    await kibanaVersion.getConfig();
  }
};

let numberOfReloads = 3;

export const clearCacheAndForceUpdate = async () => {
  httpService.clearCache(cacheProvider.CacheKeys.SESSION_CONTEXT);
  await clearAndLoadKibanaConfig();
  await kibanaVersion.init({ forceUpdate: true });
  eventEmitter.emit(EVENTS.RELOAD_PRODUCTS);
  await appRouter.stateService.go(
    '.',
    {},
    {
      location: 'replace',
      reload: true,
      inherit: false,
    },
  );
};

export const updateAppKibanaVersion = async error => {
  if (numberOfReloads <= 0) {
    LoggerService.logError({
      message: 'kibana refreshed 3 times unable to load',
      error,
    });
    NotificationService.danger({ message: 'unable to load kibana please reload page' });
  } else {
    numberOfReloads -= 1;
    await clearCacheAndForceUpdate();
  }
};
