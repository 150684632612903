import { cloneDeep } from 'lodash';
import { lookupListsApiService } from '@logz-pkg/frontend-services';
import { ErrorHandlingService } from 'services/error-handling.service';

function extractLookupIds(filters) {
  return Object.values(filters.bool).reduce((ids: any, type: any) => {
    const lookupIds = type.filter(item => item.lookup).map(({ lookup }) => lookup.id);

    return [...ids, ...lookupIds];
  }, []) as string[];
}
function enrichLookupFilters(filters, lookups) {
  const filtersCopy = cloneDeep(filters);

  Object.values(filtersCopy.bool).forEach((type: any) => {
    type.forEach(filter => {
      if (filter.lookup) {
        const lookupDetails = lookups.find(({ id }) => id === filter.lookup.id);

        if (lookupDetails) Object.assign(filter.lookup, lookupDetails);
      }
    });
  }, []);

  return filtersCopy;
}

function matchQueryTemplate(field, value) {
  return {
    match_phrase: {
      [field]: {
        query: value,
      },
    },
  };
}

function matchExistsTemplate(field) {
  return {
    exists: {
      field,
    },
  };
}

function matchOneOfTemplate(should, minimumToMatch = 1) {
  return {
    bool: {
      should,
      minimum_should_match: minimumToMatch,
    },
  };
}

function buildShouldValues(field, value) {
  return {
    match_phrase: {
      [field]: value,
    },
  };
}

export class ElasticsearchFilterManager {
  filters: { bool: { must: any[]; must_not: any[]; filter: any[]; should: any[] } };
  constructor(filters = null) {
    this.filters = {
      bool: {
        must: [],
        must_not: [],
        filter: [],
        should: [],
      },
    };

    if (filters) this.copyFilters(filters);
  }

  copyFilters(tmpFilters) {
    let filters;

    if (typeof tmpFilters === 'string') {
      try {
        filters = JSON.parse(tmpFilters);
      } catch (err) {
        throw Error('string is not object type');
      }
    } else {
      filters = tmpFilters;
    }

    const {
      bool: { must = null, must_not = null, filter = null, should = null },
    } = filters;

    const { bool } = this.filters;

    // push the filters to the bool on the Filter Object
    if (must) bool.must = [...must];

    if (must_not) bool.must_not = [...must_not];

    if (filter) bool.filter = [...filter];

    if (should) bool.should = [...should];
  }

  async populateLookupFilters() {
    const lookupsIds = extractLookupIds(this.filters);

    if (lookupsIds.length) {
      try {
        const { results: lookupFilters } = await lookupListsApiService.search({
          filter: { byIds: lookupsIds },
        });

        this.filters = enrichLookupFilters(this.filters, lookupFilters);
      } catch (error) {
        ErrorHandlingService.handleApiError({ title: 'Could not get lookup filter values', error });
      }
    }
  }

  addIsFilter({ field, value }) {
    this.filters.bool.must.push(matchQueryTemplate(field, value));
  }

  addIsNotFilter({ field, value }) {
    this.filters.bool.must_not.push(matchQueryTemplate(field, value));
  }

  addIsOneOfFilter({ field, values, minimumToMatch = 1 }) {
    const should = values.map(value => buildShouldValues(field, value));

    this.filters.bool.must.push(matchOneOfTemplate(should, minimumToMatch));
  }

  addIsNotOneOfFilter({ field, values, minimumToMatch = 1 }) {
    const should = values.map(value => buildShouldValues(field, value));

    this.filters.bool.must_not.push(matchOneOfTemplate(should, minimumToMatch));
  }

  addExistsFilter({ field }) {
    this.filters.bool.must.push(matchExistsTemplate(field));
  }

  addDoesNotExistFilter({ field }) {
    this.filters.bool.must_not.push(matchExistsTemplate(field));
  }

  get() {
    return this.filters;
  }
}
