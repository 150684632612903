import { redirectIfNotAdmin } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

const queryParams = ['accountIds', 'metricNames', 'active', 'pageNumber', 'pageSize'];

export const metricsDropFiltersRouteName = 'dashboard.tools.metrics-drop-filters';

const LazyMetricsDropFilters = props => (
  <LazyReact
    lazyImport={() => import('ui/components/MetricsDropFilters/MetricsDropFilters')}
    resolver={({ MetricsDropFilters }) => MetricsDropFilters}
    {...props}
  />
);

export const metricsDropFilters: AppStateDeclaration[] = [
  {
    name: metricsDropFiltersRouteName,
    url: `/metrics-drop-filters?${queryParams.join('&')}`,
    component: LazyMetricsDropFilters,
    dynamic: true,
    data: {
      authenticate: true,
      title: 'Metrics Drop filters',
      breadcrumb: { label: 'Metrics Drop Filters' },
    },
    resolve: [redirectIfNotAdmin],
    params: {
      accountIds: {
        type: 'int',
        array: true,
        value: [],
      },
      metricNames: {
        type: 'string',
        array: true,
        value: [],
      },
      active: {
        type: 'string',
        value: null,
      },
      pageNumber: {
        type: 'int',
        value: null,
      },
      pageSize: {
        type: 'int',
        value: null,
      },
    },
  },
];
