import React from 'react';
import { Icon, Group, Text } from '@logz-ui/styleguide';

export const AiConversationsEmptyState: React.FC = () => {
  return (
    <Group vertical fullWidth justifyContent="center" alignItems="center" mt={10} gap={5}>
      <Icon icon={'clock-regular'} size={40} color={'royalBlue.700'} />
      <Text color={'royalBlue.600'}>No history yet</Text>
    </Group>
  );
};
