import { SelectFilter, SelectSizes, ISelectOption } from '@logz-ui/styleguide';
import { ExploreTimeInterval } from '@logz-pkg/enums';

const options: ISelectOption<ExploreTimeInterval>[] = Object.keys(ExploreTimeInterval).map(key => ({
  label: key,
  value: ExploreTimeInterval[key],
}));

export const TimeIntervalSelect = ({ name }) => {
  return (
    <SelectFilter
      name={name}
      maxLabelWidth={200}
      minLabelWidth={200}
      minButtonWidth={180}
      size={SelectSizes.Small}
      label={'Time interval'}
      showLabel={false}
      showLabelColon={false}
      options={options}
      autoFocus={true}
      clearable={false}
    />
  );
};
