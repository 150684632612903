import { FunctionComponent } from 'react';
import { Button } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { ObservabilityProduct, Product } from '@logz-pkg/enums';
import { TelemetryStatus } from '@logz-pkg/models';
import { appRouter } from 'services/router/router.service';
import { welcomeStateService } from 'ui/state/welcome.state.service';
import { observabilityRoutesNames } from 'states/observability/observability.routes.names';
import { spmRouteNames } from 'states/spm.routes';
import k8sModalPhoto from 'static/welcome/k8s360-sent-modal.png';
import appModalPhoto from 'static/welcome/app360-sent-modal.png';
import logsModalPhoto from 'static/welcome/logs-sent-modal.png';
import { exploreRouteNames } from 'states/explore/explore.route';

const StyledButton = styled(Button)`
  height: 38px;
`;

const ExploreSection: FunctionComponent<{ title: string; onClick: () => void }> = ({ title, onClick }) => (
  <StyledButton width={'100%'} onClick={onClick}>
    {title}
  </StyledButton>
);

export interface IDataSentModalDetails {
  title: string;
  subTitle?: string;
  image: any;
  ExploreSection: FunctionComponent;
}

const clickOnExploreHandler = ({ state }: { product: Product | ObservabilityProduct; state: string }) => {
  welcomeStateService.setIsDataSentModalOpen(false);
  appRouter.stateService.go(state);
};

const clickOnLogsExploreHandler = () =>
  clickOnExploreHandler({ product: Product.LogAnalytics, state: exploreRouteNames.explore });
const clickOnK8s360Handler = () =>
  clickOnExploreHandler({ product: ObservabilityProduct.K8S360, state: observabilityRoutesNames.k8s360 });
const clickOnApp360Handler = () =>
  clickOnExploreHandler({ product: ObservabilityProduct.APP360, state: spmRouteNames.servicesTable });

export const getDataSentModalDetails = (telemetryStatus: TelemetryStatus): IDataSentModalDetails => {
  if (welcomeStateService.isNewTelemetryStatusItem(telemetryStatus.spmMetrics)) {
    return {
      title: 'Your App360 is now connected!',
      image: appModalPhoto,
      ExploreSection: () => <ExploreSection title={'View in App 360'} onClick={clickOnApp360Handler} />,
    };
  }

  if (welcomeStateService.isNewTelemetryStatusItem(telemetryStatus.k8sMetrics)) {
    return {
      title: 'Your K8s360 is now connected!',
      image: k8sModalPhoto,
      ExploreSection: () => <ExploreSection title={'View in Kubernetes 360'} onClick={clickOnK8s360Handler} />,
    };
  }

  if (welcomeStateService.isNewTelemetryStatusItem(telemetryStatus.logs)) {
    return {
      title: 'Your logs are now connected!',
      image: logsModalPhoto,
      ExploreSection: () => <ExploreSection title={'View in explore'} onClick={clickOnLogsExploreHandler} />,
    };
  }
};
