import { Observable } from '@logz-pkg/observable';
import { cacheProvider } from '@logz-pkg/frontend-services';
import { isEqual } from 'lodash';

type UserHistoryRecord = Record<
  string,
  {
    firstOccur: Date;
    lastOccur: Date;
  }
>;

export class UserHistoryStateService {
  history = new Observable<UserHistoryRecord>({});

  constructor() {
    const historyRecords = cacheProvider.getPersistent<UserHistoryRecord>('user-history') || {};

    this.history.set(historyRecords);
    this.history.subscribe(async historyRecords => {
      return new Promise<void>(resolve => {
        cacheProvider.setPersistent('user-history', historyRecords);
        resolve();
      });
    });
  }

  eventLastOccur = (eventName: string): Date => {
    const event = this.history.get()[eventName];

    if (!event) return new Date(0);

    return new Date(event.lastOccur);
  };

  trackEvent: (eventName: string, at?: Date) => void = (eventName, at = new Date()) => {
    const event = this.history.get()[eventName] || { firstOccur: at, lastOccur: at };

    event.lastOccur = at;

    const prevHistory = this.history.get();

    const newHistory = { ...prevHistory, [eventName]: event };

    if (!isEqual(prevHistory, newHistory)) {
      this.history.set(newHistory);
    }
  };
}
export const userHistoryStateService = new UserHistoryStateService();
