import { FeatureFlags } from '@logz-pkg/enums';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { appRouter } from 'services/router/router.service';

type RedirectIfFFParams = {
  featureName: keyof typeof FeatureFlags;
  redirectIf?: boolean;
  toState?: string | null;
};

const redirectIfFeature = ({ featureName, redirectIf, toState }: RedirectIfFFParams) => {
  const enabled = featureFlagStateService.isFeatureEnabled(featureName);

  if (redirectIf !== enabled) {
    return;
  }

  if (toState) {
    appRouter.stateService.go(toState);

    return;
  }

  return appModeService.goToModeRootState();
};
export const redirectIfFeatureEnabled = ({ featureName, toState }: RedirectIfFFParams) =>
  redirectIfFeature({ featureName, redirectIf: true, toState });

export const redirectIfFeatureDisabled = ({ featureName, toState = null }: RedirectIfFFParams) =>
  redirectIfFeature({ featureName, redirectIf: false, toState });
