import {
  sessionApiService,
  LoggerService,
  userService,
  configProvider,
  Env,
  sessionStateService,
} from '@logz-pkg/frontend-services';

const getFullstoryService = async () => import('@fullstory/browser');

const isFullstoryAllowed = async () => {
  const isTrialUser = await sessionStateService.isTrial();

  const isAccountDisabled = await getIsAccountDisabled();

  const isInternalUser = await userService.isLogzUser();

  return !isInternalUser && !isAccountDisabled && isTrialUser && Env.configs.fullstory;
};

const startSession = async () => {
  try {
    const isFullstroryAllowed = await isFullstoryAllowed();

    if (!isFullstroryAllowed) return;

    const orgId = await configProvider.getValue('fullstory.orgId');

    const { init, setUserVars } = await getFullstoryService();

    init({ orgId });

    const userSettings = await getUserSettings();

    setUserVars(userSettings);
  } catch (error) {
    await LoggerService.logError({
      message: `Could not init Fullstory - ${error?.message}`,
      error,
    });
  }
};

const isSessionInitialized = async () => {
  const { isInitialized } = await getFullstoryService();

  return isInitialized();
};

const getUserSettings = async (): Promise<import('@fullstory/browser').UserVars> => {
  const { userSession, userSummary } = await sessionApiService.get();

  return {
    displayName: userSession.fullName,
    id: userSession.accountId,
    account_name: userSummary.accountName,
    region: userSummary.accountRegion,
    plan: userSummary.planType,
    user_id: userSession.userId,
  };
};

const getIsAccountDisabled = async (): Promise<boolean> => {
  const {
    fullstory: { isDisabled },
  } = (await sessionApiService.get()).integrations;

  return isDisabled;
};

const restartSession = async () => {
  const isActiveSession = await isSessionInitialized();
  const { restart, shutdown } = await getFullstoryService();

  if (sessionStateService.isLoggedIn()) {
    if (await isFullstoryAllowed()) {
      if (isActiveSession) {
        restart();
      } else {
        await startSession();
      }
    }
  } else if (isActiveSession) {
    shutdown();
  }
};

const activateIframe = productWindow => {
  productWindow._fs_run_in_iframe = true;
};

export const fullStoryService = {
  isSessionInitialized,
  restartSession,
  activateIframe,
};
