import { useCurrentStateAndParams } from '@uirouter/react';
import { AppStateDeclaration } from '../AppStateDecleration';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { appRouter } from 'services/router/router.service';
import { customPagesImportsMap, integrationsRouteStates, integrationsRouteUrls } from 'states/integrations/route-config';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';

// DEV-46120-reinvent-the-custom-integrations-page-routing
const redirectToBasicIfCustomPagesDisabled = {
  token: 'redirectIfCustomPagesDisabled',
  deps: ['$transition$'],
  resolveFn: transition => {
    const customPagesEnabled = featureFlagStateService.isFeatureEnabled('IntegrationHubCustomPages');
    const { content } = transition.params();

    if (!customPagesEnabled) {
      appRouter.stateService.go(integrationsRouteStates.content, { content });
    }
  },
};
export const redirectToCustomIfCustomPagesEnabled = {
  token: 'redirectIfCustomPagesEnabled',
  deps: ['$transition$'],
  resolveFn: transition => {
    const customPagesEnabled = featureFlagStateService.isFeatureEnabled('IntegrationHubCustomPages');
    const { content } = transition.params();

    if (customPagesImportsMap[content] && customPagesEnabled) {
      appRouter.stateService.go(integrationsRouteStates.customContent, { content, product: 'logs' });
    }
  },
};

const LazyIntegrationCustomPageContainer = props => {
  const { params } = useCurrentStateAndParams();

  return (
    <LazyReact {...props} lazyImport={customPagesImportsMap[params.content]} resolver={({ Container }) => Container} />
  );
};
export const customContentPagesRoute: AppStateDeclaration = {
  name: integrationsRouteStates.customContent,
  url: `${integrationsRouteUrls.customContent}`,
  dynamic: true,
  component: LazyIntegrationCustomPageContainer,
  resolve: [redirectToBasicIfCustomPagesDisabled],
  data: {
    allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
    breadcrumb: {
      label: 'Collectors',
      parent: integrationsRouteStates.collectors,
      calculateLabel: params => params?.content ?? 'Collectors',
    },
  },
};
