import { Observable } from '@logz-pkg/observable';

class GraphExpandedStateService {
  state = new Observable(false);
  toggle = (value?: boolean) => {
    const nextState = value ?? !this.state.get();

    this.state.set(nextState);
  };

  clear = () => {
    this.state.set(false);
  };
}

export const graphExpandedStateService = new GraphExpandedStateService();
