import { isNil } from 'lodash';
import {
  cookiesProvider,
  authTokens,
  Env,
  authTokenService,
  authorizedAccountsApiService,
} from '@logz-pkg/frontend-services';
import { appRouter } from 'services/router/router.service';

export const tryCollectCurrentAccountId = (): void => {
  if (appRouter.locationService.path() === '/dashboard/__admin__') {
    return;
  }

  // don't need to collect current account id from the cookie
  // if we already have it in session storage
  if (authTokenService.hasCurrentAccountId()) return;

  const lastUsedAccountIdCookie = cookiesProvider.withEnv.get(authTokens.LAST_USED_ACCOUNT_ID_COOKIE);
  const logzCurrentAccountIdCookie =
    cookiesProvider.withEnv.get(authTokens.CURRENT_ACCOUNT_ID_COOKIE) || lastUsedAccountIdCookie;

  if (!isNil(logzCurrentAccountIdCookie)) {
    authTokenService.setCurrentAccountId(logzCurrentAccountIdCookie);
    cookiesProvider.withEnv.remove(authTokens.CURRENT_ACCOUNT_ID_COOKIE);
  }

  const logzAdminAccountIdCookie = cookiesProvider.withEnv.get(authTokens.ADMIN_ACCOUNT_ID_COOKIE);

  if (!isNil(logzAdminAccountIdCookie)) {
    authTokenService.setAdminAccountId(logzAdminAccountIdCookie);
    cookiesProvider.withEnv.remove(authTokens.ADMIN_ACCOUNT_ID_COOKIE);
  }
};

export const tryCollectAuthorizedAccounts = async (): Promise<void> => {
  if (authTokenService.hasCurrentAccountId()) return;

  let authorizedAccounts = await authorizedAccountsApiService.getAll();

  // use only admin account ids if we are in the admin panel
  if (Env.configs.adminPanel) {
    authorizedAccounts = authorizedAccounts.filter(account => account.admin === true);
  }

  const accountIds = authorizedAccounts.map(account => account.accountId);

  if (accountIds.length === 0) return;

  const searchParams = appRouter.locationService.search();

  const switchToAccountId = searchParams.switchToAccountId ? Number(searchParams.switchToAccountId) : null;

  // if we have switchToAccountId in the URL and it's already connected - use it
  if (switchToAccountId && accountIds.includes(switchToAccountId)) {
    authTokenService.setCurrentAccountId(searchParams.switchToAccountId);
  } else {
    authTokenService.setCurrentAccountId(accountIds[0]);

    if (authorizedAccounts[0].admin === true) {
      authTokenService.setAdminAccountId(accountIds[0]);
    }
  }
};

const restoreAdminAccountIdAfterRegionRedirect = (): void => {
  if (
    authTokenService.hasAdminAccountId() &&
    authTokenService.getAdminAccountId() !== authTokenService.getCurrentAccountId()
  ) {
    authTokenService.setCurrentAccountId(authTokenService.getAdminAccountId());
  }
};

export const bootstrapAuthTokenCollection = async (): Promise<void> => {
  cookiesProvider.removeCookie('logz-authToken');
  cookiesProvider.removeCookie('logz-adminToken');

  if (authTokenService.hasShareToken()) return;

  if (!Env.configs.adminPanel) tryCollectCurrentAccountId();

  if (Env.configs.adminPanel) restoreAdminAccountIdAfterRegionRedirect();

  try {
    await tryCollectAuthorizedAccounts();
  } catch (error) {}
};
