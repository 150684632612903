/* eslint-disable max-lines */
import { GraphEventConfig, GraphEvents } from '@logz-ui/graphs';
import { Link, NotificationService } from '@logz-ui/styleguide';
import moment from 'moment';
import {
  LoggerService,
  configProvider,
  opensearchApiService,
  searchedIndexesService,
} from '@logz-pkg/frontend-services';
import { opensearchtypes } from '@opensearch-project/opensearch';
import { SearchHit } from '@opensearch-project/opensearch/api/types';
import { graphStateService, GroupByIdSeries } from '../../../state/graph-state.service';
import { graphColors, preDefinedGraphColors } from '../../../constants';
import { queryUtils } from '../../../state/query/query.utils';
import { SpecialGraphValues } from '../../../enums';
import { DeploymentMarkerEvent } from '../../../types';
import { graphEventColumns } from './graph-event-columns';
import { show as showIntercom } from 'services/common/intercom.service';
import { timerangeQueryHelpers } from 'ui/components/shared/Timeframe/utils';
import { queryService } from 'ui/components/Explore/state/query/query.service';
import { analyticsService } from 'services/analytics/analytics.service';

const getMappedGraphColors = (groupByState: GroupByIdSeries): Record<string, string> => {
  if (!groupByState) return {};

  const graphColorsObject = [...groupByState]
    .sort((a, b) => b.count - a.count)
    .filter(state => !preDefinedGraphColors[state.key.toLocaleLowerCase()])
    .reduce((acc, state, index) => {
      acc[state.key.toLowerCase()] = graphColors[index];

      return acc;
    }, {});

  return graphColorsObject;
};

const getGroupByState = (
  termAggregations: opensearchtypes.AggregationsFiltersAggregate,
  aggregations?: Record<string, opensearchtypes.AggregationsAggregate>,
) => {
  if (!termAggregations) return [];

  const histogramPointsArray: GroupByIdSeries =
    (termAggregations.buckets as opensearchtypes.AggregationsFiltersBucketItem[]).map(bucket => ({
      series: timerangeQueryHelpers.flattenHistogramPoints(((bucket as any).byDate as any)?.buckets || []),
      key: (bucket as any).key,
      count: bucket.doc_count as number,
    })) || [];

  if (aggregations) {
    const otherBuckets = (aggregations['other-filter'] as opensearchtypes.AggregationsFiltersAggregate).buckets;

    if ((otherBuckets as any).termExist.doc_count > 0) {
      histogramPointsArray.push({
        series: timerangeQueryHelpers.flattenHistogramPoints((otherBuckets as any).termExist.dateHistogram.buckets),
        key: 'Other',
        count: (otherBuckets as any).termExist.doc_count as number,
      });
    }
  }

  return histogramPointsArray.sort((a, b) => (a.count < b.count ? 1 : -1));
};

export const getLegendIndex = (groupByState: GroupByIdSeries) => {
  if (!groupByState) return [];

  const { otherExists, missingExists } = groupByState.reduce(
    (acc, { key }) => {
      if (key.toLowerCase() === SpecialGraphValues.OTHER) {
        acc.otherExists = true;
      }

      if (key.toLowerCase() === SpecialGraphValues.MISSING) {
        acc.missingExists = true;
      }

      return acc;
    },
    { otherExists: false, missingExists: false },
  );

  const otherIndexOffset = otherExists ? 1 : 0;
  const missingIndexOffset = missingExists ? 1 : 0;

  return groupByState.map((seriesData, i, arr) => {
    let legendIndex = i;

    if (seriesData.key.toLowerCase() === SpecialGraphValues.OTHER) {
      legendIndex = missingExists ? arr.length : arr.length + otherIndexOffset;
    } else if (seriesData.key.toLowerCase() === SpecialGraphValues.MISSING) {
      legendIndex = arr.length + missingIndexOffset;
    }

    return legendIndex;
  });
};

const getMaxYValue = series => {
  const bucketSums = {};

  series.forEach(serie => {
    (serie as any).data.forEach(point => {
      const bucket = point.x;

      if (!bucketSums[bucket]) {
        bucketSums[bucket] = point.y;
      } else {
        bucketSums[bucket] += point.y;
      }
    });
  });

  const summedYValues: number[] = Object.values(bucketSums);

  return Math.max(...summedYValues);
};

const isFieldGroupByAble = async (fieldName: string): Promise<boolean> => {
  if (!fieldName) return true;

  graphStateService.isValidatingGroupBy.set(true);

  const maxCardinality = (await configProvider.getValue('explore.groupByMaxCardinality')) ?? 200;

  const { accounts, from, to, filteredFilters } = queryService.getQueryState();

  const dslFilters = queryUtils.getDslFilters(filteredFilters, queryUtils.formatTimeRange(from, to));
  const queryMarkers = { n: 'field-cardinality-validation', source: 'explore' as const };
  let result;

  try {
    result = await opensearchApiService.searchLogs(
      {
        trackTotalHits: false,
        query: dslFilters,
        size: 0,
        terminateAfter: 10000,
        timeout: '100ms',
        source: { excludes: searchedIndexesService.getAccountsWithPrefix(accounts) },
        aggs: {
          type_count: {
            cardinality: {
              field: fieldName,
              precision_threshold: maxCardinality, // will keep track of up to {maxCardinality} unique values exactly. If there are more than 100 unique values, it will start to provide approximate counts, which may have a higher degree of variance from the actual number compared to a higher precision threshold but will use less resources to do so.
            },
          },
        },
      },
      queryMarkers,
      { abortKey: `${queryMarkers.n}-${queryMarkers.source}` },
    );
  } catch (error) {
    LoggerService.logError({
      message: `Explore groupby is unable to determine field cardinality`,
      error,
      extra: { fieldName, maxCardinality },
    });
  } finally {
    graphStateService.isValidatingGroupBy.set(false);
  }

  let fieldCardinality;

  if (result.aggregations) {
    const { value } = result.aggregations.type_count as { value: number };

    fieldCardinality = value;

    analyticsService.capture('Explore page', 'User initiated group by cardinality check', {
      fieldName,
      fieldCardinality,
      maxCardinality,
    });

    if (maxCardinality > fieldCardinality) {
      return true;
    }
  }

  LoggerService.logInfo({
    message: `Explore groupby prevented a groupby of a field that is not groupable because it has too many unique values`,
    extra: { fieldName, maxCardinality, fieldCardinality },
  });

  NotificationService.warning({
    title: `Field "${fieldName}" cannot be grouped`,
    message: (
      <>
        Fields with too many unique values cannot be visualized. Refine your search or select a different field.
        <br /> <br />
        For further assistance, you can{' '}
        <Link size={16} weight={700} onClick={showIntercom}>
          contact support
        </Link>
        .
      </>
    ),
  });

  return false;
};

const createGraphEvents = (value: SearchHit<DeploymentMarkerEvent>[]) => {
  const data: GraphEventConfig<SearchHit<DeploymentMarkerEvent>>[] = value.map(deployment => ({
    icon: 'flag-pennant-regular',
    iconColor: 'gray.800',
    lineColor: 'gray.500',
    details: deployment,
    dashStyle: 'Solid',
    value: moment(deployment._source['@timestamp']).valueOf(),
  }));

  const graphEvents: GraphEvents<SearchHit<DeploymentMarkerEvent>> = {
    columns: graphEventColumns,
    data,
  };

  return graphEvents;
};

export const graphUtils = {
  isFieldGroupByAble,
  getMaxYValue,
  getMappedGraphColors,
  getGroupByState,
  createGraphEvents,
};
