import { Button, Divider, Flex, Group, LinearProgress, Tooltip } from '@logz-ui/styleguide';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { AICopilotSubjects } from '@logz-pkg/enums';
import { useAiState } from '../state/ai-state.hook';
import { useAiFetchData } from '../state/fetch-data/fetch-data.hook';
import { useAiDataSource } from '../state/data-source/data-source.hook';
import { useAiAnalytics } from '../hooks/analytics.hook';
import { useAiConversations } from '../state/conversations/conversations.hook';
import { useAiHistory } from '../state/history/history.hook';
import { AiHeaderTitle } from './HeaderTitle';
import { useFeatureFlagState } from 'ui/state/hooks';

const StyledRelativeDiv = styled.div`
  position: relative;
  height: 4px;
`;

const RelativeProgressBar = styled(LinearProgress)`
  position: relative;
  overflow: visible;
`;

export const AiChatHeader: React.FC<{ hideClearChat: boolean }> = ({ hideClearChat }) => {
  const { fetchConversations, shouldShowConversationsPage, setShouldShowConversationsPage } = useAiConversations();
  const { toggleDrawer, clear } = useAiState();
  const { isLoading } = useAiFetchData();
  const { setDataSource, initializeDataSource } = useAiDataSource();
  const { report } = useAiAnalytics();
  const { isFeatureEnabled: isAiHistoryEnabled } = useFeatureFlagState('aiHistory');
  const { history } = useAiHistory();

  const handleClose = () => {
    toggleDrawer(false);
    report('Clicked close button');
  };

  const handleClearChat = useCallback(() => {
    report('Clicked clear chat button');
    clear();
    initializeDataSource();
  }, [setDataSource]);

  const inTheMiddleOfConversation = Boolean(history?.length || isLoading);
  const headerItemsVisibility = useMemo(
    () => ({
      trash: inTheMiddleOfConversation && !hideClearChat && !shouldShowConversationsPage,
      title: shouldShowConversationsPage || inTheMiddleOfConversation,
      clock: isAiHistoryEnabled && !shouldShowConversationsPage,
    }),
    [inTheMiddleOfConversation, shouldShowConversationsPage, isAiHistoryEnabled, hideClearChat],
  );

  return (
    <Flex flexDirection={'column'} justifyContent={'center'} width={'100%'}>
      <Flex
        alignItems={'center'}
        justifyContent={headerItemsVisibility.title ? 'space-between' : 'flex-end'}
        width={'100%'}
        px={4}
        py={2}
        pb={2}
      >
        {headerItemsVisibility.title && <AiHeaderTitle />}
        <Group width={'105px'} flexGrow={1} justifyContent={'flex-end'}>
          {headerItemsVisibility.trash && (
            <Button
              round
              icon={'pen-to-square-regular'}
              size="m"
              variant={'tertiary'}
              onClick={handleClearChat}
              subject={AICopilotSubjects.DrawerClearButton}
            />
          )}
          {headerItemsVisibility.clock && (
            <Tooltip title={`View history`} placement={'bottom-end'} openDelayInMs={200} offset={2}>
              <Button
                round
                icon={'clock-regular'}
                size="m"
                variant={'tertiary'}
                onClick={() => {
                  setShouldShowConversationsPage(true);
                  fetchConversations();
                }}
                subject={AICopilotSubjects.DrawerViewHistoryButton}
              />
            </Tooltip>
          )}
          <Button
            round
            icon={'xmark-regular'}
            size="m"
            variant={'tertiary'}
            onClick={handleClose}
            subject={AICopilotSubjects.DrawerCloseButton}
          />
        </Group>
      </Flex>
      <StyledRelativeDiv>
        {isLoading && <RelativeProgressBar subject={'ai-agent-chat-loading'} overlay={true} variant={'radiant'} />}
      </StyledRelativeDiv>

      <Divider color={'gray.300'} />
    </Flex>
  );
};
