import { UIView } from '@uirouter/react';
import { ServicesFieldTypes, SpmMetrics } from '@logz-pkg/enums';
import { redirectIfFFDisabled } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';
import type { RealUserMonitoringParams } from 'ui/components/Spm/RealUserMonitoring/types';

export const spmRouteNames = {
  baseRoute: 'dashboard.spm',
  services: 'dashboard.spm.services',
  servicesTable: 'dashboard.spm.services.table',
  servicesMap: 'dashboard.spm.services.map',
  serviceOverview: 'dashboard.spm.service-overview',
  operationOverview: 'dashboard.spm.operation-overview',
  realUserMonitoring: 'dashboard.real-user-monitoring',
};

const sharedParams = {
  end: 'now',
  start: 'now-1h',
  compareTo: null,
};

const overviewParams = {
  serviceName: '',
  deploymentName: '',
  spmAccountId: '',
  quickViewCluster: undefined,
  accountId: undefined,
  quickViewId: undefined,
  quickViewSelectedTab: undefined,
  time: undefined,
  ...sharedParams,
  serviceType: ServicesFieldTypes.ServiceName,
  groupBy: 'pods',
  selectedNodes: {
    array: true,
  },
  selectedPods: {
    array: true,
  },
  environments: {
    array: true,
  },
  traceId: undefined,
  traceAccountId: undefined,
  filters: {
    type: 'string',
    array: true,
    value: undefined,
  },
};

export const spmRoutes: AppStateDeclaration[] = [
  {
    name: spmRouteNames.baseRoute,
    abstract: true,
    url: '/spm',
    component: UIView,
    data: { authenticate: true },
  },
  {
    url: '/services',
    name: spmRouteNames.services,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Spm/Services')}
        resolver={({ ServicesContainer }) => ServicesContainer}
        {...props}
      />
    ),

    data: { authenticate: true },
  },
  {
    name: spmRouteNames.servicesTable,
    url: '/table?start&end&compareTo&operations&environments',
    dynamic: true,
    params: {
      ...sharedParams,
      environments: {
        array: true,
      },
      operations: {
        array: true,
      },
    },
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Spm/ServiceList/ServiceList')}
        resolver={({ ServiceList }) => ServiceList}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Service List',
      breadcrumb: { label: 'Services' },
      saveParams: true,
    },
  },
  {
    name: spmRouteNames.servicesMap,
    dynamic: true,
    url: '/map?start&end&compareTo&operations&environments&metric&services',
    params: {
      ...sharedParams,
      environments: {
        array: true,
      },
      operations: {
        array: true,
      },
      metric: SpmMetrics.ErrorRate,
      services: {
        array: true,
      },
    },
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Spm/ServiceMap/ServiceMap')}
        resolver={({ ServiceMap }) => ServiceMap}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Service List',
      breadcrumb: { label: 'Service Map' },
      saveParams: true,
    },
    resolve: [redirectIfFFDisabled({ featureFlag: 'ServiceListMap' })],
  },
  {
    name: spmRouteNames.serviceOverview,
    url: `/service-overview?${Object.keys(overviewParams).join('&')}`,
    dynamic: true,
    params: {
      ...overviewParams,
    },
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Spm/ServiceOverview/ServiceOverview')}
        resolver={({ ServiceOverview }) => ServiceOverview}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Service Overview',
      breadcrumb: {
        label: 'Service overview',
        parent: spmRouteNames.servicesTable,
        calculateLabel: params => params?.serviceName ?? 'Service overview',
      },
    },
  },
  {
    name: spmRouteNames.operationOverview,
    url: `/operation-overview?operation&${Object.keys(overviewParams).join('&')}`,
    dynamic: true,
    params: {
      operation: '',
      ...overviewParams,
    },
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Spm/OperationOverview/OperationOverview')}
        resolver={({ OperationOverview }) => OperationOverview}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Operation Overview',
      breadcrumb: {
        label: 'Operation overview',
        parent: spmRouteNames.serviceOverview,
        calculateLabel: params => params?.operation ?? 'Operation overview',
      },
    },
  },
  {
    name: spmRouteNames.realUserMonitoring,
    url: `/real-user-monitoring?timeframe&page&browser&country&os&device&user&group&quickViewLogId`,
    dynamic: true,
    params: {
      service: '',
      env: '',
      path: '',
      browser: '',
      country: '',
      os: '',
      device: '',
      timeframe: '24h',
      quickViewLogId: '',
      user: '',
      group: '',
    } as RealUserMonitoringParams,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Spm/RealUserMonitoring/Container')}
        resolver={({ RealUserMonitoringContainer }) => RealUserMonitoringContainer}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Real User Monitoring',
      breadcrumb: {
        label: 'Real user monitoring',
        parent: spmRouteNames.serviceOverview,
      },
    },
  },
];
