import { userService } from '@logz-pkg/frontend-services';
import { getUrlResourceParam } from '../../product/route-helpers/route-helpers';
import { queryString, params } from '../../osd/osd-route-params';
import { LazyReact } from '../../../ui/components/shared/LazyReact';
import { AppStateDeclaration } from '../AppStateDecleration';
import { researchDashboardRouteName, researchRouteName } from './security.routes.names';

export const researchRoute: AppStateDeclaration[] = [
  {
    name: researchRouteName,
    url: `/security/research${getUrlResourceParam('resource')}${queryString}`,
    params,
    views: {
      research: {
        component: props => (
          <LazyReact
            {...props}
            lazyImport={() => import('ui/components/Osd/Osd')}
            resolver={({ KibanaComponent }) => KibanaComponent}
          />
        ),
      },
    },
    sticky: true,
    dynamic: true,
    resolve: {
      switchToAccountId: () => userService.getCurrentAccountId(),
    },
    data: { authenticate: true, title: 'Log analytics', breadcrumb: { label: 'Research' } },
  },
  {
    name: researchDashboardRouteName,
    url: `/security/research-dashboards`,
    data: { authenticate: true, title: 'Log analytics' },
    redirectTo: { state: researchRouteName, params: { resource: 'dashboards' } },
  },
];
