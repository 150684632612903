import { FunctionComponent } from 'react';
import { Modal, Title } from '@logz-ui/styleguide';

interface IOfflineModalProps {
  onClose(): void;
  open: boolean;
}

export const OfflineModal: FunctionComponent<IOfflineModalProps> = ({ onClose, open }) => (
  <Modal open={open} onClose={onClose}>
    <Modal.Header>
      <Title weight={700} size={24}>
        You’re offline
      </Title>
    </Modal.Header>
    <Modal.Body>We'll keep trying to reconnect, but please check your internet connection.</Modal.Body>
  </Modal>
);
