import { ProductKeys } from '@logz-pkg/enums';
import { getNavItemStates } from './get-nav-item-states';
import { App360SideNavConfigs, dataHubItemConfig, settingsItemConfig, SidenavItemConfigs } from '.';

export const productStatesMap = new Map<string, ProductKeys[]>();
export const productModeMap = new Map<string, ProductKeys[]>();
export const productParamMap = new Map<string, ProductKeys[]>();

const addItemToMappedArray = (map, key, item) => {
  const currentValues = map.get(key);

  if (currentValues) {
    map.set(key, currentValues.concat(item));
  } else {
    map.set(key, [item]);
  }

  return map;
};

[...SidenavItemConfigs, ...App360SideNavConfigs, settingsItemConfig, dataHubItemConfig].forEach(SidenavItemConfig => {
  const stateMap = getNavItemStates(SidenavItemConfig);
  const paramsList = SidenavItemConfig.expandedNavGroups
    .map(navGroup => navGroup.items.filter(navItem => navItem.targetStateParams))
    .flat();

  paramsList.forEach(item => {
    addItemToMappedArray(productParamMap, JSON.stringify(item.targetStateParams), SidenavItemConfig.key);
  });

  if (SidenavItemConfig.mode) {
    addItemToMappedArray(productModeMap, SidenavItemConfig.mode, SidenavItemConfig.key);
  }

  if (!SidenavItemConfig.mode) {
    // Add products with no mode such as Settings to all modes
    Array.from(productModeMap.keys()).forEach(mode => {
      addItemToMappedArray(productModeMap, mode, SidenavItemConfig.key);
    });
  }

  stateMap.forEach(state => {
    addItemToMappedArray(productStatesMap, state, SidenavItemConfig.key);
  });
});
