import { ExplorePageDensityModes } from '@logz-pkg/enums';
import { useObservable } from '@logz-pkg/observable';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { densityModesSizeMap } from '../../../constants';
import { tableCollapseStateService } from '../../../state/table-collapse-state.service';

type CellProps = {
  isExpanded: boolean;
  maxLines?: number;
  rowHeight?: string;
};

type UseCellProps<T> = {
  row: Row<T>;
  columnDef: ColumnDef<T>;
};

export const useCellProps = <T extends object>({ columnDef, row }: UseCellProps<T>): CellProps => {
  const tableCollapseState = useObservable(tableCollapseStateService.state);

  if (!columnDef || !row) return { maxLines: undefined, isExpanded: false, rowHeight: undefined };

  const densityMode = columnDef.meta?.densityMode || ExplorePageDensityModes.OneLine;

  const maxLines = densityMode === ExplorePageDensityModes.OneLine ? 1 : 2;
  const isExpanded = tableCollapseState.some(id => id === row.id) || densityMode === ExplorePageDensityModes.Expanded;
  const rowHeight = `${densityModesSizeMap[densityMode]}px`;

  return { maxLines, isExpanded, rowHeight };
};
