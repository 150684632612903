import { useMemo, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { opensearchApiService } from '@logz-pkg/frontend-services';
import { opensearchtypes } from '@opensearch-project/opensearch';
import { AIDataSource } from '@logz-pkg/enums';
import { AgentChatRecord, Insight } from '@logz-pkg/models';
import { agentInvocationMessageUtils } from 'ui/components/AI/Components/Messages/Agent/utils';
import { graphUtils } from 'ui/components/Explore/ContentPane/Graphs/utils/graph-utils';
import { EXCEPTIONS_FIELD_NAME } from 'ui/components/Explore/constants';
import { defaultGroupByLegendOptions, defaultLegendOptions } from 'ui/components/Explore/ContentPane/Graphs/constants';
import type { Dictionary } from 'ui/components/Explore/types';
import { GroupByIdSeries } from 'ui/components/Explore/state/graph-state.service';
import { seriesUtils } from 'ui/components/Explore/ContentPane/Graphs/utils/series.utils';
import { AiChatAgentInvocationGraphType } from 'ui/components/AI/Components/Messages/Agent/InvocationGraph';
import { useAiHistory } from 'ui/components/AI/state/history/history.hook';
import { useAiDataSource } from 'ui/components/AI/state/data-source/data-source.hook';
import { agentInvocationMessageMapUtils } from 'ui/components/AI/Components/Messages/Agent/invocationMap.utils';

export const useOpenSearchInvocationMessageHook = ({
  interval,
  functionName,
  field,
  query,
}: Omit<AiChatAgentInvocationGraphType, 'shouldShowCustomLegend'>) => {
  const groupByState = useRef<GroupByIdSeries>([]);

  const { history } = useAiHistory();
  const { dataSource } = useAiDataSource();

  const histogramQuery = useMemo(() => {
    if (!interval.start) return;

    const graphInvocationQueryFunction = agentInvocationMessageMapUtils.getGraphInvocationQuery(functionName);

    if (!graphInvocationQueryFunction) return;

    return graphInvocationQueryFunction({
      field,
      query,
      interval,
    });
  }, [field, query, interval.start, interval.end]);

  const lastRecord = history[0] as AgentChatRecord & { information: Insight };

  const information = lastRecord?.information;
  const type = lastRecord?.type;

  const isAgentPro = dataSource === AIDataSource.AllAvailableData;

  const legend = useMemo(() => {
    if (type !== 'Exceptions' || !field || isAgentPro || !information) return defaultLegendOptions;

    const { id, title } = information;

    if (!groupByState.current) return defaultLegendOptions;

    const dictionaryForLegend: Dictionary = {
      [EXCEPTIONS_FIELD_NAME]: {
        label: 'exceptions',
        readonly: true,
        valuesDictionary: { [id]: title },
      },
    };

    return defaultGroupByLegendOptions(groupByState.current, dictionaryForLegend[field]);
  }, [field, type, information?.id, information?.title, groupByState.current]);

  const { data: logs = [], isFetching } = useQuery({
    staleTime: Infinity,
    queryKey: ['chat-ai-histogram-container', interval.start, interval.end, query, field],
    queryFn: async () => {
      if (!histogramQuery) return [];

      const data = await opensearchApiService.searchLogs({ ...histogramQuery }, { n: 'invocation-graph' });
      let bucketsData = (data?.aggregations?.agg as any)?.buckets || [];

      if (field) {
        if (data?.hits?.total === 0) return [];

        const groupByQuery = agentInvocationMessageMapUtils.getGraphInvocationQuery(`${functionName}-second`)({
          field,
          query,
          interval,
          aggregationsByTermAgg: data.aggregations.byTerm as opensearchtypes.AggregationsAutoDateHistogramAggregate,
        });

        const groupByAggData = await opensearchApiService.searchLogs(
          { ...groupByQuery },
          { n: 'invocation-graph-group-by' },
        );

        bucketsData = graphUtils.getGroupByState(
          data?.aggregations?.byTerm as opensearchtypes.AggregationsFiltersAggregate,
          groupByAggData?.aggregations,
        );

        groupByState.current = bucketsData;

        return agentInvocationMessageUtils.getColorsForGroupByAndSeries({
          byTerm: data?.aggregations?.byTerm as opensearchtypes.AggregationsFiltersAggregate,
          groupBy: field,
          logs: bucketsData,
          aggregations: groupByAggData?.aggregations,
        });
      }

      return seriesUtils.getLogSeries(bucketsData, 'blue.600');
    },
  });

  return {
    logs,
    isFetching,
    legend,
  };
};
