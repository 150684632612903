import { appStateService } from '@logz-pkg/frontend-services';
import { SearchSource } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { ErrorHandlingService } from 'services/error-handling.service';

interface IState {
  name: string;
  params?: Record<string, string>;
}

export interface ILandingPage {
  name: string;
  source: SearchSource;
  state?: IState;
  link?: string;
}
const landingPageSchema = 'landing-page';
const getState = async () => {
  return appStateService.get(landingPageSchema);
};

const setState = async state => {
  await appStateService.set(landingPageSchema, state);
};

class LandingPageAppStateService {
  landingPage = new Observable<ILandingPage>();

  set = async (value: ILandingPage) => {
    try {
      await setState(value);
      this.landingPage.set(value);
    } catch (error) {
      ErrorHandlingService.handleApiError({ title: 'Failed to set new Landing Page', error });
      throw error;
    }
  };

  get = async ({ force } = { force: false }): Promise<ILandingPage> => {
    if (force || !this.landingPage.get()?.name) {
      const landingPage = await getState();

      this.landingPage.set(landingPage);
    }

    return this.landingPage.get();
  };
}

export const landingPageAppStateService = new LandingPageAppStateService();
