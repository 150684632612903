import { FunctionComponent, useEffect } from 'react';
import { colors, Modal } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { WelcomePageTestSubject } from '@logz-pkg/enums';
import { telemetryDataStatusService } from '@logz-pkg/frontend-services';
import { useObservable } from '@logz-pkg/observable';
import { DataSentModalContent } from './Content';
import { useWelcomeState } from 'ui/state/hooks';
import { getDataSentModalDetails } from 'ui/components/WelcomeCenter/DataSentModal/Details';

// DEV-37909: gradient background color modifier
const GradientBackground = styled.div`
  background: linear-gradient(0deg, ${colors.gray[0]} 50%, ${colors.yellow[200]} 85%) no-repeat;
  height: 100%;
`;

export const DataSentModalContainer: FunctionComponent = () => {
  const { setIsDataSentModalOpen } = useWelcomeState();
  const telemetryStatus = useObservable(telemetryDataStatusService.status);
  const details = getDataSentModalDetails(telemetryStatus);

  const handleCloseModal = () => {
    setIsDataSentModalOpen(false);
  };

  useEffect(() => {
    if (!details) {
      setIsDataSentModalOpen(false);
    }
  }, [details]);

  if (!details) return null;

  return (
    <Modal
      size={'m'}
      open={true}
      subject={WelcomePageTestSubject.DataSentModal}
      dontCloseOnOverlayClick
      onClose={handleCloseModal}
    >
      <GradientBackground>
        <Modal.Body flexDirection={'column'} p={6}>
          <DataSentModalContent
            title={details.title}
            subTitle={details.subTitle}
            image={details.image}
            ExploreSection={details.ExploreSection}
          />
        </Modal.Body>
      </GradientBackground>
    </Modal>
  );
};
