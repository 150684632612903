import { removeHash } from '@logz-pkg/utils';
import { LoggerService } from '@logz-pkg/frontend-services';
import { appRouter } from 'services/router/router.service';
import { logoutService } from 'core/auth/services/logout.service';
import { ContactSupport } from 'ui/components/shared/ContactSupport/ContactSupport';

const expirationLog = {
  isLogged: false,
  reset() {
    this.isLogged = false;
  },
};

const possibleMessages = {
  default: {
    regex: /(Invalid Session|Unauthorized)/,
    invalidateToken: true,
    clearStateParams: false,
    messageTitle: 'Invalid Session',
    messageBody: 'Your session is either invalid or expired. Please log into Logz.io again.',
  },
  replace: {
    regex: /Failed to replace user session to account due to different auth settings/,
    invalidateToken: false,
    clearStateParams: true,
    messageTitle: 'Cannot access your account',
    messageBody: (
      <>
        Unable to log into this account due to different authentication settings.
        <br />
        Contact <ContactSupport text="Support" /> for more information.
      </>
    ),
  },
};

const extractUnauthorizedMessage = err => {
  if (typeof err.data === 'string') return err.data;

  const responseData = err.response.data;

  if (typeof responseData === 'string') return responseData;

  if (typeof responseData === 'object' && responseData.message) return responseData.message;
};

const logSessionExpiration = error => {
  if (expirationLog.isLogged) return;

  const url = error?.config?.url;

  LoggerService.logError({
    message: `Session expiration called: unauthorized request ${url}`,
    error,
    extra: {
      url,
      logIdentifier: 'UNAUTHORIZED_REQUEST_SESSION_EXPIRATION',
    },
  });
  expirationLog.isLogged = true;
};

const expire = async ({ error }) => {
  const errorMsg = extractUnauthorizedMessage(error);
  const errorTypeFound = Object.values(possibleMessages).find(({ regex }) => regex.test(errorMsg));
  const { messageTitle, messageBody, invalidateToken, clearStateParams } = errorTypeFound || possibleMessages.default;

  await appRouter.stateService.go('.', { switchToAccountId: null }, { inherit: !clearStateParams, reload: false });

  const { hash } = window.location;
  // When having a bad token while in login, we dont want to redirect the user multiple times
  const redirect = hash.startsWith('#/login') ? null : removeHash(hash);

  logSessionExpiration(error);
  logoutService.logout({ messageTitle, messageBody, redirect }, invalidateToken);
};

export const sessionExpiration = {
  expire,
  expirationLog,
};
