import { Chart, SeriesPieOptions, ColorType } from 'highcharts';
import React, { useState } from 'react';
import { Highcharts as HighchartsReact } from '../Highcharts/Highcharts';
import { TooltipPortal } from './TooltipPortal';
import type { IGraphProps } from './types';
import { DefaultTooltipComponent } from './components/DefaultTooltipComponent';
import { useColoredSeries } from './hooks/graph-options/hooks/colored-series.hook';
import { useGraphOptions } from './hooks/graph-options/graph-options.hook';

export const PieLegend: React.FC<IGraphProps> = ({
  TooltipComponent = DefaultTooltipComponent,
  series,
  pieSeries,
  mainChart,
  ...graphOptions
}) => {
  const [pieChart, setPieChart] = useState<Chart | null>(null);

  const pieChartOptions = useGraphOptions({
    ...graphOptions,
    series: pieSeries,
    width: mainChart?.chartWidth / 4,
    height: mainChart?.chartHeight,
    mainChart,
  });

  const customSeries = useColoredSeries({ series });

  pieSeries?.[0]?.data?.forEach((pieItem: SeriesPieOptions['data'] & { name: string; color: ColorType }) => {
    customSeries.forEach(item => {
      if (pieItem.name === item.name) {
        pieItem.color = item.color;
      }
    });
  });

  return (
    <>
      <TooltipPortal chart={pieChart}>
        {({ points, nowHovered }) => (
          <TooltipComponent unit={'%'} vertical={true} points={points} nowHovered={nowHovered} />
        )}
      </TooltipPortal>
      <HighchartsReact options={pieChartOptions} callback={setPieChart} />
    </>
  );
};
