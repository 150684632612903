import { LazyReact } from 'ui/components/shared/LazyReact';

export const LazyWelcomeCenterBar = props => (
  <LazyReact
    {...props}
    fallback={null}
    lazyImport={() => import('ui/components/WelcomeCenter/Bar/Bar')}
    resolver={({ WelcomeCenterBar }) => WelcomeCenterBar}
  />
);

export const LazySiemOnboarding = props => (
  <LazyReact
    fallback={null}
    {...props}
    lazyImport={() => import('../Siem/Onboarding/Container')}
    resolver={({ SiemOnboardingContainer }) => SiemOnboardingContainer}
  />
);

export const LazyTracingTrialBar = props => (
  <LazyReact
    fallback={null}
    {...props}
    lazyImport={() => import('ui/components/Tracing')}
    resolver={({ TracingTrialBar }) => TracingTrialBar}
  />
);

export const LazyLogsSuspensionBar = props => (
  <LazyReact
    fallback={null}
    {...props}
    lazyImport={() => import('ui/components/SuspensionBars/LogsSuspensionBar')}
    resolver={({ LogsSuspensionBar }) => LogsSuspensionBar}
  />
);

export const LazyTracingSuspensionBar = props => (
  <LazyReact
    fallback={null}
    {...props}
    lazyImport={() => import('ui/components/SuspensionBars/TracingSuspensionBar')}
    resolver={({ TracingSuspensionBar }) => TracingSuspensionBar}
  />
);

export const LazyMetricsSuspensionBar = props => (
  <LazyReact
    fallback={null}
    {...props}
    lazyImport={() => import('ui/components/SuspensionBars/MetricsSuspensionBar')}
    resolver={({ MetricsSuspensionBar }) => MetricsSuspensionBar}
  />
);

export const LazyDataParsingModal = props => (
  <LazyReact
    fallback={null}
    {...props}
    lazyImport={() => import('ui/components/DataParsing/Modal')}
    resolver={({ DataParsingModal }) => DataParsingModal}
  />
);

export const LazyLogsPaymentBar = props => (
  <LazyReact
    fallback={null}
    {...props}
    lazyImport={() => import('ui/components/App/PaymentFailureNotification/PaymentFailureNotification')}
    resolver={({ PaymentFailureNotification }) => PaymentFailureNotification}
  />
);
