import { Observable } from '@logz-pkg/observable';

export class DataParsingModalStateService {
  readonly isDataParsingModalOpen = new Observable<boolean>(false);

  setIsDataParsingModalOpen = (value: boolean) => {
    this.isDataParsingModalOpen.set(value);
  };

  showDataParsingModal = () => this.isDataParsingModalOpen.set(true);
  hideDataParsingModal = () => this.isDataParsingModalOpen.set(false);
}
