import { UserRole, Permissions, PLAN_TYPE } from '@logz-pkg/enums';
import { NotificationService } from '@logz-ui/styleguide';
import { accountModelManipulator } from '@logz-pkg/models';
import { userService, authService, sessionApiService } from '@logz-pkg/frontend-services';
import { upgradeService } from '../upgrade-service/upgrade-service';
import { appRouter } from '../router/router.service';
import { isHeroku } from '../../app/configs/heroku.config';
import { userPermissionsService } from '../user-permissions/user-permissions.service';

const ROUTE_ERRORS = {
  NOT_ADMIN: 'Only account admins are allowed to access this page',
  NOT_SYSTEM_ADMIN: 'Only system admins are allowed to access this page',
  NOT_ACCOUNT_OWNER: 'Only a main account admin is allowed to access this page',
  IS_HEROKU: 'Heroku account cannot access this page',
  NO_PERMISSION: 'You do not have sufficient permissions',
  API_ACCESS_ENABLED: 'Your account does not have API access enabled',
  NOT_CORRECT_PLAN_TYPE: 'Access is restricted for this plan type',
};

export interface IIdentityRouteResolver {
  isAdmin?: boolean;
  isSystemAdmin?: boolean;
  isAccountOwner?: boolean;
  isNotHeroku?: boolean;
  hasPermission?: Permissions;
  hasOneOfPermissions?: Permissions[];
  apiAccessEnabled?: boolean;
  redirectTo?: string;
  notAllowedAccountPlanTypes?: PLAN_TYPE[];
}

export const identityRouteResolver = ({
  isAdmin,
  isSystemAdmin,
  isAccountOwner,
  isNotHeroku,
  hasPermission,
  hasOneOfPermissions,
  apiAccessEnabled,
  notAllowedAccountPlanTypes,
  redirectTo = 'dashboard.settings.general',
}: IIdentityRouteResolver) =>
  async function IdentityResolverService() {
    const assertions = {
      isSystemAdmin: () =>
        isSystemAdmin && (authService.isAdmin() ? Promise.resolve() : Promise.reject(ROUTE_ERRORS.NOT_SYSTEM_ADMIN)),

      isAdmin: () =>
        isAdmin &&
        authService
          .getUserSession()
          .then(userSession =>
            userSession.role === UserRole.Admin ? Promise.resolve() : Promise.reject(ROUTE_ERRORS.NOT_ADMIN),
          ),

      isAccountOwner: () =>
        isAccountOwner &&
        userService
          .getSummary()
          .then(({ data }) =>
            accountModelManipulator.deprecatedIsOwner(data.accountType)
              ? Promise.resolve()
              : Promise.reject(ROUTE_ERRORS.NOT_ACCOUNT_OWNER),
          ),

      isNotHeroku: () => isNotHeroku && (isHeroku ? Promise.reject(ROUTE_ERRORS.IS_HEROKU) : Promise.resolve()),

      hasPermission: () =>
        hasPermission &&
        userPermissionsService
          .hasPermission(hasPermission)
          .then(isPermission => (isPermission ? Promise.resolve() : Promise.reject(ROUTE_ERRORS.NO_PERMISSION))),

      hasOneOfPermissions: () =>
        hasOneOfPermissions &&
        userPermissionsService
          .hasOneOfPermissions(hasOneOfPermissions)
          .then(hasAny => (hasAny ? Promise.resolve() : Promise.reject(ROUTE_ERRORS.NO_PERMISSION))),

      apiAccessEnabled: () =>
        apiAccessEnabled &&
        upgradeService
          .getPlanDetails()
          .then(({ data }) =>
            data.isApiAccessEnabled ? Promise.resolve() : Promise.reject(ROUTE_ERRORS.API_ACCESS_ENABLED),
          ),

      hasPlanType: () =>
        notAllowedAccountPlanTypes &&
        sessionApiService
          .get()
          .then(userSummery =>
            !notAllowedAccountPlanTypes.includes(userSummery.plan.planType)
              ? Promise.resolve()
              : Promise.reject(ROUTE_ERRORS.NOT_CORRECT_PLAN_TYPE),
          ),
    };

    return Promise.all(Object.values(assertions).map(assertion => assertion())).catch(error => {
      NotificationService.danger({ message: error });
      appRouter.stateService.go(redirectTo);
      throw new Error(error); // prevent state from loading
    });
  };
