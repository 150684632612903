import { FunctionComponent } from 'react';
import { Group, Text, Link } from '@logz-ui/styleguide';
import { ProductEmptyState } from 'ui/components/shared/templates/ProductEmptyState/ProductEmptyState';

export const EmptyStateTitle: FunctionComponent = () => {
  const refreshAndReport = () => {
    window.location.reload();
  };

  return (
    <Group vertical gap={2}>
      <ProductEmptyState.Title>
        Welcome to Logz.io
        <br />
        Distributed Tracing!
      </ProductEmptyState.Title>
      <Text mt={2} color={'gray.800'}>
        The Logz.io Jaeger interface is available after your traces are ingested. Already sending traces? Click{' '}
        <Link onClick={refreshAndReport}>here</Link> to refresh and view your traces.
      </Text>
    </Group>
  );
};
