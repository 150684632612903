import { UIView } from '@uirouter/react';
import { Permissions } from '@logz-pkg/enums';
import { redirectIfFFDisabled, redirectIfNoPermission } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const logsToMetricsStates = {
  page: 'dashboard.logs-to-metrics',
  definitions: 'dashboard.logs-to-metrics.definitions',
  create: 'dashboard.logs-to-metrics.new',
  edit: 'dashboard.logs-to-metrics.edit',
  duplicate: 'dashboard.logs-to-metrics.duplicate',
};

export const logsToMetricsUrls = {
  page: '/logs-to-metrics',
  definitions: '/definitions',
  create: '/new',
  edit: '/:id',
  duplicate: '/:id/duplicate',
};

const LazyLogsToMetricsDefinitions = props => (
  <LazyReact
    lazyImport={() => import('ui/components/LogsToMetrics/Definitions/Page')}
    resolver={({ LogsToMetricsDefinitionsPage }) => LogsToMetricsDefinitionsPage}
    {...props}
  />
);

const LazyLogsToMetricsForm = props => (
  <LazyReact
    lazyImport={() => import('ui/components/LogsToMetrics/Edit/Page')}
    resolver={({ LogsToMetricsEditPage }) => LogsToMetricsEditPage}
    {...props}
  />
);

export const logsToMetricsRoutes: AppStateDeclaration[] = [
  {
    name: logsToMetricsStates.page,
    url: logsToMetricsUrls.page,
    component: UIView,
    redirectTo: logsToMetricsStates.definitions,
    data: { authenticate: true },
    abstract: true,
  },
  {
    name: logsToMetricsStates.definitions,
    url: logsToMetricsUrls.definitions,
    component: LazyLogsToMetricsDefinitions,
    data: {
      title: 'LogMetric',
      authenticate: true,
      breadcrumb: { label: 'LogMetric' },
    },
    resolve: [
      redirectIfFFDisabled({ featureFlag: 'LogsToMetrics' }),
      redirectIfNoPermission({ hasPermission: Permissions.LOG_METRIC_READ }),
    ],
  },
  {
    name: logsToMetricsStates.create,
    url: logsToMetricsUrls.create,
    component: LazyLogsToMetricsForm,
    data: {
      title: 'LogMetric',
      authenticate: true,
      breadcrumb: { label: 'LogMetric' },
    },
    params: {
      queryFilters: undefined,
      selectedAccounts: undefined,
    },
    resolve: [
      redirectIfFFDisabled({ featureFlag: 'LogsToMetrics' }),
      redirectIfNoPermission({ hasPermission: Permissions.LOG_METRIC_CREATE }),
    ],
  },
  {
    name: logsToMetricsStates.edit,
    url: logsToMetricsUrls.edit,
    component: LazyLogsToMetricsForm,
    data: {
      title: 'LogMetric',
      authenticate: true,
      breadcrumb: { label: 'LogMetric' },
    },
    resolve: [
      redirectIfFFDisabled({ featureFlag: 'LogsToMetrics' }),
      redirectIfNoPermission({ hasPermission: Permissions.LOG_METRIC_UPDATE }),
    ],
  },
  {
    name: logsToMetricsStates.duplicate,
    url: logsToMetricsUrls.duplicate,
    component: LazyLogsToMetricsForm,
    data: {
      title: 'LogMetric',
      authenticate: true,
      breadcrumb: { label: 'LogMetric' },
    },
    resolve: [
      redirectIfFFDisabled({ featureFlag: 'LogsToMetrics' }),
      redirectIfNoPermission({ hasPermission: Permissions.LOG_METRIC_CREATE }),
    ],
    params: {
      duplicate: true,
    },
  },
];
