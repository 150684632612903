import { ResolveTypes } from '@uirouter/react';
import { NotificationService } from '@logz-ui/styleguide';
import { searchableAccountsService } from '../state/searchable-accounts.service';
import { exploreSearchParamsService } from '../router/router';
import { ContactSupport } from 'ui/components/shared/ContactSupport/ContactSupport';
import { warmTierService } from 'ui/components/Explore/services/warm-tier.service';

export const waitForStatesToLoad: ResolveTypes = {
  token: 'wait-for-states-to-load',
  resolveFn: async () => {
    try {
      exploreSearchParamsService.intialized.set(false);
      await Promise.all([searchableAccountsService.fetchAccounts(), warmTierService.init()]); // define ONLY necessary states to load
    } catch (e) {
      NotificationService.danger({
        title: 'Error fetching accounts',
        message: (
          <>
            Please reload to try again, if this issue persists please&nbsp;
            <ContactSupport text={'Contact Support'} />
          </>
        ),
      });
    }
  },
};
