import { UxType } from '@logz-pkg/enums';
import { LoggerService } from '@logz-pkg/frontend-services';
import { IContentTab } from '../Markdown/extract-tabs';

const getTabsMarkdown = (content: string): string[] => {
  const tabsContentRegex = /<TabItem\s+value="[^"]+"\s+label="[^"]+"(?:\s+default)?>\s*([\s\S]*?)\s*<\/TabItem>/g;
  const matches = content.matchAll(tabsContentRegex);

  if (!matches) return [];

  const contents = [];

  for (const match of matches) {
    contents.push(match[1]);
  }

  return contents;
};

const getTabsMeta = (content: string): { title: string; id: string }[] => {
  const extractAllTabTitlesRegex = /<TabItem[^>]*\s+label="([^"]+)"/g;
  const matchAllTabsTitles = [];
  let match;

  while ((match = extractAllTabTitlesRegex.exec(content)) !== null) {
    matchAllTabsTitles.push({ title: match[1], id: match[1] });
  }

  return matchAllTabsTitles;
};

const extractTabs = (content, groupId): IContentTab[] => {
  try {
    const tabsMarkdown = getTabsMarkdown(content);
    const tabsMeta = getTabsMeta(content);

    if (tabsMarkdown.length !== tabsMeta.length) {
      throw new Error(`content issue - found ${tabsMarkdown.length} contents, but ${tabsMeta.length} titles`);
    }

    const contentTabs = tabsMeta.map(({ title, id }, i) => ({
      title,
      id,
      groupId,
      content: tabsMarkdown[i],
    }));

    return contentTabs;
  } catch (error) {
    LoggerService.logError({
      message: `Send your data - failed to extract tabs from content`,
      error,
      uxType: UxType.IN_PAGE,
      extra: {
        content,
      },
    });

    return [];
  }
};

const getTabsGroupId = (source: string, match: string) => {
  const startIndex = source.indexOf(match);

  if (startIndex === -1) {
    return null;
  }

  const beforeSubstring = source.substring(0, startIndex);

  const lastTabsMatch = beforeSubstring.match(/<Tabs[^>]*>/g);

  if (lastTabsMatch) {
    return lastTabsMatch?.pop()?.match(/<Tabs groupId="([^"]+)">/)?.[1] ?? null;
  }

  return null;
};

export const markdownTabsUtils = {
  extractTabs,
  getTabsGroupId,
};
