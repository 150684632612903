import { FunctionComponent } from 'react';
import { Group, Text, Icon, Link } from '@logz-ui/styleguide';

export interface IViewTutorialButtonProps {
  onClick: () => void;
}

export const ViewTutorialButton: FunctionComponent<IViewTutorialButtonProps> = ({ onClick }) => (
  <Link onClick={onClick}>
    <Group>
      <Icon icon={'play-solid'} size={14} color={'white'} />
      <Text color="white">View tutorial</Text>
    </Group>
  </Link>
);

ViewTutorialButton.displayName = 'ViewTutorialButton';
