import { ConfirmModal, Text } from '@logz-ui/styleguide';
import React from 'react';
import { useAppConfirmModal } from 'services/ui-services/app-confirm-modal.hook';

export const AppConfirmModal: React.FC = () => {
  const { open, onConfirm, onCancel, text, ...props } = useAppConfirmModal();

  if (!open) return null;

  return (
    <ConfirmModal isOpen={open} onConfirm={onConfirm} onClose={onCancel} {...props}>
      {typeof text === 'string' ? <Text>{text}</Text> : text}
    </ConfirmModal>
  );
};
