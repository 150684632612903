import { AccountType } from '@logz-pkg/enums';
import { searchableAccountsApiService } from '@logz-pkg/frontend-services';
import { SearchableAccountModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';

class SearchableAccountsService {
  state = new Observable<SearchableAccountModel[]>([]);
  stateAsMap = new Observable<Record<number, SearchableAccountModel>>({});
  isLoading = new Observable<boolean>(false);
  fetchAccounts = async () => {
    this.isLoading.set(true);

    const newAccounts =
      (await searchableAccountsApiService.getAccountsByType([
        AccountType.Owner,
        AccountType.Sub,
        AccountType.Timeless,
        AccountType.Tracing,
        AccountType.Restore,
      ])) ?? [];

    this.state.set(newAccounts);
    this.stateAsMap.set(Object.fromEntries(newAccounts.map(account => [account.id, account])));
    this.isLoading.set(false);

    return newAccounts;
  };

  clear = () => this.state.set([]);
}

export const searchableAccountsService = new SearchableAccountsService();
