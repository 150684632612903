import { httpService, sessionStateService, userService } from '@logz-pkg/frontend-services';
import { LazyReact } from '../../../ui/components/shared/LazyReact';
import { params, queryString } from '../../osd/osd-route-params';
import { kibanaRouteService } from '../../osd/osd-route.service';
import { kibanaVersion } from '../../osd/versions/osd-versions.strategy';
import { getUrlResourceParam } from '../../product/route-helpers/route-helpers';
import { AppStateDeclaration } from '../AppStateDecleration';
import { createOsdRedirectParamsBySource } from './osd.route.helpers';
import { kibanaResourceParamName, kibanaRouteName } from './osd.route.params';
import { viewAlertLastEvents, viewAlertLogs, viewRestoreAccount } from './osd.route.resolvers';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { exploreRouteHelpers } from 'ui/components/Explore/router/router.helpers';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { exploreOnboardingStatesService } from 'ui/components/Explore/state/app-state/explore-onboarding-states.service';

const createOsdStateByApp = ({ resource, appName }) => {
  return {
    name: `dashboard.${appName}`,
    url: `/osd-${appName}`,
    onEnter: trans => {
      const queryParams = createOsdRedirectParamsBySource({
        resource,
        appName,
      });

      trans.router.stateService.go(kibanaRouteName, queryParams, { inherit: false });
    },
  };
};

export const kibana: AppStateDeclaration[] = [
  { ...createOsdStateByApp({ resource: 'discover', appName: 'discover' }) },
  { ...createOsdStateByApp({ resource: 'dashboards/list', appName: 'dashboard' }) },
  { ...createOsdStateByApp({ resource: 'visualize', appName: 'visualize' }) },
  { ...createOsdStateByApp({ resource: 'coldTierSearch', appName: 'coldTierSearch' }) },
  {
    name: 'dashboard.kibana',
    url: `/kibana${getUrlResourceParam(kibanaResourceParamName)}${queryString}`,
    redirectTo: async trans => {
      return {
        state: kibanaRouteName,
        params: trans.params(),
      };
    },
  },
  {
    name: kibanaRouteName,
    url: `/osd${getUrlResourceParam(kibanaResourceParamName)}${queryString}`,
    params,
    views: {
      kibana: {
        component: props => (
          <LazyReact
            {...props}
            lazyImport={() => import('ui/components/Osd/Osd')}
            resolver={({ KibanaComponent }) => KibanaComponent}
          />
        ),
      },
    },
    sticky: true,
    dynamic: true,
    resolve: [
      {
        token: 'switchToAccountId',
        resolveFn: () => userService.getCurrentAccountId(),
      },
    ],
    data: {
      authenticate: true,
      breadcrumb: {
        label: 'OSD',
        calculateLabel: params => kibanaRouteService.extractTitle(params),
      },
    },
  },
  {
    name: 'goto',
    data: { allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value] },
    url: '/goto/:urlId?switchToAccountId',
    resolve: [
      {
        token: 'goToUrl',
        resolveFn: async transition => {
          const url = await kibanaVersion.getGotoUrl(transition.params().urlId);

          const { data: redirectHash } = await httpService.get(url, { returnUrl: true });

          window.location.hash = redirectHash;
        },
        deps: ['$transition$'],
      },
    ],
    params: {
      urlId: null,
    },
  },
  {
    name: 'view-restore-account',
    data: { authenticate: true },
    url: '/view-restore-account?restoreAccount&startTime&endTime&switchToAccountId',
    resolve: [
      {
        token: 'viewRestoreAccount',
        deps: ['$transition$'],
        resolveFn: transition => viewRestoreAccount(transition.params()),
      },
    ],
  },
  {
    name: 'view-triggered-alert',
    data: { authenticate: true, allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value] },
    url: '/view-triggered-alert?definitionId&from&to&switchToAccountId',
    resolve: [
      {
        token: 'viewTriggeredAlertEvents',
        deps: ['$transition$'],
        resolveFn: transition => viewAlertLastEvents(transition.params()),
      },
    ],
  },
  {
    name: 'view-investigate-alert',
    data: { authenticate: true, allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value] },

    url: '/view-in-kibana?triggeredId&switchToAccountId',
    resolve: [
      {
        token: 'viewAlertLogs',
        deps: ['$transition$'],
        resolveFn: async transition => {
          const { exploreAsDefault } = await exploreOnboardingStatesService.get();
          const isExploreAsDefaultFeatureFlagEnabled = featureFlagStateService.isFeatureEnabled('ExploreAsDefault');

          const isSecurityAccount = sessionStateService.session.get().loggedInAccount.type === 'SECURITY_ACCOUNT';

          if (isExploreAsDefaultFeatureFlagEnabled && exploreAsDefault && !isSecurityAccount) {
            return exploreRouteHelpers.viewAlertInExplore(transition.params());
          }

          return viewAlertLogs(transition.params());
        },
      },
    ],
  },
];
