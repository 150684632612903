import { ExplorePageGraphYAxisScaleTypes } from '@logz-pkg/enums';
import { SelectFilter, SelectSizes } from '@logz-ui/styleguide';

type Option = {
  label: string;
  value: ExplorePageGraphYAxisScaleTypes;
};

const options: Option[] = [
  { label: 'Linear', value: ExplorePageGraphYAxisScaleTypes.Linear },
  { label: 'Logarithmic', value: ExplorePageGraphYAxisScaleTypes.Logarithmic },
];

export const GraphScaleSelect = ({ name }) => {
  return (
    <SelectFilter
      name={name}
      minButtonWidth={180}
      size={SelectSizes.Small}
      label={'Y-Axis Scale'}
      showLabel={false}
      showLabelColon={false}
      autoFocus={true}
      options={options}
      clearable={false}
    />
  );
};
