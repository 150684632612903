import { AppStateDeclaration } from '../AppStateDecleration';
import { startSiemTrialRouteName } from './security.routes.names';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { siemOnboardingStateService } from 'ui/state/siem-onboarding.state.service';

export const siemTrialRoutes: AppStateDeclaration[] = [
  {
    name: startSiemTrialRouteName,
    url: '/start-siem-trial',
    data: { authenticate: true },
    redirectTo: () => {
      siemOnboardingStateService.showSiemTrialOnboardingModal();

      return appModeService.goToModeRootState();
    },
  },
];
