import React from 'react';
import { Box, CopyToClipboard, Link, Text, PlainTable } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { Code } from 'ui/components/shared/HighlightJs/Code';
import { InlineCodeBlock } from 'ui/components/shared/HighlightJs/InlineCodeBlock';
import { MarkdownTitle } from 'ui/components/shared/Markdown/Titles';

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const htmlRenderers = function ({
  onLinkClick,
  onCopyCodeClick,
  CustomHeaderComponent,
}: {
  onLinkClick?: (href: string) => void;
  onCopyCodeClick?: () => void;
  CustomHeaderComponent?: React.ElementType<{
    level?: number;
    h5HeaderCounter?: number;
    headersCounter?: number;
  }>;
} = {}) {
  let h5HeaderCounter = 0;
  let headersCounter = -1;

  return {
    paragraph: props => (
      <p>
        <Text paragraph {...props} />
      </p>
    ),
    inlineCode: ({ value }) => (
      <Text code>
        <CopyToClipboard text={value}>
          <InlineCodeBlock>{value}</InlineCodeBlock>
        </CopyToClipboard>
      </Text>
    ),
    table: ({ children, props }) => (
      <Box mb={8}>
        <table {...props}>{children}</table>
      </Box>
    ),
    image: StyledImage,
    tableHead: PlainTable.Head,
    tableBody: PlainTable.Body,
    tableRow: PlainTable.Row,
    tableCell: ({ children, props }) => (
      <PlainTable.Cell {...props}>
        <Text>{children}</Text>
      </PlainTable.Cell>
    ),
    link: props => <Link {...props} onClick={() => onLinkClick?.(props.href)} />,
    listItem: props => (
      <li>
        <Text>{props.children}</Text>
      </li>
    ),
    heading: props => {
      if (props.level === 5) {
        h5HeaderCounter += 1;
      }

      headersCounter += 1;

      return CustomHeaderComponent ? (
        <CustomHeaderComponent
          level={props.level}
          h5HeaderCounter={h5HeaderCounter}
          headersCounter={headersCounter}
          {...props}
        />
      ) : (
        <MarkdownTitle title5Counter={h5HeaderCounter} {...props} />
      );
    },
    code: ({ value, language }) => (
      <Code code={value} language={language ?? 'shell'} onCopy={() => onCopyCodeClick?.()} />
    ),
  };
};
