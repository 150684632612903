import React, { FunctionComponent, useEffect, useState } from 'react';
import { auto } from 'angular';
import { PendingPromiseCache } from '@logz-pkg/utils';
import { Spinner } from '@logz-ui/styleguide';

type IAngularEssentials = { $injector: auto.IInjectorService; angular2react };

const pendingPromise = new PendingPromiseCache();
let angularEssentials: IAngularEssentials;
export const loadAngularApp = async (name = 'unknown'): Promise<IAngularEssentials> => {
  if (angularEssentials) {
    return angularEssentials;
  }

  return pendingPromise.get('angularEssentialsPromise', async () => {
    const { getWebApp, angular2react } = await import('../app');
    const app = getWebApp();

    return new Promise(resolve => {
      app.run([
        '$injector',
        function ($injector) {
          angularEssentials = { $injector, angular2react };
          resolve(angularEssentials);
        },
      ]);
    });
  });
};

type IAngularComponent = {
  controller?: any;
  template?: any;
  bindings?: { [key: string]: string };
  controllerAs?: string;
};

interface ILazyAngularProps {
  name: string;
  bindings?: { [key: string]: any };
  componentGetter(): Promise<IAngularComponent>;
}

type ComponentState = { Component: React.FC<any> | null };

export const LazyAngular: FunctionComponent<ILazyAngularProps> = ({ name, componentGetter, bindings = {} }) => {
  const [component, setComponent] = useState<ComponentState>({ Component: null });

  useEffect(() => {
    (async () => {
      await import('../jquery');

      const [{ $injector, angular2react }, angularComponent] = await Promise.all([
        loadAngularApp(name),
        componentGetter(),
      ]);

      setComponent({ Component: angular2react(name, angularComponent, $injector) });
    })();
  }, [name]);

  const { Component } = component;

  return Component ? <Component {...bindings} /> : <Spinner fullscreen />;
};
