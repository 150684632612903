export const observabilityRoutesNames = {
  k8s360: 'dashboard.k8s360',
  traces: 'dashboard.traces',
};

export const k8sRouteFiltersParams = [
  'cluster',
  'namespace',
  'deployment',
  'rollout',
  'statefulSet',
  'daemonSet',
  'job',
  'node',
  'pod',
];
