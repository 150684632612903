import { GrafanaTheme, KibanaTheme } from '@logz-pkg/enums';
import { Observable } from '@logz-pkg/observable';

// DEV-27501:
// Since we're going for the unified theme, no need to keep track of each theme individually.
// This file will be deleted in the cleanup.
export class ThemeStateService {
  readonly kibanaTheme = new Observable<KibanaTheme>();
  readonly grafanaTheme = new Observable<GrafanaTheme>();

  setKibanaThemeState = (theme: KibanaTheme) => this.kibanaTheme.set(theme);
  setGrafanaThemeState = (theme: GrafanaTheme) => this.grafanaTheme.set(theme);
}

export const themeStateService = new ThemeStateService();
