import React, { useEffect, useMemo } from 'react';
import { Select, SelectSizes } from '@logz-ui/styleguide';
import { AICopilotSubjects } from '@logz-pkg/enums';
import styled, { css } from 'styled-components';
import { useAiDataSource } from '../../state/data-source/data-source.hook';
import { useAiState } from '../../state/ai-state.hook';
import { useAiFetchData } from '../../state/fetch-data/fetch-data.hook';

const StyledSelect = styled(Select)<{ visible?: boolean }>`
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `};
`;

export const AiDataSourceSelect: React.FC<{ isVisible?: boolean }> = ({ isVisible = true }) => {
  const { isDrawerOpen, isSessionExpired } = useAiState();
  const { isLoading } = useAiFetchData();

  const { initializeDataSource, dataSources, dataSource, setDataSource } = useAiDataSource();

  const selectedOption = useMemo(
    () => dataSources.find(option => option.value === dataSource),
    [dataSources, dataSource],
  );

  useEffect(() => {
    if (!selectedOption && isDrawerOpen) {
      initializeDataSource();
    }
  }, [initializeDataSource, isDrawerOpen, dataSources, dataSource]);

  return (
    <StyledSelect
      subject={AICopilotSubjects.SelectDataSource}
      options={dataSources}
      buttonLabel={'My Data'}
      borderless
      size={SelectSizes.Small}
      value={selectedOption}
      label={'Select'}
      onChange={option => setDataSource(option.value)}
      disabled={isLoading || isSessionExpired}
      visible={isVisible}
    />
  );
};
