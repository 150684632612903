import { userService } from '@logz-pkg/frontend-services';
import { AppStateDeclaration } from '../AppStateDecleration';
import { k8sRouteFiltersParams, observabilityRoutesNames } from './observability.routes.names';
import { LazyReact } from 'ui/components/shared/LazyReact';

const groupedFilterParams = k8sRouteFiltersParams.reduce((accum, param) => {
  accum[param] = { array: true, value: [] };

  return accum;
}, {});

const queryParams = [
  'accountId',
  'viewMode',
  'metricType',
  'filters',
  'selected',
  'groupBy',
  'data',
  'quickViewId',
  'quickViewSelectedTab',
  'quickViewType',
  'quickViewCluster',
  'quickViewNamespace',
  'query',
  'start',
  'end',
  'traceId',
  'traceAccountId',
  ...k8sRouteFiltersParams,
];

export const observabilityRoutes: AppStateDeclaration[] = [
  {
    name: observabilityRoutesNames.k8s360,
    url: `/observability/k8s360?${queryParams.join('&')}`,
    params: {
      accountId: null,
      groupBy: 'deployment',
      viewMode: 'map',
      metricType: null,
      namespace: null,
      quickViewId: null,
      quickViewSelectedTab: null,
      query: null,
      quickViewCluster: null,
      quickViewType: null,
      quickViewNamespace: null,
      traceId: null,
      traceAccountId: null,
      ...groupedFilterParams,
    },
    component: props => (
      <LazyReact
        name={'K8S360'}
        {...props}
        lazyImport={() => import('ui/kube360/components/Kube360App')}
        resolver={({ Container }) => Container}
      />
    ),
    dynamic: true,
    resolve: [
      {
        token: 'switchToAccountId',
        resolveFn: () => userService.getCurrentAccountId(),
      },
    ],
    data: { authenticate: true, title: 'Kubernetes 360', breadcrumb: { label: 'Kubernetes 360' }, saveParams: true },
  },
];
