/* eslint-disable @typescript-eslint/ban-ts-comment */
import { colorsV2 } from '@logz-ui/styleguide';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';

export const useHighlightRange = ({ chart }) => {
  const [highlightRangeStart, setHighlightRangeStart] = useState<number | null>(null);
  const [highlightRangeEnd, setHighlightRangeEnd] = useState<number | null>(null);

  useEffect(() => {
    if (chart && !isNil(highlightRangeStart) && !isNil(highlightRangeEnd)) {
      // @ts-ignore
      const plotBand = chart.xAxis[0].plotLinesAndBands.find(band => band.id === 'visible-range');

      if (plotBand) {
        plotBand.options.from = highlightRangeStart;
        plotBand.options.to = highlightRangeEnd;
        plotBand.render();
      } else {
        chart?.xAxis?.[0].addPlotBand({
          id: 'visible-range',
          from: highlightRangeStart,
          to: highlightRangeEnd,
          color: colorsV2.yellow['100'],
          // @ts-ignore
          animation: {
            duration: 1000,
            easing: 'easeOutQuad',
          },
        });
      }
    }
  }, [chart, highlightRangeStart, highlightRangeEnd]);

  return { setHighlightRangeEnd, setHighlightRangeStart };
};
