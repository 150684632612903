import { OneOf } from '@logz-build/typescript';
import { ElasticsearchFieldType } from '@logz-pkg/enums';
import { alertApiService, alertDetailsApiService, authService, LoggerService } from '@logz-pkg/frontend-services';
import {
  AlertEventDetailsModel,
  alertModelManipulator,
  Column,
  ElasticsearchEnhancedFilterModel,
  ElasticsearchFilterModel,
} from '@logz-pkg/models';
import { NotificationService } from '@logz-ui/styleguide';
import { isNil } from 'lodash';
import { smartSelectUtils } from '../Components/SmartSearch/SmartSelect/helpers/utils';
import { DEFAULT_COLUMN_SIZE, GROUPBY_FIELD_NAME } from '../constants';
import { urlParamsService } from '../services/url-params.service';
import { filtersStateService } from '../state/filters-state.service';
import { queryUtils } from '../state/query/query.utils';
import { searchableAccountsService } from '../state/searchable-accounts.service';
import { ExploreSearchParams, exploreSearchParamsService, URLParams } from './router';
import { exploreRouteNames, ExploreSingleLogUrlParams, ExploreUrlParams } from 'states/explore/explore.route';
import { alertsRoutesStates } from 'states/alerts.routes';
import { appRouter } from 'services/router/router.service';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { analyticsService } from 'services/analytics/analytics.service';

const createAlert = () => {
  analyticsService.capture('Explore page', 'Create alert');

  const accounts = exploreSearchParamsService.accounts.get();
  const query = filtersStateService.state.get().find(f => f.type === 'LUCENE')?.value;
  const exploreFilters = filtersStateService.state.get().filter(f => f.type !== 'LUCENE');

  const filters = queryUtils.getDslFilters(exploreFilters);

  return appRouter.stateService.go(alertsRoutesStates.v219New, { filters, query, accountIds: accounts });
};

const viewAlertLastEventsInExplore = async ({ definitionId, from, to, openInNewTab = false }) => {
  try {
    const exploreSearchParams = await getExploreParams({ definitionId, from, to });

    await goToExplore({
      params: { ...exploreSearchParams },
      openInNewTab,
    });
  } catch (err) {
    await handleRouteError(err);
  }
};

const getExploreParams = async ({ definitionId, from, to }): Promise<ExploreSearchParams> => {
  const columns = await getViewAlertColumnsParam(definitionId);

  const query = getViewAlertUrlParam(definitionId);

  const { accountId } = await authService.getUserSession();

  return {
    query,
    columns,
    from,
    to,
    accounts: [accountId.toString()],
  };
};

const getViewAlertColumnsParam = async (alertId: number): Promise<string> => {
  const { subComponents } = await alertApiService.get(alertId);

  const alertExploreParamsList = subComponents.map(({ queryDefinition }) => ({
    groupByName: queryDefinition.groupBy.map(({ name }) => name),
  }));

  const groupByName = [...new Set(alertExploreParamsList.flatMap(exploreParams => exploreParams.groupByName))];

  const columns: Column[] = [
    { id: '_source' },
    ...groupByName.map(x => ({
      id: `logzio-alert-group-ids.${x}`,
    })),
    { id: 'logzio-investigate' },
  ];

  return urlParamsService.columnsToUrlParam(columns);
};

const buildExploreUrl = ({
  params,
  routeName,
}: {
  params: OneOf<[ExploreUrlParams, ExploreSingleLogUrlParams]>;
  routeName?: keyof typeof exploreRouteNames;
}): string => {
  const exploreUrl = appRouter.stateService.href(
    routeName ? exploreRouteNames[routeName] : exploreRouteNames.explore,
    { ...params },
    { inherit: false },
  );

  return exploreUrl;
};

const getViewAlertUrlParam = (definitionId): string => {
  const alertIdField = smartSelectUtils.buildField('logzio-alert-definition-id', ElasticsearchFieldType.String);
  const alertIdFilter = smartSelectUtils.buildEnhancedFilter({ field: alertIdField, value: [definitionId] });

  const typeField = smartSelectUtils.buildField('type', ElasticsearchFieldType.String);
  const typeFilter = smartSelectUtils.buildEnhancedFilter({ field: typeField, value: ['logzio-alerts'] });

  return urlParamsService.filtersToUrlParam([alertIdFilter, typeFilter]);
};

const goToExplore = async ({
  params,
  openInNewTab = false,
}: {
  params: ExploreSearchParams;
  openInNewTab?: boolean;
}) => {
  if (openInNewTab) {
    const url = buildExploreUrl({ params });

    window.open(url, '_blank');
  } else {
    return appRouter.stateService.go(exploreRouteNames.explore, params);
  }
};

const getExploreQueryUrlParam = (filters: ElasticsearchFilterModel[], query: string): string => {
  const enhancedFilters: ElasticsearchEnhancedFilterModel[] = filters.map(({ field, negate, value, type }) => {
    const modifiedType = type === 'IS' ? 'IS_ONE_OF' : type;
    const modifiedValue: any = type === 'IS' ? [value] : value;

    return smartSelectUtils.buildEnhancedFilter({ field, negate, value: modifiedValue, type: modifiedType });
  });

  if (query) {
    const field = smartSelectUtils.buildField(query, ElasticsearchFieldType.Lucene);

    const queryFilter = smartSelectUtils.buildEnhancedFilter({ field, value: query, type: 'LUCENE' });

    enhancedFilters.push(queryFilter);
  }

  return urlParamsService.filtersToUrlParam(enhancedFilters);
};

const getExploreAccountsParam = async (
  accountsToQueryOn: number[],
  shouldQueryOnAllAccounts: boolean,
): Promise<string[]> => {
  const availableAccounts = await searchableAccountsService.fetchAccounts();

  if (shouldQueryOnAllAccounts) {
    return availableAccounts.map(({ id }) => id.toString());
  }

  return accountsToQueryOn.map(accountId => accountId.toString());
};

const viewAlertInExplore = async ({
  triggeredId,
  openInNewTab = false,
  redirectOnFailure = true,
}: {
  triggeredId: string;
  openInNewTab?: boolean;
  redirectOnFailure?: boolean;
}): Promise<void> => {
  try {
    const triggeredAlert: AlertEventDetailsModel = await alertDetailsApiService.get(triggeredId);

    if (!triggeredAlert) {
      NotificationService.danger({
        message: 'Alert details not found.',
        title: 'Investigation failure',
      });

      return;
    }

    const { filters, query, groupBy } = triggeredAlert.query;

    const filtersModel: ElasticsearchFilterModel[] = alertModelManipulator.convertElasticToFilters(filters);

    const queryParam = getExploreQueryUrlParam(filtersModel, query);

    const accounts: string[] = await getExploreAccountsParam(
      triggeredAlert.query.accountIdsToQueryOn,
      triggeredAlert.query.shouldQueryOnAllAccounts,
    );

    const columns: string = urlParamsService.columnsToUrlParam(
      groupBy ? [{ id: '_source' }, { id: Array.isArray(groupBy) ? groupBy[0] : groupBy }] : [{ id: '_source' }],
    );

    const params: ExploreSearchParams = {
      query: queryParam,
      columns,
      accounts,
      from: triggeredAlert.fromDate,
      to: triggeredAlert.toDate,
    };

    await goToExplore({
      params,
      openInNewTab,
    });
  } catch (err) {
    await handleRouteError(err, { redirectOnFailure });
  }
};

const getColumnArrayFromStringArray = (columns: string[]): Column[] => {
  return columns
    .filter(columnName => columnName !== '@timestamp')
    .map(columnName => ({
      id: columnName,
    }));
};

const getColumnArray = (columns: string[] | string): Column[] => {
  if (Array.isArray(columns)) {
    return getColumnArrayFromStringArray(columns);
  }

  return urlParamsService.urlParamToColumns(columns);
};

const addColumn = (column: Column | string, columns: string) => {
  const decodedColumns = urlParamsService.urlParamToColumns(columns);
  let newColumns: Column[];

  const columnExists = decodedColumns.some(c => (typeof column === 'string' ? c.id === column : c.id === column.id));

  if (columnExists) newColumns = decodedColumns;
  else if (typeof column === 'string') newColumns = [...decodedColumns, { id: column, size: DEFAULT_COLUMN_SIZE }];
  else newColumns = [...decodedColumns, column];

  const newColumnsAsParam = urlParamsService.columnsToUrlParam(newColumns);

  return newColumnsAsParam;
};

const removeColumn = (column: Column | string, columns: string) => {
  const decodedColumns = getColumnArray(columns);
  let newColumns: Column[];

  if (typeof column === 'string') newColumns = decodedColumns.filter(c => c.id !== column);
  else newColumns = decodedColumns.filter(c => c.id !== column.id);

  const newColumnsAsParam = urlParamsService.columnsToUrlParam(newColumns);

  return newColumnsAsParam;
};

const removeGroupByColumnIfNull = (groupBy: string | null, columnsArray: Column[]): Column[] => {
  if (isNil(groupBy)) {
    return columnsArray.filter(column => column.id !== GROUPBY_FIELD_NAME);
  }

  return columnsArray;
};

const handleRouteError = async (error: Error, { redirectOnFailure = true } = {}) => {
  LoggerService.logError({ message: `Can't navigate to Explore page: ${error.message}`, error });
  NotificationService.danger({ message: 'Unable to fetch logs' });

  return redirectOnFailure ? appModeService.goToModeRootState() : null;
};

const getGroupByParams = (groupBy: string, columns: string): Pick<URLParams, 'groupBy' | 'columns'> => {
  const newParams = groupBy
    ? { groupBy, columns: addColumn(GROUPBY_FIELD_NAME, columns) }
    : { groupBy: null, columns: removeColumn(GROUPBY_FIELD_NAME, columns) };

  return newParams;
};

export const exploreRouteHelpers = {
  goToExplore,
  createAlert,
  viewAlertLastEventsInExplore,
  getExploreQueryUrlParam,
  getExploreAccountsParam,
  viewAlertInExplore,
  addColumn,
  removeColumn,
  getColumnArray,
  removeGroupByColumnIfNull,
  getColumnArrayFromStringArray,
  buildExploreUrl,
  getGroupByParams,
};
