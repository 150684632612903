import { httpService } from '@logz-pkg/frontend-services/src/index';

const installDashboards = async (ids): Promise<any> => {
  return await httpService.post('/publish/account/content', {
    contentEntryIds: ids,
  });
};

export const grafanaDashboardsService = {
  installDashboards,
};
