import selectSeverityTemplate from './app-severity/select-severity.html?raw';
import upsertNotificationEndpointTemplate from './upsert-notification-endpoint/upsert-notification-endpoint.html?raw';

const selectSeverityId = 'selectSeverityTemplate';
const upsertNotificationEndpointId = 'upsertNotificationEndpointTemplate';

const AddTemplatesToCache = [
  '$templateCache',
  function AddTemplatesToCache($templateCache) {
    $templateCache.put(selectSeverityId, selectSeverityTemplate);
    $templateCache.put(upsertNotificationEndpointId, upsertNotificationEndpointTemplate);
  },
];

export { AddTemplatesToCache, selectSeverityId, upsertNotificationEndpointId };
