import { Badge, Icon, Text } from '@logz-ui/styleguide';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { sessionStateService } from '@logz-pkg/frontend-services';

const StyledBadge = styled(Badge)`
  height: 20px;
  width: 20px;
`;

export const AiChatGeneralUserBadge: React.FC = () => {
  const { userSummary } = sessionStateService.session.get();

  const IsAgentIcon: React.FC = useCallback(() => {
    if (!userSummary?.username?.[0]) {
      return <Badge value={<Icon icon={'user-regular'} color={'white'} size={12} />} color={'royalBlue.1000'} />;
    }

    return (
      <Text size={12} textAlign={'center'}>
        {userSummary?.username?.[0]?.toUpperCase()}
      </Text>
    );
  }, [userSummary]);

  return (
    <StyledBadge size={'s'} value={<IsAgentIcon />} color={'gray.300'} p={0} borderColor={'gray.400'} outline={false} />
  );
};
