import { Field } from 'formik';
import { Select } from '@logz-ui/styleguide';

export const SelectRegionField = ({ options, disabled = false }) => {
  const handleChange = (selected, form, field) => {
    form.setFieldValue(field.name, selected.value);
  };

  return (
    <Field name="region">
      {({ field, form }) => (
        <Select
          options={options}
          label="Bucket region"
          defaultValue={options[0]}
          isClearable={false}
          value={options ? options.find(option => option.value === field.value) : ''}
          onChange={selected => handleChange(selected, form, field)}
          className="log-shipping__region-select"
          disabled={disabled}
          mb={0}
        />
      )}
    </Field>
  );
};
