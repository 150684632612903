import React from 'react';
import { Grid } from '@logz-ui/styleguide';
import { AiChatEmptyState } from '../EmptyState/EmptyState';
import { AiChatMessageManager } from '../Messages/MessageManager';
import { AiFooter } from '../Footer/Footer';
import { AiChatHeader } from '../Header';
import { useAiConversations } from '../../state/conversations/conversations.hook';
import { AiConversations } from '../Conversations/Conversations';

export const AiDrawerContent: React.FC<{ hideClearChat?: boolean }> = ({ hideClearChat = false }) => {
  const { shouldShowConversationsPage } = useAiConversations();

  return (
    <Grid gridTemplateRows={`50px 1fr auto`} height="100%" width={'100%'} rowGap={0}>
      <AiChatHeader hideClearChat={hideClearChat} />
      {shouldShowConversationsPage ? (
        <AiConversations />
      ) : (
        <>
          <AiChatEmptyState />
          <AiChatMessageManager />
        </>
      )}
      <AiFooter />
    </Grid>
  );
};
