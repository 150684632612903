import styled from 'styled-components';

export const HighchartsWrapper = styled.div<{ shouldShowCustomLegend: boolean }>`
  width: 100%;
  height: 100%;
  float: left;

  .highcharts-plot-line {
    cursor: pointer;
  }
`;

export const CustomLegendWrapper = styled.div`
  width: 20%;
  float: left;
`;
