import { colorsV2 } from '@logz-ui/styleguide';
import { HelpQuestionsType } from 'ui/components/AI/types';

export const serviceOverviewHelpQuestions: HelpQuestionsType = {
  start: [
    {
      text: "How's our service health?",
      iconName: 'magnifying-glass-regular',
      color: colorsV2.green[600],
    },
    {
      text: 'Which operations need attention?',
      iconName: 'magnifying-glass-regular',
      color: colorsV2.green[600],
    },
    {
      text: 'Is the service stable post-deployment?',
      iconName: 'magnifying-glass-regular',
      color: colorsV2.green[600],
    },
  ],
};
