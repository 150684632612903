import { UIView } from '@uirouter/react';
import { userService } from '@logz-pkg/frontend-services';
import { params } from '../../osd/osd-route-params';
import { kibanaVersion } from '../../osd/versions/osd-versions.strategy';
import { LazyReact } from '../../../ui/components/shared/LazyReact';
import { AppStateDeclaration } from '../AppStateDecleration';
import { securityThreatsOverviewRouteName, securityThreatsRouteName } from './security.routes.names';

const LazyThreatsComponent = props => (
  <LazyReact
    {...props}
    page={'threats'}
    lazyImport={() => import('./SiemDashboard')}
    resolver={({ SiemDashboard }) => SiemDashboard}
  />
);

export const threatsRoute: AppStateDeclaration[] = [
  {
    name: securityThreatsOverviewRouteName,
    url: `/security/threats/overview`,
    params: {
      ...params,
      forceShowQueryBar: {
        value: 'true',
      },
    },
    views: {
      threats: {
        component: LazyThreatsComponent,
      },
    },
    resolve: {
      userSummary: [async () => (await userService.getSummary()).data],
      baseKibanaPath: () => kibanaVersion.getBaseUrl(),
    },
    sticky: true,
    dynamic: true,
    data: {
      authenticate: true,
      title: 'Threats overview',
      breadcrumb: { label: 'Threats overview', parent: 'dashboard.security' },
    },
  },
  {
    name: securityThreatsRouteName,
    url: '/threats',
    data: { authenticate: true, breadcrumb: { label: 'Threats overview', parent: 'dashboard.security' } },
    component: UIView,
    redirectTo: securityThreatsOverviewRouteName,
  },
];
