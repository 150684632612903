import { AppMode, PLAN_TYPE, ProductKeys } from '@logz-pkg/enums';
import { useEffect, useState } from 'react';
import { useObservable } from '@logz-pkg/observable';
import { telemetryDataStatusService } from '@logz-pkg/frontend-services';
import { TelemetryStatus } from '@logz-pkg/models';
import { IStateParams } from '../Sidenav/item-configs';
import { switchModeRouteName } from '../../../../../app/states/switch-mode.routes';
import { useAccountPlanState, useNavigationState, useSessionState } from 'ui/state/hooks';
import { userHistoryStateService } from 'ui/state/user-history.state.service';

const getItemTargetState = (
  targetStateName: string,
  targetStateParams: IStateParams,
  currentAppMode: AppMode,
  nextAppMode: AppMode,
) => {
  const basicNextState = {
    name: targetStateName ?? '',
    params: targetStateParams,
  };

  if (currentAppMode && nextAppMode && currentAppMode !== nextAppMode) {
    return {
      name: switchModeRouteName,
      params: {
        mode: nextAppMode,
        state: basicNextState.name,
        nextStateParams: basicNextState.params,
        switchToAccount: null,
      },
    };
  }

  return basicNextState;
};
const productToTelemetryStatus: Partial<Record<ProductKeys, keyof TelemetryStatus | null>> = {
  [ProductKeys.Explore]: 'logs',
  [ProductKeys.App360]: 'spmMetrics',
  [ProductKeys.K8S360]: 'k8sMetrics',
  [ProductKeys.Tracing]: 'traces',
  [ProductKeys.Spm]: 'spmMetrics',
};
const useNavItemBadgeState = navItem => {
  const { accountPlan } = useAccountPlanState();
  const history = useObservable(userHistoryStateService.history);
  const telemetryStatus = useObservable(telemetryDataStatusService.status);
  const productPageVisit = history[navItem.key];
  const telemetryStatusForProduct = productToTelemetryStatus[navItem.key];

  if (accountPlan?.type !== PLAN_TYPE.TRIAL) return false;

  if (!telemetryStatusForProduct) return false;

  const telemetryDataForProduct = telemetryStatus[telemetryStatusForProduct];

  if (!telemetryDataForProduct?.sent) return false;

  if (!productPageVisit) return true;

  return new Date(productPageVisit.lastOccur) < new Date(telemetryDataForProduct.timestamp);
};
export const useNavItemState = (navItem, targetMode) => {
  const { appMode } = useSessionState();
  const { hoveredNavItem } = useNavigationState();
  const showBadge = useNavItemBadgeState(navItem);

  const [targetState, setTargetState] = useState({
    name: navItem.targetStateName ?? '',
    params: { ...navItem.targetStateParams, showBadge },
  });

  useEffect(() => {
    setTargetState(
      getItemTargetState(
        navItem.targetStateName,
        { ...navItem.targetStateParams, showBadge },
        appMode?.value as AppMode,
        targetMode,
      ),
    );

    return () => {
      // Set to init/default on cleanup
      setTargetState({
        name: navItem.targetStateName ?? '',
        params: {
          ...navItem.targetStateParams,
          showBadge,
        },
      });
    };
  }, [appMode, hoveredNavItem, showBadge]);

  return targetState;
};
