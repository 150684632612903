import { ProductKeys, SidenavSubjects } from '@logz-pkg/enums';
import { Container, Divider, Flex, Group } from '@logz-ui/styleguide';
import { FunctionComponent, useEffect } from 'react';
import { useObservable } from '@logz-pkg/observable';
import {
  App360SideNavConfigs,
  ISidenavItemConfig,
  SidenavItemConfigs,
  dataHubItemConfig,
  homeItemConfig,
} from '../item-configs';
import { dashboardsHubItemConfig } from '../item-configs/dashboards-hub';
import { GlobalSearch } from './GlobalSearch/GlobalSearch';
import { FixedSidenavItem } from './Item';
import { SettingsItem } from './SettingsItem';
import { HomeSidenavItem } from './HomeDashboard';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { Minifier } from 'ui/components/App/Navigation/Sidenav/Fixed/Minifier';
import { integrationsItemConfig } from 'ui/components/App/Navigation/Sidenav/item-configs/integrations';
import { useNavigationDisplayCondition } from 'ui/components/App/Navigation/utils/use-navigaition-display-condition';
import { ExtendedLogos, Logo } from 'ui/components/shared/Logo/Logo';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { exploreOnboardingStatesService } from 'ui/components/Explore/state/app-state/explore-onboarding-states.service';

interface IFixedSidenavItemsListProps {
  onNavItemHover: (SidenavItemConfig: ISidenavItemConfig) => void;
  currentProduct: ProductKeys;
  isHoveringOnMenu: boolean;
  isMinified: boolean;
  onNavItemLeave?(): void;
  onSpacerHoverChange?(hover: boolean): void;
}

const Spacer = ({ onMouseEnter, onMouseLeave }) => (
  <Flex
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    flex={1}
    width={'100%'}
    subject={SidenavSubjects.CloseMenu}
  />
); // occupies open space, effectively pushing Settings down

export const FixedNavItemList: FunctionComponent<IFixedSidenavItemsListProps> = ({
  onNavItemHover,
  onNavItemLeave,
  currentProduct,
  isHoveringOnMenu,
  isMinified,
  onSpacerHoverChange,
}) => {
  const isExploreAsDefaultFeatureFlagEnabled = featureFlagStateService.isFeatureEnabled('ExploreAsDefault');
  const isUnifiedDashboardsFeatureFlagEnabled = featureFlagStateService.isFeatureEnabled('UnifiedDashboards');

  const onboardingState = useObservable(exploreOnboardingStatesService.state);

  const isExploreSetToDefault = isExploreAsDefaultFeatureFlagEnabled && onboardingState.exploreAsDefault;

  useEffect(() => {
    SidenavItemConfigs[0].targetStateName = isExploreSetToDefault ? 'dashboard.explore' : 'dashboard.osd';
    SidenavItemConfigs[0].key = isExploreSetToDefault ? ProductKeys.Explore : ProductKeys.Logs;
  }, [isExploreSetToDefault]);

  const handleLogoClicked = () => appModeService.goToModeRootState();

  const [itemsToShow] = useNavigationDisplayCondition(SidenavItemConfigs);
  const [app360ItemsToShow] = useNavigationDisplayCondition(App360SideNavConfigs);
  const [dataHubItems] = useNavigationDisplayCondition([dataHubItemConfig]);

  return (
    <Group alignItems={'center'} gap={1} vertical fullHeight fullWidth mx={isMinified && '2px'}>
      <Group gap={2} vertical fullWidth alignItems="center">
        <Logo logo={ExtendedLogos.Logzio} alt={'Logz.io'} mini={isMinified} onClick={handleLogoClicked} />
        <GlobalSearch />
        <HomeSidenavItem navigationItem={homeItemConfig} selected={currentProduct === homeItemConfig.key} />
      </Group>
      <Container fullWidth px={isMinified ? 1 : 3}>
        <Divider color={'gray.700'} size={'m'} my={isMinified && 4} />
      </Container>
      <Group alignItems={'center'} gap={isMinified ? 4 : 1} vertical fullHeight>
        {itemsToShow.map((itemConfig: ISidenavItemConfig) => (
          <div key={itemConfig.key}>
            <FixedSidenavItem
              onHover={onNavItemHover}
              onLeave={onNavItemLeave}
              navigationItem={itemConfig}
              selected={currentProduct === itemConfig.key}
            />
          </div>
        ))}
        <Container fullWidth px={isMinified ? 1 : 3}>
          <Divider color={'gray.700'} size={'m'} />
        </Container>
        {app360ItemsToShow.map((itemConfig: ISidenavItemConfig) => (
          <div key={itemConfig.key}>
            <FixedSidenavItem
              onHover={onNavItemHover}
              onLeave={onNavItemLeave}
              navigationItem={itemConfig}
              selected={currentProduct === itemConfig.key}
            />
          </div>
        ))}
        {isUnifiedDashboardsFeatureFlagEnabled && (
          <FixedSidenavItem
            onHover={onNavItemHover}
            onLeave={onNavItemLeave}
            beta
            navigationItem={dashboardsHubItemConfig}
            selected={currentProduct === dashboardsHubItemConfig.key}
          />
        )}
        {isHoveringOnMenu && <Minifier />}
        <Spacer
          onMouseEnter={() => onSpacerHoverChange && onSpacerHoverChange(true)}
          onMouseLeave={() => onSpacerHoverChange && onSpacerHoverChange(false)}
        />
        {dataHubItems?.length > 0 && (
          <FixedSidenavItem
            navigationItem={dataHubItemConfig}
            onHover={onNavItemHover}
            onLeave={onNavItemLeave}
            selected={currentProduct === ProductKeys.DataHub}
          />
        )}

        <FixedSidenavItem
          navigationItem={integrationsItemConfig}
          onHover={onNavItemHover}
          onLeave={onNavItemLeave}
          selected={currentProduct === ProductKeys.Integrations}
        />
        <SettingsItem
          onHover={onNavItemHover}
          onLeave={onNavItemLeave}
          selected={currentProduct === ProductKeys.Settings}
        />
      </Group>
    </Group>
  );
};
