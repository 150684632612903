import { tracingRouteStates } from './tracing-sampling.routes.names';
import { appRouter } from 'services/router/router.service';
import { jaegerUrlQueryParams } from 'ui/components/Tracing/Jaeger/jaeger.query-params';

type JaegerParams = { [key in typeof jaegerUrlQueryParams[number]]?: string };

export const buildTracingUrl = (params: JaegerParams & { resource?: 'search' }) => {
  const partialUrl = appRouter.stateService.href(
    tracingRouteStates.jaeger,
    {
      ...params,
    },
    { inherit: false, absolute: true },
  );

  return partialUrl;
};

const buildTraceLink = (traceID: string) => {
  const partialUrl = appRouter.stateService.href(
    tracingRouteStates.jaeger,
    {
      resource: `trace/${traceID}`,
    },
    { inherit: false, absolute: true },
  );

  return partialUrl;
};

export const tracingRouteService = {
  buildTracingUrl,
  buildTraceLink,
};
