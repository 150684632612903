import { Flex, Group } from '@logz-ui/styleguide';
import React from 'react';
import { AICopilotChatMessageSubjects } from '@logz-pkg/enums';
import { renderers } from '../General/Markdown';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';
import { AiChatGeneralActionButtons } from 'ui/components/AI/Components/Messages/General/ActionsButtons';
import { MarkdownContent } from 'ui/components/Integrations/Single/Body/Content';

export const AiChatAgentConclusion: React.FC<MessageType> = ({ content, isDoneContent, isLast }) => {
  return (
    <Group
      pr={8}
      ml={10}
      pl={5}
      mt={3}
      flexDirection={'column'}
      fullWidth
      subject={AICopilotChatMessageSubjects.ConclusionMessage}
    >
      <Flex alignItems={'top'}>
        <MarkdownContent markdown={content} elementRenderers={renderers} shouldShowLoader={false} />
      </Flex>
      {isDoneContent && <AiChatGeneralActionButtons content={content} isLast={isLast} />}
    </Group>
  );
};
