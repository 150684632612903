import { Observable, useObservable } from '@logz-pkg/observable';
import { useQuery } from '@tanstack/react-query';
import { aiApiService } from '@logz-pkg/frontend-services';
import { aiConversationsService } from './conversations.service';
import { useSessionState } from 'ui/state/hooks';

const conversationsFilter = new Observable<string>(null);
export const useAiConversations = () => {
  const sessionStateData = useSessionState();
  const accountId = sessionStateData?.loggedInAccount?.id;
  const userId = sessionStateData?.loggedInUser?.id;

  const shouldShowConversationsPage = useObservable(aiConversationsService.shouldShowConversationsPage);

  const {
    isFetching: isLoadingConversations,
    isError: isConversationsError,
    refetch: fetchConversations,
    data: conversations,
  } = useQuery({
    queryKey: ['conversationsList', accountId, userId],
    queryFn: async () => {
      const { results } = await aiApiService.aiGetConversations();

      return results;
    },
    enabled: !!userId && !!accountId,
    refetchOnMount: false,
    staleTime: 50000,
    gcTime: 5000,
  });

  return {
    filter: useObservable(conversationsFilter),
    setFilter: (filter: string) => conversationsFilter.set(filter),
    conversations,
    shouldShowConversationsPage,
    setShouldShowConversationsPage: aiConversationsService.setShouldShowConversationsPage,
    fetchConversations,
    isLoadingConversations,
    isConversationsError,
    setConversation: aiConversationsService.setConversation,
  };
};
