import { Group, Text } from '@logz-ui/styleguide';
import { EmptyStateActionBox } from '../EmptyStateActionBox';

export const GoToDocs = () => {
  return (
    <Group vertical gap={3} fullWidth>
      <Text weight={400} color={'gray.800'}>
        Not sure how your team can benefit from Jaeger?
      </Text>
      <EmptyStateActionBox
        title={'Powering up with Distributed Tracing'}
        contentText={'Why you need Distributed Tracing and how to get started.'}
        variant={'secondary'}
        href={'https://docs.logz.io/user-guide/distributed-tracing/getting-started-tracing/'}
        buttonText={'Go to the docs'}
      />
    </Group>
  );
};
