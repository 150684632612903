import { FunctionComponent, useContext } from 'react';
import { TracingTrialBar } from './TracingTrialBar';
import { OnboardingBarsStateContext } from 'ui/components/App/OnboardingBars';

export const Container: FunctionComponent = () => {
  const { isTracingTrialVisible } = useContext(OnboardingBarsStateContext);

  return isTracingTrialVisible && <TracingTrialBar />;
};

Container.displayName = 'Container';
