import { authService, LoggerService as Logger } from '@logz-pkg/frontend-services';
import { switchModeRouteName } from '../switch-mode.routes';
import {
  metricsEmptyStateRouteName,
  tracingEmptyStateRouteName,
  siemEmptyStateRouteName,
  logsEmptyStateRouteName,
} from '../empty-states.routes';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { appRouter } from 'services/router/router.service';
import { exploreRouteNames } from 'states/explore/explore.route.names';

const emptyStateRoutes = [
  logsEmptyStateRouteName,
  siemEmptyStateRouteName,
  tracingEmptyStateRouteName,
  metricsEmptyStateRouteName,
];

appRouter.transitionService.onStart({}, async $transition => {
  // User isn't authenticated. He's using a shareToken in embedded mode or he will be logged out and redirected to login
  if (!authService.isAuthenticated()) return;

  const toStateName = $transition.$to().name;
  const { stateService } = $transition.router;

  if (
    toStateName.startsWith(switchModeRouteName) ||
    emptyStateRoutes.some(emptyState => toStateName.startsWith(emptyState))
  )
    return;

  if (AppModes.SECURITY && AppModes.OPERATIONS) {
    // DEV-13553: Refactor bootstrap flow
    if (await appModeService.isOperationsMode()) {
      if (toStateName === AppModes.SECURITY.kibanaState) {
        await stateService.go(AppModes.OPERATIONS.kibanaState, $transition.params());
      } else if (toStateName.startsWith(AppModes.SECURITY.rootState)) {
        await stateService.go(AppModes.OPERATIONS.rootState);
      }
    } else {
      if (toStateName.startsWith(AppModes.SECURITY.rootState)) return;

      // Need to remove this line as part of: DEV-20793 in Security case, its redundant to check 'AppModes.OPERATIONS.kibanaState'
      if (toStateName.startsWith(AppModes.OPERATIONS.kibanaState)) return;

      if (Object.values(exploreRouteNames).some(route => toStateName.startsWith(route))) return;

      const { switchToAccountId } = $transition.params();

      if (switchToAccountId) return;

      const allowedAppModesInRoute = $transition.$to()?.data?.allowedAppModes;

      if (allowedAppModesInRoute?.includes(AppModes.SECURITY.value)) return;

      await stateService.go(AppModes.SECURITY.rootState);
    }
  } else {
    Logger.logError({ message: 'AppModes are not defined' });
  }
});
