import { Button, Flex, Group, JsonFormatter, Popover, Text, colors } from '@logz-ui/styleguide';
import React from 'react';
import { opensearchtypes } from '@opensearch-project/opensearch';
import styled from 'styled-components';
import { AiChatGeneralUserBadge } from 'ui/components/AI/Components/Messages/General/UserBadge';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';
import { DateCell } from 'ui/components/Explore/ContentPane/columns/components/DateCell/DateCell';
import { SourceCell } from 'ui/components/Explore/ContentPane/columns/components/SourceCell';

const StyledGroup = styled(Group)`
  &:hover {
    background-color: ${colors.blue[100]};
  }
`;

const Component: React.FC<{ informationLog: opensearchtypes.SearchHit['_source'] }> = ({ informationLog }) => {
  return (
    <Flex p={3} width={'590px'} maxHeight={'312px'} overflow={'auto'}>
      <JsonFormatter maxLines={undefined} json={informationLog} delimiter={<br />} />
    </Flex>
  );
};

export const AiChatAgentUserLog: React.FC<MessageType & { information: opensearchtypes.SearchHit['_source'] }> = ({
  content,
  information,
}) => {
  const informationLog = information;

  return (
    <Popover
      zIndex={100}
      topOffset={-6}
      appendToBody
      placement="bottom"
      Content={{ Component, props: { informationLog } }}
    >
      <Group vertical gap={3} px={8} pt={8} pb={4} fullWidth>
        <Group width={'100%'} flexDirection={'row'}>
          <AiChatGeneralUserBadge />
          <Group width={'100%'} flexDirection={'column'} rowGap={3} mr={8}>
            <Text size={14} color={'royalBlue.600'} weight={600}>
              {content}
            </Text>
          </Group>
        </Group>
        <StyledGroup gap={2} p={2} fullWidth>
          <Flex pr={2} pl={4} width={'130px'}>
            <DateCell value={informationLog['@timestamp']} />
          </Flex>

          <Flex width={'398px'} pr={2} pl={3}>
            <SourceCell value={informationLog} maxLines={2} shouldDisplayMenu={false} />
          </Flex>
          <Flex>
            <Button variant={'tertiary'} icon={'arrow-up-right-and-arrow-down-left-from-center-regular'} />
          </Flex>
        </StyledGroup>
      </Group>
    </Popover>
  );
};
