import { FunctionComponent } from 'react';
import { Drawer } from '@logz-ui/styleguide';
import { GuidedWalkthroughSubject } from '@logz-pkg/enums';
import { DrawerContent } from './DrawerContent';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';

export const GuidedWalkthroughDrawer: FunctionComponent = () => {
  const { isDrawerOpen, allTasks } = useGuidedWalkthrough();

  return (
    <Drawer
      name={'guidedWalkthrough'}
      hideBackdrop
      open={isDrawerOpen}
      direction={'right'}
      subject={GuidedWalkthroughSubject.Drawer}
    >
      <DrawerContent tasks={allTasks} />
    </Drawer>
  );
};
