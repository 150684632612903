import { Observable } from '@logz-pkg/observable';

export class ConfirmLeaveChangesStateService {
  readonly onBeforeUnsubscriber = new Observable<Function>(() => {});
  readonly shouldConfirm = new Observable<boolean>(false);

  setOnBeforeUnsubscribe = (value: Function) => this.onBeforeUnsubscriber.set(value);

  onBeforeUnsubscribe = () => this.onBeforeUnsubscriber.get()?.();

  getShouldConfirm = () => this.shouldConfirm.get();

  setShouldConfirm = (value: boolean) => this.shouldConfirm.set(value);
}

export const confirmLeaveChangesStateService = new ConfirmLeaveChangesStateService();
