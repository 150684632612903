import { useState, useEffect } from 'react';
import { Options } from 'markdown-it';
import { isNil } from 'lodash';

export const useMarkdown = (options: Options) => {
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    (async () => {
      const { default: markdownConstructor } = await import('markdown-it');

      setMarkdown(() => markdownConstructor);
    })();
  }, []);

  return {
    isLoading: isNil(markdown),
    parseMarkdown: !isNil(markdown) && ((content: string) => markdown(options).render(content)),
  };
};
