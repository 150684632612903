import { ElasticsearchFieldModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { plainToClass } from 'class-transformer';

export type FieldMappingsProps = {
  fieldMappings: {
    [k: string]: ElasticsearchFieldModel;
  };
  fieldNames: string[];
  fields: ElasticsearchFieldModel[];
};

class FieldMappingsService {
  state = new Observable<FieldMappingsProps>({ fields: [], fieldMappings: {}, fieldNames: [] });
  isLoading = new Observable(true);

  getField(fieldName: string) {
    return (
      this.state.get().fieldMappings[fieldName] ||
      plainToClass(ElasticsearchFieldModel, {
        type: 'string',
        name: fieldName,
        isAggregatable: false,
      })
    );
  }

  clear = () => {
    this.state.set({ fields: [], fieldMappings: {}, fieldNames: [] });
    this.isLoading.set(true);
  };
}

export const fieldMappingsService = new FieldMappingsService();
