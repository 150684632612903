import React, { useEffect } from 'react';
import { IFrame } from '@logz-ui/styleguide';

interface IVideoProps {
  src: string;
  onPlay?: () => void;
  onPause?: () => void;
}

export const Video: React.FC<IVideoProps> = ({ src, onPlay, onPause }) => {
  useEffect(() => {
    const handleVideoMessage = ({ data, origin }) => {
      if (!origin.includes('.wistia.com')) return;

      try {
        const { method, args } = JSON.parse(data);

        if (method !== 'updateProperties') return;

        if (args[0]?._state === 'playing') return onPlay?.();

        if (args[0]?._state === 'paused') return onPause?.();
      } catch (err) {}
    };

    window.addEventListener('message', handleVideoMessage);

    return () => {
      window.removeEventListener('message', handleVideoMessage);
    };
  }, []);

  return (
    <IFrame
      src={src}
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" // accelerometer; gyroscope; picture-in-picture;
      style={{
        position: 'absolute',
        overflow: 'auto',
        left: 0,
        top: 0,
      }}
    />
  );
};
