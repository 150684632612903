import { colorsV2 } from '@logz-ui/styleguide';
import { HelpQuestionsChatType } from '../AI/types';

export const grafanaHelpQuestions: HelpQuestionsChatType = {
  pro: {
    trends: [
      {
        text: 'Tell me more about this dashboard',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
      {
        text: 'What are the key panels to focus on?',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
      {
        text: 'What trends can we observe here?',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
      {
        text: 'Which metric matters most?',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
    ],
  },
};
