/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/display-name */
import { SecurityRulesStates } from '@logz-pkg/enums';
import { AppStateDeclaration } from '../AppStateDecleration';
import { securityEventManagementRouteName } from './security.routes.names';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { IEventManagerData } from 'ui/components/EventManagement/EventManagement';

const securityEventData: IEventManagerData = {
  modelName: 'rule',
  editStateName: SecurityRulesStates.Edit,
  docLink: 'https://docs.logz.io/user-guide/cloud-siem/siem-event-management.html',
};

export const eventManagementRoutes: AppStateDeclaration[] = [
  {
    name: securityEventManagementRouteName,
    url: '/event-management?search&statuses',
    params: {
      statuses: {
        type: 'string',
        array: true,
        value: [],
      },
    },
    dynamic: true,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/EventManagement/EventManagement')}
        resolver={({ EventManagement }) =>
          () =>
            <EventManagement {...securityEventData} />}
        {...props}
      />
    ),
    data: { authenticate: true, title: 'Event Management', breadcrumb: { label: 'Event Management' } },
  },
];
