import { Title, Button, Group } from '@logz-ui/styleguide';

export const MarkdownTitle = ({ level, children, title5Counter, id }) => {
  if (level === 1)
    return (
      <Title id={id} weight={700} size={26} mt={6} mb={4}>
        {children}
      </Title>
    );

  if (level === 2)
    return (
      <Title id={id} weight={700} size={24} mt={6} mb={4}>
        {children}
      </Title>
    );

  if (level === 3)
    return (
      <Title id={id} weight={700} size={20} mt={6} mb={4}>
        {children}
      </Title>
    );

  if (level === 4)
    return (
      <Title id={id} weight={700} size={16} mt={6} mb={4}>
        {children}
      </Title>
    );

  if (level === 5) {
    return (
      <Group mt={6} mb={4}>
        <Button round>{title5Counter}</Button>
        <Title id={id} weight={700} size={16}>
          {children}
        </Title>
      </Group>
    );
  }

  if (level === 6)
    return (
      <Title id={id} weight={700} size={14} mb={5} mt={4}>
        {children}
      </Title>
    );

  return (
    <Title id={id} weight={500} size={14} mb={1}>
      {children}
    </Title>
  );
};
