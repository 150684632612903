import { Text } from '@logz-ui/styleguide';

export const BetaLabel = () => {
  return (
    <div style={{ position: 'absolute', top: '-7px', right: '4px' }}>
      <Text size={'8px'} color={'yellow.600'}>
        BETA
      </Text>
    </div>
  );
};
