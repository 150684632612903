import { httpService, LoggerService } from '@logz-pkg/frontend-services';
import { Permissions } from '@logz-pkg/enums';
import { DEFAULT_QUERY_FILTERS } from '../dashboard/triggers/table-data-fetcher/query-generator';
import { OPTIMIZER_DEFINITIONS_PAGE_FILTERS_IDS } from '../dashboard/triggers/optimizer-definitions/page-filters-ids';
import { LazyAngular, loadAngularApp } from './LazyAngular';
import { redirectIfFFEnabled, redirectIfNoPermission } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { newOptimizerRouteNames } from './optimizers/optimizers.routes';
import { appRouter } from 'services/router/router.service';

export const params = [...DEFAULT_QUERY_FILTERS, ...Object.values(OPTIMIZER_DEFINITIONS_PAGE_FILTERS_IDS)];
const redirectIfNoEditTriggerPermission = redirectIfNoPermission({ hasPermission: Permissions.TRIGGER });

const redirectIfNewOptimizersFFIsEnabledToDefinition = redirectIfFFEnabled({
  featureFlag: 'NewOptimizersPage',
  toState: newOptimizerRouteNames.definition,
});

const redirectIfNewOptimizersFFIsEnabledToNew = redirectIfFFEnabled({
  featureFlag: 'NewOptimizersPage',
  toState: newOptimizerRouteNames.new,
});

export const optimizersRoutes: AppStateDeclaration[] = [
  {
    name: 'dashboard.triggers.optimizer-definitions',
    url: `/optimizer-definitions?${params.join('&')}`,
    params: {
      from: '0',
      sortBy: 'updatedAt',
      sortOrder: 'DESC',
    },
    component: () => {
      const loadComponent = async () => {
        const [controller, template] = await Promise.all([
          import('../dashboard/triggers/optimizer-definitions/optimizer-definitions.controller'),
          import('../dashboard/triggers/optimizer-definitions/optimizer-definitions.template.html?raw'),
        ]);

        const component = {
          controller: controller.default,
          template: template.default,
          controllerAs: 'vm',
        };

        return component;
      };

      return <LazyAngular name={'angularOptimizerDefinitions'} componentGetter={loadComponent} />;
    },
    data: { authenticate: true, title: 'Optimizer definitions', breadcrumb: { label: 'Optimizer definitions' } },
    resolve: [redirectIfNewOptimizersFFIsEnabledToDefinition, redirectIfNoEditTriggerPermission],
  },
  {
    name: 'dashboard.triggers.optimizer-wizard',
    url: '/optimizer-wizard/:id',
    component: ({ predefinedTags, tags, triggerData }) => {
      const loadComponent = async () => {
        const [controller, template] = await Promise.all([
          import('../dashboard/triggers/optimizer-wizard/alert-wizard/alert-wizard-page.controller'),
          import('../dashboard/triggers/optimizer-wizard/alert-wizard/alert-wizard-page.html?raw'),
        ]);

        const component = {
          controller: controller.default,
          template: template.default,
          controllerAs: 'vm',
          bindings: {
            predefinedTags: '<',
            tags: '<',
            triggerData: '<',
          },
        };

        return component;
      };

      return (
        <LazyAngular
          name={'angularOptimizerWizard'}
          componentGetter={loadComponent}
          bindings={{ predefinedTags, tags, triggerData }}
        />
      );
    },
    data: { authenticate: true, title: 'Create an optimizer', breadcrumb: { label: 'Create an optimizer' } },
    params: {
      // Empty params route to allow $state.go with params invocation for create mode
      id: null,
      query: null,
      filter: null,
      accountIds: null,
    },
    resolve: [
      redirectIfNoEditTriggerPermission,
      redirectIfNewOptimizersFFIsEnabledToNew,
      {
        token: 'triggerData',
        resolveFn: async () => {
          const { $injector } = await loadAngularApp();
          const TriggerWizardService: any = $injector.get('TriggerWizardService');
          let retVal = null;
          const { id, query, filter, accountIds } = appRouter.globals.params;

          if (id && id.length > 0) {
            // edit mode
            retVal = TriggerWizardService.getTriggerDataById(id, 'alerts/').then(({ data }) => {
              data.triggerType = 'ALERT';

              return data;
            });
          } else if (query) {
            // create mode
            retVal = TriggerWizardService.initializeAlertWizardData(query, filter, null, accountIds).then(data => {
              data.triggerType = 'ALERT';

              return data;
            });
          } else {
            // TODO: deppicated local storage usage, remove it once irrelevant.
            // Propagated triggerData from trigger-wizard.service,
            // as part of removing use of local storage refactoring.
            retVal = TriggerWizardService.getData();
          }

          return retVal;
        },
      },
      {
        token: 'tags',
        resolveFn: async () => {
          const { $injector } = await loadAngularApp();
          const AlertTagsApiService: any = $injector.get('AlertTagsApiService');

          return AlertTagsApiService.get();
        },
      },
      {
        token: 'predefinedTags',
        resolveFn: () => {
          return httpService
            .get('/triggers-tags?type=alert')
            .then(data => data)
            .catch(error => {
              LoggerService.logError({
                message: 'There was an error getting tags from graph cms for type alert',
                error,
              });

              return [];
            });
        },
      },
    ],
  },
];
