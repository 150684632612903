import { authService } from '@logz-pkg/frontend-services';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { appRouter } from 'services/router/router.service';

export const initRouterConfigs = () => {
  appRouter.urlService.rules.otherwise(() => {
    if (!authService.isAuthenticated()) return { state: 'login' };

    appModeService.goToModeRootState();
  });
};
