import { useEffect, useState } from 'react';

export const useScrollChat = ({
  shouldAutoScroll = false,
  element,
}: {
  shouldAutoScroll: boolean;
  element: HTMLElement;
}) => {
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);

  const scrollToBottom = () => {
    const container = element?.parentElement;

    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  // Check if user is scrolled to the bottom
  const isUserAtBottom = () => {
    const container = element?.parentElement;

    if (!container) return false;

    const tolarence = 1;

    const isAtBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + tolarence;

    return isAtBottom;
  };

  // Handle user scroll action
  const handleScroll = () => {
    const atBottom = isUserAtBottom();

    if (isAutoScrollEnabled !== atBottom) {
      setIsAutoScrollEnabled(atBottom);
    }
  };

  useEffect(() => {
    const container = element?.parentElement;

    const observer = new MutationObserver(() => {
      if (shouldAutoScroll && isAutoScrollEnabled) {
        scrollToBottom(); // Scroll to bottom if auto-scroll is enabled
      }
    });

    if (container) {
      container.addEventListener('scroll', handleScroll);

      // Observe changes in the DOM (for example, when new messages are added)
      observer.observe(container, { childList: true, subtree: true });
    }

    // Cleanup event listener
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
        observer.disconnect(); // Stop observing
      }
    };
  }, [element, isAutoScrollEnabled]);

  return {
    atBottom: isAutoScrollEnabled,
    scrollToBottom,
  };
};
