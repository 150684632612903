import { dateService } from '@logz-pkg/frontend-services';
import { useObservable } from '@logz-pkg/observable';
import { Tag } from '@logz-ui/styleguide';
import moment from 'moment-timezone';
import { explorePreferencesStateService } from 'ui/components/Explore/state/app-state/explore-preferences-states.service';

export const UtcTag = () => {
  const { timezone } = useObservable(explorePreferencesStateService.state).generalSettings;

  const formattedTimezone = timezone ? timezone : dateService.getBrowserTimeZone();

  const utcOffset = moment.tz(formattedTimezone).utcOffset() / 60;

  const utcText = utcOffset >= 0 ? `UTC+${utcOffset}` : `UTC${utcOffset}`;

  return (
    <Tag size={'s'} variant="secondary">
      {utcText}
    </Tag>
  );
};
