import { createQueryString, assignQueryOptions, getUrlResourceParam } from '../product/route-helpers/route-helpers';
import { tracingRouteStates } from './tracing/tracing-sampling.routes.names';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { jaegerUrlQueryParams } from 'ui/components/Tracing/Jaeger/jaeger.query-params';

export const jaegerResourceParamName = 'resource';
const resourceUrlParam = getUrlResourceParam('resource'); // DEV-20369 Add dynamic to all routes
const params = assignQueryOptions({}, [jaegerResourceParamName, ...jaegerUrlQueryParams]);
const queryString = createQueryString(jaegerUrlQueryParams);

export const jaeger: AppStateDeclaration[] = [
  {
    name: tracingRouteStates.jaeger,
    url: `/jaeger${resourceUrlParam}${queryString}`,
    params,
    views: {
      jaeger: {
        component: props => (
          <LazyReact
            lazyImport={() => import('ui/components/Tracing')}
            resolver={({ TracingContainer }) => TracingContainer}
            {...props}
          />
        ),
      },
    },
    sticky: true,
    dynamic: true,
    data: { authenticate: true, title: 'Jaeger', breadcrumb: { label: 'Jaeger' } },
  },
];
