import { ElasticsearchEnhancedFilterModel } from '@logz-pkg/models';
import { getLuceneFilterField } from '../../SmartSelect/components/OptionsMenu/LuceneQuerySection/LuceneQuerySection';

export const createExceptionAlias = (exceptions, filter) => {
  const filterValues = filter.value;

  if (Array.isArray(filterValues)) {
    const currentExceptions = exceptions.results.filter(result => filterValues.includes(result.id));
    const exceptionsTitles = currentExceptions.map(result => result.title);
    const titlesAlias = exceptionsTitles.join(', ');

    return titlesAlias ? titlesAlias : null;
  }
};

export const formatFieldName = filter => {
  return filter.field.name === '_logzio_logceptions' ? 'Exceptions' : filter.field.name;
};

export const getLuceneFilter = (
  inputValue: string,
  invalid: boolean = false,
  negate: boolean = false,
  isDisabled: boolean = false,
) => {
  const luceneFilter = new ElasticsearchEnhancedFilterModel();

  luceneFilter.type = 'LUCENE';
  luceneFilter.field = getLuceneFilterField(inputValue);
  luceneFilter.value = inputValue;
  luceneFilter.isDisabled = isDisabled;
  luceneFilter.invalid = invalid;
  luceneFilter.negate = negate;

  return luceneFilter;
};

export const stripQuotes = (value: string) => {
  if (value.startsWith('"') && !value.endsWith('"')) {
    return value.slice(1);
  }

  if (!value.startsWith('"') && value.endsWith('"')) {
    return value.slice(0, -1);
  }

  if (value.startsWith('"') && value.endsWith('"')) {
    return value.slice(1, -1);
  }

  return value;
};
