import { researchRoute } from './research.route';
import { summaryRoute } from './summary.route';
import { threatsRoute } from './threats.route';
import { lookupsRoutes } from './lookups.routes';
import { eventManagementRoutes } from './event-management.routes';
import './security.state.hook';
import { AppStateDeclaration } from '../AppStateDecleration';

export const securityRoutes: AppStateDeclaration[] = [
  ...researchRoute,
  ...summaryRoute,
  ...threatsRoute,
  ...lookupsRoutes,
  ...eventManagementRoutes,
];
