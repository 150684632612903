import { Options } from 'highcharts';
import { useMemo } from 'react';
import { IGetOptionsProps } from '../../../types';
import { getDefaultTooltipSettings } from '../../../constants';

type UseTooltipOptions = {
  overrideTooltip: IGetOptionsProps['overrideTooltip'];
  multiPointTooltip: IGetOptionsProps['multiPointTooltip'];
};

export const useTooltipOptions = ({ overrideTooltip, multiPointTooltip }: UseTooltipOptions) => {
  const tooltipOptions: Options['tooltip'] = useMemo(
    () => ({ ...getDefaultTooltipSettings(multiPointTooltip), ...overrideTooltip }),
    [multiPointTooltip, overrideTooltip],
  );

  return tooltipOptions;
};
