import {
  sessionApiService,
  LoggerService,
  configProvider,
  httpService,
  Env,
  authService,
} from '@logz-pkg/frontend-services';
import { waitFor } from 'poll-until-promise';
import { interceptIntercomRequests } from '../../modules/intercom-intercept';
import { appEmbedded } from '../../app/configs/app-embedded';
import { loadScript } from '../../utils/load-static-resources';

let isIntercomAuthorized;

const actionHandler = (...args) => {
  if (!isIntercomAuthorized) return;

  try {
    (window as any).Intercom(...args);
  } catch (e) {
    console.log('intercom error', ...args, e);
  }
};

export const shutdown = () => {
  actionHandler('shutdown');
  isIntercomAuthorized = false;
};

export const update = () => {
  actionHandler('update');
};

const overrideIntercom = async () => {
  const intercomFrame: Partial<HTMLIFrameElement> = await waitFor(
    () => {
      const iframeElement = document.getElementById('intercom-frame');

      if (!iframeElement) throw new Error(`Couldn't get intercom-iframe`);

      return iframeElement;
    },
    { timeout: 5000, interval: 200 },
  );

  const iframeWindow = intercomFrame.contentWindow || intercomFrame.contentDocument;

  interceptIntercomRequests(iframeWindow);
};

const boot = intercomSettings => {
  actionHandler('boot', intercomSettings);
};

export const event = eventName => {
  actionHandler('trackEvent', eventName);
};

export const show = () => {
  actionHandler('show');
};

export const showNewMessage = (message = '') => {
  actionHandler('showNewMessage', message);
};

const getIntercomSettings = async (account, user) => {
  const [{ data: userHash }, { appId }] = await Promise.all([
    httpService.get('/intercom/user-hash'),
    configProvider.getValue('intercom'),
  ]);

  const company = {
    id: account.accountId,
    name: account.accountName,
    plan: account.planType,
    created_at: account?.accountCreatedDate / 1000 || undefined,
  };

  return {
    app_id: appId,
    user_id: user.userId,
    name: user.fullName,
    email: user.username,
    created_at: user?.userRegistered / 1000 || undefined,
    user_hash: userHash,
    company,
  };
};

const register = async () => {
  // shutdown previous active instance
  shutdown();

  try {
    const {
      integrations: {
        intercom: { isDisabled: isInBlacklist },
      },
      userSession,
      userSummary,
    } = await sessionApiService.get();

    if (!Env.configs.intercom) return;

    if (isInBlacklist) return;

    if (authService.isAdmin()) return;

    if (!authService.isAuthenticated()) return;

    if (appEmbedded) return;

    isIntercomAuthorized = true;

    const [intercomSettings] = await Promise.all([
      getIntercomSettings(userSummary, userSession),
      loadScript({ src: '//widget.intercom.io/widget/bkz9ugqv' }),
      import('./intercom/intercom.less'),
    ]);

    await overrideIntercom();

    boot(intercomSettings);
  } catch (error) {
    LoggerService.logError({ message: `Intercom service error while booting - ${error?.message}`, error });
  }
};

export const IntercomService = {
  shutdown,
  update,
  boot,
  event,
  show,
  showNewMessage,
  register,
};
