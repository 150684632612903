import React, { Fragment, isValidElement, useMemo } from 'react';
import { LOGZIO_HIGHLIGHT_TAG } from '../../../constants';

type UseHighlight = {
  highlight: string[] | undefined;
  value: string | string[] | React.JSX.Element;
  style?: React.CSSProperties;
};

export const getHighlightedText = ({ highlight, value, style }: UseHighlight) => {
  if (isValidElement(value)) return [value];

  if (!highlight) return `${Array.isArray(value) ? value.join(', ') : value}`;

  if (Array.isArray(value)) {
    return value.flatMap((v, index) => {
      let isHighlighted = false;
      const elementsToDisplay: React.JSX.Element[] = [];

      highlight.forEach(h => {
        h.split(LOGZIO_HIGHLIGHT_TAG).forEach((child, index) => {
          if (v === child) {
            isHighlighted = true;

            if (index % 2 === 0) {
              elementsToDisplay.push(<Fragment key={`${child}-${index}`}>{child}</Fragment>);
            }

            elementsToDisplay.push(
              <mark style={{ ...style }} key={`${child}-${index}`}>
                {child}
              </mark>,
            );
          }
        });
      });

      if (!isHighlighted) {
        elementsToDisplay.push(<Fragment key={`${v}-${index}`}>{v}</Fragment>);
      }

      if (index !== value.length - 1) {
        elementsToDisplay.push(<span>, </span>);
      }

      return elementsToDisplay;
    });
  }

  return highlight.flatMap(v =>
    v.split(LOGZIO_HIGHLIGHT_TAG).map((child, index) => {
      if (index % 2 === 0) {
        return <Fragment key={`${child}-${index}`}>{child}</Fragment>;
      }

      return (
        <mark style={{ ...style }} key={`${child}-${index}`}>
          {child}
        </mark>
      );
    }),
  );
};

export const useHighlight = ({ highlight, style, value }: UseHighlight) => {
  return useMemo(() => getHighlightedText({ highlight, value, style }), [style, highlight, value]);
};
