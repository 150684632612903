import confirmAggregationDialogTemplate from './alert-wizard/trigger-dialogs/confirm-aggregation-dialog.html?raw';
import triggerSavedDialogTemplate from './alert-wizard/trigger-dialogs/trigger-saved-dialog.html?raw';

const confirmAggregationDialogId = 'confirmAggregationDialogTemplate';
const triggerSavedDialogId = 'triggerSavedDialogTemplate';

const AddTemplatesToCache = [
  '$templateCache',
  function AddTemplatesToCache($templateCache) {
    $templateCache.put(confirmAggregationDialogId, confirmAggregationDialogTemplate);
    $templateCache.put(triggerSavedDialogId, triggerSavedDialogTemplate);
  },
];

export { AddTemplatesToCache, confirmAggregationDialogId, triggerSavedDialogId };
