import { Env, authService } from '@logz-pkg/frontend-services';

export const overrideConsole = (windowInstance = window) => {
  if (windowInstance.location.pathname === '/context.html') return;

  if (authService.isAdmin()) return;

  if (!Env.configs.overrideConsole) return;

  const css = `
        font-family: Roboto+Mono:300,400,500,700;
        font-size: 32px; color: #008ab1;
        font-weight: bold; padding: 10px 20px;
        background-color: #333; border-radius: 5px;
        border: 10px solid #333; border-left-width: 20px;
        border-right-width: 20px;
    `;

  windowInstance.console.clear();
  windowInstance.console.log('%clogz.io', css);
  windowInstance.console.log('');

  if (!windowInstance.console) {
    windowInstance.console = {} as any;
  }

  const methods = ['log', 'debug', 'warn', 'info', 'error', 'group', 'groupEnd', 'groupCollapsed'];

  methods.forEach(method => {
    console[method] = () => {};
  });
};
