import { InjectorProxy } from '@logz-pkg/utils';
import { logError, logWarn } from 'ui/components/ProductIframe/report';

export function injectServicesToWindow(services) {
  if ((window as any).__logzio__) return;

  (window as any).__logzio__ = {
    services: InjectorProxy({
      injector: name => services[name],
      onGetMissingService: logError,
      onGetMissingServiceProperty: logWarn,
      onSetMissingServiceProperty: logWarn,
      onCallMissingServiceMethod: logError,
    }),
  };
}
