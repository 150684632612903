import { AIDataSource } from '@logz-pkg/enums';

export const aiDataSourceList: Record<AIDataSource, { value: string; label: string; disabled?: boolean }> = {
  [AIDataSource.CurrentPageSearch]: { value: AIDataSource.CurrentPageSearch, label: 'Using Search Results' },
  [AIDataSource.CurrentPageAgent]: { value: AIDataSource.CurrentPageAgent, label: 'Using Search Results' },
  [AIDataSource.AllAvailableData]: { value: AIDataSource.AllAvailableData, label: 'All Available Data' },
  [AIDataSource.RCA]: { disabled: true, value: AIDataSource.RCA, label: 'RCA' },
  [AIDataSource.KnowledgeBaseShipping]: { value: AIDataSource.KnowledgeBaseShipping, label: 'User Guide' },
  [AIDataSource.KnowledgeBaseUserGuide]: { value: AIDataSource.KnowledgeBaseUserGuide, label: 'Data Shipping Guide' },
  [AIDataSource.MetricsDashboard]: { value: AIDataSource.MetricsDashboard, label: 'Metrics Dashboard' },
};
