import { CustomIntegrationPagesProducts, CustomIntegrationPages } from '@logz-pkg/enums';

export const integrationsRouteStates = {
  page: 'dashboard.integrations',
  collectors: 'dashboard.integrations.collectors',
  newCollector: 'dashboard.integrations.collectors-new',
  content: 'dashboard.integration',
  customContent: 'dashboard.customIntegration',
  fleet: 'dashboard.integrations.fleet',
  create: 'dashboard.integrations.new',
  edit: 'dashboard.integrations.edit',
  installation: 'dashboard.integrations.installation',
};

export const customPagesImportsMap: Partial<Record<CustomIntegrationPages, () => Promise<any>>> = {
  'Node-js': () => import('ui/components/Integrations/CustomPages/Pages/NodeJs/Container'),
  Java: () => import('ui/components/Integrations/CustomPages/Pages/Java/Container'),
  HTTP: () => import('ui/components/Integrations/CustomPages/Pages/HTTP/Container'),
  'cURL-data': () => import('ui/components/Integrations/CustomPages/Pages/Curl/Container'),
  Python: () => import('ui/components/Integrations/CustomPages/Pages/Python/Container'),
  dotnet: () => import('ui/components/Integrations/CustomPages/Pages/DotNet/Container'),
  Kubernetes: () => import('ui/components/Integrations/CustomPages/Pages/Kubernetes/Container'),
  'Filebeat-data': () => import('ui/components/Integrations/CustomPages/Pages/FileBeat/Container'),
  'Rsyslog-data': () => import('ui/components/Integrations/CustomPages/Pages/RsysLog/Container'),
  'AWS-S3-Bucket': () => import('ui/components/Integrations/CustomPages/Pages/AwsS3Bucket/Container'),
};
const customIntegrationPagesProducts = Object.values(CustomIntegrationPagesProducts).join('|');
const customIntegrationPages = Object.keys(customPagesImportsMap).join('|');

export const integrationsRouteUrls = {
  page: '/integrations',
  collectors: '/collectors',
  content: '/integrations/:content',
  customContent: `/integrations/{content:(?:${customIntegrationPages})}/{product:(?:${customIntegrationPagesProducts})}`,
};
