import { Card, Flex, Group, Text, colors } from '@logz-ui/styleguide';
import React, { useState } from 'react';
import { useAiFeedback } from '../../state/feedback/feedback.hook';
import { aiFeedbackService } from '../../state/feedback/feedback.service';
import { AiFeedbackOtherModal } from './OtherModal';
import { AiFeedbackReasons } from './Reasons';

export const AiFeedbackReasonsPanel: React.FC = () => {
  const [shouldOpen, setShouldOpen] = useState(false);
  const { rate } = useAiFeedback();

  return (
    <>
      <Card p={5} backgroundColor={colors.blue[100]} width={'100%'}>
        <Flex width={'100%'} justifyContent="center" mb={3}>
          <Group vertical gap={3}>
            <Text color={colors.royalBlue[1000]} size={14} weight={600} whiteSpace="pre-wrap" lineHeight={'expanded'}>
              {aiFeedbackService.feedbackTextMap[rate]}
            </Text>
            <AiFeedbackReasons setShouldOpen={setShouldOpen} />
          </Group>
        </Flex>
      </Card>
      <AiFeedbackOtherModal shouldOpen={shouldOpen} setShouldOpen={setShouldOpen} />
    </>
  );
};
