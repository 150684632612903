import { Product } from '@logz-pkg/enums';
import { useObservable } from '@logz-pkg/observable';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { useEffect } from 'react';
import { accountPlanStateService } from '../account-plan.state.service';

interface IUseAccountPlanState {
  onFailure?(): void;
  fresh?: boolean;
}

export const useAccountPlanState = ({ fresh, onFailure }: IUseAccountPlanState = {}) => {
  const accountPlan = useObservable(accountPlanStateService.accountPlan);
  const pendingAccountPlan = useObservable(accountPlanStateService.pendingAccountPlan);

  const { action: fetchAccountPlan, error, isPending } = useAsyncAction(accountPlanStateService.fetch, { onFailure });

  const logsPlan = accountPlan?.products?.getProduct(Product.LogAnalytics);
  const metricsPlan = accountPlan?.products?.getProduct(Product.Metrics);
  const tracingPlan = accountPlan?.products?.getProduct(Product.Tracing);
  const siemPlan = accountPlan?.products?.getProduct(Product.Siem);

  useEffect(() => {
    if (!accountPlan || fresh) {
      fetchAccountPlan();
    }
  }, []);

  return {
    fetch: fetchAccountPlan,
    isPending,
    accountPlan,
    logsPlan,
    metricsPlan,
    tracingPlan,
    siemPlan,
    pendingAccountPlan,
    error,
  };
};
