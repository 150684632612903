import { KibanaQueryUtils } from '@logz-pkg/utils';
import { cacheProvider, httpService, LoggerService as Logger } from '@logz-pkg/frontend-services';
import { OsdVersionsEnum } from '@logz-pkg/enums';
import { getKibanaTheme } from '../osd-theme.service';
import { IKibanaVersion } from './osd-versions.interface';

export class OSD2_0 implements IKibanaVersion {
  async getProductPrefix() {
    return '/osd-2-0/app';
  }

  getUrlQuerySearchPart(query) {
    return Promise.resolve(KibanaQueryUtils.K6Query.compose(query));
  }

  async getVersion() {
    return OsdVersionsEnum.Osd_2_0;
  }

  async getBaseUrl() {
    return 'osd-2-0/app';
  }

  async getGotoUrl(urlId) {
    return `/osd-2-0/goto/${urlId}`;
  }

  async getIndexPatternFields() {
    const reqData = { type: 'index-pattern', per_page: 200 };

    const response = await httpService.get(`/osd-2-0/api/saved_objects/_find`, reqData);

    return JSON.parse(response.data.saved_objects[0].attributes.fields);
  }

  async getElkAppQuery(objectId, objectType) {
    return { size: 1, query: { term: { [`${objectType.toLowerCase()}._logzioOriginalAppId`]: objectId } } };
  }

  async getLuceneSyntaxLink() {
    return 'https://www.elastic.co/guide/en/elasticsearch/reference/7.10/query-dsl-query-string-query.html#query-string-syntax';
  }

  async getIndexPattern() {
    return 'logzioCustomerIndex*';
  }

  async getConfig() {
    const cacheKey = `osd-2-0/${cacheProvider.CacheKeys.OSD_CONFIG}`;

    try {
      const {
        data: { settings },
      } = await cacheProvider.get(cacheKey, () => httpService.get(cacheKey));
      const kibanaTheme = await getKibanaTheme();

      return {
        ...settings,
        'theme:darkMode': { userValue: kibanaTheme },
      };
    } catch (error) {
      Logger.logError({ message: 'Failed to pre-load OSD Config values', error });

      return {};
    }
  }
}
