import { FunctionComponent } from 'react';
import { Group, Text, Link, Button, Icon } from '@logz-ui/styleguide';
import { HeaderSubjects } from '@logz-pkg/enums';
import { HeaderButton } from 'ui/components/App/Navigation/Header/Button';
import { useAiState } from 'ui/components/AI/state/ai-state.hook';
import { useAiAnalytics } from 'ui/components/AI/hooks/analytics.hook';
import { useWhatsNewState } from 'ui/state/hooks';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';
import { whatsNewStateService } from 'ui/state/whats-new.state.service';

export const OpenChat: FunctionComponent = () => {
  const { toggleDrawer, isDrawerOpen } = useAiState();
  const { isWhatsNewOpen } = useWhatsNewState();
  const { isDrawerOpen: isUseGuidedWalkthroughOpen, setIsDrawerOpen: setIsUseGuidedWalkthroughDrawerOpen } =
    useGuidedWalkthrough();
  const { report } = useAiAnalytics();
  const { context } = useAiState();

  const handleOnClick = () => {
    if (isUseGuidedWalkthroughOpen) setIsUseGuidedWalkthroughDrawerOpen(false);

    if (isWhatsNewOpen) whatsNewStateService.isWhatsNewOpen.set(!whatsNewStateService.isWhatsNewOpen.get());

    toggleDrawer(!isDrawerOpen);
    report('Clicked open chat button');
  };

  return (
    <Link disabled={!context} onClick={handleOnClick} subject={HeaderSubjects.OpenChatDrawerButton}>
      <HeaderButton
        active={isDrawerOpen}
        icon={
          <Button variant={'radiant'} round subject={HeaderSubjects.NotificationBadge} size={'s'}>
            <Icon icon={'sparkle-solid'} color={'black'} size={10} />
          </Button>
        }
      >
        <Group gap={1}>
          <Text size={12}>AI Agent</Text>
        </Group>
      </HeaderButton>
    </Link>
  );
};
