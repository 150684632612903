import { Group, Link, Text, ConfirmModal } from '@logz-ui/styleguide';
import { AuthenticationTypes, AwsS3BucketSubject } from '@logz-pkg/enums';
import { stopArchivingModalText } from '../../../../../app/dashboard/tools/archive-and-restore/archive-settings/archive-settings-config';

export const DeleteModal = ({ onConfirm, onClose, authType = AuthenticationTypes.SecretAccess }) => {
  const modalText = {
    title: `Remove S3 bucket configuration?`,
    text: `Your credentials will be removed from Logz.io; logs won't be fetched from the bucket`,
    confirmText: `Remove configuration`,
  };

  return (
    <ConfirmModal
      isOpen
      variant={'danger'}
      title={modalText.title}
      confirmText={modalText.confirmText}
      onConfirm={onConfirm}
      onClose={onClose}
      subject={AwsS3BucketSubject.DeleteBucketModal}
    >
      {authType === AuthenticationTypes.IAM && (
        <>
          <Text>{modalText.text}</Text>
          <Group vertical gap={0}>
            <Text>{stopArchivingModalText.additionalIAMInformation}</Text>
            <Link href="https://docs.logz.io/user-guide/give-aws-access-with-iam-roles/#new-external-id">
              {stopArchivingModalText.migratingRolesLinkText}
            </Link>
          </Group>
        </>
      )}
    </ConfirmModal>
  );
};
