import { Observable } from '@logz-pkg/observable';

class DashboardsStateService {
  favoritesO = new Observable<string[]>([]);

  setFavoritesState = (favorites: string[]) => {
    this.favoritesO.set(favorites);
  };

  addFavorite = (favorite: string) => {
    this.favoritesO.set([...this.favoritesO.get(), favorite]);
  };

  removeFavorite = (favorite: string) => {
    this.favoritesO.set(this.favoritesO.get().filter(fav => fav !== favorite));
  };

  clear = () => {
    this.favoritesO.set([]);
  };
}

export const dashboardsStateService = new DashboardsStateService();
