import type { ParamDeclaration } from '@uirouter/react';
import type { AppStateDeclaration } from '../AppStateDecleration';
import { redirectIfFFDisabled } from '../utils';
import { exploreRouteNames } from './explore.route.names';
import { exploreRouteHelpers } from 'ui/components/Explore/router/router.helpers';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { waitForStatesToLoad } from 'ui/components/Explore/utils/router-utils';
import type { ExploreSearchParams } from 'ui/components/Explore/router/router';
import { DEFAULT_SORT } from 'ui/components/Explore/constants';

export { exploreRouteNames } from './explore.route.names';

export type CommonProps = {
  switchToAccountId?: number;
};

export type ExploreSingleLogBaseParams = { logId?: string | number };

export type ExploreUrlParams = ExploreSearchParams & CommonProps;
export type ExploreSingleLogUrlParams = ExploreSingleLogBaseParams & CommonProps;

export const exploreQueryParams = [
  'query',
  'mode',
  'inputMode',
  'yAxisScale',
  'timeInterval',
  'accounts',
  'isFilterOpen',
  'quickViewLogId',
  'columns',
  'groupBy',
  'from',
  'to',
  'sort',
  'savedSearchId',
  'compareTo',
] as const;

type ExploreQueryParams = typeof exploreQueryParams[number];

export const exploreContextQueryParams = [
  'query',
  'mode',
  'inputMode',
  'yAxisScale',
  'timeInterval',
  'accounts',
  'isFilterOpen',
  'columns',
  'contextLogId',
  'contextBeforeAmount',
  'contextAfterAmount',
] as const;

type ExploreContextQueryParams = typeof exploreContextQueryParams[number];

const redirectIfNewDiscoverFFDisabled = redirectIfFFDisabled({ featureFlag: 'NewDiscover' });

const params: Record<ExploreQueryParams | ExploreContextQueryParams, ParamDeclaration> = {
  compareTo: { value: null },
  groupBy: { value: null },
  query: { value: null },
  mode: { value: 'CLASSIC' },
  inputMode: { value: null },
  yAxisScale: { value: null },
  timeInterval: { value: null },
  accounts: { array: true, value: [] },
  isFilterOpen: { value: 'true' },
  quickViewLogId: { value: null },
  columns: { value: null },
  from: { value: `now-15m` },
  to: { value: 'now' },
  contextLogId: { value: null },
  contextBeforeAmount: { value: null },
  contextAfterAmount: { value: null },
  savedSearchId: { value: null },
  sort: { array: true, value: DEFAULT_SORT },
};

export const exploreRoutes: AppStateDeclaration[] = [
  {
    name: exploreRouteNames.explore,
    url: `/explore?${exploreQueryParams.join('&')}`,
    params,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Explore/Explore')}
        resolver={({ Explore }) => Explore}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Explore',
      breadcrumb: { label: 'Explore' },
      saveParams: true,
    },
    dynamic: true,
    resolve: [redirectIfNewDiscoverFFDisabled, waitForStatesToLoad],
  },

  {
    name: exploreRouteNames.context,
    url: `/explore/context?${exploreContextQueryParams.join('&')}`,
    params: {
      ...params,
      mode: 'SURROUNDING_LOGS',
      isFilterOpen: 'false',
      from: null,
      to: null,
    },
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Explore/Explore')}
        resolver={({ Explore }) => Explore}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Surrounding Logs',
      breadcrumb: { label: 'Surrounding Logs', parent: exploreRouteNames.explore },
      saveParams: true,
    },
    dynamic: true,
    resolve: [redirectIfNewDiscoverFFDisabled, waitForStatesToLoad],
  },
  {
    name: exploreRouteNames.single,
    url: `/explore/log/:logId`,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Explore/Components/SingleLogView/SingleLogView')}
        resolver={({ SingleLogView }) => SingleLogView}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Log view',
      breadcrumb: { label: 'Log view', parent: exploreRouteNames.explore },
      saveParams: true,
    },
    dynamic: true,
  },
  {
    name: 'view-investigate-explore-alert',
    url: `/view-in-explore?triggeredId&switchToAccountId`,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Explore/Explore')}
        resolver={({ Explore }) => Explore}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Explore',
      breadcrumb: { label: 'Explore' },
      saveParams: true,
    },
    dynamic: true,
    resolve: [
      redirectIfNewDiscoverFFDisabled,
      {
        token: 'viewAlertLogs',
        deps: ['$transition$'],
        resolveFn: transition => exploreRouteHelpers.viewAlertInExplore(transition.params()),
      },
    ],
  },
  {
    name: exploreRouteNames.preferences,
    url: `/explore/preferences`,
    params: {},
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Explore/Components/Preferences/Preferences')}
        resolver={({ Preferences }) => Preferences}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Preferences',
      breadcrumb: { label: 'Preferences', parent: exploreRouteNames.explore },
      saveParams: true,
    },
    dynamic: true,
  },
];
