import { saveAs } from 'file-saver';

export const LogzioFileSaver = (name: string, content: string, typeOverride?: string) => {
  const blob = new Blob([content], { type: typeOverride || 'text/plain;charset=utf-8' });

  saveAs(blob, name);
};

export const AngularLogzioFileSaver = function () {
  return LogzioFileSaver;
};
