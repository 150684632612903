import { NotificationService } from '@logz-ui/styleguide';
import { ReactNode } from 'react';
import { IErrorResponse } from '@logz-pkg/frontend-services/src/dal/utilities/types';
import { LoggerService } from '@logz-pkg/frontend-services/src/core/logger/logger.service';

interface IErrorOptions {
  error: Error | IErrorResponse;
  unexpectedErrorLogMessage?: string;
  title?: string;
  subject?: string;
  extra?: any;
  userMessage?: ReactNode;
  log?: boolean;
}

const logError = async (message: string, error: Error | IErrorResponse, extra?: Record<string, unknown>): Promise<any> =>
  LoggerService.logError({ message, error, extra });

const handleUnexpectedError = (
  error: Error | IErrorResponse,
  unexpectedErrorLogMessage?: string,
  title?: string,
  subject?: string,
  extra?: Record<string, unknown>,
  log = true,
): void => {
  if (title && unexpectedErrorLogMessage && subject) {
    if (log) logError(unexpectedErrorLogMessage, error, extra);

    NotificationService.unexpectedError(title, { subject });
  } else {
    LoggerService.logError({
      message: `Error handling service cannot retrieve title / unexpected error msg / subject `,
    });
  }
};

const handleApiError = ({
  error,
  unexpectedErrorLogMessage = 'Unexpected error',
  title,
  subject,
  extra = {},
  userMessage,
  log = true,
}: IErrorOptions): void => {
  extra.appNotificationErrorMessage = title;

  const errorMessage = userMessage ?? error?.message;

  if (errorMessage) {
    const logErrorMessage = `${unexpectedErrorLogMessage}\n${error?.message ?? ''}`;

    NotificationService.danger({ title, message: errorMessage, subject });

    if (log) logError(logErrorMessage, error, extra);

    return;
  }

  handleUnexpectedError(error, unexpectedErrorLogMessage, title, subject, extra, log);
};

const handleUiError = ({ error, unexpectedErrorLogMessage, title, subject, extra }: IErrorOptions): void => {
  handleUnexpectedError(error, unexpectedErrorLogMessage, title, subject, extra);
};

export const ErrorHandlingService = {
  handleApiError,
  handleUiError,
  logError,
};
