import { useObservable } from '@logz-pkg/observable';
import { navigationStateService } from '../navigation.state.service';

export const useNavigationState = () => ({
  toggleSidenav: navigationStateService.toggleSidenav,
  isSidenavOpen: useObservable(navigationStateService.isSidenavOpen),
  setSidenavOpen: navigationStateService.setIsSidenavOpen,
  pageTitle: useObservable(navigationStateService.pageTitle),
  setPageTitle: navigationStateService.setPageTitle,
  hoveredNavItem: useObservable(navigationStateService.hoveredNavItem),
  setHoveredNavItem: navigationStateService.setHoveredNavItem,
  isMinified: useObservable(navigationStateService.isMinified),
  setIsMinified: navigationStateService.setIsMinified,
});
