import { httpService, searchableIndexesService } from '@logz-pkg/frontend-services';
import _ from 'lodash';

const timelessAccountsUrl = '/timeless-indices';

const TIMELESS_INDEX = 'TIMELESS_INDEX';

const getAll = () => httpService.get(timelessAccountsUrl);

const getAllSearchableAccounts = () => searchableIndexesService.getCachedSearchableIndexes();

const getAllSearchableTimelessIndices = () =>
  getAllSearchableAccounts().then(allAccounts => _.filter(allAccounts, account => account.type === TIMELESS_INDEX));

/** *
 * Creates a new timeless account
 * @param timelessAccount - Object, consists of name: String, volumeGb: number, authorizedAccounts: Array<number>
 * @returns {* Promise } - The timeless account that was generated
 */
const create = function create(timelessAccount) {
  const safeTimelessAccount = _getSafeTimelessAccountObject(timelessAccount);

  return httpService
    .post(timelessAccountsUrl, safeTimelessAccount)
    .then(searchableIndexesService.clearSearchableCacheAndNotify);
};

/** *
 * Updates a timeless account
 * @param timelessAccount - Object, consists of name: String, volumeGb: number, authorizedAccounts: Array<number>
 * @returns {* Promise } - A promise that resolves the timeless account that was generated
 */
const update = function update(timelessAccount) {
  const safeTimelessAccount = _getSafeTimelessAccountObject(timelessAccount);

  return httpService
    .put(`${timelessAccountsUrl}/${timelessAccount.id}`, safeTimelessAccount)
    .then(searchableIndexesService.clearSearchableCacheAndNotify);
};

const remove = function remove(timelessId) {
  return httpService
    .delete(`${timelessAccountsUrl}/${timelessId}`)
    .then(searchableIndexesService.clearSearchableCacheAndNotify);
};

const _getSafeTimelessAccountObject = function _getSafeTimelessAccountObject(timelessAccount) {
  if (
    !timelessAccount ||
    !timelessAccount.name ||
    !_.isNumber(timelessAccount.volumeGb) ||
    timelessAccount.volumeGb <= 0
  ) {
    return;
  }

  const authorizedAccounts = timelessAccount.getAuthorizedAccounts();
  const safeTimelessAccountObject = _.pick(timelessAccount, ['name', 'volumeGb']);

  // if no permitted accounts were provided, we apply a blank array
  safeTimelessAccountObject.authorizedAccounts = authorizedAccounts;

  return safeTimelessAccountObject;
};

/** *
 * Get triggers associated with the timeless
 * @param timelessAccount - Object, consists of name: String, volumeGb: number, authorizedAccounts: Array<number>
 * @returns {Promise} - A promise that resolves the timeless triggers
 */
const getTimelessTriggers = function getTimelessTriggers(timelessAccount) {
  return httpService.get(`triggers/target/${timelessAccount.id}`).then(res => res.data);
};

export const timelessAccountsService = {
  timelessAccountsUrl,
  getAll,
  getAllSearchableAccounts,
  getAllSearchableTimelessIndices,
  create,
  update,
  remove,
  getTimelessTriggers,
  _getSafeTimelessAccountObject,
};
