import { useObservable } from '@logz-pkg/observable';
import { siemOnboardingStateService } from '../siem-onboarding.state.service';

export const useSiemOnboardingState = () => {
  const isOnboardingModalOpen = useObservable<boolean>(siemOnboardingStateService.isOnboardingModalOpen);
  const isOnboardingBarOpen = useObservable<boolean>(siemOnboardingStateService.isOnboardingBarOpen);
  const isAccountEligableForSiemTrial = useObservable<boolean>(siemOnboardingStateService.isAccountEligableForSiemTrial);

  return {
    isOnboardingModalOpen,
    showSiemTrialOnboardingModal: siemOnboardingStateService.showSiemTrialOnboardingModal,
    hideSiemTrialOnboardingModal: siemOnboardingStateService.hideSiemTrialOnboardingModal,
    isOnboardingBarOpen,
    showSiemTrialOnboardingBar: siemOnboardingStateService.showSiemTrialOnboardingBar,
    hideSiemTrialOnboardingBar: siemOnboardingStateService.hideSiemTrialOnboardingBar,
    isAccountEligableForSiemTrial,
    setIsAccountEligableForSiemTrial: siemOnboardingStateService.setIsAccountEligableForSiemTrial,
  };
};
