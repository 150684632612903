import { Observable } from '@logz-pkg/observable';
import type { opensearchtypes } from '@opensearch-project/opensearch';
import { FilterCriteria } from '@logz-ui/styleguide';
import { FilterCriteriaMeta, LogMap } from '../types';
import { fieldMappingsService } from './field-mappings.service';

class LogsStateService {
  state = new Observable<opensearchtypes.SearchResponse>();
  isStateInitialized = new Observable<boolean>(false);
  logMap = new Observable(new Map<string, LogMap>());
  isLogMapInitialized = new Observable<boolean>(false);
  totalLogs = new Observable<number>(0);

  private getHits = () => this.state.get()?.hits?.hits || [];

  setState = (value: opensearchtypes.SearchResponse) => this.state.set(value);

  buildLogMap = () => {
    if (fieldMappingsService.isLoading.get() || !this.isStateInitialized.get()) return;

    const newLogsMap = new Map<string, LogMap>();
    const tableLogsLength = this.getHits().length ?? 300;

    const processObject = (obj: unknown) => {
      Object.entries(obj).forEach(([key, value]) => {
        const field = fieldMappingsService.getField(key);

        const formattedValues = Array.isArray(value) ? value : [value];

        const logEntry = newLogsMap.get(key) || { results: [], total: 0, field };
        const resultsMap = new Map(logEntry.results.map(result => [result.id, result]));

        formattedValues.forEach(val => {
          const formattedValue = field.type === 'number' ? Number(val) : val;

          const existingEntry = resultsMap.get(formattedValue);

          if (existingEntry) {
            existingEntry.meta.count += 1;
          } else {
            const newEntry: FilterCriteria<FilterCriteriaMeta> = {
              id: formattedValue,
              type: field.type,
              title: formattedValue,
              meta: { count: 1 },
              desc: '',
            };

            resultsMap.set(formattedValue, newEntry);
            logEntry.results.push(newEntry);
          }
        });

        newLogsMap.set(key, logEntry);
      });
    };

    this.getHits().forEach(hit => hit._source && processObject(hit._source));

    newLogsMap.forEach(logEntry => {
      const { results } = logEntry;

      results.forEach(result => {
        result.desc = `~${((result.meta.count / tableLogsLength) * 100).toFixed(1)}%`;
      });

      logEntry.results.sort((a, b) => b.meta.count - a.meta.count);
      logEntry.total = results.length;
    });

    this.logMap.set(newLogsMap);
    this.isLogMapInitialized.set(true);
  };

  clear = () => {
    this.state.set(undefined);
    this.isStateInitialized.set(false);
    this.logMap.set(new Map<string, LogMap>());
    this.totalLogs.set(0);
    this.isLogMapInitialized.set(false);
  };
}

export const logsStateService = new LogsStateService();
