import { Box, SelectFilter, SelectSizes, Tooltip } from '@logz-ui/styleguide';
import { useMemo } from 'react';
import { dateToMoment, durationStringToTuple, durationToText } from '@logz-pkg/utils';
import { useObservable } from '@logz-pkg/observable';
import { Option } from '@logz-ui/styleguide/components/SearchFilters/types';
import { useExploreMode } from 'ui/components/Explore/hooks/explore-mode.hook';
import { warmTierService } from 'ui/components/Explore/services/warm-tier.service';
import { exploreSearchParamsService } from 'ui/components/Explore/router/router';

const warmTierTooltip = 'The Compare function doesn’t work when the query results include warm tier data';
const values = ['1h', '1d', '1w', '1M'] as const;

export const CompareToSelect = ({ name }) => {
  const { isWarmTier } = useExploreMode();
  const accountIds = useObservable(exploreSearchParamsService.accounts);
  const from = useObservable(exploreSearchParamsService.from);

  const options: Option[] = useMemo(
    () =>
      values.map(value => {
        const [amount, unit] = durationStringToTuple(value);
        const start = dateToMoment(from).subtract(amount, unit);
        const isDisabled = warmTierService.isInWarmTierRange(accountIds, start);

        return {
          label: durationToText(value),
          value,
          isDisabled,
          disabledTooltip: { title: warmTierTooltip, placement: 'top', openDelayInMs: 300 },
        };
      }),
    [accountIds, from],
  );

  return (
    <Tooltip title={isWarmTier ? warmTierTooltip : null}>
      <Box>
        <SelectFilter
          name={name}
          maxLabelWidth={200}
          minLabelWidth={200}
          minButtonWidth={180}
          disabled={isWarmTier}
          size={SelectSizes.Medium}
          label={'Select'}
          showLabel={false}
          showLabelColon={false}
          autoFocus={true}
          options={options}
          clearable={true}
        />
      </Box>
    </Tooltip>
  );
};
