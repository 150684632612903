import { AppMode, ProductKeys } from '@logz-pkg/enums';
import { homeDashboardRouteName } from '../../../../../../app/states/home.routes';
import { ISidenavItemConfig } from './item-configs';
import { ExtendedLogos } from 'ui/components/shared/Logo/Logo';

export const homeItemConfig: ISidenavItemConfig = {
  expandedSidenavTitle: 'Home',
  key: ProductKeys.Home,
  logo: ExtendedLogos.Home,
  mode: AppMode.OPERATIONS,
  targetStateName: homeDashboardRouteName,
  expandedNavGroups: [],
};
