import React, { ComponentType, useMemo } from 'react';
import { waitFor } from 'poll-until-promise';
import { Spinner } from '@logz-ui/styleguide';
import { LoggerService } from '@logz-pkg/frontend-services';

const collectNetworkInfo = () => {
  const nav = navigator as any;
  const connection = nav?.connection || nav?.mozConnection || nav.webkitConnection;

  if (connection) {
    return {
      downlink: connection.downlink,
      effectiveType: connection.effectiveType,
      rtt: connection.rtt,
      userAgent: navigator.userAgent,
      online: navigator.onLine,
    };
  }

  return {};
};

interface LazyReactProps<T> {
  lazyImport: () => Promise<T>;
  fallback?: JSX.Element;
  resolver: (loaded: T) => ComponentType;
}

export function LazyReact<T extends { [key: string]: ComponentType<any> }>({
  lazyImport,
  fallback = <Spinner fullscreen />,
  resolver,
  ...props
}: LazyReactProps<T>) {
  const LoadedComponent = useMemo(
    () =>
      React.lazy(async () => {
        const requestStartTime = performance.now();

        try {
          const importedComponent = await waitFor(
            async () => {
              try {
                const lazyComponent = await lazyImport().then(resolver);

                return lazyComponent;
              } catch (e) {
                // this assumes that the error message that the browser exception will contain this specific text.
                // if not, the url will not be able to parse and we'll get an error on that
                const url = new URL(e.message.replace('Failed to fetch dynamically imported module: ', '').trim());

                url.searchParams.set('t', `${+new Date()}`);

                return import(/* @vite-ignore */ url.href).then(resolver);
              }
            },
            { timeout: 60000, backoffFactor: 2, interval: 1000 },
          );

          return { default: importedComponent };
        } catch (e) {
          const errorCatchTime = performance.now();

          LoggerService.logError({
            error: e,
            message: 'Lazy load failure',
            extra: { ...collectNetworkInfo(), loadTime: requestStartTime - errorCatchTime },
          });

          throw e;
        }
      }),
    [],
  );

  return (
    <React.Suspense fallback={fallback}>
      <LoadedComponent {...props} />
    </React.Suspense>
  );
}
