import { IDBNames } from '@logz-pkg/enums';
import { LoggerService } from '@logz-pkg/frontend-services';

export const removeForbiddenIDBDbs = () => {
  indexedDB.databases().then(dbs => {
    const allowedDbs: string[] = Object.values(IDBNames);

    const forbiddenDbs = dbs.filter(db => !allowedDbs.includes(db.name));

    forbiddenDbs.forEach(db => {
      const request = indexedDB.deleteDatabase(db.name);

      request.onsuccess = () => {
        LoggerService.logInfo({ message: `Previous indexedDB removed successfully`, extra: { dbName: db.name } });
      };

      request.onerror = event => {
        const error = (event?.target as IDBRequest)?.error;

        LoggerService.logError({ message: `Failed to remove previous indexedDB`, error, extra: { dbName: db.name } });
      };
    });
  });
};
