import moment from 'moment-timezone';
import { isUndefined } from 'lodash';
import { accountManagementCollectionApiService, cacheProvider, eventEmitter, EVENTS } from '@logz-pkg/frontend-services';
import { AccountRetentionDetailsModel } from '@logz-pkg/models';
import { RelativeValue, relativeToMoment, isRelativeDate } from '@logz-pkg/utils';
import { Moment } from 'moment';
import { Observable } from '@logz-pkg/observable';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

const ACCOUNT_DETAILS_CACHE_KEY = 'warm-tier-service_account-retention-details';

export type WarmTierDateRange = { start: number; end: number };
export class WarmTier {
  private accounts: AccountRetentionDetailsModel[] = [];
  private isWarmTier: Record<string, boolean> = {};
  private warmTierDateRange: Record<string, WarmTierDateRange> = {};
  private isFFWarmTierEnabled: boolean;
  isSynced = new Observable<boolean | undefined>(undefined);

  constructor() {
    eventEmitter.on(EVENTS.AUTH_LOGOUT, this.clearCache.bind(this));
  }

  async init() {
    this.accounts = await cacheProvider.get(
      ACCOUNT_DETAILS_CACHE_KEY,
      () => accountManagementCollectionApiService.getRetentionDetails(),
      moment.duration(1, 'hour').asSeconds(),
    );
  }

  private getCacheKey(accountIds: string[]): string {
    return accountIds.sort().toString();
  }

  isFFEnabled(): boolean {
    if (!isUndefined(this.isFFWarmTierEnabled)) return this.isFFWarmTierEnabled;

    this.isFFWarmTierEnabled = featureFlagStateService.isFeatureEnabled('WarmTier');

    return this.isFFWarmTierEnabled;
  }

  isActive(accountIds: string[]): boolean {
    if (!this.isFFEnabled() || !this.accounts.length) return false;

    const key = this.getCacheKey(accountIds);

    if (this.isWarmTier[key]) return this.isWarmTier[key];

    this.isWarmTier[key] = this.accounts.some(
      ({ accountId, snapSearchRetentionDays }) =>
        accountIds.includes(accountId.toString()) && typeof snapSearchRetentionDays === 'number',
    );

    return this.isWarmTier[key];
  }

  getWarmTierDateRange(accountIds: string[]): WarmTierDateRange {
    const key = this.getCacheKey(accountIds);

    if (this.warmTierDateRange[key]) return this.warmTierDateRange[key];

    const accounts = this.accounts.filter(({ accountId }) => accountIds.includes(accountId.toString()));

    const hotRetentions = accounts.map(({ retentionDays }) => retentionDays);
    const fullTimeRetentions = accounts.map(
      ({ retentionDays, snapSearchRetentionDays }) => retentionDays + (snapSearchRetentionDays ?? 0),
    );

    const maxFullRetention = Math.max(...fullTimeRetentions);
    const minHotRetention = Math.min(...hotRetentions);

    const start = moment().utcOffset(0).subtract(maxFullRetention, 'days').startOf('day').valueOf();
    const end = moment().utcOffset(0).subtract(minHotRetention, 'days').startOf('day').valueOf();

    this.warmTierDateRange[key] = { start, end };

    return this.warmTierDateRange[key];
  }

  isInWarmTierRange(accountIds: string[], start: number | RelativeValue | Moment): boolean {
    if (!this.isActive(accountIds)) return false;

    const { end: warmEnd } = this.getWarmTierDateRange(accountIds);

    let startMoment: Moment;

    if (moment.isMoment(start)) {
      startMoment = start;
    } else if (isRelativeDate(start)) {
      startMoment = relativeToMoment(start);
    } else {
      startMoment = moment(start as number);
    }

    return startMoment.isSameOrBefore(warmEnd);
  }

  clearCache() {
    this.isWarmTier = {};
    this.warmTierDateRange = {};

    cacheProvider.clear(ACCOUNT_DETAILS_CACHE_KEY);
  }

  async refreshAccounts() {
    cacheProvider.clear(ACCOUNT_DETAILS_CACHE_KEY);
    await this.init();
  }
}

export const warmTierService = new WarmTier();
