import type { Chart, GradientColorObject, PatternObject, Series, TooltipFormatterCallbackFunction } from 'highcharts';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useObservable } from '@logz-pkg/observable';
import { CustomPoint, TooltipComponent } from './types';
import { useGraphContext } from './GraphTheme.provider';

const getTooltipId = (chartId: number) => `custom-tooltip-${chartId}`;

type FormatterContext = {
  x: number;
  series: Series;
  color: string | GradientColorObject | PatternObject;
  y: number;
  colorIndex: number;
  key: string | number;
  percentage: number;
  total: number;
  point?: CustomPoint;
  points?: Omit<FormatterContext, 'points'>[];
};

interface ITooltipPortalProps {
  chart: Chart | null;
  nowHovered?: string;
  children(formatterContext: TooltipComponent): JSX.Element;
}

export const TooltipPortal = ({ chart, children }: ITooltipPortalProps) => {
  const [context, setContext] = useState<TooltipComponent | null>(null);
  const { isEventMenuOpenO, isMenuOpenO } = useGraphContext();
  const [isEventMenuOpen, isMenuOpen] = [useObservable(isEventMenuOpenO), useObservable(isMenuOpenO)];

  useEffect(() => {
    if (!chart?.options) return;

    const formatter: TooltipFormatterCallbackFunction = function () {
      const { x, points: multiPoint, point: singlePoint, series, color, y } = this as FormatterContext;

      const points = multiPoint
        ? multiPoint.map(p => ({
            x: +p.x,
            y: p.y,
            name: p.series.name,
            color: p.color,
            ...(p.point.data && { data: p.point.data }),
          }))
        : [
            {
              x: singlePoint.x,
              y,
              color,
              name: singlePoint?.name ?? series?.name,
              ...(singlePoint.data && { data: singlePoint.data }),
            },
          ];

      setContext({ x: +x, points, nowHovered: chart.hoverPoint.series.name });

      return `<div id="${getTooltipId(chart.index)}"></div>`;
    };

    chart.update({
      tooltip: {
        formatter,
        useHTML: true,
      },
    });
  }, [chart, children]);

  const node = chart && document.getElementById(getTooltipId(chart.index));
  const shouldRender = !isEventMenuOpen && !isMenuOpen && node && context;

  return shouldRender ? ReactDOM.createPortal(children(context), node) : null;
};
