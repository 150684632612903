import moment from 'moment';
import { SeriesOptionsType } from 'highcharts';
import { colorsV2, Group, Text } from '@logz-ui/styleguide';
import React from 'react';
import { AICopilotChatMessageSubjects, HomeDashboardEventType } from '@logz-pkg/enums';
import { Insight } from '@logz-pkg/models';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';
import { HomeDashboardEvent } from 'ui/components/HomeDashboard/Components/types';
import { AiChatGeneralUserBadge } from 'ui/components/AI/Components/Messages/General/UserBadge';
import { AiChatAgentInformation } from 'ui/components/AI/Components/Messages/Agent/Information';

const timeFormat = 'MM/DD/YYYY, h:mm:ss A';

type AiChatAgentInformationManagerType = Omit<MessageType, 'information' | 'type'> & {
  information: HomeDashboardEvent | Insight;
  type: HomeDashboardEventType;
};

export const AiChatAgentInformationManager: React.FC<AiChatAgentInformationManagerType> = ({
  information,
  content,
  type,
}) => {
  if (!information || !['Insights', 'Alerts', 'Exceptions'].includes(type)) return;

  let timeString: string = 'Event date: ';
  const { numberOfOccurrences } = information;
  const badges = numberOfOccurrences ? [`${numberOfOccurrences} logs`] : [];
  const newType = type[type.length - 1] === 's' ? type.slice(0, -1) : type;
  const title: string = `${newType}: ${information.title}`;

  let series: SeriesOptionsType[];

  if (information.tagName) badges.push(information.tagName);

  if (type === 'Insights' || type === 'Alerts') {
    const newInformation = information as HomeDashboardEvent;

    timeString += moment(newInformation.lastTriggered).format(timeFormat);
  } else {
    const newInformation = information as Insight;

    timeString += moment(newInformation.firstOccurrence).format(timeFormat);

    series = newInformation?.datesToHitCountHistogram
      ? [
          {
            type: 'areaspline',
            fillColor: colorsV2.indigo[100],
            name: 'Count',
            color: colorsV2.blue[600],
            lineWidth: 1.5,
            data: newInformation.datesToHitCountHistogram.map(({ bucketTimestamp: x, count: y }) => ({
              x,
              y,
            })),
          },
        ]
      : [];
  }

  return (
    <Group
      width={'100%'}
      flexDirection={'column'}
      gap={3}
      px={8}
      pb={4}
      subject={AICopilotChatMessageSubjects.InformationMessageManager}
    >
      <Group>
        <AiChatGeneralUserBadge />
        <Text size={14} color={'royalBlue.1000'} weight={600}>
          {content}
        </Text>
      </Group>
      <AiChatAgentInformation timeString={timeString} title={title} badges={badges} series={series} />
    </Group>
  );
};
