import { ElasticsearchFieldType } from '@logz-pkg/enums';
import { ElasticsearchFieldModel } from '@logz-pkg/models';
import { ColumnDef } from '@tanstack/react-table';
import { DEFAULT_COLUMN_SIZE, EXCEPTIONS_FIELD_NAME } from '../../constants';
import { fieldMappingsService } from '../../state/field-mappings.service';
import { SearchResultRecord } from '../Table/types';
import { ExpandCell } from './components/ExpandCell';
import { TableCell } from './TableCell';

const INVESTIGATE_FIELD_NAME = 'logzio-investigate';
const SOURCE_FIELD_NAME = '_source';

const columnNameMap = {
  [INVESTIGATE_FIELD_NAME]: 'Investigate',
  [EXCEPTIONS_FIELD_NAME]: 'Exceptions',
  [SOURCE_FIELD_NAME]: 'Source',
};

const noExpandFieldNames = ['_source', 'logzio-investigate', 'exceptions', '@timestamp'];

export const buildColumn = (
  fieldName: string,
  fieldSize: number = DEFAULT_COLUMN_SIZE,
  type: ElasticsearchFieldModel['type'] = ElasticsearchFieldType.String,
): ColumnDef<SearchResultRecord> => ({
  id: fieldName,
  removable: true,
  size: fieldSize,
  minSize: 50,
  header: columnNameMap[fieldName] ?? fieldName,
  enableSorting: Boolean(fieldMappingsService.getField(fieldName).isAggregatable),
  accessorFn: fieldName === '_source' ? row => row._source : row => row._source[fieldName],
  cellExpandRender: !noExpandFieldNames.includes(fieldName)
    ? ({ cell, closePopover }) => <ExpandCell cell={cell} fieldName={fieldName} closePopover={closePopover} />
    : null,
  cell: ({ cell }) => <TableCell cell={cell} fieldType={type} fieldName={fieldName} />,
});
