import { ISessionState, sessionStateService } from '@logz-pkg/frontend-services';
import { useObservable } from '@logz-pkg/observable';
import { useAsyncAction } from '@logz-pkg/react-hooks';

interface IUseSessionState {
  fetchSession: (params?: any) => Promise<void>;
  fetchSessionError: any;
  clearSession: () => void;
}

export type UseSessionState = IUseSessionState & ISessionState;

export const useSessionState = (): UseSessionState => {
  const session = useObservable(sessionStateService.session);
  const { action: fetchSession, error: fetchSessionError } = useAsyncAction(params => sessionStateService.fetch(params));

  return {
    fetchSession,
    fetchSessionError,
    clearSession: sessionStateService.clear,
    ...session,
  };
};
