import { shortUrlService } from '@logz-pkg/frontend-services';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import type { AppStateDeclaration } from 'states/AppStateDecleration';

const goToShortUrlState = () => {
  return {
    data: { allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value] },
    resolve: [
      {
        token: 'goToUrl',
        resolveFn: async transition => {
          const redirectHash = await shortUrlService.getFullUrl(transition.params().urlId);

          window.location.hash = redirectHash;
        },
        deps: ['$transition$'],
      },
    ],
    params: {
      urlId: null,
    },
  };
};

export const shortUrlRoutes: AppStateDeclaration[] = [
  {
    name: 'short-url-goto',
    url: '/short-url/:urlId?switchToAccountId',
    ...goToShortUrlState(),
  },
  {
    name: 'explore-goto',
    url: '/explore/goto/:urlId?switchToAccountId',
    ...goToShortUrlState(),
  },
];
