import { useObservable } from '@logz-pkg/observable';
import { aiFeedbackService } from './feedback.service';

export const useAiFeedback = () => {
  const shouldShowFeedback = useObservable(aiFeedbackService.shouldShowFeedback);
  const rate = useObservable(aiFeedbackService.rate);

  return {
    shouldShowFeedback,
    rate,
    setRate: aiFeedbackService.setRate,
    setShouldShowFeedback: aiFeedbackService.setShouldShowFeedback,
  };
};
