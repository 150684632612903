import { cacheProvider } from '@logz-pkg/frontend-services';
import { Observable } from '@logz-pkg/observable';
import { Crumb, CustomCrumb } from '@logz-ui/styleguide';
import { debounce } from 'lodash';

export type RouteStateParams = Record<string, Record<string, any>>;

const routeStateParamsCacheKey = 'route-state-params';
const routeStateParamsCacheKeyTTL = 60 * 60; // One hour

class BreadcrumbsService {
  readonly crumbs = new Observable<Crumb[]>([]);
  readonly customCrumb = new Observable<CustomCrumb>(null);

  set = debounce((crumbs: Crumb[]) => {
    this.crumbs.set(crumbs);
  }, 150);

  get = () => this.crumbs.get();

  getStateParams = () => cacheProvider.get(routeStateParamsCacheKey) as Promise<RouteStateParams>;

  setStateParams = async ({ state, params }: { state: keyof RouteStateParams; params: RouteStateParams[string] }) => {
    const current = await this.getStateParams();

    cacheProvider.set(routeStateParamsCacheKey, { ...current, [state]: params }, routeStateParamsCacheKeyTTL);
  };

  clear = () => {
    cacheProvider.clear(routeStateParamsCacheKey);
    this.crumbs.set([]);
    this.customCrumb.set(null);
  };

  setCustomBreadcrumb = (crumb: CustomCrumb) => {
    this.customCrumb.set(crumb);
  };
}

export const breadcrumbsService = new BreadcrumbsService();
