import React, { FunctionComponent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Box, Divider, Group, Icon, Popover, Text } from '@logz-ui/styleguide';
import { AccountType, HeaderSubjects } from '@logz-pkg/enums';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { eventEmitter, EVENTS, accountApiService } from '@logz-pkg/frontend-services';
import { AccountModel } from '@logz-pkg/models';
import { AccountSelect } from './AccountSelect';
import { usePinnedAccounts } from './hooks';
import { useSessionState } from 'ui/state/hooks';

const LoggedInAccountContainer = styled(Box)<{ isSingleAccount: boolean }>`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  width: 200px;
  border-radius: 3px;
  transition: border-color 0.1s ease-in-out;

  ${({ isSingleAccount }) =>
    !isSingleAccount &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        border: 1px solid ${({ theme }) => theme.colors.blue[700]};
      }
    `}
`;

const LoggedInAccountText = styled(Text)`
  max-width: 150px;
`;

const accountTypeMap = {
  [AccountType.Owner]: 'Main account',
  [AccountType.Sub]: 'Sub account',
  [AccountType.Security]: 'Security account',
};

interface IComponentProps {
  accountsData: AccountModel[];
  closePopover: () => void;
  pinnedAccounts: number[];
  handlePinChanged: (accountId, pinned) => Promise<void>;
}

const Component: React.FC<IComponentProps> = ({ accountsData, closePopover, pinnedAccounts, handlePinChanged }) => {
  return (
    <AccountSelect
      accounts={accountsData}
      onChange={closePopover}
      pinnedAccounts={pinnedAccounts ? pinnedAccounts : []}
      onPinChange={handlePinChanged}
    />
  );
};

export const SelectAccountButton: FunctionComponent = () => {
  const { loggedInAccount, fetchSession, loggedInUser } = useSessionState();
  const { action: getAccounts, data: accountsData } = useAsyncAction(() => accountApiService.getAll(), {
    initialData: [],
  });

  const isSingleAccount = accountsData?.length <= 1;
  const accountType = accountTypeMap[loggedInAccount?.type] ?? 'Sub account';
  const accountRole = loggedInUser.getHumanReadableRole();
  const { pinnedAccounts, pinAccount, unPinAccount } = usePinnedAccounts();
  const handlePinChanged = async (accountId, pinned) => {
    if (pinned) {
      unPinAccount(accountId);
    } else {
      pinAccount(accountId);
    }
  };

  // Accounts data prefetch for the AccountSelect
  useEffect(() => {
    getAccounts();
  }, [loggedInAccount?.id]);

  useEffect(() => {
    const onSubAccountUpdated = () => {
      getAccounts();
    };
    const onAccountUpdate = () => {
      fetchSession();
    };

    eventEmitter.on(EVENTS.SUB_ACCOUNTS_UPDATED, onSubAccountUpdated);
    eventEmitter.on(EVENTS.ACCOUNT_NAME_UPDATED, onAccountUpdate);
    eventEmitter.on(EVENTS.ACCOUNT_SWITCH_MODE, onAccountUpdate);

    return () => {
      eventEmitter.off(EVENTS.SUB_ACCOUNTS_UPDATED, onSubAccountUpdated);
      eventEmitter.off(EVENTS.ACCOUNT_NAME_UPDATED, onAccountUpdate);
      eventEmitter.off(EVENTS.ACCOUNT_SWITCH_MODE, onAccountUpdate);
    };
  }, []);

  return (
    <Box>
      <Popover
        Content={{
          Component,
          props: { accountsData, pinnedAccounts, handlePinChanged },
        }}
        placement="bottom-start"
        disabled={isSingleAccount}
        wrapChildWithRef
      >
        <LoggedInAccountContainer
          px={2}
          py={1}
          my={1}
          subject={HeaderSubjects.AccountSelectContainer}
          context={'header-account-select'}
          isSingleAccount={isSingleAccount}
        >
          <Group alignItems={'center'} fullWidth justifyContent={'space-between'}>
            <Group vertical gap={1}>
              <LoggedInAccountText ellipsis subject={'logged-in-account-name'}>
                {loggedInAccount?.name}
              </LoggedInAccountText>
              <Group>
                <Text size={12} italic color={'gray.800'} subject={'logged-in-user-role'}>
                  {accountRole}
                </Text>
                <Divider vertical color={'gray.800'} size={'s'} />
                <Text size={12} italic color={'gray.800'} subject={'logged-in-account-type'}>
                  {accountType}
                </Text>
              </Group>
            </Group>
            {!isSingleAccount && <Icon color={'gray.800'} icon={'angle-down-solid'} />}
          </Group>
        </LoggedInAccountContainer>
      </Popover>
    </Box>
  );
};
