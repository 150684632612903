import { Field } from 'formik';
import { Input } from '@logz-ui/styleguide';

export const SecretKeyField = () => (
  <Field name="secretKey">
    {({ field, form }) => (
      <Input
        label="Secret key"
        placeholder="your-secret-key"
        type="password"
        disableAutoComplete={true}
        error={form.touched.secretKey && form.errors.secretKey}
        mb={0}
        {...field}
      />
    )}
  </Field>
);
