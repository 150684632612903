import { ICollectionResponse } from '@logz-build/typescript';
import { aiApiService, discoverInsightsApiService } from '@logz-pkg/frontend-services';
import { InsightsTableRow } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { EXCEPTIONS_FIELD_NAME } from '../constants';
import { exploreSearchParamsService } from '../router/router';
import { queryUtils } from 'ui/components/Explore/state/query/query.utils';
import { filtersStateService } from 'ui/components/Explore/state/filters-state.service';

class ExceptionsService {
  exceptions = new Observable<ICollectionResponse<InsightsTableRow>>({
    results: [],
    total: 0,
  });
  isOnlyNewExceptionsO = new Observable<boolean>(false);
  isLoadingExceptionsO = new Observable<boolean>(false);

  fetchExceptions = async (orderBy: 'COUNT' | 'LAST_SEEN' = 'COUNT', onlyNew = false) => {
    this.isLoadingExceptionsO.set(true);

    const accountIds = exploreSearchParamsService.accounts.get().map(v => +v);
    const from = exploreSearchParamsService.from.get();
    const to = exploreSearchParamsService.to.get();
    const elasticsearchFilters = filtersStateService.state.get();
    const filteredFilters = elasticsearchFilters.filter(filter => !filter.invalid);
    const query = queryUtils.getDslFilters(filteredFilters, queryUtils.formatTimeRange(from, to));

    try {
      const searchResults = await discoverInsightsApiService.search({
        orderBy,
        onlyNew,
        query,
        accountIds,
        from: 0,
        size: 100,
      } as any);

      const currentConversations = await aiApiService.aiGetConversations();

      const searchResultsWithConv = searchResults?.results?.map(exception => {
        const correspondingConversation = currentConversations?.results?.find(conv => conv.rcaId === exception.id);

        return {
          ...exception,
          conv: correspondingConversation,
        };
      });

      this.exceptions.set({
        ...searchResults,
        results: searchResultsWithConv,
      } as ICollectionResponse<InsightsTableRow>);
      filtersStateService.addToDictionary(EXCEPTIONS_FIELD_NAME, {
        label: 'exceptions',
        readonly: true,
        valuesDictionary: searchResults.results.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.title }), {}),
      });
    } catch {
      // TODO: error handing DEV-44614
    }

    this.isLoadingExceptionsO.set(false);
  };

  clear = () => {
    this.exceptions.set({ results: [], total: 0 });
    this.isOnlyNewExceptionsO.set(false);
    this.isLoadingExceptionsO.set(false);
  };
}

export const exceptionsService = new ExceptionsService();
