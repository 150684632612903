import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Link, colors, Icon } from '@logz-ui/styleguide';

const StyledLink = styled(Link)`
  margin: 15px 10px 0 0;
  position: absolute;
  color: ${colors.gray[800]};
  right: 0;
`;

interface IClosedButtonProps {
  subject?: string;
  onClick: () => void;
}

export const CloseButton: FunctionComponent<IClosedButtonProps> = ({ subject = '', onClick }) => (
  <StyledLink subject={subject} onClick={onClick}>
    <Icon icon={'xmark-regular'} />
  </StyledLink>
);
