import * as Yup from 'yup';
import { LogzioSupportedBucketTypes, AuthenticationTypes } from '@logz-pkg/enums';

const BaseBucketSettingsSchema = Yup.object().shape({
  bucket: Yup.string().min(3, 'Must be 3 to 63 characters').max(63, 'Must be 3 to 63 characters').required('Required'),
});

const BaseAWSBucketSettingsSchema = BaseBucketSettingsSchema.shape({
  accessKey: Yup.string()
    .min(16, 'Must be 16 to 128 characters')
    .max(128, 'Must be 16 to 128 characters')
    .required('Required'),
  secretKey: Yup.string().required('Required'),
});

const BaseIAMSettingsSchema = BaseBucketSettingsSchema.shape({
  arn: Yup.string().required('Required'),
});
const cloudTrailIAMSchema = BaseIAMSettingsSchema.shape({
  prefix: Yup.string().required('Required'),
});
const cloudTrailSecretAccessSchema = BaseAWSBucketSettingsSchema.shape({
  prefix: Yup.string().required('Required'),
});
const s3ValidationSchema = {
  logsType: Yup.string()
    .required('Required')
    .test(
      'Only non-supported types',
      ({ value }) =>
        `${value} is a reserved type. Please change the type or configure this bucket from the "${value}" page.`,
      value => {
        const supportedBucketTypes = Object.values(LogzioSupportedBucketTypes);

        return !supportedBucketTypes.includes(value.toLowerCase());
      },
    ),
};

const s3IAMSchema = BaseIAMSettingsSchema.shape(s3ValidationSchema);
const s3SecretAccessSchema = BaseAWSBucketSettingsSchema.shape(s3ValidationSchema);

const validationSchemaMapping = {
  [LogzioSupportedBucketTypes.CloudTrail]: {
    [AuthenticationTypes.IAM]: cloudTrailIAMSchema,
    [AuthenticationTypes.SecretAccess]: cloudTrailSecretAccessSchema,
  },
  [LogzioSupportedBucketTypes.S3]: {
    [AuthenticationTypes.IAM]: s3IAMSchema,
    [AuthenticationTypes.SecretAccess]: s3SecretAccessSchema,
  },
  [AuthenticationTypes.IAM]: BaseIAMSettingsSchema,
  [AuthenticationTypes.SecretAccess]: BaseAWSBucketSettingsSchema,
};

export const getValidationByBucketType = (bucketType, authenticationType) => {
  const schemaByBucketType = validationSchemaMapping[bucketType];

  if (schemaByBucketType) {
    return schemaByBucketType[authenticationType];
  }

  return validationSchemaMapping[authenticationType];
};
