import { ConfirmPageLeaveModalSubject } from '@logz-pkg/enums';
import { Transition } from '@uirouter/react';
import { isNil } from 'lodash';
import { routeStateToVerify } from './config';
import { confirmLeaveChangesStateService } from 'ui/state/confirm-leave-changes-state.service';
import { appRouter } from 'services/router/router.service';
import { dialogService } from 'services/ui-services/dialog.service';

const getStateCriteriaToOpen = () => ({ from: state => routeStateToVerify.includes(state.name) });

interface IHandleTransitionProps {
  currTransition: Transition;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
}

const handleTransition = async ({
  currTransition,
  title = 'Leave this page?',
  description = "Changes you made won't be saved",
  confirmText = 'Discard changes',
  cancelText = 'Keep working',
}: IHandleTransitionProps) => {
  if (confirmLeaveChangesStateService.getShouldConfirm()) {
    const confirmed = await dialogService.confirm({
      title,
      confirmText,
      cancelText,
      subject: ConfirmPageLeaveModalSubject.ConfirmPageLeaveModal,
      text: description,
    });

    if (confirmed) {
      confirmLeaveChangesStateService.onBeforeUnsubscribe();

      const stateName = currTransition.to().name;

      if (!isNil(stateName)) {
        appRouter.stateService.go(stateName as string, currTransition.params());
      }
    }

    // Abort the current transition
    return false;
  }
};

const unsubscribeConfirmation = () => {
  confirmLeaveChangesStateService.onBeforeUnsubscribe();
};

const subscribe = ({ title, description, confirmText, cancelText }: Omit<IHandleTransitionProps, 'currTransition'>) => {
  const unsubscriber = appRouter.transitionService.onBefore(getStateCriteriaToOpen(), currTransition =>
    handleTransition({ currTransition, title, description, confirmText, cancelText }),
  );

  // Un-subscribe the old subscription before subscribing again, to validate that there won't be double subscriptions
  unsubscribeConfirmation();
  confirmLeaveChangesStateService.setOnBeforeUnsubscribe(unsubscriber);

  return unsubscriber;
};

export const confirmLeaveChangesService = {
  subscribe,
  unsubscribeConfirmation,
};
