import { useQuery } from '@tanstack/react-query';

import { elasticsearchValidateQueryApiService } from '@logz-pkg/frontend-services';
import moment from 'moment';

export const useValidation = (value: string, enabled: boolean = true) => {
  const { data = true, isFetching } = useQuery({
    queryKey: [`lucene-validation-${value}`],
    queryFn: async () => {
      const { isValid } = await elasticsearchValidateQueryApiService.validate(value);

      return isValid;
    },
    staleTime: moment.duration(5, 'hours').asMilliseconds(),
    gcTime: moment.duration(5, 'hours').asMilliseconds(),
    enabled,
  });

  if (!enabled) {
    return { isValid: true, isFetching: false };
  }

  return { isValid: data, isFetching };
};
