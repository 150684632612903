import React from 'react';
import { Grid, Text } from '@logz-ui/styleguide';
import styled, { keyframes } from 'styled-components';

const fadeAnimation = keyframes`
    0%, 30% {
      opacity: 0;
    }
   35%, 60% {
      opacity: 1;
    }
    70%, 100% {
      opacity: 0;  
    }
`;

const AnimatedText = styled(Text)`
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
  animation: ${fadeAnimation} 6s linear infinite;
  &:nth-child(2) {
    animation-delay: 2s;
  }
  &:nth-child(3) {
    animation-delay: 4s;
  }
`;

export const LoadingFadeText: React.FC<{ textToDisplay: [string, string, string] }> = ({ textToDisplay }) => {
  return (
    <Grid subject={'grid-animated-faded-text'}>
      {textToDisplay.map(text => (
        <AnimatedText key={text} weight={700} size={14} subject={'animated-faded-text'}>
          {text}
        </AnimatedText>
      ))}
    </Grid>
  );
};
