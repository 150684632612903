import { createElement, ComponentProps, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { UIView } from '@uirouter/react';

interface IProductUIViewProps extends ComponentProps<typeof UIView> {
  show: boolean;
}

const UIViewContent = styled.div<{ show: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 0;
  top: 0;
  z-index: -100;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      z-index: 0;
    `};
`;

export const ProductUIView: FunctionComponent<IProductUIViewProps> = ({ show, ...props }) => (
  <UIView
    {...props}
    render={(component, props) => (
      <UIViewContent show={show} {...(show ? { 'data-is-shown': true } : {})}>
        {createElement(component, props)}
      </UIViewContent>
    )}
  />
);
