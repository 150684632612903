import { AccountType, Product, UserRole } from '@logz-pkg/enums';
import {
  accountManagementCollectionApiService,
  logShippingTokenApiService,
  metricsApiBudgetService,
  sessionStateService,
  tracingAccountsApiService,
} from '@logz-pkg/frontend-services';
import { AccountInfoModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { metricsService } from 'services/metrics/metrics.service';
import { IContentTab } from 'ui/components/Integrations/Markdown/extract-tabs';

class IntegrationsHubStateService {
  readonly logsAccountsDetails = new Observable<AccountInfoModel[]>(null);
  readonly metricsAccountsDetails = new Observable<AccountInfoModel[]>(null);
  readonly tracingAccountsDetails = new Observable<AccountInfoModel[]>(null);
  readonly selectedLogAnalyticsAccountDetails = new Observable<AccountInfoModel | null>(null);
  readonly selectedMetricAccountDetails = new Observable<AccountInfoModel | null>(null);
  readonly selectedTracingAccountDetails = new Observable<AccountInfoModel | null>(null);
  readonly isFetchingAccountsData = new Observable<boolean>(false);
  readonly selectedGlobalTab = new Observable<IContentTab | null>(null);

  fetchAndSetAccountsDetails = async (): Promise<void> => {
    const { loggedInUser } = sessionStateService.session.get();
    const isReadOnlyView = loggedInUser?.role === UserRole.ReadOnly;

    if (!loggedInUser || isReadOnlyView) return;

    this.isFetchingAccountsData.set(true);

    const [logsAccountsDetails, metricsAccountsNamesAndTokens, tracingAccountsNamesAndTokens] = await Promise.all([
      this.getLogsAccountsNamesAndTokens(),
      this.getMetricsAccountsNamesAndTokens(),
      this.getTracingAccountsNamesAndTokens(),
    ]);

    this.logsAccountsDetails.set(logsAccountsDetails);
    this.metricsAccountsDetails.set(metricsAccountsNamesAndTokens);
    this.tracingAccountsDetails.set(tracingAccountsNamesAndTokens);

    this.isFetchingAccountsData.set(false);
  };

  clear = () => {
    this.logsAccountsDetails.set(null);
    this.metricsAccountsDetails.set(null);
    this.tracingAccountsDetails.set(null);
    this.selectedLogAnalyticsAccountDetails.set(null);
    this.selectedMetricAccountDetails.set(null);
    this.selectedTracingAccountDetails.set(null);
    this.isFetchingAccountsData.set(null);
  };

  setSelectedLogAnalyticAccounts = this.selectedLogAnalyticsAccountDetails.set;
  setSelectedGlobalTab = this.selectedGlobalTab.set;

  setSelectedMetricAccounts = this.selectedMetricAccountDetails.set;

  setSelectedTracingAccounts = this.selectedTracingAccountDetails.set;

  private getLogsAccountsNamesAndTokens = async (): Promise<AccountInfoModel[]> => {
    let logsAccountsDetails;
    const { loggedInAccount } = sessionStateService.session.get();

    if (loggedInAccount?.type !== AccountType.Owner) {
      const { token } = await logShippingTokenApiService.getDefaultToken();

      logsAccountsDetails = [
        {
          name: loggedInAccount.name,
          token,
        },
      ];
    } else {
      const accounts = await accountManagementCollectionApiService.getAll();

      logsAccountsDetails = accounts.map(({ account: { accountName: name, accountToken: token } }) => ({
        name,
        token,
      }));
    }

    return logsAccountsDetails;
  };

  private getMetricsAccountsNamesAndTokens = async (): Promise<AccountInfoModel[]> => {
    const hasMetrics = (await metricsService.getState()).hasMetricsAccount;

    if (!hasMetrics) return null;

    const { loggedInAccount } = sessionStateService.session.get();
    const isOwnerAccount = loggedInAccount?.type === AccountType.Owner;

    const metricsAccountsFetcher = isOwnerAccount
      ? () => metricsApiBudgetService.getDetailed().then(data => data.metricsAccounts)
      : metricsService.getAuthorizedMetricsAccounts;

    const metricsAccounts = await metricsAccountsFetcher();

    if (metricsAccounts) {
      const metricsAccountsNamesAndTokens = metricsAccounts.map(({ name, token }) => ({ name, token }));

      return metricsAccountsNamesAndTokens;
    }
  };

  getAndSetMetricsAccountsNamesAndTokens = async (): Promise<void> => {
    this.metricsAccountsDetails.set(await this.getMetricsAccountsNamesAndTokens());
  };

  private getTracingAccountsNamesAndTokens = async (): Promise<AccountInfoModel[]> => {
    const tracingAccounts = await tracingAccountsApiService.getAllTracingAccounts();

    if (!tracingAccounts?.length) return;

    const tracingSpmAccounts = await Promise.all(
      tracingAccounts.map(t =>
        tracingAccountsApiService.getTracingMetricsAccount(t.accountId).then(d => {
          return {
            tracingAccountId: t.accountId,
            ...d,
          };
        }),
      ),
    );

    const tracingAccountsNamesAndTokens = tracingAccounts.map(({ accountName: name, token, accountId }) => {
      const tracingMetricsAccounts = tracingSpmAccounts
        .filter(t => t.tracingAccountId === accountId)
        .map(t => ({ ...t, type: 'SPM' }));

      return {
        name,
        token,
        connectedAccounts: tracingMetricsAccounts.length === 0 ? undefined : tracingMetricsAccounts[0],
      };
    });

    return tracingAccountsNamesAndTokens;
  };

  getAndSetTracingAccountsNamesAndTokens = async (): Promise<void> => {
    this.metricsAccountsDetails.set(await this.getTracingAccountsNamesAndTokens());
  };

  setSelectedAccount = (accountType: Product, data: AccountInfoModel) => {
    if (accountType === Product.LogAnalytics) {
      this.setSelectedLogAnalyticAccounts(data);
    }

    if (accountType === Product.Metrics) {
      this.setSelectedMetricAccounts(data);
    }

    if (accountType === Product.Tracing) {
      this.setSelectedTracingAccounts(data);
    }
  };
}

export const integrationsHubStateService = new IntegrationsHubStateService();
