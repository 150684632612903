import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Group, Icon, InputSearch, Title, Link, colors, Text } from '@logz-ui/styleguide';
import { HeaderSubjects } from '@logz-pkg/enums';

const TopBar = styled.div`
  flex: 0 0 5px;
  background-color: ${colors.yellow[600]};
`;

const Container = styled(Group)`
  border-bottom: 1px solid ${colors.gray[500]};
  text-align: left;
`;

interface IWhatsNewHeaderProps {
  onSearch(value: string): void;
}

export const WhatsNewHeader: FunctionComponent<IWhatsNewHeaderProps> = ({ onSearch }) => (
  <>
    <TopBar />
    <Container vertical p={5} pb={4} gap={3} fullWidth>
      <Title variant={'deprecatedPanel'}>What's New</Title>
      <Group justifyContent={'space-between'} alignItems={'center'} fullWidth>
        <InputSearch subject={HeaderSubjects.WhatsNewSearch} placeholder="Search..." onChange={onSearch} />
        <Link href="https://logz.io/tag/logz-io-features/">
          <Group gap={1}>
            <Text>Latest from Logz.io </Text>
            <Icon icon={'arrow-up-right-from-square-regular'} />
          </Group>
        </Link>
      </Group>
    </Container>
  </>
);
