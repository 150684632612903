import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { dashboardsApiService, projectsApiService, ProjectsModel } from '@logz-pkg/frontend-services';
import { getDefaultHeaders } from '@logz-pkg/frontend-services/src/core/http/request.provider';
import { GlobalSearchResultModel, SearchSource, UnifiedDashboardModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { NotificationService } from '@logz-ui/styleguide';
import type { GlobalDatasourceResource } from '@perses-dev/core';

import { appRouter } from 'services/router/router.service';
import { viewDashboardRouteName } from 'states/dashboards-hub/dashboards-hub.routes';

type ExtendedDatasourceResource = GlobalDatasourceResource & { name: string };

const DEFAULT_DATA: ICollectionResponse<ProjectsModel> = {
  results: [],
  total: 0,
  pagination: { pageNumber: 1, pageSize: 20 },
};

class DashboardsService {
  data = new Observable<ICollectionResponse<ProjectsModel>>(DEFAULT_DATA);
  isLoading = new Observable(true);
  activeDashboard = new Observable<UnifiedDashboardModel | null>(null);
  globalDatasources = new Observable<ExtendedDatasourceResource[]>([]);

  getAllWithDashboards = async () => {
    try {
      this.isLoading.set(true);

      const dashboards = await projectsApiService.getAllDetailed({ withDashboards: true });

      this.data.set({
        results: dashboards,
        total: dashboards.length,
        pagination: { pageNumber: 1, pageSize: dashboards.length },
      });

      return dashboards;
    } catch (error) {
      NotificationService.unexpectedError('Failed to load dashboards');

      return [];
    } finally {
      this.isLoading.set(false);
    }
  };

  getByName = async (name: string, folderId: string, source?: 'grafana') => {
    try {
      this.isLoading.set(true);

      const dashboard = await dashboardsApiService.getOne(name, folderId, source);

      this.activeDashboard.set(dashboard);

      return dashboard;
    } catch (error) {
      NotificationService.unexpectedError('Failed to load dashboard');
    }
  };

  create = async (folderId: string, dashboard: Partial<UnifiedDashboardModel>) => {
    try {
      this.isLoading.set(true);

      const response = await dashboardsApiService.createDashboard(folderId, dashboard);

      this.data.set({
        results: [...(await this.getAllWithDashboards())],
        total: this.data.get().total + 1,
        pagination: this.data.get().pagination,
      });

      NotificationService.success({
        title: 'Dashboard Created',
        message: `Created dashboard "${dashboard?.doc?.metadata?.name || 'Unnamed dashboard'}"`,
      });

      return response;
    } catch (error) {
      NotificationService.unexpectedError('Failed to create dashboard');

      return null;
    } finally {
      this.isLoading.set(false);
    }
  };

  update = async (dashboard: { uid: string; doc?: UnifiedDashboardModel['doc'] }, folderId) => {
    const prevData = this.data.get();
    const prevActiveDashboard = this.activeDashboard.get();
    const isUpdatingActive = prevActiveDashboard?.uid === dashboard.uid;

    if (isUpdatingActive) {
      this.activeDashboard.set({ ...prevActiveDashboard, ...dashboard });
    }

    try {
      const response = await dashboardsApiService.update({ uid: dashboard.uid, doc: dashboard.doc }, folderId);

      await this.getAllWithDashboards();

      NotificationService.success({
        title: 'Dashboard Updated',
        message: `Updated dashboard "${dashboard.doc.metadata.name}"`,
      });

      return response;
    } catch (error) {
      this.activeDashboard.set(prevActiveDashboard);
      this.data.set(prevData);

      NotificationService.unexpectedError('Failed to update dashboard');

      return null;
    }
  };

  remove = async (uid: string, folderId: string, name: string) => {
    const prevData = this.data.get();
    const isDeletingActive = this.activeDashboard.get()?.uid === uid;

    if (isDeletingActive) {
      this.activeDashboard.set(null);
    }

    try {
      await dashboardsApiService.deleteDashboard(uid, folderId);

      NotificationService.success({
        title: 'Dashboard Deleted',
        message: `Deleted dashboard "${name}"`,
      });

      return true;
    } catch (error) {
      this.data.set(prevData);

      NotificationService.unexpectedError('Failed to delete dashboard');

      return false;
    }
  };

  search = async (searchRequest: ISearchRequestObject) => {
    try {
      this.isLoading.set(true);

      const response = await projectsApiService.searchForProjects(searchRequest);

      this.data.set(response);

      return response;
    } catch (error) {
      NotificationService.unexpectedError('Failed to search dashboards');

      return DEFAULT_DATA;
    } finally {
      this.isLoading.set(false);
    }
  };

  searchByTerm = async (searchTerm: string, pageNumber = 1, pageSize = 20) => {
    return this.search({
      filter: { searchTerm },
      pagination: { pageNumber, pageSize },
    });
  };

  getSearchableResults = async (searchTerm: string, maxResults = 100): Promise<GlobalSearchResultModel[]> => {
    if (!searchTerm) return [];

    try {
      this.isLoading.set(true);

      const searchResult = await this.searchByTerm(searchTerm, 1, maxResults);

      const globalSearchResults = [];

      searchResult.results.forEach(({ dashboards }) => {
        dashboards.forEach(dashboard => {
          globalSearchResults.push({
            title: dashboard.doc?.metadata?.name || dashboard.uid || 'Unnamed Dashboard',
            source: SearchSource.UnifiedDashboard,
            id: dashboard.uid,
            state: {
              name: dashboard.uid,
              doc: dashboard.doc,
            },
            link: appRouter.stateService.href(viewDashboardRouteName, {
              dashboardId: dashboard.uid,
              folderId: dashboard.doc?.metadata?.project,
              dashboardTitle: dashboard.doc?.metadata?.name,
            }),
            description: 'Unified Dashboard',
          });
        });
      });

      return globalSearchResults;
    } catch (error) {
      NotificationService.unexpectedError('Failed to search dashboards');

      return [];
    } finally {
      this.isLoading.set(false);
    }
  };

  getFormattedGlobalDatasources = async (): Promise<ExtendedDatasourceResource[]> => {
    try {
      if (this.globalDatasources.get().length > 0) {
        return this.globalDatasources.get();
      }

      this.isLoading.set(true);

      const datasources = (await dashboardsApiService.getGlobalDatasources()) as GlobalDatasourceResource[];
      const headers = { ...getDefaultHeaders('post') };

      const formattedDatasources = datasources.map((ds: any) => {
        const formatted: ExtendedDatasourceResource = {
          ...ds,
          name: ds.metadata.name,
        };

        if (formatted.spec?.plugin?.spec) {
          formatted.spec.plugin.spec.proxy = {
            kind: 'HTTPProxy',
            spec: {
              url: formatted.spec.plugin.spec.directUrl,
              headers,
            },
          };
        }

        return formatted;
      });

      this.globalDatasources.set(formattedDatasources);

      return formattedDatasources;
    } catch (error) {
      NotificationService.unexpectedError('Failed to load datasources');

      return [];
    } finally {
      this.isLoading.set(false);
    }
  };

  move = async (dashboardId: string, dashboardName: string, oldFolderId: string, newFolderId: string) => {
    try {
      this.isLoading.set(true);

      await dashboardsApiService.move(dashboardId, oldFolderId, newFolderId);

      await this.getAllWithDashboards();

      NotificationService.success({
        title: 'Dashboard Moved',
        message: `Moved dashboard "${dashboardName}"`,
      });

      return true;
    } catch (error) {
      NotificationService.unexpectedError('Failed to move dashboard');

      return false;
    } finally {
      this.isLoading.set(false);
    }
  };

  getDashboardsOwnerUsers = async () => dashboardsApiService.getDashboardsOwnerUsers();

  clear = () => {
    this.data.set(DEFAULT_DATA);
    this.activeDashboard.set(null);
    this.globalDatasources.set([]);
  };
}

export const dashboardsService = new DashboardsService();
