import { useCallback, useEffect, useRef, useState } from 'react';
import { IFrame, Spinner } from '@logz-ui/styleguide';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import styled from 'styled-components';
import { UxType } from '@logz-pkg/enums';
import { eventEmitter, EVENTS, LoggerService } from '@logz-pkg/frontend-services';
import { overrideFetch } from '../../../modules/override-fetch';
import { fetchProgressBarInterceptor } from '../../../modules/progressbar-intercept';
import overrideXhr from '../../../modules/override-xhr';
import { overrideConsole } from '../../../modules/override-console';
import { IProductRouteOptions } from '../../../app/product/route-helpers/route-helpers';
import { getLogzioPresets } from './presets';
import { useSyncUrl } from 'ui/components/ProductIframe/sync-url.hook';
import { reportError, reportInfo } from 'ui/components/ProductIframe/report';
import { extractErrorFromHTMLContent, ProductIframeError } from 'ui/components/ProductIframe/ErrorContainer';
import { iframeAttribute, ProductType } from 'ui/components/ProductIframe/types';

export const StyledStretchedLoader = styled(Spinner)`
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: white;
`;

export type ProductIframeProps = {
  /*
    name is used for loading and error messages
   */
  name: string;
  type: ProductType;
  configGetter?(): Promise<any>;
  onPreLoad?(): Promise<void>;
  onLoad?(productWindow): Promise<void>;
  routeOptions: IProductRouteOptions;
  stateParams: {};
  stateName: string;
  stateData?: {};
  loadingMessage?: string;
};

interface LogzioIframe extends HTMLIFrameElement {
  logzio: any;
  logzioPresets: any;
}

export const ProductIframe = ({
  name,
  type,
  configGetter,
  onPreLoad,
  onLoad,
  routeOptions,
  stateParams,
  stateName,
  stateData,
  loadingMessage = `Loading ${name}...`,
}: ProductIframeProps) => {
  const [error, setError] = useState<Error>(null);
  const iframeDom = useRef<LogzioIframe>();
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [isIframeReloading, setIsIframeReLoading] = useState(false);

  const onProductResolve = () => setIsIframeLoading(false);

  const {
    data: presets,
    isPending: isLoadingPresets,
    action: getPresets,
  } = useAsyncAction(() => getLogzioPresets({ stateName, onProductResolve, configGetter, onPreLoad, stateData }), {
    onFailure: logzioPresetsError => {
      LoggerService.logError({
        message: `Error while loading logzio presets for iframe ${name}`,
        error: logzioPresetsError,
        uxType: UxType.IN_PAGE,
      });
      setError(logzioPresetsError);
    },
  });

  const { src, clearRoute, isStateActive } = useSyncUrl({
    presets,
    routeOptions,
    initialStateParams: stateParams,
    productIframeDom: iframeDom.current,
    appWindow: window,
    stateName,
  });

  const onIframeLoad = () => {
    try {
      const {
        contentWindow: { window: productWindow },
        contentDocument,
      } = iframeDom.current;

      if ((productWindow as any)?.logzio?.productLoadedFlag) {
        presets.productLoaded.resolve();
      }

      (productWindow as any).logzio = presets;

      const iframeError = extractErrorFromHTMLContent(contentDocument?.documentElement?.innerText);

      if (iframeError) throw iframeError;

      onLoad?.(productWindow);

      overrideFetch(productWindow, stateName);
      overrideXhr(productWindow);
      overrideConsole(productWindow);
      fetchProgressBarInterceptor.subscribe({ id: stateName, targetWindow: productWindow });
      reportInfo(stateName, `onIframeLoad Done`);
    } catch (e) {
      reportError(stateName, `Error on iframeOnLoad ${e.message}`, e);
      setError(e);
    }
  };

  const onRefChange = useCallback(
    (ref: LogzioIframe) => {
      if (!ref) return;

      iframeDom.current = ref;
      iframeDom.current.setAttribute(iframeAttribute[type], '');
      iframeDom.current.setAttribute('product-iframe', '');
      iframeDom.current.setAttribute('id', `${stateName}.iframe`);
      iframeDom.current.setAttribute('name', `${stateName}.iframe`);

      // Synchronous access form iFrame via window.frameElement.logzio
      ref.logzio = presets;
      ref.logzioPresets = presets;
    },
    [presets, type, stateName],
  );

  const onIframeError = useCallback(iframeLoadError => {
    reportInfo('onIframeError', { error: iframeLoadError });
    setError(iframeLoadError);
  }, []);

  useEffect(() => {
    reportInfo(stateName, 'getting presets');
    getPresets();

    return () => {
      fetchProgressBarInterceptor.unsubscribe({ id: stateName });
    };
  }, []);

  useEffect(() => {
    if (!presets?.configs) return;

    presets.configs.isStateActive = isStateActive;
  }, [isStateActive, presets]);

  useEffect(() => {
    // DEV-41495 React 18 - Migration and Deprecations leftovers
    // After upgrading to React 18, some state updates were happening simultaneously rather than sequentially
    // requiring the addition of this useEffect to force another render, causing IFrame to unmount and mount again
    if (isIframeReloading) {
      setIsIframeReLoading(false);
    }
  }, [isIframeReloading]);

  useEffect(() => {
    const onReload = async () => {
      reportInfo(stateName, 'product iframe reload event: RELOAD_PRODUCTS');

      setIsIframeLoading(true);
      setIsIframeReLoading(true);
      fetchProgressBarInterceptor.clear(stateName);
      await getPresets();
    };

    eventEmitter.on(EVENTS.RELOAD_PRODUCTS, onReload);

    return () => {
      eventEmitter.off(EVENTS.RELOAD_PRODUCTS, onReload);
    };
  }, [clearRoute]);

  const isInitialLoading = isLoadingPresets || !src || !presets;

  return (
    <>
      {error && <ProductIframeError name={name} error={error} />}
      {(isIframeLoading || isInitialLoading) && !error && (
        <StyledStretchedLoader fullscreen subject={'product-loader'} title={loadingMessage} />
      )}
      {!isInitialLoading && !isIframeReloading && (
        <IFrame src={src} ref={onRefChange} onLoad={onIframeLoad} onError={onIframeError} />
      )}
    </>
  );
};
