import { useObservable } from '@logz-pkg/observable';
import { useMemo } from 'react';
import { AgentChatRecord } from '@logz-pkg/models';
import { useAiDataSource } from '../data-source/data-source.hook';
import { aiHistoryService } from './history.service';

export const useAiHistory = () => {
  const assistant = useObservable(aiHistoryService.assistant);
  const agent = useObservable(aiHistoryService.agent);
  const { dataSource, isAgent } = useAiDataSource();

  const history: AgentChatRecord[] = useMemo(() => {
    if (isAgent) {
      return agent;
    }

    return assistant;
  }, [dataSource, assistant, agent]);

  return {
    history,
  };
};
