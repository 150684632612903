import { Group, Icon, Text } from '@logz-ui/styleguide';
import styled from 'styled-components';

export const extractErrorFromHTMLContent = content => {
  if (!content) return;

  if (typeof content !== 'string') return;

  if (content[0] !== '{') return;

  try {
    const json = JSON.parse(content);

    if (json.message) return json;
  } catch (e) {}
};

const StyledGroup = styled(Group)`
  position: absolute;
  background-color: white;
`;

export const ProductIframeError = ({ error, name }) => {
  const statusCode = error?.statusCode || error?.status;

  return (
    <StyledGroup width={'100%'} fullHeight justifyContent="center" vertical alignItems={'center'}>
      <Icon color={'red.500'} icon={'diamond-exclamation-solid'} size={30} />
      <Text size={20} textAlign={'center'}>
        Something went wrong while loading {name} <br />
        please contact support <br />
      </Text>

      <Text size={12} color={'red.500'} textAlign={'center'}>
        {statusCode ? `${statusCode}: ` : ''}
        {error?.message}
      </Text>
    </StyledGroup>
  );
};
