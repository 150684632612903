import { Observable } from '@logz-pkg/observable';

export class SiemOnboardingStateService {
  readonly isOnboardingModalOpen = new Observable<boolean>(false);
  readonly isOnboardingBarOpen = new Observable<boolean>(false);
  readonly isAccountEligableForSiemTrial = new Observable<boolean>(false);

  setIsAccountEligableForSiemTrial = (value: boolean) => {
    this.isAccountEligableForSiemTrial.set(value);
  };

  showSiemTrialOnboardingModal = () => {
    this.isOnboardingModalOpen.set(true);
  };
  hideSiemTrialOnboardingModal = () => {
    this.isOnboardingModalOpen.set(false);
  };

  showSiemTrialOnboardingBar = () => {
    this.isOnboardingBarOpen.set(true);
  };
  hideSiemTrialOnboardingBar = () => {
    this.isOnboardingBarOpen.set(false);
  };
}

export const siemOnboardingStateService = new SiemOnboardingStateService();
