import styled from 'styled-components';
import { Icon } from '@logz-ui/styleguide';
import { useNavigationState } from 'ui/state/hooks';
import { navigationAppStateService } from 'ui/components/App/Navigation/app-state';

const StyledDiv = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  background-color: ${({ theme }) => theme.colors.blue[800]};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 8px 4px 8px 4px;
  z-index: 2;
  cursor: pointer;
`;

export const Minifier = () => {
  const { isMinified, setIsMinified } = useNavigationState();

  const handleToggleMinified = () => {
    const newMinifiedState = !isMinified;

    setIsMinified(newMinifiedState);
    navigationAppStateService.setMinified(newMinifiedState);
  };

  return (
    <StyledDiv onClick={handleToggleMinified}>
      <Icon color={'blue.400'} icon={isMinified ? 'chevron-right-regular' : 'chevron-left-regular'} size={10} />
    </StyledDiv>
  );
};
