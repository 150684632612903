import { cloneDeep, isNil, partition } from 'lodash';
import { fluentToOtelFieldMapping } from './osd.route.helpers';

const notOptionKey = value => !['adjust_pure_negative', 'minimum_should_match', 'boost'].includes(value[0]);

const isTerms = obj => !isNil(obj.terms);

export const convertTermsToShould = filter => {
  const [fieldName, values] = Object.entries(filter.terms).find(notOptionKey) as any;

  const equivalentOtelField = fluentToOtelFieldMapping[fieldName];

  return {
    bool: {
      should: [
        ...values.map(value => ({
          match_phrase: {
            [fieldName]: value,
          },
        })),
        ...(equivalentOtelField
          ? values.map(value => ({
              match_phrase: {
                [equivalentOtelField]: value,
              },
            }))
          : []),
      ],
      minimum_should_match: '1',
    },
  };
};

export const parseElasticFilters = filters => {
  const filtersCopy = cloneDeep(filters);

  const [mustTerms, mustRest] = partition(filters.bool.must, isTerms);

  filtersCopy.bool.must = [...mustTerms.map(convertTermsToShould), ...mustRest];

  const [mustNotTerms, mustNotRest] = partition(filters.bool.must_not, isTerms);

  filtersCopy.bool.must_not = [...mustNotTerms.map(convertTermsToShould), ...mustNotRest];

  return filtersCopy;
};
