import {
  forgotPasswordRouteState,
  forgotPasswordValidateRouteState,
  loginRouteState,
  logoutState,
  multiFactorLoginRouteState,
  registerRouteState,
  ssoLoginRouteState,
  validateResetPasswordState,
  verifyInvitationState,
  signupSuccessState,
  verifyEmailState,
} from './auth/auth.routes';
import { dashboardRouteState, playgroundRoute, settingsRouteState, toolsRouteState } from './dashboard.routes';
import {
  logsEmptyStateRouteState,
  metricsEmptyStateRouteState,
  siemEmptyStateRouteState,
  switchModeRouteState,
  tracingEmptyStateRouteState,
} from './switch-mode.routes';
import {
  auditTrailRoute,
  dropFilters,
  generalSettingsRoute,
  logsDropFilters,
  manageAccountRoute,
  userManagementRoute,
} from './settings.routes';
import { archiveAndRestoreRoute } from './archive-and-restore.routes';
import { drilldownRoute } from './drilldown.routes';
import { liveTailRoute } from './live-tail.routes';
import { adminRoute } from './admin.routes';
import { scheduledReportsRoutes } from './scheduled-reports.routes';
import { securityRoute } from './security.routes';
import { rulesRoutes } from './rules.routes';
import { optimizersRoutes } from './optimizers.routes';
import { alertsRoutes } from './alerts.routes';
import { alerts } from './alerts.route';
import { manageTokens } from './manage-tokens/manage-tokens.route';
import { fieldMapping } from './field-mapping.route';
import { threatsFeeds } from './security/threats-feeds.route';
import { systemStatus } from './system-status.route';
import { jaeger } from './jaeger.route';
import { scheduledReportsForm } from './scheduled-reports-form';
import { rules } from './security.rules.route';
import { grafana } from './grafana/grafana.route';
import { kibana } from './osd/osd.route';
import { securityRoutes } from './security/security.routes';
import { planAndBilling } from './plan-and-billing.route';
import { siemTrialRoutes } from './security/start-siem-trial';
import { AppStateDeclaration } from './AppStateDecleration';
import { logsToMetricsRoutes } from './logs-to-metrics.routes';
import { costManagementRoute } from './cost-management.route';
import { welcomeRoute } from './welcome.routes';
import { metricsDropFilters } from './drop-metrics.routes';
import { tracingConfigurationRoutes } from './tracing/tracing-sampling-rules.route';
import { metricsRollupsRoutes } from './metrics-rollups.routes';
import { observabilityRoutes } from './observability/observability.routes';
import { homeDashboardRoute } from './home.routes';
import {
  dashboardsHubLegacyRoute,
  dashboardsHubRoute,
  viewDashboardRoute,
} from './dashboards-hub/dashboards-hub.routes';
import { spmRoutes } from './spm.routes';
import { integrationsRoutes } from './integrations/integrations.route';
import { newOptimizerRoutes } from './optimizers/optimizers.routes';
import { triggersRoutes } from './triggers/triggers.routes';
import { exploreRoutes } from 'states/explore/explore.route';
import { awsMarketplaceRoutes } from 'states/aws-marketplace.routes';
import { cloudMarketplaceRoutes } from 'states/cloud-marketplace.routes';
import { shortUrlRoutes } from 'states/short-url.routes';

export const appRouteStates: AppStateDeclaration[] = [
  loginRouteState,
  multiFactorLoginRouteState,
  ssoLoginRouteState,
  registerRouteState,
  forgotPasswordRouteState,
  validateResetPasswordState,
  forgotPasswordValidateRouteState,
  dashboardRouteState,
  siemEmptyStateRouteState,
  logsEmptyStateRouteState,
  tracingEmptyStateRouteState,
  metricsEmptyStateRouteState,
  settingsRouteState,
  toolsRouteState,
  switchModeRouteState,
  generalSettingsRoute,
  userManagementRoute,
  logsDropFilters,
  dropFilters,
  auditTrailRoute,
  manageAccountRoute,
  archiveAndRestoreRoute,
  drilldownRoute,
  liveTailRoute,
  adminRoute,
  logoutState,
  welcomeRoute,
  homeDashboardRoute,
  dashboardsHubRoute,
  dashboardsHubLegacyRoute,
  ...exploreRoutes,
  ...spmRoutes,
  ...scheduledReportsRoutes,
  securityRoute,
  ...rulesRoutes,
  ...alertsRoutes,
  ...triggersRoutes,
  ...optimizersRoutes,
  ...newOptimizerRoutes,
  ...kibana,
  ...grafana,
  ...jaeger,
  ...alerts,
  ...manageTokens,
  ...fieldMapping,
  ...threatsFeeds,
  ...systemStatus,
  ...scheduledReportsForm,
  ...rules,
  ...securityRoutes,
  ...planAndBilling,
  ...siemTrialRoutes,
  ...logsToMetricsRoutes,
  ...costManagementRoute,
  ...metricsDropFilters,
  ...tracingConfigurationRoutes,
  ...metricsRollupsRoutes,
  ...observabilityRoutes,
  playgroundRoute,
  ...integrationsRoutes,
  verifyInvitationState,
  signupSuccessState,
  verifyEmailState,
  ...awsMarketplaceRoutes,
  ...cloudMarketplaceRoutes,
  ...newOptimizerRoutes,
  viewDashboardRoute,
  ...shortUrlRoutes,
];
