import { Group, Spinner } from '@logz-ui/styleguide';

export const EmptyResultsContainer = props => (
  <Group alignItems={'center'} justifyContent={'center'} width={'100%'} fullHeight py={10} {...props} />
);

export const LoadingResultsSpinner = () => (
  <EmptyResultsContainer>
    <Spinner size={'s'} />
  </EmptyResultsContainer>
);
