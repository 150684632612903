import { Flex, InlineNotification } from '@logz-ui/styleguide';
import React from 'react';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';

export const AiChatGeneralErrorMessage: React.FC<MessageType> = ({ content, title }) => {
  return (
    <Flex px={8} mt={4}>
      <InlineNotification variant={'danger'} noClose title={title} fullWidth>
        {content}
      </InlineNotification>
    </Flex>
  );
};
