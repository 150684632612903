import { Badge, Button, Divider, Grid, Group, Text } from '@logz-ui/styleguide';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { AgentChatRecord } from '@logz-pkg/models';
import { dateService } from '@logz-pkg/frontend-services';
import { useAiHistory } from '../../state/history/history.hook';
import { useAiFetchData } from '../../state/fetch-data/fetch-data.hook';
import { AiFeedback } from '../Feedback/Feedback';
import { useAiState } from '../../state/ai-state.hook';
import { useScrollChat } from './scrollChat.hook';
import { AiChatGeneralUser } from './General/UserMessage';
import { AiChatGeneralLoadingManager } from './General/LoadingManager';
import { AiChatAgentLoadingBarStatus } from 'ui/components/AI/Components/Messages/Agent/LoadingBarStatus';
import { AiChatAgentCollapse } from 'ui/components/AI/Components/Messages/Agent/Collapse';
import { AiChatAgentConclusion } from 'ui/components/AI/Components/Messages/Agent/Conclusion';
import { AiChatGeneralErrorMessage } from 'ui/components/AI/Components/Messages/General/Error';
import { AiChatAssistantResponse } from 'ui/components/AI/Components/Messages/Assistant/Response';
import { AiChatAgentUserLog } from 'ui/components/AI/Components/Messages/Agent/UserLog';
import { AiChatAgentInformationManager } from 'ui/components/AI/Components/Messages/Agent/InformationManager';

const chatMessageMap = {
  User: AiChatGeneralUser,
  Loading: AiChatAgentLoadingBarStatus,
  Exceptions: AiChatAgentInformationManager,
  Insights: AiChatAgentInformationManager,
  Alerts: AiChatAgentInformationManager,
  Log: AiChatAgentUserLog,
  Collapse: AiChatAgentCollapse,
  Conclusion: AiChatAgentConclusion,
  Error: AiChatGeneralErrorMessage,
  Assistant: AiChatAssistantResponse,
  default: AiChatAssistantResponse,
};

export type MessageType = Omit<AgentChatRecord, 'role'> & { isFirst?: boolean; isLast?: boolean };

const MainSectionContainer = styled.div`
  max-height: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const StyledButton = styled(Button)`
  border-radius: 30px;
  position: absolute;
  left: 50%;
  bottom: 20%;
  z-index: 1;
`;

const ScrollTarget = styled.div`
  height: 1px;
`;

export const AiChatMessageManager: React.FC = () => {
  const { history } = useAiHistory();
  const { isLoading } = useAiFetchData();
  const { shouldAutoScroll } = useAiState();

  const bottomScrollRef = useRef(null);
  const { atBottom, scrollToBottom } = useScrollChat({ shouldAutoScroll, element: bottomScrollRef?.current });

  if (!history?.length) {
    scrollToBottom();

    return null;
  }

  return (
    <MainSectionContainer>
      {history.map((record, index) => {
        const key = `${String(record.content).split('.')[0]}-${index}`;

        const Component = chatMessageMap[record.type] || chatMessageMap.default;
        const createdAt = record?.createdAt;
        const formattedDate =
          createdAt && dateService.format({ date: record?.createdAt, format: 'MMM DD, YYYY @ HH:mm:ss' });

        return ['Alerts', 'Insights', 'Exceptions', 'User'].includes(record.type) ? (
          <Group vertical key={key} width={'100%'}>
            <Grid mt={4} px={8} width={'100%'} alignItems={'center'} gridTemplateColumns={'1fr auto 1fr'} cellGap={0}>
              <Divider color={'gray.300'} />
              <Badge
                px={3}
                value={
                  <Text size={12} color={'royalBlue.700'}>
                    {formattedDate}
                  </Text>
                }
                size={'m'}
                outline
                borderColor={'gray.300'}
                color={'royalBlue.700'}
              />
              <Divider color={'gray.300'} />
            </Grid>
            <Component {...record} isFirst={index === 0} isLast={history.length - 1 === index} />
          </Group>
        ) : (
          <Component {...record} key={key} isFirst={index === 0} isLast={history.length - 1 === index} />
        );
      })}
      <AiFeedback />
      <ScrollTarget ref={bottomScrollRef} />
      {isLoading && <AiChatGeneralLoadingManager />}
      {!atBottom && (
        <StyledButton size={'l'} variant={'secondary'} icon={'arrow-down-regular'} onClick={scrollToBottom} />
      )}
    </MainSectionContainer>
  );
};
