import React, { useCallback } from 'react';
import { Textarea } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { AICopilotSubjects } from '@logz-pkg/enums';
import { useAiState } from 'ui/components/AI/state/ai-state.hook';
import { useAiFetchData } from 'ui/components/AI/state/fetch-data/fetch-data.hook';

const StyledTextareaComponent = styled(Textarea)`
  border: none;
`;

export const MultilineInput: React.FC = () => {
  const { userInput, setUserInput, isSessionExpired } = useAiState();
  const { handleSubmit, isLoading } = useAiFetchData();

  const insertNewLine = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { selectionStart, selectionEnd } = event.currentTarget;
    const newValue = `${userInput.slice(0, selectionStart)}\n${userInput.slice(selectionEnd)}`;

    setUserInput(newValue);
    event.currentTarget.setSelectionRange(selectionStart + 1, selectionStart + 1);
    event.preventDefault();
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        if (!userInput || isLoading) return;

        if (event.shiftKey) {
          insertNewLine(event);

          return;
        }

        handleSubmit({ prompt: userInput });
      }
    },
    [userInput, isLoading, handleSubmit],
  );

  return (
    <StyledTextareaComponent
      width="100%"
      resize="none"
      borderless
      minRows={1}
      maxRows={5}
      value={userInput}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setUserInput(e.target.value)}
      data-logz-test-subject={AICopilotSubjects.DrawerInput}
      placeholder="Ask a question about your data"
      isLoading={isLoading}
      disabled={isSessionExpired || isLoading}
      onKeyDown={handleKeyDown}
    />
  );
};
