import { FunctionComponent, useEffect, useState } from 'react';
import { Card, Tab, Tabs } from '@logz-ui/styleguide';
import { markdownTabsUtils } from '../../utils';
import { IContentTab } from '../../../Markdown/extract-tabs';
import { MarkdownContent } from '../Content';
import { useIntegrationsHub } from 'ui/state/hooks';
import { ErrorBoundary } from 'ui/components/shared/ErrorBoundary/ErrorBoundary';

interface ITabsComponentsProps {
  markdown: string;
  groupId?: string;
}

export const TabsComponents: FunctionComponent<ITabsComponentsProps> = ({ markdown, groupId }) => {
  const [tabs, setTabs] = useState<IContentTab[]>([]);
  const [selectedTab, setSelectedTab] = useState<IContentTab>(null);
  const { selectedGlobalTab, setSelectedGlobalTab } = useIntegrationsHub();

  useEffect(() => {
    const allTabs = markdownTabsUtils.extractTabs(markdown, groupId);

    if (allTabs.length > 0) {
      setTabs(allTabs);

      if (selectedGlobalTab) {
        setSelectedTab(
          allTabs.find(({ id, groupId }) => id === selectedGlobalTab.id && groupId === selectedGlobalTab.groupId),
        );
      } else {
        setSelectedTab(allTabs[0]);
      }

      if (groupId) {
        setSelectedGlobalTab(allTabs[0]);
      }
    }
  }, [markdown, groupId]);

  useEffect(() => {
    if (selectedGlobalTab) {
      const tabWithSameId = tabs.find(
        ({ id, groupId }) => id === selectedGlobalTab.id && groupId === selectedGlobalTab.groupId,
      );

      if (tabWithSameId) {
        setSelectedTab(tabWithSameId);
      }
    }
  }, [selectedGlobalTab]);

  const handleSelectTab = (tabId: IContentTab['id']) => {
    const matchingTabContent = tabs.find(({ id }) => id === tabId);

    setSelectedTab(matchingTabContent);

    if (groupId) {
      setSelectedGlobalTab(matchingTabContent);
    }
  };

  if (!selectedTab || !tabs) return null;

  return (
    <ErrorBoundary errorContext={'IntegrationPage/Tabs'}>
      <Card backgroundColor={'white'} p={5} mb={3} width={'100%'}>
        <Tabs selectedTab={selectedTab.id} onChange={(event, value) => handleSelectTab(value)} size={'s'}>
          {tabs.map(({ id, title }) => (
            <Tab key={id} value={id} label={title} />
          ))}
        </Tabs>
        <MarkdownContent shouldRenderMarkdownHeader={false} markdown={selectedTab.content} />
      </Card>
    </ErrorBoundary>
  );
};
