import React from 'react';
import { LazySiemOnboarding, LazyTracingTrialBar, LazyWelcomeCenterBar } from './AppGlobalComponents';
import { TOnboardingBarsState, useOnboardingBarsState } from 'ui/state/hooks/onboarding-bars.state.hook';

export const OnboardingBarsStateContext = React.createContext<TOnboardingBarsState>({
  isTracingTrialVisible: false,
  isWelcomeCenterVisible: false,
  isSiemOnboardingVisible: false,
});

export const OnboardingBars = () => {
  const headerBarsState = useOnboardingBarsState();

  return (
    <OnboardingBarsStateContext.Provider value={headerBarsState}>
      <LazySiemOnboarding />
      <LazyTracingTrialBar />
      {headerBarsState.isWelcomeCenterVisible && <LazyWelcomeCenterBar />}
    </OnboardingBarsStateContext.Provider>
  );
};
