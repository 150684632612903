import React from 'react';
import { Flex, Feedback } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { useAiFeedback } from '../../state/feedback/feedback.hook';
import { useAiFetchData } from '../../state/fetch-data/fetch-data.hook';
import { AiFeedbackReasonsPanel } from './ReasonsPanel';

const StyledCardWrapper = styled(Flex)`
  border-radius: 4px;
  padding: 1px;
  background: linear-gradient(95.34deg, rgb(21, 112, 239) 30.62%, rgb(238, 70, 188) 79.47%, rgb(250, 197, 21) 112.72%);
`;

export const AiFeedback: React.FC = () => {
  const { shouldShowFeedback, rate, setRate } = useAiFeedback();
  const { isLoading } = useAiFetchData();

  return (
    !isLoading &&
    shouldShowFeedback && (
      <StyledCardWrapper ml={10} mr={7} mb={5}>
        {rate ? <AiFeedbackReasonsPanel /> : <Feedback rate={rate} onClick={setRate} />}
      </StyledCardWrapper>
    )
  );
};
