import { Flex, Group, Icon, Link } from '@logz-ui/styleguide';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { GlobalSearchModal } from './GlobalSearchModal';
import { useNavigationState } from 'ui/state/hooks';

const StyledSearchIconContainer = styled.div`
  transition: color 0.1s ease-in-out;
  color: ${({ theme }) => theme.colors.gray[400]};

  &:hover {
    color: ${({ theme }) => theme.colors.gray[0]};
  }
`;

const SearchIcon: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => {
  const { isMinified } = useNavigationState();

  return (
    <Link onClick={onClick}>
      <StyledSearchIconContainer>
        <Flex alignItems={'baseline'} context={'open-global-search'}>
          <Group alignItems={'center'} gap={1} vertical>
            <Icon icon={'magnifying-glass-regular'} size={isMinified ? 12 : 16} />
          </Group>
        </Flex>
      </StyledSearchIconContainer>
    </Link>
  );
};

export const GlobalSearch: FunctionComponent = () => {
  const [isSearchOpen, setSearchOpen] = useState(false);

  function openSearch() {
    setSearchOpen(true);
  }

  return (
    <Group mt={4} mb={2} alignItems="center" fullHeight fullWidth>
      <SearchIcon onClick={openSearch} />
      <GlobalSearchModal open={isSearchOpen} onClose={() => setSearchOpen(false)} />
    </Group>
  );
};
