import { ElasticsearchFieldType } from '@logz-pkg/enums';
import { ElasticsearchFieldModel } from '@logz-pkg/models';
import { useObservable } from '@logz-pkg/observable';
import { Card, Ellipsis, Flex, Group, Icon, IIconProps, Text } from '@logz-ui/styleguide';
import React, { useEffect, useMemo } from 'react';
import { useSelectOptions, useSelectState } from '../../../SmartSelect.provider';
import { MenuItem } from '../MenuItem';
import { useSmartValidate } from './smart-validate-hook';

interface ILuceneQuerySectionProps {
  onChange: (value: ElasticsearchFieldModel | null, shouldStartNewTag?: boolean) => void;
}

export const getLuceneFilterField = (inputValue: string, isInputValid?: boolean, isFilterValid?: boolean) => {
  const luceneField = new ElasticsearchFieldModel();

  luceneField.isAggregatable = false;
  luceneField.name = isInputValid || !isFilterValid ? inputValue : `"${inputValue}"`;
  luceneField.type = ElasticsearchFieldType.Lucene;

  return luceneField;
};

const getIcon = (loading: boolean, isFilterValid: boolean): IIconProps['icon'] => {
  if (loading) {
    return 'arrows-rotate-regular';
  } else if (!isFilterValid) {
    return 'triangle-exclamation-solid';
  }

  return 'magnifying-glass-regular';
};

export const LuceneQuerySection: React.FC<ILuceneQuerySectionProps> = ({ onChange }) => {
  const { inputValueO, highlightedOptionO, shouldSubmitO, isCreatingNewTagO, filterValueO } = useSelectState();
  const { preferences } = useSelectOptions();
  const shouldSubmit = useObservable(shouldSubmitO);
  const inputValue = useObservable(inputValueO);
  const highlightedOption = useObservable(highlightedOptionO);
  const isCreatingNewTag = useObservable(isCreatingNewTagO);
  const filterValue = useObservable(filterValueO);
  const isHighlighted = highlightedOption === -1;
  const { isLoading, isFilterValid, isInputValid } = useSmartValidate(inputValue);
  const currentFilter: ElasticsearchFieldModel = useMemo(
    () => (inputValue ? getLuceneFilterField(inputValue, isInputValid, isFilterValid) : null),
    [inputValue, isInputValid, isFilterValid],
  );

  useEffect(() => {
    if (shouldSubmit && !isLoading) {
      const newFilter = getLuceneFilterField(inputValueO.get(), isInputValid, isFilterValid);

      shouldSubmitO.set(false);
      onChange(newFilter, preferences.startNewTagOnEnter);
    }
  }, [shouldSubmit, isLoading, isInputValid, isFilterValid]);

  const shouldShowLuceneSection = useMemo(
    () => filterValue.operator === 'LUCENE' || isCreatingNewTag,
    [filterValue.operator, isCreatingNewTag],
  );

  return (
    <>
      {inputValue && shouldShowLuceneSection && (
        <Group my={2} fullWidth>
          <MenuItem highlighted={isHighlighted} onClick={() => (isLoading ? {} : onChange(currentFilter))}>
            <Flex p={'8px'} justifyContent="space-between" alignItems="center" width={'100%'} height={'35px'}>
              <Flex justifyContent="space-between" alignItems="center">
                <Icon color={'royalBlue.1000'} icon={getIcon(isLoading, isFilterValid)} spin={isLoading} size={16} />
                <Flex px={'8px'}>
                  <Ellipsis maxLines={5} wordBreak="break-all">
                    <Text>{isInputValid || !isFilterValid ? `${inputValue}` : `"${inputValue}"`}</Text>
                  </Ellipsis>
                </Flex>
                {isFilterValid ? (
                  <>
                    {isHighlighted ? (
                      <Group flexDirection={'row'} alignItems="center">
                        <Text> - Press </Text>
                        <Card p={1}>
                          <Icon size={12} color={'gray.600'} icon={'arrow-turn-down-left-regular'} />
                        </Card>
                        <Text> to apply custom value and create a Lucene query </Text>
                      </Group>
                    ) : (
                      <Text> - Enter text to create a Lucene query</Text>
                    )}
                  </>
                ) : (
                  <Text> - Invalid Lucene query </Text>
                )}
              </Flex>
            </Flex>
          </MenuItem>
        </Group>
      )}
    </>
  );
};
