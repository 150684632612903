import React from 'react';
import { LogzioSupportedBucketTypes, OperatingSystem } from '@logz-pkg/enums';
import { InlineNotification, Link, Tag } from '@logz-ui/styleguide';
import { LoggerService } from '@logz-pkg/frontend-services';
import { reactStringReplace } from '../../../../utils/react-string-replace';
import { BucketsContainer } from '../BucketSettings/Container';
import { InstallGrafanaDashboardsButton } from '../InstallGrafanaDashboard/InstallGrafanaDashboardButton';
import { TabsComponents } from '../Single/Body/Markdowns/Tabs';
import { FilebeatConfWiz } from 'ui/components/FilebeatConfWiz/FilebeatConfWiz';
import { markdownTabsUtils } from 'ui/components/Integrations/Single/utils';

const mapInfoBoxToInlineNotification = {
  note: ['announcement', 'NOTE'],
  tip: ['success', 'PRO TIP'],
  important: ['warning', 'IMPORTANT'],
  warning: ['danger', 'WARNING'],
  read: ['info', 'READ MORE'],
};

const admonitionTypeToInlineNotificationVariant: Record<string, 'info' | 'announcement' | 'danger'> = {
  note: 'info',
  tip: 'announcement',
  caution: 'danger',
};

const markdownTag = {
  match: /`(.*?)`/gm,
  to: tag => {
    return <Tag>{tag}</Tag>;
  },
};

const markdownBold = {
  match: /\*\*(.*?)\*\*/g,
  to: text => <b>{text}</b>,
};
const markdownLink = {
  match: /(\[(?:[^\[\]]*)\]\((?:.*?)\))/gm,
  to: markdownLink => {
    const [, text, href] = markdownLink.match(/\[([^\[\]]*)\]\((.*?)\)/);

    return <Link href={href}>{text}</Link>;
  },
};

export const replaceWithInlineNotification = {
  match: /^<!-- info-box-start:\w+ -->\s*([\w\W]+?\s*{:\.info-box\.\w+})\s*<!-- info-box-end -->$/gm,
  to: match => {
    const [, type] = match.match(/{:\.info-box\.(\w+)}/);
    const text = match.replace(/\s*{:\.info-box\.\w+}\s*/, '');
    const texts = reactStringReplace({
      source: text,
      stringToReact: [markdownLink],
    });
    const [variant, title] = mapInfoBoxToInlineNotification[type] ?? mapInfoBoxToInlineNotification.note;

    return (
      <InlineNotification noClose variant={variant} title={title}>
        {texts}
      </InlineNotification>
    );
  },
};

const Admonition: React.FC<{ text: string; type: keyof typeof admonitionTypeToInlineNotificationVariant }> = ({
  text,
  type,
}) => {
  const texts = reactStringReplace({
    source: text,
    stringToReact: [markdownLink, markdownTag, markdownBold],
  });

  return (
    <InlineNotification mt={5} noClose variant={admonitionTypeToInlineNotificationVariant[type]} mb={2}>
      {texts}
    </InlineNotification>
  );
};

export const matchCautionAdmonition = {
  match: /:::caution([\w\W\s]*?):::\s+/gm,
  to: match => <Admonition type={'caution'} text={match} />,
};

export const matchNoteAdmonition = {
  match: /:::note([\w\W\s]*?):::\s+/gm,
  to: match => <Admonition type={'note'} text={match} />,
};

export const matchTipsAdmonition = {
  match: /:::tip([\w\W\s]*?):::\s+/gm,
  to: match => <Admonition type={'tip'} text={match} />,
};

export const matchInstallation = {
  match: /<!-- logzio-inject:install:grafana:dashboards ids=(\[.*?\]) -->/gs,
  to: match => {
    let ids;

    try {
      ids = JSON.parse(match);
    } catch (error) {
      ids = [];
    }

    const isValidValues = ids.every(i => typeof i === 'string' && i.length);

    if (ids.length && isValidValues) return <InstallGrafanaDashboardsButton ids={ids} />;

    LoggerService.logError({
      message: `Could not display install dashboards button component`,
      error: `No properly ids has given in the markdown`,
    });
  },
};

export const replaceTabsV2 = {
  match: /<Tabs[^>]*>([\s\S]*?)<\/Tabs>/gm,
  to: (match, x, source) => {
    const groupId = markdownTabsUtils.getTabsGroupId(source, match);

    return <TabsComponents markdown={match} groupId={groupId} />;
  },
};

export const stringToReact = [
  replaceTabsV2,
  {
    match: /(<!-- logzio-inject:filebeat-wizard:os-linux -->)/gm,
    to: () => <FilebeatConfWiz os={OperatingSystem.Linux} />,
  },
  {
    match: /(<!-- logzio-inject:filebeat-wizard:os-windows -->)/gm,
    to: () => <FilebeatConfWiz os={OperatingSystem.Windows} />,
  },
  {
    match: /(<!-- logzio-inject:aws:cloudfront -->)/gm,
    to: () => <BucketsContainer bucketType={LogzioSupportedBucketTypes.CloudFront} />,
  },
  {
    match: /(<!-- logzio-inject:aws:cloudtrail -->)/gm,
    to: () => <BucketsContainer bucketType={LogzioSupportedBucketTypes.CloudTrail} />,
  },
  {
    match: /(<!-- logzio-inject:aws:elb -->)/gm,
    to: () => <BucketsContainer bucketType={LogzioSupportedBucketTypes.Elb} />,
  },
  {
    match: /(<!-- logzio-inject:aws:s3-buckets -->)/gm,
    to: () => <BucketsContainer bucketType={LogzioSupportedBucketTypes.S3} />,
  },
  {
    match: /import\s+Tabs\s+from\s+'@theme\/Tabs';/g,
    to: () => <></>,
  },
  {
    match: /(<!-- logzio-inject:aws:s3-access -->)/gm,
    to: () => <BucketsContainer bucketType={LogzioSupportedBucketTypes.S3Access} />,
  },
  {
    match: /(<!-- logzio-inject:aws:vpc-flow -->)/gm,
    to: () => <BucketsContainer bucketType={LogzioSupportedBucketTypes.VPC} />,
  },
  {
    match: /(^---[\s\S\n]*?---)/gms,
    to: () => <br />,
  },
  {
    match: /<!-- info:start:(\w+) -->(\n[\s\S\n])*?<!-- info:end -->/g,
    to: match => (
      <Tag variant={match} key={match}>
        {match}
      </Tag>
    ),
  },
  matchCautionAdmonition,
  matchNoteAdmonition,
  matchTipsAdmonition,
  matchInstallation,
  replaceWithInlineNotification,
];
