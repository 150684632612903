import { Options, XAxisOptions } from 'highcharts';
import { IGetOptionsProps } from './types';

export const dateTimeLabelFormats = {
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: '%H:%M',
  hour: '%H:%M',
  day: '%e. %b',
  week: '%e. %b',
  month: "%b '%y",
  year: '%Y',
};

export const defaultLegendOptions: Options['legend'] = {
  enabled: false,
  padding: 2,
  margin: 3,
  itemDistance: 20,
  symbolRadius: 0,
  itemStyle: { fontWeight: '500', opacity: 0.8 },
  itemHoverStyle: { fontWeight: '500', opacity: 1 },
};
const HORIZONTAL_MOUSE_OFFSET = 55;
const VERTICAL_MOUSE_OFFSET = 45;
export const getDefaultTooltipSettings = (isMulti: boolean): Options['tooltip'] => ({
  positioner(labelWidth, labelHeight, point) {
    const mousePosition = this.chart.mouseCoords;
    let tooltipY = point.plotY + VERTICAL_MOUSE_OFFSET;
    const chartRect = this.chart.container.getBoundingClientRect();

    const tooltipContainer = this.chart.tooltip.container.querySelector('div span div');
    const tooltipContent = tooltipContainer.querySelector(':scope > *');

    const contentRect = (tooltipContent || tooltipContainer).getBoundingClientRect();
    const wrapperRect = this.chart.tooltip.container.getBoundingClientRect();
    const deltaY = contentRect.top - wrapperRect.top;
    const deltaLeft = contentRect.left - wrapperRect.left;
    const tooltipBottom = chartRect.top + tooltipY + contentRect.height + deltaY;
    let tooltipX = point.plotX - (contentRect.width * (point.plotX - deltaLeft)) / this.chart.plotWidth; // position x the tooltip relative to the point on graph

    if (tooltipBottom > window.innerHeight) {
      tooltipY -= tooltipBottom - window.innerHeight;

      if (mousePosition.x > this.chart.plotWidth / 2) {
        tooltipX = mousePosition.x - contentRect.width - HORIZONTAL_MOUSE_OFFSET;
      } else {
        tooltipX = mousePosition.x + HORIZONTAL_MOUSE_OFFSET - deltaLeft;
      }
    }

    return {
      x: tooltipX,
      y: tooltipY,
    };
  },
  outside: true, //  nessesery to render the tooltip out of bounds
  split: false, // nessesery props to unify tooltips and hide them
  shared: isMulti,
  shadow: false,
  hideDelay: 100,
  style: {
    pointerEvents: 'none', // nessesery for rendering react component
  },
});

export const getDefaultCrosshairOptions = (
  crosshair: XAxisOptions['crosshair'],
  appTheme: IGetOptionsProps['appTheme'],
): XAxisOptions['crosshair'] => {
  if (crosshair !== undefined) {
    return crosshair;
  }

  return {
    width: 2,
    color: appTheme.graph.crosshairColor,
    dashStyle: 'Dash',
  };
};
