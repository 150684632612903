import { createRoot } from 'react-dom/client';
import { App } from 'ui/components/App/App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(<App />);
}
