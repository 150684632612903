import { appStateService } from '@logz-pkg/frontend-services';
import { AccountModel } from '@logz-pkg/models';

const navigationSchemaName = 'navigation';

export const navigationAppStateService = {
  async getState() {
    return appStateService.get(navigationSchemaName);
  },

  async setState(state) {
    const currentState = await this.getState();
    const newState = { ...currentState, ...state };

    return appStateService.set(navigationSchemaName, newState);
  },

  async turnOnNewNavigation() {
    return this.setState({ enabled: true });
  },

  async turnOffNewNavigation() {
    return this.setState({ enabled: false, userDisabled: true });
  },

  async setMinified(mini: boolean) {
    return this.setState({ minified: mini });
  },

  async setPinnedAccounts(accountIds: AccountModel['id'][]) {
    return this.setState({ pinnedAccounts: accountIds });
  },
};
