import { FunctionComponent } from 'react';
import { GuidedWalkthroughTaskId } from '@logz-pkg/models';
import { PhaseWrapper } from './PhaseWrapper';
import { Task } from './Task';
import { WalkthroughTasks } from 'ui/components/GuidedWalkthrough/Tasks';

interface IPhase {
  name: string;
  isOpenByDefault: boolean;
  tasks: GuidedWalkthroughTaskId[];
}

export const Phase: FunctionComponent<IPhase> = ({ name, tasks, isOpenByDefault }) => (
  <PhaseWrapper name={name} tasks={tasks} isOpenByDefault={isOpenByDefault}>
    {tasks.map(taskId => {
      return <Task key={taskId} task={WalkthroughTasks[taskId]} />;
    })}
  </PhaseWrapper>
);
