import { Flex, Group, Icon, Text, InputSearch } from '@logz-ui/styleguide';
import React, { useMemo } from 'react';
import { timeAgo } from '@logz-pkg/utils';
import { AICopilotSubjects } from '@logz-pkg/enums';
import { useAiFetchData } from '../state/fetch-data/fetch-data.hook';
import { useAiConversations } from '../state/conversations/conversations.hook';
import { useAiHistory } from '../state/history/history.hook';
import { useAiState } from '../state/ai-state.hook';
import { useFeatureFlagState } from 'ui/state/hooks';

export const AiHeaderTitle: React.FC = () => {
  const { isLoading } = useAiFetchData();
  const { history } = useAiHistory();
  const { setFilter, filter, shouldShowConversationsPage, setShouldShowConversationsPage } = useAiConversations();
  const { isFeatureEnabled: isAiHistoryEnabled } = useFeatureFlagState('aiHistory');
  const conversationTitle = useMemo(() => history.find(item => item.role === 'User')?.content, [history]);
  const { createdAt } = useAiState();

  const titleDate = useMemo(() => (createdAt && timeAgo(createdAt)) || 'Now', [createdAt]);

  if (isAiHistoryEnabled && shouldShowConversationsPage) {
    return (
      <>
        <Group alignItems={'center'} gap={1} flexGrow={1}>
          <Flex
            width={20}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={() => {
              setShouldShowConversationsPage(false);
            }}
          >
            <Icon size={14} icon={'angle-left-regular'} color={'royalBlue.700'} />
          </Flex>
          <Text size={14} weight={700} color={'royalBlue.700'}>
            Chat history
          </Text>
        </Group>
        <InputSearch defaultValue={filter} onChange={setFilter} ml={2} placeholder={'Search chat'} width={'340px'} />
      </>
    );
  }

  if (history?.length || isLoading) {
    return (
      <Group alignItems={'center'} gap={2} subject={AICopilotSubjects.QuickViewTitle}>
        <Text size={14} weight={700} color={'royalBlue.700'} maxWidth={'427px'} ellipsis>
          {conversationTitle}
        </Text>
        <Group alignItems={'center'} gap={1}>
          <Icon icon={'clock-regular'} color={'royalBlue.500'} />
          <Text size={14} weight={400} color={'royalBlue.500'}>
            {titleDate}
          </Text>
        </Group>
      </Group>
    );
  }

  return null;
};
