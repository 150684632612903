import { Observable } from '@logz-pkg/observable';
import { opensearchtypes } from '@opensearch-project/opensearch/.';
import { graphUtils } from '../ContentPane/Graphs/utils/graph-utils';
import { HistogramPoint, timerangeQueryHelpers } from 'ui/components/shared/Timeframe/utils';

export type GroupByIdSeries = Array<{
  series: HistogramPoint[];
  key: string;
  count: number;
}>;

class GraphStateService {
  state = new Observable<HistogramPoint[]>();
  compareToState = new Observable<HistogramPoint[]>();
  groupByState = new Observable<GroupByIdSeries>([]);
  compareToGroupByState = new Observable<GroupByIdSeries>([]);
  mappedGraphColors = new Observable<any>(null);
  isValidatingGroupBy = new Observable<boolean>(false);

  setState = data => {
    const histogramPoints = timerangeQueryHelpers.flattenHistogramPoints(data?.aggregations?.agg?.buckets || []);

    return this.state.set(histogramPoints);
  };
  setCompareToState = data => {
    const histogramPoints = timerangeQueryHelpers.flattenHistogramPoints(data?.aggregations?.agg?.buckets || []);

    return this.compareToState.set(histogramPoints);
  };
  setGroupByState = (
    termValues: opensearchtypes.SearchResponse<any> | undefined,
    otherValues: opensearchtypes.SearchResponse<any> | undefined,
  ) => {
    const termAggregations = termValues?.aggregations?.byTerm as opensearchtypes.AggregationsFiltersAggregate;

    this.groupByState.set(graphUtils.getGroupByState(termAggregations, otherValues?.aggregations));

    const mappedColors = graphUtils.getMappedGraphColors(this.groupByState.get());

    this.mappedGraphColors.set(mappedColors);
  };
  setCompareToGroupByState = (
    termValues: opensearchtypes.SearchResponse<any> | undefined,
    otherValues: opensearchtypes.SearchResponse<any> | undefined,
  ) => {
    const termAggregations = termValues?.aggregations?.byTerm as opensearchtypes.AggregationsFiltersAggregate;

    this.compareToGroupByState.set(graphUtils.getGroupByState(termAggregations, otherValues?.aggregations));
  };

  getValueColor = (key: string) => (key ? this.mappedGraphColors.get()?.[key.toLocaleLowerCase()] : undefined);

  clear = () => {
    this.state.set(undefined);
    this.groupByState.set([]);
    this.mappedGraphColors.set(null);
  };
}

export const graphStateService = new GraphStateService();
