import { AppMode, Product, ProductKeys, UserRole } from '@logz-pkg/enums';
import { tracingRouteStates } from '../../../../../../app/states/tracing/tracing-sampling.routes.names';
import { ConditionType, IDisplayCondition, ISidenavItemConfig } from './item-configs';
import { notificationEndpointsNavigationItem } from './shared-items';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { FilterTagNames } from 'ui/components/Integrations/utils';

const adminDisplayCondition: IDisplayCondition = { type: ConditionType.UserRole, value: UserRole.Admin };

export const tracingItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'Traces',
  mode: AppMode.OPERATIONS,
  expandedSidenavTitle: 'Distributed Tracing',
  key: ProductKeys.Tracing,
  logo: Product.Tracing,
  targetStateName: tracingRouteStates.jaeger,
  expandedNavGroups: [
    {
      items: [{ icon: 'bars-staggered-regular', text: 'Jaeger', targetStateName: tracingRouteStates.jaeger }],
    },
    {
      title: 'Manage Data',
      items: [
        {
          text: 'Manage tokens',
          icon: 'key-regular',
          targetStateName: 'dashboard.settings.manage-tokens.data-shipping',
          targetStateParams: { product: 'tracing' },
          displayConditions: [adminDisplayCondition],
        },
        {
          icon: 'paper-plane-regular',
          text: 'Send your traces',
          targetStateName: integrationsRouteStates.collectors,
          targetStateParams: { tags: FilterTagNames.TRACING },
          innerStates: [integrationsRouteStates.content],
        },
        notificationEndpointsNavigationItem,
      ],
    },
    {
      title: 'Configurations',
      items: [
        {
          icon: 'file-yml-custom',
          text: 'YAML configuration',
          targetStateName: tracingRouteStates.yamlConfig,
          innerStates: [tracingRouteStates.yamlConfigLocalhost],

          displayConditions: [adminDisplayCondition],
        },
      ],
    },
    {
      title: 'learn more',
      items: [
        {
          text: 'Introduction to Tracing',
          icon: 'circle-question-solid',
          externalUrl: 'https://docs.logz.io/user-guide/distributed-tracing',
          openInNewTab: true,
        },
      ],
    },
  ],
};
