import { SidenavSubjects } from '@logz-pkg/enums';
import { Group, Link, Text } from '@logz-ui/styleguide';
import { FunctionComponent, useEffect } from 'react';
import { useHoverIntent } from 'react-use-hoverintent';
import { useNavItemState } from '../../utils';
import { intervalTimesInMilliseconds } from '../config';
import { ISidenavItemConfig } from '../item-configs';
import { BetaLabel } from './BetaLabel';
import { MainNavigationItem } from './MainNavigationItem';
import { useNavigationState } from 'ui/state/hooks';
import { Logo } from 'ui/components/shared/Logo/Logo';
import { appRouter } from 'services/router/router.service';
import { urlParamsStateService } from 'ui/state/url-params.state.service';
import { analyticsService } from 'services/analytics/analytics.service';

export interface ISidenavItemProps {
  navigationItem: ISidenavItemConfig;
  active?: boolean;
  selected: boolean;
  onHover: (string) => void;
  onLeave: () => void;
  beta?: boolean;
}
export const FixedSidenavItem: FunctionComponent<ISidenavItemProps> = ({
  navigationItem,
  onHover,
  onLeave,
  selected,
  beta,
  ...props
}) => {
  const { name, params } = useNavItemState(navigationItem, navigationItem.mode);

  const [isHovering, intentRef] = useHoverIntent({ interval: intervalTimesInMilliseconds.hoverIntent });
  const { isMinified, isSidenavOpen, hoveredNavItem } = useNavigationState();
  const openSideMenu = () => {
    onHover(navigationItem);
  };

  const handleClick = () => {
    analyticsService.capture('Navigation', 'Clicked on navigation item', { itemName: name });

    if (isMinified && !isSidenavOpen && navigationItem.expandedNavGroups.length > 0) {
      // when the side nav is minified and closed,
      // we only want to open the submenu instead of navigating to the page on the first click
      openSideMenu();
    } else {
      const urlParams = urlParamsStateService.urlParams.get();
      const savedParams = urlParams[name];

      appRouter.stateService.go(name, savedParams ?? params);
    }
  };

  useEffect(() => {
    if (isMinified && !isSidenavOpen) {
      // when side nav is minified and closed we dont want to trigger hover effects
      return;
    }

    if (isHovering) {
      openSideMenu();
    } else {
      onLeave();
    }
  }, [isHovering, isMinified]);

  return (
    <Link
      {...props}
      onClick={handleClick}
      currentTab
      subject={`${SidenavSubjects.MainItemLinkPrefix}${navigationItem.key}`}
      className={selected ? 'active' : ''}
    >
      <MainNavigationItem
        notificationBadge={params.showBadge}
        isMinified={isMinified}
        highlighted={selected || hoveredNavItem?.key === navigationItem.key}
        ref={intentRef}
      >
        <Group alignItems={'center'} gap={1} vertical>
          <Logo logo={navigationItem.logo} alt={navigationItem.fixedSidenavText} active={selected} mini={isMinified} />
          {!isMinified && (
            <Text size={11} color={'gray.400'} weight={500} whiteSpace="nowrap">
              {navigationItem.fixedSidenavText}
            </Text>
          )}
          {beta && !isMinified ? <BetaLabel /> : null}
        </Group>
      </MainNavigationItem>
    </Link>
  );
};
