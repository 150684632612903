// polyfill window process to prevent this bug in react-markdown
// https://github.com/remarkjs/react-markdown/issues/339
(window as any).process = window.process ?? { cwd: () => {} };
type ReactMarkdownType = typeof import('react-markdown');
type RemarkGfmType = typeof import('remark-gfm');

export const getReactMarkdownModules = async (): Promise<[ReactMarkdownType, RemarkGfmType]> => {
  // DEV-23898 Log Shipping - solve ts with promise.all
  const { default: ReactMarkdown } = await import('react-markdown');
  const { default: remarkGfm } = await import('remark-gfm');

  return [ReactMarkdown, remarkGfm];
};
