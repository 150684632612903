import { useObservable } from '@logz-pkg/observable';
import { ConfirmModal } from '@logz-ui/styleguide';
import { ComponentProps } from 'react';
import { appConfirmModalObservable } from 'services/ui-services/dialog.service';

export const useAppConfirmModal = () => {
  return {
    ...useObservable(appConfirmModalObservable),
    setProps: (props: ComponentProps<typeof ConfirmModal>) => {
      appConfirmModalObservable.set({
        ...appConfirmModalObservable.get(),
        ...props,
      });
    },
  };
};
