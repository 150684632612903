import React, { PropsWithChildren } from 'react';
import { Card, Color, type ICardProps } from '@logz-ui/styleguide';

type CardProps = Omit<ICardProps, 'ref'>;
interface IGraphContainerProps extends CardProps {
  height?: string | number;
  width?: string | number;
  loading?: boolean;
  color?: Color;
  loadingType?: ICardProps['loadingType'];
  borderless?: boolean;
}

export const GraphContainer: React.FC<PropsWithChildren<IGraphContainerProps>> = ({
  height = '100%',
  width = '100%',
  loading,
  p = 4,
  children,
  color,
  loadingType = 'graph',
  borderless,
  ...props
}) => {
  return (
    <Card
      backgroundColor={color}
      loadingType={loadingType}
      borderRadius={0}
      padding={p}
      loading={loading}
      height={height}
      width={width}
      borderless={borderless}
      {...props}
    >
      {children}
    </Card>
  );
};
