import { metricsAccountApiService } from '@logz-pkg/frontend-services';
import { MetricsStoreTypeModel } from '@logz-pkg/models';
import { Observable, useObservable } from '@logz-pkg/observable';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { useEffect } from 'react';

const NotReady = null;

class M3dbAccountsStateService {
  readonly accounts = new Observable<MetricsStoreTypeModel[]>(NotReady);

  fetchAccounts = async (id?: number): Promise<MetricsStoreTypeModel[]> => {
    let accounts;

    if (this.accounts.get() !== NotReady) {
      return this.accounts.get();
    }

    try {
      accounts = id ? metricsAccountApiService.getAccountById(id) : await metricsAccountApiService.getAllM3DB();

      this.accounts.set(accounts);
    } catch (err) {
      accounts = [];
    }

    return accounts;
  };

  clear() {
    this.accounts.set(NotReady);
  }
}

export const m3dbAccountsStateService = new M3dbAccountsStateService();

export const useM3dbAccounts = () => {
  const m3dbAccounts = useObservable<MetricsStoreTypeModel[]>(m3dbAccountsStateService.accounts);
  const {
    action: fetchAccounts,
    isPending,
    error,
  } = useAsyncAction<MetricsStoreTypeModel[]>(m3dbAccountsStateService.fetchAccounts, {
    initialIsPending: true,
  });

  useEffect(() => {
    fetchAccounts();
  }, []);

  return { m3dbAccounts, isPending, error };
};
