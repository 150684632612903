import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHover } from '@logz-pkg/react-hooks';
import { Popover } from '@logz-ui/styleguide';
import { useObservable } from '@logz-pkg/observable';
import type { MenuActions, MenuData, MenuLocation } from '../types';
import { useGraphContext } from '../GraphTheme.provider';
import { SeriesContextMenu } from './SeriesContextMenu';

const StyledMenuLAnchor = styled.div<MenuLocation>`
  position: fixed;
  top: ${({ y }) => `${y + 10}px`};
  left: ${({ x }) => `${x + 2}px`};
  z-index: 1;
`;

type GraphActionsMenuProps = { menuActions: MenuActions; onClose: () => void };

type ComponentProps = GraphActionsMenuProps & {
  menuRef: React.MutableRefObject<HTMLElement>;
  isOpen: boolean;
  menuData: MenuData;
};

const Component: React.FC<ComponentProps> = ({ menuRef, onClose, menuActions, isOpen, menuData }) => {
  return isOpen ? (
    <SeriesContextMenu ref={menuRef} menuData={menuData} onClose={onClose} menuActions={menuActions} />
  ) : null;
};

export const GraphActionsMenuContent: React.FC<Partial<ComponentProps>> = ({
  menuActions,
  onClose,
  isOpen,
  menuData,
}) => {
  const { ref: menuRef } = useHover();
  const [isFirstOpen, setIsFirstOpen] = useState<boolean>(true);

  const closeMenuRefOnOutsideClick = event => {
    if (isFirstOpen) {
      return setIsFirstOpen(false);
    }

    if (!menuRef?.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeMenuRefOnOutsideClick);
    document.getElementsByClassName('service-overview-page-body')?.[0]?.addEventListener('scroll', onClose);

    return () => {
      document.removeEventListener('click', closeMenuRefOnOutsideClick);
      document.getElementsByClassName('service-overview-page-body')?.[0]?.removeEventListener('scroll', onClose);
    };
  }, [menuRef?.current]);

  if (!menuData) return null;

  return (
    <Popover
      open={isOpen}
      Content={{ Component, props: { isOpen, menuRef, menuData, onClose, menuActions } }}
      placement={'bottom'}
      appendToBody
    >
      <StyledMenuLAnchor x={menuData.location.x} y={menuData.location.y} />
    </Popover>
  );
};

export const GraphActionsMenu: React.FC<GraphActionsMenuProps> = ({ menuActions, onClose }) => {
  const { isMenuOpenO, menuDataO } = useGraphContext();
  const [isOpen, menuData] = [useObservable(isMenuOpenO), useObservable(menuDataO)];

  return isOpen && <GraphActionsMenuContent {...{ isOpen, menuActions, menuData, onClose }} />;
};
