import { useMemo, useRef } from 'react';
import { Options, SelectEventObject } from 'highcharts';
import { graphUtils } from '../../../utils/graph-utils';
import { useGraphContext } from '../../../GraphTheme.provider';
import { IGetOptionsProps } from '../../../types';

type UseChartOptions = {
  width: IGetOptionsProps['width'];
  height: IGetOptionsProps['height'];
  onSelection: IGetOptionsProps['onSelection'];
  events: IGetOptionsProps['events'];
  overrideChartOptions: IGetOptionsProps['overrideChartOptions'];
};

export const useChartOptions = ({ width, height, onSelection, events, overrideChartOptions }: UseChartOptions) => {
  const { handleEventMenuClick, eventMenuDataO } = useGraphContext();
  const prevGraphWidth = useRef<number | null>(null);
  const prevEvents = useRef<IGetOptionsProps['events'] | null>(null);

  const chartOptions: Options['chart'] = useMemo(() => {
    return {
      width,
      height,
      style: {
        fontSize: '14px',
      },
      backgroundColor: 'transparent',
      ...(onSelection && {
        zooming: {
          type: 'x',
          mouseWheel: false,
        },
      }),
      events: {
        load() {
          const chartContainerMouseMove = (event: MouseEvent) => {
            const containerCoords = this.container.getBoundingClientRect();
            const relativeMouseX = event.pageX - containerCoords.x;
            const relativeMouseY = event.pageY - containerCoords.y;

            this.mouseCoords = { x: relativeMouseX, y: relativeMouseY };
          };

          this.container.addEventListener('mousemove', chartContainerMouseMove);
        },
        ...(events && {
          render() {
            const { chartWidth } = this;
            const groupedEvents = graphUtils.groupEventsByDensity(events, this);

            if (prevGraphWidth.current !== chartWidth || prevEvents.current !== events) {
              graphUtils.renderEventPlotlines({
                chart: this,
                groupedEvents,
                onClick: handleEventMenuClick,
                menuObservable: eventMenuDataO,
              });

              prevGraphWidth.current = width;
              prevEvents.current = events;
            }

            graphUtils.addClickHandlerToEventPlotlineLabels({
              chart: this,
              groupedEvents,
              onClick: handleEventMenuClick,
              menuObservable: eventMenuDataO,
            });
          },
        }),
        ...(onSelection && {
          selection: (event: SelectEventObject): boolean => {
            onSelection(event.xAxis[0].min, event.xAxis[0].max);

            return false;
          },
        }),
      },
      spacingTop: 3,
      spacingBottom: 0,
      ...overrideChartOptions,
    };
  }, [width, height, onSelection, events, handleEventMenuClick, overrideChartOptions]);

  return chartOptions;
};
