import { AIDataSource } from '@logz-pkg/enums';
import { Observable } from '@logz-pkg/observable';

class AiDataSourceService {
  readonly dataSource = new Observable<AIDataSource>(null);

  setDataSource = (dataSource: AIDataSource): void => {
    this.dataSource.set(dataSource);
  };
}

export const aiDataSourceService = new AiDataSourceService();
