import { AppMode, ProductKeys } from '@logz-pkg/enums';
import { ISidenavItemConfig } from './item-configs';
import { ExtendedLogos } from 'ui/components/shared/Logo/Logo';

export const k8sLuiItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'K8S 360',
  mode: AppMode.OPERATIONS,
  expandedSidenavTitle: 'K8S 360',
  key: ProductKeys.K8S360,
  logo: ExtendedLogos.K8S360,
  targetStateName: 'dashboard.k8s360',
  expandedNavGroups: [],
};
