import { Group, Text } from '@logz-ui/styleguide';
import { EmptyStateActionBox } from '../EmptyStateActionBox';

export const DemoData = () => {
  return (
    <Group vertical gap={3} fullWidth>
      <Text weight={700} size={20} variant={'dark'}>
        Not ready yet, but want to explore Jaeger UI?
      </Text>
      <EmptyStateActionBox
        title={'Experiment with the HotROD demo app'}
        contentText={'Zoom into demo traces within minutes!'}
        variant={'primary'}
        href={'https://docs.logz.io/user-guide/distributed-tracing/trace-hotrod-demo'}
        buttonText={'Use demo data'}
      />
    </Group>
  );
};
