import { TourType } from './types';

interface IPendoTours {
  id: string;
}

export const pendoTours: { [key in TourType]: IPendoTours } = {
  [TourType.Siem]: { id: 'V-aFK2p5cuV_jG7ULGxbE2_HQYQ' },
  [TourType.Metrics]: { id: 'EqBQhjp187foHW9jtDAjR0UrhW0' },
  [TourType.GrandTour]: { id: 'lohiooCHkbe7-hBFs0bIAyGqcts' },
  [TourType.Kibana]: { id: 'dw3S4xqugcn0My85CpRftUn89o4' },
  [TourType.LogShipping]: { id: '_-i5ySRBAued3EHqmz_OkP-ndYk' },
  [TourType.ManageAccount]: { id: '2cXjajKB8HKtfiSiTNq32VcGabc' },
  [TourType.SaveQuery]: { id: 'uB_neWLyrgnQLP-E9fK5O2kJI40' },
  [TourType.CreateAlert]: { id: 'ic6PAoh3BDlUUsQcPQEuc9AQjy8' },
  [TourType.RunKibanaQuery]: { id: '6P43kHRZacehdggo_lr3E5Ams4A' },
  [TourType.SearchInExplore]: { id: 'MO59aT0tsOsnK3U_0OAzXNwM0vQ' },
  [TourType.SaveSearchInExplore]: { id: 'fSyg9uo4zSjYfzQpoCTgRzX47fE' },
  [TourType.GroupByInExplore]: { id: '22FiJqWGVudtoPLi77zUkXtwcXk' },
};
