import React from 'react';
import { InlineNotification } from '@logz-ui/styleguide';
import { AIDataSource } from '@logz-pkg/enums';
import { useAiDataSource } from '../../../state/data-source/data-source.hook';

export const AiChatGeneralResultsInfo: React.FC = () => {
  const { dataSource } = useAiDataSource();

  if (dataSource !== AIDataSource.CurrentPageSearch) return null;

  return (
    <InlineNotification variant={'info'} noClose>
      Note: Results are based on the latest data from your search.
    </InlineNotification>
  );
};
