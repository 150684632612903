import { UserRole } from '@logz-pkg/enums';

export enum TourType {
  Kibana = 'kibanaBasic',
  LogShipping = 'logShipping',
  GrandTour = 'grandTour',
  Siem = 'siem',
  Metrics = 'metrics',
  ManageAccount = 'manageAccount',
  SaveQuery = 'saveQuery',
  CreateAlert = 'createAlert',
  RunKibanaQuery = 'runKibanaQuery',
  SearchInExplore = 'searchInExplore',
  SaveSearchInExplore = 'saveSearchInExplore',
  GroupByInExplore = 'groupByInExplore',
}

type ITourSessionParams = IPendoSessionParams;

export type IMetricsTourParams = IMetricsPendoParams;

export type ITracingTourParams = ITracingPendoParams;

export interface IShowTourParams {
  tourType: TourType;
  isTriggeredByUser?: boolean;
}

export interface IStartSessionParams {
  targetWindow?: Window;
  sanitizeUrl?: (url: string) => string;
  isMainWindow?: boolean;
}
export interface ITourService {
  isDisabled(): Promise<boolean>;
  updateTracingParams(...params): Promise<void>;
  startSession(params: IStartSessionParams): Promise<void>;
  showTour(params: IShowTourParams): Promise<void>;
  reloadParams?(): Promise<void>;
}

export type SessionTourParams = {
  params: IMetricsTourParams & ITracingTourParams & ITourSessionParams;
  userId: number;
};

export interface IMetricsPendoParams {
  isMetricsActive: boolean;
  metricsAccountCreateDate?: any;
}

export interface ITracingPendoParams {
  daysLeftForTracingTrial?: number;
  tracingPlanType?: any;
  isTracingActive?: boolean;
}

export interface IPendoSessionParams {
  accountId: number;
  accountCreatedDate: any;
  planType: any;
  role: UserRole;
  featureFlags: any;
  isLiftr?: boolean;
}
