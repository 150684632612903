import { OperatingSystem } from '@logz-pkg/enums';
import { httpService } from '@logz-pkg/frontend-services';

const baseUrl = '/cms/docs';

async function generateYamlConfigurationTemplates(os: OperatingSystem): Promise<any> {
  const windowsUrl = `${baseUrl}/31K5Yftt7O2MqSWeEGk0OE`;
  const linuxUrl = `${baseUrl}/3rGVsCeBegGwKOm06SUGaS`;
  const yamlConfigurationTemplateUrl = os === OperatingSystem.Windows ? windowsUrl : linuxUrl;
  const response = await httpService.get(yamlConfigurationTemplateUrl);
  const yamlConfigurationTemplates = {
    [os.toLowerCase()]: response.data.content,
  };

  return yamlConfigurationTemplates;
}

async function generateYamlLogTypeSection(): Promise<any> {
  const logTypeSectionUrl = `${baseUrl}/6cgBMj4zbGG6USGsMSW2a4`;
  const response = await httpService.get(logTypeSectionUrl);

  return response.data.content;
}

async function generateLogTypes(): Promise<any> {
  const response = await httpService.get(`${baseUrl}/NRit1qhDsykUqwYsYC4W8`);

  const logTypes = response.data.content
    .split('\n')
    .map(logType => {
      const logTypeSplitted = logType.split(',');

      return {
        name: logTypeSplitted[0].trim(),
        value: logTypeSplitted[1].trim(),
      };
    })
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  return logTypes;
}

export const LogShippersApiService = {
  generateYamlConfigurationTemplates,
  generateYamlLogTypeSection,
  generateLogTypes,
};
