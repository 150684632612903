import { Color, colors } from '@logz-ui/styleguide';
import { getSeries } from '@logz-ui/graphs';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { productNames } from 'ui/components/HomeDashboard/Components/shared';
import {
  GetCompareToSeriesParams,
  GetGroupByStateSeries,
  NormalizeLogsParams,
} from 'ui/components/Explore/ContentPane/Graphs/types';
import { filtersStateService } from 'ui/components/Explore/state/filters-state.service';
import { preDefinedGraphColors } from 'ui/components/Explore/constants';
import { getLegendIndex } from 'ui/components/Explore/ContentPane/Graphs/utils/graph-utils';
import { HistogramPoint } from 'ui/components/shared/Timeframe/utils';

const normalizeLogs = (logs: any[], { manipulateX }: NormalizeLogsParams = {}) => {
  return logs.map(({ time, key_as_string, count, doc_count }) => {
    let xTime = moment(time ?? key_as_string);

    if (manipulateX) {
      xTime = manipulateX(xTime);
    }

    return {
      x: xTime.valueOf(),
      y: count ?? doc_count,
      data: { product: 'Logs' },
    };
  });
};

const getLogSeries = (logs: HistogramPoint[], color?: Color) => {
  const series = getSeries.bar({
    id: 'Logs',
    series: logs ? normalizeLogs(logs) : [],
    color: color ? color : 'product.logs',
    name: productNames.Logs,
  });

  return series ? [series] : [];
};

const getCompareToSeries = ({ logs, compareToLogs, compareToValue, comparedText, color }: GetCompareToSeriesParams) => {
  const manipulateX = x => x.add(compareToValue[0], compareToValue[1]);
  const normalizedLogs = normalizeLogs(logs || []);
  const normalizedCompareToLogs = isEmpty(compareToLogs)
    ? normalizedLogs.map(v => ({ ...v, y: 0 }))
    : normalizeLogs(compareToLogs || [], { manipulateX });

  const logsSeries = getSeries.line({
    id: 'Logs',
    series: normalizedLogs,
    color: 'royalBlue.700',
    name: `Current`,
  });

  const compareToSeries = getSeries.area({
    id: `${compareToValue[0]} ${compareToValue[1]} comparison`,
    series: normalizedCompareToLogs,
    color: color ? color : 'product.logs',
    name: comparedText,
  });

  return [compareToSeries, logsSeries];
};

const getGroupByStateSeries = ({
  groupByState,
  compareToGroupByState,
  graphQueryRunning,
  getValueColor,
  groupBy,
}: GetGroupByStateSeries) => {
  if (!groupByState) return [];

  const legendIndexes = getLegendIndex(groupByState);

  const series = groupByState.map((seriesData, i) => {
    return getSeries.bar({
      id: seriesData.key,
      series: seriesData.series
        ? seriesData.series.map(({ time, count }, j) => ({
            x: moment(time).valueOf(),
            y: count,
            data: {
              product: seriesData.key,
              label: filtersStateService.getFieldValueLabel(groupBy, seriesData.key),
              compareTo: compareToGroupByState?.[i]?.series?.[j]?.count || 0,
              delta: compareToGroupByState?.[i]?.series?.[j]?.count
                ? count - compareToGroupByState[i].series[j].count
                : count,
            },
          }))
        : [],
      graphHexColor: graphQueryRunning
        ? colors.gray[400]
        : preDefinedGraphColors[seriesData.key.toLowerCase()] ?? getValueColor(seriesData.key),
      name: seriesData.key,
      legendIndex: legendIndexes[i],
    });
  });

  return series ? series : [];
};

export const seriesUtils = {
  getLogSeries,
  getCompareToSeries,
  getGroupByStateSeries,
};
