import React from 'react';
import { ISelectOption, Select, SelectSizes } from '@logz-ui/styleguide';

export interface ILogTypeSelectProps {
  label?: string;
  helper?: string;
  className?: string;
  name?: string;
  placeholder?: string;
  borderless?: boolean;
  disabled?: boolean;
  error?: boolean | string;
  autoFocus?: boolean;
  defaultValue?: ISelectOption<string>;
  options?: ISelectOption<string>[];
  onChange?(option: { label: string; value: string }): void;
  onBlur?: any;
  size?: SelectSizes;
  isLoadingLogTypes: boolean;
}

export class LogTypeSelect extends React.Component<ILogTypeSelectProps> {
  static defaultProps = {
    placeholder: 'Select...',
  };

  handleChange(selectOption, { action }: { action: string }) {
    if (action === 'select-option') {
      this.props.onChange(selectOption.value);

      return;
    }

    this.props.onChange(null);
  }

  render() {
    const { label, name, placeholder, borderless, disabled, className, error, size, ...props } = this.props;

    return (
      <Select
        subject={'log-type'}
        label={label}
        className={className}
        name={name}
        placeholder={placeholder}
        borderless={borderless}
        defaultOptions={true}
        disabled={disabled}
        isLoading={this.props.isLoadingLogTypes}
        options={this.props.options}
        onChange={(val, action) => this.handleChange(val, action)}
        error={error}
        size={size}
        {...props}
      />
    );
  }
}
