import { ElasticsearchEnhancedFilterModel, ElasticsearchFieldModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { PopoverHandle } from '@logz-ui/styleguide/components/popover/Popover.component';
import React from 'react';
import { FilterInputValue, InputStage, Options } from '../types';
import { smartSelectUtils } from '../helpers/utils';
import { Operator } from 'ui/components/Explore/types';

const defaultFilterValue: FilterInputValue = {
  field: null,
  negate: null,
  operator: null,
  value: null,
};

export class SelectStateService {
  // Select items state
  highlightedItemIndexO = new Observable<number | null>(null);
  selectedItemIndexO = new Observable<number | null>(null);
  highlightedStepIndexO = new Observable<number | null>(null);
  selectedStepIndexO = new Observable<number | null>(null);

  // Input field state
  isCreatingNewTagO = new Observable<boolean>(false);
  inputValueO = new Observable<string>('');
  placeHolderO = new Observable<string>('');
  isFilterValidO = new Observable(true);
  shouldSubmitO = new Observable<boolean>(false);
  inputRefO = new Observable<HTMLInputElement | null>(null);

  fieldsO = new Observable<Options<ElasticsearchFieldModel>>([]);
  operatorsO = new Observable<Operator[]>([]);
  valuesO = new Observable<Options<ElasticsearchEnhancedFilterModel['value']>>([]);

  // Input stage state( making new filter )
  inputStageO = new Observable<InputStage>('field');
  filterValueO = new Observable<FilterInputValue>(defaultFilterValue);

  // Drowdown menu options state
  highlightedOptionO = new Observable<number>(0);

  // Popover state
  isPopoverOpenO = new Observable<boolean>(false);
  popoverHeightO = new Observable<number>(0);
  popoverWidthO = new Observable<number>(0);
  popoverRefO = new Observable<React.RefObject<PopoverHandle> | null>(null);

  reset = ({ removeInputValue }: { removeInputValue?: boolean } = { removeInputValue: true }) => {
    if (removeInputValue) {
      this.isCreatingNewTagO.set(false);
      this.inputValueO.set('');
      this.placeHolderO.set('');
    }

    this.highlightedItemIndexO.set(null);
    this.selectedItemIndexO.set(null);
    this.highlightedStepIndexO.set(null);
    this.selectedStepIndexO.set(null);
    this.isFilterValidO.set(true);
    this.fieldsO.set([]);
    this.operatorsO.set([]);
    this.valuesO.set([]);
    this.inputStageO.set('field');
    this.filterValueO.set(defaultFilterValue);
    this.highlightedOptionO.set(0);
    this.isPopoverOpenO.set(false);
  };

  continueLuceneTag = () => {
    this.isPopoverOpenO.set(true);
  };

  startNewMultiTag = () => {
    this.reset();
    this.isCreatingNewTagO.set(true);
    this.inputStageO.set('field');
    this.isPopoverOpenO.set(true);
    this.inputRefO.get()?.focus();
  };

  selectTagByIndex = (index: number, filter: ElasticsearchEnhancedFilterModel) => {
    this.reset();
    this.isPopoverOpenO.set(true);
    this.selectedItemIndexO.set(index);
    this.inputStageO.set('none');

    const newFilterValue = smartSelectUtils.modelToFilterValue(filter);

    this.filterValueO.set(newFilterValue);
  };
}
