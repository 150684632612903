import { FunctionComponent } from 'react';
import { Button, InlineNotification, List, ListItem } from '@logz-ui/styleguide';
import { LogManagementSubject } from '@logz-pkg/enums';
import { FilterTagNames, goToIntegrationsPage } from '../Integrations/utils';
import { ProductEmptyState } from 'ui/components/shared/templates/ProductEmptyState/ProductEmptyState';
import { useFeatureFlagState } from 'ui/state/hooks';
import { videos } from 'ui/components/shared/Video/videos';

interface ILogManagementEmptyState {
  onSuccess?(): void;
  canCreateLogsAccount?: boolean;
}

export const LogManagementEmptyState: FunctionComponent<ILogManagementEmptyState> = ({
  onSuccess,
  canCreateLogsAccount = true,
}) => {
  const { isFeatureEnabled: isATelemetryAgentEnabled } = useFeatureFlagState('TelemetryAgent');

  const sendLogsHandler = async () => {
    await goToIntegrationsPage({ tags: FilterTagNames.LOG_ANALYTICS });
    onSuccess?.();
  };

  const ManualLogsCTA = () =>
    isATelemetryAgentEnabled ? (
      <Button ml={1} variant={'secondary'} subject={LogManagementSubject.SendYourLogs} onClick={sendLogsHandler}>
        Send your own logs
      </Button>
    ) : (
      <Button ml={1} variant={'primary'} subject={LogManagementSubject.SendYourLogs} onClick={sendLogsHandler}>
        Send your logs
      </Button>
    );

  return (
    <ProductEmptyState>
      <ProductEmptyState.ContentSection>
        <ProductEmptyState.Title>Centralize all of your logs at any scale</ProductEmptyState.Title>
        <ProductEmptyState.Description>
          <List variant={'check'} size={'large'}>
            <ListItem>
              Search logs faster with high-performance queries, monitoring visualizations, and advanced analytics
            </ListItem>
            <ListItem>Our Customer Support team will parse your logs for you. Just ask in the chat bot! </ListItem>
            <ListItem>Move older data into low-cost storage tiers to reduce spend </ListItem>
          </List>
        </ProductEmptyState.Description>
        <ProductEmptyState.CallToAction>
          {canCreateLogsAccount ? (
            ManualLogsCTA()
          ) : (
            <InlineNotification
              variant={'info'}
              title={'Contact your main account administrator to gain access'}
              subject={LogManagementSubject.NonAdminInlineNotification}
              noClose
            />
          )}
        </ProductEmptyState.CallToAction>
      </ProductEmptyState.ContentSection>
      <ProductEmptyState.MediaSection src={videos.logManagementDemo} />
    </ProductEmptyState>
  );
};
