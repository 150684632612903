import { alertApiService } from '@logz-pkg/frontend-services';
import { AlertModel, GlobalSearchResultModel, SearchSource } from '@logz-pkg/models';

class LogzAlertApiService {
  async getLogzAlerts(query: string): Promise<GlobalSearchResultModel[]> {
    const results: GlobalSearchResultModel[] = (
      await alertApiService.search({
        filter: { search: query },
      })
    ).results.map((alert: AlertModel) => ({
      source: SearchSource.LogzAlert,
      title: alert.title,
      link: `#/dashboard/triggers/alert-definitions?search=${alert.title}`,
      state: {
        name: 'dashboard.triggers.alert-definitions',
        params: {
          search: alert.title,
        },
      },
      description: alert.description,
      createdBy: alert.createdBy === null ? undefined : alert.createdBy,
      updatedBy: alert.updatedBy === null ? undefined : alert.updatedBy,
    }));

    return results;
  }
}

export const logzAlertApiService = new LogzAlertApiService();
