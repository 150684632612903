import React, { useEffect, useState } from 'react';
import { Flex } from '@logz-ui/styleguide';
import { useAiDataSource } from '../../../state/data-source/data-source.hook';
import { AiChatAgentLoadingMessage } from 'ui/components/AI/Components/Messages/Agent/LoadingFadeText';
import { AiChatGeneralMarkdown } from 'ui/components/AI/Components/Messages/General/Markdown';

export const AiChatGeneralLoadingManager: React.FC = () => {
  const [content, setContent] = useState('•');
  const { isAgent } = useAiDataSource();

  useEffect(() => {
    if (isAgent) return;

    const interval = setInterval(() => {
      setContent(prevContent => {
        if (prevContent.length >= 20) {
          return '•••';
        }

        return `${prevContent}•`;
      });
    }, 700);

    return () => clearInterval(interval);
  }, []);

  return isAgent ? (
    <AiChatAgentLoadingMessage />
  ) : (
    <Flex ml={8} pl={7}>
      <AiChatGeneralMarkdown content={content} outOfContext={false} />
    </Flex>
  );
};
