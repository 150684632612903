import _ from 'lodash';
import { NotificationService } from '@logz-ui/styleguide';
import { UxType, Origin, Permissions, GeneralSettingsSubject } from '@logz-pkg/enums';
import { httpService, eventEmitter, EVENTS, LoggerService, accountService } from '@logz-pkg/frontend-services';
import { kibanaVersion } from '../../app/osd/versions/osd-versions.strategy';
import { userPermissionsService } from '../user-permissions/user-permissions.service';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { upgradeService } from 'services/upgrade-service/upgrade-service';
import { ErrorHandlingService } from 'services/error-handling.service';

let validationInProgress = false;

const validationPromises = new Map([
  ['', Promise.resolve({ isValid: true })],
  ['*', Promise.resolve({ isValid: true })],
]);

const validateQuery = async (query, explain = false) => {
  if (!validationPromises.has(query)) {
    validationInProgress = true;
    validationPromises.set(query, sendQueryForValidation(query, explain));
  }

  return validationPromises.get(query).catch(() => {
    validationPromises.delete(query);

    return false;
  });
};

const isInProgress = () => validationInProgress;

const isQueryContainingLeadingWildcard = query => {
  try {
    if (query && query[0] === '*' && query.length > 1) {
      return true;
    } else if (query && query[0] === '/' && query[query.length - 1] === '/' && query.includes('.*')) {
      return true;
    } else if (query && query.includes(' *') && !query.match(/".* \*.*"/) && !query.match(/: *\*/)) {
      return true;
    }
  } catch (error) {
    LoggerService.logError({ message: `failed to block leading wildcard query`, error });
  }

  return false;
};

async function isLeadingWildcard(query) {
  const isLeadingWildCardDisabled = featureFlagStateService.isFeatureEnabled('disableWildCardQueryV2');

  if (isLeadingWildCardDisabled) {
    return isQueryContainingLeadingWildcard(query);
  }

  return false;
}

const sendQueryForValidation = async (query, explain = false) => {
  try {
    if (await isLeadingWildcard(query)) {
      LoggerService.logWarn({ message: `block leading wildcard query: ${query}` });

      return { isValid: false, reason: 'LeadingWildCardError' };
    }

    const endpointAddress = `/osd/elasticsearch/logzioCustomerKibanaIndex/__kibanaQueryValidator/_validate/query?explain=${explain}&ignore_unavailable=true`;
    const reqData = {
      query: {
        query_string: {
          analyze_wildcard: true,
          query,
        },
      },
    };

    const response = await httpService.post(endpointAddress, reqData, { dontShowProgressBar: true });

    const failExplanations = _.get(response, 'data.explanations', false);
    const isQueryValid = _.get(response, 'data.valid', false);

    if (explain) return { isValid: isQueryValid, reason: failExplanations };

    return Boolean(isQueryValid);
  } catch (error) {
    await LoggerService.logError({
      origin: Origin.KIBANA,
      message: 'failed to fetch query validation result',
      error,
      uxType: UxType.KIBANA_BAR,
    });
  } finally {
    validationInProgress = false;
  }
};

const retrieveElasticIndexedFields = async () => kibanaVersion.getIndexPatternFields();

const applyPrioritizedRefreshMapping = fields => {
  const defaultFields = _.isString(fields) ? [fields] : [];

  fields = _.isArray(fields) ? fields : defaultFields;

  return httpService.post('/mappings/refresh-kibana', { fields });
};

const updateKibanaIndexFeature = async fields => applyPrioritizedRefreshMapping(fields);

const refreshMapping = async (category, location, avoidOsdFrameRefresh, fields) => {
  const canRefreshMapping = await userPermissionsService.hasPermission(Permissions.REFRESH_KIBANA_MAPPINGS);

  if (!canRefreshMapping) {
    NotificationService.danger({
      title: "Couldn't refresh field mapping",
      message: 'You dont have the right permissions.',
    });

    return;
  }

  try {
    accountService.clearAccountLogTypesCache();
    accountService.clearAccountFieldsCache();

    await updateKibanaIndexFeature(fields);

    NotificationService.success({
      message: 'Field mapping has been refreshed',
      subject: GeneralSettingsSubject.RefreshKibanaMappingNotification,
    });

    if (avoidOsdFrameRefresh) return;

    eventEmitter.emit(EVENTS.RELOAD_PRODUCTS);
  } catch (error) {
    ErrorHandlingService.handleApiError({ title: "Couldn't refresh field mapping", error: error?.data });
  }
};

const ratiosToMessage = [
  {
    from: 0,
    to: 0.6,
    show: false,
  },
  {
    from: 0.6,
    to: 0.8,
    show: true,
    elementClass: 'kuiIcon--warning',
    message: "You are getting closer to the max allowed fields based on OpenSearch's limitations",
  },
  {
    from: 0.8,
    to: 1,
    show: true,
    elementClass: 'kuiIcon--error',
    message:
      "You are very close to maximum allowed mapped fields based on OpenSearch's limitations." +
      ' Once you hit the limit, some fields will not be mapped automatically and you will need to manually' +
      ' map such fields as needed',
  },
  {
    from: 1,
    to: Infinity,
    show: true,
    elementClass: 'kuiIcon--error',
    message:
      "You have reached the maximum allowed mapped fields based on OpenSearch's limitations." +
      ' Any new fields will not be mapped automatically and you will need to manually map ' +
      'such fields as needed',
  },
];

const defaultMaxKibanaFieldsNum = 1000;

const _getMaxKibanaFieldsNum = async () => {
  try {
    const planDetails = await upgradeService.getPlanDetails();

    return planDetails.data.maxKibanaFieldsNum || defaultMaxKibanaFieldsNum;
  } catch {
    return defaultMaxKibanaFieldsNum;
  }
};

const getMessageByRation = function getMessageByRation(fieldCount, maxKibanaFieldsNum) {
  const ratio = fieldCount / maxKibanaFieldsNum;

  return _.find(ratiosToMessage, rationToMessage => rationToMessage.from <= ratio && rationToMessage.to > ratio);
};

const getFieldsThreshold = async indexPatternFields => {
  const maxKibanaFieldsNum = await _getMaxKibanaFieldsNum();

  const fieldCount = indexPatternFields.length;
  const messageByRatio = getMessageByRation(fieldCount, maxKibanaFieldsNum);

  return {
    show: messageByRatio.show,
    tooltip: messageByRatio.message,
    elementClass: messageByRatio.elementClass,
    countDisplayed: `(${fieldCount} / ${maxKibanaFieldsNum})`,
  };
};

export const elasticSearchService = {
  validateQuery,
  isInProgress,
  sendQueryForValidation,
  retrieveElasticIndexedFields,
  updateKibanaIndexFeature,
  refreshMapping,
  getFieldsThreshold,
};
