/* eslint-disable max-lines */
import { useMemo } from 'react';
import { Options } from 'highcharts';
import { merge } from 'lodash';
import { useTheme } from 'styled-components';
import { getBrowserTimeZone } from '../../utils/utils';
import { IGetOptionsProps } from '../../types';
import { useColoredSeries } from './hooks/colored-series.hook';
import { useChartOptions } from './hooks/chart-options.hook';
import { useTimeOptions } from './hooks/time-options.hook';
import { useLegendOptions } from './hooks/legend-options.hook';
import { useTooltipOptions } from './hooks/tooltip-options.hook';
import { useXAxisOptions } from './hooks/xAxis-options.hook';
import { useYAxisOptions } from './hooks/yAxis-options.hook';
import { usePlotOptions } from './hooks/plot-options.hook';

export const useGraphOptions = (
  {
    xStart,
    xEnd,
    width,
    height,
    series,
    mainChart,
    multiPointTooltip = true,
    overrideTooltip = { borderWidth: 0, backgroundColor: 'none' },
    events,
    legend = {},
    showAxis = true,
    showMarkerOnHover = true,
    crosshair,
    axisGridLineWidth = 1,
    overrideYAxisOptions,
    overrideYAxis2Options,
    overrideXAxisOptions,
    overrideChartOptions,
    yAxisTitle = null,
    deepMergeOptions,
    enableMouseTracking = true,
    tickInterval,
    yTickInterval,
    labelsFormatter,
    onSelection = null,
    onClick = null,
    onMouseOver = null,
    duplicatesMenuInSideBar,
    timezone = getBrowserTimeZone(),
  }: IGetOptionsProps = { series: [] },
) => {
  const appTheme = useTheme();

  const chartOptions = useChartOptions({ width, height, onSelection, events, overrideChartOptions });
  const timeOptions = useTimeOptions({ timezone });
  const legendOptions = useLegendOptions({ legend, duplicatesMenuInSideBar, onClick });
  const tooltipOptions = useTooltipOptions({ multiPointTooltip, overrideTooltip });
  const xAxisOptions = useXAxisOptions({
    appTheme,
    axisGridLineWidth,
    crosshair,
    events,
    tickInterval,
    xStart,
    xEnd,
    overrideXAxisOptions,
    showAxis,
  });
  const yAxisOptions = useYAxisOptions({
    appTheme,
    axisGridLineWidth,
    labelsFormatter,
    overrideYAxisOptions,
    overrideYAxis2Options,
    yAxisTitle,
    yTickInterval,
    showAxis,
  });
  const plotOptions = usePlotOptions({
    appTheme,
    enableMouseTracking,
    mainChart,
    onClick,
    onMouseOver,
    showMarkerOnHover,
  });
  const seriesOptions = useColoredSeries({ series });

  return useMemo<Options>(() => {
    const baseOptions: Options = {
      chart: chartOptions,
      time: timeOptions,
      title: { text: '' },
      legend: legendOptions,
      credits: { enabled: false },
      tooltip: tooltipOptions,
      xAxis: xAxisOptions,
      yAxis: yAxisOptions,
      plotOptions,
      series: seriesOptions,
    };

    return deepMergeOptions ? merge(baseOptions, deepMergeOptions) : baseOptions;
  }, [
    chartOptions,
    timeOptions,
    legendOptions,
    tooltipOptions,
    xAxisOptions,
    yAxisOptions,
    plotOptions,
    seriesOptions,
    deepMergeOptions,
  ]);
};
