import { Flex } from '@logz-ui/styleguide';
import { LoadingFadeText } from 'ui/components/shared/LoadingFadeText/LoadingFadeText';

export const AiChatAgentLoadingMessage = () => {
  return (
    <Flex ml={10} pl={5} py={4}>
      <LoadingFadeText textToDisplay={['Analyzing results...', 'Reasoning next steps...', 'Getting more context...']} />
    </Flex>
  );
};
