import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Card, Divider, Flex, Grid, If, Title, Toggle, Tooltip, LearnMoreLink } from '@logz-ui/styleguide';
import { useBoolean } from '@logz-pkg/react-hooks';
import { AuthenticationTypes, AwsS3BucketSubject, SendYourDataTestSubject } from '@logz-pkg/enums';
import { ISecretAccessBucketSettings, ISecretAccessBucketSettingsProps } from './interfaces';
import { getValidationByBucketType } from './validation';
import {
  AccessKeyField,
  BucketField,
  DeleteModal,
  PrefixField,
  SecretKeyField,
  SelectLogTypeField,
  SelectRegionField,
} from './components';
import { IncludeSourcePathField } from './components/IncludeSourcePathField';

export const SecretAccessSettings: React.FunctionComponent<ISecretAccessBucketSettingsProps> = ({
  settings,
  bucketType,
  onSave,
  onDelete,
  regions,
  supportedLogTypes,
  onCancel,
}) => {
  const [logtype, setLogtype] = useState(settings.logsType);
  const { value: isDeleteModalOpen, setTrue: openDeleteModal, setFalse: closeDeleteModal } = useBoolean(false);
  const isSaved = Boolean(settings.id);
  const fakeSecretKey = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX';
  const validationSchema = getValidationByBucketType(bucketType, AuthenticationTypes.SecretAccess);

  const initialValues = settings;

  if (initialValues.id) {
    initialValues.secretKey = fakeSecretKey;
  }

  const handleFormSubmission = async (values: ISecretAccessBucketSettings, actions): Promise<void> => {
    if (values.secretKey === fakeSecretKey) {
      values.secretKey = undefined;
    }

    const saveBucketResult = await onSave(values);

    actions.setSubmitting(false);

    if (saveBucketResult) {
      values.secretKey = fakeSecretKey;
    }
  };

  return (
    <Card p={6} className={`log-shipping__${bucketType}-settings`}>
      <Formik onSubmit={handleFormSubmission} validationSchema={validationSchema} initialValues={settings}>
        {({ setFieldValue, submitForm, values, isSubmitting }) => (
          <Form>
            <Grid columns={12}>
              <Grid.Cell span={9}>
                <Title variant={'deprecatedDialogSmall'} inline mr={1} my={0}>
                  Key access configuration
                </Title>
                <LearnMoreLink about={'https://docs.logz.io/user-guide/give-aws-access-with-access-keys/'} />
              </Grid.Cell>
              <Grid.Cell last>
                <Flex justifyContent={'flex-end'}>
                  <Toggle
                    name={'active'}
                    label={values.active ? 'Active' : 'Inactive'}
                    value={values.active}
                    subject={AwsS3BucketSubject.BucketActiveToggle}
                    onChange={isActive => setFieldValue('active', isActive)}
                  />
                </Flex>
              </Grid.Cell>
              <Grid.Cell span={12}>
                <Divider dashed />
              </Grid.Cell>
              <Grid.Cell span={6}>
                <BucketField />
              </Grid.Cell>
              <Grid.Cell span={6}>
                <PrefixField authenticationType={AuthenticationTypes.SecretAccess} bucketType={bucketType} />
              </Grid.Cell>
              <Grid.Cell span={6}>
                <AccessKeyField />
              </Grid.Cell>
              <Grid.Cell span={6}>
                <SecretKeyField />
              </Grid.Cell>
              <If condition={Boolean(regions)}>
                <Grid.Cell span={6}>
                  <SelectRegionField options={regions} />
                </Grid.Cell>
              </If>
              <If condition={Boolean(supportedLogTypes)}>
                <SelectLogTypeField
                  options={supportedLogTypes}
                  setFieldValue={setFieldValue}
                  setLogType={setLogtype}
                  selectedLogType={logtype}
                />
              </If>
              <Grid.Cell span={12}>
                <IncludeSourcePathField />
              </Grid.Cell>
              <Grid.Cell>
                <Button onClick={submitForm} loading={isSubmitting} subject={SendYourDataTestSubject.SaveBucketButton}>
                  Save
                </Button>
              </Grid.Cell>
              <If condition={!isSaved}>
                <Grid.Cell>
                  <Button variant={'tertiary'} onClick={() => onCancel(settings.appOnlyId)}>
                    Cancel
                  </Button>
                </Grid.Cell>
              </If>
              <If condition={isSaved}>
                <Grid.Cell last>
                  <Flex justifyContent={'flex-end'}>
                    <Tooltip title="Delete">
                      <Button
                        round
                        icon={'trash-can-regular'}
                        onClick={openDeleteModal}
                        subject={AwsS3BucketSubject.DeleteBucketButton}
                      />
                    </Tooltip>
                  </Flex>
                </Grid.Cell>
              </If>
            </Grid>
          </Form>
        )}
      </Formik>
      <If condition={isDeleteModalOpen}>
        <DeleteModal
          onConfirm={async () => {
            closeDeleteModal();
            await onDelete(settings.id, settings.logsType);
          }}
          onClose={closeDeleteModal}
          authType={AuthenticationTypes.SecretAccess}
        />
      </If>
    </Card>
  );
};
