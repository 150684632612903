import { colorService } from '@logz-pkg/utils';
import { colors } from '@logz-ui/styleguide';

export const graphLightV2Theme = {
  gridLineColor: colors.royalBlue[100],
  labelColor: colors.royalBlue[900],
  lineColor: colors.gray[800],
  crosshairColor: colors.gray[900],
  barCrosshairColor: colorService.convertHexToRgba('#E6EAEC', 0.6),
  oppositeYAxisLineColor: 'black',
  oppositeYAxisLabelColor: '#09A2C3',
  markerOnHoverColor: 'black',
  eventActionMenuCardBorderColor: colors.blue[400],
  eventActionMenuCardTextColor: colors.gray[1000],
  eventActionMenuCardButtonTextColor: colors.royalBlue[1000],
  eventActionMenuCardIconColor: colors.royalBlue[1000],
};
