import { appStateService, LoggerService } from '@logz-pkg/frontend-services';
import { ExploreOnboardingStateModel, ExploreStateModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { cloneDeep, merge } from 'lodash';

const VERSION = 0;
const STATE_NAME = 'explore-onboarding';
const defaultState: ExploreOnboardingStateModel = {
  _migrated: true,
  exploreAsDefault: true,
};

const getPreviousVersion = async (): Promise<ExploreOnboardingStateModel> => {
  const prevSavedState: ExploreStateModel = await appStateService.get('explore-states', 4);

  const state: ExploreOnboardingStateModel = cloneDeep(defaultState);

  if (prevSavedState?.onboarding?.exploreAsDefault !== undefined) {
    state.exploreAsDefault = prevSavedState.onboarding.exploreAsDefault;
  }

  LoggerService.logInfo({
    message: `explore migrated explore-onboarding from previous version`,
  });

  return state;
};

class ExploreOnboardingStatesService {
  state = new Observable<ExploreOnboardingStateModel>(defaultState);

  clear = () => this.state.set(defaultState);

  resetRecents = async () => {
    await appStateService.set(STATE_NAME, { ...this.state.get(), recent: [] });
    this.clear();
  };

  private set = async (value: ExploreOnboardingStateModel) => {
    const current = this.state.get();

    const newState: ExploreOnboardingStateModel = {
      _migrated: value._migrated ?? current._migrated ?? defaultState._migrated,
      exploreAsDefault: value.exploreAsDefault ?? current.exploreAsDefault ?? defaultState.exploreAsDefault,
    };

    appStateService.set(STATE_NAME, newState, VERSION);
  };

  get = async (): Promise<ExploreOnboardingStateModel> => {
    const savedInAppState = await appStateService.get(STATE_NAME, VERSION);
    let newState: ExploreOnboardingStateModel;

    if (!savedInAppState?._migrated) {
      newState = await getPreviousVersion();
      this.set(newState);
    } else {
      newState = merge({}, defaultState, savedInAppState);
    }

    this.state.set(newState);

    return newState;
  };

  setExploreAsDefault = async (exploreAsDefault: boolean) => {
    const newState = { ...(await this.get()), exploreAsDefault };

    this.state.set(newState);
    await this.set(newState);
  };
}

export const exploreOnboardingStatesService = new ExploreOnboardingStatesService();
