import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Divider, Group, Tag, Text, Title } from '@logz-ui/styleguide';
import { SidenavSubjects } from '@logz-pkg/enums';
import { ISidenavItemConfig } from '../item-configs';
import { useAccountPlanState, useSessionState } from 'ui/state/hooks';

interface IExpandedSidenavHeader {
  hoveredMainNavItem: ISidenavItemConfig;
}

const HeaderContainer = styled(props => <Group p={5} gap={1} vertical {...props} />)`
  border-bottom: 3px solid ${({ theme }) => theme.colors.gray[300]};
  width: 240px;
`;

const UsernameText = styled(Text)`
  max-width: 140px;
`;

export const ExpandedSidenavHeader: FunctionComponent<IExpandedSidenavHeader> = ({ hoveredMainNavItem }) => {
  const { loggedInUser } = useSessionState();
  const { accountPlan } = useAccountPlanState();

  return (
    <HeaderContainer>
      <Tag subject={SidenavSubjects.ExpandedHeaderPlanType}>{accountPlan?.type}</Tag>
      <Title size={18} subject={SidenavSubjects.ExpandedHeaderTitle}>
        {hoveredMainNavItem?.expandedSidenavTitle}
      </Title>
      <Group>
        <UsernameText ellipsis size={12} color={'gray.800'} subject={SidenavSubjects.ExpandedHeaderUsername}>
          {loggedInUser?.username}
        </UsernameText>
        <Divider vertical color={'gray.800'} size={'s'} />
        <Text size={12} color={'gray.800'} subject={SidenavSubjects.ExpandedHeaderRole}>
          {loggedInUser?.getHumanReadableRole()}
        </Text>
      </Group>
    </HeaderContainer>
  );
};
