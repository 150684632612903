import { AccountType } from '@logz-pkg/enums';
import { sessionApiService, cacheProvider, sessionStateService } from '@logz-pkg/frontend-services';
import { metricsService } from '../metrics/metrics.service';
import { IMetricsTourParams, ITracingTourParams, SessionTourParams } from './types';
import { tracingService } from 'services/tracing/tracing.service';

const getUserParams = async () => {
  const {
    user: loggedInUser,
    account: loggedInAccount,
    userSummary: { planType, accountCreatedDate },
  } = await sessionApiService.get();

  return {
    userId: loggedInUser.id,
    accountId: loggedInAccount.id,
    planType,
    accountCreatedDate,
    accountType: loggedInAccount.type,
    role: loggedInUser.role,
  };
};

const getFeatureFlags = async () => {
  const { featureFlags } = await sessionApiService.get();

  return { featureFlags: `|${featureFlags.join('|')}|` };
};

const getAllParams = async (): Promise<SessionTourParams> => {
  let metricsTourParams: IMetricsTourParams;
  let tracingTourParams: ITracingTourParams | undefined;

  const [{ userId, accountId, planType, accountCreatedDate, accountType, role }, { featureFlags }] = await Promise.all([
    getUserParams(),
    getFeatureFlags(),
  ]);

  const { userSummary } = sessionStateService.session.get();
  const isLiftr = userSummary?.liftrAccount;

  if (accountType === AccountType.Security) {
    metricsTourParams = { isMetricsActive: false };
    tracingTourParams = {};
  } else {
    [metricsTourParams, tracingTourParams] = await Promise.all([
      metricsService.getTourParams(),
      tracingService.getTourParams(),
    ]);
  }

  return {
    userId,
    params: {
      accountId,
      planType,
      accountCreatedDate,
      role,
      featureFlags,
      isLiftr,
      ...metricsTourParams,
      ...tracingTourParams,
    },
  };
};

export const getSessionTourParams = async (): Promise<SessionTourParams> =>
  cacheProvider.get(cacheProvider.CacheKeys.SESSION_TOUR_PARAMS, () => getAllParams(), 60);
