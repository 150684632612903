import { Observable } from '@logz-pkg/observable';
import {
  AiChatRoles,
  BedrockMessage,
  ChatResponse,
  AgentChatRecord,
  ChatRole,
  RecordType,
  SearchChatRecord,
  AiHistoryItemModel,
  bedrockUtils,
} from '@logz-pkg/models';
import { AIDataSource } from '@logz-pkg/enums';
import { parseSSEData } from '@logz-pkg/frontend-services';
import moment from 'moment';
import { aiUtils } from '../../utils';
import { aiStateService } from '../ai-state.service';
import { aiDataSourceService } from '../data-source/data-source.service';
import { aiFeedbackService } from '../feedback/feedback.service';
import { aiConversationsService } from '../conversations/conversations.service';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

class AiHistoryService {
  readonly agent = new Observable<AgentChatRecord[]>([]);
  readonly assistant = new Observable<SearchChatRecord[]>([]);
  private addAgentHistory = (chatResponse: ChatResponse, isConclusion?: boolean) => {
    const history = this.agent.get();
    let lastConclusionIndex = history.findLastIndex(item => item.role.includes('User'));

    lastConclusionIndex = lastConclusionIndex > -1 ? lastConclusionIndex : 0;

    const record: AgentChatRecord = bedrockUtils.buildAgentRecordByIndex({
      index: history.slice(lastConclusionIndex).filter(item => item.type === 'Collapse').length,
      chatResponse,
    });

    if (history.at(-1)?.type !== 'Loading') {
      if (isConclusion) {
        record.type = 'Conclusion';
      }

      let historyWithoutCurrentStreamedItem;

      if (history.at(-1).role === 'User') {
        historyWithoutCurrentStreamedItem = history;
      } else {
        historyWithoutCurrentStreamedItem = history?.slice?.(0, -1);
      }

      this.agent.set([...historyWithoutCurrentStreamedItem, record]);
    } else {
      this.agent.set([...history, record]);
    }
  };
  agentSetLoading = ({
    prompt,
    context,
    componentType = 'User',
    dataSource,
  }: {
    prompt: string;
    context: unknown;
    componentType: RecordType;
    dataSource: AIDataSource;
  }) => {
    const agentHistory = this.agent.get();

    this.agent.set([
      ...agentHistory,
      ...bedrockUtils.getLoadingComponents({ prompt, context, componentType, dataSource }),
    ]);
  };
  agentOnError = (chatResponse: ChatResponse) => {
    const history = this.agent.get();
    const error = chatResponse.message;
    const record: AgentChatRecord = { content: error, role: 'Ai', type: 'Error' };

    this.agent.set([...history, record]);
  };
  agentOnData = (chatResponse: ChatResponse, isDone?: boolean, isConclusion?: boolean) => {
    const { status, message } = chatResponse;
    const history = this.agent.get();
    const last = history.at(-1);

    if (status === 'invocation') {
      bedrockUtils.addAgentInvocation(chatResponse, history);
    }

    if (isDone && last.content) {
      aiUtils.loggerInfo('Agent response', {
        sessionId: aiStateService.sessionId.get(),
        isConclusion,
        content: parseSSEData(last.content),
      });

      history.at(-1).isDoneContent = true;
    }

    if (isConclusion && !message && last.type !== 'Conclusion') {
      this.agent.set([...history, { type: 'Conclusion', role: 'Ai', content: '' }]);
    }

    if (!message && !isDone) return;

    if (message && status === 'other') {
      if (last.isDoneContent) {
        const record: AgentChatRecord = { type: 'Collapse', role: 'Ai', content: '', subTitle: '' };

        this.agent.set([...history, record]);
      }

      this.addAgentHistory(chatResponse, isConclusion);
    }
  };

  agentOnDone = async (chatResponse: ChatResponse) => {
    const isAiHistoryEnabled = featureFlagStateService.isFeatureEnabled('aiHistory');

    aiUtils.loggerInfo('Agent final response', {
      sessionId: aiStateService.sessionId.get(),
      chatResponse,
    });

    const history = this.agent.get();

    history.forEach((record, index) => {
      if (index === history.length - 1) record.isLastBeforeConclusion = true;
    });

    const conclusionMsg = chatResponse.message;

    if (conclusionMsg) {
      await aiFeedbackService.setShouldShowFeedback();

      const historyItems = [
        ...history,
        {
          content: conclusionMsg,
          role: 'Ai' as ChatRole,
          type: 'Conclusion' as RecordType,
          isDoneContent: true,
        },
      ];

      this.agent.set(historyItems);

      if (isAiHistoryEnabled) {
        await aiConversationsService.saveConversation({ historyItems });
      }
    }
  };
  assistantSetLoading = (prompt: string) => {
    const history = this.assistant.get();

    this.assistant.set([...history, { content: prompt, role: 'User', type: 'User', createdAt: moment().format() }]);
  };
  assistantSetOutOfContext = (prompt: string) => {
    const history = this.assistant.get();

    aiUtils.loggerInfo('Assistant out-of-context', {
      prompt,
    });

    this.assistant.set([
      ...history,
      {
        content: prompt,
        role: 'User',
        type: 'User',
        outOfContext: true,
      },
    ]);
  };
  assistantOnData = (chatResponse: ChatResponse) => {
    const history = this.assistant.get();
    const historyItem: SearchChatRecord = {
      content: chatResponse.message,
      role: 'Ai',
      type: 'Assistant',
    };

    if (history.at(-1).role === 'User') {
      this.assistant.set([...history, historyItem]);

      return;
    }

    const historyWithoutCurrentItem = history?.length > 1 ? history.slice(0, -1) : [history.at(-1)];

    this.assistant.set([...historyWithoutCurrentItem, historyItem]);
  };
  assistantOnDone = async () => {
    const isAiHistoryEnabled = featureFlagStateService.isFeatureEnabled('aiHistory');
    const history = this.assistant.get();
    const last = history.at(-1);

    last.isDoneContent = true;

    const historyWithoutCurrentItem = history?.length > 1 ? history.slice(0, -1) : [history.at(-1)];

    aiUtils.loggerInfo('Assistant response', {
      sessionId: aiStateService.sessionId.get(),
      dataSource: aiDataSourceService.dataSource.get(),
      chatResponse: last,
    });

    const historyItems = [...historyWithoutCurrentItem, last];

    this.assistant.set(historyItems);

    if (isAiHistoryEnabled) {
      await aiConversationsService.saveConversation({ historyItems });
    }
  };
  assistantBuildReqContent = ({ meta, context }: { meta: SearchChatRecord[]; context: unknown }): BedrockMessage[] => {
    const history = this.assistant.get();
    const requestContent: SearchChatRecord[] = [];
    const promptMeta: SearchChatRecord[] =
      meta.length > 0
        ? [
            {
              content: `${meta.join(' ')}`,
              role: 'User',
              type: 'User',
            },
          ]
        : [];

    if (context) {
      const content = `
          This following data relates to all the following questions:
          ${typeof context === 'string' ? context : JSON.stringify(context)}
          `;

      requestContent.push({ content, role: 'User', type: 'User' });
    }

    requestContent.push(...history, ...promptMeta);

    const payload = requestContent.map(item => ({
      content: item.content,
      role: item.role === 'User' ? AiChatRoles.User : AiChatRoles.Assistant,
    }));

    return payload;
  };
  knowlageBaseIsFirstRequset = () => !this.assistant.get().length;
  clearHistory = () => {
    this.agent.set([]);
    this.assistant.set([]);
  };
  setHistory = (history: AiHistoryItemModel[], isAgent: boolean) => {
    if (isAgent) {
      this.agent.set(history);
    } else {
      this.assistant.set(history);
    }
  };
}

export const aiHistoryService = new AiHistoryService();
