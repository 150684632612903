import { Box, Group, Icon } from '@logz-ui/styleguide';
import { AccountModel } from '@logz-pkg/models';
import styled from 'styled-components';
import React from 'react';

const StyledIcon = styled(Icon)<{ isPinned?: boolean }>`
  svg {
    opacity: ${props => (props.isPinned ? 1 : 0)};
    transition: ${props => (props.isPinned ? 'none' : 'opacity 150ms ease-in')};
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  width: 20px;
  height: 100%;

  line-height: 20px;
  text-align: center;
`;

export const HoverWrapper = styled(Group)`
  &:hover svg {
    opacity: 1;
  }
`;

interface IAccountPin {
  accountId: AccountModel['id'];
  isPinned: boolean;
  onChange(accountId: AccountModel['id'], isPinned: boolean): void;
}

export const AccountPin: React.FC<IAccountPin> = ({ accountId, isPinned, onChange }) => {
  return (
    <StyledBox
      onClick={e => {
        e.stopPropagation();
        onChange(accountId, isPinned);
      }}
    >
      <StyledIcon
        color={'gray.600'}
        icon={isPinned ? 'thumbtack-solid' : 'thumbtack-regular'}
        size={12}
        isPinned={isPinned}
      />
    </StyledBox>
  );
};
