import { FeatureFlags } from '@logz-pkg/enums';
import { sessionStateService } from '@logz-pkg/frontend-services';
import { useObservable } from '@logz-pkg/observable';
import { useEffect, useState } from 'react';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

export const useFeatureFlagState = (featureName: keyof typeof FeatureFlags) => {
  const { featureFlags } = useObservable(sessionStateService.session);
  const [isFeatureEnabled, setIsFeatureEnabled] = useState<boolean>(
    featureFlagStateService.isFeatureEnabled(featureName),
  );

  useEffect(() => {
    const isEnabled = featureFlagStateService.isFeatureEnabled(featureName);

    if (isEnabled !== isFeatureEnabled) {
      setIsFeatureEnabled(featureFlagStateService.isFeatureEnabled(featureName));
    }
  }, [featureName, featureFlags, isFeatureEnabled]);

  return { isFeatureEnabled };
};
