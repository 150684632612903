export enum AvailableLanguages {
  yaml = 'yaml',
  shell = 'shell',
  csharp = 'csharp',
  xml = 'xml',
  powershell = 'powershell',
  go = 'go',
  java = 'java',
  json = 'json',
  javascript = 'javascript',
  js = 'javascript',
  python = 'python',
  bash = 'bash',
}

type HighlightJs = typeof import('highlight.js');

const lazyLoadHighlight = async (): Promise<HighlightJs> => {
  const [hljs, yaml, shell, csharp, xml, powershell, go, java, json, javascript, python, bash] = await Promise.all([
    import('highlight.js/lib/core'),
    import('highlight.js/lib/languages/yaml'),
    import('highlight.js/lib/languages/shell'),
    import('highlight.js/lib/languages/csharp'),
    import('highlight.js/lib/languages/xml'),
    import('highlight.js/lib/languages/powershell'),
    import('highlight.js/lib/languages/go'),
    import('highlight.js/lib/languages/java'),
    import('highlight.js/lib/languages/json'),
    import('highlight.js/lib/languages/javascript'),
    import('highlight.js/lib/languages/python'),
    import('highlight.js/lib/languages/bash'),
    import('highlight.js/styles/night-owl.css'),
  ]);

  hljs.configure({ useBR: true });
  hljs.registerLanguage('yaml', yaml.default);
  hljs.registerLanguage('shell', shell.default);
  hljs.registerLanguage('csharp', csharp.default);
  hljs.registerLanguage('xml', xml.default);
  hljs.registerLanguage('powershell', powershell.default);
  hljs.registerLanguage('go', go.default);
  hljs.registerLanguage('java', java.default);
  hljs.registerLanguage('json', json.default);
  hljs.registerLanguage('javascript', javascript.default);
  hljs.registerLanguage('python', python.default);
  hljs.registerLanguage('bash', bash.default);

  return hljs;
};

let loadPromise;

export const loadHighlight = async () => {
  if (!loadPromise) {
    loadPromise = lazyLoadHighlight();
  }

  return loadPromise;
};
