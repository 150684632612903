import { FunctionComponent } from 'react';
import { TracingProductActivationState } from '@logz-pkg/enums';
import { ActivatedWithNoData } from './EmptyState/ActivatedWithNoData';
import { TracingNotActivated } from './EmptyState/TracingNotActivated';
import { useTracingState } from 'ui/state/hooks';
import { ProductEmptyState } from 'ui/components/shared/templates/ProductEmptyState/ProductEmptyState';
import { videos } from 'ui/components/shared/Video/videos';

interface ITracingEmptyState {
  showTellMeMore?: boolean;
  onSuccess?(): void;
  showOnlyNotActivatedState?: boolean;
}

export const TracingEmptyState: FunctionComponent<ITracingEmptyState> = ({
  showTellMeMore = true,
  onSuccess,
  showOnlyNotActivatedState = false,
}) => {
  const { tracingProductActivationState } = useTracingState();
  const shouldShowNotActivatedState =
    tracingProductActivationState !== TracingProductActivationState.ActivatedNotInUse || showOnlyNotActivatedState;

  return (
    <ProductEmptyState>
      <ProductEmptyState.ContentSection>
        {shouldShowNotActivatedState ? (
          <TracingNotActivated showTellMeMore={showTellMeMore} onSuccess={onSuccess} />
        ) : (
          <ActivatedWithNoData />
        )}
      </ProductEmptyState.ContentSection>
      <ProductEmptyState.MediaSection src={videos.distributedTracingDemo} />
    </ProductEmptyState>
  );
};
