import { FunctionComponent } from 'react';
import {
  Flex,
  Group,
  If,
  FlexColumn,
  colors,
  Text,
  Title,
  Badge,
  Box,
  LearnMoreLink,
  Spinner,
} from '@logz-ui/styleguide';
import styled, { css } from 'styled-components';
import { AnnouncementSubjects, HeaderSubjects } from '@logz-pkg/enums';
import { AnnouncementModel } from '@logz-pkg/models';
import { dateService } from '@logz-pkg/frontend-services';
import { useMarkdown } from 'ui/hooks/markdown.hook';

interface IWhatsNewAnnouncementProps {
  announcement: AnnouncementModel;
}

const AnnouncementContainer = styled(FlexColumn)`
  text-align: left;
  border-bottom: 1px solid ${colors.gray[400]};
  padding: 30px 25px 25px 15px;
  flex-shrink: 0;
  font-size: 15px;
  background-color: transparent;

  ${({ isRead }) =>
    !isRead &&
    css`
      background-color: ${colors.gray[0]};
    `}
`;

const CreatedOnLine = styled(Flex)`
  position: relative;
  background-color: transparent;
`;

const iframeWidth = 390;
const Content = styled(Text).attrs({
  variant: 'secondary',
  size: 16,
  mb: 3,
})`
  word-break: break-word;

  // Reduce size of the video without coercing the editor to do it manually (AWESOMEEE)
  //Based on
  // https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
  // But changed due to confines of css selectors (can't select parent p of an iframe)
  iframe {
    width: ${iframeWidth}px;
    height: ${(iframeWidth + 25) * 0.5625}px;
  }

  // To render the line breaks wanted by the CMS
  p {
    margin: inherit;
  }
`;

const UnreadContainer = styled.div`
  width: 10px;
  min-width: 10px;
`;

export const WhatsNewAnnouncement: FunctionComponent<IWhatsNewAnnouncementProps> = ({ announcement }) => {
  const { isLoading: isLoadingMarkdown, parseMarkdown } = useMarkdown({
    html: true,
    breaks: true,
    typographer: true,
  });

  const markdownToHtml = content => ({
    __html: parseMarkdown(content),
  });

  return (
    <AnnouncementContainer isRead={announcement.isRead} subject={HeaderSubjects.AnnouncementContainer}>
      <Group gap={1}>
        <UnreadContainer>
          {!announcement.isRead && (
            <Badge size={'xxs'} variant={'warning'} subject={HeaderSubjects.UnreadAnnouncementIndicator} />
          )}
        </UnreadContainer>
        <Box>
          <Group vertical>
            <CreatedOnLine alignItems={'center'}>
              <Text>{dateService.format({ date: announcement.createdOn, format: 'MMMM YYYY' })}</Text>
            </CreatedOnLine>
            <Title variant={'deprecatedPanel'}>{announcement.title}</Title>
          </Group>
          {isLoadingMarkdown ? (
            <Spinner fullscreen />
          ) : (
            <Content>
              <Box dangerouslySetInnerHTML={markdownToHtml(announcement.content)} />
            </Content>
          )}
          <If condition={Boolean(announcement.externalLink)}>
            <LearnMoreLink subject={AnnouncementSubjects.LearnMore} about={announcement.externalLink} />
          </If>
        </Box>
      </Group>
    </AnnouncementContainer>
  );
};
