import { httpService, tracingAccountsApiService } from '@logz-pkg/frontend-services';
import axios from 'axios';
import { GlobalSearchResultModel, SearchSource } from '@logz-pkg/models';
import { Headers } from '@logz-pkg/enums';
import { ISpans } from 'ui/kube360/components/TracesPage/types';

class JaegerApiService {
  async getServices(accountId): Promise<string[]> {
    const { data } = await axios.get(`/jaeger-app/api/services`, {
      headers: { [Headers.TracingAccountId]: accountId, ...httpService.getDefaultHeaders() },
    });

    return data.data;
  }

  async getJaegerResults(query: string): Promise<GlobalSearchResultModel[] | null> {
    const tracingAccounts = await tracingAccountsApiService.getAllTracingAccounts();

    if (!tracingAccounts?.length) return null;

    const services = await this.getServices(tracingAccounts[0].accountId);

    if (services) {
      const results: GlobalSearchResultModel[] = services
        .filter((result: string) => result.includes(query))
        .map((result: string) => ({
          source: SearchSource.JaegerService,
          title: result,
          link: `#/dashboard/jaeger/search?service=${result}`,
        }));

      return results;
    }

    return null;
  }

  async getSpansByTraceId(
    traceId: string,
    traceAccountId: number,
  ): Promise<(ISpans & { processes: { [key: string]: { serviceName: string } } })[]> {
    const { data } = await axios.get(`/jaeger-app/api/traces/${traceId}`, {
      headers: { [Headers.TracingAccountId]: traceAccountId, ...httpService.getDefaultHeaders() },
    });

    return data?.data;
  }
}

export const jaegerApiService = new JaegerApiService();
