import { Permissions } from '@logz-pkg/enums';
import { userPermissionsApiService } from '@logz-pkg/frontend-services';

const hasPermission = async (permission: Permissions): Promise<boolean> => {
  const permissions = await userPermissionsApiService.get();

  return permissions.includes(permission);
};

const hasOneOfPermissions = async (permissionsToCheck: Permissions[]): Promise<boolean> => {
  const permissions = await userPermissionsApiService.get();
  const userPermissions = new Set(permissions);

  return permissionsToCheck.some(permission => userPermissions.has(permission));
};

export const userPermissionsService = {
  hasPermission,
  hasOneOfPermissions,
};
