import React, { useEffect, useRef, useState } from 'react';
import { Text, Link, Flex } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { markdownToText } from '@logz-pkg/frontend-services/src/dal/utilities/utilities';
import { AICopilotInvocationMessageSubjects } from '@logz-pkg/enums';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';
import { renderers } from 'ui/components/AI/Components/Messages/General/Markdown';
import { MarkdownContent } from 'ui/components/Integrations/Single/Body/Content';

const StyledRelativeDiv = styled.div`
  position: relative;
`;

const StyledLink = styled(Link)`
  float: right;
  clear: none;
  margin-top: 1lh;
  text-align: end;
  padding-inline-start: 4px;
  shape-outside: inset(1.5lh 0px 0px 0px);
`;

const StyledText = styled(Text)`
  margin: 0;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledHiddenText = styled(Text)`
  white-space: normal;
  visibility: hidden;
  position: absolute;
  z-index: -1;
`;

export const AiChatAgentReadMore: React.FC<MessageType> = props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const hiddenRef = useRef<HTMLDivElement>(null);

  const text = markdownToText(props.content);

  useEffect(() => {
    const checkTruncation = () => {
      if (hiddenRef.current) {
        hiddenRef.current.style.display = 'block';

        const lineHeight = parseFloat(window.getComputedStyle(hiddenRef.current).lineHeight);

        if (hiddenRef.current.scrollHeight > lineHeight * 2) {
          setIsTextTruncated(true);

          if (isExpanded) setIsExpanded(false);

          return;
        }

        hiddenRef.current.style.display = 'none';
        setIsTextTruncated(false);
        setIsExpanded(true);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    hiddenRef.current.style.display = 'none';

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [text]);

  return (
    <StyledRelativeDiv {...generateLogzTestAttributes({ subject: AICopilotInvocationMessageSubjects.ReadMore })}>
      {!isExpanded ? (
        <StyledText>
          {isTextTruncated && <StyledLink onClick={() => setIsExpanded(prevState => !prevState)}>Read more</StyledLink>}
          {text}
        </StyledText>
      ) : (
        <>
          <MarkdownContent markdown={props.content} elementRenderers={renderers} shouldShowLoader={false} />
          {isTextTruncated && (
            <Flex pt={3}>
              <Link onClick={() => setIsExpanded(prevState => !prevState)}>Read less</Link>
            </Flex>
          )}
        </>
      )}
      <StyledHiddenText ref={hiddenRef}>{text}</StyledHiddenText>
    </StyledRelativeDiv>
  );
};
