import { useEffect, useRef } from 'react';

type UseDimensions = {
  height: number;
  width: number;
};

export const useDimensions = ({ height, width }: UseDimensions) => {
  const dimensionsRef = useRef({ height, width });

  useEffect(() => {
    dimensionsRef.current = { height, width };
  }, [height, width]);

  return dimensionsRef;
};
