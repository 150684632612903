import { AppMode, Permissions, Product, ProductKeys, SecurityRulesStates } from '@logz-pkg/enums';
import { ConditionType, ISidenavItemConfig } from './item-configs';
import {
  securityLookupsListEditRouteName,
  securityLookupsListRouteName,
  securitySummaryRouteName,
} from 'components/../states/security/security.routes.names';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { FilterTagNames } from 'ui/components/Integrations/utils';

export const siemItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'SIEM',
  mode: AppMode.SECURITY,
  expandedSidenavTitle: 'Cloud SIEM',
  key: ProductKeys.Siem,
  logo: Product.Siem,
  targetStateName: securitySummaryRouteName,
  expandedNavGroups: [
    {
      items: [
        { icon: 'chart-simple-regular', text: 'Summary', targetStateName: 'dashboard.security-summary' },
        {
          icon: 'calendar-regular',
          text: 'Event Management',
          targetStateName: 'dashboard.security.event-management',
        },
        {
          icon: 'bell-regular',
          text: 'Rules',
          targetStateName: 'dashboard.security.rules.definitions',
          action: {
            targetStateName: SecurityRulesStates.New,
            eventName: 'new security rule',
            subject: 'nav-new-rule',
            displayConditions: [{ type: ConditionType.Permission, value: Permissions.SECURITY_RULES_PERMISSION }],
          },
          innerStates: [SecurityRulesStates.Edit, SecurityRulesStates.New],
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.READ_SECURITY_RULES_PERMISSION }],
        },
        {
          icon: 'objects-column-regular',
          text: 'Dashboards',
          targetStateName: 'dashboard.security-research-dashboards',
        },
        {
          icon: 'chart-pie-regular',
          text: 'Threats overview',
          targetStateName: 'dashboard.security-threats-overview',
          subItems: [{ targetStateName: 'dashboard.security.threats.feeds', text: 'Threat Intelligence feeds' }],
        },
        {
          text: 'OpenSearch Dashboards',
          icon: 'file-magnifying-glass-regular',
          targetStateName: 'dashboard.security-research',
        },
        {
          icon: 'regular-memo-clock-custom',
          text: 'Reports',
          targetStateName: 'dashboard.scheduled-reports',
          action: { targetStateName: 'dashboard.scheduled-reports-new', eventName: 'new scheduled report' },
          innerStates: ['dashboard.scheduled-reports-new', 'dashboard.scheduled-reports-edit'],
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.SCHEDULED_REPORTS }],
        },
      ],
    },
    {
      title: 'More options',
      items: [
        {
          icon: 'paper-plane-regular',
          text: 'Send your data',
          targetStateName: integrationsRouteStates.collectors,
          targetStateParams: { tags: FilterTagNames.SIEM },
        },
        {
          text: 'Drilldowns',
          icon: 'magnifying-glass-chart-regular',
          targetStateName: 'dashboard.settings.drilldowns',
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.DRILLDOWNS_PERMISSION }],
        },
        {
          text: 'Lookups',
          icon: 'list-check-regular',
          targetStateName: securityLookupsListRouteName,
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.LOOKUP_LISTS }],
          innerStates: [securityLookupsListEditRouteName],
        },
      ],
    },
  ],
};
