import React from 'react';
import { Field } from 'formik';
import { Grid, If, Input, Select } from '@logz-ui/styleguide';

export const SelectLogTypeField = ({ options, selectedLogType, isDisabled = false, setFieldValue, setLogType }) => {
  const handleLogTypeChanged = ev => {
    if (ev.value !== 'other') {
      setFieldValue('logsType', ev.value);
    } else {
      setFieldValue('logsType', '');
    }

    setLogType(ev.value);
  };

  return (
    <React.Fragment>
      <Grid.Cell span={6}>
        <Select
          className="log-shipping__log-type-select"
          options={options}
          label="Log types"
          isDisabled={isDisabled}
          isClearable={false}
          value={{ value: selectedLogType, label: selectedLogType }}
          onChange={ev => handleLogTypeChanged(ev)}
        />
      </Grid.Cell>
      <If condition={selectedLogType === 'other'}>
        <Grid.Cell span={6}>
          <Field name="logsType">
            {({ field, form }) => (
              <Input
                disabled={isDisabled}
                mb={0}
                placeholder="enter log type"
                error={form.touched.logsType && form.errors.logsType}
                {...field}
              />
            )}
          </Field>
        </Grid.Cell>
      </If>
    </React.Fragment>
  );
};
