import React, { FunctionComponent, useEffect, useState } from 'react';
import { IntegrationsSubject, Product } from '@logz-pkg/enums';
import { getRegionCode } from '@logz-pkg/utils';
import { reactStringReplace } from '../../../../../utils/react-string-replace';
import { integrationHubUtils } from '../../utils';
import { stringToReact } from '../../Markdown/replace-strings-to-react';
import { MarkdownRender } from '../../../shared/Markdown/Markdown';
import { htmlRenderers } from '../../../shared/Markdown/html-to-react';
import { MarkdownHeaderComponent } from '../TableOfContent/MarkdownHeader';
import { useSessionState, useIntegrationsHub } from 'ui/state/hooks';

interface IMarkdownContentProps {
  markdown: string;
  shouldRenderMarkdownHeader?: boolean;
  elementRenderers?: { [nodeType: string]: React.ElementType<any, keyof React.JSX.IntrinsicElements> };
  shouldShowLoader?: boolean;
}

export const MarkdownContent: FunctionComponent<IMarkdownContentProps> = ({
  markdown,
  shouldRenderMarkdownHeader = true,
  elementRenderers,
  shouldShowLoader = true,
}) => {
  const [parsedMarkdowns, setParsedMarkdowns] = useState<string[]>([]);
  const { selectedMetricAccount, selectedTracingAccount, selectedLogAnalyticAccount } = useIntegrationsHub();
  const { userSummary } = useSessionState();

  useEffect(() => {
    const stringToString = integrationHubUtils.stringToString({
      [Product.LogAnalytics]: selectedLogAnalyticAccount,
      [Product.Metrics]: selectedMetricAccount,
      [Product.Tracing]: selectedTracingAccount,
      listenerAddress: userSummary?.listenerAddress,
      regionCode: getRegionCode(userSummary?.accountRegion),
    });

    setParsedMarkdowns(
      reactStringReplace({
        source: markdown,
        stringToString: [
          { match: /{@include: [\w./-]+.html(\s*)?(:?\w+='\w+')?(\s+)?}(:?\n\s)?/g, to: '' },
          ...stringToString,
        ],
        stringToReact,
      }),
    );
  }, [markdown, selectedMetricAccount, selectedTracingAccount, selectedLogAnalyticAccount]);

  const renderers = {
    ...htmlRenderers({
      CustomHeaderComponent: shouldRenderMarkdownHeader ? MarkdownHeaderComponent : undefined,
    }),
    ...elementRenderers,
  };

  return (
    <MarkdownRender
      markdowns={parsedMarkdowns}
      subject={IntegrationsSubject.MarkdownRender}
      renderers={renderers}
      shouldShowLoader={shouldShowLoader}
    />
  );
};
