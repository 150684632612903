import { IntegrationsSubject, Product, BundleTypes, AccountType } from '@logz-pkg/enums';
import { AccountInfoCardModel, AccountInfoModel, GroupedTagCollectors, HubIntegration } from '@logz-pkg/models';
import { sessionStateService } from '@logz-pkg/frontend-services';
import { IIconProps } from '@logz-ui/styleguide';
import { genericGetKnownStringsToReplaceMap } from './Markdown/replace-strings';
import { integrationsHubStateService } from 'ui/state/integrations-hub.state.service';
import { metricsService } from 'services/metrics/metrics.service';
import { tracingService } from 'services/tracing/tracing.service';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { appRouter } from 'services/router/router.service';

export const goToIntegrationsPage = async ({ tags }: { tags: string }) => {
  await appRouter.stateService.go(integrationsRouteStates.collectors, { tags });
};

const mapProductToTrialHandler = {
  [Product.Metrics]: async () => {
    await metricsService.createFreeMetricsAccount();
    await integrationsHubStateService.getAndSetMetricsAccountsNamesAndTokens();
  },
  [Product.Tracing]: async () => {
    await tracingService.activateTrial();
    await integrationsHubStateService.getAndSetTracingAccountsNamesAndTokens();
  },
};

const mapProductToName: Record<Product, string> = {
  LOG_ANALYTICS: 'Logs',
  METRICS: 'Metrics',
  TRACING: 'Tracing',
  SIEM: 'SIEM',
};

const mapBundleTypeToProductName: Record<BundleTypes, Product> = {
  [BundleTypes.OsdDashboards]: Product.LogAnalytics,
  [BundleTypes.OsdAlerts]: Product.LogAnalytics,
  [BundleTypes.MetricsDashboards]: Product.Metrics,
  [BundleTypes.MetricsAlerts]: Product.Metrics,
};

const getAccountDetailsByProduct: Partial<Record<Product, () => Partial<AccountInfoCardModel>>> = {
  [Product.LogAnalytics]: () => {
    const logsAccounts = integrationsHubStateService.logsAccountsDetails.get();

    return {
      product: Product.LogAnalytics,
      ...(logsAccounts?.length
        ? {
            accounts: logsAccounts,
            subject: IntegrationsSubject.InfoCardLogsToken,
            tooltip: 'The account to which you are currently logged in',
          }
        : {}),
    };
  },
  [Product.Metrics]: () => {
    const metricsAccounts = integrationsHubStateService.metricsAccountsDetails.get();

    return {
      product: Product.Metrics,
      ...(metricsAccounts?.length
        ? {
            accounts: metricsAccounts,
            subject: IntegrationsSubject.InfoCardMetricsToken,
            tooltip: "Choose the Metric account you'd like to use for this integration",
          }
        : {}),
    };
  },
  [Product.Tracing]: () => {
    const tracingAccounts = integrationsHubStateService.tracingAccountsDetails.get();

    return {
      product: Product.Tracing,
      ...(tracingAccounts?.length
        ? {
            accounts: tracingAccounts,
            subject: IntegrationsSubject.InfoCardTracingToken,
            tooltip: "Choose the Tracing account you'd like to use for this integration",
          }
        : {}),
    };
  },
};

type SelectedAccountTokenDetails = {
  [Product.LogAnalytics]: AccountInfoModel | null;
  [Product.Metrics]: AccountInfoModel | null;
  [Product.Tracing]: AccountInfoModel | null;
};

type ReplacedData = SelectedAccountTokenDetails & { apiToken?: string; listenerAddress: string; regionCode: string };

const stringToString = ({
  [Product.LogAnalytics]: logs,
  [Product.Metrics]: metrics,
  [Product.Tracing]: tracing,
  apiToken,
  listenerAddress,
  regionCode,
}: ReplacedData) => [
  ...genericGetKnownStringsToReplaceMap,
  { match: /<<LOG-SHIPPING-TOKEN>>/g, to: logs?.token },
  { match: /<<API-TOKEN>>/g, to: apiToken },
  { match: /<<PROMETHEUS-METRICS-SHIPPING-TOKEN>>/g, to: metrics?.token },
  { match: /<<METRICS-SHIPPING-TOKEN>>/g, to: metrics?.token },
  { match: /<<TRACING-SHIPPING-TOKEN>>/g, to: tracing?.token },
  { match: /<<SPM-METRICS-SHIPPING-TOKEN>>/g, to: tracing?.connectedAccounts?.token },
  { match: /<<LISTENER-HOST>>/g, to: listenerAddress },
  { match: /<<LOGZIO_ACCOUNT_REGION_CODE>>/g, to: regionCode },
];

const mostPopularTagLabel = 'Most Popular';

const filterCollectorsByQuery = ({ arr, query }: { arr: GroupedTagCollectors['collectors']; query: string }) =>
  arr.filter(({ title }) => title.toLowerCase().includes(query.toLowerCase()));

const getKeyByValue = (object: Record<string, string>, value: string) => {
  return Object.keys(object).find(key => object[key] === value);
};

const groupTagsBySubjects = (tags: string[]): Record<string, string[]> => {
  const categories: string[] = [];
  const products: string[] = [];

  tags.forEach(tag => {
    if (getKeyByValue(mapProductToName, tag)) {
      products.push(tag);
    } else {
      categories.push(tag);
    }
  });

  return {
    categories,
    products,
  };
};

const isCollectorMatchProducts = (collector: HubIntegration, products: string[]): boolean => {
  return products.some(tag => {
    const productTagKey = getKeyByValue(mapProductToName, tag);

    return collector.productTags.includes(productTagKey as Product);
  });
};

const isCollectorMatchTags = (collector: HubIntegration, tags: string[]): boolean => {
  return tags.some(tag => {
    return collector.filterTags.includes(tag);
  });
};

const filterCollectorsByTags = ({
  collectors,
  tags,
}: {
  collectors: GroupedTagCollectors['collectors'];
  tags: string[];
}) => {
  const { products, categories } = groupTagsBySubjects(tags);

  let filteredCollectors = collectors;

  if (products.length > 0) {
    filteredCollectors = filteredCollectors.filter(collector => isCollectorMatchProducts(collector, products));
  }

  if (categories.length > 0) {
    filteredCollectors = filteredCollectors.filter(collector => isCollectorMatchTags(collector, categories));
  }

  return filteredCollectors;
};

const groupCollectorsIntoMap = collectors =>
  collectors.reduce((groupedMap, collector) => {
    collector.filterTags?.forEach(tag => {
      const group = groupedMap.get(tag) || { title: tag, collectors: [] };

      group.collectors.push(collector);
      groupedMap.set(tag, group);
    });

    return groupedMap;
  }, new Map());

const sortByPopularity = (a: GroupedTagCollectors, b: GroupedTagCollectors) => {
  if (a.title === mostPopularTagLabel) return -1;

  if (b.title === mostPopularTagLabel) return 1;

  return a.title.localeCompare(b.title);
};

const sortGroupedItems = groupedItemsArray => {
  groupedItemsArray.sort(sortByPopularity);

  groupedItemsArray.forEach(group => group.collectors.sort((a, b) => a.title.localeCompare(b.title)));

  return groupedItemsArray;
};

const groupCollectorsByTags = (collectors: GroupedTagCollectors['collectors']): GroupedTagCollectors[] => {
  const groupedItemsMap = groupCollectorsIntoMap(collectors);
  const groupedItemsArray: GroupedTagCollectors[] = Array.from(groupedItemsMap.values());

  return sortGroupedItems(groupedItemsArray);
};

const getInstallBundleTitle = bundle => {
  const hasAlerts = Object.values(bundle).some(({ type }) =>
    [BundleTypes.MetricsAlerts, BundleTypes.OsdAlerts].includes(type),
  );

  const hasDashboards = Object.values(bundle).some(({ type }) =>
    [BundleTypes.OsdDashboards, BundleTypes.MetricsDashboards].includes(type),
  );

  let text;

  if (hasDashboards && hasAlerts) {
    text = 'Dashboards and alerts';
  } else if (hasDashboards) {
    text = 'Dashboards';
  } else if (hasAlerts) {
    text = 'Alerts';
  }

  return `${text} for the following:`;
};

const generateProductAccountsDetails = (products: Product[]) => {
  const {
    loggedInAccount: { type: loggedInAccountType },
  } = sessionStateService.session.get();

  const isOwnerAccount = loggedInAccountType === AccountType.Owner;

  return products
    .map(product =>
      getAccountDetailsByProduct.hasOwnProperty(product)
        ? integrationHubUtils.getAccountDetailsByProduct[product]()
        : null,
    )
    .filter(productDetails => productDetails && (isOwnerAccount || productDetails.accounts));
};

export const integrationHubUtils = {
  BundleTypes,
  mapProductToName,
  mapProductToTrialHandler,
  getAccountDetailsByProduct,
  mapBundleTypeToProductName,
  stringToString,
  getInstallBundleTitle,
  filterCollectorsByQuery,
  filterCollectorsByTags,
  groupCollectorsByTags,
  generateProductAccountsDetails,
};

type FilterTagOption = {
  name: string;
  icon: IIconProps['icon'];
};

export const FilterTagNames = {
  quickSetup: 'Quick Setup',
  preBuildContent: 'Pre-built Content',
  ...mapProductToName,
};

export const filterTagOptions: FilterTagOption[] = [
  { name: FilterTagNames.quickSetup, icon: 'quick-setup-regular-custom' },
  { name: FilterTagNames.preBuildContent, icon: 'logzio-logo-solid-custom' },
];
