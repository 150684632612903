import { Group, Text, Title } from '@logz-ui/styleguide';
import { ContactSupport } from 'ui/components/shared/ContactSupport/ContactSupport';

export const ContentErrorText = () => (
  <Group alignItems={'center'} vertical width={'100%'}>
    <Title weight={700} size={14}>
      Content could not be loaded
    </Title>
    <Text weight={400} size={14}>
      Please try reloading the page, and if this happens again, <ContactSupport />
    </Text>
  </Group>
);
