import { parse, format } from 'url';
import { NotificationService } from '@logz-ui/styleguide';
import { cookiesProvider, LoggerService, alertApiService } from '@logz-pkg/frontend-services';
import { KibanaSubjects } from '@logz-pkg/enums';
import { SubComponentModel as SubComponent, alertModelManipulator } from '@logz-pkg/models';
import { removeHash, urlParser } from '@logz-pkg/utils';
import { pick } from 'lodash';
import { kibanaRouteService, kibanaTimeModes } from '../../osd/osd-route.service';
import { getKibanaTheme } from '../../osd/osd-theme.service';
import { kibanaVersion } from '../../osd/versions/osd-versions.strategy';
import { createRouteOptions } from '../../product/route-helpers/route-helpers';
import { appEmbedded } from '../../configs/app-embedded';
import { appQueryParams, kibanaUrlQueryParams } from '../../osd/osd-route-params';
import { AlertKibanaParameters } from './types';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { drilldownService } from 'ui/components/DrilldownSettings/drilldown.service';
import { appRouter } from 'services/router/router.service';

// route helper functions
const getAlertKibanaParams = (subComponent: SubComponent) => ({
  query: subComponent.queryDefinition.query,
  filter: alertModelManipulator.convertFiltersToElastic(subComponent.queryDefinition),
  subAccountsIds: subComponent.queryDefinition.accountsToQueryOn.map(({ id }) => id),
  allSubAccounts: subComponent.queryDefinition.shouldQueryOnAllAccounts,
  fieldToAggregateOn: subComponent.queryDefinition.aggregation?.field?.name,
  groupByName: subComponent.queryDefinition.groupBy.map(({ name }) => name),
});

export const createOsdRedirectParamsBySource = ({
  resource,
  appName,
}): Record<typeof kibanaUrlQueryParams[number], string> & { resource: string } => {
  const { appName: appNameFromResource, action } = extractAppAndActionFromResource(resource);

  const updatedAppQueryParams = urlParser(window.location.href).hashQuery;
  const formattedAppQueryParams = pick(updatedAppQueryParams, [...appQueryParams, 'switchToAccountId']);

  const redirectPath = urlParser(
    `${window.location.origin}/${appNameFromResource}${
      removeHash(window.sessionStorage.getItem(`lastUrl:/osd-2-0:${appName}`) ?? '') ?? `/${action}`
    }`,
  );

  const queryParams = redirectPath.parsedQuery;

  return {
    resource: redirectPath.parsedUrl.pathname?.substring(1) ?? '',
    ...formattedAppQueryParams,
    ...queryParams,
  };
};

export async function fetchAlertKibanaParams(alertId) {
  const response = await alertApiService.get(alertId);

  return response.subComponents.map(getAlertKibanaParams);
}

export async function goToKibanaWithParams({
  filters,
  query = '*',
  fromDate,
  toDate,
  subAccountsIds = null,
  allSubAccounts = false,
  columns = [],
  mode = kibanaTimeModes.absolute,
  openInNewTab = false,
  embed = false,
  time,
  skipSort = false,
  resource = '',
  switchToAccountId,
}: AlertKibanaParameters) {
  const kibanaParams = await kibanaRouteService.buildRouteForDiscover({
    query,
    time,
    from: fromDate,
    to: toDate,
    filters,
    columns,
    mode,
    skipSort,
    resource,
    switchToAccountId,
  });
  const accountIds = allSubAccounts ? true : subAccountsIds;

  if (openInNewTab) {
    const mode = await appModeService.getCurrentMode();

    if (mode.kibanaState) {
      const url = format({
        ...parse(window.location.href),
        hash: appRouter.stateService.href(mode.kibanaState, {
          ...kibanaParams,
          accountIds,
          forceShowQueryBar: true,
          embed,
        }),
      });

      window.open(url, '_blank');
    }
  } else {
    return appModeService.goToModeKibanaState({
      ...kibanaParams,
      accountIds,
    });
  }
}

export async function handleRouteError(error: Error, { redirectOnFailure = true } = {}): Promise<void | null> {
  LoggerService.logError({ message: `Can't navigate to kibana page: ${error.message}`, error });
  NotificationService.danger({ message: 'Unable to fetch logs', subject: KibanaSubjects.FetchLogs });

  return redirectOnFailure ? appModeService.goToModeKibanaState() : null;
}

export const onKibanaPreLoad = async () => {
  const [version, kibanaTheme] = await Promise.all([kibanaVersion.getVersion(), getKibanaTheme()]);

  cookiesProvider.setCookie('kibana-theme', kibanaTheme);
  cookiesProvider.setCookie('kibana-version', version);
};

const extractAppAndActionFromResource = (resource = '') => {
  const [appName, ...action] = resource.split('/');

  return {
    action: action.join('/'),
    appName: appName || 'discover',
  };
};

export const createKibanaRouteOptions = ({
  viewUrlPrefix,
  baseUrl,
  appName = 'kibana',
  disableSync,
  productPathPrefix = '',
}: {
  viewUrlPrefix: string;
  baseUrl: string;
  appName?: string;
  disableSync?: boolean;
  productPathPrefix?: string;
}) =>
  createRouteOptions({
    viewUrlPrefix,
    shouldSync: disableSync ? false : !appEmbedded,
    refreshOnRoute: () => drilldownService.isDrillDown(),
    appQueryParams,
    buildBasePath: () => `${baseUrl}/${appName}`,
    productQueryParams: kibanaUrlQueryParams,
    addSwitchToAccountId: true,
    ignoreRouteUpdate({ newProductURL, appURL }) {
      return newProductURL.includes('timelion') || newProductURL.includes('management') || appURL.includes('drilldown');
    },
    listenOnHashRouting: true,
    ...(productPathPrefix === '/osd-2-0/app'
      ? {
          buildBasePath: ({ resource: rawResource }: { resource: string }) => {
            const resource = parseKibanaToOsdPath(rawResource); // DEV-32386 :: Delete kibana dashboards prefix fix in osd

            return `${baseUrl}/${extractAppAndActionFromResource(resource).appName}`;
          },
          listenOnHistoryRouting: true,
          getAppPathname: ({ parsedIframeUrl, pathname }) => {
            // extract appName from productIframeUrl in order to attach it to appUrl
            // e.g osd-2-0/app/visualize will return visualize
            // visualize field, uses 'osd-2-0/app/visualize/' - remove last '/'.
            const parsedIframeUrlPathName = parsedIframeUrl?.parsedUrl?.pathname?.replace(/\/$/, '');

            return `${viewUrlPrefix}${parsedIframeUrlPathName?.replace(productPathPrefix, '')}${pathname}`;
          },
          getProductPathname: ({ resource: rawResource = '' }) => {
            // delete appName from productIframeUrl since it has it inside basePath(before hash #)
            // e.g /visualize/list will return /list in order to use as productIframe hash suffix #/list...
            const resource = parseKibanaToOsdPath(rawResource); // DEV-32386 :: Delete kibana dashboards prefix fix in osd
            const { action } = extractAppAndActionFromResource(resource);

            return `/${action}`;
          },
        }
      : {}),
  });
// DEV-32386 :: Delete kibana dashboards prefix fix in osd
const kibanaToOsdUrlsMappers = [
  { kibanaPrefix: 'dashboard/', osdPrefix: 'dashboards/view/', regex: /(dashboard\/)\w+/ },
  { kibanaPrefix: 'discover/', osdPrefix: 'discover/view/', regex: /(discover\/)(?!.*(view|context|doc))\w+/ },
];
const parseKibanaToOsdPath = (rawResource: string): string => {
  // in case we got to osd url with kibana prefix, change to osd. e.g. open kibana's dashboard alert inside osd.
  const resource = rawResource || '';
  const kibanaToOsdMapper = kibanaToOsdUrlsMappers.find(mapper => mapper.regex.test(resource));

  return kibanaToOsdMapper ? resource.replace(kibanaToOsdMapper.kibanaPrefix, kibanaToOsdMapper.osdPrefix) : resource;
};

export const fluentToOtelFieldMapping = {
  ['kubernetes.container_name']: 'k8s_container_name',
  ['kubernetes.container_image']: 'k8s_container_image',
  ['kubernetes.container_image_id ']: 'k8s_container_image_id',
  ['kubernetes.pod_name']: 'k8s_pod_name',
  ['kubernetes.pod_id']: 'k8s_pod_uid',
  ['kubernetes.namespace_name']: 'k8s_namespace_name ',
  ['kubernetes.daemonset_name']: 'k8s_daemonset_name ',
  ['kubernetes.deployment_name']: 'k8s_deployment_name',
  ['kubernetes.statfulset_name']: 'k8s_statefulset_name',
  ['kubernetes.host']: 'k8s_node_name',
  ['stream']: 'iostream',
};

export const securityFieldMapping = {
  ['kubernetes.host']: 'kubernetes.node_name',
  ['kubernetes.pod_name']: 'k8s_pod_name',
};
