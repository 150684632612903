import { useObservable } from '@logz-pkg/observable';
import { EVENTS, eventEmitter } from '@logz-pkg/frontend-services';
import { useCallback } from 'react';
import { aiStateService } from './ai-state.service';
import { aiHistoryService } from './history/history.service';
import { aiFetchDataService } from './fetch-data/fetch-data.service';
import { aiConversationsService } from './conversations/conversations.service';

export const useAiState = () => {
  const isDrawerOpen = useObservable(aiStateService.isDrawerOpen);
  const sessionId = useObservable(aiStateService.sessionId);
  const context = useObservable(aiStateService.context);
  const userInput = useObservable(aiStateService.userInput);
  const createdAt = useObservable(aiStateService.createdAt);
  const isSessionExpired = useObservable(aiStateService.isSessionExpired);
  const rcaId = useObservable(aiStateService.rcaId);
  const shouldAutoScroll = useObservable(aiStateService.shouldAutoScroll);

  const initSessionId = () => {
    aiStateService.setSessionId();
  };

  const clear = useCallback(
    (shouldInitSessionId = true) => {
      eventEmitter.emit(EVENTS.STOP_LAST_REQUEST);

      if (shouldInitSessionId) {
        initSessionId();
      }

      aiFetchDataService.setLoading(false);
      aiHistoryService.clearHistory();
      aiStateService.setUserInput('');
      aiStateService.convId.set(null);
      aiStateService.createdAt.set(null);
      aiStateService.isSessionExpired.set(false);
      aiStateService.shouldAutoScroll.set(true);
      aiConversationsService.shouldShowConversationsPage.set(false);
    },
    [initSessionId],
  );

  return {
    isDrawerOpen,
    toggleDrawer: aiStateService.toggleDrawer,
    sessionId,
    initSessionId,
    clear,
    context,
    setContext: aiStateService.setContext,
    userInput,
    setUserInput: aiStateService.setUserInput,
    createdAt,
    isSessionExpired,
    rcaId,
    shouldAutoScroll,
  };
};
