import { colorService } from '@logz-pkg/utils';
import { useMemo } from 'react';
import { IGraphProps } from '../../../types';

type UseColoredSeries = {
  series: IGraphProps['series'];
};

export const useColoredSeries = ({ series }: UseColoredSeries) => {
  return useMemo(
    () =>
      series.map((item: any) => {
        if (!item.color) {
          item.color = colorService.getColorByString(item?.name ?? '') as string;
        }

        return item;
      }),
    [series],
  );
};
