import { Options } from 'highcharts';
import { useMemo } from 'react';
import { IGetOptionsProps } from '../../../types';

type UseYAxisOptions = {
  showAxis: IGetOptionsProps['showAxis'];
  yAxisTitle: IGetOptionsProps['yAxisTitle'];
  appTheme: IGetOptionsProps['appTheme'];
  axisGridLineWidth: IGetOptionsProps['axisGridLineWidth'];
  labelsFormatter: IGetOptionsProps['labelsFormatter'];
  yTickInterval: IGetOptionsProps['yTickInterval'];
  overrideYAxisOptions: IGetOptionsProps['overrideYAxisOptions'];
  overrideYAxis2Options: IGetOptionsProps['overrideYAxis2Options'];
};

export const useYAxisOptions = ({
  showAxis,
  yAxisTitle,
  appTheme,
  axisGridLineWidth,
  labelsFormatter,
  yTickInterval,
  overrideYAxisOptions,
  overrideYAxis2Options,
}: UseYAxisOptions) => {
  const yAxisOptions: Options['yAxis'] = useMemo(() => {
    return [
      {
        visible: showAxis,
        title: { text: yAxisTitle },
        lineWidth: 1,
        lineColor: appTheme.graph.lineColor,
        gridLineColor: appTheme.graph.gridLineColor,
        gridLineWidth: axisGridLineWidth,
        labels: {
          style: { color: appTheme.graph.labelColor },
          ...(labelsFormatter ? { formatter: labelsFormatter } : {}),
        },
        min: 0,
        softMax: 0.1,
        ...(yTickInterval && { tickInterval: yTickInterval }),
        ...overrideYAxisOptions,
      },
      {
        visible: showAxis,
        showEmpty: false,
        opposite: true,
        gridLineWidth: 0,
        title: { text: '' },
        lineWidth: 1,
        lineColor: appTheme.graph.oppositeYAxisLineColor,
        labels: {
          style: { color: appTheme.graph.oppositeYAxisLabelColor },
        },
        ...overrideYAxis2Options,
      },
    ];
  }, [
    showAxis,
    yAxisTitle,
    appTheme,
    axisGridLineWidth,
    labelsFormatter,
    yTickInterval,
    overrideYAxisOptions,
    overrideYAxis2Options,
  ]);

  return yAxisOptions;
};
