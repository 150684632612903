import { appStateService, LoggerService } from '@logz-pkg/frontend-services';
import { ExploreFavoritesStateModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { cloneDeep, merge } from 'lodash';

const VERSION = 0;
const STATE_NAME = 'explore-favorites';
const defaultState: ExploreFavoritesStateModel = { _migrated: true, bookmarks: [] };
const getPreviousVersion = async (): Promise<ExploreFavoritesStateModel> => {
  const prevSavedState: ExploreFavoritesStateModel = await appStateService.get('explore-user-account-states', 0);
  const state: ExploreFavoritesStateModel = cloneDeep(defaultState);

  if (prevSavedState?.bookmarks !== undefined) {
    state.bookmarks = prevSavedState.bookmarks;
  }

  LoggerService.logInfo({ message: `explore migrated explore-favorites from previous version` });

  return state;
};

class ExploreFavoritesStatesService {
  state = new Observable<ExploreFavoritesStateModel>(defaultState);
  clear = () => this.state.set(defaultState);
  private set = async value => {
    const current = this.state.get();
    const newState: ExploreFavoritesStateModel = {
      _migrated: value._migrated ?? current._migrated ?? defaultState._migrated,
      bookmarks: value.bookmarks ?? current.bookmarks ?? defaultState.bookmarks,
    };

    appStateService.set(STATE_NAME, newState, VERSION);
  };
  reset = () => this.set(defaultState);
  get = async (): Promise<ExploreFavoritesStateModel> => {
    const savedInAppState = await appStateService.get(STATE_NAME, VERSION);
    let newState: ExploreFavoritesStateModel;

    if (!savedInAppState?._migrated) {
      newState = await getPreviousVersion();
      this.set(newState);
    } else {
      newState = merge({}, defaultState, savedInAppState);
    }

    this.state.set(newState);

    return savedInAppState;
  };
  addBookmark = async (field: string) => {
    const { bookmarks } = this.state.get();

    if (bookmarks?.includes(field)) {
      return;
    }

    const newState = { ...(await this.get()), bookmarks: [...(bookmarks ?? []), field] };

    this.state.set(newState);
    await this.set(newState);
  };
  removeBookmark = async (field: string) => {
    const bookmarks = this.state.get().bookmarks.filter(bookmark => bookmark !== field);
    const newState = { ...(await this.get()), bookmarks };

    this.state.set(newState);
    await this.set(newState);
  };
}

export const exploreFavoritesStatesService = new ExploreFavoritesStatesService();
