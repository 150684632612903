import { ComponentProps } from 'react';
import { Icon, IIconProps, Tag } from '@logz-ui/styleguide';

export enum HelpQuestionKeyType {
  HELP = 'help',
  TRENDS = 'trends',
  COMPARE = 'compare',
  DYNAMIC = 'dynamic',
  START = 'start',
}

export type HelpQuestionsCardInformationType = {
  text: string;
  iconName: IIconProps['icon'];
  color: string;
  index?: number;
};

export type HelpQuestionsType = {
  [key in HelpQuestionKeyType]?: HelpQuestionsCardInformationType[];
};

export type HelpQuestionsChatType = {
  assistant?: HelpQuestionsType;
  pro?: HelpQuestionsType;
};

export type InsightMessage = {
  name: string;
  numberOfLogs: number;
  severity: ComponentProps<typeof Tag.Severity>['severity'];
  tags: string[];
  description: string;
  actions: {
    icon: ComponentProps<typeof Icon>['icon'];
    callback: () => void;
    tooltip?: string;
  }[];
};
