import { ExploreGeneralSettingsModel } from '@logz-pkg/models';

export const defaultGeneralSettingsState: ExploreGeneralSettingsModel = {
  openSurroundingLogsInNewTab: true,
  defaultColumns: [{ id: 'message' }],
  alternatingRowColor: true,
  startNewTagOnEnter: true,
  timezone: null,
};

export const generalSettingsMap: Record<keyof ExploreGeneralSettingsModel, { description: string; title: string }> = {
  openSurroundingLogsInNewTab: {
    description: 'Should opening surrounding logs again open a new tab',
    title: 'Open surrounding logs in new tab',
  },
  defaultColumns: {
    description: 'Set the default column to display in the logs table',
    title: 'Default column',
  },
  alternatingRowColor: {
    description: 'Color every other row in the logs table to improve readability',
    title: 'Alternate row color',
  },
  startNewTagOnEnter: {
    description: 'Should start a new filter if the previous one was submitted using enter',
    title: 'Start new filter on enter',
  },
  timezone: {
    description: 'Set the default timezone for Explore',
    title: 'Timezone',
  },
};
