import { Color, colors } from '@logz-ui/styleguide';
import { get } from 'lodash';
import React from 'react';

interface IBackdropProps extends React.SVGProps<SVGSVGElement> {
  color: Color;
}

export const Backdrop: React.FC<IBackdropProps> = ({ color, ...props }) => {
  const formattedColor = get(colors, color);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257 142" width={257} height={142} fill="none" {...props}>
      <path
        fill={formattedColor}
        d="M220.634 118.406C165.898 167.608 61.242 131.571 9.226 80.313-42.79 29.057 150.213-8.504 207.545 1.75c56.619 10.126 67.825 67.454 13.089 116.657Z"
      />
    </svg>
  );
};
