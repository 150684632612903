import { Button, Group, Text, Icon } from '@logz-ui/styleguide';
import { AICopilotSubjects } from '@logz-pkg/enums';
import React from 'react';
import { ITooltipProps } from '@logz-ui/styleguide/components/tooltip/Tooltip.component';
import { useObservable } from '@logz-pkg/observable';
import { helpQuestionsTitleMap } from 'ui/components/Explore/constants';
import { QuestionCard } from 'ui/components/AI/Components/EmptyState/QuestionCard';
import { HelpQuestionKeyType, HelpQuestionsCardInformationType } from 'ui/components/AI/types';
import { hotkeysNavigationService } from 'ui/components/AI/state/hotkey-navigation/hotkeys-navigation.service';

const tooltip: ITooltipProps = {
  title: 'Automatically generates questions based on the context of your current data search.',
  placement: 'top',
};

export const helpQuestionPreFixId = 'ai-chat-empty-state-help-question';

interface IQuestionSectionProps {
  questionInformationArray: HelpQuestionsCardInformationType[];
  questionSection: string;
  onClickQuestionCard: (value: string) => void;
  isFetching: boolean;
  handleRefreshDynamicQuestions: () => void;
}

export const QuestionSection: React.FC<IQuestionSectionProps> = ({
  questionInformationArray,
  questionSection,
  onClickQuestionCard,
  isFetching,
  handleRefreshDynamicQuestions,
}) => {
  const highlightIndex = useObservable(hotkeysNavigationService.highlightedIndex);

  if (!helpQuestionsTitleMap[questionSection] && questionInformationArray.length === 0) return null;

  return (
    <Group key={questionSection} vertical alignItems={'left'} width={'100%'}>
      <Group justifyContent={'space-between'} alignItems={'center'} fullWidth>
        <Group gap={1} alignItems={'center'}>
          <Text color={'royalBlue.1000'} size={14} textAlign={'left'} weight={700}>
            {helpQuestionsTitleMap[questionSection]}
          </Text>
          {questionSection === HelpQuestionKeyType.DYNAMIC && (
            <Icon icon={'circle-question-solid'} size={14} color={'royalBlue.600'} tooltip={tooltip} />
          )}
        </Group>
        {questionSection === HelpQuestionKeyType.DYNAMIC && (
          <Button
            size={'xs'}
            loading={isFetching}
            icon={<Icon icon={'arrow-rotate-right-regular'} size={14} color={'royalBlue.1000'} />}
            onClick={handleRefreshDynamicQuestions}
            variant={'secondary'}
          />
        )}
      </Group>

      {questionInformationArray.map((questionInformation, index) => {
        const isHighlighted = questionInformation.index === highlightIndex;

        const shouldDisplayLoading = questionSection === 'dynamic' && isFetching;

        return (
          <QuestionCard
            isFetching={shouldDisplayLoading}
            id={`${helpQuestionPreFixId}-${questionInformation.index}`}
            subject={`${AICopilotSubjects.HelpQuestion}-${index}`}
            {...questionInformation}
            isHighlighted={isHighlighted}
            key={index}
            handleClick={async () => onClickQuestionCard(questionInformation.text)}
          />
        );
      })}
    </Group>
  );
};
