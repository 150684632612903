import { ISidenavItemConfig } from './item-configs';

export const getNavItemStates = (productConfig: ISidenavItemConfig) => {
  return productConfig.expandedNavGroups.reduce((acc: string[], navGroup) => {
    const linkNavGroupItems = navGroup.items.filter(item => !item.component);
    const groupStates = linkNavGroupItems.reduce((acc: string[], navItem) => {
      const subItemStates = navItem.subItems?.map(subItem => subItem.targetStateName);

      if (navItem.innerStates) {
        acc = acc.concat(navItem.innerStates);
      }

      if (subItemStates) {
        acc = acc.concat(subItemStates);
      }

      return acc.concat(navItem.targetStateName as string);
    }, []);

    return acc.concat(groupStates);
  }, []);
};
