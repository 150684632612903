import { useEffect, useState } from 'react';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { LoggerService } from '@logz-pkg/frontend-services';
import { loadHighlight, AvailableLanguages } from 'ui/components/shared/HighlightJs/lazy-load-highlight';

interface ISyntaxHighlight {
  language: keyof typeof AvailableLanguages;
  code: string;
  editableText?: string;
}

export const useSyntaxHighlight = ({ language, code, editableText }: ISyntaxHighlight) => {
  const {
    isPending,
    action: getHighlight,
    data: hljs,
    error,
  } = useAsyncAction(loadHighlight, { initialIsPending: true });
  const [highlightedData, setHighlightedData] = useState('');

  useEffect(() => {
    getHighlight();
  }, []);

  useEffect(() => {
    if (!hljs || !code) return;

    if (Object.keys(AvailableLanguages).includes(language)) {
      const parsedCode = hljs.highlight(language, code).value;

      if (editableText) {
        const findRegex = new RegExp(editableText, 'gm');

        const replaceString = `<span contentEditable="true" spellCheck={false}  class="custom-highlight">${editableText}</span>`;
        const customHighlighted = parsedCode.replace(findRegex, replaceString);

        setHighlightedData(customHighlighted);

        return;
      }

      setHighlightedData(parsedCode);

      return;
    }

    const autoHighlightResult = hljs.highlightAuto(code);

    LoggerService.logWarn({
      message: `No highlight syntax language detected for:${language} - auto detection resulted in ${autoHighlightResult.language}`,
      extra: {
        origin: language,
        detected: autoHighlightResult.language,
      },
    });

    setHighlightedData(autoHighlightResult.value);
  }, [hljs, code]);

  return { highlightedData, isPending, error };
};
