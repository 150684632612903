import { ExploreKeyBindingsStateModel } from '@logz-pkg/models';

export const keyBindingsDescription: Record<string, { description: string; title: string }> = {
  clearAll: {
    description: 'Clears all active filters',
    title: 'Clear all filters',
  },
  focusInput: {
    description: `Focuses on the smart search bar`,
    title: 'Smart Search Focus',
  },
  reloadSearch: {
    description: 'Refreshes the search results',
    title: 'Reload search',
  },
  toLast: {
    description: 'Jump to the last option in the list',
    title: 'Jump to last option',
  },
  toFirst: {
    description: 'Jump to the first option in the list',
    title: 'Jump to first option',
  },
  quickExists: {
    description: 'When entering a new filter, Set the current highlighted "Field" as an "exists" filter',
    title: 'Set quick exists',
  },
  quickNotExists: {
    description: 'When entering a new filter, Set the current highlighted "Field" as an "Not exists" filter',
    title: 'Set quick not exists',
  },
};

export const defaultKeyBindingsState: ExploreKeyBindingsStateModel = {
  clearAll: {
    keyBindings: ['alt+backspace'],
    enabled: true,
  },
  focusInput: {
    keyBindings: ['alt+f'],
    enabled: true,
  },
  reloadSearch: {
    keyBindings: ['alt+r'],
    enabled: true,
  },
  toLast: {
    keyBindings: ['control+down', 'meta+down', 'pagedown', 'end'],
    enabled: true,
  },
  toFirst: {
    keyBindings: ['control+up', 'meta+up', 'pageup', 'home'],
    enabled: true,
  },
  quickExists: {
    keyBindings: ['alt+enter'],
    enabled: true,
  },
  quickNotExists: {
    keyBindings: ['alt+shift+enter'],
    enabled: true,
  },
};
