import { Options } from 'highcharts';
import { useMemo } from 'react';
import { dateTimeLabelFormats, getDefaultCrosshairOptions } from '../../../constants';
import { IGetOptionsProps } from '../../../types';

type UseXAxisOptions = {
  showAxis: IGetOptionsProps['showAxis'];
  appTheme: IGetOptionsProps['appTheme'];
  axisGridLineWidth: IGetOptionsProps['axisGridLineWidth'];
  crosshair: IGetOptionsProps['crosshair'];
  events: IGetOptionsProps['events'];
  tickInterval: IGetOptionsProps['tickInterval'];
  xStart: IGetOptionsProps['xStart'];
  xEnd: IGetOptionsProps['xEnd'];
  overrideXAxisOptions: IGetOptionsProps['overrideXAxisOptions'];
};

export const useXAxisOptions = ({
  showAxis,
  appTheme,
  axisGridLineWidth,
  crosshair,
  events,
  tickInterval,
  xStart,
  xEnd,
  overrideXAxisOptions,
}: UseXAxisOptions) => {
  const xAxisOptions: Options['xAxis'] = useMemo(() => {
    return {
      showEmpty: true,
      visible: showAxis,
      startOnTick: false,
      endOnTick: false,
      gridLineColor: appTheme.graph.gridLineColor,
      crosshair: getDefaultCrosshairOptions(crosshair, appTheme),
      gridLineWidth: axisGridLineWidth,
      type: 'datetime',
      allowDecimals: false,
      labels: {
        style: { color: appTheme.graph.labelColor },
        y: events?.data.length > 0 ? 30 : 20,
      },
      tickWidth: 0,
      lineWidth: 1,
      lineColor: appTheme.graph.lineColor,
      dateTimeLabelFormats,
      ...(tickInterval && { tickInterval }),
      ...(xStart && { min: xStart }),
      ...(xEnd && { max: xEnd }),
      ...overrideXAxisOptions,
    } as Options['xAxis'];
  }, [showAxis, appTheme, crosshair, axisGridLineWidth, events, tickInterval, xStart, xEnd, overrideXAxisOptions]);

  return xAxisOptions;
};
