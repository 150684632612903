import { FunctionComponent } from 'react';
import { Text, Group, List, ListItem } from '@logz-ui/styleguide';

export const GrafanaDescription: FunctionComponent = () => (
  <Group vertical gap={3}>
    <Text color={'gray.800'} size={16}>
      End-to-end visibility for your infrastructure and applications
    </Text>
    <List variant={'check'} size={'large'}>
      <ListItem>Managed Prometheus - Easily migrate your existing data and content in minutes</ListItem>
      <ListItem>Effortless scalability</ListItem>
      <ListItem>See it all in one place: Analyze your data in Kibana and Prometheus, side by side</ListItem>
      <ListItem>Get alerted on critical issues in real-time</ListItem>
    </List>
  </Group>
);
