import { Group, Title } from '@logz-ui/styleguide';
import React from 'react';
import { Kube360Subjects } from '@logz-pkg/enums';
import styled from 'styled-components';
import { useAiHistory } from '../../state/history/history.hook';
import { HelpSection } from './HelpSection';

const StyledDiv = styled.div`
  width: 340px;
  margin: 60px auto 32px;
`;

export const AiChatEmptyState: React.FC = () => {
  const { history } = useAiHistory();

  if (history?.length) {
    return null;
  }

  return (
    <Group width={'100%'} vertical overflow={'auto'}>
      <StyledDiv>
        <Title subject={Kube360Subjects.EmptyStateTitle} size={18}>
          How can I help you today?
        </Title>
      </StyledDiv>

      <HelpSection />
    </Group>
  );
};
