import { Button, Divider, Group, Icon, NotificationService } from '@logz-ui/styleguide';
import React, { useRef } from 'react';
import { MarkdownRender } from 'ui/components/shared/Markdown/Markdown';
import { htmlRenderers } from 'ui/components/shared/Markdown/html-to-react';
import { aiUtils } from 'ui/components/AI/utils';
import { useAiAnalytics } from 'ui/components/AI/hooks/analytics.hook';

export const AiChatGeneralActionButtons: React.FC<{ content: string; isLast?: boolean }> = ({
  content,
  isLast = false,
}) => {
  const { report } = useAiAnalytics();
  const markdownRef = useRef(null);

  const handleThumbsUp = () => {
    report('AI response marked as helpful', { content });
    aiUtils.loggerInfo('Click on thumbs-up', { content });
    NotificationService.success({ message: 'Thank you for your feedback', duration: 1 });
  };
  const handleThumbsDown = () => {
    report('AI response marked as unhelpful', { content });
    aiUtils.loggerInfo('Click on thumbs-down', { content });
    NotificationService.success({ message: 'Thank you for your feedback', duration: 1 });
  };
  const handleCopy = async () => {
    report('AI response copied to clipboard');
    aiUtils.loggerInfo('Click on copy', { content });

    const renderedHtml = markdownRef?.current.innerHTML;
    const clipboardContent = {
      'text/plain': new Blob([content], { type: 'text/plain' }),
    };

    if (renderedHtml) {
      clipboardContent['text/html'] = new Blob([renderedHtml], { type: 'text/html' });
    }

    await navigator.clipboard.write([new ClipboardItem(clipboardContent)]);
    NotificationService.success({ message: 'Ai message was copied to your clipboard', duration: 1 });
  };

  return (
    <>
      <Group width={'100%'} justifyContent="end" mt={4}>
        <Button onClick={handleCopy} variant="tertiary" round size="s">
          <Icon icon={'copy-regular'} />
        </Button>
        <Button onClick={handleThumbsUp} variant="tertiary" round size="s">
          <Icon icon={'thumbs-up-regular'} />
        </Button>
        <Button onClick={handleThumbsDown} variant="tertiary" round size="s">
          <Icon icon={'thumbs-down-regular'} />
        </Button>
      </Group>
      <div hidden ref={markdownRef}>
        <MarkdownRender shouldShowLoader={false} markdowns={[content]} renderers={htmlRenderers()} />
      </div>
      {isLast && <Divider color="royalBlue.100" mb={4} mt={4} />}
    </>
  );
};
