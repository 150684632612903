import { cookieKeys } from '@logz-pkg/enums';
import { cookiesProvider } from '@logz-pkg/frontend-services';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Box, Flex, Notification, Spinner } from '@logz-ui/styleguide';
import { getThemeObject } from '@logz-ui/styleguide/components/themes';
import { UIRouter, UIView } from '@uirouter/react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { bootstrap } from '../../../app/bootstrap';
import { appEmbedded } from '../../../app/configs/app-embedded';
import { loginFlow } from 'core/auth-flows';
import { initRouterConfigs } from 'services/router/router.rules';
import { appRouter } from 'services/router/router.service';
import { initIDBRetentionWorker } from 'services/web-workers/init-idb-retention-worker';
import { AppConfirmModal } from 'ui/components/App/AppConfirmModal';
import { GlobalStyling } from 'ui/components/App/GlobalStyling';
import { SelectAwsMainAccountModal } from 'ui/components/App/SelectAwsMainAccountModal';
import { AppProvider } from 'ui/components/AppProvider';
import { GuidedWalkthroughDrawer } from 'ui/components/GuidedWalkthrough/Components/Drawer';
import { ImpersonationBar } from 'ui/components/Impersonation/ImpersonationBar';
import { OnlineChecker } from 'ui/components/OnlineChecker/OnlineChecker';
import { PlaygroundModal } from 'ui/components/Playground/Modal';
import { DataSentModalContainer } from 'ui/components/WelcomeCenter/DataSentModal/Container';
import { useInitSideNavigation } from 'ui/hooks/init-side-navigation.hook';
import { useMarketplaceSuccessMessageActivation } from 'ui/hooks/marketplace-activation.hook';
import { useTabVisibility } from 'ui/hooks/tabVisibility.hook';
import { useFeatureFlagState, useSessionState, useWelcomeState, useWhatsNewState } from 'ui/state/hooks';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';
import { usePlaygroundState } from 'ui/state/hooks/playground.state.hook';
import { useHistoryTracker } from 'ui/state/hooks/recent-visits.state.hook';
import '../../../app/app.less';
import { appRouteStates } from '../../../app/states';
import { WhatsNewDrawer } from '../WhatsNew/WhatsNewDrawer';
import {
  LazyDataParsingModal,
  LazyLogsPaymentBar,
  LazyLogsSuspensionBar,
  LazyMetricsSuspensionBar,
  LazyTracingSuspensionBar,
} from './AppGlobalComponents';
import { NavigationHeader } from './Navigation/Header/Header';
import { Sidenav } from './Navigation/Sidenav/Sidenav';
import { OnboardingBars } from './OnboardingBars';
import { initOPFSWorker } from 'services/web-workers/opfs/init-opfs-worker';
import { AiChatDrawer } from 'ui/components/AI/Components/Drawer/Drawer';

const Layout = styled(Flex)`
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

const Main = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

const Content = styled(Box)`
  display: inline-block;
  flex-grow: 1;
  flex-direction: row;
  position: relative;
  overflow: auto;
  // Eliminate default order for the z-index stack so the header will be above the content.
  z-index: 0;
`;

const AnchoredFlex = styled(Flex)`
  anchor-name: --drawer-wrapper;
`;

export const App: FunctionComponent = () => {
  const [appReady, setAppReady] = useState<boolean>(false);
  const { isWhatsNewOpen } = useWhatsNewState();
  const { isActivated: isGuidedWalkthroughActivated } = useGuidedWalkthrough();
  const { isFocusMode, isDataSentModalOpen } = useWelcomeState();
  const { loggedInUser, userSettings } = useSessionState();
  const { isPlayground } = usePlaygroundState();
  const theme = useMemo(() => {
    return getThemeObject(userSettings?.logzioTheme);
  }, [userSettings?.logzioTheme]);
  const { isFeatureEnabled: isAiMetricsDashboardEnabled } = useFeatureFlagState('aiMetricsDashboard');

  const showSidenav = !appEmbedded && loggedInUser?.id && !isFocusMode;
  const awsMarketplaceToken = cookiesProvider.getCookie(cookieKeys.AWS_MARKETPLACE_TOKEN);
  const marketplaceToken = cookiesProvider.getCookie(cookieKeys.CLOUD_MARKETPLACE_TOKEN);

  useInitSideNavigation();
  useHistoryTracker();
  useTabVisibility();
  useMarketplaceSuccessMessageActivation(appReady);

  useEffect(() => {
    initRouterConfigs();
    initIDBRetentionWorker();
    initOPFSWorker();

    (async () => {
      appRouteStates.forEach(state => {
        if (state.name && !appRouter.stateRegistry.get(state.name)) {
          appRouter.stateRegistry.register(state);
        }
      });

      await bootstrap();

      loginFlow();

      setAppReady(true);
    })();
  }, []);

  // When angular is removed - revert to the basic initialisation
  // https://ui-router.github.io/react/docs/latest/modules/components.html#uirouter

  return (
    <>
      <GlobalStyling />
      <AppProvider theme={theme}>
        {appReady ? (
          <UIRouter router={appRouter}>
            <OnlineChecker />
            {isPlayground && <PlaygroundModal />}
            <Layout {...generateLogzTestAttributes({ theme: theme.name })}>
              {isDataSentModalOpen && <DataSentModalContainer />}
              {showSidenav && <Sidenav />}
              <Main>
                {loggedInUser && (
                  <>
                    <AppConfirmModal />
                    <OnboardingBars />
                    <LazyLogsSuspensionBar />
                    <LazyTracingSuspensionBar />
                    <LazyMetricsSuspensionBar />
                    <ImpersonationBar />
                    <LazyDataParsingModal />
                    <LazyLogsPaymentBar />
                    {awsMarketplaceToken && <SelectAwsMainAccountModal />}
                    {marketplaceToken && <SelectAwsMainAccountModal />}
                    {!appEmbedded && !isFocusMode && <NavigationHeader />}
                  </>
                )}
                <AnchoredFlex />
                <Content>
                  {loggedInUser && isWhatsNewOpen ? <WhatsNewDrawer /> : null}
                  {loggedInUser && isGuidedWalkthroughActivated ? <GuidedWalkthroughDrawer /> : null}
                  {loggedInUser && isAiMetricsDashboardEnabled ? <AiChatDrawer /> : null}
                  <UIView />
                </Content>
              </Main>
            </Layout>
          </UIRouter>
        ) : (
          <Spinner fullscreen />
        )}
        <Notification />
      </AppProvider>
    </>
  );
};
