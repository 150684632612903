import { ProductKeys } from '@logz-pkg/enums';
import { Observable } from '@logz-pkg/observable';
import { ISidenavItemConfig } from 'ui/components/App/Navigation/Sidenav/item-configs';

export class NavigationStateService {
  readonly isSidenavOpen = new Observable<boolean>(false);
  readonly pageTitle = new Observable<string>('');
  readonly currentProduct = new Observable<ProductKeys>(ProductKeys.None);
  readonly hoveredNavItem = new Observable<ISidenavItemConfig | null>(null);
  readonly isMinified = new Observable<boolean>(false);

  toggleSidenav = () => {
    this.setIsSidenavOpen(!this.isSidenavOpen.get());
  };
  setIsSidenavOpen = (newDrawerState: boolean) => {
    this.isSidenavOpen.set(newDrawerState);
  };

  setCurrentProduct = (newProduct: ProductKeys) => {
    this.currentProduct.set(newProduct);
  };

  setHoveredNavItem = (item: ISidenavItemConfig | null) => {
    this.hoveredNavItem.set(item);
  };

  setPageTitle = (title: string) => {
    this.pageTitle.set(title);
  };
  setIsMinified = (mini: boolean) => {
    this.isMinified.set(mini);
  };

  clear = () => {
    this.isSidenavOpen.set(false);
    this.pageTitle.set('');
    this.currentProduct.set(ProductKeys.None);
    this.hoveredNavItem.set(null);
    this.isMinified.set(false);
  };
}

export const navigationStateService = new NavigationStateService();
