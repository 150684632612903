import { Box, Text } from '@logz-ui/styleguide';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { useCellProps } from './cell-props.hook';

interface IEmptyCellProps<T> {
  row?: Row<T>;
  columnDef?: ColumnDef<T>;
  value?: string;
  alias?: string;
}

export const EmptyCell = <T extends object>({ columnDef, row }: IEmptyCellProps<T>) => {
  const { rowHeight } = useCellProps({ columnDef, row });

  return (
    <Box height={rowHeight || undefined}>
      <Text variant="code" size={12}>
        -
      </Text>
    </Box>
  );
};
