import _ from 'lodash';

export default function () {
  const service = {};

  service.CONTINUOUS_AGGREGATION_NAME = 'CONTINUOUS_AGGREGATION';
  service.ALERT_NAME = 'ALERT';
  service.SECURITY_NAME = 'SECURITY';
  service.CONDITION_NAME = 'CONDITION';
  service.SCHEDULE_NAME = 'SCHEDULE';
  service.TABLE_FORMAT_NAME = 'TABLE';
  service.ALERT_JSON_FORMAT_LABEL = 'Default format';
  service.ALERT_TABLE_FORMAT_LABEL = 'Custom format';
  service.OPTIMIZER_JSON_FORMAT_LABEL = 'Full log';
  service.OPTIMIZER_TABLE_FORMAT_LABEL = 'Aggregations';
  service.JSON_FORMAT_NAME = 'JSON';
  service.AGGREGATION_VALUES = {
    NULL_VALUE: 'NULL',
    NONE_VALUE: 'NONE',
    COUNT_VALUE: 'COUNT',
  };
  service.OVER_PERIOD_COUNTER = 5;
  service.MAX_MINUTE_PERIOD_VALUE = 60;
  service.MIN_MINUTE_PERIOD_VALUE = 5;
  service.MAX_HOUR_PERIOD_VALUE = 24;
  service.MIN_HOUR_PERIOD_VALUE = 1;
  service.MAX_DAY_PERIOD_VALUE = 1;
  service.MIN_DAY_PERIOD_VALUE = 1;
  service.TRIGGER_THRESHOLD = 10 ** 380; // max value in sql

  service.EVERY_1_HOUR = 'EVERY_1_HOUR';
  service.EVERY_4_HOURS = 'EVERY_4_HOURS';
  service.EVERY_6_HOURS = 'EVERY_6_HOURS';
  service.EVERY_8_HOURS = 'EVERY_8_HOURS';
  service.EVERY_1_DAY = 'EVERY_1_DAY';
  service.EVERY_10_MINUTES = 'EVERY_10_MINUTES';
  service.EVERY_30_MINUTES = 'EVERY_30_MINUTES';

  service.FIELD_TYPES = {
    NUMBER: ['float', 'half_float', 'scaled_float', 'double', 'integer', 'long', 'short', 'byte', 'token_count'],
    DATE: ['date'],
  };

  service.SEVERITIES_MAP = {
    INFO: 0,
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
    SEVERE: 4,
  };

  service.getSearchableAccountsDefaultModel = () => ({
    allSubAccounts: false,
    allTimelessIndices: false,
    timelessIndices: [],
    subAccountsIds: [],
  });

  service.triggerFrequency = {
    minutesOnceEveryOption: [
      { text: '10', value: service.EVERY_10_MINUTES },
      { text: '30', value: service.EVERY_30_MINUTES },
    ],
    hoursOnceEveryOption: [
      { text: '1', value: service.EVERY_1_HOUR },
      { text: '4', value: service.EVERY_4_HOURS },
      { text: '6', value: service.EVERY_6_HOURS },
      { text: '8', value: service.EVERY_8_HOURS },
    ],
    daysOnceEveryOption: [{ text: '1', value: service.EVERY_1_DAY }],
  };

  service.initialTriggerModel = {
    name: '',
    enabled: true,
    description: '',
    search: {
      periodInMinutes: '',
      metricsAggregations: [], // list of object as preparation for multiple aggregation metrics (in server)
      source: service.getSearchableAccountsDefaultModel(),
    },
    triggerOn: {
      aggregation: {},
      severities: [
        {
          severity: 'MEDIUM',
          threshold: 0,
        },
      ],
      threshold: 0,
    },
    output: {
      format: { type: service.JSON_FORMAT_NAME },
      target: {
        emailNotifications: { notifications: [] },
        notificationEndpoints: [],
      },
    },
  };

  service.getGroupBySectionParameters = () => ({
    0: { value: '', isShown: false },
    2: { value: '', isShown: false },
    1: { value: '', isShown: false },
  });

  service.getTriggerSectionParameters = () => ({
    triggerGroupButtonsData: {
      uiData: {
        data: [
          {
            label: 'Condition',
            value: service.CONDITION_NAME,
          },
          {
            label: 'Schedule',
            value: service.SCHEDULE_NAME,
          },
        ],
        value: service.CONDITION_NAME,
        disabledBtnsList: [service.SCHEDULE_NAME],
      },
    },
    triggerIfAppSelect: {
      options: [
        {
          text: '# of events',
          value: service.AGGREGATION_VALUES.NONE_VALUE,
        },
        {
          text: 'Minimum',
          value: 'MIN',
          showOnlyFieldTypes: [...service.FIELD_TYPES.NUMBER, ...service.FIELD_TYPES.DATE],
        },
        {
          text: 'Maximum',
          value: 'MAX',
          showOnlyFieldTypes: [...service.FIELD_TYPES.NUMBER, ...service.FIELD_TYPES.DATE],
        },
        {
          text: 'Average',
          value: 'AVG',
          showOnlyFieldTypes: [...service.FIELD_TYPES.NUMBER],
        },
        {
          text: 'Sum',
          value: 'SUM',
          showOnlyFieldTypes: [...service.FIELD_TYPES.NUMBER],
        },
        {
          text: 'Unique Count',
          value: 'UNIQUE_COUNT',
        },
      ],
    },
    triggerConditionAppSelect: {
      model: 'GREATER_THAN',
      options: [
        {
          text: 'Greater than',
          value: 'GREATER_THAN',
        },
        {
          text: 'Greater than or equal to',
          value: 'GREATER_THAN_OR_EQUALS',
        },
        {
          text: 'Less than',
          value: 'LESS_THAN',
        },
        {
          text: 'Less than or equal to',
          value: 'LESS_THAN_OR_EQUALS',
        },
        {
          text: 'Equal to',
          value: 'EQUALS',
        },
        {
          text: 'Not equal to',
          value: 'NOT_EQUALS',
        },
      ],
    },
    suppressNotificationGroupButtonsData: {
      uiData: {
        data: [
          {
            label: 'Day',
            value: 'day',
          },
          {
            label: 'Hour',
            value: 'hour',
          },
          {
            label: 'Minute',
            value: 'minute',
          },
        ],
        value: 'hour',
      },
    },
    conditionOverPeriodGroupButtonsData: {
      uiData: {
        data: [
          {
            label: 'Day',
            value: 'day',
          },
          {
            label: 'Hour',
            value: 'hour',
          },
          {
            label: 'Minute',
            value: 'minute',
          },
        ],
        value: 'minute',
      },
    },
    triggerOnceEveryGroupButtonsData: {
      uiData: {
        data: [
          {
            label: 'Day',
            value: 'day',
          },
          {
            label: 'Hour',
            value: 'hour',
          },
          {
            label: 'Minute',
            value: 'minute',
          },
        ],
        value: 'hour',
      },
    },
    aggregationMetricsSelect: {
      options: [
        {
          text: '# of events',
          value: service.AGGREGATION_VALUES.COUNT_VALUE,
        },
        {
          text: 'Minimum',
          value: 'MIN',
        },
        {
          text: 'Maximum',
          value: 'MAX',
        },
        {
          text: 'Average',
          value: 'AVG',
        },
        {
          text: 'Sum',
          value: 'SUM',
        },
        {
          text: 'Unique Count',
          value: 'UNIQUE_COUNT',
        },
      ],
    },
  });

  service.getSeverityListItem = () => ({
    severity: 'MEDIUM',
    threshold: 0,
    isShown: true,
    thresholdErrorMessage: '',
    id: _.uniqueId(),
  });

  service.getSeveritiesData = function getSeveritiesData() {
    return Object.keys(service.SEVERITIES_MAP).map(severity => ({ label: _.capitalize(severity), value: severity }));
  };

  return service;
}
