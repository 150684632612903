import { AppMode, IAppModeValue } from '@logz-pkg/enums';
import {
  kibanaRouteName as operationsKibanaState,
  kibanaViewUrlPrefix as operationsKibanaHash,
} from '../../../app/states/osd/osd.route.params';
import {
  researchViewUrlPrefix as securityKibanaHash,
  researchRouteName as securityKibanaState,
  securityRouteState,
} from '../../../app/states/security/security.routes.names';

const operationsRootState = operationsKibanaState;

export const AppModes: Partial<Record<AppMode, IAppModeValue>> = {
  [AppMode.OPERATIONS]: {
    value: AppMode.OPERATIONS,
    normalized: 'Operations',
    rootState: operationsRootState,
    kibanaState: operationsKibanaState,
    kibanaHash: operationsKibanaHash,
  },
  [AppMode.SECURITY]: {
    value: AppMode.SECURITY,
    normalized: 'Security',
    rootState: securityRouteState,
    kibanaState: securityKibanaState,
    kibanaHash: securityKibanaHash,
  },
};

export const appModesFactory = () => AppModes;
