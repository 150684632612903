import React, { useEffect } from 'react';
import { Badge, Box, colors, Group, Link, Modal, NotificationService, Text, Title } from '@logz-ui/styleguide';
import { SearchableAccountModel } from '@logz-pkg/models';
import styled from 'styled-components';
import { useMarketplaceActivation } from 'ui/hooks/marketplace-activation.hook';

const badgeColors = [colors.red[400], colors.blue[400], colors.green[400], colors.orange[400], colors.yellow[400]];

const StyledButton = styled(Link)`
  padding: 12px;
  background: ${({ theme }) => theme.button.variants.tertiary.background};
  &:hover {
    background: ${({ theme }) => theme.button.variants.tertiary.hover.background};
  }
  &:active {
    background: ${({ theme }) => theme.button.variants.tertiary.active.background};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray[300]};
  }
`;

export const SelectAwsMainAccountModal: React.FC = () => {
  const { accounts, activateSubscription, isLoading } = useMarketplaceActivation();

  const handleSelectedAccount = async (account: SearchableAccountModel) => {
    try {
      await activateSubscription(account);
      // tech debt https://logzio.atlassian.net/browse/DEV-47923 - refresh setting without reload.
      window.location.reload();
    } catch (err) {
      NotificationService.danger({
        title: 'Error Occurred',
        message: (
          <>
            We couldn't connect your AWS purchase to the account please Contact our {` `}
            <a href="mailto:help@logz.io">support team</a>
          </>
        ),
        autoHide: false,
        closable: true,
      });
    }
  };

  useEffect(() => {
    if (isLoading) return;

    if (accounts.length === 1) {
      handleSelectedAccount(accounts[0]);
    }

    if (accounts.length === 0) {
      NotificationService.info({
        autoHide: false,
        title: 'Owner account not found',
        message:
          'To activate your marketplace subscription, switch to your main admin account using the dropdown in the top right corner.',
      });
    }
  }, [isLoading]);

  if (accounts.length <= 1) return null;

  return (
    <Modal open={true} noCloseButton p={6} size={'s'}>
      <Title>
        Choose an account <br />
        to connect to your AWS account
      </Title>
      <Box overflow={'scroll'}>
        <Group vertical mt={8} fullWidth gap={0}>
          {accounts.map((account, i) => {
            return (
              <StyledButton
                key={`account_${account.id}`}
                onClick={() => {
                  handleSelectedAccount(account);
                }}
              >
                <Group width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
                  <Badge color={badgeColors[i % badgeColors.length]} size={'l'} value={account.name[0]} />
                  <Text>{account.name}</Text>
                </Group>
              </StyledButton>
            );
          })}
        </Group>
      </Box>
    </Modal>
  );
};
