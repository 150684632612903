import { createQueryString, assignQueryOptions, getUrlResourceParam } from '../product/route-helpers/route-helpers';

export const kibanaUrlResourceParam = 'resource';
export const resourceUrlParam = getUrlResourceParam(kibanaUrlResourceParam);

const deprecatedKibanaRouteParam = 'kibanaRoute';
export const logzUrlQueryParams = ['drilldown'];
export const kibanaUrlQueryParams = [
  '_a',
  '_g',
  'id',
  'type',
  'indexPattern',
  'savedSearchId',
  'filter',
  'embed',
  'notFound',
  'addToDashboard',
  'addVisualization',
  'discoverTab',
  'selected',
  'cluster',
  'groupBy',
  'data',
  'quickViewId',
  'quickViewType',
  'namespace',
];

const logzKibanaUrlParams = [...kibanaUrlQueryParams, ...logzUrlQueryParams];

// DEV-25025 remove alertAdvisor param
const additionalQueryParams = [
  'shareToken',
  'accountIds',
  'snapshot_object_id',
  'force_snapshot_timezone',
  'forceShowQueryBar',
  'alertAdvisor',
];
const allQueryParams = [deprecatedKibanaRouteParam, ...logzKibanaUrlParams, ...additionalQueryParams];

export const params = assignQueryOptions(
  {
    kibanaRoute: { value: null },
  },
  [kibanaUrlResourceParam, ...allQueryParams],
);

// it will look like that "?{kibanaRoute:any}&{_a:any}&{_g:any}&{id:any}&additionalQueryStrings"
export const queryString = createQueryString(allQueryParams);
export const appQueryParams = [...additionalQueryParams, ...logzUrlQueryParams];
