import { plainToClass, classToPlain } from 'class-transformer';
import {
  ElasticsearchEnhancedFilterModel,
  ElasticsearchEnhancedFilterOperator,
  ElasticsearchFieldModel,
  IElasticsearchFilterValue,
} from '@logz-pkg/models';
import { ElasticsearchFieldType } from '@logz-pkg/enums';
import { FilterInputValue } from '../types';

const filterValueToModel = (currentValue: FilterInputValue) => {
  const model = new ElasticsearchEnhancedFilterModel();

  model.field = currentValue.field;
  model.negate = currentValue.negate;
  model.isDisabled = currentValue.isDisabled ?? false;
  model.type = currentValue.operator;
  model.value = currentValue.value;
  model.invalid = currentValue.invalid ?? false;

  return model;
};

const modelToFilterValue = (filter: ElasticsearchEnhancedFilterModel) => {
  const filterCopy = { ...filter };

  const { field, invalid, negate, isDisabled } = filterCopy;

  if (filterCopy.type === 'IS_ONE_OF') {
    if ((filterCopy.value as IElasticsearchFilterValue['IS_ONE_OF']).length === 1) {
      filterCopy.type = 'IS';
      filterCopy.value = filterCopy.value[0];
    }
  } else if (filterCopy.type === 'IS_BETWEEN') {
    filterCopy.value = [
      (filterCopy.value as IElasticsearchFilterValue['IS_BETWEEN']).gte,
      (filterCopy.value as IElasticsearchFilterValue['IS_BETWEEN']).lt,
    ];
  } else if (filterCopy.type === 'GREATER_THAN') {
    filterCopy.value = (filterCopy.value as IElasticsearchFilterValue['GREATER_THAN']).gte;
  } else if (filterCopy.type === 'LESS_THAN') {
    filterCopy.value = (filterCopy.value as IElasticsearchFilterValue['LESS_THAN']).lt;
  }

  const filterValue: FilterInputValue = {
    field,
    invalid,
    negate,
    isDisabled,
    operator: filterCopy.type,
    value: filterCopy.value,
  };

  return filterValue;
};

const buildField = (name: string, type = ElasticsearchFieldType.String) => {
  const field = new ElasticsearchFieldModel();

  field.isAggregatable = true;
  field.name = name;
  field.type = type;

  return field;
};

const buildEnhancedFilter = ({
  field,
  value,
  type = 'IS_ONE_OF',
  negate = false,
  isDisabled = false,
}: BuildFilter): ElasticsearchEnhancedFilterModel => {
  const expectedFilter = new ElasticsearchEnhancedFilterModel();

  expectedFilter.field = field;
  expectedFilter.invalid = false;
  expectedFilter.negate = negate;
  expectedFilter.isDisabled = isDisabled;
  expectedFilter.type = type;
  expectedFilter.value = value;

  return expectedFilter;
};

type BuildFilter = {
  field: ElasticsearchFieldModel;
  value: ElasticsearchEnhancedFilterModel['value'];
  type?: ElasticsearchEnhancedFilterOperator;
  negate?: boolean;
  isDisabled?: boolean;
};

const buildFilter = (currentValue: FilterInputValue) => {
  const newFilter = filterValueToModel(currentValue);

  if (newFilter.type === 'IS') {
    newFilter.type = 'IS_ONE_OF';
    newFilter.value = [newFilter.value as string];
  } else if (newFilter.type === 'GREATER_THAN') {
    newFilter.value = { gte: newFilter.value as number, lt: null };
  } else if (newFilter.type === 'LESS_THAN') {
    newFilter.value = { gte: null, lt: newFilter.value as number };
  } else if (newFilter.type === 'IS_BETWEEN') {
    newFilter.value = { gte: newFilter.value[0], lt: newFilter.value[1] };
  }

  return plainToClass(ElasticsearchEnhancedFilterModel, classToPlain(newFilter));
};

export const smartSelectUtils = { buildFilter, filterValueToModel, modelToFilterValue, buildField, buildEnhancedFilter };
