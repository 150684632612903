import { FeatureFlags } from '@logz-pkg/enums';
import { cookiesProvider, sessionApiService } from '@logz-pkg/frontend-services';

const cookieKey = `${FeatureFlags.experimentalApiGateway}-enabled`;
const intervalTime = 1000 * 60 * 30;
let timeoutId: null | ReturnType<typeof setTimeout> = null;

async function startApiGatewayCookieInterval() {
  const { featureFlags } = await sessionApiService.get();

  if (featureFlags.includes(FeatureFlags.experimentalApiGateway)) {
    cookiesProvider.setCookie(cookieKey, true, { expires: 1 / 24 });
  } else {
    cookiesProvider.removeCookie(cookieKey);
  }

  timeoutId = setTimeout(startApiGatewayCookieInterval, intervalTime);
}

function stopApiGatewayCookieInterval() {
  if (timeoutId) clearTimeout(timeoutId);

  cookiesProvider.removeCookie(cookieKey);
}

export const apiGatewayService = {
  startApiGatewayCookieInterval,
  stopApiGatewayCookieInterval,
};
