import alertDisabledTemplate from './upgrade-service/alerts/alert-disabled.html?raw';
import alertsNotAvailableForPlanTemplate from './upgrade-service/alerts/alerts-not-available-for-plan.html?raw';
import usersUpgradeTemplate from './upgrade-service/users-upgrade.html?raw';
import s3ArchiveUpgradeTemplate from './upgrade-service/s3archive-upgrade.html?raw';

const alertDisabledId = 'alertDisabledTemplate';
const alertsNotAvailableForPlanId = 'alertsNotAvailableForPlanTemplate';
const usersUpgradeId = 'usersUpgradeTemplate';
const s3ArchiveUpgradeId = 's3ArchiveUpgradeTemplate';

const AddTemplatesToCache = [
  '$templateCache',
  function AddTemplatesToCache($templateCache) {
    $templateCache.put(alertDisabledId, alertDisabledTemplate);
    $templateCache.put(alertsNotAvailableForPlanId, alertsNotAvailableForPlanTemplate);
    $templateCache.put(usersUpgradeId, usersUpgradeTemplate);
    $templateCache.put(s3ArchiveUpgradeId, s3ArchiveUpgradeTemplate);
  },
];

export { AddTemplatesToCache, alertDisabledId, alertsNotAvailableForPlanId, usersUpgradeId, s3ArchiveUpgradeId };
