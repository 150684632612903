import { useMemo } from 'react';
import { PointOptionsObject, SeriesPieOptions, SeriesColumnOptions } from 'highcharts';

export const usePieSeries = (series: SeriesColumnOptions[]) => {
  const allSeriesCounter = useMemo(
    () =>
      series.reduce((acc, curr) => {
        curr.data.forEach((item: PointOptionsObject) => (acc += item.y));

        return acc;
      }, 0),
    [series],
  );

  const pieSeries: SeriesPieOptions[] = useMemo(
    () => [
      {
        colorByPoint: true,
        type: 'pie',
        data: Object.entries(
          series.reduce((acc, curr) => {
            if (!acc[curr.name]) acc[curr.name] = { data: [{ y: 0 }] };

            curr.data.forEach((item: PointOptionsObject) => (acc[curr.name].data[0].y += item.y));

            return acc;
          }, {}),
        ).map(([key, value]: [string, { data: [{ name: string; y: number }] }]) => ({
          name: key,
          y: allSeriesCounter ? (value.data[0].y * 100) / allSeriesCounter : 0,
        })),
      },
    ],
    [series, allSeriesCounter],
  );

  return {
    pieSeries,
  };
};
