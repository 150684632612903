import copy from 'copy-to-clipboard';
import { NotificationService, Text, ConfirmModal } from '@logz-ui/styleguide';
import { ArchiveNotificationSubject } from '@logz-pkg/enums';
import { RolePolicyContent } from './RolePolicyContent';

const actionToTextMapping = {
  ['getObject']: 's3:GetObject',
  ['list']: 's3:ListBucket',
  ['getLocation']: 's3:GetBucketLocation',
  ['putObject']: 's3:PutObject',
};

export const RolePolicyModal = ({ onConfirm, onClose, bucketName, actions }) => {
  const modalText = {
    title: 'Policy template',
    text: 'Paste this text in an AWS policy JSON tab and attach it to an IAM role, so we can fetch logs from your S3 bucket',
    policy: {
      Version: '2012-10-17',
      Statement: [
        {
          Sid: 'VisualEditor0',
          Effect: 'Allow',
          Action: actions.map(action => actionToTextMapping[action]),
          Resource: [`arn:aws:s3:::${bucketName}`, `arn:aws:s3:::${bucketName}/*`],
        },
      ],
    },
  };

  const policyText = JSON.stringify(modalText.policy, null, 2);

  const handleConfirm = () => {
    try {
      copy(policyText);
      NotificationService.success({
        message: 'Policy copied to your clipboard',
        subject: ArchiveNotificationSubject.CopyRolePolicy,
      });
    } catch (e) {
      NotificationService.unexpectedError('Failed to copy Role Policy', {
        subject: ArchiveNotificationSubject.CopyRolePolicy,
      });
    } finally {
      onConfirm();
    }
  };

  return (
    <ConfirmModal
      isOpen
      title={modalText.title}
      confirmText={'Copy'}
      cancelText={'Close'}
      onConfirm={handleConfirm}
      onClose={onClose}
    >
      <Text>{modalText.text}</Text>
      <RolePolicyContent content={policyText} />
    </ConfirmModal>
  );
};
