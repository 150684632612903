export const DEFAULT_QUERY_FILTERS = ['from', 'sortBy', 'sortOrder', 'search'];

export default class QueryGenerator {
  constructor(
    pageSize = 25,
    cb = () => {
      throw new Error('fetchCallback method is not initialized');
    },
  ) {
    this.cb = cb;
    this.pageSize = pageSize;
    this.pagination = {
      from: 0,
      size: pageSize,
    };
    this.filters = {};
    this.search = null;
  }

  setFetchCallback = cb => {
    this.cb = cb;
    this.resetPagination();
  };

  updateFilters = (filters, fetch = true) => {
    this.filters = filters;
    this.resetPagination();

    if (fetch) return this.executeCallback();
  };

  updatePagination = (pageNumber, size = this.pageSize, fetch = true) => {
    const from = (pageNumber - 1) * size;

    this.pagination = Object.assign({}, this.pagination, { from, size });

    if (fetch) return this.executeCallback();
  };

  resetPagination = () => {
    this.updatePagination(1, this.pageSize, false);
  };

  updateSorting = (sortBy, isDescending, fetch = true) => {
    this.sort = {
      sortBy,
      sortOrder: isDescending ? 'DESC' : 'ASC',
    };
    this.resetPagination();

    if (fetch) return this.executeCallback();
  };

  updateSearch = (search = null, fetch = true) => {
    this.search = search ? { search } : {};
    this.resetPagination();

    if (fetch) return this.executeCallback();
  };

  executeCallback = () => this.cb(Object.assign({}, this.search, this.filters, this.sort, this.pagination));
}
