import { FunctionComponent, useState } from 'react';
import { Button, Link, Group, NotificationService, InlineNotification } from '@logz-ui/styleguide';
import { MetricsSubject } from '@logz-pkg/enums';
import { GrafanaDescription } from './GrafanaDescription';
import { ErrorHandlingService } from 'services/error-handling.service';
import { ProductEmptyState } from 'ui/components/shared/templates/ProductEmptyState/ProductEmptyState';
import { metricsService } from 'services/metrics/metrics.service';
import { tourService } from 'services/tours/tours';
import { TourType } from 'services/tours/types';
import { videos } from 'ui/components/shared/Video/videos';
import { FilterTagNames, goToIntegrationsPage } from 'ui/components/Integrations/utils';

interface IGrafanaEmptyState {
  canCreateMetricsAccount: boolean;
  onSuccess?(): void;
}

export const GrafanaEmptyState: FunctionComponent<IGrafanaEmptyState> = ({ canCreateMetricsAccount, onSuccess }) => {
  const [isAccountCreationInProgress, setIsAccountCreationInProgress] = useState<boolean>(false);

  const sendMetricsHandler = async () => {
    try {
      setIsAccountCreationInProgress(true);

      await metricsService.createFreeMetricsAccount();

      setIsAccountCreationInProgress(false);

      NotificationService.success({
        subject: MetricsSubject.GrafanaOnboarding,
        message: 'Your Infrastructure Monitoring account is ready for you. Enjoy!',
      });

      await goToIntegrationsPage({ tags: FilterTagNames.METRICS });
      onSuccess?.();
      tourService.showTour({ tourType: TourType.Metrics });
    } catch (error) {
      setIsAccountCreationInProgress(false);

      const message =
        'Sorry, we had a problem creating the Infrastructure Monitoring account. Please try again in a few moments.';

      ErrorHandlingService.handleApiError({
        title: message,
        unexpectedErrorLogMessage: message,
        error,
      });
    }
  };

  return (
    <ProductEmptyState>
      <ProductEmptyState.ContentSection>
        <ProductEmptyState.Title>Get started with Infrastructure Monitoring</ProductEmptyState.Title>
        <ProductEmptyState.Description>
          <GrafanaDescription />
        </ProductEmptyState.Description>
        <ProductEmptyState.CallToAction>
          <Group vertical gap={3}>
            {canCreateMetricsAccount ? (
              <Link currentTab={true} onClick={sendMetricsHandler}>
                <Button
                  variant={'primary'}
                  subject={MetricsSubject.StartSendingMetrics}
                  loading={isAccountCreationInProgress}
                >
                  Start sending metrics
                </Button>
              </Link>
            ) : (
              <InlineNotification
                noClose={true}
                variant={'info'}
                title={'Not an admin?'}
                subject={MetricsSubject.NotAnAdminNotification}
              >
                Contact your account administrator to start sending metrics and using Infrastructure Monitoring.
              </InlineNotification>
            )}
          </Group>
        </ProductEmptyState.CallToAction>
      </ProductEmptyState.ContentSection>
      <ProductEmptyState.MediaSection src={videos.infrastructureMonitoringDemo} />
    </ProductEmptyState>
  );
};
