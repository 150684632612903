import { parse } from 'url';
import { KibanaTheme } from '@logz-pkg/enums';
import { authTokens, userService, LoggerService } from '@logz-pkg/frontend-services';

export const getKibanaTheme = async (): Promise<KibanaTheme> => {
  const isInShareMode = authTokens.isInShareMode();

  if (isInShareMode) {
    const darkThemeFromUrl = parse(window.location.href, true).query.theme === 'dark';

    return darkThemeFromUrl ? KibanaTheme.Dark : KibanaTheme.Light;
  }

  try {
    const { kibanaTheme } = (await userService.getUserSettings()) ?? { kibanaTheme: KibanaTheme.Light };

    return kibanaTheme;
  } catch (error) {
    LoggerService.logError({
      message: `Failed to get Kibana theme from user settings - ${error?.message}`,
      error,
    });
  }

  return KibanaTheme.Light;
};

export const kibanaThemeService = {
  getKibanaTheme,
};
