import { UIRouterReact } from '@uirouter/react';
import { AppStateDeclaration } from 'states/AppStateDecleration';

export const registerTitleTransitions = (appRouter: UIRouterReact) => {
  appRouter.transitionService.onSuccess({}, transition => {
    const transitionTarget = transition.to();

    const targetTitle = transitionTarget?.data?.title;
    const targetBreadrumb = transitionTarget?.data?.breadcrumb as AppStateDeclaration['data']['breadcrumb'];

    if (targetTitle) {
      window.document.title = targetTitle;
    } else if (targetBreadrumb) {
      const label = targetBreadrumb.calculateLabel?.(transition.params('to')) ?? targetBreadrumb.label;

      window.document.title = `${label} - Logz.io`;
    } else {
      window.document.title = 'Logz.io';
    }
  });
};
