import { FunctionComponent } from 'react';
import { Divider, Group } from '@logz-ui/styleguide';
import { EmptyStateTitle } from './EmptyStateTitle';
import { GetStarted } from './GetStarted';
import { DemoData } from './DemoData';
import { GoToDocs } from './GoToDocs';

export const ActivatedWithNoData: FunctionComponent = () => {
  return (
    <Group vertical gap={5} fullWidth>
      <EmptyStateTitle />
      <GetStarted />
      <DemoData />
      <Divider color={'gray.400'} mt={10} mb={4} />
      <GoToDocs />
    </Group>
  );
};
