import { urlParser } from '@logz-pkg/utils';
import { LoggerService } from '@logz-pkg/frontend-services';
import { manageTokensRouteConfig } from '../../app/states/manage-tokens/manage-tokens.route-config';
import { integrationsRouteUrls } from '../../app/states/integrations/route-config';
import { IShowTourParams, ITourService } from './types';
import { pendoTourService } from './tours.pendo';
import { ErrorHandlingService } from 'services/error-handling.service';

class EmptyTourService {
  async isDisabled() {
    return true;
  }
  async updateTracingParams() {}
  async startSession() {}
  async showTour() {}
  async reloadParams() {}
}

const emptyTourService = new EmptyTourService();

let _tourService: ITourService;

const WHITELIST_URLS = [
  `${manageTokensRouteConfig.url}${manageTokensRouteConfig.dataShippingUrl}`,
  integrationsRouteUrls.page,
];

const getTourService = async (): Promise<ITourService> => {
  if (_tourService) return _tourService;

  _tourService = emptyTourService;

  if (!(await pendoTourService.isDisabled())) {
    _tourService = pendoTourService;
  }

  return _tourService;
};

const sanitizeUrl = urlString => {
  const shouldSanitize = !WHITELIST_URLS.find(toSanitizeUrl => urlString.includes(toSanitizeUrl));

  if (!shouldSanitize) return urlString;

  const parsedUrl = urlParser(urlString);
  const url = new URL(urlString);

  url.hash = parsedUrl.hashPathname;

  return url.toString();
};

const startSession = async ({
  targetWindow,
  isMainWindow = false,
}: {
  targetWindow?: Window;
  isMainWindow?: boolean;
}) => {
  const tourService = await getTourService();

  if (tourService === emptyTourService) return;

  try {
    await tourService.startSession({ targetWindow, sanitizeUrl, isMainWindow });
  } catch (error) {
    LoggerService.logError({ message: 'Could not initialize tour params', error });
  }
};

const updateTracingParams = async (...tracingParams) => {
  if (_tourService === emptyTourService) return;

  _tourService.updateTracingParams(...tracingParams);
};

const showTour = async ({ tourType, isTriggeredByUser }: IShowTourParams) => {
  try {
    await _tourService.showTour({ tourType, isTriggeredByUser });
  } catch (error) {
    const errorMessage = 'Failed to show tour';

    if (isTriggeredByUser) {
      ErrorHandlingService.handleUiError({
        title: "Can't show tour",
        unexpectedErrorLogMessage: errorMessage,
        error,
      });
    } else {
      ErrorHandlingService.logError(errorMessage, error);
    }
  }
};

export const tourService = {
  startSession,
  updateTracingParams,
  showTour,
};
