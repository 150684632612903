import { IDBNames } from '@logz-pkg/enums';
import { LoggerService } from '@logz-pkg/frontend-services';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import React, { useEffect, useRef, useState } from 'react';
import { createQueryClient } from '../utils';
import { createOPFSPersister } from './create-opfs-persister';
import { useSessionState } from 'ui/state/hooks';
import { opfsService } from 'services/web-workers/opfs/opfs.service';

export let queryClient: QueryClient | null = null;

interface IReactQueryPersistProviderProps {
  children: React.ReactNode;
  DBName: `${IDBNames}`;
}

export const ReactQueryPersistProvider: React.FC<IReactQueryPersistProviderProps> = ({ children, DBName }) => {
  const { loggedInAccount } = useSessionState();
  const [isReady, setIsReady] = useState(false);
  const initializingRef = useRef(false);

  useEffect(() => {
    if (!queryClient) {
      queryClient = createQueryClient();
    }
  }, []);

  useEffect(() => {
    if (!loggedInAccount?.id || !queryClient || initializingRef.current) {
      return;
    }

    const accountId = loggedInAccount.id;
    let isMounted = true;

    initializingRef.current = true;

    const initializeAccount = async () => {
      try {
        queryClient.clear();

        await opfsService.cleanup();
        await opfsService.initialize(accountId);

        if (isMounted) {
          setIsReady(true);
          initializingRef.current = false;
        }
      } catch (error) {
        LoggerService.logError({
          message: 'Failed to initialize OPFS',
          error,
          extra: { accountId },
        });

        if (isMounted) {
          setIsReady(true);
          initializingRef.current = false;
        }
      }
    };

    setIsReady(false);
    initializeAccount();

    return () => {
      isMounted = false;
    };
  }, [loggedInAccount?.id]);

  if (!isReady || !queryClient || !loggedInAccount?.id) {
    return null;
  }

  try {
    const persister = createOPFSPersister(DBName);

    return (
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister,
          dehydrateOptions: {
            shouldDehydrateQuery: query => query.state.status === 'success' && !query.state.error,
          },
        }}
      >
        {children}
      </PersistQueryClientProvider>
    );
  } catch (error) {
    LoggerService.logError({
      message: 'Failed to create OPFS persister, running without persistence',
      error,
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
  }
};
