import { Permissions } from '@logz-pkg/enums';
import { alertApiService } from '@logz-pkg/frontend-services';
import { LearnMoreLink, Text } from '@logz-ui/styleguide';
import { UIView } from '@uirouter/react';
import { DEFAULT_QUERY_FILTERS } from '../dashboard/triggers/table-data-fetcher/query-generator';
import { TRIGGERED_ALERTS_PAGE_FILTERS_IDS } from '../dashboard/triggers/triggered-alerts/page-filter-ids';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyAngular } from './LazyAngular';
import { redirectIfFFDisabled, redirectIfNoPermission } from './utils';
import { LazyReact } from 'ui/components/shared/LazyReact';
import { IEventManagerData } from 'ui/components/EventManagement/EventManagement';
import { ALERT_DEFINITIONS_PAGE_FILTERS_IDS } from 'ui/components/AlertDefinitions/table/page-filter-ids';

export const alertsRoutesStates = {
  alertDefinition: 'dashboard.triggers.alert-definitions',
  triggeredAlerts: 'dashboard.triggers.triggered-alerts',
  v219New: 'dashboard.alerts-v2019-new',
  endpoints: 'dashboard.alerts.endpoints',
};
const alertDefinitionsParams = [...DEFAULT_QUERY_FILTERS, ...Object.values(ALERT_DEFINITIONS_PAGE_FILTERS_IDS)];
const triggeredAlertsParams = [...DEFAULT_QUERY_FILTERS, ...Object.values(TRIGGERED_ALERTS_PAGE_FILTERS_IDS)];
const redirectIfNoReadAlertPermission = redirectIfNoPermission({ hasPermission: Permissions.READ_ALERTS });
const alertDefinitionsTitle: JSX.Element = (
  <Text>
    Set up alerts to automatically get notified about issues that demand attention.{' '}
    <LearnMoreLink about={'https://docs.logz.io/user-guide/alerts/'} />
  </Text>
);
const redirectIfLogsEventManagementDisabled = redirectIfFFDisabled({
  featureFlag: 'LogsEventManagment',
  toState: alertsRoutesStates.alertDefinition,
});
const redirectIfTriggeredAlertsDisabled = redirectIfFFDisabled({
  featureFlag: 'DeprecatedTriggeredAlert',
  toState: alertsRoutesStates.alertDefinition,
});
const alertEventData: IEventManagerData = {
  modelName: 'alert',
  editStateName: 'dashboard.alerts-v2019-edit',
  docLink: 'https://docs.logz.io/user-guide/alerts/event-management.html',
};
const LazyEventManagement = props => (
  <LazyReact
    lazyImport={() => import('ui/components/EventManagement/EventManagement')}
    resolver={({ EventManagement }) =>
      () =>
        <EventManagement {...alertEventData} />}
    {...props}
  />
);

export const alertsRoutes: AppStateDeclaration[] = [
  {
    name: 'dashboard.alerts',
    abstract: true,
    url: '/alerts',
    component: UIView,
    data: { authenticate: true },
  },
  {
    name: alertsRoutesStates.alertDefinition,
    url: `/alert-definitions?${alertDefinitionsParams.join('&')}`,
    params: {
      sortBy: 'updatedAt',
      sortOrder: 'DESC',
      severities: {
        array: true,
      },
    },
    dynamic: true,
    component: props => (
      <LazyReact
        {...props}
        lazyImport={() => import('ui/components/AlertDefinitions/Container')}
        resolver={({ Container }) => Container}
      />
    ),
    data: {
      breadcrumb: { label: 'Alert Definitions' },
      authenticate: true,
      title: 'Alert definitions',
      duplicatedStateName: 'dashboard.alerts-v2019-duplicate',
      editStateName: 'dashboard.alerts-v2019-edit',
      addStateName: 'dashboard.alerts-v2019-new',
      apiService: alertApiService,
      modelName: 'alert',
      subHeader: alertDefinitionsTitle,
    },
    resolve: [redirectIfNoReadAlertPermission],
  },
  {
    name: alertsRoutesStates.triggeredAlerts,
    url: `/triggered-alerts?${triggeredAlertsParams.join('&')}`,
    params: {
      from: '0',
      sortBy: 'DATE',
      sortOrder: 'DESC',
    },
    component: () => {
      const loadComponent = async () => {
        const [controller, template] = await Promise.all([
          import('../dashboard/triggers/triggered-alerts/triggered-alerts.controller'),
          import('../dashboard/triggers/triggered-alerts/triggered-alerts.template.html?raw'),
        ]);

        const component = {
          controller: controller.default,
          template: template.default,
          controllerAs: 'vm',
        };

        return component;
      };

      return <LazyAngular name={'angularTriggeredAlerts'} componentGetter={loadComponent} />;
    },
    data: { authenticate: true, title: 'Triggered Alerts', breadcrumb: { label: 'Triggered alerts' } },
    resolve: [redirectIfNoReadAlertPermission, redirectIfTriggeredAlertsDisabled],
  },
  {
    name: 'dashboard.event-management',
    url: '/event-management',
    resolve: [redirectIfLogsEventManagementDisabled],
    component: LazyEventManagement,
    data: {
      breadcrumb: { label: 'Event management' },
      authenticate: true,
      title: 'Event Management',
    },
  },
];
