import React from 'react';
import { Group, Icon, Link, Text } from '@logz-ui/styleguide';

const disclaimer =
  'Please note that Observability IQ is utilizing AI technology and therefore some of its outputs may include AI-generated content. This content might be incomplete, inaccurate, and/or contain errors. Logz.io makes no representations or warranties of any kind, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of this feature and/or its use. You can opt out of this feature at any time by contacting your Account Executive.';

export const DisclaimerInfo: React.FC = () => (
  <Group alignItems={'center'} justifyContent={'flex-start'} width={'100%'} gap={1}>
    <Text size={12} color={'gray.600'} weight={500} pr={2}>
      AI Agent uses AI. Check for mistakes
    </Text>
    <Icon icon={'circle-question-solid'} tooltip={{ title: disclaimer }} size={14} color={'royalBlue.700'} />
    <Link size={12} href="https://docs.logz.io/docs/user-guide/observability/assistantiq">
      <Text size={12} color="royalBlue.700">
        Docs
      </Text>
    </Link>
    <Text size={12} color="royalBlue.700">
      |
    </Text>
    <Link size={12} href="https://docs.logz.io/docs/user-guide/observability/faq">
      <Text size={12} color="royalBlue.700">
        FAQ
      </Text>
    </Link>
  </Group>
);
