import moment from 'moment';
import { kibanaTimeModes } from '../../osd/osd-route.service';
import { goToKibanaWithParams, handleRouteError } from '../osd/osd.route.helpers';

const isRelative = time => time.includes('now');
// parse to ISO if it's not a relative format
const toKibanaFormat = time => (isRelative(time) ? time : moment(parseInt(time, 10)).toISOString());

const toLuceneReplacer = (match, strValues) => `("${strValues.split(',').join('" OR "')}")`;

export const drilldownFromGrafana = async function drilldownFromGrafana({ from, to, query }) {
  // Relative dates contain the word 'now' (since relative is by definition depends on the current time)
  // "from": 'now/1d'
  // "from": '1873567894192'
  const anyRelative = isRelative(from) || isRelative(to);
  const mode = anyRelative ? kibanaTimeModes.relative : kibanaTimeModes.absolute;

  const queryInLuceneSyntax = query && query.replace(/{(.*?)}/g, toLuceneReplacer);

  try {
    await goToKibanaWithParams({
      // If the date is in milliseconds format, we convert it to ISO format
      fromDate: toKibanaFormat(from),
      toDate: toKibanaFormat(to),
      query: queryInLuceneSyntax,
      allSubAccounts: true,
      mode,
    });
  } catch (err) {
    await handleRouteError(err);
  }
};
