import { useObservable } from '@logz-pkg/observable';
import { tracingStateService } from '../tracing.state.service';
import { tracingService } from 'services/tracing/tracing.service';

export const useTracingState = () => ({
  setTracingBudget: tracingStateService.setTracingBudgetState,
  tracingBudgetState: useObservable(tracingStateService.tracingBudgetState),

  setSelectedAccountId: tracingStateService.setSelectedAccountIdState,
  selectedAccountIdState: useObservable(tracingStateService.selectedAccountIdState),

  setTracingProductActivation: tracingStateService.setTracingProductActivationState,
  tracingProductActivationState: useObservable(tracingStateService.tracingProductActivationState),

  setSearchableTracingAccounts: tracingStateService.setSearchableTracingAccounts,
  searchableTracingAccounts: useObservable(tracingStateService.searchableTracingAccounts),

  updateTracingStatus: () => tracingService.updateTracingStatus(),
});
