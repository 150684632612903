import { ExplorePageSubjects } from '@logz-pkg/enums';
import { cacheProvider } from '@logz-pkg/frontend-services';
import { useObservable } from '@logz-pkg/observable';
import { Button, Grid, Group, InlineNotification, Link, NotificationService, Text } from '@logz-ui/styleguide';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { exploreRouteNames } from 'states/explore/explore.route';
import { exploreOnboardingStatesService } from 'ui/components/Explore/state/app-state/explore-onboarding-states.service';
import { useRouter } from 'ui/hooks';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { analyticsService } from 'services/analytics/analytics.service';

const StyledNotification = styled(InlineNotification)`
  align-self: center;
  display: flex;
  padding: 5px 8px;
  div {
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  span {
    font-size: 12px;
    font-weight: 600;
  }
`;

const EXPLORE_FLOW_URL = 'https://logz.navattic.com/k05a0c2m';
const CACHE_KEY = 'hide-explore-as-default-callout';

const ExploreBanner = ({ handleClose, handleExploreAsDefault, isLoading }) => {
  return (
    <StyledNotification
      variant={'announcement'}
      title={
        <Grid mx={2} gridTemplateColumns={'1.5fr 1fr'}>
          <Text ellipsis size={12} weight={600} whiteSpace="nowrap">
            Explore is our new logging experience
          </Text>
          <Group gap={4}>
            <Link size={12} href={EXPLORE_FLOW_URL} whiteSpace="nowrap">
              Start tour
            </Link>
            <StyledButton disabled={isLoading} size="xs" variant="secondary" onClick={handleExploreAsDefault}>
              Set as default
            </StyledButton>
          </Group>
        </Grid>
      }
      onClose={handleClose}
    />
  );
};

const BackToExplore = ({ handleClose, handleClick }) => {
  return (
    <StyledNotification
      variant={'announcement'}
      title={
        <Group mx={2} gap={2}>
          <Text ellipsis size={14} weight={600} whiteSpace="nowrap">
            Enhance your logging experience with Explore!
          </Text>
          <StyledButton minWidth={'auto'} size="xs" variant="secondary" onClick={handleClick}>
            Check it out
          </StyledButton>
        </Group>
      }
      onClose={handleClose}
    />
  );
};

export const ExploreAsDefaultCallout: React.FC = () => {
  const { currentState, goToState } = useRouter();
  const url = window.location.hash;
  const { data: dismissed, refetch } = useQuery({
    queryKey: [CACHE_KEY],
    queryFn: () => cacheProvider.getPersistent(CACHE_KEY),
  });
  const exploreAsDefaultFeatureFlagEnabled = featureFlagStateService.isFeatureEnabled('ExploreAsDefault');
  const discoverBannerEnabled = featureFlagStateService.isFeatureEnabled('DiscoverToExploreBanner');
  const onboardingState = useObservable(exploreOnboardingStatesService.state);

  const setExploreAsDefault = async (): Promise<void> => {
    await exploreOnboardingStatesService.setExploreAsDefault(true);
  };

  const { mutateAsync: updateExploreToDefault, isPending: isLoading } = useMutation({
    mutationFn: setExploreAsDefault,
    onSuccess: () => {
      NotificationService.success({
        title: 'Set Explore as Default',
        message: 'Your default logging page is now Explore',
        subject: ExplorePageSubjects.ExploreAsDefaultSuccessNotification,
      });
    },
    onError: () => {
      NotificationService.unexpectedError('Failed to set Explore as the default logging page');
    },
  });

  const handleExploreAsDefault = () => {
    goToState('dashboard.explore');

    if (!onboardingState?.exploreAsDefault) {
      updateExploreToDefault();
    }

    analyticsService.capture('General settings', 'Set Explore as default');
  };

  const handleGoToExplore = () => {
    goToState(exploreRouteNames.explore);
  };

  const handleClose = () => {
    cacheProvider.setPersistent(CACHE_KEY, true);
    refetch();
  };

  const shouldShow = useMemo(() => {
    return !dismissed && exploreAsDefaultFeatureFlagEnabled;
  }, [currentState, exploreAsDefaultFeatureFlagEnabled, dismissed]);

  if (!shouldShow) return null;

  if (currentState?.includes(exploreRouteNames.explore) && !onboardingState?.exploreAsDefault) {
    return (
      <ExploreBanner handleClose={handleClose} handleExploreAsDefault={handleExploreAsDefault} isLoading={isLoading} />
    );
  } else if (url.includes('#/dashboard/osd/discover/') && discoverBannerEnabled) {
    return <BackToExplore handleClose={handleClose} handleClick={handleGoToExplore} />;
  }
};
