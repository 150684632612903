import { Button } from '@logz-ui/styleguide';
import styled from 'styled-components';

export const ButtonWidth = 32;
export const SubmitButton = styled(Button)`
  width: ${ButtonWidth}px;
  height: ${ButtonWidth}px;
  min-width: unset;
  border-radius: 4px;
  background: linear-gradient(95.34deg, #1570ef 30.62%, #ee46bc 79.47%, #fac415 112.72%);
  &:hover:not(:disabled) {
    background: linear-gradient(95.34deg, #1570ef 30.62%, #ee46bc 79.47%, #fac415 112.72%);
    filter: brightness(1.2);
  }

  &:disabled {
    background: linear-gradient(95.34deg, #1570ef 30.62%, #ee46bc 79.47%, #fac415 112.72%);
    opacity: 0.5;
  }
`;
