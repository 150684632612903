/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table';
import { SearchResultRecord } from '../Table/types';
import { DateHeader } from '../headers/components/DateHeader/DateHeader';
import { ACCOUNTS_FIELD_NAME } from '../../constants';
import { searchableAccountsService } from '../../state/searchable-accounts.service';
import { DateCell } from './components/DateCell/DateCell';
import { SourceCell } from './components/SourceCell';
import { StringCell } from './components/StringCell';

export const timestampColumn: ColumnDef<SearchResultRecord> = {
  accessorKey: '_source.@timestamp',
  id: '@timestamp',
  header: DateHeader,
  size: 155,
  enableResizing: false,
  enablePinning: false,
  removable: false,
  cell: ({ cell, row, column }) => (
    <DateCell columnDef={column.columnDef} value={cell.getValue() as string | number} row={row} />
  ),
};

export const accountsColumn: ColumnDef<SearchResultRecord> = {
  accessorKey: '_id',
  id: ACCOUNTS_FIELD_NAME,
  header: 'Account',
  size: 225,
  enableResizing: true,
  enableSorting: false,
  cell: ({ cell, row, column }) => {
    const accounts = searchableAccountsService.stateAsMap.get();

    const [_, accountId] = cell.getValue().toString().split('.account-');
    const accountName = accounts[accountId]?.name;

    return <StringCell columnDef={column.columnDef} value={accountName} row={row} />;
  },
};

export const sourceColumn: ColumnDef<SearchResultRecord> = {
  accessorKey: '_source',
  id: '_source',
  header: 'Source',
  size: 300,
  minSize: 100,
  removable: false,
  enableSorting: false,
  cell: ({ cell, row, column }) => <SourceCell columnDef={column.columnDef} value={cell.getValue()} row={row} />,
};
