import { SecurityIOCFeedsRoutingStates, SecurityRulesStates } from '@logz-pkg/enums';
import { integrationsRouteStates } from 'states/integrations/route-config';
import { newOptimizerRouteNames } from 'states/optimizers/optimizers.routes';

export const routeStateToVerify = [
  'dashboard.alerts-v2019-new',
  'dashboard.alerts-v2019-edit',
  'dashboard.alerts-v2019-duplicate',
  SecurityIOCFeedsRoutingStates.New,
  SecurityIOCFeedsRoutingStates.Edit,
  SecurityRulesStates.Duplicate,
  'dashboard.scheduled-reports-new',
  'dashboard.scheduled-reports-edit',
  'dashboard.triggers.optimizer-wizard',
  SecurityIOCFeedsRoutingStates.New,
  SecurityIOCFeedsRoutingStates.Edit,
  integrationsRouteStates.create,
  newOptimizerRouteNames.edit,
  newOptimizerRouteNames.new,
];
