import React, { useMemo } from 'react';
import { Group, Icon } from '@logz-ui/styleguide';
import { AICopilotSubjects, AIDataSource } from '@logz-pkg/enums';
import { AiDataSourceSelect } from '../DataSourceSelect';
import { SubmitButton } from 'ui/components/AI/Components/Buttons/SumbitButton';
import { useAiHistory } from 'ui/components/AI/state/history/history.hook';
import { useAiDataSource } from 'ui/components/AI/state/data-source/data-source.hook';
import { useAiFetchData } from 'ui/components/AI/state/fetch-data/fetch-data.hook';
import { useAiState } from 'ui/components/AI/state/ai-state.hook';

export const FooterControls: React.FC = () => {
  const { history } = useAiHistory();
  const { dataSource } = useAiDataSource();
  const { handleSubmit, isLoading } = useAiFetchData();
  const { userInput, isSessionExpired } = useAiState();

  const isRCAActivated = useMemo(() => dataSource === 'RCA' && history.length > 0, [dataSource, history]);

  return (
    <Group
      justifyContent={isRCAActivated || dataSource === AIDataSource.MetricsDashboard ? 'flex-end' : 'space-between'}
      fullWidth
    >
      {!isRCAActivated && <AiDataSourceSelect isVisible={dataSource !== AIDataSource.MetricsDashboard} />}
      <SubmitButton
        disabled={isLoading || !userInput || isSessionExpired}
        onClick={() => handleSubmit({ prompt: userInput })}
        subject={AICopilotSubjects.DrawerSubmitButton}
        aria-label="Submit AI Input"
      >
        <Icon icon="arrow-up-regular" color="gray.0" />
      </SubmitButton>
    </Group>
  );
};
