import { FunctionComponent, useEffect } from 'react';
import { useBoolean } from '@logz-pkg/react-hooks';
import { OfflineModal } from './OfflineModal';

export const OnlineChecker: FunctionComponent = () => {
  const { value: isOfflineModalOpen, setTrue: openOfflineModal, setFalse: closeOfflineModal } = useBoolean(false);

  const checkConnection = () => {
    if (!window.navigator.onLine) {
      openOfflineModal();

      return;
    }

    isOfflineModalOpen && closeOfflineModal();
  };

  useEffect(() => {
    const checkConnectionInterval = setInterval(checkConnection, 3000);

    return () => {
      clearInterval(checkConnectionInterval);
    };
  }, [isOfflineModalOpen]);

  return <OfflineModal open={isOfflineModalOpen} onClose={closeOfflineModal} />;
};
