import { Origin } from '@logz-pkg/enums';
import { LoggerService } from '@logz-pkg/frontend-services';
import { throwAnErrorMock } from './error.hook.mock';

const getError = (error, name) => {
  if (!error) return false;

  if (typeof error === 'string') return new Error(error);

  if (error instanceof Error) {
    if (error.name && error.name !== '') return error;

    try {
      error.name = name;
    } catch (e) {}

    return error;
  }

  const newError = new Error(error.message || 'Unknown error message');

  if (error.stack) {
    newError.stack = error.stack;
  }

  newError.name = name;

  return newError;
};

/* ******************************************** */
/* Logz.io UI Error Hooks (not kibana iframe)   */
/* ******************************************** */

const createWindowOnError =
  (origin = Origin.APP) =>
  (msg, file, line, col, error) => {
    let message = msg;

    if (error && error.message !== undefined) {
      message += `\nmessage: ${error.message}`;
      error = getError(error, 'windowOnError');
    }

    LoggerService.logError({ origin, message: `Uncaught error on ${origin} App: ${message}`, error });

    return false; // DANGER - some browsers will propagate the error. Chrome - 'false' will display the error
  };

const kibanaWindowOnError = createWindowOnError(Origin.KIBANA);
const kibanaAngularOnError = getAngularErrorHook(Origin.KIBANA);

const windowOnUnhandledRejection = unhandledRejection => {
  const { reason } = unhandledRejection;

  if (!reason) {
    LoggerService.logError({ message: `Unhandled promise without reason`, error: unhandledRejection });
  } else if (reason.stack) {
    const error = getError(reason, 'onUnhandledRejection');

    LoggerService.logError({ message: `Unhandled promise rejection: ${error.message}`, error });
  } else if (reason.config) {
    LoggerService.logError({ error: reason, message: 'Unhandled request rejection', isFailedRequest: true });
  }
};

const __throwAnErrorMock__ = throwAnErrorMock;

export const windowErrorHandler = {
  createWindowOnError,
  kibanaWindowOnError,
  kibanaAngularOnError,
  windowOnUnhandledRejection,
  __throwAnErrorMock__,
};

const tryParseAngularMessage = exception => {
  if (typeof exception !== 'string') return null;

  try {
    const [extracted] = /({.*})/.exec(exception);

    return JSON.parse(extracted);
  } catch (e) {
    return null;
  }
};

export function getAngularErrorHook(origin = Origin.APP) {
  return function AngularErrorHook() {
    return async (exception, cause) => {
      let msg = 'Angular $exceptionHandler';

      if (cause) {
        msg += `:\ncause: ${cause}`;
      }

      const parsedException = tryParseAngularMessage(exception);

      if (parsedException && parsedException.config) {
        LoggerService.logError({
          error: parsedException,
          message: msg,
          isFailedRequest: true,
        });
      } else if (exception && exception.stack) {
        const error = getError(exception, 'angularException');

        msg += `:\nerror: ${error.message}`;

        LoggerService.logError({ origin, message: msg, error });
      } else {
        LoggerService.logError({ origin, message: msg, error: exception });
      }
    };
  };
}

export const AngularErrorHook = getAngularErrorHook();

export const WindowErrorHandler = [
  function WindowErrorHandler() {
    return windowErrorHandler;
  },
];

export const registerWindowErrorHandlers = () => {
  window.onerror = windowErrorHandler.createWindowOnError();
  window.onunhandledrejection = windowErrorHandler.windowOnUnhandledRejection;
  window.throwAnErrorMock = windowErrorHandler.__throwAnErrorMock__;
};

export const AngularRunWindowErrorHook = [
  function WindowErrorHook() {
    registerWindowErrorHandlers();
  },
];
