import { LoggerService } from '@logz-pkg/frontend-services';
import { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { debounce } from 'lodash';
import { opfsService } from 'services/web-workers/opfs/opfs.service';

const MUTATIONS_KEY = 'mutations';
const PERSIST_DEBOUNCE_MS = 300;

const persistClient = debounce(async (client: PersistedClient, dbName: string): Promise<void> => {
  try {
    const allData = await opfsService.getAllEntries(dbName);

    if (allData && Object.keys(allData).length > 0) {
      const activeQueryHashes = new Set(client.clientState.queries.map(query => query.queryHash));
      const staleKeys = Object.keys(allData).filter(key => key !== MUTATIONS_KEY && !activeQueryHashes.has(key));

      if (staleKeys.length > 0) {
        await Promise.all(staleKeys.map(key => opfsService.delete(dbName, key)));
        LoggerService.logInfo({
          message: 'Removed stale query entries from OPFS',
          extra: { dbName, removedCount: staleKeys.length },
        });
      }
    }

    await opfsService.write(dbName, MUTATIONS_KEY, client.clientState.mutations);

    const writePromises = client.clientState.queries.map(query => opfsService.write(dbName, query.queryHash, query));

    await Promise.all(writePromises);
  } catch (error) {
    LoggerService.logError({
      message: 'Failed to persist client state to OPFS',
      error,
      extra: { dbName },
    });
  }
}, PERSIST_DEBOUNCE_MS);

const restoreClient = async (dbName: string): Promise<PersistedClient | null> => {
  try {
    const allData = await opfsService.getAllEntries(dbName);

    if (!allData || Object.keys(allData).length === 0) {
      return null;
    }

    const queriesData = Object.entries(allData)
      .filter(([key]) => key !== MUTATIONS_KEY)
      .map(([_, value]) => value);

    return {
      buster: '',
      timestamp: Date.now(),
      clientState: {
        mutations: allData[MUTATIONS_KEY] || [],
        queries: queriesData,
      },
    };
  } catch (error) {
    LoggerService.logError({
      message: 'Failed to restore client state from OPFS',
      error,
      extra: { dbName },
    });

    return null;
  }
};

const removeClient = async (dbName: string): Promise<void> => {
  try {
    await opfsService.cleanupDb(dbName);
  } catch (error) {
    LoggerService.logError({
      message: 'Failed to remove client state from OPFS',
      error,
      extra: { dbName },
    });
  }
};

export const createOPFSPersister = (dbName: string): Persister => ({
  persistClient: client => persistClient(client, dbName),
  restoreClient: () => restoreClient(dbName),
  removeClient: () => removeClient(dbName),
});
