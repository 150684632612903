import { LoggerService } from '@logz-pkg/frontend-services';
import { IWorkerMessage, IWorkerResponse } from './opfs.types';

class OPFSService {
  private worker: Worker | null = null;
  private currentAccountId: number | null = null;
  private initPromise: Promise<void> | null = null;

  setOPFSWorker = (worker: Worker): void => {
    this.worker = worker;
  };

  getCurrentAccountId = (): number | null => {
    return this.currentAccountId;
  };

  private sendWorkerMessage = async <T = unknown>(message: IWorkerMessage): Promise<IWorkerResponse<T>> => {
    if (!this.worker) {
      throw new Error('OPFS worker not initialized');
    }

    return new Promise(resolve => {
      const messageHandler = (event: MessageEvent<IWorkerResponse<T>>) => {
        this.worker?.removeEventListener('message', messageHandler);
        resolve(event.data);
      };

      this.worker.addEventListener('message', messageHandler);
      this.worker.postMessage(message);
    });
  };

  initialize = async (accountId: number): Promise<void> => {
    if (this.initPromise && this.currentAccountId === accountId) {
      return this.initPromise;
    }

    this.initPromise = (async () => {
      try {
        const response = await this.sendWorkerMessage({
          type: 'initialize',
          accountId,
        });

        if ('error' in response) {
          throw new Error(response.error);
        }

        this.currentAccountId = accountId;
        LoggerService.logInfo({
          message: 'OPFS initialized for account',
          extra: { accountId },
        });
      } catch (error) {
        LoggerService.logError({
          message: 'OPFS initialization failed',
          error,
          extra: { accountId },
        });
        throw error;
      }
    })();

    return this.initPromise;
  };

  getAllEntries = async (dbName: string): Promise<Record<string, any>> => {
    if (!this.currentAccountId) {
      LoggerService.logWarn({
        message: 'Attempted to get entries without active account',
        extra: { dbName },
      });

      return {};
    }

    const response = await this.sendWorkerMessage<Record<string, any>>({
      type: 'readAll',
      dbName,
    });

    if ('error' in response) {
      throw new Error(response.error);
    }

    return response.data;
  };

  write = async (dbName: string, queryKey: string, data: unknown): Promise<void> => {
    if (!this.currentAccountId) {
      LoggerService.logWarn({
        message: 'Attempted to write without active account',
        extra: { dbName, queryKey },
      });

      return;
    }

    const response = await this.sendWorkerMessage({
      type: 'write',
      dbName,
      queryKey,
      data,
    });

    if ('error' in response) {
      throw new Error(response.error);
    }
  };

  delete = async (dbName: string, queryKey: string): Promise<void> => {
    if (!this.currentAccountId) {
      LoggerService.logWarn({
        message: 'Attempted to delete without active account',
        extra: { dbName, queryKey },
      });

      return;
    }

    const response = await this.sendWorkerMessage({
      type: 'delete',
      dbName,
      queryKey,
    });

    if ('error' in response) {
      throw new Error(response.error);
    }
  };

  cleanupDb = async (dbName: string): Promise<void> => {
    if (!this.currentAccountId) {
      LoggerService.logWarn({
        message: 'Attempted to cleanup DB without active account',
        extra: { dbName },
      });

      return;
    }

    const response = await this.sendWorkerMessage({
      type: 'cleanup',
      dbName,
    });

    if ('error' in response) {
      throw new Error(response.error);
    }
  };

  cleanup = async (): Promise<void> => {
    this.initPromise = null;

    if (!this.currentAccountId) {
      return;
    }

    const accountBeingCleaned = this.currentAccountId;

    this.currentAccountId = null;

    try {
      const response = await this.sendWorkerMessage({
        type: 'cleanup',
      });

      if ('error' in response) {
        throw new Error(response.error);
      }

      LoggerService.logInfo({
        message: 'OPFS cleaned up for account',
        extra: { accountId: accountBeingCleaned },
      });
    } catch (error) {
      LoggerService.logError({
        message: 'OPFS cleanup failed',
        error,
        extra: { accountId: accountBeingCleaned },
      });
      throw error;
    }
  };
}

export const opfsService = new OPFSService();
