import { ExplorePageDensityModes } from '@logz-pkg/enums';
import { colorsV2 } from '@logz-ui/styleguide';
import { SpecialGraphValues } from './enums';
import { HelpQuestionKeyType, HelpQuestionsCardInformationType, HelpQuestionsChatType } from 'ui/components/AI/types';

export const specialFields = [/^message$/, /^@timestamp$/];
export const ignoredFields = [/^_logzio(?!_logceptions)/, /^__logzio/];

export const DEFAULT_TABLE_COLUMNS = [];
export const DEFAULT_COLUMN_SIZE = 150;
export const DEFAULT_SORT = ['-@timestamp'];

export const SOURCE_FIELD_NAME = '_source';
export const EXCEPTIONS_FIELD_NAME = '_logzio_logceptions';
export const INSIGHTS_FIELD_NAME = '_logzio_insights';

export const GROUPBY_FIELD_NAME = '_logzio_group_by_column';
export const ACCOUNTS_FIELD_NAME = '_logzio_accounts_column';

export const customFieldNames = {
  [EXCEPTIONS_FIELD_NAME]: 'exceptions',
};

export const preDefinedGraphColors = {
  [SpecialGraphValues.MISSING]: colorsV2.violet[400],
  [SpecialGraphValues.OTHER]: colorsV2.orange[900],
  error: colorsV2.error[600],
  warn: colorsV2.regularWarning[300],
  info: colorsV2.darkBlue[300],
  success: colorsV2.success[500],
};

export const graphColors = [
  colorsV2.blue[500],
  colorsV2.violet[700],
  colorsV2.pink[600],
  colorsV2.teal[900],
  colorsV2.orange[700],
  colorsV2.green[700],
  colorsV2.pink[800],
  colorsV2.darkBlue[800],
  colorsV2.yellow[700],
  colorsV2.teal[600],
  colorsV2.indigo[900],
];

export const MIN_ALLOWED_COLUMNS = 2;

export const densityModesSizeMap: Record<ExplorePageDensityModes, number> = {
  OneLine: 16,
  TwoLines: 32,
  Expanded: 16,
};

export const REQUEST_MAX_TOKENS = 200000;
export const CHAT_MAX_MESSAGES = 100;
export const CONTEXT_MAX_TOKENS = 25000;
export const CONTEXT_MAX_LOGS = 100;
export const CONTEXT_TOKEN_INCREMENT = 5000;
export const CONTEXT_SET_DEBOUNCE = 600;
export const MESSAGE_FIELD_LENGTH = 400;

export const additionalPromptInfo = `The following prompt will include data in the form of logs, filters the user has chosen, and graph data to assist in data analysis. The logs contain information from the user's system, such as error messages, user actions, or system events. Filters represent criteria that user has applied to narrow down the data, including search queries or specific field values. If the user has chosen to group data in the graph, it will be presented based on the selected groupBy field, showing timestamps and the count of occurrences for each category.`;

export const helpQuestionsTitleMap: { [key in HelpQuestionKeyType]: string } = {
  help: 'Need help? try these',
  start: 'Start here',
  dynamic: 'Dynamic questions',
  trends: 'Identify trends',
  compare: 'Compare data',
};

export const dynamicQuestions = {
  iconName: 'sparkle-solid',
  color: colorsV2.yellow[600],
} as Omit<HelpQuestionsCardInformationType, 'text'>;

export const exploreHelpQuestions: HelpQuestionsChatType = {
  assistant: {
    start: [
      {
        text: 'Pinpoint top log issues and suggest fixes',
        iconName: 'magnifying-glass-regular',
        color: colorsV2.green[600],
      },
      {
        text: 'Find root causes of critical errors in the logs',
        iconName: 'magnifying-glass-regular',
        color: colorsV2.green[600],
      },
    ],
  },
  pro: {
    trends: [
      {
        text: 'Services’ error in the last 24 hours',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
      {
        text: 'Error trend per component over the last 2 days',
        iconName: 'arrow-trend-up-regular',
        color: colorsV2.blue[600],
      },
    ],
    compare: [
      {
        text: 'Prod vs. staging error rate over the past 7 days',
        iconName: 'scale-unbalanced-regular',
        color: colorsV2.pink[600],
      },
      {
        text: 'Log count comparison: yesterday vs. today',
        iconName: 'scale-unbalanced-regular',
        color: colorsV2.pink[600],
      },
    ],
  },
};
export const SHORT_QUERY_DEBOUNCE_TIME = 150;
export const LONG_QUERY_DEBOUNCE_TIME = 1500;
export const SURROUNDING_INITIAL_FETCH_AMOUNT = 25;

export const LOGZIO_HIGHLIGHT_TAG = '__logzio-highlight__';
export const DEPLOYMENT_MARKERS_QUERY_KEY = 'explore-deployment-markers';
