import { Observable } from '@logz-pkg/observable';
import { SearchHit } from '@opensearch-project/opensearch/api/types';
import { ElasticsearchFieldType, Origin } from '@logz-pkg/enums';
import { LoggerService, opensearchApiService, searchedIndexesService } from '@logz-pkg/frontend-services';
import { ElasticsearchEnhancedFilterModel, ElasticsearchFilterOperator } from '@logz-pkg/models';
import { plainToClass } from 'class-transformer';
import { intersection } from 'lodash';
import { NotificationService } from '@logz-ui/styleguide';
import { DeploymentMarkerEvent } from '../types';
import { queryService } from './query/query.service';
import { queryUtils } from './query/query.utils';

class ExploreGraphEventsService {
  allEvents = new Observable<SearchHit<DeploymentMarkerEvent>[]>([]);
  selectedEventNames = new Observable<string[]>([]);
  showAllEvents = new Observable<boolean>(true);

  isLoading = new Observable<boolean>(false);

  fetchEvents = async () => {
    const { accounts, from, to } = queryService.getQueryState();

    const graphMarkersFilter = plainToClass(ElasticsearchEnhancedFilterModel, {
      field: { name: '__logzio_marker_title', type: ElasticsearchFieldType.String },
      type: ElasticsearchFilterOperator.Exists,
    });

    const dslFilters = queryUtils.getDslFilters([graphMarkersFilter], queryUtils.formatTimeRange(from, to));
    const queryMarkers = { n: 'graph-markers', source: 'explore' as const };
    let isAborted = false;

    try {
      this.isLoading.set(true);

      const response = await opensearchApiService.searchLogs<DeploymentMarkerEvent>(
        {
          size: 500,
          source: { excludes: searchedIndexesService.getAccountsWithPrefix(accounts) },
          trackTotalHits: false,
          query: dslFilters,
        },
        queryMarkers,
        { abortKey: `${queryMarkers.n}-${queryMarkers.source}` },
      );

      const hits = response.hits.hits ?? [];

      this.allEvents.set(hits);

      const currentDeploymentNames = hits.map(deployment => deployment._source.__logzio_marker_title);
      const selectedDeploymentNames = this.selectedEventNames.get();
      const intersectedSelectedEvents = intersection(currentDeploymentNames, selectedDeploymentNames);

      this.selectedEventNames.set(intersectedSelectedEvents);
    } catch (error) {
      if (error?.config?.signal?.aborted) {
        isAborted = true;

        return;
      }

      LoggerService.logError({ origin: Origin.APP, message: 'Error fetching logs', error });

      NotificationService.danger({
        title: 'Error fetching logs',
        message: 'Please try again, or contact support',
        duration: 3,
      });
    } finally {
      if (!isAborted) {
        this.isLoading.set(false);
      }
    }
  };

  clear = () => {
    this.allEvents.set([]);
    this.selectedEventNames.set([]);
  };
}

export const exploreGraphEventsService = new ExploreGraphEventsService();
