import styled, { css } from 'styled-components';
import { colors } from '@logz-ui/styleguide';
import { highlightColor } from '../config';

interface IMainNavigationItemProps {
  isMinified: boolean;
  highlighted: boolean;
  notificationBadge?: boolean;
  ref: any;
}

const NavigationItemBadgePosition = {
  Base: { top: '-1px', left: 'calc(50% + 4px)' },
  Minimized: { top: '-3px', left: 'calc(50% - 1px)' },
};

const NavigationItemSize = {
  Base: { height: 48, width: 64 },
  Minimized: { height: 24, width: 24 },
};
export const MainNavigationItem = styled.div<IMainNavigationItemProps>`
  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
  cursor: pointer;

  ${({ highlighted }) => highlighted && `background-color: ${highlightColor};`}
  ${({ isMinified }) => {
    const sizePropName = isMinified ? 'Minimized' : 'Base';
    const borderRadius = isMinified ? '4px' : '0px';

    return css`
      width: ${NavigationItemSize[sizePropName].width}px;
      height: ${NavigationItemSize[sizePropName].height}px;
      border-radius: ${borderRadius};
    `;
  }}
  &:hover {
    background-color: ${highlightColor};
  }

  ${({ notificationBadge, isMinified }) => {
    if (!notificationBadge) return null;

    const sizePropName = isMinified ? 'Minimized' : 'Base';

    return css`
      &::before {
        content: '';
        background-color: ${colors.green[500]};
        border: 2px solid ${colors.blue[900]};
        width: 10px;
        height: 10px;
        position: absolute;
        z-index: 2;
        border-radius: 50%;
        box-sizing: content-box;
        top: ${NavigationItemBadgePosition[sizePropName].top};
        left: ${NavigationItemBadgePosition[sizePropName].left};
      }
    `;
  }}
`;
