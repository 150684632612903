import { ISearchRequestObject } from '@logz-build/typescript';
import { HeaderSubjects } from '@logz-pkg/enums';
import { announcementApiService } from '@logz-pkg/frontend-services';
import { AnnouncementModel } from '@logz-pkg/models';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { FlexColumn, NotificationService, colors } from '@logz-ui/styleguide';
import { isNil } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { WhatsNewAnnouncementsList as AnnouncementsList } from './AnnouncementsList';
import { WhatsNewHeader as Header } from './Header';
import { CloseButton } from 'ui/components/shared/ClosedButton/CloseButton';
import { useWhatsNewState } from 'ui/state/hooks';

const Container = styled(FlexColumn)`
  position: relative;
  flex-grow: 1;
  background-color: ${colors.gray[200]};
`;

interface IWhatsNewProps {
  open?: boolean;
}

export const WhatsNew: FunctionComponent<IWhatsNewProps> = ({ open }) => {
  const PAGE_SIZE = 10;
  const { toggleWhatsNew } = useWhatsNewState();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>();
  // We hold it in another state since we want to aggregate the announcements rather then switch content
  const [announcements, setAnnouncements] = useState<AnnouncementModel[]>([]);
  const {
    action: search,
    isPending: isSearching,
    data: searchResponse,
  } = useAsyncAction(announcementApiService.search, {
    initialData: {
      total: 0,
      results: [],
    },
    onFailure: error => {
      const title = 'Could not load announcements';

      if (error?.message) {
        return NotificationService.danger({ title, message: error.message });
      }

      return NotificationService.unexpectedError(title);
    },
  });

  useEffect(() => {
    setAnnouncements(previousAnnouncements => [...previousAnnouncements, ...(searchResponse?.results ?? [])]);
  }, [searchResponse?.results]);

  const searchAnnouncements = ({ term = searchTerm, page = pageNumber } = {}) => {
    if (!open || isNil(pageNumber)) return;

    const requestObject: ISearchRequestObject = { pagination: { pageNumber: page, pageSize: PAGE_SIZE } };

    if (term) {
      requestObject.filter = { searchTerm: term };
    }

    search(requestObject);
  };

  useEffect(() => {
    if (open) {
      searchAnnouncements();
    }
  }, [open]);

  const handleInputSearch = value => {
    setPageNumber(1);
    setSearchTerm(value);
    // Reset the announcements to not stack the results on top of the existing ones
    setAnnouncements([]);
    searchAnnouncements({ term: value, page: 1 });
  };

  const handleLoadMore = () => {
    searchAnnouncements({ page: pageNumber + 1 });
    setPageNumber(prev => prev + 1);
  };

  const hasMore = searchResponse?.total > pageNumber * PAGE_SIZE;

  const handleClose = async () => {
    await toggleWhatsNew();
  };

  return (
    <Container subject={HeaderSubjects.WhatsNewContainer}>
      <CloseButton subject={HeaderSubjects.CloseWhatsNew} onClick={handleClose} />
      <Header onSearch={handleInputSearch} />
      <AnnouncementsList
        announcements={announcements}
        onLoadMore={handleLoadMore}
        hasMore={hasMore}
        isLoadingFirstPage={isSearching && pageNumber === 1}
        isLoadingMore={isSearching && pageNumber > 1}
      />
    </Container>
  );
};
