import { Group, Icon, Text } from '@logz-ui/styleguide';
import React from 'react';
import { MessageType } from 'ui/components/AI/Components/Messages/MessageManager';

export const AiChatAgentLoadingBarStatus: React.FC<MessageType> = ({ content }) => (
  <Group flexDirection={'column'} pt={2} px={8} fullWidth>
    <Group alignItems={'center'} mb={3}>
      <Group width={'20px'} justifyContent={'center'} alignItems={'center'}>
        <Icon icon={'sparkle-solid'} color={'royalBlue.1000'} size={16} />
      </Group>
      <Text weight={600} color={'royalBlue.1000'}>
        {content}
      </Text>
    </Group>
  </Group>
);
