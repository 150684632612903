import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-debounce-hook';
import { Flex } from '@logz-ui/styleguide';
import { Observable, useObservable } from '@logz-pkg/observable';
import { ExpandedSidenav } from './Expanded';
import { ISidenavItemConfig } from './item-configs';
import { FixedSidenav } from './Fixed/Fixed';
import { intervalTimesInMilliseconds } from './config';
import { useNavigationState } from 'ui/state/hooks';
import { useCurrentProduct } from 'ui/state/hooks/current-product.hook';
import { navigationStateService } from 'ui/state/navigation.state.service';

const isHoveringOnMenuObservable = new Observable(false);

const onNavMouseIn = () => {
  isHoveringOnMenuObservable.set(true);
};

const handleMouseLeave = () => {
  isHoveringOnMenuObservable.set(false);
};

const closeExpandedMenu = () => {
  navigationStateService.isSidenavOpen.set(false);
  navigationStateService.hoveredNavItem.set(null);
};

export const Sidenav = () => {
  const isHoveringOnMenu = useObservable(isHoveringOnMenuObservable);
  const [isHoveringOnSpacer, setIsHoveringOnSpacer] = useState<boolean>(false);
  const [hoverSelectionTimer, setHoverSelectionTimer] = useState<NodeJS.Timeout | null>();
  const { isSidenavOpen, hoveredNavItem, isMinified, setSidenavOpen, setHoveredNavItem } = useNavigationState();
  const { currentProduct } = useCurrentProduct();

  const onNavigationItemHover = useCallback((nextSelectedNavItem: ISidenavItemConfig) => {
    if (!navigationStateService.isSidenavOpen.get()) {
      setSidenavOpen(true);
      setHoveredNavItem(nextSelectedNavItem);
    }

    clearTimeout(hoverSelectionTimer as NodeJS.Timeout);

    // Keep a constant timer when scrubbing through items
    setHoverSelectionTimer(
      setTimeout(() => {
        if (isHoveringOnMenuObservable.get() || navigationStateService.isSidenavOpen.get()) {
          setHoveredNavItem(nextSelectedNavItem);
          setHoverSelectionTimer(null);
        } else {
          setHoveredNavItem(null);
        }
      }, intervalTimesInMilliseconds.hoverSelection),
    );
  }, []);

  // Reset our timer if the user leaves another item too fast
  const onNavigationItemLeave = useCallback(() => {
    clearTimeout(hoverSelectionTimer as NodeJS.Timeout);
  }, [hoverSelectionTimer]);

  useDebounce(
    isHoveringOnMenu,
    () => {
      // If the user returns to the menu before our debounce is finished, keep the menu open
      if (isHoveringOnMenuObservable.get()) {
        return;
      }

      closeExpandedMenu();
    },

    intervalTimesInMilliseconds.closeExpandedSidenav,
  );

  useDebounce(
    isHoveringOnSpacer,
    () => {
      // If the user returns to the menu before our debounce is finished, keep the menu open
      if (!isHoveringOnSpacer) {
        return;
      }

      closeExpandedMenu();
    },

    intervalTimesInMilliseconds.hoverSelection,
  );

  useEffect(() => {
    if (hoveredNavItem) {
      setSidenavOpen(true);
    }
  }, [hoveredNavItem]);

  return (
    <Flex onMouseEnter={onNavMouseIn} onMouseLeave={handleMouseLeave}>
      <FixedSidenav
        onNavItemHover={onNavigationItemHover}
        onNavItemLeave={onNavigationItemLeave}
        currentProduct={currentProduct}
        isHoveringOnMenu={isHoveringOnMenu}
        isMinified={isMinified}
        onSpacerHoverChange={setIsHoveringOnSpacer}
      />
      <ExpandedSidenav
        open={isSidenavOpen}
        hoveredMainNavItem={hoveredNavItem as ISidenavItemConfig}
        currentProduct={currentProduct}
      />
    </Flex>
  );
};
