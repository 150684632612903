import { FieldArray, FastField, Field } from 'formik';
import { Input, Checkbox, ButtonGroup, Link, Icon, Text, Flex, Group, Button, Info } from '@logz-ui/styleguide';
import { get } from 'lodash';
import { OperatingSystem, FilebeatConfWizSubjects } from '@logz-pkg/enums';
import { LogTypeSelect } from '../shared/Osd/LogTypeSelect/LogTypeSelect';
import { osUrls, logTypeTooltips, formatOptions } from './form-config';
import { FieldCondition } from './FieldCondition';

const placeholderFromOS = {
  [OperatingSystem.Linux]: osUrls.linux,
  [OperatingSystem.Windows]: osUrls.windows,
};

const getLogTypeError = (form, logTypeInd, propName) =>
  get(form, `touched.logTypeArr[${logTypeInd}].${propName}`) &&
  get(form, `errors.logTypeArr[${logTypeInd}].${propName}`);

const getAdditionalFieldErr = (fieldConditionsArrHelpers, logTypeInd, index, field) =>
  get(fieldConditionsArrHelpers, `form.touched.logTypeArr[${logTypeInd}].fieldConditions[${index}.${field}]`) &&
  get(fieldConditionsArrHelpers, `form.errors.logTypeArr[${logTypeInd}].fieldConditions[${index}].${field}]`);

export const LogTypeItem = ({ showRemoveBtn, logType, logTypeInd, logTypeArrHelpers, logTypes, isLoadingLogTypes }) => (
  <div>
    {showRemoveBtn && (
      <Flex justifyContent="flex-end">
        <Button
          variant={'tertiary'}
          round
          subject={FilebeatConfWizSubjects.RemoveLogTypeSection}
          icon={'xmark-regular'}
          onClick={() => {
            logTypeArrHelpers.remove(logTypeInd);
          }}
        />
      </Flex>
    )}
    <Group mb={1}>
      <Text>Path</Text>
      <Info placement={'right'} tooltip={logTypeTooltips.path} />
    </Group>
    <Flex>
      <Field
        name={`logTypeArr.${logTypeInd}.path`}
        render={({ field, form }) => (
          <Input
            subject={`logTypeArr.${logTypeInd}.path`}
            error={getLogTypeError(form, logTypeInd, 'path')}
            value={logType.path}
            name={field.name}
            placeholder={placeholderFromOS[form.values.os]}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
          />
        )}
      />
    </Flex>
    <Group mb={1}>
      <Text>Type</Text>
      <Info placement={'right'} tooltip={logTypeTooltips.type} />
    </Group>
    <Flex flexDirection={'row'} flexWrap={'nowrap'} mb={1}>
      <Flex width={0.5} flexWrap={'nowrap'} mb={2}>
        <Field
          name={`logTypeArr.${logTypeInd}.logType`}
          render={({ field, form }) => (
            <LogTypeSelect
              name={field.name}
              placeholder={'Please select log type'}
              error={getLogTypeError(form, logTypeInd, 'logType')}
              onBlur={() => form.setFieldTouched(`logTypeArr[${logTypeInd}].logType`, true)}
              onChange={option => form.setFieldValue(field.name, option && option.value)}
              options={logTypes}
              isLoadingLogTypes={isLoadingLogTypes}
            />
          )}
        />
      </Flex>
      {logType.logType === 'Other' && (
        <>
          <Text size={16} color={'gray.1000'} mb={3} mx={3}>
            =
          </Text>
          <Flex width={0.2}>
            <Field
              name={`logTypeArr.${logTypeInd}.logTypeOther`}
              render={({ field, form }) => (
                <Input
                  subject={`logTypeArr.${logTypeInd}.logTypeOther`}
                  mb={0}
                  error={getLogTypeError(form, logTypeInd, 'logTypeOther')}
                  value={logType.logTypeOther}
                  name={field.name}
                  placeholder="Other..."
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  disabled={form.isSubmitting}
                />
              )}
            />
          </Flex>
        </>
      )}
    </Flex>
    <Group mb={1}>
      <Text>Format</Text>
    </Group>
    <Flex>
      <Field
        name={`logTypeArr.${logTypeInd}.format`}
        render={({ field, form }) => (
          <ButtonGroup
            value={logType.format}
            options={formatOptions}
            onChange={value => {
              form.setFieldValue(field.name, value);
            }}
          />
        )}
      />
    </Flex>
    <Group mt={3} mb={1} flexWrap={'nowrap'} fullWidth justifyContent={'flex-start'} alignItems={'center'}>
      <FastField
        name={`logTypeArr.${logTypeInd}.multiline`}
        render={({ field, form }) => (
          <Checkbox
            name={field.name}
            checked={logType.multiline}
            onChange={value => form.setFieldValue(field.name, value)}
            subject={FilebeatConfWizSubjects.Multiline}
          />
        )}
      />
      <Text>Multiline</Text>
      <Info placement={'right'} tooltip={logTypeTooltips.multiline} />
    </Group>
    <Flex flexDirection={'row'} alignItems={'center'} flexWrap={'nowrap'} justifyContent={'space-between'} mb={1}>
      {logType.multiline && (
        <Field
          name={`logTypeArr.${logTypeInd}.multilineRegex`}
          render={({ field, form }) => (
            <Input
              subject={`logTypeArr.${logTypeInd}.multilineRegex`}
              error={getLogTypeError(form, logTypeInd, 'multilineRegex')}
              value={logType.multilineRegex}
              name={field.name}
              placeholder="Regex pattern eg: ^\[?[0-9][0-9]:?[0-9][0-9]|^[[:graph:]]+"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              disabled={form.isSubmitting}
              my={0}
            />
          )}
        />
      )}
    </Flex>
    {logType.multiline && (
      <Flex mb={3}>
        <Text>
          Filebeat supports a subset of regex.{' '}
          <Link href="https://www.elastic.co/guide/en/beats/filebeat/current/regexp-support.html">
            [See supported regex syntax]
          </Link>
        </Text>
      </Flex>
    )}
    <Group mt={2} mb={1}>
      <Text>Additional fields (optional)</Text>
    </Group>
    <FieldArray
      name={`logTypeArr.${logTypeInd}.fieldConditions`}
      render={fieldConditionsArrHelpers => (
        <>
          {logType.fieldConditions.map((fieldConditions, index) => (
            <Group mb={2} key={index}>
              <FieldCondition
                nameError={getAdditionalFieldErr(fieldConditionsArrHelpers, logTypeInd, index, 'fieldName')}
                valueError={getAdditionalFieldErr(fieldConditionsArrHelpers, logTypeInd, index, 'fieldValue')}
                name={`logTypeArr.${logTypeInd}.fieldConditions.${index}`}
                index={index}
                isRemovable={logType.fieldConditions.length > 1}
                handleRemove={fieldConditionsArrHelpers.remove}
              />
            </Group>
          ))}
          <Link onClick={() => fieldConditionsArrHelpers.push({})} subject={FilebeatConfWizSubjects.AddField}>
            <Group gap={1}>
              <Icon icon={'plus-regular'} />
              <Text>Add a field</Text>
            </Group>
          </Link>
        </>
      )}
    />
  </div>
);
