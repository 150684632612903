import { AppMode, ProductKeys } from '@logz-pkg/enums';
import { ISidenavItemConfig } from './item-configs';
import { ExtendedLogos } from 'ui/components/shared/Logo/Logo';
import { dashboardsHubRouteName } from 'states/dashboards-hub/dashboards-hub.routes';

export const dashboardsHubItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'Dashboards',
  mode: AppMode.OPERATIONS,
  expandedSidenavTitle: 'Dashboards Hub',
  key: ProductKeys.DashboardsHub,
  logo: ExtendedLogos.DashboardsHub,
  targetStateName: dashboardsHubRouteName,
  expandedNavGroups: [],
};
