import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import './nprogress.overrides.less';
import { debounce, get } from 'lodash';
import { subscribeOnPendingRequestsChange } from 'core/http/services/http-interceptors.service';

(function snapshotterBackwardCompatibility() {
  const SNAPSHOT_FLAG_CLASSES = 'loading-progress-bar ng-hide';
  const SNAPSHOT_FLAG_SELECTOR = '.loading-progress-bar.ng-hide';

  function removeElement(element) {
    element && element.parentNode && element.parentNode.removeChild(element);
  }

  NProgress.startOrig = NProgress.start;
  NProgress.start = function customStart() {
    removeElement(document.querySelector(SNAPSHOT_FLAG_SELECTOR));
    NProgress.startOrig();
  };

  NProgress.removeOrig = NProgress.remove;
  NProgress.remove = function customRemove() {
    let snapshotterFlag = document.querySelector(SNAPSHOT_FLAG_SELECTOR);

    if (!snapshotterFlag) {
      snapshotterFlag = document.createElement('div');
      snapshotterFlag.id = 'snapshotter-flag';
      snapshotterFlag.className = SNAPSHOT_FLAG_CLASSES;
      document.documentElement.appendChild(snapshotterFlag);
    }

    NProgress.removeOrig();
  };
})();

const EASE_SPEED = 500;
const TRICKLE_SPEED = 200;
const npConfig = {
  showSpinner: false,
  easing: 'ease',
  speed: EASE_SPEED,
  trickle: true,
  trickleSpeed: TRICKLE_SPEED,
};

const debouncedAction = debounce(start => (start ? NProgress.start() : NProgress.done()), 500);
const start = () => debouncedAction(true);
const done = () => debouncedAction(false);

NProgress.configure(npConfig);

let isFirstProgressBarredRequest = true;

export function updateProgress({ config, increase, pendingRequestsCount }) {
  const countAbs = pendingRequestsCount > 0 ? pendingRequestsCount : 0;

  if (countAbs === 0) {
    isFirstProgressBarredRequest = true;

    return done();
  }

  const dontShowProgressBar = get(config, 'meta.dontShowProgressBar');

  if (dontShowProgressBar) return;

  if (increase) {
    if (isFirstProgressBarredRequest) {
      isFirstProgressBarredRequest = false;

      return start();
    }

    npConfig.trickleSpeed = TRICKLE_SPEED * countAbs;
    NProgress.configure(npConfig);
  } else {
    NProgress.inc();
  }
}

export function bootstrapProgressBar() {
  subscribeOnPendingRequestsChange(updateProgress);
}
