import { to } from 'await-to-js';
import { NotificationService } from '@logz-ui/styleguide/components';
import { configProvider, LoggerService } from '@logz-pkg/frontend-services';
import { LivetailStateService } from '../dashboard/livetail/livetail-state.service';
import { LazyAngular } from './LazyAngular';
import { AppStateDeclaration } from './AppStateDecleration';

export const liveTailRoute: AppStateDeclaration = {
  name: 'dashboard.livetail',
  url: '/live-tail',
  component: ({ livetailConfig, livetailFiltersState }) => {
    const getComponent = async () => {
      const [controller, template] = await Promise.all([
        import('../dashboard/livetail/livetail-controller'),
        import('../dashboard/livetail/livetail.html?raw'),
      ]);

      const component = {
        template: template.default,
        controller: controller.default,
        bindings: {
          livetailConfig: '<',
          livetailFiltersState: '<',
        },
      };

      return component;
    };

    return (
      <LazyAngular
        name={'angularLiveTail'}
        componentGetter={getComponent}
        bindings={{ livetailConfig, livetailFiltersState }}
      />
    );
  },
  resolve: [
    {
      token: 'livetailFiltersState',
      resolveFn: async () => {
        return LivetailStateService.getLivetailFiltersState();
      },
    },
    {
      token: 'livetailConfig',
      resolveFn: async () => {
        const [error, livetailConfig] = await to(configProvider.getValue('livetail'));

        if (error) {
          const message = 'Error loading live tail configuration';

          NotificationService.danger({ message });
          LoggerService.logError({ message, error });

          return;
        }

        return livetailConfig;
      },
    },
  ],
  data: { authenticate: true, title: 'Live tail', breadcrumb: { label: 'Live Tail' } },
};
