import { useObservable } from '@logz-pkg/observable';
import { integrationsHubStateService } from '../integrations-hub.state.service';

export const useIntegrationsHub = () => {
  return {
    logsAccounts: useObservable(integrationsHubStateService.logsAccountsDetails),
    metricsAccounts: useObservable(integrationsHubStateService.metricsAccountsDetails),
    tracingAccount: useObservable(integrationsHubStateService.tracingAccountsDetails),
    selectedLogAnalyticAccount: useObservable(integrationsHubStateService.selectedLogAnalyticsAccountDetails),
    selectedMetricAccount: useObservable(integrationsHubStateService.selectedMetricAccountDetails),
    selectedTracingAccount: useObservable(integrationsHubStateService.selectedTracingAccountDetails),
    isFetchingAccountsData: useObservable(integrationsHubStateService.isFetchingAccountsData),

    fetchAndSetAccountsDetails: integrationsHubStateService.fetchAndSetAccountsDetails,
    setSelectedLogAnalyticAccount: integrationsHubStateService.setSelectedLogAnalyticAccounts,
    setSelectedMetricAccount: integrationsHubStateService.setSelectedMetricAccounts,
    setSelectedTracingAccount: integrationsHubStateService.setSelectedTracingAccounts,
    setSelectedAccount: integrationsHubStateService.setSelectedAccount,

    selectedGlobalTab: useObservable(integrationsHubStateService.selectedGlobalTab),
    setSelectedGlobalTab: integrationsHubStateService.setSelectedGlobalTab,
  };
};
