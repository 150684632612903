import { ConfirmModal, NotificationService, Textarea } from '@logz-ui/styleguide';
import React from 'react';
import { useAiFeedback } from '../../state/feedback/feedback.hook';
import { useAiAnalytics } from '../../hooks/analytics.hook';

export const AiFeedbackOtherModal: React.FC<{
  shouldOpen: boolean;
  setShouldOpen: (sholdOpen: boolean) => void;
}> = ({ shouldOpen, setShouldOpen }) => {
  const [otherReason, setOtherReason] = React.useState('');
  const { rate, setShouldShowFeedback } = useAiFeedback();
  const { report } = useAiAnalytics();

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(value);
  };

  return (
    <ConfirmModal
      isOpen={shouldOpen}
      size={'s'}
      title={`Share your feedback`}
      onClose={() => {
        setShouldOpen(false);
      }}
      onConfirm={() => {
        setShouldOpen(false);
        setShouldShowFeedback(false);
        report('Sent feedback reasons', { score: rate, reason: 'Share your feedback', otherReason });

        NotificationService.success({
          title: 'Thank you for your feedback',
          message: 'Your input helps us improve continuously. ',
        });
      }}
      confirmText="Share"
      cancelText="Cancel"
    >
      <Textarea onChange={handleChange} placeholder={'Write here'} value={otherReason} />
    </ConfirmModal>
  );
};
