import { AIDataSource, HeaderSubjects } from '@logz-pkg/enums';
import { Box, Flex, Group, Header } from '@logz-ui/styleguide';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useCurrentStateAndParams, useOnStateChanged } from '@uirouter/react';
import { SelectAccountButton } from './AccountSelector/SelectButton';
import { HeaderBreadcrumbs } from './HeaderBreadcrumbs';
import { HelpMenu } from './HelpMenu';
import { WhatsNew } from './WhatsNew';
import { ExploreAsDefaultCallout } from './ExploreAsDefaultCallout';
import { OpenChat } from './OpenChat';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';
import { GuidedWalkthroughBadge } from 'ui/components/GuidedWalkthrough/Components/WalkthroughHeaderBadge';
import { useAiState } from 'ui/components/AI/state/ai-state.hook';
import { grafanaStateName, metricsRouteName } from 'states/grafana/grafana.route';
import { useFeatureFlagState } from 'ui/state/hooks';
import { useAiDataSource } from 'ui/components/AI/state/data-source/data-source.hook';

export const NavigationHeader: FunctionComponent = () => {
  const { isFeatureEnabled: isAiMetricsDashboardEnabled } = useFeatureFlagState('aiMetricsDashboard');
  const { isActivated: isGuidedWalkthroughActivated } = useGuidedWalkthrough();
  const { setContext, toggleDrawer, clear } = useAiState();
  const { dataSource } = useAiDataSource();
  const {
    state: { name: stateName },
  } = useCurrentStateAndParams();
  const [url, setUrl] = useState(window.location.href);

  useOnStateChanged(() => setUrl(window.location.href));

  const isInsideGrafana = useMemo(() => [grafanaStateName, metricsRouteName].includes(stateName), [stateName]);

  const shouldShowChat = useMemo(() => {
    const isInsideDashboard = url.includes('metrics/d/') || url.includes('metrics/dashboard/');

    return isAiMetricsDashboardEnabled && isInsideDashboard && isInsideGrafana;
  }, [isInsideGrafana, isAiMetricsDashboardEnabled, url]);

  useEffect(() => {
    const isMismatchedSource =
      dataSource &&
      ((isInsideGrafana && dataSource !== AIDataSource.MetricsDashboard) ||
        (!isInsideGrafana && dataSource === AIDataSource.MetricsDashboard));

    if (isMismatchedSource) {
      clear();
      toggleDrawer(false);

      return;
    }

    if (isInsideGrafana) {
      setContext(url);
    }
  }, [url, isInsideGrafana, dataSource]);

  return (
    <Header pr={3} pl={8} flexDirection={'column'} subject={HeaderSubjects.Header}>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <HeaderBreadcrumbs />
        <Box />
        <ExploreAsDefaultCallout />
        <Group gap={3} alignItems={'center'}>
          {isGuidedWalkthroughActivated ? <GuidedWalkthroughBadge /> : null}
          <WhatsNew />
          <HelpMenu />
          {shouldShowChat ? <OpenChat /> : null}
          <SelectAccountButton />
        </Group>
      </Flex>
    </Header>
  );
};
