import { httpService } from '@logz-pkg/frontend-services';
import { InsightModel } from './insight.model';
import { appRouter } from 'services/router/router.service';
import { alertsRoutesStates } from 'states/alerts.routes';

const _INSIGHT_TO_FIELD_NAME = {
  PUBLIC_CI: {
    title: 'Cognitive Insights',
    field: '_logzio_insights',
    tooltip:
      'Using AI and machine learning, Cognitive Insights uncovers issues in your logs and finds crowdsourced' +
      'solutions. Use markers to correlate insights with changes in your environment, like deployments.',
  },
  LOGCEPTION: {
    title: 'Application Insights',
    field: '_logzio_logceptions',
    tooltip:
      'These are exceptions and errors detected in your logs. Use markers to correlate insights with ' +
      'changes in your environment, like deployments.',
  },
};

const insightIdToPromise = new Map();

/** ****************************************************************************************
 *                                    MISCELLANEOUS                                       *
 ***************************************************************************************** */
function getInsightsByIds(ids, { dontShowProgressBar = false } = {}) {
  return httpService.post('/insights', { ids }, { dontShowProgressBar });
}

function openCreateAlertWizardForInsight(insightId, insightType) {
  const match = {};

  match[_INSIGHT_TO_FIELD_NAME[insightType].field] = {
    query: insightId,
  };

  const filter = { bool: { must: [{ match_phrase: match }], must_not: [] } };

  return appRouter.stateService.go(alertsRoutesStates.v219New, { filters: filter });
}

function getInsightsByIdsForKibana(ids, { dontShowProgressBar = false } = {}) {
  if (!ids || !ids.length) {
    return Promise.resolve([]);
  }

  const idsToFetch = ids.filter(id => {
    return !insightIdToPromise.get(id);
  });

  if (idsToFetch.length !== 0) {
    const promise = getInsightsByIds(idsToFetch, { dontShowProgressBar });

    idsToFetch.forEach(id => {
      insightIdToPromise.set(
        id,
        promise.then(({ data = [] }) => {
          const insightData = data.find(insight => insight.id === id);

          // Insight was not found after requesting - might be deleted
          const insightModel = insightData ? new InsightModel(insightData) : null;

          return insightModel;
        }),
      );
    });
    promise.catch(() => {
      idsToFetch.forEach(id => {
        insightIdToPromise.delete(id);
      });
    });
  }

  return Promise.all(ids.map(id => insightIdToPromise.get(id)));
}

export const insightsService = {
  openCreateAlertWizardForInsight,
  getInsightsByIdsForKibana,
};
