import { ArchiveStatus } from '@logz-pkg/enums';

export const statusTexts = {
  [ArchiveStatus.NONE]: {
    submitText: 'Start archiving',
  },
  [ArchiveStatus.ENABLED]: {
    submitText: 'Update settings',
  },
  [ArchiveStatus.DISABLED]: {
    submitText: 'Update settings',
  },
};

export const awsFormPlaceholderTexts = {
  bucketName: 'your-bucket-name',
  accessKey: 'e.g. AKIAIOSFODNN7EXAMPLE',
  secretKey: id => (id ? 'Click here to update your secret key' : 'e.g. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY'),
  arn: 'arn:aws:iam::123456780912:role/S3Access',
};

export const AzureFormPlaceholdersText = {
  tenantId: 'example: 1966-55f7-be10-58ff61a89089',
  clientId: 'example: 505c-59ee-82ad-fa4cda6f60e6',
  clientSecret: id => (id ? 'Click here to update your client secret key' : 'example: 5050-59ee-0000-000000000000'),
  accountName: 'example: logzio-archives',
  containerName: 'example: logzio-archive-container1',
  storagePathPrefix: 'example: region1',
};

export const stopArchivingModalText = {
  title: 'Pause archiving?',
  text: `Data that's already been archived will remain. You can resume at any time.`,
  confirmText: 'Pause archiving',
  cancelText: 'No, continue archiving',
  removeSettingsText: 'Remove the credentials, too',
  additionalIAMInformation: `If you remove your S3 settings and you want to archive in the future, you’ll also need to migrate to a role that uses a new Logz.io-assigned external ID.`,
  migratingRolesLinkText: `Read about migrating roles`,
};

export const deleteArchiveSettingsModalText = {
  title: 'Delete archive settings',
  text: `You’ll stop any restores that are in progress right now`,
  confirmText: 'Delete archiving',
  cancelText: 'Cancel',
};

export const formFieldsTooltips = {
  tenantId:
    'The Tenant ID of the AD app. Found under the App Overview page. Go to Azure Active Directory > App registrations and select the app to see it.',
  clientId:
    'The Client ID of the AD app. Found under the App Overview page. Go to Azure Active Directory > App registrations and select the app to see it.',
  clientSecret:
    "Password of the Client secret. Found in the app's Certificates & secrets page. Go to Azure Active Directory > App registrations and select the app. Then select Certificates & secrets to see it.",
  accountName: 'Name of the storage account that holds the container where the logs will be archived.',
  containerName: 'Name of the container. This is where the logs will be archived.',
  storagePathPrefix: 'Optional. Logs will be archived under the path “{container-name}/{virtual sub-folder)”.',
};

export const onChangeStorageTypeText = {
  toAzure:
    'Only 1 archive can be active. If you switch to Azure Blob, archiving to AWS will end and your AWS S3 credentials will be deleted.',
  toAWS:
    'Only 1 archive can be active. If you switch to AWS, archiving to Azure Blob will end and your Azure Blob credentials will be deleted.',
};

export const ARCHIVE_RESTORE_LEARN_MORE_LINK = `https://docs.logz.io/user-guide/archive-and-restore/configure-archiving.html#enter-your-bucket-information-and-s3-credentials`;
export const POWER_SEARCH_LEARN_MORE_LINK = `https://docs.logz.io/user-guide/archive-and-restore/set-s3-permissions.html#add-power-search-permissions`;
