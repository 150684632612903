import { Flex, Icon } from '@logz-ui/styleguide';
import React from 'react';
import { GraphProducts } from './types';

export const icons: Record<GraphProducts, React.FunctionComponent> = {
  Alerts: () => <Icon icon={'bell-regular'} size={18} color={'product.alerts'} />,
  Exceptions: () => <Icon icon={'regular-layer-group-circle-bolt-custom'} size={18} color={'product.exceptions'} />,
  Insights: () => <Icon icon={'lightbulb-on-regular'} size={18} color={'product.insights'} />,
  Logs: () => <Icon icon={'logz-prod-icon-solid-custom'} size={24} color={'product.logs'} subject={'logs-logo'} />,
  Metrics: () => (
    <Icon icon={'metrics-prod-icon-solid-custom'} size={24} color={'product.metrics'} subject={'metrics-logo'} />
  ),
  Tracing: () => (
    <Icon icon={'tracing-prod-icon-solid-custom'} size={18} color={'product.tracing'} subject={'tracing-logo'} />
  ),
  Unified: () => (
    <Flex p={'3px'}>
      <Icon icon={'objects-column-regular'} size={18} color={'yellow.600'} />
    </Flex>
  ),
};

export const productNames: Record<GraphProducts, string> = {
  Alerts: 'Alerts',
  Exceptions: 'Exceptions',
  Insights: 'Insights',
  Logs: 'Logs',
  Metrics: 'Metrics',
  Tracing: 'Tracing',
  Unified: 'Unified Dashboard',
};
