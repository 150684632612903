import { AppStateDeclaration } from './AppStateDecleration';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const drilldownRoute: AppStateDeclaration = {
  name: 'dashboard.settings.drilldowns',
  url: '/drilldowns?{page:int}&{pageSize:int}',
  component: props => (
    <LazyReact
      lazyImport={() => import('ui/components/DrilldownSettings/DrilldownSettings')}
      resolver={({ DrilldownSettings }) => DrilldownSettings}
      {...props}
    />
  ),
  data: { allowedAppModes: [AppModes.SECURITY?.value], title: 'Drilldown Settings', breadcrumb: { label: 'Drilldown' } },
  resolve: [
    {
      token: 'isAuthenticated',
      resolveFn: () =>
        appModeService.getCurrentMode().then(mode => {
          if (mode.value !== 'SECURITY') {
            return appModeService.goToModeKibanaState();
          }
        }),
    },
  ],
};
