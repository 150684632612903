import { redirectIfNotAdmin } from '../utils';
import { AppStateDeclaration } from '../AppStateDecleration';
import { tracingRouteStates } from './tracing-sampling.routes.names';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const tracingConfigurationRoutes: AppStateDeclaration[] = [
  {
    name: tracingRouteStates.samplingRules,
    url: '/tracing-sampling-rules?accountId',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Tracing/SamplingRules/SamplingRulesPage')}
        resolver={({ SamplingRulesPage }) => SamplingRulesPage}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Sampling rules',
      breadcrumb: { label: 'Sampling rules' },
    },
    params: {
      accountId: null,
    },
    resolve: [redirectIfNotAdmin],
    dynamic: true,
  },
  {
    name: tracingRouteStates.yamlConfig,
    url: '/tracing-yaml-configuration?accountId',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Tracing/YamlConfiguration/YamlConfigurationPage')}
        resolver={({ YamlConfigurationPage }) => YamlConfigurationPage}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'YAML configuration',
      breadcrumb: { label: 'YAML configuration' },
    },
    params: {
      accountId: null,
    },
    resolve: [redirectIfNotAdmin],
    redirectTo: tracingRouteStates.yamlConfigLocalhost,
    dynamic: true,
  },
  {
    name: tracingRouteStates.yamlConfigLocalhost,
    url: '/localhost',
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Tracing/YamlConfiguration/LocalhostContainer')}
        resolver={({ LocalhostContainer }) => LocalhostContainer}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      breadcrumb: { label: 'Localhost' },
    },

    resolve: [redirectIfNotAdmin],
    dynamic: true,
  },
];
