import styled from 'styled-components';

export const InlineCodeBlock = styled.pre`
  white-space: pre-wrap;
  word-break: break-word;
  display: inline;

  .custom-highlight {
    background-color: yellow;
  }
`;
