import { ExplorePageSubjects } from '@logz-pkg/enums';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { colorsV2 } from '@logz-ui/styleguide';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const hoverStyle = css`
  background-color: ${({ theme }) => theme.colors.gray[300]};
`;

const highlightStyle = css`
  padding-left: 0px;
  border-left: 4px solid ${colorsV2.blue[600]};
  background-color: ${colorsV2.blue[50]};
`;

const StyledItem = styled.div<{ highlighted?: boolean; selected?: boolean }>`
  width: 100%;
  margin: 0;
  padding: 0 0 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ highlighted }) => highlighted && highlightStyle}
  &:hover {
    cursor: pointer;
    ${hoverStyle}
  }
  ${({ selected, highlighted }) =>
    selected &&
    css`
      background-color: ${colorsV2.blue[highlighted ? 200 : 100]};
    `}
`;

interface IMenuItemProps extends PropsWithChildren {
  highlighted?: boolean;
  selected?: boolean;
  subject?: string;
  onClick: (event: React.MouseEvent) => void;
}

export const MenuItem: React.FC<IMenuItemProps> = ({
  onClick,
  selected,
  children,
  highlighted = false,
  subject = ExplorePageSubjects.SmartSelectPopoverOption,
}) => {
  return (
    <StyledItem
      selected={selected}
      highlighted={highlighted}
      onMouseDown={e => e.preventDefault()}
      onClick={onClick}
      data-hovered={highlighted}
      data-selected={selected}
      {...generateLogzTestAttributes({ subject })}
    >
      {children}
    </StyledItem>
  );
};
