import { Product } from '@logz-pkg/enums';
import styled from 'styled-components';
import { Img } from '@logz-ui/styleguide';
import homeLogoSrc from '../assets/home.svg';
import settingsLogoSrc from '../assets/settings.svg';
import logzioLogoSrc from '../assets/logzio.svg';
import logzioWithBackgroundLogoSrc from '../assets/logziowithbackground.svg';
import siemLogoSrc from '../assets/siem.svg';
import logsLogoSrc from '../assets/logs.svg';
import metricsLogoSrc from '../assets/metrics.svg';
import tracingLogoSrc from '../assets/tracing.svg';
import supportLogoSrc from '../assets/support.svg';
import kube360LogoSrc from '../assets/kube360.svg';
import exploreLogoSrc from '../assets/explore.svg';
import dashboardsHubLogoSrc from '../assets/dashboards-hub.svg';
import dataHubLogoSrc from '../assets/data-hub.svg';
import app360LogoSrc from '../assets/app-360.svg';
import integrationsLogoSrc from '../assets/integrations.svg';

export enum ExtendedLogos {
  Logzio,
  LogzioWithBackground,
  Settings,
  Support,
  K8S360,
  Explore,
  DataHub,
  DashboardsHub,
  Home,
  App360,
  Integrations,
}

export interface ILogoProps {
  logo: keyof typeof LogoSrcMap;
  active?: boolean;
  mini?: boolean;
  alt?: string;
  width?: string;
  onClick?: () => void;
}

export const LogoSrcMap = {
  [ExtendedLogos.Logzio]: logzioLogoSrc,
  [ExtendedLogos.LogzioWithBackground]: logzioWithBackgroundLogoSrc,
  [ExtendedLogos.Settings]: settingsLogoSrc,
  [Product.LogAnalytics]: logsLogoSrc,
  [Product.Metrics]: metricsLogoSrc,
  [Product.Siem]: siemLogoSrc,
  [Product.Tracing]: tracingLogoSrc,
  [ExtendedLogos.K8S360]: kube360LogoSrc,
  [ExtendedLogos.Explore]: exploreLogoSrc,
  [ExtendedLogos.DataHub]: dataHubLogoSrc,
  [ExtendedLogos.DashboardsHub]: dashboardsHubLogoSrc,
  [ExtendedLogos.Home]: homeLogoSrc,
  [ExtendedLogos.Support]: supportLogoSrc,
  [ExtendedLogos.App360]: app360LogoSrc,
  [ExtendedLogos.Integrations]: integrationsLogoSrc,
};

interface IStyledLogoProps {
  $active?: boolean;
  $mini?: boolean;
  onClick?: () => void;
}

const StyledImg = styled(Img)<IStyledLogoProps>`
  ${({ $active }) => !$active && `filter: invert(0.5) grayscale(1) brightness(1.5);`}
  ${({ $mini }) => $mini && `transform: scale(0.8);`}
  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

export const Logo = ({ logo, active = true, mini = false, alt, width, onClick }: ILogoProps) => (
  <StyledImg
    alt={alt}
    src={LogoSrcMap[logo]}
    onClick={onClick}
    $active={active}
    $mini={mini}
    width={width}
    hideProgress
  />
);
