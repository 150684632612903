import { useEffect, useMemo } from 'react';
import { AccountType, PLAN_TYPE, TracingAccountCardState } from '@logz-pkg/enums';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { tracingService } from 'services/tracing/tracing.service';
import { useSessionState, useSiemOnboardingState, useTracingState } from 'ui/state/hooks';

export type TOnboardingBarsState = {
  isTracingTrialVisible: boolean;
  isWelcomeCenterVisible: boolean;
  isSiemOnboardingVisible: boolean;
};

export const useOnboardingBarsState = (): TOnboardingBarsState => {
  const { isOnboardingBarOpen: isSiemOnboardingVisible } = useSiemOnboardingState();

  const { plan, loggedInAccount } = useSessionState();

  const { action: getTracingBudget, data: tracingBudget } = useAsyncAction(() => tracingService.getTracingBudget(), {
    initialData: null,
  });
  const { tracingBudgetState, setTracingBudget } = useTracingState();

  useEffect(() => {
    if (loggedInAccount?.type === AccountType.Owner) {
      getTracingBudget();
    }

    return () => {
      setTracingBudget(null);
    };
  }, [loggedInAccount]);

  useEffect(() => {
    !tracingBudgetState && setTracingBudget(tracingBudget);
  }, [tracingBudget, tracingBudgetState]);

  // The tracing bar is only displayed if user's plan is paid and tracing account is in trial status
  const isTracingTrialVisible = useMemo(() => {
    const isCommunityPlanType = [PLAN_TYPE.TRIAL, PLAN_TYPE.FREE].includes(plan?.planType);
    const isTrialTracingAccount =
      tracingService.getTracingAccountState(tracingBudgetState) === TracingAccountCardState.Trial;

    return !isCommunityPlanType && isTrialTracingAccount && loggedInAccount?.type === AccountType.Owner;
  }, [tracingBudgetState, plan, loggedInAccount?.type]);

  // The welcome bar is displayed by default if no other bars displayed
  const isWelcomeCenterVisible = useMemo(
    () => !(isTracingTrialVisible || isSiemOnboardingVisible),
    [isTracingTrialVisible, isSiemOnboardingVisible],
  );

  return { isWelcomeCenterVisible, isTracingTrialVisible, isSiemOnboardingVisible };
};
