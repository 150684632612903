import React from 'react';
import { Button } from '@logz-ui/styleguide';
import { AccountType, SendYourDataTestSubject } from '@logz-pkg/enums';
import { IButtonProps } from '@logz-ui/styleguide/components/Button/Button.types';
import { useSessionState } from 'ui/state/hooks';

export const AddBucketButton: React.FC<IButtonProps> = props => {
  const { userSummary } = useSessionState();
  const isButtonDisabled: boolean = userSummary.accountType === AccountType.Security;

  return (
    <Button
      variant={'secondary'}
      icon={'plus-regular'}
      subject={SendYourDataTestSubject.AddBucketButton}
      disabled={isButtonDisabled}
      disabledTooltip={'To add bucket you need to navigate to relevant logging account.'}
      {...props}
    >
      Add a bucket
    </Button>
  );
};
