import { KibanaSubjects } from '@logz-pkg/enums';
import { NotificationService } from '@logz-ui/styleguide';
import { AlertEventDetailsModel } from '@logz-pkg/models';
import { authService, alertDetailsApiService } from '@logz-pkg/frontend-services';
import { fetchAlertKibanaParams, goToKibanaWithParams, handleRouteError } from './osd.route.helpers';
import { parseElasticFilters } from './osd.filter-parser';
import { ElasticsearchFilterManager } from './elasticsearch-filters-manager';

async function viewAlertLogsInKibana({
  filter,
  fieldToAggregateOn,
  groupByIds,
  query,
  fromDate,
  toDate,
  subAccountsIds,
  allSubAccounts,
  openInNewTab = false,
}) {
  const filterManager = new ElasticsearchFilterManager(filter);
  const columns: any[] = [];

  if (fieldToAggregateOn) {
    filterManager.addExistsFilter({
      field: fieldToAggregateOn,
    });
    columns.push(fieldToAggregateOn);
  }

  Object.entries(groupByIds).forEach(([field, value]) => filterManager.addIsFilter({ field, value }));

  await filterManager.populateLookupFilters();

  const filters = filterManager.get();

  await goToKibanaWithParams({
    query,
    filters,
    fromDate,
    toDate,
    subAccountsIds,
    allSubAccounts,
    openInNewTab,
  });
}

async function viewTriggeredAlertEventsInKibana({ groupByName, definitionId, fromDate, toDate, openInNewTab = false }) {
  const columns = [...groupByName.map(x => `logzio-alert-group-ids.${x}`), 'logzio-investigate'];

  const filterManager = new ElasticsearchFilterManager();

  filterManager.addIsFilter({ field: 'logzio-alert-definition-id', value: definitionId });
  filterManager.addIsFilter({ field: 'type', value: 'logzio-alerts' });

  const { accountId } = await authService.getUserSession();

  const filters = filterManager.get();

  await goToKibanaWithParams({
    filters,
    fromDate,
    toDate,
    columns,
    subAccountsIds: [accountId],
    openInNewTab,
  });
}

function getGroupBysFromKibanaParams(alertKibanaParamsList) {
  return [...new Set(alertKibanaParamsList.flatMap(kibanaParams => kibanaParams.groupByName))];
}

export async function viewRestoreAccount({ restoreAccount, startTime = 0, endTime = 0 }) {
  try {
    await goToKibanaWithParams({ fromDate: +startTime, toDate: +endTime, subAccountsIds: [restoreAccount] });
  } catch (err) {
    await handleRouteError(err);
  }
}

export async function viewAlertLastEvents({ definitionId, from, to, openInNewTab = false }) {
  try {
    const alertKibanaParamsList = await fetchAlertKibanaParams(definitionId);
    const groupByName = getGroupBysFromKibanaParams(alertKibanaParamsList);

    viewTriggeredAlertEventsInKibana({ groupByName, definitionId, toDate: +to, fromDate: +from, openInNewTab });
  } catch (err) {
    await handleRouteError(err);
  }
}

export async function viewAlertLogs({
  triggeredId,
  openInNewTab = false,
  redirectOnFailure = true,
}: {
  triggeredId: string;
  openInNewTab?: boolean;
  redirectOnFailure?: boolean;
}): Promise<void> {
  try {
    const triggeredAlert: AlertEventDetailsModel = await alertDetailsApiService.get(triggeredId);

    if (!triggeredAlert) {
      NotificationService.danger({
        message: 'Alert details not found.',
        title: 'Investigation failure',
        subject: KibanaSubjects.FetchLogs,
      });

      return;
    }

    const parsedFilters = parseElasticFilters(triggeredAlert.query.filters);

    await goToKibanaWithParams({
      filters: parsedFilters,
      query: triggeredAlert.query.query,
      fromDate: triggeredAlert.fromDate,
      toDate: triggeredAlert.toDate,
      subAccountsIds: triggeredAlert.query.accountIdsToQueryOn,
      allSubAccounts: triggeredAlert.query.shouldQueryOnAllAccounts,
      columns: triggeredAlert.query.groupBy,
      openInNewTab,
    });
  } catch (err) {
    await handleRouteError(err, { redirectOnFailure });
  }
}

export async function goToKibanaDiscoverWithQuickMode({ from, to }) {
  await goToKibanaWithParams({
    fromDate: from,
    toDate: to,
    mode: 'quick',
  });
}
