import { ElasticsearchFilterModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';

class LiveTailStateService {
  readonly livetailObservable = new Observable<{ open: boolean; filters: ElasticsearchFilterModel[] | null }>({
    open: false,
    filters: null,
  });

  open = (filters: ElasticsearchFilterModel[]) => {
    this.livetailObservable.set({
      open: true,
      filters,
    });
  };

  close = () => {
    this.livetailObservable.set({
      open: false,
      filters: null,
    });
  };
}

export const liveTailStateService = new LiveTailStateService();
