import React from 'react';
import { Card, colors, colorsV2, Flex, Grid, Icon, Skeleton, Text } from '@logz-ui/styleguide';
import styled, { css } from 'styled-components';
import { HelpQuestionsCardInformationType } from 'ui/components/AI/types';

const StyledFlexIcon = styled(Flex)`
  display: none;
`;

const StyledCard = styled(Card)`
  border: 1px solid transparent;
  padding: 6px;
`;

const StyledFlex = styled(Flex)<{ isFetching: boolean; isHighlighted?: boolean }>`
  border-radius: 8px;
  background: ${colors.gray[200]};
  border: none;
  padding: 1px;

  ${({ isFetching }) => isFetching && `pointer-events: none;`}

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background: ${colorsV2.gradient.radiant};

      ${StyledCard} {
        box-shadow: none;

        ${StyledFlexIcon} {
          display: flex;
        }
      }
    `}

  &:hover {
    background: ${colorsV2.gradient.radiant};

    ${StyledCard} {
      box-shadow: none;

      ${StyledFlexIcon} {
        display: flex;
      }
    }
  }

  ${({ isFetching }) =>
    isFetching &&
    css`
      ${StyledCard} {
        ${StyledFlexIcon} {
          display: none;
        }
      }
    `}
`;

interface IQuestionCardProps extends HelpQuestionsCardInformationType {
  handleClick: () => void;
  id: string;
  subject?: string;
  isHighlighted?: boolean;
  isFetching?: boolean;
}

export const QuestionCard: React.FC<IQuestionCardProps> = ({
  text,
  iconName,
  color,
  handleClick,
  subject = '',
  isHighlighted = false,
  id,
  isFetching = false,
}) => (
  <StyledFlex isHighlighted={isHighlighted} id={id} isFetching={isFetching}>
    <StyledCard width={'100%'} borderRadius={7} mb={0} onClick={handleClick} subject={subject} flexDirection={'column'}>
      <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid gridTemplateColumns={'20px 1fr'} cellGap={2}>
          <Flex height={20} width={20} justifyContent={'center'} alignItems={'center'}>
            <Icon icon={iconName} size={14} color={color} />
          </Flex>
          <Flex alignItems={'center'}>
            {isFetching ? (
              <Skeleton width={'296px'} height={'14px'} round={false} />
            ) : (
              <Text ellipsis color={'royalBlue.1000'}>
                {text}
              </Text>
            )}
          </Flex>
        </Grid>

        <StyledFlexIcon height={20} width={20} justifyContent={'center'} alignItems={'center'}>
          <Icon icon={'arrow-turn-down-left-regular'} size={14} color={'gray.600'} />
        </StyledFlexIcon>
      </Flex>
    </StyledCard>
  </StyledFlex>
);
