import { ParamDeclaration } from '@uirouter/react';
import { AppStateDeclaration } from '../AppStateDecleration';
import { redirectIfFFDisabled } from 'states/utils';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const dashboardsHubRouteName = 'dashboard.dashboards-hub';
export const viewDashboardRouteName = 'dashboard.view-dashboard';
export const viewLegacyDashboardRouteName = 'dashboard.dashboards-hub-legacy';

const redirectIfDashboardsHubFFDisabled = redirectIfFFDisabled({ featureFlag: 'CentralizedDashboards' });
const redirectIfUnifiedDashboardsFFDisabled = redirectIfFFDisabled({
  featureFlag: 'UnifiedDashboards',
  toState: viewLegacyDashboardRouteName,
});

export const dashboardsHubRoute: AppStateDeclaration = {
  name: dashboardsHubRouteName,
  url: '/dashboards-hub',
  params: {},
  component: props => (
    <LazyReact
      lazyImport={() => import('ui/components/DashboardsHub/DashboardsHub')}
      resolver={({ DashboardsHub }) => DashboardsHub}
      {...props}
    />
  ),
  data: {
    breadcrumb: { label: 'Dashboards Hub' },
    authenticate: true,
    title: 'Dashboards Hub',
  },
  dynamic: true,
  resolve: [redirectIfDashboardsHubFFDisabled, redirectIfUnifiedDashboardsFFDisabled],
};

export const dashboardsHubLegacyRoute: AppStateDeclaration = {
  name: viewLegacyDashboardRouteName,
  url: '/dashboards-hub/legacy',
  params: {},
  component: props => (
    <LazyReact
      lazyImport={() => import('ui/components/DashboardsHub/legacy/DashboardsHubLegacy')}
      resolver={({ LegacyDashboardsHub }) => LegacyDashboardsHub}
      {...props}
    />
  ),
  data: {
    breadcrumb: { label: 'Legacy Dashboards Hub', parent: dashboardsHubRouteName },
    authenticate: true,
    title: 'Dashboards Hub',
  },
  dynamic: true,
  resolve: [redirectIfDashboardsHubFFDisabled],
};

const params = ['start', 'end', 'refresh', 'source', 'dashboardTitle', 'viewPanelRef'] as const;
const defaultParams: Record<typeof params[number] | 'panelDefinition', ParamDeclaration> = {
  start: null,
  end: null,
  refresh: null,
  source: null,
  dashboardTitle: null,
  panelDefinition: null,
  viewPanelRef: {
    value: null,
    inherit: false,
  },
};

export const viewDashboardRoute: AppStateDeclaration = {
  name: viewDashboardRouteName,
  url: `/dashboards-hub/view/folders/:folderId/dashboards/:dashboardId?${params.join('&')}`,
  params: defaultParams,
  component: props => (
    <LazyReact
      lazyImport={() => import('ui/components/DashboardsHub/ViewUnifiedDashboard')}
      resolver={({ ViewUnifiedDashboard }) => ViewUnifiedDashboard}
      {...props}
    />
  ),
  data: {
    breadcrumb: {
      calculateLabel: params => params.dashboardTitle,
      parent: dashboardsHubRouteName,
    },
    authenticate: true,
  },
  dynamic: true,
  resolve: [redirectIfDashboardsHubFFDisabled],
};
