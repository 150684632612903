/* eslint-disable security/detect-object-injection */
import { appStateService, LoggerService } from '@logz-pkg/frontend-services';
import { ExplorePreferencesStateModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { NotificationService } from '@logz-ui/styleguide';
import { cloneDeep, isNil, merge, startCase } from 'lodash';
import { defaultGeneralSettingsState } from '../../Components/Preferences/GeneralSettings/constants';
import { defaultKeyBindingsState } from '../../Components/Preferences/keyboard/constants';
import { SetKeyBindingParams, ToggleKeyBindingParams } from '../../Components/Preferences/types';

const VERSION = 1;
const STATE_NAME = 'explore-preferences';
const defaultState: ExplorePreferencesStateModel = {
  _migrated: true,
  keyBindings: defaultKeyBindingsState,
  generalSettings: defaultGeneralSettingsState,
};

// Moving from v0 to v1 - we changed the columns from string[] to Column[]
const getPreviousVersion = async (): Promise<ExplorePreferencesStateModel> => {
  const prevSavedState: ExplorePreferencesStateModel = await appStateService.get(STATE_NAME, 0);

  const state: ExplorePreferencesStateModel = cloneDeep(defaultState);

  if (prevSavedState?.keyBindings !== undefined) {
    state.keyBindings = prevSavedState.keyBindings;
  }

  if (prevSavedState?.generalSettings !== undefined) {
    state.generalSettings = prevSavedState.generalSettings;
  }

  if (prevSavedState?.generalSettings.defaultColumns !== undefined) {
    state.generalSettings.defaultColumns = prevSavedState.generalSettings.defaultColumns.map(col =>
      typeof col === 'string' ? { id: col } : col,
    );
  }

  LoggerService.logInfo({
    message: `explore migrated explore-preferences from previous version`,
  });

  return state;
};

class ExplorePreferencesStateService {
  state = new Observable<ExplorePreferencesStateModel>(defaultState);

  clear = () => this.state.set(defaultState);

  resetRecents = async () => {
    await appStateService.set(STATE_NAME, { ...this.state.get(), recent: [] });
    this.clear();
  };

  private set = async (value: ExplorePreferencesStateModel) => {
    const current = this.state.get();

    const newState: ExplorePreferencesStateModel = {
      _migrated: value._migrated ?? current._migrated ?? defaultState._migrated,
      keyBindings: value.keyBindings ?? current.keyBindings ?? defaultState.keyBindings,
      generalSettings: value.generalSettings ?? current.generalSettings ?? defaultState.generalSettings,
    };

    appStateService.set(STATE_NAME, newState, VERSION);
  };

  get = async (): Promise<ExplorePreferencesStateModel> => {
    const savedInAppState: ExplorePreferencesStateModel = await appStateService.get(STATE_NAME, VERSION);
    let newState: ExplorePreferencesStateModel;

    if (!savedInAppState?._migrated) {
      newState = await getPreviousVersion();
      this.set(newState);
    } else {
      newState = merge({}, defaultState, savedInAppState);
    }

    Object.entries(defaultKeyBindingsState).forEach(([key, value]) => {
      if (isNil(newState.keyBindings[key])) {
        newState.keyBindings[key] = value;
      }
    });
    Object.entries(defaultGeneralSettingsState).forEach(([key, value]) => {
      if (isNil(newState.generalSettings)) {
        newState.generalSettings = {};
      }

      if (isNil(newState.generalSettings[key])) {
        newState.generalSettings[key] = value;
      }
    });

    this.state.set(newState);

    return newState;
  };

  enableKeybinding = async ({ action }: ToggleKeyBindingParams): Promise<void> => {
    const explorePreferencesState = cloneDeep(this.state.get());

    if (explorePreferencesState.keyBindings[action]) {
      explorePreferencesState.keyBindings[action].enabled = true;

      const newState: ExplorePreferencesStateModel = { ...(await this.get()), ...explorePreferencesState };

      this.state.set(newState);
      await this.set(newState);
    }
  };

  disableKeybinding = async ({ action }: ToggleKeyBindingParams): Promise<void> => {
    const explorePreferencesState = cloneDeep(this.state.get());

    if (explorePreferencesState.keyBindings[action]) {
      explorePreferencesState.keyBindings[action].enabled = false;

      const newState: ExplorePreferencesStateModel = { ...(await this.get()), ...explorePreferencesState };

      this.state.set(newState);
      await this.set(newState);
    }
  };

  addKeyBinding = async ({ action, keyBindings }: SetKeyBindingParams): Promise<void> => {
    const explorePreferences = cloneDeep(this.state.get());

    if (explorePreferences.keyBindings[action]) {
      explorePreferences.keyBindings[action].keyBindings = [
        ...explorePreferences.keyBindings[action].keyBindings,
        ...keyBindings,
      ];

      const newState: ExplorePreferencesStateModel = { ...(await this.get()), ...explorePreferences };

      this.state.set(newState);
      await this.set(newState);
    }
  };

  removeKeyBinding = async ({ action, keyBindings }: { action: string; keyBindings: string }): Promise<void> => {
    const explorePreferences = cloneDeep(this.state.get());

    if (explorePreferences.keyBindings[action]) {
      explorePreferences.keyBindings[action].keyBindings = explorePreferences.keyBindings[action].keyBindings.filter(
        binding => binding !== keyBindings,
      );

      const newState: ExplorePreferencesStateModel = { ...(await this.get()), ...explorePreferences };

      this.state.set(newState);
      await this.set(newState);
    }
  };

  resetKeyBinding = async ({ action }: { action: string }): Promise<void> => {
    const explorePreferences = cloneDeep(this.state.get());

    if (explorePreferences.keyBindings[action]) {
      explorePreferences.keyBindings[action].keyBindings = defaultKeyBindingsState[action].keyBindings;

      const newState: ExplorePreferencesStateModel = { ...(await this.get()), ...explorePreferences };

      this.state.set(newState);
      await this.set(newState);
    }
  };

  resetAllKeyBindings = async (): Promise<void> => {
    const explorePreferences = cloneDeep(this.state.get());

    explorePreferences.keyBindings = defaultKeyBindingsState;

    const newState: ExplorePreferencesStateModel = { ...(await this.get()), ...explorePreferences };

    this.state.set(newState);
    await this.set(newState);
  };

  updateGeneralSettings = async (generalSetting, value): Promise<void> => {
    const exploreStateCopy = cloneDeep(this.state.get());

    exploreStateCopy.generalSettings[generalSetting] = value;

    this.state.set(exploreStateCopy);

    try {
      await this.set(exploreStateCopy);
    } catch {
      NotificationService.unexpectedError(`Failed to update ${startCase(generalSetting)}`);
    }
  };

  resetAllGeneralSettings = async (): Promise<void> => {
    const exploreStateCopy = cloneDeep(this.state.get());

    exploreStateCopy.generalSettings = defaultGeneralSettingsState;

    this.state.set(exploreStateCopy);
    await this.set(exploreStateCopy);
  };
}

export const explorePreferencesStateService = new ExplorePreferencesStateService();
