import { Graph, GraphContainer } from '@logz-ui/graphs';
import { Card, Group, Icon, Text } from '@logz-ui/styleguide';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IntervalData } from '@logz-pkg/utils';
import { AICopilotInvocationMessageSubjects } from '@logz-pkg/enums';
import { graphUtils } from 'ui/components/Explore/ContentPane/Graphs/utils/graph-utils';
import { Backdrop } from 'ui/components/Explore/Components/EmptyState/Backdrop';
import { useOpenSearchInvocationMessageHook } from 'ui/components/AI/Components/Messages/Agent/openSearchInvocation.hook';

const StyledGroupEmptyState = styled(Group)`
  position: relative;
`;

const HistogramEmptyState: React.FC = () => (
  <Card
    width={'100%'}
    alignItems="center"
    justifyContent="center"
    zIndex={-2}
    height={100}
    subject={AICopilotInvocationMessageSubjects.EmptyGraph}
  >
    <StyledGroupEmptyState width={270} flexDirection={'column'} alignItems="center" rowGap={2}>
      <Icon icon={'magnifying-glass-regular'} color={'blue.600'} size={30} />
      <Backdrop color="yellow.100" style={{ width: 110, height: 60, position: 'absolute', zIndex: -1, top: 10 }} />
      <Text weight={700} size={12}>
        No matching results found
      </Text>
    </StyledGroupEmptyState>
  </Card>
);

export type AiChatAgentInvocationGraphType = {
  interval: IntervalData;
  functionName: string;
  field: string;
  query: string;
  shouldShowCustomLegend: boolean;
};

export const AiChatAgentInvocationGraph: React.FC<AiChatAgentInvocationGraphType> = ({
  functionName,
  interval,
  field,
  query,
  shouldShowCustomLegend,
}) => {
  const { logs, isFetching } = useOpenSearchInvocationMessageHook({
    functionName,
    interval,
    field,
    query,
  });

  const maxY = useMemo(() => {
    if (!logs) return 0;

    return graphUtils.getMaxYValue(logs);
  }, [logs]);

  return (
    <Group width={'100%'} mt={1} subject={AICopilotInvocationMessageSubjects.Graph}>
      {isFetching || (logs?.length && logs[0]?.data) ? (
        <GraphContainer
          loading={isFetching}
          height={'80px'}
          width={shouldShowCustomLegend ? '80%' : '100%'}
          borderless
          p={0}
        >
          <Graph
            series={logs}
            legendType={shouldShowCustomLegend ? 'pie' : null}
            height={80}
            overrideXAxisOptions={{ gridLineWidth: 0 }}
            overrideYAxisOptions={{ max: maxY, lineColor: null }}
          />
        </GraphContainer>
      ) : (
        <HistogramEmptyState />
      )}
    </Group>
  );
};
