import { Column } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { isNil, union } from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableHandle } from '@logz-ui/styleguide';
import { ACCOUNTS_FIELD_NAME, GROUPBY_FIELD_NAME } from '../constants';
import { buildColumn } from '../ContentPane/columns/buildColumn';
import { accountsColumn, timestampColumn } from '../ContentPane/columns/columns';
import { SearchResultRecord } from '../ContentPane/Table/types';
import { exploreSearchParamsService } from '../router/router';
import { exploreRouteHelpers } from '../router/router.helpers';
import { explorePreferencesStateService } from './app-state/explore-preferences-states.service';
import { fieldMappingsService } from './field-mappings.service';

const predefinedColumns: Record<string, ColumnDef<SearchResultRecord>> = {
  '@timestamp': timestampColumn,
  [ACCOUNTS_FIELD_NAME]: accountsColumn,
};

class TableColumnStateService {
  tableRef = new Observable<DataTableHandle | null>(null);
  state = new Observable<ColumnDef<SearchResultRecord>[]>([]);

  // Creating the initial columns for the skeleton
  getInitialColumns = () => {
    const { defaultColumns } = explorePreferencesStateService.state.get().generalSettings;
    const columns = exploreSearchParamsService.columns.get();

    const decodedColumns: Column[] = exploreRouteHelpers.getColumnArray(columns);

    const initialColumns: Column[] =
      decodedColumns.length > 0
        ? union([{ id: '@timestamp' }], decodedColumns)
        : union([{ id: '@timestamp' }], defaultColumns);

    return initialColumns.map(
      (column: Column): ColumnDef<SearchResultRecord> =>
        predefinedColumns[column.id] ?? buildColumn(column.id, column.size),
    );
  };

  buildColumnsFromFields = (fields: Column[]) => {
    const newColumns: ColumnDef<SearchResultRecord>[] = [];
    const groupByFieldName = exploreSearchParamsService.groupBy.get();

    fields.unshift({ id: '@timestamp', size: 225 });
    fields.forEach(field => {
      if (
        newColumns.some(
          column =>
            column.header === field.id || (column.header === groupByFieldName && field.id === GROUPBY_FIELD_NAME),
        )
      )
        return;

      let fieldName;

      if (field.id === GROUPBY_FIELD_NAME) {
        if (isNil(groupByFieldName)) return;

        fieldName = groupByFieldName;
      } else if (field.id === ACCOUNTS_FIELD_NAME) {
        fieldName = 'Accounts';
      } else {
        fieldName = field.id;
      }

      const { type } = fieldMappingsService.getField(field.id);
      const column = predefinedColumns[field.id] ?? buildColumn(fieldName, field.size, type);

      newColumns.push(column);
    });
    this.state.set(newColumns);
  };

  clear = () => {
    this.state.set([]);
  };
}

export const tableColumnStateService = new TableColumnStateService();
