import { LoggerService as Logger, eventEmitter, EVENTS, clearAllCache, httpService } from '@logz-pkg/frontend-services';
import { get } from 'lodash';
import { appRouter } from 'services/router/router.service';
import { clearAppStateAndSubscribers } from 'core/auth-flows';

const authFlags = { isLogoutInProgress: false };

const logout = async (stateOptions: any = {}, invalidateToken = true): Promise<void> => {
  if (authFlags.isLogoutInProgress || appRouter.globals.current?.data?.onlyAnonymous) {
    return;
  }

  const lastState = appRouter.globals.current;

  authFlags.isLogoutInProgress = true;

  try {
    if (lastState.name !== 'login') {
      await appRouter.stateService.go('dashboard.logout');
    }
  } catch (e) {
    Logger.logWarn({ message: "Couldn't log out", extra: e });
  }

  try {
    if (invalidateToken) await httpService.post('/logout');
  } finally {
    eventEmitter.emit(EVENTS.AUTH_LOGOUT);
    eventEmitter.emit(EVENTS.PRE_KILL_PRODUCT_IFRAMES);
    clearAllCache({ clearTokens: true });
    clearAppStateAndSubscribers();

    const { authenticate, adminOnly } = get(lastState, 'data', {});
    const isEmptyState = lastState.name === '';
    const isAuthenticatedRoute = authenticate || adminOnly;

    authFlags.isLogoutInProgress = false;

    if (isAuthenticatedRoute || isEmptyState || stateOptions.redirect) {
      appRouter.stateService.go('login', stateOptions);
    } else if (lastState.name !== 'login') {
      appRouter.stateService.go(lastState);
    }
  }
};

export const logoutService = { authFlags, logout };
