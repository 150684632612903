import { AccountType, Permissions } from '@logz-pkg/enums';
import { optimizerApiService, searchableAccountsApiService } from '@logz-pkg/frontend-services';
import { NotificationService } from '@logz-ui/styleguide';
import { ParamDeclaration, ResolvableLiteral, Transition } from '@uirouter/react';
import { AppStateDeclaration } from 'states/AppStateDecleration';
import { redirectIfFFDisabled, redirectIfNoPermission } from 'states/utils';
import { LazyReact } from 'ui/components/shared/LazyReact';

const queryParams = [
  'pageNumber',
  'pageSize',
  'sortBy',
  'sortOrder',
  'search',
  'createdBy',
  'updatedBy',
  'activeState',
] as const;

export const newOptimizerRouteNames = {
  definition: 'dashboard.triggers.optimizers',
  new: 'dashboard.triggers.optimizers-new',
  edit: 'dashboard.triggers.optimizers-edit',
};

const redirectIfNoEditTriggerPermission = redirectIfNoPermission({
  hasPermission: Permissions.TRIGGER,
});

const redirectIfNewOptimizersPageFFDisabled = redirectIfFFDisabled({ featureFlag: 'NewOptimizersPage' });

const timelessAccountResolveToken = 'redirectIfNoTimelessAccount';

const redirectIfNoTimelessAccount: ResolvableLiteral = {
  token: timelessAccountResolveToken,
  deps: ['$transition$'],
  resolveFn: async (transition: Transition) => {
    try {
      const timelessAccounts = await searchableAccountsApiService.getAccountsByType([AccountType.Timeless]);
      const hasTimelessAccount = timelessAccounts?.length > 0;

      if (!hasTimelessAccount) {
        NotificationService.warning({
          title: 'No Timeless account',
          message: 'You need to have a Timeless account to create/edit an optimizer.',
        });
        transition.router.stateService.go(newOptimizerRouteNames.definition);
      } else {
        return timelessAccounts;
      }
    } catch (err) {
      NotificationService.unexpectedError('Failed to fetch timeless accounts');
      throw new Error('Failed to fetch timeless accounts');
    }
  },
};

const editModelDefinitionToken = 'edittedOptimizerModelDefinition';

const editModelDefinitionResolver: ResolvableLiteral = {
  token: editModelDefinitionToken,
  deps: ['$transition$'],
  resolveFn: async (transition: Transition) => {
    const { id } = transition.params();

    if (!id) {
      transition.router.stateService.go(newOptimizerRouteNames.new);
      NotificationService.unexpectedError('No optimizer id provided');
      throw new Error('No optimizer id provided');
    }

    try {
      const optimizer = await optimizerApiService.get(Number(id));

      if (!optimizer) {
        transition.router.stateService.go(newOptimizerRouteNames.new);
        NotificationService.unexpectedError('No optimizer found');
        throw new Error('No optimizer found');
      }

      return optimizer;
    } catch (err) {
      transition.router.stateService.go(newOptimizerRouteNames.definition);
      NotificationService.unexpectedError('Failed to fetch optimizer');
      throw new Error('Failed to fetch optimizer');
    }
  },
};

export const defaultParams: Record<typeof queryParams[number], ParamDeclaration> = {
  pageNumber: { value: '1' },
  pageSize: { value: '10' },
  sortBy: { value: 'updatedAt' },
  sortOrder: { value: 'DESC' },
  search: { value: null },
  activeState: { value: null },
  createdBy: { value: [], array: true },
  updatedBy: { value: [], array: true },
};

export const newOptimizerRoutes: AppStateDeclaration[] = [
  {
    name: newOptimizerRouteNames.definition,
    url: `/optimizers?${queryParams.join('&')}`,
    params: defaultParams,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/Optimizers/Optimizers')}
        resolver={({ Optimizers }) => Optimizers}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      title: 'Optimizers',
      breadcrumb: { label: 'Optimizers' },
      saveParams: true,
    },
    resolve: [redirectIfNewOptimizersPageFFDisabled, redirectIfNoEditTriggerPermission],
    dynamic: true,
  },
  {
    name: newOptimizerRouteNames.new,
    url: '/optimizers/new',
    params: {
      query: { value: '*' },
      accountIds: { value: [], array: true },
    },
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/OptimizerForm/OptimizerForm')}
        resolver={({ OptimizerForm }) => OptimizerForm}
        timelessAccounts={props.transition.injector().get(timelessAccountResolveToken)}
        {...props}
      />
    ),
    data: {
      breadcrumb: { parent: newOptimizerRouteNames.definition, label: 'Create new optimizer' },
      saveParams: true,
      title: 'Create new optimizer',
    },
    dynamic: true,
    resolve: [redirectIfNewOptimizersPageFFDisabled, redirectIfNoEditTriggerPermission, redirectIfNoTimelessAccount],
  },
  {
    name: newOptimizerRouteNames.edit,
    url: '/optimizers/:id',
    params: { id: null },
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/OptimizerForm/OptimizerForm')}
        resolver={({ OptimizerForm }) => OptimizerForm}
        resolvedOptimizerModel={props.transition.injector().get(editModelDefinitionToken)}
        timelessAccounts={props.transition.injector().get(timelessAccountResolveToken)}
        {...props}
      />
    ),
    data: {
      breadcrumb: { parent: newOptimizerRouteNames.definition, label: 'Edit optimizer' },
      saveParams: true,
      title: 'Edit optimizer',
    },
    dynamic: true,
    resolve: [
      redirectIfNewOptimizersPageFFDisabled,
      redirectIfNoEditTriggerPermission,
      redirectIfNoTimelessAccount,
      editModelDefinitionResolver,
    ],
  },
];
