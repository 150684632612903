import { appStateService, favoriteApiService, sessionStateService } from '@logz-pkg/frontend-services';
import { BookmarkModel, GlobalSearchResultModel, SearchSource } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { ErrorHandlingService } from 'services/error-handling.service';

const objectIdsParser = {
  [SearchSource.OsdDashboard]: (dashboard: GlobalSearchResultModel) => {
    const link = dashboard.link || dashboard.state?.params?.resource;

    if (!link) return null;

    return link.split('view/')[1];
  },
  [SearchSource.GrafanaDashboard]: (dashboard: GlobalSearchResultModel) => {
    if (dashboard.link) {
      // "#/dashboard/metrics/d/UPXiBXfVz/new-dashboard-2"
      const [_hash, _dashboard, _metrics, _d, id, _name] = dashboard.link.split('/');

      return id;
    }

    const { uid, resource } = dashboard.state.params;

    if (uid) return uid;

    return resource.split('/')[1];
  },
  [SearchSource.UnifiedDashboard]: (dashboard: GlobalSearchResultModel) => {
    if (dashboard.state?.params?.dashboardId) return dashboard.state.params.dashboardId;

    if (dashboard.link) {
      const [path] = dashboard.link.split('?');
      const [_hash, _dashboard, _hub, _view, id] = path.split('/');

      return id;
    }
  },
};

export const getDashboardId = (dashboard: GlobalSearchResultModel) => {
  return objectIdsParser[dashboard.source]?.call(null, dashboard);
};

const STATE_NAME = 'bookmark';
const ERROR_TITLE = 'Failed bookmark operation';

class BookmarkStateService {
  bookmarks = new Observable<BookmarkModel[]>([]);
  private logzioStarred = new Observable<BookmarkModel[]>([]);
  private grafanaStarred = new Observable<BookmarkModel[]>([]);
  private isExist = ({ id, source, accountId }): boolean =>
    Boolean(source === SearchSource.GrafanaDashboard)
      ? this.grafanaStarred.get().findIndex(bookmark => bookmark.id === id) >= 0
      : this.logzioStarred.get().findIndex(bookmark => bookmark.id === id && bookmark.accountId === accountId) >= 0;

  fetch = async () => {
    const state = await appStateService.get(STATE_NAME);

    this.logzioStarred.set(state.bookmarks || []);

    const grafanaState = await favoriteApiService.getStarredGrafanaObjects();

    this.grafanaStarred.set(grafanaState);

    this.bookmarks.set([...this.logzioStarred.get(), ...this.grafanaStarred.get()]);
  };

  add = async ({ id, title, source, accountId }: BookmarkModel): Promise<void> => {
    if (this.isExist({ id, source, accountId })) {
      ErrorHandlingService.handleUiError({
        title: ERROR_TITLE,
        error: new Error('Bookmark already exists'),
      });

      return;
    }

    const { loggedInAccount } = sessionStateService.session.get();

    const newBookmark: BookmarkModel = {
      accountId: accountId || loggedInAccount.id,
      title,
      source,
      id,
    };

    try {
      if (source === SearchSource.GrafanaDashboard) {
        this.grafanaStarred.set([...this.grafanaStarred.get(), newBookmark]);
        await favoriteApiService.starGrafanaDashboard(newBookmark.id);
      } else {
        this.logzioStarred.set([...this.logzioStarred.get(), newBookmark]);
        await appStateService.set(STATE_NAME, { bookmarks: this.logzioStarred.get() });
      }
    } catch (error) {
      ErrorHandlingService.handleApiError({
        title: ERROR_TITLE,
        error,
      });
    }

    this.bookmarks.set([...this.logzioStarred.get(), ...this.grafanaStarred.get()]);
  };

  remove = async ({ source, id }): Promise<void> => {
    if (source === SearchSource.GrafanaDashboard) {
      this.grafanaStarred.set(this.grafanaStarred.get().filter(bookmark => bookmark.id !== id));
      await favoriteApiService.unStarGrafanaDashboard(id);
    } else {
      this.logzioStarred.set(this.logzioStarred.get().filter(bookmark => bookmark.id !== id));

      try {
        await appStateService.set(STATE_NAME, { bookmarks: this.logzioStarred.get() });
      } catch (error) {
        ErrorHandlingService.handleApiError({
          title: ERROR_TITLE,
          error,
        });
      }
    }

    this.bookmarks.set([...this.logzioStarred.get(), ...this.grafanaStarred.get()]);
  };

  clear = async () => {
    try {
      await appStateService.set(STATE_NAME, {});
      this.logzioStarred.set([]);
    } catch (error) {
      ErrorHandlingService.handleApiError({
        title: ERROR_TITLE,
        error,
      });
    }
  };
}

export const bookmarkStateService = new BookmarkStateService();
