import { AppMode, ProductKeys } from '@logz-pkg/enums';
import {
  logsEmptyStateRouteName,
  metricsEmptyStateRouteName,
  siemEmptyStateRouteName,
  tracingEmptyStateRouteName,
} from '../../../../../app/states/empty-states.routes';
import { homeDashboardRouteName } from '../../../../../app/states/home.routes';
import { integrationsRouteStates } from '../../../../../app/states/integrations/route-config';
import { observabilityRoutesNames } from '../../../../../app/states/observability/observability.routes.names';
import { switchModeRouteName } from '../../../../../app/states/switch-mode.routes';
import { IStateParams } from '../Sidenav/item-configs';
import { productModeMap, productParamMap, productStatesMap } from '../Sidenav/item-configs/products-states-map';
import { spmRouteNames } from 'states/spm.routes';
import { exploreRouteNames } from 'states/explore/explore.route';
import { dashboardsHubRouteName, viewDashboardRouteName } from 'states/dashboards-hub/dashboards-hub.routes';

const pageToNavigationProductState = {
  [siemEmptyStateRouteName]: ProductKeys.Siem,
  [homeDashboardRouteName]: ProductKeys.Home,
  [logsEmptyStateRouteName]: ProductKeys.Logs,
  [exploreRouteNames.explore]: ProductKeys.Explore,
  [observabilityRoutesNames.k8s360]: ProductKeys.K8S360,
  [tracingEmptyStateRouteName]: ProductKeys.Tracing,
  [metricsEmptyStateRouteName]: ProductKeys.Metrics,
  [integrationsRouteStates.page]: ProductKeys.Integrations,
  [integrationsRouteStates.collectors]: ProductKeys.Integrations,
  [integrationsRouteStates.content]: ProductKeys.Integrations,
  [integrationsRouteStates.fleet]: ProductKeys.Integrations,
  [spmRouteNames.servicesTable]: ProductKeys.App360,
  [spmRouteNames.servicesMap]: ProductKeys.App360,
  [spmRouteNames.serviceOverview]: ProductKeys.App360,
  [spmRouteNames.operationOverview]: ProductKeys.App360,
  [dashboardsHubRouteName]: ProductKeys.DashboardsHub,
  [viewDashboardRouteName]: ProductKeys.DashboardsHub,
};

const findMatchingParamsFromStateParams = (stateParams: IStateParams): ProductKeys[] => {
  const productOptions = new Set<ProductKeys>();
  const stateParamKeyValuePairs = Object.entries(stateParams);

  stateParamKeyValuePairs.forEach(keyValuePair => {
    if (!keyValuePair[1]) return;

    const paramObject = { [keyValuePair[0]]: keyValuePair[1] };
    const productsWithMatchingParams = productParamMap.get(JSON.stringify(paramObject));

    if (productsWithMatchingParams) {
      productsWithMatchingParams.forEach(product => productOptions.add(product));
    }
  });

  return Array.from(productOptions);
};

const matchingProductOrDefault = (products, appMode?) => {
  if (products.length === 1) return products[0];

  if (products.includes(ProductKeys.Settings)) return ProductKeys.Settings;

  return defaultProductForMode[appMode];
};

const defaultProductForMode = {
  [AppMode.OPERATIONS]: ProductKeys.Logs,
  [AppMode.SECURITY]: ProductKeys.Siem,
  [AppMode.NONE]: ProductKeys.None,
};

export const getProductForState = ({
  state,
  appMode,
  stateParams = {},
}: {
  state: string;
  appMode: AppMode;
  stateParams: IStateParams;
}): ProductKeys => {
  if (!appMode || !state) return ProductKeys.None;

  if (Object.keys(pageToNavigationProductState).includes(state)) {
    return pageToNavigationProductState[state];
  }

  if (state === switchModeRouteName && stateParams?.mode) {
    return defaultProductForMode[stateParams.mode];
  }

  const matchingStateProducts = Array.from(new Set(productStatesMap.get(state) || []));

  if (matchingStateProducts.length === 0) return defaultProductForMode[appMode];

  if (matchingStateProducts.length === 1) return matchingStateProducts[0];

  const matchingModeProducts = productModeMap.get(appMode) || [];

  // Returns true for { x: null, b: undefined, c: 'value' } but false for { x: undefined, b: null }
  const hasStateParams =
    stateParams &&
    !Object.values(stateParams).every(item => {
      return item === undefined || item === null;
    });

  if (hasStateParams) {
    const matchingParamProducts = findMatchingParamsFromStateParams(stateParams);
    const matchingStateAndParams = matchingStateProducts.filter(product => matchingParamProducts.includes(product));

    if (matchingStateAndParams.length === 1) {
      return matchingStateAndParams[0];
    }

    if (matchingStateAndParams.length === 0) {
      return defaultProductForMode[appMode];
    }

    const matchingStateModeAndParams = matchingStateAndParams.filter(product => matchingModeProducts.includes(product));

    return matchingProductOrDefault(matchingStateModeAndParams, appMode);
  }

  const matchingStateAndMode = matchingStateProducts.filter(product => matchingModeProducts.includes(product));

  return matchingProductOrDefault(matchingStateAndMode, appMode);
};
