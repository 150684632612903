import { announcementApiService } from '@logz-pkg/frontend-services';
import { Observable } from '@logz-pkg/observable';

export class WhatsNewStateService {
  readonly unreadAnnouncementsCount = new Observable<number>(0);
  readonly isWhatsNewOpen = new Observable<boolean>(false);
  clear = () => {
    this.unreadAnnouncementsCount.set(0);
    this.isWhatsNewOpen.set(false);
  };
  setUnreadAnnouncementsCount = this.unreadAnnouncementsCount.set;
  toggleWhatsNew = async () => {
    if (this.isWhatsNewOpen.get() && this.unreadAnnouncementsCount.get() > 0) {
      await announcementApiService.markRead();
      this.setUnreadAnnouncementsCount(0);
    }

    this.isWhatsNewOpen.set(!this.isWhatsNewOpen.get());
  };
}
export const whatsNewStateService = new WhatsNewStateService();
