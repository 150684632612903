import { UIView, useCurrentStateAndParams } from '@uirouter/react';
import { createElement } from 'react';
import { tracingRouteStates } from '../../../app/states/tracing/tracing-sampling.routes.names';
import { ErrorBoundary } from '../shared/ErrorBoundary/ErrorBoundary';
import { GlitchPage } from 'ui/components/AppProvider';
import { ProductUIView } from 'ui/components/App/ProductUIView';

export const DashboardComponent = () => {
  const { state } = useCurrentStateAndParams();

  const showState = (stateName: string) => (state.name ? state.name.includes(stateName) : false);

  return (
    <>
      <UIView
        render={(component, props) => (
          // Okay okay: We wrap the content of the UIView with an error boundary, and we make sure we update it when the route changes (props.key)
          <ErrorBoundary fallback={<GlitchPage />} errorContext={state.name} key={props.key}>
            {createElement(component, props)}
          </ErrorBoundary>
        )}
      />
      <ProductUIView name={'kibana'} show={showState('dashboard.osd')} />
      <ProductUIView name={'summary'} show={showState('dashboard.security-summary')} />
      <ProductUIView name={'research'} show={showState('dashboard.security-research')} />
      <ProductUIView name={'threats'} show={showState('dashboard.security-threats-overview')} />
      <ProductUIView name={'grafana'} show={showState('dashboard.metrics')} />
      <ProductUIView name={'jaeger'} show={showState(tracingRouteStates.jaeger)} />
    </>
  );
};
