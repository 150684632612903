import { ExplorePageGraphYAxisScaleTypes, ExploreTimeInterval, InputMode } from '@logz-pkg/enums';
import { Observable } from '@logz-pkg/observable';
import { DurationString, RelativeValue } from '@logz-pkg/utils';
import { TransitionOptions } from '@uirouter/react';
import { cloneDeep } from 'lodash';
import { DEFAULT_SORT, SURROUNDING_INITIAL_FETCH_AMOUNT } from '../constants';
import { ExploreModes } from '../types';
import { exploreRouteHelpers } from './router.helpers';
import { appRouter } from 'services/router/router.service';

export type ExploreSearchParams = {
  mode?: ExploreModes;
  query?: string | null;
  inputMode?: InputMode | null;
  yAxisScale?: ExplorePageGraphYAxisScaleTypes;
  timeInterval?: ExploreTimeInterval;
  accounts?: string[];
  isFilterOpen?: boolean;
  quickViewLogId?: string | null;
  columns?: string | null;
  groupBy?: string;
  compareTo?: DurationString;
  from?: RelativeValue | number;
  to?: 'now' | number;
  sort?: Exclude<string, '_source'>[];
  contextLogId?: string | null;
  contextBeforeAmount?: number;
  contextAfterAmount?: number;
  savedSearchId?: string;
};

// !!NOTE: URLParams still can't handle Objects
export type URLParams = {
  mode?: ExploreSearchParams['mode'];
  query?: ExploreSearchParams['query'];
  inputMode?: ExploreSearchParams['inputMode'];
  yAxisScale?: ExploreSearchParams['yAxisScale'];
  timeInterval?: ExploreSearchParams['timeInterval'];
  accounts?: ExploreSearchParams['accounts'];
  isFilterOpen?: ExploreSearchParams['isFilterOpen'];
  quickViewLogId?: ExploreSearchParams['quickViewLogId'];
  columns?: ExploreSearchParams['columns'];
  groupBy?: ExploreSearchParams['groupBy'];
  compareTo?: ExploreSearchParams['compareTo'];
  from?: ExploreSearchParams['from'];
  to?: ExploreSearchParams['to'];
  sort?: ExploreSearchParams['sort'];
  contextLogId?: string;
  contextBeforeAmount?: ExploreSearchParams['contextBeforeAmount'];
  contextAfterAmount?: ExploreSearchParams['contextAfterAmount'];
  savedSearchId?: ExploreSearchParams['savedSearchId'];
  switchToAccountId?: string;
};

export type PreTransformedParams = Partial<Record<keyof URLParams, string | string[] | null>>;

class ExploreSearchParamsService {
  mode = new Observable<ExploreSearchParams['mode']>('CLASSIC');
  query = new Observable<ExploreSearchParams['query']>(null);
  inputMode = new Observable<ExploreSearchParams['inputMode']>();
  yAxisScale = new Observable<ExploreSearchParams['yAxisScale']>(ExplorePageGraphYAxisScaleTypes.Linear);
  timeInterval = new Observable<ExploreSearchParams['timeInterval']>();
  accounts = new Observable<ExploreSearchParams['accounts']>([]);
  isFilterOpen = new Observable<ExploreSearchParams['isFilterOpen']>(true);
  quickViewLogId = new Observable<ExploreSearchParams['quickViewLogId']>(null);
  columns = new Observable<ExploreSearchParams['columns']>(null);
  groupBy = new Observable<ExploreSearchParams['groupBy']>(null);
  compareTo = new Observable<ExploreSearchParams['compareTo']>(null);
  from = new Observable<ExploreSearchParams['from']>('now-15m');
  to = new Observable<ExploreSearchParams['to']>('now');
  sort = new Observable<ExploreSearchParams['sort']>(DEFAULT_SORT);
  contextLogId = new Observable<ExploreSearchParams['contextLogId']>(null);
  contextBeforeAmount = new Observable<ExploreSearchParams['contextBeforeAmount']>(SURROUNDING_INITIAL_FETCH_AMOUNT);
  contextAfterAmount = new Observable<ExploreSearchParams['contextAfterAmount']>(SURROUNDING_INITIAL_FETCH_AMOUNT);
  savedSearchId = new Observable<ExploreSearchParams['savedSearchId']>(null);

  intialized = new Observable<boolean>(false);

  clear = () => {
    this.mode.set('CLASSIC');
    this.query.set(null);
    this.inputMode.set(null);
    this.yAxisScale.set(null);
    this.timeInterval.set(null);
    this.accounts.set([]);
    this.isFilterOpen.set(true);
    this.quickViewLogId.set(null);
    this.columns.set(null);
    this.compareTo.set(null);
    this.groupBy.set(null);
    this.from.set('now-15m');
    this.to.set('now');
    this.sort.set(DEFAULT_SORT);
    this.contextLogId.set(null);
    this.contextBeforeAmount.set(null);
    this.contextAfterAmount.set(null);
    this.savedSearchId.set(null);
  };
}

export const exploreSearchParamsService = new ExploreSearchParamsService();

type Params = Record<keyof ExploreSearchParams, string | string[] | number | boolean | null>;

const getProcessedParams = (params: Partial<Params>): Partial<Params> => {
  const { groupBy, query, columns: nextColumns } = params;
  const processedParams = cloneDeep(params);

  if (query !== undefined) {
    processedParams.query = query ? query : null;
  }

  if (groupBy !== undefined) {
    const columnsToCompare = nextColumns ?? exploreSearchParamsService.columns.get();
    const { columns, groupBy: newGroupBy } = exploreRouteHelpers.getGroupByParams(
      groupBy as string,
      columnsToCompare as string,
    );

    processedParams.columns = columns;
    processedParams.groupBy = newGroupBy;
  }

  return processedParams;
};

export const setExploreSearchParam = (params: Partial<Params>, options?: TransitionOptions) => {
  const processedParams = getProcessedParams(params);

  const currentState = '.';

  if (options?.location === 'replace') {
    const replacedUrl = appRouter.stateService.href(currentState, processedParams, { inherit: options.inherit ?? true });

    window.history.replaceState(null, '', replacedUrl);
    appRouter.stateService.go(currentState, processedParams, { ...options, location: false });
  } else {
    appRouter.stateService.go(currentState, processedParams, options);
  }
};
