import { IJsonFormatterProps, MenuItems, NotificationService } from '@logz-ui/styleguide';
import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { analyticsService } from 'services/analytics/analytics.service';
import { exploreSearchParamsService, setExploreSearchParam } from 'ui/components/Explore/router/router';
import { urlParamsService } from 'ui/components/Explore/services/url-params.service';
import { filtersStateService } from 'ui/components/Explore/state/filters-state.service';
import { exploreUtils } from 'ui/components/Explore/utils/explore-utils';
import { useFeatureFlagState } from 'ui/state/hooks';
import { exploreRouteHelpers } from 'ui/components/Explore/router/router.helpers';

export const useContextMenuItems = (value: Record<string, any>, shouldDisplayMenu: boolean) => {
  const { isFeatureEnabled: isExploreGroupByEnabled } = useFeatureFlagState('ExploreGroupBy');

  const getPropertyContextMenu: IJsonFormatterProps['getPropertyContextMenu'] = useCallback(
    item => {
      if (!shouldDisplayMenu) return;

      const currentFilters = filtersStateService.state.get();
      const groupBy = exploreSearchParamsService.groupBy.get();
      const columns = exploreSearchParamsService.columns.get();
      const decodedColumns = urlParamsService.urlParamToColumns(columns);

      const fieldExistsInGraph = item.property === groupBy;
      const filterExists = exploreUtils.filterExists({ currentFilters, id: item.property, type: 'EXIST' });
      const filterExistsNegate = exploreUtils.filterExists({
        currentFilters,
        id: item.property,
        type: 'EXIST',
        negate: true,
      });
      const isInTable = decodedColumns.some(col => col.id === item.property);
      const menuItems: MenuItems = [];

      if (!filterExists) {
        menuItems.push({
          icon: 'circle-plus-regular',
          label: 'Filter in',
          onClick: item => {
            analyticsService.capture('Explore table', 'Filter in field', { field: item.property });

            return exploreUtils.filterForFieldExistence({ fieldName: item.property, negate: false });
          },
        });
      }

      if (!filterExistsNegate) {
        menuItems.push({
          icon: 'circle-minus-regular',
          label: 'Filter out',
          onClick: item => {
            analyticsService.capture('Explore table', 'Filter out field', { field: item.property });

            return exploreUtils.filterForFieldExistence({ fieldName: item.property, negate: true });
          },
        });
      }

      if (filterExists || filterExistsNegate) {
        menuItems.push({
          icon: 'regular-filter-slash-custom',
          label: 'Remove filter',
          onClick: item => {
            analyticsService.capture('Explore table', 'Removed filter field', {
              field: item.property,
            });

            return exploreUtils.filterForFieldExistence({ fieldName: item.property, negate: false });
          },
        });
      }

      menuItems.push({
        icon: isInTable ? 'regular-table-columns-slash-custom' : 'table-columns-regular',
        label: isInTable ? 'Remove from table' : 'Add to table',
        onClick: item => {
          analyticsService.capture('Explore table', isInTable ? `Removed field from table` : `Added field to table`, {
            field: item.property,
          });

          if (isInTable) {
            const nextColumns = decodedColumns.filter(col => col.id !== item.property);

            setExploreSearchParam({ columns: urlParamsService.columnsToUrlParam(nextColumns) });
          } else {
            const nextColumns = [...decodedColumns, { id: item.property }];

            setExploreSearchParam({ columns: urlParamsService.columnsToUrlParam(nextColumns) });
          }
        },
      });

      if (isExploreGroupByEnabled) {
        menuItems.push({
          icon: fieldExistsInGraph ? 'regular-chart-simple-slash-custom' : 'chart-simple-regular',
          label: fieldExistsInGraph ? 'Remove from graph' : 'Group in graph',
          onClick: item => {
            analyticsService.capture('Explore table', fieldExistsInGraph ? 'Removed from graph' : 'Grouped in graph', {
              field: item.property,
            });

            const groupByParams = exploreRouteHelpers.getGroupByParams(
              fieldExistsInGraph ? null : item.property,
              columns,
            );

            setExploreSearchParam(groupByParams);
          },
        });
      }

      return { menuItems } as ReturnType<IJsonFormatterProps['getPropertyContextMenu']>;
    },
    [isExploreGroupByEnabled],
  );

  const getValueContextMenu: IJsonFormatterProps['getPropertyContextMenu'] | undefined = useCallback(item => {
    if (!shouldDisplayMenu) return;

    const currentFilters = filtersStateService.state.get();

    const regularFilterExists = currentFilters.find(
      filter =>
        filter.field &&
        filter.field.id === item.property &&
        filter.negate === false &&
        filter.type === 'IS_ONE_OF' &&
        ((filter.value as string[]).includes?.(item.value) || filter.value === item.value),
    );
    const regularFilterExistsNegate = currentFilters.find(
      filter =>
        filter.field &&
        filter.field.id === item.property &&
        filter.negate === true &&
        filter.type === 'IS_ONE_OF' &&
        ((filter.value as string[]).includes?.(item.value) || filter.value === item.value),
    );

    const menuItems = [];

    if (!regularFilterExists) {
      menuItems.push({
        icon: 'circle-plus-regular',
        label: 'Filter in',
        onClick: item => {
          exploreUtils.filterInOutValues(item.property, item.value);
          analyticsService.capture('Explore table', 'Filter in value', {
            field: item.property,
          });
        },
      });
    }

    if (!regularFilterExistsNegate) {
      menuItems.push({
        icon: 'circle-minus-regular',
        label: 'Filter out',
        onClick: item => {
          exploreUtils.excludeValue(item.property, item.value);
          analyticsService.capture('Explore table', 'Filter out value', {
            field: item.property,
          });
        },
      });
    }

    if (regularFilterExists || regularFilterExistsNegate) {
      menuItems.push({
        icon: 'regular-filter-slash-custom',
        label: 'Remove filter',
        onClick: item => {
          exploreUtils.filterInOutValues(item.property, item.value);
          analyticsService.capture('Explore table', 'Removed filter value', {
            field: item.property,
          });
        },
      });
    }

    menuItems.push({
      icon: 'copy-regular',
      label: 'Copy to clipboard',
      onClick: item => {
        copy(item.value);
        NotificationService.success({ title: 'Copied!' });
        analyticsService.capture('Explore table', 'Copied to clipboard specific value');
      },
    });

    return { menuItems } as ReturnType<IJsonFormatterProps['getPropertyContextMenu']>;
  }, []);

  return { getPropertyContextMenu, getValueContextMenu };
};
