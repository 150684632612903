import { colorService, KibanaQueryUtils, urlParser } from '@logz-pkg/utils';
import { NotificationService } from '@logz-ui/styleguide';
import {
  authService,
  dateService,
  globalSearchEvents,
  httpService,
  insightsEvents,
  kibanaAnalyticsService,
  opensearchApiService,
  patternsEvents,
  searchableIndexesService,
  searchedIndexesService,
  sessionIdService,
  userService,
  LoggerService,
  numberService,
  eventEmitter,
  EVENTS,
  sharedObjects,
  discoverInsightsApiService,
  logPatternsApiService,
  couponsApiService,
  sessionStateService,
  textToLuceneApiService,
  lookupListsApiService,
} from '@logz-pkg/frontend-services';
import { kibanaFilters as kibanaFiltersService } from '@logz-pkg/models';
import { HttpStatusCode } from '@logz-pkg/enums';
import { overrideFetch } from '../modules/override-fetch';
import { overrideJaegerFetch } from '../modules/override-jaeger-fetch';
import { overrideGrafanaFetch } from '../modules/override-grafana-fetch';
import { kibanaThemeService } from '../app/osd/osd-theme.service';
import { kibanaRouteService } from '../app/osd/osd-route.service';
import { grafanaRouteService } from '../app/states/grafana/route-util';
import { tracingRouteService } from '../app/states/tracing/route-util';
import { userTokensService } from '../app/dashboard/settings/api-tokens/user-tokens.service';
import { logzPatterns } from '../modules/patterns';
import { windowErrorHandler } from '../hooks/error.hooks';
import { insightsService } from '../app/dashboard/insights/insights.service.js';
import { LogzioFileSaver } from './file-saver/file-saver.service';
import { elasticFieldTypeService } from './elastic-field-types';
import { elasticSearchService } from './elastic-search/elastic-search.service';
import { notificationEndpointService } from './common/notification-endpoint.service';
import { IntercomService } from './common/intercom.service';
import { fullStoryService } from './fullstory/fullstory.service';
import { analyticsService } from './analytics/analytics.service';
import { shareUrlService } from './common/share-url.service';
import { confirmLeaveChangesService } from './confirm-leave-changes/confirm-leave-changes.service';
import { userPermissionsService } from './user-permissions/user-permissions.service';
import { ErrorHandlingService } from './error-handling.service';
import { jaegerApiService } from './global-search/jaeger-api.service';
import { logzAlertApiService } from './global-search/logz-alert-api.service';
import { metricsAlertsApiService } from './global-search/metrics-alerts-api.service';
import { platformSearchHistoryApiService } from './global-search/platform-search-history-api.service';
import { triggerWizardService } from 'services/trigger-wizard/trigger-wizard.service';
import { appRouter } from 'services/router/router.service';
import { liveTailStateService } from 'ui/state/livetail.state.service';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { drilldownService } from 'ui/components/DrilldownSettings/drilldown.service';

export const services = {
  authService,
  lookupListsApiService,
  colorService,
  sessionIdService,
  ErrorHandlingService,
  LogzioFileSaver,
  elasticFieldTypeService,
  elasticSearchService,
  dateService,
  notificationEndpointService,
  IntercomService,
  LoggerService,
  NotificationService,
  numberService,
  analyticsService,
  fullStoryService,
  AppModes,
  kibanaAnalyticsService,
  patternsEvents,
  httpService,
  insightsEvents,
  globalSearchEvents,
  searchableIndexesService,
  searchedIndexesService,
  overrideFetch,
  overrideJaegerFetch,
  overrideGrafanaFetch,
  shareUrlService,
  sharedObjects,
  userService,
  eventEmitter,
  EVENTS,
  confirmLeaveChangesService,
  kibanaThemeService,
  kibanaRouteService,
  userPermissionsService,
  userTokensService,
  logzPatterns,
  urlParser,
  KibanaQueryUtils,
  HttpStatusCode,
  windowErrorHandler,
  appRouter,
  sessionStateService,
  unifiedFiltersStateService: {
    unifiedFilters: { subscribe: () => {} },
    unifiedVariables: { subscribe: () => {} },
    isEnabled: { subscribe: () => {} },
  },
  unifiedFiltersService: {
    grafana: {
      getUnifiedFilterAdHocVariables: () => {
        return [];
      },
    },
  },
  grafanaRouteService,
  tracingRouteService,
  opensearchApiService,
  liveTailStateService,
  drilldownService,
  insightsService,
  appModeService,
  kibanaFiltersService,
  discoverInsightsApiService,
  logPatternsApiService,
  triggerWizardService,
  couponsApiService,
  textToLuceneApiService,
  jaegerApiService,
  platformSearchHistoryApiService,
  metricsAlertsApiService,
  logzAlertApiService,
};
