import React from 'react';
import type { ChartCallbackFunction, Options } from 'highcharts';
import { isNil } from 'lodash';
import { Box, Spinner } from '../../../../components';
import { highchartsLazyLoadService } from './lazy-load';

let highcharts: typeof import('highcharts') | undefined;
let HighchartsReact: React.ComponentType<any> | undefined;

const configureHighchartsReact = async () => {
  if (HighchartsReact) return HighchartsReact;

  const HighchartsReactModule = await import('highcharts-react-official');

  HighchartsReact = HighchartsReactModule.default;

  return HighchartsReact;
};

type HighchartsProps = {
  options: Options;
  subject?: string;
  callback?: ChartCallbackFunction;
};

export const Highcharts: React.FC<HighchartsProps> = ({ subject, ...props }) => {
  const [HighchartsInstance, setHighchartsInstance] = React.useState<typeof import('highcharts') | undefined>(
    highcharts,
  );
  const [isHighchartsReactReady, setIsHighchartsReactReady] = React.useState(
    !isNil(highcharts) && !isNil(HighchartsReact),
  );

  React.useEffect(() => {
    if (!isNil(highcharts) && !isNil(HighchartsReact)) return;

    Promise.all([highchartsLazyLoadService.lazyLoadHighcharts(), configureHighchartsReact()]).then(
      ([HighchartsInstance]) => {
        highcharts = HighchartsInstance;
        setHighchartsInstance(HighchartsInstance);
        setIsHighchartsReactReady(true);
      },
    );
  }, []);

  if (!HighchartsInstance || !isHighchartsReactReady) {
    return <Spinner fullscreen />;
  }

  return (
    <Box subject={subject}>{HighchartsReact && <HighchartsReact highcharts={HighchartsInstance} {...props} />}</Box>
  );
};
