import { Origin } from '@logz-pkg/enums';
import { indexDbService, LoggerService } from '@logz-pkg/frontend-services';
import isNil from 'lodash/isNil';

export type CacheKeyPrefix = 'exploreWarmTierCache';

export const createHash = async (obj: Record<string, any>) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(JSON.stringify(obj));

  let hash: string;

  try {
    const hashBuffer = await window.crypto.subtle.digest('SHA-1', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array

    hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  } catch (error) {
    LoggerService.logError({ origin: Origin.APP, message: 'Cannot create an encrypted hash', error });

    hash = btoa(JSON.stringify(obj)).slice(0, 40);
  }

  return hash;
};

const warmTierStore = indexDbService.createStore('warm-tier', 'cache');

type CacheStoreDataFormat<T> = {
  response: T;
  timestamp: number;
};

export const cacheByPayload = async <T>({
  fn,
  args,
  shouldCache = false,
  keyPrefix,
  customKeyObject,
}: {
  fn: Function;
  args: Array<any>;
  shouldCache: boolean;
  keyPrefix: CacheKeyPrefix;
  customKeyObject?: Record<string, any>;
}): Promise<T & { isCached?: boolean }> => {
  if (shouldCache === false) {
    return fn(...args);
  }

  const cacheKey = `${keyPrefix}-${await createHash(customKeyObject === undefined ? args : customKeyObject)}`;
  const cachedData = await indexDbService.get<CacheStoreDataFormat<T>>(cacheKey, warmTierStore);

  if (isNil(cachedData)) {
    const response = await fn(...args);

    await indexDbService.set(cacheKey, { response, timestamp: Date.now() }, warmTierStore);

    return response;
  }

  return { ...(cachedData as CacheStoreDataFormat<T>).response, isCached: true };
};
