import { isObject } from 'lodash';
import type { EventName, FeatureName } from '@logz-pkg/analytics';
import {
  accountService,
  dateService,
  Env,
  httpService,
  sessionApiService,
  sessionIdService,
  sessionStateService,
  cacheProvider,
} from '@logz-pkg/frontend-services';
import { planDetailsModelManipulator } from '@logz-pkg/models';
import { impersonationService } from 'services/identity/impersonation/impersonation.service';

const ANALYTICS_IDENTIFY_CACHE_KEY = 'analytics-identified-accounts';

export const getIsAccountDisabled = async (): Promise<boolean> => {
  const {
    amplitude: { isDisabled },
  } = (await sessionApiService.get()).integrations;

  return isDisabled;
};

export const getAccountFormattedCreateDate = (createdDate: Date): string =>
  dateService.format({ date: createdDate }).toString();

export const reportInternalEvent = async <F extends FeatureName>(
  featureName: F,
  eventName: EventName<F>,
  eventData: Record<string, any>,
) => {
  if (!Env.configs.reportUserAnalytics) return;

  const userSummary = await getUserProps();

  const data = {
    eventName: formatEventName(featureName, eventName),
    eventData: isObject(eventData) ? JSON.stringify(eventData) : eventData,
    timestamp: new Date().toISOString(),
    userSummary,
  };

  httpService.post('/user-analytics', data, { dontShowProgressBar: true });
};

export const enrichEventData = (featureName: FeatureName, originalEventProps: Record<string, any>) => {
  const { loggedInUser, loggedInAccount } = sessionStateService.session.get();

  return {
    ...originalEventProps,
    featureName,
    userId: loggedInUser.id,
    accountId: loggedInAccount.id,
    sessionId: sessionIdService.getSessionId(),
    isImpersonating: impersonationService.isImpersonating(),
  };
};

export const getUserProps = async () => {
  const { loggedInUser, userSummary } = sessionStateService.session.get();
  const [, emailDomain] = userSummary.username.split('@');
  const userAccounts = await accountService.getUserAccounts();

  return {
    ...userSummary,
    emailDomain,
    userAccounts,
    accountCreatedDate: getAccountFormattedCreateDate(userSummary.accountCreatedDate),
    role: loggedInUser.role,
  };
};

export const getAccountProps = () => {
  const { userSummary, loggedInAccount, plan } = sessionStateService.session.get();

  return {
    name: loggedInAccount.name,
    accountId: plan.accountId,
    accountType: loggedInAccount.type,
    accountPlanType: plan.planType,
    planStartDate: planDetailsModelManipulator.getFormattedStartDate(plan),
    accountGB: plan.maxDailyGb,
    accountRetentionDays: plan.retentionDays,
    accountRegion: userSummary.accountRegion,
    accountCreatedDate: getAccountFormattedCreateDate(userSummary.accountCreatedDate),
  };
};

export const isUserAccountIdentified = () => {
  const { loggedInAccount } = sessionStateService.session.get();
  const identifiedAccounts = (cacheProvider.getPersistent(ANALYTICS_IDENTIFY_CACHE_KEY) as number[]) || [];

  if (identifiedAccounts.includes(loggedInAccount.id)) return true;

  identifiedAccounts.push(loggedInAccount.id);
  cacheProvider.setPersistent(ANALYTICS_IDENTIFY_CACHE_KEY, identifiedAccounts);

  return false;
};

export const formatEventName = <F extends FeatureName>(featureName: F, eventName: EventName<F>) => {
  return featureName === 'Global' ? eventName : `${eventName} -in- ${featureName}`;
};
