import { NotificationService } from '@logz-ui/styleguide';
import {
  authService,
  LoggerService,
  sessionStateService,
  announcementApiService,
  telemetryDataStatusService,
} from '@logz-pkg/frontend-services';
import { bootstrapKibanaConfig } from '../app/osd/osd-settings.service';
import { kibanaVersion } from '../app/osd/versions/osd-versions.strategy';
import { removeForbiddenIDBDbs } from './remove-forbidden-idb-dbs';
import { accountPlanStateService } from 'ui/state/account-plan.state.service';
import { whatsNewStateService } from 'ui/state/whats-new.state.service';
import { welcomeStateService } from 'ui/state/welcome.state.service';
import { apiGatewayService } from 'services/common/apiGateway.service';
import { IntercomService } from 'services/common/intercom.service';
import { tourService } from 'services/tours/tours';
import { GlobalNotificationService } from 'services/ui-services/global-notification/global-notification.service';
import { themeService } from 'services/theme/theme.service';
import { sessionExpiration } from 'services/errors/logz-session-expiration';
import { recentVisitsStateService } from 'ui/state/recent-visits.state.service';
import { m3dbAccountsStateService } from 'ui/resource-hooks/m3db-accounts/m3db-accounts.hook';
import { integrationsHubStateService } from 'ui/state/integrations-hub.state.service';
import { breadcrumbsService } from 'ui/state/breadcrumbs.state.service';
import { landingPageAppStateService } from 'ui/state/landing-page/landing-page.app-state.service';
import { exploreSearchParamsService } from 'ui/components/Explore/router/router';
import { savedObjectsService } from 'ui/components/Explore/state/saved-objects.service';
import { exploreFavoritesStatesService } from 'ui/components/Explore/state/app-state/explore-favorites-states.service';
import { exploreOnboardingStatesService } from 'ui/components/Explore/state/app-state/explore-onboarding-states.service';
import { exploreRecentsStatesService } from 'ui/components/Explore/state/app-state/explore-recents-states.service';
import { explorePreferencesStateService } from 'ui/components/Explore/state/app-state/explore-preferences-states.service';
import { searchableAccountsService } from 'ui/components/Explore/state/searchable-accounts.service';
import { logsStateService } from 'ui/components/Explore/state/logs-state.service';
import { queryService } from 'ui/components/Explore/state/query/query.service';
import { tableColumnStateService } from 'ui/components/Explore/state/table-columns.service';
import { luceneInputStateService } from 'ui/components/Explore/state/lucene-input-state.service';
import { inputStateService } from 'ui/components/Explore/state/input-state.service';
import { graphExpandedStateService } from 'ui/components/Explore/state/graph-expanded-state.service';
import { filtersStateService } from 'ui/components/Explore/state/filters-state.service';
import { fieldMappingsService } from 'ui/components/Explore/state/field-mappings.service';
import { exportLogsStateService } from 'ui/components/Explore/state/export-logs-state.service';
import { exceptionsService } from 'ui/components/Explore/state/exceptions.service';
import { dataCollectionService } from 'ui/components/Explore/state/data-collection.service';
import { graphStateService } from 'ui/components/Explore/state/graph-state.service';
import { exploreGraphEventsService } from 'ui/components/Explore/state/explore-graph-events.service';
import { urlParamsStateService } from 'ui/state/url-params.state.service';
import { tracingService } from 'services/tracing/tracing.service';
import { dashboardsStateService } from 'ui/components/DashboardsHub/state/dashboards-state.service';
import { dashboardsService } from 'ui/components/DashboardsHub/state/dashboards.service';

const baseSharedModeAuthFlow = async () => {
  await sessionStateService.fetch();
};

export const loginFlow = async ({ isAdminPanel = false } = {}) => {
  if (authService.isAuthenticated()) {
    await baseAuthFlow({ isAdminPanel });
  } else if (authService.hasShareToken()) {
    await baseSharedModeAuthFlow();
  }

  if (!isAdminPanel) await bootstrapKibanaConfig();
};

const clearObservableStates = ({ clearSessionState = true }: { clearSessionState?: boolean } = {}) => {
  accountPlanStateService.clear();
  m3dbAccountsStateService.clear();
  integrationsHubStateService.clear();
  breadcrumbsService.clear();
  whatsNewStateService.clear();
  telemetryDataStatusService.clear();
  urlParamsStateService.clear();

  // Explore
  exploreSearchParamsService.clear();
  queryService.clear();
  logsStateService.clear();
  graphStateService.clear();
  savedObjectsService.clear();
  exploreOnboardingStatesService.clear();
  exploreRecentsStatesService.clear();
  explorePreferencesStateService.clear();
  exploreFavoritesStatesService.clear();
  exploreGraphEventsService.clear();
  searchableAccountsService.clear();
  tableColumnStateService.clear();
  luceneInputStateService.clear();
  graphExpandedStateService.clear();
  inputStateService.clear();
  filtersStateService.clear();
  fieldMappingsService.clear();
  exportLogsStateService.clear();
  exceptionsService.clear();
  dataCollectionService.clear();

  // Dashboards hub
  dashboardsStateService.clear();
  dashboardsService.clear();

  if (clearSessionState) {
    sessionStateService.clear();
  }
};

const clearSubscribers = () => {
  apiGatewayService.stopApiGatewayCookieInterval();
  GlobalNotificationService.stopNotifications();
  NotificationService.destroy();
  welcomeStateService.stopWelcomeFlow();
  IntercomService.shutdown();
};

export const clearAppStateAndSubscribers = () => {
  clearSubscribers();
  clearObservableStates();
  themeService.clearTheme();
};

export const switchModeFlow = async () => {
  clearObservableStates({ clearSessionState: false });
  clearSubscribers();

  await baseAuthFlow();
};

const baseAuthFlow = async ({ isAdminPanel = false } = {}) => {
  sessionExpiration.expirationLog.reset();
  tracingService.updateTracingStatus();

  await Promise.all([sessionStateService.fetch()]);
  await Promise.all([
    kibanaVersion.init({ forceUpdate: true }),
    welcomeStateService.activate(),
    recentVisitsStateService.fetch(),
    exploreOnboardingStatesService.get(),
    exploreRecentsStatesService.get(),
    explorePreferencesStateService.get(),
    exploreFavoritesStatesService.get(),
  ]);

  removeForbiddenIDBDbs();

  apiGatewayService.startApiGatewayCookieInterval();

  if (!isAdminPanel) {
    const { userSettings } = sessionStateService.session.get();

    // Don't wait for these promises, as they may delay the login process
    Promise.all([
      themeService.setDarkReaderForTheme(userSettings.logzioTheme),
      IntercomService.register(),
      tracingService.updateTracingStatus(),
      tourService.startSession({ isMainWindow: true }),
      landingPageAppStateService.get({ force: true }),
      // Must be after when the feature-flags are available.
      announcementApiService
        .metadata()
        .then(({ unreadAnnouncementsCount }) =>
          whatsNewStateService.setUnreadAnnouncementsCount(unreadAnnouncementsCount),
        ),
    ])
      .then(() => GlobalNotificationService.startNotificationPolling())
      .catch(error => {
        LoggerService.logError({
          message: `Auth flow activations - ${error?.message}`,
          error,
        });
      });
  }
};
