import React from 'react';
import { Group, Text } from '@logz-ui/styleguide';
import { UtcTag } from './components/UtcTag';

export const DateHeader: React.FC = () => {
  return (
    <Group alignItems={'center'} fullWidth>
      <Text>Time</Text>
      <UtcTag />
    </Group>
  );
};
