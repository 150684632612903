import { useMemo } from 'react';
import { Options } from 'highcharts';
import { IGetOptionsProps } from '../../../types';

type UseTimeOptions = {
  timezone: IGetOptionsProps['timezone'];
};

export const useTimeOptions = ({ timezone }: UseTimeOptions) => {
  const timeOptions: Options['time'] = useMemo(() => ({ timezone }), [timezone]);

  return timeOptions;
};
