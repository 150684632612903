import { Box, JsonFormatter } from '@logz-ui/styleguide';
import type { ColumnDef, Row } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { SearchHit } from '@opensearch-project/opensearch/api/types';
import { useContextMenuItems } from '../hooks/context-menu-items.hook';
import { SearchResultRecord } from '../../Table/types';
import { getHighlightedText } from '../hooks/highlight.hook';
import { useCellProps } from './cell-props.hook';

const getHighlightObject = (highlight: SearchHit['highlight'], value: Record<string, any>) => {
  if (!highlight) return undefined;

  return Object.entries(highlight).reduce<Record<string, string | React.JSX.Element[]>>(
    (acc, [highlightKey, highlightValue]) => {
      acc[highlightKey] = getHighlightedText({ highlight: highlightValue, value: value[highlightKey] });

      return acc;
    },
    {},
  );
};

interface ISourceColumnProps {
  value: Record<string, any>;
  row?: Row<SearchResultRecord>;
  columnDef?: ColumnDef<SearchResultRecord>;
  subject?: string;
  maxLines?: number;
  shouldDisplayMenu?: boolean;
}

export const SourceCell: React.FC<ISourceColumnProps> = ({
  value,
  row,
  columnDef,
  subject = '',
  maxLines: externalMaxLines,
  shouldDisplayMenu = true,
}) => {
  const { getPropertyContextMenu, getValueContextMenu } = useContextMenuItems(value, shouldDisplayMenu);
  const { maxLines, isExpanded, rowHeight } = useCellProps({ columnDef, row });
  const highlight = useMemo(() => getHighlightObject(row?.original.highlight, value), [row?.original.highlight, value]);

  const getMaxLines = () => {
    if (isExpanded) return undefined;

    return externalMaxLines ?? maxLines;
  };

  return (
    // -2px margin to fix the height alighnment caused by the Json formatter tag component
    <Box mt={'-2px'} height={!isExpanded ? rowHeight : undefined} minHeight={rowHeight} width={'100%'}>
      <JsonFormatter
        maxLines={getMaxLines()}
        getPropertyContextMenu={getPropertyContextMenu}
        getValueContextMenu={getValueContextMenu}
        delimiter={isExpanded ? <br /> : ' '}
        json={value}
        subject={subject}
        highlight={highlight}
      />
    </Box>
  );
};
