import _ from 'lodash';
import { colorService } from '@logz-pkg/utils';
import { numberService } from '@logz-pkg/frontend-services';

export class InsightModel {
  constructor(options) {
    // Options should reflect the response received from the BE
    Object.assign(this, options); // Support old insight form from getInsightsByIds
    this.id = options.id;
    this.title = options.title;
    this.subtitle = _.get(options.additionalData, 'subTitleName', '');
    this.description = options.description;
    this.assignee = options.assignee;
    this.firstOccurrence = options.firstOccurrence;
    this.lastOccurrence = options.lastOccurrence;
    this.helpful = options.helpful;
    this.links = options.links;
    this.logTypes = options.logTypes;
    this.occurrences = options.occurrences;
    this.occurrencesHumanReadable = numberService.format(this.occurrences, {
      notation: 'compact',
      maximumFractionDigits: 2,
    });
    this.severity = options.severity;
    this.severityLastEditedBy = options.severityLastEditedBy;
    this.severityLastEditedDate = options.severityLastEditedDate;
    this.status = options.status;
    this.previousStatus = this.status;
    this.tagName = options.tagName;
    this.textLastEditedBy = options.textLastEditedBy;
    this.textLastEditedDate = options.textLastEditedDate;
    this.type = options.type;
    this.sparkline = options.sparkline || [];
    this.sparklineChartConfig = this._getOccurrencesChartConfig(this);
    this.exception = _.get(options.additionalData, 'exception', '');
    this.color = colorService.convertStringToRGBA(this.id);
  }

  _getOccurrencesChartConfig(insight) {
    const sparklineConfig = {
      color: '#a3a3a3',
      areaColor: '#dddddd',
      lineWidth: 2,
    };

    return {
      chart: {
        margin: [4, 8, 4, 8],
        type: 'area',
        backgroundColor: null,
        style: {
          overflow: 'visible',
        },
      },
      title: false,
      legend: false,
      xAxis: {
        visible: false,
        type: 'datetime',
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        style: {
          width: 'auto',
        },
        xDateFormat: '%Y-%m-%d %H:%M',
        pointFormat: '{point.y} occurrences',

        // Position the tooltip above the point, and not constrained in the chart box
        positioner(w, h, point) {
          return { x: point.plotX - w / 2, y: point.plotY - h };
        },
      },
      credits: false,
      plotOptions: {
        series: {
          lineWidth: sparklineConfig.lineWidth,
          fillColor: sparklineConfig.areaColor,
          color: sparklineConfig.color,
          states: {
            hover: {
              // The lines are widened by 1, so we prevent that functionality
              lineWidth: sparklineConfig.lineWidth,
            },
          },
          marker: {
            radius: 2,
            states: {
              hover: {
                radius: 3,
              },
            },
          },
          fillOpacity: 0.25,
        },
      },
      series: [
        {
          data: this._generateDataForOccurrencesChart(insight.sparkline),
        },
      ],
    };
  }

  _generateDataForOccurrencesChart(occurrencesData) {
    return Object.keys(occurrencesData)
      .sort()
      .map(timestamp => ({ x: +timestamp, y: occurrencesData[timestamp] }));
  }

  /**
   * Since the assignee we initially get is the id, we need to lazily translate it to an object with name as well.
   * But for that, we need the pool or people available to be passed to us.
   * @param srcCollection
   */
  getAssigneeObject(srcCollection) {
    return _.find(srcCollection, { value: this.assignee });
  }
}
