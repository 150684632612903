import { FunctionComponent } from 'react';
import { Button, Group, FlexColumn, Page, Text, Link } from '@logz-ui/styleguide';
import styled from 'styled-components';
import errorImage from 'ui/components/AppProvider/Fallback/assets/img_error_bottom.png';
import { show as showIntercom } from 'services/common/intercom.service';

const ErrorImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const ErrorPage = styled(Page)`
  justify-content: center;
  align-items: center;
`;

const ErrorCTA = styled(FlexColumn)`
  width: 400px;
  position: absolute;
  bottom: 50%;
  justify-content: center;
  text-align: center;
  align-items: center;
  z-index: 1;
`;

export const GlitchPage: FunctionComponent<{
  onTryAgainClick?: () => void;
}> = ({ onTryAgainClick }) => (
  <ErrorPage>
    <ErrorCTA>
      <Group vertical gap={6} alignItems={'center'}>
        <Group vertical gap={3} alignItems={'center'}>
          <Text size={18} variant={'faded'} mb={0}>
            You've found a glitch in the matrix
          </Text>
          <Text color={'gray.700'} mb={0}>
            Looks like something is wrong, please refresh and try again
            <br />
            If the problem persists, please&nbsp; <Link onClick={showIntercom}>contact Support</Link>.
          </Text>
        </Group>
        <Button size={'l'} onClick={onTryAgainClick ? () => onTryAgainClick() : () => location.reload()}>
          Try again
        </Button>
      </Group>
    </ErrorCTA>
    <ErrorImage alt="Confused developer" src={errorImage} />
  </ErrorPage>
);
