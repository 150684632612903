import { AccountType, PLAN_TYPE, Product, WelcomeFlowStep } from '@logz-pkg/enums';
import {
  configProvider,
  sessionStateService,
  telemetryDataStatusService,
  welcomeCenterApiService,
} from '@logz-pkg/frontend-services';
import { GuidedWalkthroughTaskId, PlanDetailsModel, TelemetryStatus, TelemetryStatusItem } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { ErrorHandlingService } from 'services/error-handling.service';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { welcomeAppStateService } from 'ui/components/WelcomeCenter/state.service';
import { guidedWalkthroughStateService } from 'ui/state/guided-walkthrough.state.service';
import { TELEMETRY_POLLING_INTERVAL } from 'ui/components/WelcomeCenter/Bar/telemetry-status.hook';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

const didRegisterBeforeDateThreshold = async ({ startDate }: PlanDetailsModel): Promise<boolean> => {
  const dateThreshold = await configProvider.getValue('welcomeCenter.startDateThreshold');

  return startDate.diff(dateThreshold, 'days') < 0;
};

export type SentData = Partial<Record<Product, boolean>>;

class WelcomeStateService {
  readonly isInWelcomeFlow = new Observable<boolean>(false);
  readonly isFocusMode = new Observable<boolean>(false);
  readonly isDataSentModalOpen = new Observable<boolean>(false);
  readonly isBarDisplayed = new Observable<boolean>(false);
  readonly sentData = new Observable<SentData>({
    [Product.LogAnalytics]: false,
    [Product.Metrics]: false,
  });
  readonly unsubscribes: (() => void)[] = [];

  activate = async () => {
    const { currentStep } = await welcomeAppStateService.getState();

    const isEligible = await this.isEligibleForWelcomeFlow({ currentStep });

    if (!isEligible) return;

    const { plan } = sessionStateService.session.get();

    if (plan.isCommunityAccount()) {
      this.isBarDisplayed.set(true);
    }

    if (currentStep >= WelcomeFlowStep.Second) await guidedWalkthroughStateService.startGuidedWalkthrough();

    await this.startWelcomeFlow({ step: currentStep });
  };

  isEligibleForWelcomeFlow = async ({ currentStep }): Promise<boolean> => {
    try {
      const { loggedInAccount, plan } = sessionStateService.session.get();

      const registeredBeforeStartDate = await didRegisterBeforeDateThreshold(plan);

      if (plan.planType !== PLAN_TYPE.TRIAL) return false;

      if (currentStep === WelcomeFlowStep.End) return false;

      if (loggedInAccount.type !== AccountType.Owner) return false;

      if (registeredBeforeStartDate) return false;

      return true;
    } catch (error) {
      ErrorHandlingService.logError("Couldn't determine guided flow", error);

      return false;
    }
  };

  startWelcomeFlow = async ({ step }: { step: WelcomeFlowStep }) => {
    this.stopAllListeners();

    if (this.isInWelcomeFlow.get()) return;

    this.setWelcomeFlow(true);

    const shouldListenToData = featureFlagStateService.isFeatureEnabled('GuidedFlowListenToData');

    if (shouldListenToData) {
      await this.fetchAndSetSentData();
    }

    if (step === WelcomeFlowStep.First) {
      await this.turnOnFirstStepFlow();
    } else if (step === WelcomeFlowStep.Second) {
      await this.turnOnSecondStepFlow();
    }
  };

  private handleFirstRealDataSent = async () => {
    welcomeAppStateService.advanceToStep(WelcomeFlowStep.Third);
    this.setFocusMode(false);

    await guidedWalkthroughStateService.startGuidedWalkthrough({ openDrawer: true });
    guidedWalkthroughStateService.markTaskDone(GuidedWalkthroughTaskId.SendData);
  };

  private fetchAndSetSentData = async () => {
    const [{ sentLogs }, { sentMetrics }] = await Promise.all([
      welcomeCenterApiService.accountSentLogs(),
      welcomeCenterApiService.accountSentMetrics(),
    ]);

    if (sentLogs || sentMetrics) {
      this.sentData.set({ [Product.LogAnalytics]: sentLogs, [Product.Metrics]: sentMetrics });
    }
  };

  private dataSentListener = async () => {
    try {
      await this.fetchAndSetSentData();

      if (this.didSendData()) {
        this.handleFirstRealDataSent();
        this.stopAllListeners();
      }
    } catch (error) {
      ErrorHandlingService.handleApiError({
        userMessage: "Couldn't get account sent data for guided flow",
        error,
      });

      this.setFocusMode(false);
      this.stopAllListeners();
    }
  };

  private waitForData = async () => {
    const shouldListenToData = featureFlagStateService.isFeatureEnabled('GuidedFlowListenToData');

    if (!shouldListenToData) {
      return;
    }

    this.stopAllListeners();

    if (this.didSendData()) {
      await this.handleFirstRealDataSent();
    } else {
      const dataSentInterval = await configProvider.getValue('welcomeCenter.dataSentInterval');

      const checkSentDataInterval = setInterval(this.dataSentListener, dataSentInterval);

      this.unsubscribes.push(() => clearInterval(checkSentDataInterval));
    }
  };

  private turnOnFirstStepFlow = async () => {
    this.setFocusMode(true);

    await this.waitForData();
  };

  turnOnSecondStepFlow = async () => {
    welcomeAppStateService.advanceToStep(WelcomeFlowStep.Second);
    guidedWalkthroughStateService.startGuidedWalkthrough({ openDrawer: true });

    this.setFocusMode(false);

    await this.waitForData();
  };

  stopWelcomeFlow = ({ permanently = false } = {}) => {
    guidedWalkthroughStateService.stop();

    if (!this.isInWelcomeFlow.get()) return;

    if (permanently) {
      welcomeAppStateService.advanceToStep(WelcomeFlowStep.End);
      this.isBarDisplayed.set(false);
    }

    this.clear();
  };

  private clear = () => {
    this.setFocusMode(false);
    this.setWelcomeFlow(false);
    this.clearDataObservables();
    this.setIsDataSentModalOpen(false);
    this.stopAllListeners();
  };

  private clearDataObservables = () => {
    this.sentData.set({
      [Product.LogAnalytics]: false,
      [Product.Metrics]: false,
    });
  };

  private stopAllListeners = () => {
    this.unsubscribes.forEach(unsubscribe => unsubscribe());
  };

  setFocusMode = (mode: boolean) => {
    this.isFocusMode.set(mode);
  };

  setWelcomeFlow = (mode: boolean) => {
    this.isInWelcomeFlow.set(mode);
  };

  setIsDataSentModalOpen = (mode: boolean) => {
    this.isDataSentModalOpen.set(mode);
  };

  shouldGoToWelcomePage = async (): Promise<boolean> => {
    const { appMode } = sessionStateService.session.get();
    const isOperationsMode = appMode === AppModes.OPERATIONS;

    const { currentStep } = await welcomeAppStateService.getState();
    const isEligibleForWelcomeFlows = await this.isEligibleForWelcomeFlow({ currentStep });

    return isEligibleForWelcomeFlows && isOperationsMode && currentStep === WelcomeFlowStep.First;
  };

  // DEV-45338 - tech debt - all sentData related mechanism should be removed
  didSendData = (): boolean => {
    return (
      Object.values(this.sentData.get()).some(Boolean) ||
      Object.values(telemetryDataStatusService.status.get()).some(o => o.sent)
    );
  };

  getDataSentTypes = (): (keyof SentData)[] =>
    (Object.keys(this.sentData.get()) as (keyof SentData)[]).filter(telemetry => this.sentData.get()[telemetry]);

  isBeforeTelemetryInterval = (timestamp: string) => {
    return Date.now() - new Date(timestamp).valueOf() <= TELEMETRY_POLLING_INTERVAL;
  };

  isNewTelemetryStatusItem = (item: TelemetryStatusItem) => {
    return item.sent && this.isBeforeTelemetryInterval(item.timestamp);
  };

  async handleTelemetriesStatus(telemetries: TelemetryStatus) {
    telemetryDataStatusService.status.set(telemetries);

    if (Object.values(telemetries).some(this.isNewTelemetryStatusItem)) {
      const { currentStep } = await welcomeAppStateService.getState();

      this.setIsDataSentModalOpen(true);

      if (currentStep < WelcomeFlowStep.Third) {
        await this.dataSentListener();
      }
    }
  }
}

export const welcomeStateService = new WelcomeStateService();
