import { AiChatRoles, BedrockMessage, ChatResponse, ServerStreamDataChunk } from '@logz-pkg/models';
import { eventEmitter, EVENTS, serverStreamApiService } from '@logz-pkg/frontend-services';
import { AIDataSource } from '@logz-pkg/enums';
import { abortControllerService } from '@logz-pkg/utils';
import { aiStateService } from '../ai-state.service';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

const handleSearchSse = async ({
  message,
  type,
  isConclusion = false,
  streamSseUrl,
  onData,
}: {
  message: string;
  type: Omit<BedrockMessage['type'], 'dynamicQuestions'>;
  isConclusion?: boolean;
  streamSseUrl: string;
  onData: (data: ServerStreamDataChunk, isDone?: boolean, isConclusion?: boolean) => void;
}) => {
  await serverStreamApiService.doSse({
    streamUrl: streamSseUrl,
    payload: [{ role: AiChatRoles.User, content: message, type: type as BedrockMessage['type'] }],
    onData: res => onData(res, false, isConclusion),
    onDone: () => onData({ message: '', status: 'other' }, true, isConclusion),
  });
};
export const handleResponse = async ({
  onData,
  onEnd,
  onError,
  response,
  streamSseUrl,
  reqIndex,
  additionalData,
  shouldStart,
}: {
  response: ChatResponse;
  onData?: (data: ServerStreamDataChunk, isDone?: boolean, isConclusion?: boolean) => void;
  onEnd?: (reason?: ServerStreamDataChunk) => void;
  onError?: (error: ServerStreamDataChunk) => void;
  streamSseUrl?: string;
  reqIndex: number;
  additionalData?: {
    [key: string]: any;
    message?: {
      content?: unknown;
      [key: string]: any;
    };
  };
  shouldStart?: boolean;
}): Promise<{ shouldStop: boolean; reqIndex: number }> => {
  aiStateService.setShouldSendContent(shouldStart);

  const { status, message } = response;

  if (['canceled', 'timeout', 'done', 'error'].includes(status)) {
    if (status === 'error') return onError(response), { shouldStop: true, reqIndex };

    if (status === 'timeout')
      return onEnd({ message: 'The request has timed out. Please try again.', status }), { shouldStop: true, reqIndex };

    if (status === 'done') {
      if (featureFlagStateService.isFeatureEnabled('aiAgentSearchSseConclusion')) {
        const type =
          additionalData.agentType === AIDataSource.AllAvailableData ? 'proConclusionSummary' : 'conclusionSummary';

        await handleSearchSse({ message, type, isConclusion: true, streamSseUrl, onData });
      }

      onEnd(response);
    }

    return { shouldStop: true, reqIndex }; // Should stop polling
  }

  if (status !== 'started') {
    reqIndex += 1;

    if (status === 'rational') {
      const type = [AIDataSource.AllAvailableData, AIDataSource.MetricsDashboard].includes(additionalData.agentType)
        ? 'proSummary'
        : 'summary';

      await handleSearchSse({ message, type, streamSseUrl, onData });
    } else {
      onData(response);
    }
  }

  return { shouldStop: false, reqIndex }; // Continue polling
};
export const abortTheRequest = (key: string) => {
  abortControllerService.abort(key);

  const abortController = abortControllerService.create(key);

  eventEmitter.on(EVENTS.STOP_LAST_REQUEST, () => {
    abortControllerService.abort(key);
  });

  abortController.signal.onabort = () => {
    abortControllerService.delete(key);
  };

  return abortController.signal;
};
