import { Button, Group, NotificationService, Text } from '@logz-ui/styleguide';
import { useAiAnalytics } from '../../hooks/analytics.hook';
import { useAiFeedback } from '../../state/feedback/feedback.hook';
import { aiFeedbackService } from '../../state/feedback/feedback.service';

export const AiFeedbackReasons = ({ setShouldOpen }: { setShouldOpen: (shouldOpen: boolean) => void }) => {
  const { setShouldShowFeedback } = useAiFeedback();
  const { report } = useAiAnalytics();
  const { rate } = useAiFeedback();

  return (
    <Group>
      {aiFeedbackService.aiReasons.map(buttonText => (
        <Button
          variant={'secondary'}
          key={buttonText}
          onClick={() => {
            if (buttonText !== '+ Other feedback') {
              setShouldShowFeedback(false);
              report('Sent feedback reasons', { score: rate, reason: buttonText });

              return NotificationService.success({
                title: 'Thank you for your feedback',
                message: 'Your input helps us improve continuously. ',
              });
            }

            setShouldOpen(true);
          }}
          size={'m'}
        >
          <Text weight={500}>{buttonText}</Text>
        </Button>
      ))}
    </Group>
  );
};
