import { useMemo } from 'react';
import { IDisplayCondition } from '../Sidenav/item-configs/item-configs';
import { checkDisplayConditions } from './check-display-condition';
import { useSessionState, UseSessionState } from 'ui/state/hooks';

type BaseItem = {
  displayConditions?: IDisplayCondition[];
};

export function useNavigationDisplayCondition<T extends BaseItem>(
  items: T[],
): [allowedItems: T[], sessionState: UseSessionState] {
  const sessionState = useSessionState();

  const allowedItems: T[] = useMemo(() => {
    return items?.filter(item =>
      checkDisplayConditions({ conditions: item.displayConditions as IDisplayCondition[], sessionState }),
    );
  }, [sessionState]);

  return [allowedItems, sessionState];
}
