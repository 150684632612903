import { Chart, PointOptionsObject, SeriesColumnOptions } from 'highcharts';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isNil } from 'lodash';
import { ResizeCallbackWrapper } from '@logz-ui/styleguide';
import { Highcharts as HighchartsReact } from '../Highcharts/Highcharts';
import { TooltipPortal } from './TooltipPortal';
import { GraphEventMenu } from './components/GraphEventMenu';
import type { DateFormat, GraphRef, IGraphProps } from './types';
import { GraphActionsMenu } from './components/GraphActionsMenu';
import { HighchartsWrapper, CustomLegendWrapper } from './components/HighChartWrapper';
import { GraphProvider, useGraphContext } from './GraphTheme.provider';
import { DefaultTooltipComponent } from './components/DefaultTooltipComponent';
import { DefaultEmptyStateComponent } from './components/DefaultEmptyStateComponent';
import { PieLegend } from './PieLegend.component';
import { usePieSeries } from './pie-series.hook';
import { useGraphOptions } from './hooks/graph-options/graph-options.hook';
import { useDimensions } from './hooks/dimensions.hook';
import { useHighlightRange } from './hooks/highlight-range.hook';

const IGNORE_DARK_READER_CLASSNAME = 'dark-reader-ignore';

const defaultEmptyState = { disable: false, forceShow: false };

export const GraphBase = forwardRef<GraphRef, IGraphProps>(
  (
    {
      TooltipComponent = DefaultTooltipComponent,
      PieLegendComponent = PieLegend,
      legendType,
      emptyState: { disable: disableEmptyState, forceShow: forceShowEmptyState } = defaultEmptyState,
      EmptyStateComponent = DefaultEmptyStateComponent,
      menuActions,
      subject,
      maxTooltipItemsToRender,
      series,
      events,
      height,
      width,
      timezone,
      ...graphOptions
    },
    ref,
  ) => {
    const { handleClick, handleCloseMenu, handleEventMenuClick } = useGraphContext();
    const [chart, setChart] = useState<Chart | null>(null);

    const { pieSeries } = usePieSeries(series as SeriesColumnOptions[]);
    const shouldShowPie = legendType === 'pie';
    const dimensionsRef = useDimensions({ height, width });

    const { setHighlightRangeStart, setHighlightRangeEnd } = useHighlightRange({ chart });

    useImperativeHandle(ref, () => ({ chartInstance: chart, setHighlightRangeStart, setHighlightRangeEnd }), [chart]);

    const options = useGraphOptions({
      ...graphOptions,
      ...(shouldShowPie && { legend: { enabled: false } }),
      series,
      onClick: menuActions ? handleClick : graphOptions.onClick,
      handleEventMenuClick,
      events,
      timezone,
      height: dimensionsRef.current.height,
      width: dimensionsRef.current.width,
    });

    useEffect(() => {
      const getIconImport = async () => {
        if (!(window as any).FontAwesome) {
          await import('@logz-ui/styleguide/components/icons/font-awesome/js/all.min.js');
        }
      };

      getIconImport();
    }, []);

    const { hasData, dateFormat } = useMemo(() => {
      if (!series || series.length === 0) {
        return { hasData: false, dateFormat: undefined };
      }

      let detectedFormat: DateFormat['format'] | undefined;

      const hasData = series.some(g => {
        if (!('data' in g) || !Array.isArray(g.data) || g.data.length === 0) {
          return false;
        }

        const [firstPoint, secondPoint] = g.data as PointOptionsObject[];

        if (
          firstPoint?.x !== undefined &&
          secondPoint?.x !== undefined &&
          Math.abs(Number(firstPoint.x) - Number(secondPoint.x)) < 1000
        ) {
          detectedFormat = 'MMM-DD-YYYY HH:mm:ss:SSS';
        }

        return true;
      });

      return { hasData, dateFormat: detectedFormat };
    }, [series]);

    const handleResize = useCallback(
      (newDimensions: DOMRect) => {
        const newSizes = { width: width ?? newDimensions.width, height: height ?? newDimensions.height };

        dimensionsRef.current = newSizes;

        if (chart) {
          if (chart.chartWidth !== newDimensions.width || chart.chartHeight !== newDimensions.height) {
            chart.setSize(newSizes.width, newSizes.height);
          }
        }
      },
      [chart?.chartWidth, chart?.chartHeight, height, width],
    );

    if (forceShowEmptyState || (!hasData && !disableEmptyState)) {
      return <EmptyStateComponent />;
    }

    return (
      <>
        <ResizeCallbackWrapper
          trackChildren={false}
          disableHeight={!isNaN(height)}
          disableWidth={!isNaN(width)}
          debounceTime={50}
          callback={handleResize}
        >
          <HighchartsWrapper
            className={IGNORE_DARK_READER_CLASSNAME}
            shouldShowCustomLegend={shouldShowPie}
            {...generateLogzTestAttributes({ context: 'logz-graph', subject })}
          >
            <HighchartsReact options={options} callback={setChart} />
            {menuActions && <GraphActionsMenu menuActions={menuActions} onClose={handleCloseMenu} />}
            {!isNil(events) && <GraphEventMenu chart={chart} columns={events.columns} />}
            {TooltipComponent && (
              <TooltipPortal chart={chart}>
                {({ x, points, nowHovered }) => {
                  return (
                    <TooltipComponent
                      x={x}
                      points={points}
                      format={dateFormat}
                      nowHovered={nowHovered}
                      maxRenderItems={maxTooltipItemsToRender === undefined ? 5 : maxTooltipItemsToRender}
                      timezone={timezone}
                    />
                  );
                }}
              </TooltipPortal>
            )}
          </HighchartsWrapper>
        </ResizeCallbackWrapper>
        {shouldShowPie ? (
          <CustomLegendWrapper>
            <PieLegendComponent
              menuActions={menuActions}
              series={series}
              pieSeries={pieSeries}
              mainChart={chart}
              {...graphOptions}
            />
          </CustomLegendWrapper>
        ) : null}
      </>
    );
  },
);

export const Graph = forwardRef<GraphRef, IGraphProps>((props, ref) => {
  return (
    <GraphProvider {...props}>
      <GraphBase {...props} ref={ref} />
    </GraphProvider>
  );
});

GraphBase.displayName = 'GraphBase';
Graph.displayName = 'Graph';
