import { useObservable } from '@logz-pkg/observable';
import { tableOfContentService } from './tableOfContent.state.service';

export const useTableOfContentService = () => {
  const tableOfContent = useObservable(tableOfContentService.tableOfContent);
  const tableOfContentReady = useObservable(tableOfContentService.tableOfContentReady);

  return {
    tableOfContent,
    tableOfContentReady,
    setTableOfContent: tableOfContentService.setTableOfContent,
    clearTableOfContent: tableOfContentService.clearTableOfContent,
  };
};
