import { AccountType, IAppModeValue } from '@logz-pkg/enums';
import { accountService, configProvider } from '@logz-pkg/frontend-services';
import { welcomeRouteState } from '../../../app/states/welcome.routes';
import { AppModes } from './app-modes.factory';
import { appRouter } from 'services/router/router.service';
import { welcomeStateService } from 'ui/state/welcome.state.service';
import { landingPageAppStateService } from 'ui/state/landing-page/landing-page.app-state.service';

export const ACCOUNT_TYPE_TO_MODE: Partial<Record<AccountType, IAppModeValue>> = {
  [AccountType.Owner]: AppModes.OPERATIONS,
  [AccountType.Sub]: AppModes.OPERATIONS,
  [AccountType.Security]: AppModes.SECURITY,
  [AccountType.Metrics]: AppModes.OPERATIONS,
  [AccountType.Tracing]: AppModes.OPERATIONS,
  [AccountType.Restore]: AppModes.OPERATIONS,
};

const isSecurityMode = async () => {
  const mode = await getCurrentMode();

  return mode === AppModes.SECURITY;
};

const isOperationsMode = async () => {
  const mode = await getCurrentMode();

  return mode === AppModes.OPERATIONS;
};

const getCurrentMode = (): Promise<Partial<IAppModeValue>> =>
  accountService
    .getAccountType()
    .then(accountType => {
      if (!accountType) return {};

      return ACCOUNT_TYPE_TO_MODE[accountType] || {};
    })
    .catch(() => ({}));

/**
 * Go to root state by Mode, in case of Operations mode open welcome flow if required.
 * @returns {Promise<string>} - Current new state
 */
const goToModeRootState = async (stateParams = {}, stateOptions = {}) => {
  const mode = await getCurrentMode();
  let rootState;

  if (mode && mode.rootState) {
    ({ rootState } = mode);

    const landingPage = await landingPageAppStateService.get();

    if (landingPage?.state?.name) {
      const { name, params } = landingPage.state;

      return appRouter.stateService.go(name, params, { inherit: false });
    } else if (landingPage?.link) {
      return appRouter.urlService.url(landingPage.link);
    }

    if (await welcomeStateService.shouldGoToWelcomePage()) {
      rootState = welcomeRouteState;
    } else if (mode === AppModes.OPERATIONS) {
      rootState = await configProvider.getValue('homepage');
    }

    await appRouter.stateService.go(rootState, stateParams, stateOptions);
  }

  return rootState;
};

const goToModeKibanaState = async (stateParams = {}) => {
  const mode = await getCurrentMode();

  if (!mode.kibanaState) return;

  await appRouter.stateService.go(mode.kibanaState, stateParams);
};

export const appModeService = {
  isSecurityMode,
  isOperationsMode,
  getCurrentMode,
  goToModeRootState,
  goToModeKibanaState,
};
