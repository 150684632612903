import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { Card, DataTable } from '@logz-ui/styleguide';
import { Chart } from 'highcharts';
import { LogzGraphTestSubjects } from '@logz-pkg/enums';
import { GenericEventDetails, IGetOptionsProps } from '../types';

interface IEventActionMenuProps {
  data: GenericEventDetails[];
  columns: IGetOptionsProps['events']['columns'];
  chart: Chart;
}

export const EventActionMenu: React.FC<IEventActionMenuProps> = memo(({ data, columns, chart }) => {
  const appTheme = useTheme();

  return (
    <Card
      p={2}
      borderColor={appTheme.graph.eventActionMenuCardBorderColor}
      borderRadius={4}
      subject={LogzGraphTestSubjects.EventActionMenuSubject}
    >
      <DataTable
        maxHeight={200}
        density="compact"
        columns={columns}
        data={data}
        subject={`${LogzGraphTestSubjects.EventActionMenuSubject}-table`}
        name={`${LogzGraphTestSubjects.EventActionMenuSubject}-${chart.index}-table`}
      />
    </Card>
  );
});

EventActionMenu.displayName = 'EventActionMenu';
