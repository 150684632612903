import { sessionApiService } from '@logz-pkg/frontend-services';
import { AppStateDeclaration } from './AppStateDecleration';
import { appModeService } from 'services/identity/app-mode/app-mode.service';

export const adminRoute: AppStateDeclaration = {
  name: 'dashboard.admin',
  url: '/__admin__',
  redirectTo: async () => {
    const { appAdminPanelUrl } = await sessionApiService.get();

    if (!appAdminPanelUrl) return appModeService.goToModeRootState();

    window.location.href = appAdminPanelUrl;
  },
  data: { adminOnly: true, authenticate: true },
};
