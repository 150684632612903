import { useEffect } from 'react';

export let isTabActive: boolean;

export const useTabVisibility = () => {
  useEffect(() => {
    const visibilityChangeHadler = () => {
      if (document.hidden) {
        isTabActive = false;
      } else {
        isTabActive = true;
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeHadler);

    return () => window.removeEventListener('visibilitychange', visibilityChangeHadler);
  }, []);
};
