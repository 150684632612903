import { dropRight, union } from 'lodash';
import { NotificationEndpointType } from '@logz-pkg/enums';
import { cacheProvider, httpService } from '@logz-pkg/frontend-services';
import { NotificationEndpointTemplateModel } from '@logz-pkg/models';

const maxEmailsToStore = 15;

export const saveNotificationEndpoint = (notificationEndpoint, templateUrlSuffix, test?) => {
  const urlPrefix = `endpoints/${templateUrlSuffix}`;
  const testSuffix = test ? '?test=true' : '';

  if (notificationEndpoint && notificationEndpoint.id) {
    return httpService.put(`${urlPrefix}/${notificationEndpoint.id}${testSuffix}`, notificationEndpoint);
  }

  return httpService.post(`${urlPrefix}${testSuffix}`, notificationEndpoint);
};

export const deleteNotificationEndpoint = notificationEndpoint => {
  if (notificationEndpoint.id) {
    const url = `/endpoints/${notificationEndpoint.id}`;

    return httpService.del(url);
  }

  return Promise.reject([]);
};

export const getNotificationEndpoint = id => httpService.get(`/endpoints/${id}`);

export const getAllNotificationEndpoints = () => httpService.get('/endpoints');

const endpointsTemplatesUrl = '/endpoints/templates';

export const getAllNotificationEndpointTemplates = (): Promise<NotificationEndpointType[]> =>
  cacheProvider
    .get(endpointsTemplatesUrl, () => httpService.get(endpointsTemplatesUrl))
    .then(({ data: templateTypes }: { data: NotificationEndpointTemplateModel[] }) =>
      templateTypes.map(({ templateName }) => templateName),
    );

export const handleCachedEmailsList = async (emails = []) => {
  // we face an issue with the values, and therefore we filter the cache to remove unsafe values
  let cachedEmails = union(emails, cacheProvider.getPersistent<string[]>('emails'));

  cachedEmails = cachedEmails.filter(email => typeof email === 'string');

  if (cachedEmails.length > maxEmailsToStore) {
    cachedEmails = dropRight(cachedEmails, cachedEmails.length - maxEmailsToStore);
  }

  cacheProvider.setPersistent<string[]>('emails', cachedEmails);

  return cachedEmails;
};

export const notificationEndpointService = {
  handleCachedEmailsList,
  getAllNotificationEndpoints,
  getAllNotificationEndpointTemplates,
  getNotificationEndpoint,
  saveNotificationEndpoint,
  deleteNotificationEndpoint,
};
