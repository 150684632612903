import React, { FunctionComponent, useState } from 'react';
import { Card, Flex, Group, Icon, Text } from '@logz-ui/styleguide';
import { GuidedWalkthroughTaskId } from '@logz-pkg/models';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';

interface IPhaseTitleProps {
  title: string;
  subTitle: string;
}

const PhaseTitle: FunctionComponent<IPhaseTitleProps> = ({ title, subTitle }) => {
  return (
    <Group>
      <Text color={'royalBlue.1000'} size={12} weight={700}>
        {title}
      </Text>
      <Text color={'royalBlue.1000'} size={12}>
        {subTitle}
      </Text>
    </Group>
  );
};

interface IPhaseWrapperProps {
  name: string;
  tasks: GuidedWalkthroughTaskId[];
  isOpenByDefault: boolean;
  children: React.ReactNode;
}

export const PhaseWrapper: FunctionComponent<IPhaseWrapperProps> = ({ name, tasks, isOpenByDefault, children }) => {
  const [isPhaseOpen, setIsPhaseOpen] = useState<boolean>(isOpenByDefault);
  const { doneTasksIds } = useGuidedWalkthrough();

  const numberOfDoneTasks = tasks.filter(taskId => doneTasksIds?.includes(taskId)).length;

  return (
    <Card width={'100%'} pt={4} pl={4} pr={4} borderless>
      <Flex justifyContent={'space-between'} width={'100%'} onClick={() => setIsPhaseOpen(!isPhaseOpen)}>
        <PhaseTitle title={name} subTitle={`(${numberOfDoneTasks}/${tasks.length} completed)`} />
        <Icon icon={isPhaseOpen ? 'chevron-down-regular' : 'chevron-right-regular'} color={'gray.600'} size={10} />
      </Flex>
      {isPhaseOpen ? children : null}
    </Card>
  );
};
