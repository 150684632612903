import React from 'react';
import { Badge, Group, Text } from '@logz-ui/styleguide';
import styled, { css } from 'styled-components';
import { useAiConversations } from '../../state/conversations/conversations.hook';
import { AiUserInput } from './UserInput/UserInput';
import { DisclaimerInfo } from './DisclaimerInfo';

const ShadowGroup = styled(Group)<{ shadow: boolean }>`
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
    `}
`;

const DisclaimerAndDocs: React.FC = () => {
  const { shouldShowConversationsPage } = useAiConversations();

  return (
    <ShadowGroup justifyContent={'space-between'} width={'100%'} py={4} px={7} shadow={shouldShowConversationsPage}>
      <Group alignItems={'center'} width={'150px'}>
        <Text size={14} weight={700} color={'royalBlue.700'}>
          AI Agent
        </Text>
        <Badge
          color={'gray.200'}
          square={true}
          value={
            <Text weight={400} color={'royalBlue.900'}>
              Beta
            </Text>
          }
        />
      </Group>
      <DisclaimerInfo />
    </ShadowGroup>
  );
};

export const AiFooter: React.FC = () => {
  const { shouldShowConversationsPage } = useAiConversations();

  return (
    <Group
      fullWidth
      vertical
      justifyContent={'flex-end'}
      pt={shouldShowConversationsPage ? 1 : 4}
      width={'100%'}
      gap={1}
    >
      {!shouldShowConversationsPage && <AiUserInput />}
      <DisclaimerAndDocs />
    </Group>
  );
};
