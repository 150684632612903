import { redirectIfNotAdmin } from './utils';
import { AppStateDeclaration } from './AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

const queryParams = ['accountIds', 'usages', 'searchTerm', 'field', 'descending', 'pageNumber', 'pageSize', 'table'];

export const costManagementRouteName = 'dashboard.cost';

export const costManagementRoute: AppStateDeclaration[] = [
  {
    name: costManagementRouteName,
    url: `/cost?${queryParams.join('&')}`,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/CostManagement/CostManagementPage')}
        resolver={({ CostManagementPage }) => CostManagementPage}
        {...props}
      />
    ),
    data: { authenticate: true, title: 'Data Optimization Hub', breadcrumb: { label: 'Data Optimization Hub' } },
    dynamic: true,
    resolve: [redirectIfNotAdmin],
    params: {
      accountIds: {
        type: 'int',
        array: true,
        value: [],
        dynamic: true,
      },
      usages: {
        type: 'string',
        array: true,
        value: [],
        dynamic: true,
      },
      searchTerm: {
        type: 'string',
        value: null,
        dynamic: true,
      },
      pageNumber: {
        type: 'int',
        value: null,
        dynamic: true,
      },
      pageSize: {
        type: 'int',
        value: null,
        dynamic: true,
      },
      field: {
        type: 'string',
        value: null,
        dynamic: true,
      },
      descending: {
        type: 'string',
        value: null,
        dynamic: true,
      },
      table: {
        type: 'string',
        value: null,
        dynamic: true,
      },
    },
  },
];
