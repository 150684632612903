import { format as formatUrl } from 'url';
import { urlParser } from '@logz-pkg/utils';
import { appModeService } from '../identity/app-mode/app-mode.service';

const GRAFANA_HASH = '/dashboard/metrics';

const getParsedUrl = ({ productUrl, unHashUrlWithStates }) =>
  unHashUrlWithStates ? urlParser(unHashUrlWithStates(productUrl)) : urlParser(productUrl);

const getLogzioUrl = async ({
  shareToken,
  productUrl,
  unHashUrlWithStates,
  hash,
  switchToAccountId,
  hashQuery,
  useOnlyGlobalState,
}) => {
  const query = shareToken ? { shareToken } : {};

  const parsedUrl = getParsedUrl({ productUrl, unHashUrlWithStates });

  const { host, protocol } = window.location;

  const appModeHash = hash || (await appModeService.getCurrentMode()).kibanaHash;

  const parsedLogzioUrl = {
    protocol,
    auth: parsedUrl.auth,
    host: `${host}/`,
    hash: appModeHash,
  };

  const logzioUrlParsed = urlParser(formatUrl({ ...parsedLogzioUrl, query: { ...parsedLogzioUrl.query, ...query } }));

  const urlHash = {};

  if (parsedUrl.hashPathname) {
    urlHash.pathname = `${logzioUrlParsed.hashPathname}${parsedUrl.hashPathname}`;

    urlHash.query = useOnlyGlobalState
      ? { ...hashQuery, _g: parsedUrl.hashQuery._g }
      : { ...hashQuery, ...parsedUrl.hashQuery };
  } else {
    urlHash.pathname = `${logzioUrlParsed.hashPathname}${parsedUrl.parsedUrl.pathname}`;
    urlHash.query = { ...parsedUrl.parsedQuery };
  }

  if (switchToAccountId) urlHash.query.switchToAccountId = switchToAccountId;

  return formatUrl({
    protocol: parsedUrl.parsedUrl.protocol,
    host: `${parsedUrl.parsedUrl.host}/`,
    hash: formatUrl(urlHash),
  });
};

const getLogzioGrafanaUrl = async ({ productUrl, switchToAccountId, hash = GRAFANA_HASH } = {}) =>
  getLogzioUrl({
    productUrl,
    hash,
    switchToAccountId,
  });

export const shareUrlService = {
  getParsedUrl,
  getLogzioUrl,
  getLogzioGrafanaUrl,
};
