import { searchedIndexesService } from '@logz-pkg/frontend-services';
import { IBuildSearchPayload } from '@logz-pkg/frontend-services/src/dal/opensearch/opensearch-query.service';
import { ElasticsearchEnhancedFilterModel, exploreModelManipulator, IOpensearchDsl } from '@logz-pkg/models';
import { IntervalData } from '@logz-pkg/utils';
import { opensearchtypes } from '@opensearch-project/opensearch';
import { cloneDeep, get, isNil } from 'lodash';
import { LOGZIO_HIGHLIGHT_TAG } from 'ui/components/Explore/constants';
import { exploreSearchParamsService } from 'ui/components/Explore/router/router';
import { logsStateService } from 'ui/components/Explore/state/logs-state.service';
import {
  OtherValuesHistogramPayload,
  SearchWithDateHistogram,
  TermHistogramPayload,
  UpdateRange,
} from 'ui/components/Explore/state/query/types';
import { exploreUtils } from 'ui/components/Explore/utils/explore-utils';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

const getDslFilters = (
  elasticsearchFilters: ElasticsearchEnhancedFilterModel[],
  time?: {
    start?: string;
    end?: string;
  },
  equal: boolean = true,
): IOpensearchDsl => {
  const dslFilters = exploreModelManipulator.convertFiltersToElastic({ filters: elasticsearchFilters || [] });

  if (!dslFilters.bool.filter) {
    dslFilters.bool.filter = [];
  }

  if (time?.start || time?.end) {
    const rangeQuery: Record<string, any> = {
      '@timestamp': {
        format: 'strict_date_optional_time',
      },
    };

    if (time.start) {
      rangeQuery['@timestamp'][equal ? 'gte' : 'gt'] = time.start;
    }

    if (time.end) {
      rangeQuery['@timestamp'][equal ? 'lte' : 'lt'] = time.end;
    }

    dslFilters.bool.filter.push({
      range: rangeQuery,
    });
  }

  return dslFilters;
};

const getDateHistogramAggregation = (interval: IntervalData): opensearchtypes.AggregationsDateHistogramAggregation => ({
  field: '@timestamp',
  min_doc_count: 0,
  calendar_interval: interval.interval,
  extended_bounds: {
    min: interval.start,
    max: interval.end,
  },
});

const otherValuesPayloadBuilder = ({ payload, byTermAgg }: OtherValuesHistogramPayload): opensearchtypes.MsearchBody => {
  const { accounts, term, dslFilters, interval } = payload;

  const otherValuesPayload = {
    query: dslFilters,
    size: 0,
    source: { excludes: searchedIndexesService.getAccountsWithPrefix(accounts) },
    aggs: {
      'other-filter': {
        aggs: {
          dateHistogram: {
            date_histogram: getDateHistogramAggregation(interval),
          },
        },
        filters: {
          filters: {
            termExist: {
              bool: {
                must: [],
                filter: [{ exists: { field: term } }],
                should: [],
                must_not: [],
              },
            },
          },
        },
      },
    },
  };

  (byTermAgg.buckets as opensearchtypes.AggregationsKeyedBucket<string>[]).forEach(bucket => {
    if (bucket.key !== 'Missing') {
      otherValuesPayload.aggs['other-filter'].filters.filters.termExist.bool.must_not.push({
        match_phrase: {
          [term]: bucket.key,
        },
      });
    }
  });

  return otherValuesPayload;
};

const searchWithDateHistogramPayload = ({
  filters,
  interval,
  accounts,
}: SearchWithDateHistogram): IBuildSearchPayload => {
  return {
    trackTotalHits: false,
    query: updateRange(filters, { start: interval.start, end: interval.end }),
    size: 0,
    source: { excludes: searchedIndexesService.getAccountsWithPrefix(accounts) },
    aggs: {
      agg: {
        date_histogram: getDateHistogramAggregation(interval),
      },
    },
  };
};

const updateRange: UpdateRange = (dsl, time) => {
  const copiedDsl = cloneDeep(dsl);

  copiedDsl.bool.filter.forEach(f => {
    if (f.range !== undefined) {
      f.range['@timestamp'] = {
        ...f.range['@timestamp'],
        gte: time.start,
        lte: time.end,
      };
    }
  });

  return copiedDsl;
};

const termHistogramPayload = (payload: TermHistogramPayload, showMissing = true): IBuildSearchPayload => {
  const { limit, accounts, term, dslFilters, interval } = payload;

  return {
    query: dslFilters,
    size: 0,
    aggs: {
      byTerm: {
        terms: {
          field: term,
          ...((showMissing && { missing: 'Missing' }) || {}),
          order: {
            _count: 'desc',
          },
          size: limit,
        },
        aggs: {
          byDate: { date_histogram: getDateHistogramAggregation(interval) },
        },
      },
    },
    source: { excludes: searchedIndexesService.getAccountsWithPrefix(accounts) },
  };
};

const handleSortWithSearchAfter = (dslFilters, fetchNextPage) => {
  const sort = exploreSearchParamsService.sort.get();
  let querySort: Record<string, Record<string, string>>[] = [];
  const allLogs = logsStateService.state.get()?.hits.hits;
  const lastLog = allLogs && allLogs[allLogs.length - 1];
  let searchAfter = lastLog?.sort;

  if (sort?.length) {
    const { timeStampField, secondField } = exploreUtils.extractFieldsFromSortArr(sort);

    [secondField, timeStampField].forEach(field => {
      if (!field) return;

      const order = field.startsWith('-') ? 'desc' : 'asc';
      const cleanedField = field.startsWith('-') ? field.slice(1) : field;

      querySort.push({
        [cleanedField]: { order },
      });
    });

    if (querySort?.length === 1 && secondField) {
      querySort.push({
        '@timestamp': { order: secondField === '-' ? 'desc' : 'asc' },
      });
    }

    const searchAfterHasNull =
      fetchNextPage &&
      (searchAfter?.some(searchAfterItem => isNil(searchAfterItem)) ||
        (searchAfter?.length === 1 && querySort.find(item => Object.keys(item)[0] === '@timestamp')));

    if (searchAfterHasNull && sort?.length) {
      const timeStampInd = querySort.findIndex(item => Object.keys(item)[0] === '@timestamp');

      searchAfter = searchAfter?.[timeStampInd] && [searchAfter?.[timeStampInd]];

      querySort = [querySort.find(item => Object.keys(item)[0] === '@timestamp')];

      if (secondField) {
        dslFilters.bool.must_not.push({ exists: { field: secondField.slice(1) } });
      }
    }
  }

  return { searchAfter, querySort };
};

const getLogzioHighLight = query => {
  const clonedQuery = cloneDeep(query);
  let isQueryStringExists = false;
  const musts = get(clonedQuery, 'bool.must', []).map(must => {
    if (must.query_string) {
      isQueryStringExists = true;
      must.query_string.default_field = '*';
    }

    return must;
  });

  if (isQueryStringExists) {
    clonedQuery.bool.must = musts;

    return {
      highlight_query: clonedQuery,
    };
  }

  return {};
};

const buildLogsQuerySearchPayload = (fetchNextPage, dslFilters) => {
  const accounts = exploreSearchParamsService.accounts.get();
  const pageSize = fetchNextPage ? 100 : 300;
  const { searchAfter, querySort } = handleSortWithSearchAfter(dslFilters, fetchNextPage);

  return {
    size: pageSize,
    trackTotalHits: true,
    query: dslFilters,
    sort: querySort,
    source: { excludes: searchedIndexesService.getAccountsWithPrefix(accounts) },
    highlight: {
      fields: {
        '*': featureFlagStateService.isFeatureEnabled('HighlightQueryContainsQuery')
          ? getLogzioHighLight(dslFilters)
          : {},
      },
      pre_tags: [LOGZIO_HIGHLIGHT_TAG],
      post_tags: [LOGZIO_HIGHLIGHT_TAG],
      fragment_size: 2147483647,
    },
    ...(fetchNextPage && searchAfter && { searchAfter }),
  };
};

export const queryBuilder = {
  otherValuesPayloadBuilder,
  termHistogramPayload,
  searchWithDateHistogramPayload,
  getDslFilters,
  buildLogsQuerySearchPayload,
};
