import { Badge, Card, Flex, Group, Text } from '@logz-ui/styleguide';
import React from 'react';
import { Graph } from '@logz-ui/graphs';
import { SeriesOptionsType } from 'highcharts';
import { AICopilotChatMessageSubjects, AICopilotInformationMessageSubjects } from '@logz-pkg/enums';

type AiChatAgentInformationType = {
  timeString: string;
  badges: string[];
  title: string;
  series?: SeriesOptionsType[];
};

export const AiChatAgentInformation: React.FC<AiChatAgentInformationType> = ({ timeString, badges, title, series }) => {
  return (
    <Flex pl={6} width={'100%'} subject={AICopilotChatMessageSubjects.InformationMessage}>
      <Card width={'100%'} p={4}>
        <Group vertical gap={1} mb={2}>
          <Text size={12} weight={700} color={'royalBlue.600'}>
            {timeString}
          </Text>

          <Text size={16} weight={700} color={'royalBlue.1000'}>
            {title}
          </Text>
        </Group>
        <Group gap={1}>
          {badges.map(badge => (
            <Badge
              subject={AICopilotInformationMessageSubjects.Badge}
              key={badge}
              color={'gray.200'}
              square={true}
              value={
                <Text weight={700} color={'royalBlue.1000'}>
                  {badge}
                </Text>
              }
            />
          ))}
        </Group>
        {series?.length ? (
          <Group fullWidth mt={4}>
            <Graph showAxis={false} height={32} series={series} subject={AICopilotInformationMessageSubjects.Graph} />
          </Group>
        ) : null}
      </Card>
    </Flex>
  );
};
