import { IBuildSearchPayload } from '@logz-pkg/frontend-services/src/dal/opensearch/opensearch-query.service';
import { InvocationFunctionName } from '@logz-pkg/models';
import { IntervalData } from '@logz-pkg/utils';
import { searchedIndexesService, sessionStateService } from '@logz-pkg/frontend-services';
import { opensearchtypes } from '@opensearch-project/opensearch';
import { queryUtils } from 'ui/components/Explore/state/query/query.utils';
import { GraphInvocationQueryType } from 'ui/components/AI/Components/Messages/Agent/invocation.type';
import { getLuceneFilter } from 'ui/components/Explore/Components/SmartSearch/LuceneInput/helpers/utils';

const invocationTitleMap: Record<InvocationFunctionName, string> = {
  'logzio-find-deployments': 'Deployment finder',
  'group-by-with-date-histogram': 'Logs grouped by __fieldPlaceHolder__ over time',
  'search-with-date-histogram': 'Time-based logs histogram',
  get_grouped_by_buckets: 'Logs grouped by __fieldPlaceHolder__ over time',
  get_logs_histogram: 'Time-based logs histogram',
  get_logs_samples: 'View log samples',
  'logzio-search-logs-using-lambda': 'Search logs',
  'get-workload-list': 'Search K8s Resources',
  'get-workload-metrics': 'Get K8s Resource Metrics',
};

const searchQueryFunction = ({ interval, query }: { interval: IntervalData; query: string }) => {
  const dslFilters = queryUtils.getDslFilters([getLuceneFilter(query)], interval);
  const excludesAccounts = searchedIndexesService.getAccountsWithPrefix([
    sessionStateService.session.get().loggedInAccount?.id?.toString(),
  ]);

  return queryUtils.searchWithDateHistogramPayload({ filters: dslFilters, accounts: excludesAccounts, interval });
};

const deploymentQueryFunction = ({ interval }: { interval: IntervalData }) => {
  const dslFilters = queryUtils.getDslFilters([], interval);
  const excludesAccounts = searchedIndexesService.getAccountsWithPrefix([
    sessionStateService.session.get().loggedInAccount?.id?.toString(),
  ]);

  return queryUtils.searchWithDateHistogramPayload({ filters: dslFilters, accounts: excludesAccounts, interval });
};

const groupByQueryFunction = ({ interval, query, field }: { interval: IntervalData; query: string; field: string }) => {
  const dslFilters = queryUtils.getDslFilters([getLuceneFilter(query)], interval);
  const excludesAccounts = searchedIndexesService.getAccountsWithPrefix([
    sessionStateService.session.get().loggedInAccount?.id?.toString(),
  ]);

  return queryUtils.termHistogramPayload({ dslFilters, term: field, limit: 10, interval, accounts: excludesAccounts });
};

const groupBySecondQueryFunction = ({
  interval,
  query,
  field,
  aggregationsByTermAgg,
}: {
  interval: IntervalData;
  query: string;
  field: string;
  aggregationsByTermAgg: opensearchtypes.AggregationsAutoDateHistogramAggregate;
}) => {
  const dslFilters = queryUtils.getDslFilters([getLuceneFilter(query)], interval);
  const excludesAccounts = searchedIndexesService.getAccountsWithPrefix([
    sessionStateService.session.get().loggedInAccount?.id?.toString(),
  ]);

  return queryUtils.otherValuesPayloadBuilder({
    payload: {
      dslFilters,
      term: field,
      interval,
      accounts: excludesAccounts,
    },
    byTermAgg: aggregationsByTermAgg,
  });
};

const graphInvocationQuery: Record<
  keyof typeof invocationTitleMap & `${keyof typeof invocationTitleMap}-second`,
  (values: GraphInvocationQueryType) => IBuildSearchPayload
> = {
  'search-with-date-histogram': searchQueryFunction,
  get_logs_histogram: searchQueryFunction,
  'group-by-with-date-histogram': groupByQueryFunction,
  get_grouped_by_buckets: groupByQueryFunction,
  'group-by-with-date-histogram-second': groupBySecondQueryFunction,
  'get_grouped_by_buckets-second': groupBySecondQueryFunction,
  'logzio-find-deployments': deploymentQueryFunction,
  'logzio-search-logs-using-lambda': searchQueryFunction,
};

const getGraphInvocationQuery = (functionName: string) => {
  return graphInvocationQuery[functionName];
};

const getInvocationTitle = (functionName: string): string => {
  return invocationTitleMap[functionName] ?? '';
};

export const agentInvocationMessageMapUtils = {
  getGraphInvocationQuery,
  getInvocationTitle,
};
