import { FunctionComponent } from 'react';
import { GuidedWalkthroughSubject } from '@logz-pkg/enums';
import { Group, StepsProgress, Divider, Link, Flex } from '@logz-ui/styleguide';
import { GuidedWalkthroughTask } from '@logz-pkg/models';
import { CloseButton } from 'ui/components/shared/ClosedButton/CloseButton';
import { useGuidedWalkthrough } from 'ui/state/hooks/guided-walkthrough.state.hook';
import { PhaseList } from 'ui/components/GuidedWalkthrough/Components/PhaseList';

interface IContentProps {
  tasks: GuidedWalkthroughTask[];
}

export const DrawerContent: FunctionComponent<IContentProps> = ({ tasks }) => {
  const { setIsDrawerOpen, doneTasksIds } = useGuidedWalkthrough();

  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Group vertical fullHeight width={'100%'} subject={GuidedWalkthroughSubject.DrawerContent}>
      <CloseButton subject={GuidedWalkthroughSubject.DrawerCloseButton} onClick={handleClose} />
      <Group p={4} vertical width={'100%'}>
        <StepsProgress
          completed={doneTasksIds.length}
          total={tasks.length}
          title={'Get started with Logz.io'}
          description={`${doneTasksIds.length} of ${tasks.length} task completed`}
        />
      </Group>
      <Divider color={'grey.300'} />
      <PhaseList tasks={tasks} />
      <Divider color="gray.300" />
      <Flex p={4}>
        <Link size={12} href={'https://docs.logz.io/getting-started'}>
          Getting started in our docs
        </Link>
      </Flex>
    </Group>
  );
};
