import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Flex, Text, Box } from '@logz-ui/styleguide';
import { AppMode, SidenavSubjects, ProductKeys } from '@logz-pkg/enums';
import { IExpandedNavItemConfig, IExpandedNavGroup, IDisplayCondition } from '../item-configs';
import { checkDisplayConditions } from '../../utils/check-display-condition';
import { useNavigationDisplayCondition } from '../../utils/use-navigaition-display-condition';
import { ExpandedSidenavItem } from './Item';
import { SidenavComponentsMapping } from './Components';

interface IExpandedNavItemsGroupProps {
  navGroup: IExpandedNavGroup;
  productName: string;
  appMode: AppMode | undefined;
  productKey: ProductKeys;
  currentProduct: ProductKeys;
}

export const GroupsContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ExpandedNavItemsGroup: FunctionComponent<IExpandedNavItemsGroupProps> = ({
  navGroup,
  appMode,
  productName,
  productKey,
  currentProduct,
  ...props
}) => {
  const [allowedNavGroupItems, sessionState] = useNavigationDisplayCondition(navGroup.items);

  if (!allowedNavGroupItems.length) return null;

  return (
    <GroupsContainer {...props}>
      {navGroup?.title && (
        <Box py={2} px={5}>
          <Text size={12} color={'gray.800'} upperCase>
            {navGroup.title}
          </Text>
        </Box>
      )}
      {allowedNavGroupItems.map((expandedNavItemConfig: IExpandedNavItemConfig) => {
        if (expandedNavItemConfig.component) {
          const Component = SidenavComponentsMapping[expandedNavItemConfig.component];

          return <Component key={expandedNavItemConfig.id} />;
        }

        return (
          <React.Fragment key={expandedNavItemConfig.text}>
            <ExpandedSidenavItem
              key={expandedNavItemConfig.text || expandedNavItemConfig.id}
              config={expandedNavItemConfig}
              appMode={appMode}
              productName={productName}
              productKey={productKey}
              currentProduct={currentProduct}
              subject={`${SidenavSubjects.SubItemPrefix}${expandedNavItemConfig.text}`}
            />
            {expandedNavItemConfig.subItems &&
              expandedNavItemConfig.subItems
                .filter(subItem =>
                  checkDisplayConditions({
                    conditions: subItem.displayConditions as IDisplayCondition[],
                    sessionState,
                  }),
                )
                .map(subItem => (
                  <ExpandedSidenavItem
                    key={subItem.text}
                    config={subItem}
                    appMode={appMode}
                    productName={productName}
                    productKey={productKey}
                    currentProduct={currentProduct}
                    subject={`${SidenavSubjects.SubItemPrefix}${subItem.text}`}
                    subItem
                  />
                ))}
          </React.Fragment>
        );
      })}
    </GroupsContainer>
  );
};
