import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Spinner } from '@logz-ui/styleguide';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { getReactMarkdownModules } from 'ui/components/shared/ReactMarkdown/react-markdown';
import { GlitchPage } from 'ui/components/AppProvider';

// DEV-23946 Create list component - styleguide
const StyledContent = styled(Box)`
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
`;

interface IMarkdownContentProps {
  markdowns: string[];
  renderers: { [nodeType: string]: React.ElementType };
  subject?: string;
  shouldShowLoader?: boolean;
}

export const MarkdownRender: FunctionComponent<IMarkdownContentProps> = ({
  markdowns,
  renderers,
  subject,
  shouldShowLoader = true,
}) => {
  const {
    isPending: isReactMarkdownPending,
    action: getReactMarkdown,
    data: reactMarkdownModules,
    error: reactMarkdownError,
  } = useAsyncAction(getReactMarkdownModules);

  useEffect(() => {
    getReactMarkdown();
  }, []);

  if (reactMarkdownError) return <GlitchPage />;

  if (!markdowns.length || isReactMarkdownPending || !reactMarkdownModules) {
    if (!shouldShowLoader) return <></>;

    return <Spinner fullscreen title={'Loading Content'} />;
  }

  const [ReactMarkdown, gfmPlugin] = reactMarkdownModules;

  return (
    <StyledContent subject={subject}>
      {markdowns.map((parsedMarkdown, i) => {
        return typeof parsedMarkdown === 'string' ? (
          <ReactMarkdown
            plugins={[gfmPlugin]}
            allowDangerousHtml={false}
            key={i}
            source={parsedMarkdown}
            renderers={renderers}
          />
        ) : (
          parsedMarkdown
        );
      })}
    </StyledContent>
  );
};
