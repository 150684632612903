import { ExplorePageSubjects } from '@logz-pkg/enums';
import { useObservable } from '@logz-pkg/observable';
import { Group, MultiSelectFilter, Radio, Text } from '@logz-ui/styleguide';
import { Option } from '@logz-ui/styleguide/components/SearchFilters/types';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { exploreGraphEventsService } from 'ui/components/Explore/state/explore-graph-events.service';

export const ServicesSelect = ({ name }) => {
  const allMarkers = useObservable(exploreGraphEventsService.allEvents);
  const isLoading = useObservable(exploreGraphEventsService.isLoading);

  const { values, setFieldValue } = useFormikContext();
  const servicesValue = values[name] ?? [];

  const [showAllEventsRadio, setShowAllEventsRadio] = React.useState(exploreGraphEventsService.showAllEvents.get());

  const options = useMemo<Option[]>(() => {
    const markerNamesSet = new Set<string>();

    return allMarkers.reduce<Option[]>((acc, { _source }) => {
      const title = _source.__logzio_marker_title;

      if (!markerNamesSet.has(title)) {
        acc.push({ label: title, value: title });
        markerNamesSet.add(title);
      }

      return acc;
    }, []);
  }, [allMarkers]);

  const handleRadioChange = value => {
    setShowAllEventsRadio(value);
    setFieldValue('showAllEvents', value);
  };

  return (
    <Group width={'fit-conetent'} vertical gap={3}>
      <Radio.Group
        subject={ExplorePageSubjects.ExploreDeploymentsSetting}
        width={'200px'}
        alignItems={'center'}
        height={'30px'}
        horizontal
      >
        <Radio
          subject={ExplorePageSubjects.ExploreDeploymentsSettingAll}
          checked={showAllEventsRadio}
          value={true}
          onChange={handleRadioChange}
          label={
            <Group>
              <Text>All</Text>
            </Group>
          }
        />
        <Radio
          subject={ExplorePageSubjects.ExploreDeploymentsSettingSelected}
          value={false}
          onChange={handleRadioChange}
          checked={!showAllEventsRadio}
          label={
            <Group>
              <Text>Selected</Text>
            </Group>
          }
        />
      </Radio.Group>
      {!showAllEventsRadio && (
        <MultiSelectFilter
          minLabelWidth={200}
          loading={isLoading}
          name={name}
          label={`Deployments${servicesValue.length === 0 ? ` (${options.length})` : ''}`}
          options={options}
        />
      )}
    </Group>
  );
};
