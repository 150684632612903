// TableCell.tsx
import { ElasticsearchFieldType, ExplorePageSubjects } from '@logz-pkg/enums';
import { Cell } from '@tanstack/react-table';
import { isNil } from 'lodash';
import React from 'react';
import { SearchResultRecord } from '../Table/types';
import { EmptyCell } from './components/EmptyCell';
import { ExceptionCell } from './components/ExceptionCell';
import { LinkCell } from './components/LinkCell';
import { SourceCell } from './components/SourceCell';
import { StringCell } from './components/StringCell';

interface ITableCellProps {
  cell: Cell<SearchResultRecord, unknown>;
  fieldName: string;
  fieldType?: ElasticsearchFieldType;
  isExpanded?: boolean;
}

export const TableCell = React.memo<ITableCellProps>(({ cell, fieldType, isExpanded = false, fieldName }) => {
  const value: any = cell.getValue();
  const { column, row } = cell;
  const { columnDef } = column;

  if (isNil(value) || value === '') {
    return <EmptyCell columnDef={columnDef} row={row} />;
  }

  const commonProps = {
    columnDef,
    row,
    value,
    isCellExpanded: isExpanded,
  };

  if (fieldName === '_source') {
    return <SourceCell {...commonProps} subject={`${ExplorePageSubjects.TableJsonFormatter}-${row.index}`} />;
  }

  if (fieldName === 'logzio-investigate') {
    return <LinkCell href={`${window.location.origin}/#/view-in-explore?${value}`} linkText="Investigate" />;
  }

  if (fieldName === 'exceptions') {
    return <ExceptionCell {...commonProps} />;
  }

  if (fieldType === ElasticsearchFieldType.Boolean) {
    const formattedValue = Array.isArray(value) ? value : String(value);

    return <StringCell {...commonProps} value={formattedValue} />;
  }

  return <StringCell {...commonProps} />;
});

TableCell.displayName = 'TableCell';
