import { Observable } from '@logz-pkg/observable';
import { AgentChatRecord, IAiConversationModel } from '@logz-pkg/models';
import { EVENTS, aiApiService, eventEmitter } from '@logz-pkg/frontend-services';
import { AIDataSource } from '@logz-pkg/enums';
import { isMoreThanTimeAgo } from '@logz-pkg/utils';
import { aiStateService } from '../ai-state.service';
import { aiDataSourceService } from '../data-source/data-source.service';
import { aiFetchDataService } from '../fetch-data/fetch-data.service';
import { aiHistoryService } from '../history/history.service';
import { exceptionsService } from 'ui/components/Explore/state/exceptions.service';

class AiConversationsService {
  readonly shouldShowConversationsPage = new Observable<boolean>(false);

  saveConversation = async ({ historyItems }: { historyItems: AgentChatRecord[] }) => {
    const convId = aiStateService.convId.get();
    const dataSource = aiDataSourceService.dataSource.get();

    if (convId) {
      const lastIndex = historyItems.findLastIndex(item => item.role === 'User');
      const lastQuestionHistoryItems = lastIndex !== -1 ? historyItems.slice(lastIndex) : [];

      await aiApiService.aiAddConversationHistoryItems(
        {
          historyItems: lastQuestionHistoryItems,
        },
        convId,
      );
    } else {
      const isRca = dataSource === AIDataSource.RCA;
      const { id } = await aiApiService.aiSaveConversation({
        title: historyItems.find(item => item.role === 'User')?.content,
        dataSource,
        sessionId: aiStateService.sessionId.get(),
        historyItems,
        rcaId: isRca ? aiStateService.rcaId.get() : '',
      });

      if (isRca) {
        await exceptionsService.fetchExceptions('COUNT', exceptionsService.isOnlyNewExceptionsO.get());
      }

      aiStateService.convId.set(id);
    }
  };

  setConversation = async ({ item, isAgent }: { item: IAiConversationModel; isAgent: boolean }) => {
    const { historyItems, id, dataSource, sessionId, createdAt, updatedAt } = await aiApiService.aiGetConversation(
      item.id,
    );

    eventEmitter.emit(EVENTS.STOP_LAST_REQUEST);
    aiHistoryService.setHistory(historyItems, isAgent);
    aiFetchDataService.setLoading(false);
    aiStateService.setUserInput('');
    aiStateService.convId.set(id);
    aiStateService.sessionId.set(sessionId);
    aiStateService.createdAt.set(createdAt);
    aiStateService.isSessionExpired.set(isMoreThanTimeAgo({ date: updatedAt, timeInMinutes: 30 }));
    aiDataSourceService.setDataSource(dataSource);
    this.shouldShowConversationsPage.set(false);
  };

  setShouldShowConversationsPage = async (shouldShowConversationsPage: boolean) => {
    this.shouldShowConversationsPage.set(shouldShowConversationsPage);
    aiStateService.shouldAutoScroll.set(false);
  };
}

export const aiConversationsService = new AiConversationsService();
