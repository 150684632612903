import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTableOfContentService } from './tableOfContent.hook';
import { MarkdownTitle } from 'ui/components/shared/Markdown/Titles';

type MarkdownV2HeaderComponentProps = {
  level: number;
  h5HeaderCounter: number;
  headersCounter: number;
  children: React.ReactNode;
};

export const MarkdownHeaderComponent: FunctionComponent<MarkdownV2HeaderComponentProps> = ({
  level,
  h5HeaderCounter,
  headersCounter,
  children,
}) => {
  let id;
  const { tableOfContent, setTableOfContent } = useTableOfContentService();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const name = React.Children.toArray(children)
      .map((k: any) => k.props.value)
      .join(' ');

    setTableOfContent(ref.current, headersCounter, {
      name,
      level,
    });
  }, [ref.current]);

  if (tableOfContent && tableOfContent[headersCounter]) {
    id = tableOfContent[headersCounter].anchor;
  }

  return (
    <div ref={ref}>
      <MarkdownTitle id={id} title5Counter={h5HeaderCounter} level={level}>
        {children}
      </MarkdownTitle>
    </div>
  );
};
