import { Observable } from '@logz-pkg/observable';
import { colorService } from '@logz-pkg/utils';
import { IIconProps } from '@logz-ui/styleguide/components/icons/Icon.component';
import { getIconNameAndPrefix } from '@logz-ui/styleguide/components/icons/get-icon-name-and-prefix';
import { Chart, DashStyleValue, XAxisPlotLinesOptions } from 'highcharts';
import { get } from 'lodash';
import { colors } from '../../../../../components';
import { EventPopupData, GenericEventDetails, GraphEventConfig, GraphEvents } from '../types';

interface IGetStringIconComponent {
  iconName: IIconProps['icon'];
  color: string;
  size?: number;
  isMulti?: boolean;
}

const getStringIconComponent = ({ iconName, color, size = 14, isMulti = false }: IGetStringIconComponent) => {
  const iconNameAndPrefix = getIconNameAndPrefix(iconName);
  const iconInformation = (window as any).FontAwesome?.findIconDefinition(iconNameAndPrefix).icon;
  const [height, width, styles, aliases, path] = iconInformation;

  return `
  
      <div class="font-awesome-icon-container" height="${size}px" width="${size}px" title="${
    isMulti ? 'deployments' : 'deployment'
  }">
        <svg aria-hidden="true" focusable="false" data-prefix="${iconNameAndPrefix.prefix}" data-icon="${
    iconInformation.iconName
  }" class="svg-inline--fa fa-${
    iconNameAndPrefix.iconName
  }" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${height} ${width}" data-logz-test-context="icon" data-logz-test-subject="${iconName}">
          <path fill="${color}" d="${path}"></path>
        </svg>
              ${
                isMulti
                  ? `<span style="color: ${color}; fontSize: 12px; left: 4px; bottom: -4px; position: absolute">+</span>`
                  : ''
              }
      </div>
  `;
};

const groupEventsByDensity = (
  events: GraphEvents<GenericEventDetails>,
  chart: Chart,
  sectionWidthPx = 25,
): Record<number, GraphEventConfig<GenericEventDetails>[]> => {
  if (!chart || events?.data?.length === 0) return {};

  const groupedEvents: Record<number, GraphEventConfig<GenericEventDetails>[]> = {};
  const extremes = chart.xAxis[0].getExtremes();
  const minTimestamp = extremes.min;
  const maxTimestamp = extremes.max;
  const { chartWidth } = chart;

  const pixelPerTimestamp = chartWidth / (maxTimestamp - minTimestamp);

  const sortedEvents = events.data.toSorted((a, b) => a.value - b.value);

  let currentSectionStart = sortedEvents[0].value;

  let currentSectionEvents: GraphEventConfig<GenericEventDetails>[] = [];

  sortedEvents.forEach((event, index) => {
    const plotlinePositionPx = (event.value - currentSectionStart) * pixelPerTimestamp;

    if (plotlinePositionPx <= sectionWidthPx) {
      currentSectionEvents.push(event);
    } else {
      const sectionCenter = Math.round(
        currentSectionStart + (currentSectionEvents[currentSectionEvents.length - 1].value - currentSectionStart) / 2,
      );

      groupedEvents[sectionCenter] = currentSectionEvents;

      currentSectionStart = event.value;
      currentSectionEvents = [event];
    }

    if (index === sortedEvents.length - 1 && currentSectionEvents.length > 0) {
      const sectionCenter = Math.round(
        currentSectionStart + (currentSectionEvents[currentSectionEvents.length - 1].value - currentSectionStart) / 2,
      );

      groupedEvents[sectionCenter] = currentSectionEvents;
    }
  });

  return groupedEvents;
};

type RenderEventPlotlines = {
  groupedEvents: ReturnType<typeof groupEventsByDensity>;
  onClick: (data: EventPopupData) => void;
  menuObservable: Observable<EventPopupData>;
  chart: Chart;
};

const addClickHandlerToEventPlotlineLabels = ({
  groupedEvents,
  onClick,
  chart,
  menuObservable,
}: RenderEventPlotlines) => {
  const { xAxis } = chart;
  const plotLines = (xAxis[0] as any).plotLinesAndBands || [];

  const getMenuId = (timestamp: number) => `highcharts-plotline-label-${timestamp}`;

  for (const plotline of plotLines) {
    const plotlineLabelElement = plotline.label?.element;

    if (!plotlineLabelElement) continue;

    if (!plotlineLabelElement.getAttribute('data-click-listener')) {
      plotlineLabelElement.addEventListener('click', (clickEvent: PointerEvent) => {
        const menuId = getMenuId(Number(plotline.options.value));
        const currentMenu = menuObservable.get();

        if (currentMenu?.id === menuId) return;

        const events = groupedEvents[plotline.options.value];

        onClick({
          id: menuId,
          events: events.map(event => event.details),
          pointerEvent: clickEvent,
          plotline: { data: plotline, color: events[0].lineColor },
        });
      });

      plotlineLabelElement.setAttribute('data-click-listener', 'true');
    }
  }
};

export const renderEventPlotlines = ({ groupedEvents, onClick, menuObservable, chart }: RenderEventPlotlines) => {
  const [xAxis] = chart.xAxis;
  const previousPlotlines = xAxis.userOptions.plotLines || [];

  previousPlotlines.forEach(plotLine => {
    xAxis.removePlotLine(plotLine.id);
  });

  const getConnectedPlotLine = plotline => {
    const currentValue = plotline.options.value;

    return (xAxis as any).plotLinesAndBands.find(line => line.options.value === currentValue);
  };

  const isPlotlineActive = plotline => {
    const currentValue = plotline.options.value;

    const menuData = menuObservable.get();
    const activePlotLine = menuData?.plotline?.data?.options?.value;

    return activePlotLine === currentValue;
  };

  const getMenuId = (timestamp: number) => `highcharts-plotline-${timestamp}`;

  const newPlotlines = Object.entries(groupedEvents).flatMap(([timestamp, eventsGroup]) => {
    const firstEvent = eventsGroup[0];
    const plotlineCenterCoords = { x: 2, y: 17 };

    const visiblePlotLine: XAxisPlotLinesOptions = {
      dashStyle: firstEvent.dashStyle,
      color: get(colors, firstEvent.lineColor),
      width: 2,
      value: Number(timestamp),
      zIndex: 1,
      className: 'highcharts-visible-plotline',
      label: {
        text: graphUtils.getStringIconComponent({
          isMulti: eventsGroup.length > 1,
          iconName: firstEvent.icon,
          color: get(colors, firstEvent.iconColor),
        }),
        align: 'center',
        verticalAlign: 'bottom',
        useHTML: true,
        rotation: 0,
        style: {
          cursor: 'pointer',
        },
        ...plotlineCenterCoords,
      },
    };

    const hitboxPlotLine: XAxisPlotLinesOptions = {
      dashStyle: 'Solid' as DashStyleValue,
      color: 'rgba(0,0,0,0)',
      width: 10,
      value: Number(timestamp),
      zIndex: 2,
      className: 'highcharts-hitbox-plotline',
      events: {
        click(clickEvent: PointerEvent) {
          const currentMenuData = menuObservable.get();
          const menuId = getMenuId(Number(timestamp));

          if (currentMenuData?.id === menuId) return;

          const connectedPlotLine = getConnectedPlotLine(this);

          const eventData: EventPopupData = {
            id: `highcharts-plotline-${Number(timestamp)}`,
            events: eventsGroup.map(event => event.details),
            pointerEvent: clickEvent,
            plotline: { data: connectedPlotLine, color: firstEvent.lineColor },
          };

          onClick(eventData);
        },
        mouseover() {
          const isActive = isPlotlineActive(this);

          if (isActive) return;

          const connectedPlotLine = getConnectedPlotLine(this);

          connectedPlotLine.svgElem.attr({
            stroke: colorService.shadeColor(get(colors, firstEvent.lineColor), -50),
          });
        },
        mouseout() {
          const isActive = isPlotlineActive(this);

          if (isActive) return;

          const connectedPlotLine = getConnectedPlotLine(this);

          connectedPlotLine.svgElem.attr({ stroke: get(colors, firstEvent.lineColor) });
        },
      },
    };

    return [visiblePlotLine, hitboxPlotLine];
  });

  newPlotlines.forEach(plotline => {
    xAxis.addPlotLine(plotline);
  });
};

export const graphUtils = {
  getStringIconComponent,
  addClickHandlerToEventPlotlineLabels,
  groupEventsByDensity,
  renderEventPlotlines,
};
