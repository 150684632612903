import { FunctionComponent } from 'react';
import { NotificationService, Group, ButtonGroup, Spinner } from '@logz-ui/styleguide';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { LogzioTheme, SidenavSubjects } from '@logz-pkg/enums';
import { ErrorHandlingService } from 'services/error-handling.service';
import { themeService } from 'services/theme/theme.service';
import { useSessionState } from 'ui/state/hooks';

const logzThemeToLabel = {
  [LogzioTheme.Light]: 'Light',
  [LogzioTheme.Dark]: 'Dark',
};

const themeOptions = [
  { value: LogzioTheme.Light, label: logzThemeToLabel[LogzioTheme.Light] },
  { value: LogzioTheme.Dark, label: logzThemeToLabel[LogzioTheme.Dark] },
];

export const ThemePicker: FunctionComponent = () => {
  const { userSettings } = useSessionState();
  const { action: changeTheme, isPending } = useAsyncAction(themeService.setLogzioTheme, {
    onSuccess: () => {
      NotificationService.success({
        message: 'Updated the Logz.io Theme',
      });
    },
    onFailure: error => {
      ErrorHandlingService.handleApiError({
        title: 'Failed to update Logz.io Theme',
        error,
      });
    },
  });

  const handleThemeChange = async newThemeValue => {
    await changeTheme({ currentUserSettings: userSettings, newUserTheme: newThemeValue });
  };

  return (
    <Group pl={5} pb={2}>
      {isPending ? (
        <Spinner fullscreen size={'s'} />
      ) : (
        <ButtonGroup
          options={themeOptions}
          value={userSettings?.logzioTheme}
          onChange={handleThemeChange}
          subject={SidenavSubjects.ThemeSelector}
        />
      )}
    </Group>
  );
};
