import {
  AccountType,
  AppMode,
  CostManagementTabs,
  FeatureFlags,
  FieldMappingsTabs,
  Permissions,
  PLAN_TYPE,
  Product,
  ProductKeys,
  UserRole,
} from '@logz-pkg/enums';
import { costManagementRouteName } from '../../../../../../app/states/cost-management.route';
import { metricsDropFiltersRouteName } from '../../../../../../app/states/drop-metrics.routes';
import { logsToMetricsStates } from '../../../../../../app/states/logs-to-metrics.routes';
import { metricsRollupsRouteName } from '../../../../../../app/states/metrics-rollups.routes';
import { dropFiltersStateName, logsDropFiltersStateName } from '../../../../../../app/states/settings.routes';
import { tracingRouteStates } from '../../../../../../app/states/tracing/tracing-sampling.routes.names';
import { dataParsingModalStateService } from '../../../../DataParsing';
import { integrationsRouteStates } from '../../../../../../app/states/integrations/route-config';
import { ConditionType, ISidenavItemConfig } from './item-configs';
import { ExtendedLogos } from 'ui/components/shared/Logo/Logo';
import { IntercomService } from 'services/common/intercom.service';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';

export const dataHubItemConfig: ISidenavItemConfig = {
  fixedSidenavText: 'Data Hub',
  expandedSidenavTitle: 'Data Hub',
  key: ProductKeys.DataHub,
  logo: ExtendedLogos.DataHub,
  mode: AppMode.OPERATIONS,
  targetStateParams: { table: CostManagementTabs.Logs },
  targetStateName: costManagementRouteName,
  displayConditions: [
    {
      type: ConditionType.OneOfUserRoles,
      value: [UserRole.Admin, UserRole.Expert],
    },
  ],
  expandedNavGroups: [
    {
      displayConditions: [
        {
          type: ConditionType.UserRole,
          value: UserRole.Expert,
        },
      ],
      title: 'Data Management',
      items: [
        {
          icon: 'regular-box-archive-rotate-left-custom',
          text: 'Archive and restore',
          targetStateName: 'dashboard.tools.archive-and-restore',
          productIcon: Product.LogAnalytics,
          displayConditions: [
            {
              type: ConditionType.OneOfNotAccountPlan,
              value: [PLAN_TYPE.FREE],
            },
          ],
        },
      ],
    },
    {
      displayConditions: [
        {
          type: ConditionType.UserRole,
          value: UserRole.Admin,
        },
      ],
      title: 'Send your data',
      items: [
        {
          icon: 'paper-plane-regular',
          text: 'Telemetry collectors',
          targetStateName: integrationsRouteStates.fleet,
          displayConditions: [
            {
              type: ConditionType.HasFeatureFlag,
              value: FeatureFlags.TelemetryAgent,
            },
            {
              type: ConditionType.HasFeatureFlag,
              value: FeatureFlags.TelemetryAgentFleet,
            },
            { type: ConditionType.AccountOfType, value: AccountType.Owner },
            { type: ConditionType.UserRole, value: UserRole.Admin },
          ],
          action: {
            targetStateName: integrationsRouteStates.newCollector,
            eventName: 'new collector',
          },
          innerStates: [integrationsRouteStates.create, integrationsRouteStates.installation],
        },
      ],
    },
    {
      displayConditions: [
        {
          type: ConditionType.UserRole,
          value: UserRole.Admin,
        },
      ],
      title: 'Data Management',
      items: [
        {
          text: 'Data optimization',
          icon: 'lightbulb-dollar-regular',
          targetStateName: costManagementRouteName,
          targetStateParams: { table: CostManagementTabs.Logs },
        },
        {
          icon: 'regular-filter-gear-custom',
          text: 'Drop filters',
          targetStateName: dropFiltersStateName,
          subItems: [
            {
              icon: 'regular-filter-gear-custom',
              text: 'Logs',
              targetStateName: logsDropFiltersStateName,
            },
            {
              icon: 'regular-filter-gear-custom',
              text: 'Metrics',
              targetStateName: metricsDropFiltersRouteName,
            },
            {
              icon: 'filter-regular',
              text: 'Traces Sampling',
              targetStateName: tracingRouteStates.samplingRules,
            },
          ],
        },
        {
          icon: 'metrics-prod-icon-solid-custom',
          text: 'Log Metrics',
          targetStateName: 'dashboard.logs-to-metrics.definitions',
          displayConditions: [
            {
              type: ConditionType.HasFeatureFlag,
              value: FeatureFlags.LogsToMetrics,
            },
            {
              type: ConditionType.Permission,
              value: Permissions.LOG_METRIC_READ,
            },
          ],
          action: {
            targetStateName: logsToMetricsStates.create,
            eventName: 'new log metric',
            displayConditions: [
              {
                type: ConditionType.Permission,
                value: Permissions.LOG_METRIC_CREATE,
              },
            ],
          },
          innerStates: [logsToMetricsStates.create, logsToMetricsStates.edit],
          productIcon: Product.Metrics,
        },
        {
          icon: 'regular-box-archive-rotate-left-custom',
          text: 'Archive and restore',
          targetStateName: 'dashboard.tools.archive-and-restore',
          productIcon: Product.LogAnalytics,
          displayConditions: [
            {
              type: ConditionType.OneOfNotAccountPlan,
              value: [PLAN_TYPE.FREE],
            },
          ],
        },
        {
          text: 'Rollups',
          icon: 'rollups-solid-custom',
          targetStateName: metricsRollupsRouteName,
          displayConditions: [
            {
              type: ConditionType.HasFeatureFlag,
              value: FeatureFlags.MetricsRollups,
            },
          ],
          productIcon: Product.Metrics,
        },
      ],
    },
    {
      displayConditions: [
        {
          type: ConditionType.UserRole,
          value: UserRole.Admin,
        },
      ],
      title: 'Data Configuration',
      items: [
        {
          icon: 'objects-align-left-regular',
          text: 'Data parsing',
          onClick: () => {
            if (featureFlagStateService.isFeatureEnabled('DiyDataParsing')) {
              return dataParsingModalStateService.showDataParsingModal();
            }

            return IntercomService.showNewMessage('Help me with data parsing');
          },
          displayConditions: [{ type: ConditionType.Permission, value: Permissions.DATA_PARSING }],
          productIcon: Product.LogAnalytics,
        },
        {
          icon: 'sitemap-regular',
          text: 'Field mappings',
          targetStateName: FieldMappingsTabs.Base,
          innerStates: [FieldMappingsTabs.Mappings, FieldMappingsTabs.Errors],
          productIcon: Product.LogAnalytics,
        },
      ],
    },
  ],
};
