export const securityRouteState = 'dashboard.security';

export const securitySummaryRouteName = 'dashboard.security-summary';

export const researchRouteName = 'dashboard.security-research';
export const researchViewUrlPrefix = '/dashboard/security/research';
export const researchDashboardRouteName = 'dashboard.security-research-dashboards';

export const securityThreatsOverviewRouteName = 'dashboard.security-threats-overview';
export const securityThreatsRouteName = 'dashboard.security.threats';

export const securityLookupsListRouteName = 'dashboard.security.lookups';
export const securityLookupsListEditRouteName = 'dashboard.security.lookups-edit';
export const securityDynamicLookupEditRouteName = 'dashboard.security.dynamic-lookup-edit';
export const securityDynamicLookupCreateRouteName = 'dashboard.security.dynamic-lookup-create';

export const securityEventManagementRouteName = 'dashboard.security.event-management';

export const startSiemTrialRouteName = 'dashboard.start-seim-trial';
