import { AppStateDeclaration } from 'states/AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const awsMarketplaceRouteNames = {
  marketplace: 'aws-marketplace',
};

const LazyAwsMarketplaceComponent = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Auth/AwsAccountConnect/AwsAccountConnect')}
    resolver={({ AwsAccountConnect }) => AwsAccountConnect}
    {...props}
  />
);

export const awsMarketplaceRoutes: AppStateDeclaration[] = [
  {
    name: awsMarketplaceRouteNames.marketplace,
    url: '/aws-marketplace?awsMarketplaceToken',
    component: LazyAwsMarketplaceComponent,
    data: {
      breadcrumb: { label: 'AWS Marketplace' },
      title: 'AWS Marketplace',
      onlyAnonymous: true,
    },
  },
];
