import { Box, Text } from '@logz-ui/styleguide';
import type { ColumnDef, Row } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useObservable } from '@logz-pkg/observable';
import { isNil } from 'lodash';
import { useHighlight } from '../hooks/highlight.hook';
import { SearchResultRecord } from '../../Table/types';
import { useCellProps } from './cell-props.hook';
import { graphStateService } from 'ui/components/Explore/state/graph-state.service';
import { exploreSearchParamsService } from 'ui/components/Explore/router/router';
import { preDefinedGraphColors } from 'ui/components/Explore/constants';

interface IStringColumnProps {
  value: string | string[];
  height?: string;
  alias?: string;
  row: Row<SearchResultRecord>;
  columnDef: ColumnDef<SearchResultRecord>;
  isCellExpanded?: boolean;
}

export const StringCell: React.FC<IStringColumnProps> = ({ height, value, row, columnDef, alias, isCellExpanded }) => {
  const { maxLines, isExpanded, rowHeight } = useCellProps({ columnDef, row });
  const groupBy = useObservable(exploreSearchParamsService.groupBy);
  const colorsMap = useObservable(graphStateService.mappedGraphColors);
  const highlight = row.original.highlight?.[columnDef.id];

  const style = useMemo(() => {
    let color, weight;

    if (!isNil(value) && !isNil(groupBy)) {
      if (groupBy === columnDef.id) {
        color = preDefinedGraphColors[String(value).toLowerCase()] ?? graphStateService.getValueColor(String(value));
      } else {
        color = graphStateService.getValueColor('other');
      }

      weight = groupBy === columnDef.id ? 700 : 400;
    }

    return { color, weight };
  }, [groupBy, value, colorsMap]);

  const content = useHighlight({ highlight, value, style });

  return (
    <Box height={isExpanded || isCellExpanded ? height : rowHeight} minHeight={rowHeight} width={'100%'}>
      <Text
        {...style}
        variant="code"
        size={12}
        ellipsis={
          isCellExpanded
            ? undefined
            : {
                maxLines: isExpanded ? 8 : maxLines,
                noTooltip: true,
                wordBreak: isExpanded ? 'normal' : 'break-all',
              }
        }
        whiteSpace={isExpanded || isCellExpanded ? 'pre-wrap' : 'initial'}
      >
        {alias ?? content}
      </Text>
    </Box>
  );
};
