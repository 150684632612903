import { hashLocationPlugin, pushStateLocationPlugin, servicesPlugin, UIRouterReact } from '@uirouter/react';
import { StickyStatesPlugin } from '@uirouter/sticky-states';
import { debounce } from 'lodash';

export const appRouter = new UIRouterReact();

// activate plugins
appRouter.plugin(servicesPlugin);
appRouter.plugin(pushStateLocationPlugin);
appRouter.plugin(StickyStatesPlugin as any);
// TODO: DEV-23633 - Remove
appRouter.plugin(hashLocationPlugin);
appRouter.urlService.config.strictMode(false);
// Either login page if unauthenticated, or root state of each product.

export const debouncedUrlSync = debounce(() => {
  appRouter.urlService.sync();
}, 10);
