import { aiApiService, LoggerService } from '@logz-pkg/frontend-services';
import { AIDataSource } from '@logz-pkg/enums';
import { aiDataSourceService } from './state/data-source/data-source.service';
import { report } from './hooks/analytics.service';

const loggerInfo = (message: string, extraData: Record<string, unknown>): void => {
  LoggerService.logInfo({
    message,
    extra: { dataSource: aiDataSourceService.dataSource.get(), ...extraData, logIdentifier: 'AI/INFO' },
  });
};

const loggerError = (message: string, extraData: Record<string, unknown>): void => {
  LoggerService.logError({
    message,
    extra: { dataSource: aiDataSourceService.dataSource.get(), ...extraData, logIdentifier: 'AI/ERROR' },
  });
};

const reportUserInput = async (params: {
  prompt: string;
  dataSource: AIDataSource;
  context: unknown;
  componentType;
  meta;
}): Promise<void> => {
  const { topic } = await aiApiService.aiCategorizeText(params.prompt);

  await report('User started conversation', {
    dataSource: params.dataSource,
    topic,
  });

  await loggerInfo('User submission', { ...params, topic });
};

export const aiUtils = {
  loggerInfo,
  loggerError,
  reportUserInput,
};
