import { debounce, isNil } from 'lodash';
import type { Transition } from '@uirouter/core';
import { authService, authTokenService, sessionIdService, userService } from '@logz-pkg/frontend-services';
import { SearchSource, mapAppStateToSearchSource } from '@logz-pkg/models';
import { removeHash } from '@logz-pkg/utils';
import { NotificationService } from '@logz-ui/styleguide';
import { appEmbedded } from '../app/configs/app-embedded';
import { registerBreadcrumbTransitions } from './register-breadcrumb-transitions';
import { registerTitleTransitions } from './register-title-transitions';
import { registerSaveParamsTransitions } from './register-save-params-transitions';
import { registerHistoryTracking } from './register-history-tracking';
import { logoutService } from 'core/auth/services/logout.service';
import { switchSessionService } from 'core/auth/services/switch-session.service';
import { analyticsService } from 'services/analytics/analytics.service';
import { IntercomService } from 'services/common/intercom.service';
import { fullStoryService } from 'services/fullstory/fullstory.service';
import { appModeService } from 'services/identity/app-mode/app-mode.service';
import { impersonationService } from 'services/identity/impersonation/impersonation.service';
import { appRouter, debouncedUrlSync } from 'services/router/router.service';
import { dialogService } from 'services/ui-services/dialog.service';
import { recentVisitsStateService } from 'ui/state/recent-visits.state.service';

const SWITCH_USER_DIALOG_ERRORS = {
  SAME_USER: {
    code: 1,
    message: 'User already at the appointed account',
  },
};
const ALLOWED_PARAMS = ['switchToAccountId'];

let confirmPromise: Promise<boolean> | null;

async function confirmSwitchAccountDialog(param) {
  const accountData = await userService.getSummary();

  if (accountData.data.accountId === +param) {
    const error = new Error(SWITCH_USER_DIALOG_ERRORS.SAME_USER.message) as any;

    error.code = SWITCH_USER_DIALOG_ERRORS.SAME_USER.code;
    throw error;
  }

  if (confirmPromise) return confirmPromise;

  confirmPromise = dialogService.confirm({
    title: 'Switch account',
    text: `To view the link you selected, you will be redirected to the relevant account and your current session may expire.`,
    confirmText: 'Continue',
  });

  return confirmPromise;
}

function authenticateStateChange(toState, toParams) {
  if (toState?.data?.authenticate && !authService.isAuthenticated() && !appEmbedded) {
    // User isn’t authenticated
    // In case of public shared link open in new tab, else redirect through login page.
    if (toParams?.shareToken) {
      // We'll never reach this point if its a none session tab because shareToken always
      // populated with 'embed=true' url.
      window.open(window.location.href, '_blank');
    } else {
      // if redirect param exists, use it
      const redirectTo = appRouter.globals.params.redirect || removeHash(window.location.hash);

      logoutService.logout({ redirect: redirectTo }, false);
    }

    return true;
  }

  return false;
}

function handleRouteRestrictions(toState, toParams) {
  function goToRootState() {
    appModeService.goToModeRootState(toParams);

    return true;
  }

  const { onlyAnonymous: onlyAnonymousRoute, adminOnly: adminOnlyRoute } = toState?.data ?? {};

  const isAuthenticated = authService.isAuthenticated();

  if (onlyAnonymousRoute && isAuthenticated) {
    return goToRootState();
  }

  if (adminOnlyRoute) {
    if (!authService.isAdmin()) {
      if (isAuthenticated) return goToRootState();

      return false;
    }

    const isImpersonating = impersonationService.isImpersonating();

    if (isImpersonating) {
      return goToRootState();
    }
  }

  return false;
}

const capturePageView = debounce((transition: Transition) => {
  const options = transition.options();

  if (!options.location) return;

  const fromState = transition.from();
  const toState = transition.to();

  if (fromState.name === toState.name) return;

  const params = Object.fromEntries(
    Object.entries(transition.params()).filter(([key, value]) => ALLOWED_PARAMS.includes(key) && !isNil(value)),
  );

  analyticsService.capture('Global', 'Pageview', {
    params,
    stateName: toState.name,
    title: toState.data?.breadcrumb?.label ?? 'no title',
    sessionId: sessionIdService.getSessionId(),
  });
}, 500);

let inTransition;

export const registerStateChangeHooks = () => {
  appRouter.transitionService.onStart({}, router => {
    /**
     * REMARK: authenticateStateChange should be the first condition so we won't
     * accidentally do other operations when not allowed
     */
    inTransition = true;

    if (authenticateStateChange(router.to(), router.params()) || handleRouteRestrictions(router.to(), router.params())) {
      return Promise.reject('Either not authenticated or you do not have permissions for the transition');
    }
  });

  /**
   * Send a page-view log to logz.io, with full url, name, title and an action.
   * We don't care whether the transition succeeded or not, as the user intended to view that page.
   * https://ui-router.github.io/guide/transitions#atomicity
   */
  appRouter.transitionService.onStart({}, capturePageView);

  appRouter.transitionService.onSuccess({}, () => {
    inTransition = false;
    IntercomService.update();
  });

  const isTransitionTrackable = state => {
    return authService.isAuthenticated() && recentVisitsStateService.isStateWhitelisted(state);
  };

  appRouter.transitionService.onSuccess({ to: isTransitionTrackable }, $transition => {
    const params = $transition.params();

    const switchToAccountId = params.switchToAccountId || authTokenService.getCurrentAccountId();

    recentVisitsStateService.add({
      source: mapAppStateToSearchSource[$transition.$to().name] || SearchSource.LogzPage,
      title: $transition.$to().data?.title || document.title,
      state: {
        name: $transition.$to().name,
        params: { ...params, switchToAccountId },
      },
    });
  });

  appRouter.transitionService.onStart({}, async $transition => {
    const $state = $transition.router.stateService;
    const from = $transition.$from();
    const to = $transition.$to();
    const { switchToAccountId, ...restToParams } = $transition.params();
    const isBlankState = from.abstract;

    if (!switchToAccountId) return;

    const currentAccountId = await userService.getCurrentAccountId();
    const knownChangeAccountId = switchSessionService.getKnownChange();

    if (Number(switchToAccountId) === currentAccountId) return;

    try {
      if (Number(knownChangeAccountId) !== Number(switchToAccountId)) {
        const confirmed = await confirmSwitchAccountDialog(switchToAccountId);

        if (!confirmed) {
          $state.go(to.name, { ...restToParams, switchToAccountId: currentAccountId });

          return;
        }

        await switchSessionService.switchAccount(switchToAccountId);
        /** $state.target does not support the reload: true option for forcing a full state reload.
         To achieve this, the current transition is aborted, and $state.go is used with reload: true. */
        $transition.abort();
        $state.go(to.name, $transition.params(), { reload: true });
      }
    } catch (error) {
      if (error.code === SWITCH_USER_DIALOG_ERRORS.SAME_USER.code) {
        return $state.go(to.name, restToParams);
      }

      if (error.status === 403 && error.data.message) {
        NotificationService.danger({ message: error.data.message });

        return appModeService.goToModeRootState();
      }

      if (isBlankState) {
        // When user open this link in a new window
        return $state.go('login');
      }
    } finally {
      confirmPromise = null;
      switchSessionService.setKnownChange(switchToAccountId);
    }
  });

  appRouter.transitionService.onSuccess({}, async () => {
    await fullStoryService.restartSession();
  });

  registerBreadcrumbTransitions(appRouter);
  registerTitleTransitions(appRouter);
  registerSaveParamsTransitions(appRouter);
  registerHistoryTracking(appRouter);

  // Since UI router might be a bit slow to process multiple url changes very quickly, we sync the url after a small debounce.
  window.addEventListener(
    'hashchange',
    () => {
      // Do not sync while currently in transition - Otherwise it can cause the url of the previous state to persist.
      if (inTransition) return;

      debouncedUrlSync();
    },
    false,
  );
};
