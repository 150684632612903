import React, { useEffect } from 'react';
import { Button, Group, Text, List, ListItem } from '@logz-ui/styleguide';
import {
  AccountType,
  PlanAndBillingState,
  Product,
  SiemOwnerAccountState,
  SiemTrialTestSubject,
  UserRole,
} from '@logz-pkg/enums';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { NotificationService } from '@logz-ui/styleguide/components';
import {
  eventEmitter,
  EVENTS,
  siemAccountSettingsApiService,
  siemAccountsApiService,
} from '@logz-pkg/frontend-services';
import { generalSettingsRoute } from '../../../../app/states/settings.routes';
import { ProductEmptyState as EmptyStateTemplate } from 'ui/components/shared/templates/ProductEmptyState/ProductEmptyState';
import { useSessionState } from 'ui/state/hooks';
import { ContactSupport } from 'ui/components/shared/ContactSupport/ContactSupport';
import { videos } from 'ui/components/shared/Video/videos';
import { useRouter } from 'ui/hooks';

interface IProductEmptyState {
  showViewAvailablePlans?: boolean;
  onSuccess?(): void;
}

const ContactSupportButton: React.FC<{ text?: string; primary?: boolean }> = ({
  primary = false,
  text = 'Contact support',
}) => <ContactSupport text={<Button variant={primary ? 'primary' : 'secondary'}>Extend trial</Button>} />;

const AdminCTA: React.FC<IProductEmptyState & { isTrialExpired: boolean }> = ({
  isTrialExpired,
  showViewAvailablePlans,
  onSuccess,
}) => {
  const { loggedInUser, loggedInAccount, fetchSession } = useSessionState();
  const { goToState } = useRouter();
  const canStartTrial = loggedInUser?.role === UserRole.Admin && loggedInAccount?.type === AccountType.Owner;
  const { action: createSiemTrialAccount, isPending: isCreatingSiemTrialAccount } = useAsyncAction(
    siemAccountsApiService.createTrialAccount,
    {
      onSuccess: async () => {
        eventEmitter.emit(EVENTS.PLAN_CHANGE);
        NotificationService.success({
          message: 'SIEM trial account was successfully created.',
        });
        await fetchSession({ fresh: true });
        onSuccess?.();
      },
      onFailure: ({ message }) => {
        NotificationService.warning({
          title: 'Could not create the SIEM trial account.',
          message,
        });
      },
    },
  );

  const handlePlansClick = () => {
    goToState(PlanAndBillingState.Plan, { productToNavigateTo: Product.Siem });
  };

  if (isTrialExpired)
    return (
      <Group>
        {canStartTrial && <ContactSupportButton primary text={'Extend trial'} />}
        {showViewAvailablePlans && (
          <Button variant={'secondary'} onClick={handlePlansClick}>
            View available plans
          </Button>
        )}
      </Group>
    );

  return (
    <Group>
      {canStartTrial && (
        <Button
          onClick={createSiemTrialAccount}
          loading={isCreatingSiemTrialAccount}
          subject={SiemTrialTestSubject.ConfirmTrialButton}
        >
          Start a free trial
        </Button>
      )}
      <ContactSupportButton />
    </Group>
  );
};

const ProductBulletPoints = () => (
  <List variant={'check'} size={'large'}>
    <ListItem>
      <b>Get alerts on malicious activity</b> with out-of-the-box <b>threat intelligence</b>
    </ListItem>
    <ListItem>
      Gain access to hundreds of <b>prebuilt security rules</b> and <b>dashboards</b>
    </ListItem>
    <ListItem>Boost application protection with a dedicated security analyst</ListItem>
    <ListItem>Enjoy 24/7 support access</ListItem>
  </List>
);

const ExistingSiemAccountEmptyState: React.FC<IProductEmptyState> = ({ showViewAvailablePlans, onSuccess }) => {
  return (
    <EmptyStateTemplate>
      <EmptyStateTemplate.ContentSection>
        <EmptyStateTemplate.Title>Need more time to evaluate our SIEM solution?</EmptyStateTemplate.Title>
        <EmptyStateTemplate.Description>
          <Group vertical gap={3}>
            <Text color={'gray.800'} size={16}>
              Extend your trial and gain access to the following:
            </Text>
            <ProductBulletPoints />
          </Group>
        </EmptyStateTemplate.Description>
        <EmptyStateTemplate.CallToAction>
          <AdminCTA isTrialExpired={true} showViewAvailablePlans={showViewAvailablePlans} onSuccess={onSuccess} />
        </EmptyStateTemplate.CallToAction>
      </EmptyStateTemplate.ContentSection>
      <EmptyStateTemplate.MediaSection src={videos.cloudSiemOnboarding} />
    </EmptyStateTemplate>
  );
};
const NewSiemAccountEmptyState: React.FC<IProductEmptyState> = ({ showViewAvailablePlans, onSuccess }) => {
  return (
    <EmptyStateTemplate>
      <EmptyStateTemplate.ContentSection>
        <EmptyStateTemplate.Title>Enhance your security with SIEM</EmptyStateTemplate.Title>
        <EmptyStateTemplate.Description>
          <Group vertical gap={3}>
            <Text color={'gray.800'} size={16}>
              Detect and investigate threats quickly and efficiently.
            </Text>
            <ProductBulletPoints />
          </Group>
        </EmptyStateTemplate.Description>
        <EmptyStateTemplate.CallToAction>
          <AdminCTA isTrialExpired={false} showViewAvailablePlans={showViewAvailablePlans} onSuccess={onSuccess} />
        </EmptyStateTemplate.CallToAction>
      </EmptyStateTemplate.ContentSection>
      <EmptyStateTemplate.MediaSection src={videos.cloudSiemOnboarding} />
    </EmptyStateTemplate>
  );
};
const ContactAdminEmptyState = () => {
  return (
    <EmptyStateTemplate>
      <Group vertical gap={7} alignItems={'center'}>
        <EmptyStateTemplate.Title>To activate SIEM, contact your account administrator</EmptyStateTemplate.Title>
        <Text variant={'faded'} size={16} textAlign={'center'} color={'gray.600'}>
          Contact your account admin to activate and access the SIEM solution.
          <br />
          For additional assistance, contact Logz.io's support team.
        </Text>
        <EmptyStateTemplate.CallToAction>
          <ContactSupportButton primary />
        </EmptyStateTemplate.CallToAction>
      </Group>
    </EmptyStateTemplate>
  );
};
export const SiemEmptyState: React.FC<IProductEmptyState> = ({ showViewAvailablePlans = true, onSuccess }) => {
  const { goToState } = useRouter();

  const {
    isPending,
    data: ownerAccountSiemState,
    action: loadOwnerAccountSiemState,
    error,
  } = useAsyncAction(siemAccountSettingsApiService.getOwnerAccountStatus, {
    initialIsPending: true,
    onFailure: e => {
      NotificationService.danger({
        title: 'Could not get owner account status for SIEM page',
        message: e.message,
      });

      goToState(generalSettingsRoute.name);

      return null;
    },
  });

  useEffect(() => {
    loadOwnerAccountSiemState();
  }, []);

  if (isPending || error !== null) return null;

  if (ownerAccountSiemState.ownerState === SiemOwnerAccountState.SiemAccountExists) return <ContactAdminEmptyState />;

  if (ownerAccountSiemState.ownerState === SiemOwnerAccountState.InactiveSiemAccount)
    return <ExistingSiemAccountEmptyState showViewAvailablePlans={showViewAvailablePlans} onSuccess={onSuccess} />;

  if (ownerAccountSiemState.ownerState === SiemOwnerAccountState.NoSiemAccount)
    return <NewSiemAccountEmptyState showViewAvailablePlans={showViewAvailablePlans} onSuccess={onSuccess} />;
};
