import { FunctionComponent, useMemo, useState } from 'react';
import { Box, Card, colors, Group, Spinner } from '@logz-ui/styleguide';
import styled from 'styled-components';
import { InlineCodeBlock } from './InlineCodeBlock';
import { useSyntaxHighlight } from './syntax-highlight';
import { AvailableLanguages } from './lazy-load-highlight';
import { CollapsableCodeBadge } from 'ui/components/shared/HighlightJs/components/CollapsableCodeBadge';
import { ContentErrorText } from 'ui/components/shared/HighlightJs/components/ContentErrorText';
import { CodeWrapper } from 'ui/components/shared/HighlightJs/components/CodeWrapper';

const CHARS_PER_LINE = 50;

interface ICodeProps {
  language: keyof typeof AvailableLanguages;
  code: string;
  onCopy?: () => void;
  isLoading?: boolean;
  isError?: boolean;
  subject?: string;
  editableText?: string;
  copyOnClick?: boolean;
  maxLines?: number;
}

const ShadowCover = styled.div``;
const StyledCard = styled(Card)<{ lines: number; allowCollapse: boolean; isOpen: boolean }>`
  border-radius: 4px;
  border: none;
  background-color: ${colors.royalBlue[1000]};

  ${ShadowCover} {
    position: absolute;
    inset: calc(100% - 40px) 0 0 0;
    background: linear-gradient(to bottom, transparent, ${colors.royalBlue[1000]});
    border-radius: 0 0 4px 4px;
    pointer-events: none;
  }

  > * {
    transition: max-height 0.4s ease-out;
    max-height: ${({ lines, isOpen, allowCollapse }) => (isOpen || !allowCollapse ? 2 * lines : lines)}lh;
    overflow: hidden;
  }

  :hover {
    > *:last-child {
      transition: opacity, width 200ms ease-in-out;
      opacity: ${({ allowCollapse }) => (allowCollapse ? 1 : 0)};
    }
  }
`;

export const Code: FunctionComponent<ICodeProps> = ({
  language,
  code,
  onCopy,
  isLoading,
  isError,
  subject,
  editableText,
  maxLines,
  copyOnClick = true,
}) => {
  const {
    highlightedData,
    isPending: isHighlightPending,
    error: highlightError,
  } = useSyntaxHighlight({ language, code, editableText });
  const [isOpen, setIsOpen] = useState(false);
  const codeLineLength = useMemo(
    () => (code ? Math.max(code?.length / CHARS_PER_LINE, code?.trim()?.split('\n')?.length) : 0),
    [code],
  );

  const showLoading = isHighlightPending || isLoading;
  const showError = highlightError || isError;
  const allowCollapse = !showError && maxLines && maxLines < codeLineLength;

  return (
    <Box my={3} width={'100%'} context={'code'} subject={subject} color={'gray.400'} position={'relative'}>
      {showLoading && (
        <Group justifyContent={'center'} width={'100%'}>
          <Spinner fullscreen title={'Loading Content'} subject={`loading-code`} />
        </Group>
      )}
      {!showLoading && (
        <StyledCard
          allowCollapse={allowCollapse}
          isOpen={isOpen}
          lines={allowCollapse && !isOpen ? maxLines : codeLineLength}
          p={2}
        >
          {showError ? (
            <ContentErrorText />
          ) : (
            <CodeWrapper copyOnClick={copyOnClick} text={code} onCopy={onCopy}>
              {!isOpen && allowCollapse && <ShadowCover />}
              <InlineCodeBlock className={`lang-${language}`} dangerouslySetInnerHTML={{ __html: highlightedData }} />
            </CodeWrapper>
          )}
          <CollapsableCodeBadge
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            linesCount={codeLineLength - maxLines}
          />
        </StyledCard>
      )}
    </Box>
  );
};
