/* eslint-disable max-lines */
import { format, parse } from 'url';
import { useEffect, useRef, useState } from 'react';
import { useOnStateChanged, useCurrentStateAndParams } from '@uirouter/react';
import { useForceUpdate } from '@logz-pkg/react-hooks';
import {
  createProductIframeRoute,
  IProductRouteOptions,
  mergeProductToAppUri,
} from '../../../app/product/route-helpers/route-helpers';
import { reportError, reportInfo, reportWarn } from 'ui/components/ProductIframe/report';
import { debouncedUrlSync } from 'services/router/router.service';

export const buildBaseUrl = ({ basePath, productPath = '/', listenOnHashRouting }) => {
  productPath = productPath === '' ? '/' : productPath;

  const { hash: appHash, ...base } = parse(window.location.href);

  if (listenOnHashRouting) {
    const builtUrl = format({
      ...base,
      pathname: basePath,
      hash: productPath,
    });

    return builtUrl;
  }

  const { search: productSearch, pathname: productPathName } = parse(productPath);
  const modifiedPathName = `${basePath}${productPathName || '/'}`;
  const builtUrl = format({
    ...base,
    pathname: modifiedPathName,
    search: productSearch,
  });

  return builtUrl;
};

interface IRouteEventListener extends EventListener {
  newURL: string;
}

type IUseSyncUrlProps = {
  routeOptions: IProductRouteOptions;
  initialStateParams: object;
  productIframeDom: Partial<HTMLIFrameElement>;
  presets: any;
  appWindow: Partial<Window>;
  stateName: string;
};

export const useSyncUrl = ({
  routeOptions,
  initialStateParams,
  presets,
  productIframeDom,
  appWindow = window,
  stateName,
}: IUseSyncUrlProps) => {
  const {
    ignoreRouteUpdate,
    addSwitchToAccountId,
    listenOnHashRouting,
    listenOnHistoryRouting,
    productPathPrefix,
    appQueryParams,
    dynamicSearchParamsRegexp,
    viewUrlPrefix,
    productQueryParams,
    shouldNavigate,
    hideBooleanParams,
    shouldSync,
    refreshOnRoute,
    getAppPathname,
    getProductPathname,
    buildBasePath,
  } = routeOptions;
  const [stateParams, setStateParams] = useState<{}>(initialStateParams);
  const [src, setSrc] = useState<string>();
  const [lastProductUrl, setLastProductUrl] = useState<string>(null);
  const routeChangeListener = useRef<(IRouteEventListener) => void>(null);
  // TODO: DEV-25873 Remove duplications from sync-url.hook
  const { state } = useCurrentStateAndParams();
  const isStateActive = useRef<boolean>(state?.name === stateName);
  const [, forceUpdate] = useForceUpdate();
  const mergeAndSetAppUrl = ({ newProductURL, appURL = window.location.href }) => {
    if (!shouldSync) return;

    if (ignoreRouteUpdate && ignoreRouteUpdate({ newProductURL, appURL })) return;

    const newAppUrl = mergeProductToAppUri({
      newProductURL,
      appURL,
      appQueryParams,
      productPathPrefix,
      viewUrlPrefix,
      switchToAccountId: addSwitchToAccountId ? presets?.configs?.account?.accountId : null,
      listenOnHashRouting,
      getAppPathname,
    });

    setAppUrl({ newAppUrl });
  };

  useOnStateChanged((stateChange, params) => {
    let currentActiveStateChange = false;

    if (stateChange.name === stateName) {
      if (params.resource === null && lastProductUrl && !isStateActive.current) {
        reportInfo(stateName, `returning to previous state ${stateName}`, {
          stateChangeTo: stateChange.name,
          params,
          lastProductUrl,
        });
        mergeAndSetAppUrl({ newProductURL: lastProductUrl });
      } else if (appWindow.location.href.includes(viewUrlPrefix)) {
        reportInfo(stateName, `updating state [${stateName}] params`, {
          stateChangeTo: stateChange.name,
          params,
          lastProductUrl,
        });
        setStateParams(params);
      } else {
        reportWarn(stateName, `updating state [${stateName}] failed, postponing update`, {
          stateChangeTo: stateChange.name,
          appWindowHref: appWindow.location.href,
          params,
          viewUrlPrefix,
        });
        setTimeout(() => setStateParams(params));
      }

      reportInfo(stateName, `isStateActive = true`, { stateChangeTo: stateChange.name, params, lastProductUrl });

      if (isStateActive.current === false) currentActiveStateChange = true;

      isStateActive.current = true;
    } else {
      if (isStateActive.current === true) currentActiveStateChange = true;

      isStateActive.current = false;
      reportInfo(stateName, `isStateActive = false`, { stateChangeTo: stateChange.name, params, lastProductUrl });
      reportInfo(stateName, `different state updated ${stateName}, ignoring`);
    }

    if (currentActiveStateChange) forceUpdate();
  });

  useEffect(() => {
    const productPath = createProductIframeRoute({
      $stateParams: stateParams,
      resourceParam: 'resource',
      queryParams: productQueryParams,
      hideBooleanParams,
      dynamicSearchParamsRegexp,
      parentWindowHref: appWindow.location.href,
      getProductPathname,
    });

    const basePath = buildBasePath(stateParams);

    const nextIframeRoute = buildBaseUrl({
      listenOnHashRouting,
      basePath,
      productPath,
    });

    reportInfo(stateName, 'built url', { nextIframeRoute });

    if (!src) {
      reportInfo(stateName, 'updating product src', { nextIframeRoute });
      setSrc(nextIframeRoute);
      setLastProductUrl(nextIframeRoute);
    } else if (productIframeDom?.contentWindow) {
      const currIframeRoute = productIframeDom.contentWindow.location.href;

      if (refreshOnRoute()) {
        refresh(nextIframeRoute);
        reportInfo(stateName, 'refreshOnRoute', {
          currIframeRoute,
          nextIframeRoute,
          href: window.location.href,
          stateParams,
        });
      } else if (shouldNavigate(currIframeRoute, nextIframeRoute)) {
        reportInfo(stateName, 'updating product href', { currIframeRoute, nextIframeRoute });
        productIframeDom.contentWindow.location.href = nextIframeRoute;
        setLastProductUrl(nextIframeRoute);
      } else {
        reportInfo(stateName, 'ignoring url update to product, shouldNavigate returned false', {
          currIframeRoute,
          nextIframeRoute,
        });
      }
    } else {
      reportWarn(stateName, 'not setting window href iframe not loaded', { nextIframeRoute });
    }
  }, [productIframeDom, stateName, stateParams]);

  const onWindowProductHashChange = (params: any = {}) => {
    const { newURL: newProductURL = null } = params;

    if (!newProductURL) return;

    setLastProductUrl(newProductURL);

    if (!isStateActive.current) {
      reportInfo(stateName, 'onWindowProductHashChange canceled - not an active state', { newProductURL });

      return;
    }

    reportInfo(stateName, 'onWindowProductHashChange', { newProductURL });
    mergeAndSetAppUrl({ newProductURL });
  };

  const setAppUrl = ({ newAppUrl }) => {
    appWindow.history.replaceState({}, '', newAppUrl);

    // Sync the iframe and app's urls with the UI router (since the UI router needs to be informed or vanilla url changes).
    debouncedUrlSync();

    reportInfo(stateName, 'setAppRoute', { newAppUrl });
  };

  const addRouteChangeEvents = () => {
    removeRouteChangeEvents();

    const productWindow = productIframeDom?.contentWindow;

    if (!productWindow) {
      reportWarn(stateName, 'addEventListener hashchange ignored, contentWindow not accessible yet');

      return () => {};
    }

    if (listenOnHashRouting) {
      routeChangeListener.current = onWindowProductHashChange;
      reportInfo(stateName, 'adding hashchange event');
      productWindow.addEventListener('hashchange', routeChangeListener.current, false);
    }

    if (listenOnHistoryRouting) {
      const historyOrig = productWindow.history.pushState;

      productWindow.history.pushState = (f =>
        function pushState(...rest) {
          const ret = f.apply(this, rest);

          reportInfo(stateName, 'pushState event fired', { rest });
          productWindow.dispatchEvent(new Event('pushstate'));
          productWindow.dispatchEvent(new Event('locationchange'));

          return ret;
        })(historyOrig);

      productWindow.history.replaceState = (f =>
        function replaceState(...rest) {
          const ret = f.apply(this, rest);

          reportInfo(stateName, 'replaceState event fired', { rest });
          productWindow.dispatchEvent(new Event('replacestate'));
          productWindow.dispatchEvent(new Event('locationchange'));

          return ret;
        })(historyOrig);

      routeChangeListener.current = event =>
        onWindowProductHashChange({ ...event, newURL: productWindow.location.href });
      reportInfo(stateName, 'adding event listeners on pushState and replaceState', {
        isStateActive: isStateActive.current,
        'state.name': state.name,
      });
      productWindow.addEventListener('locationchange', routeChangeListener.current, false);
    }
  };

  const removeRouteChangeEvents = () => {
    const productWindow = productIframeDom?.contentWindow;

    if (!productWindow) return;

    if (!routeChangeListener.current) return;

    try {
      if (listenOnHashRouting) {
        reportInfo(stateName, 'remove hashchange event');
        productWindow.removeEventListener('hashchange', routeChangeListener.current, false);
      } else {
        reportInfo(stateName, 'remove locationchange event');
        productWindow.removeEventListener('locationchange', routeChangeListener.current, false);
      }
    } catch (e) {
      reportError(stateName, 'remove event failed', e);
    }
  };

  useEffect(() => {
    addRouteChangeEvents();

    if (!productIframeDom) return;

    reportInfo(stateName, 'adding event listener - iframe load');
    productIframeDom.addEventListener('load', addRouteChangeEvents, true);

    return () => {
      reportInfo(stateName, 'removing event listener - iframe load');
      productIframeDom.removeEventListener('load', addRouteChangeEvents, true);
      removeRouteChangeEvents();
    };
  }, [productIframeDom?.contentWindow]);

  const refresh = url => {
    if (!productIframeDom.src) {
      productIframeDom.setAttribute('src', url);

      return;
    }

    // In Safari, In order to refresh when we have src attribute
    // we cant just replace the src attr, we need to change the internal location object
    const navigate = () => {
      productIframeDom.contentWindow.location.href = url;
      productIframeDom.removeEventListener('load', navigate);
    };

    // Wait for about:blank loading to finish, and update the url with the navigate function
    // Eventually, remove the event after navigating
    productIframeDom.addEventListener('load', navigate);
    productIframeDom.contentWindow.location.href = 'about:blank';
  };

  const clearRoute = () => {
    const basePath = buildBasePath(stateParams);
    const url = buildBaseUrl({ basePath, listenOnHashRouting });

    mergeAndSetAppUrl({ newProductURL: url });

    setSrc(url);
  };

  return { src, clearRoute, isStateActive: isStateActive.current, lastProductUrl };
};
