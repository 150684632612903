import { fetchInterceptor } from '@logz-pkg/frontend-services';
import { Headers } from '@logz-pkg/enums';

export const requestInterceptor = ({ url, config = {} }) => {
  const pageType = window.parent.location.hash.split('?')[0].replace('#/dashboard/metrics/', '');
  const headers = {
    [Headers.GrafanaPageType]: pageType,
  };

  if (pageType.startsWith('d/')) {
    const [, dashboardUid] = pageType.split('/');

    headers[Headers.GrafanaDashboardUid] = dashboardUid;
  }

  return { url, config, headers };
};

export function overrideGrafanaFetch(targetWindow) {
  const { fetch: fetchOrig } = targetWindow;

  if (fetchOrig.isLogzioGrafanaOverride) return;

  const interceptor = fetchInterceptor(targetWindow, 'dashboard.grafana');

  interceptor.register({
    request: requestInterceptor,
  });

  targetWindow.fetch.isLogzioGrafanaOverride = true;
}
