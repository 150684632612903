import { Box, Icon, Text, Group } from '@logz-ui/styleguide';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SidenavSubjects } from '@logz-pkg/enums';
import { useSessionState } from 'ui/state/hooks';
import { logoutService } from 'core/auth/services/logout.service';

const ButtonContainer = styled(props => <Box py={4} px={5} {...props} />)`
  border-top: 3px solid ${({ theme }) => theme.colors.gray[300]};
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }
`;
export const LogoutButton: FunctionComponent = () => {
  const { loggedInUser } = useSessionState();

  const handleClick = () => {
    logoutService.logout();
  };

  return (
    <ButtonContainer onClick={handleClick} subject={SidenavSubjects.LogoutButton}>
      <Group>
        <Icon icon={'arrow-right-from-arc-regular'} color={'royalBlue.600'} />
        <Group vertical>
          <Text color={'royalBlue.1000'}>Log out</Text>
          <Text ellipsis color={'gray.800'} size={12}>
            {loggedInUser?.username}
          </Text>
        </Group>
      </Group>
    </ButtonContainer>
  );
};
