import { WelcomeFlowStep, WelcomePageUserRole } from '@logz-pkg/enums';
import { appStateService } from '@logz-pkg/frontend-services';

export interface IWelcomeAppState {
  currentStep?: WelcomeFlowStep;
  role?: WelcomePageUserRole;
}

const welcomeCenterSchema = 'welcome-center';
const version = 1;
export const welcomeAppStateService = {
  async getState(): Promise<IWelcomeAppState> {
    return appStateService.get(welcomeCenterSchema, version);
  },

  async setState(state: IWelcomeAppState) {
    const currentState = await this.getState();
    const newState = { ...currentState, ...state };

    return appStateService.set(welcomeCenterSchema, newState, version);
  },

  async setCurrentStep(currentStep: WelcomeFlowStep) {
    return this.setState({ currentStep });
  },

  async advanceToStep(step: WelcomeFlowStep) {
    const { currentStep } = await this.getState();

    if (step > currentStep) {
      return this.setState({ currentStep: step });
    }
  },

  async getRole(): Promise<WelcomePageUserRole | null> {
    const { role } = await this.getState();

    return role || null;
  },

  setRole(role: WelcomePageUserRole) {
    return this.setState({ role });
  },
};
