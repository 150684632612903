import { Icon, IIconProps } from '@logz-ui/styleguide';
import {
  AccountType,
  AppMode,
  ProductKeys,
  Permissions,
  UserRole,
  FeatureFlags,
  OsdVersionsEnum,
  Product,
  PLAN_TYPE,
} from '@logz-pkg/enums';
import { ILogoProps } from 'ui/components/shared/Logo/Logo';

export interface IStateParams {
  [key: string]: any;
}

export interface IExpandedNavSubItemConfig {
  icon?: React.ComponentProps<typeof Icon>['icon'];
  text: string;
  targetStateName: string;
  targetStateParams?: IStateParams;
  stateParams?: IStateParams;
  externalUrl?: string;
  openInNewTab?: boolean;
  displayConditions?: IDisplayCondition[];
  productIcon?: Product;
  beta?: boolean;
}

export enum ConditionType {
  Permission,
  OneOfPermissions,
  OneOfUserRoles,
  UserRole,
  IsSysAdmin,
  HasFeatureFlag,
  DoesntHaveFeatureFlag,
  AppMode,
  AccountOfType,
  OsdVersion,
  OneOfNotAccountPlan,
  HasOneOfFeatureFlags,
}

export interface IPermissionCondition {
  type: ConditionType.Permission;
  value: Permissions;
}

export interface IOsdVersionsEnumCondition {
  type: ConditionType.OsdVersion;
  value: OsdVersionsEnum;
}

export interface IOneOfRolesCondition {
  type: ConditionType.OneOfUserRoles;
  value: UserRole[];
}

export interface IOneOfPermissionsCondition {
  type: ConditionType.OneOfPermissions;
  value: Permissions[];
}

export interface IUserRoleCondition {
  type: ConditionType.UserRole;
  value: UserRole;
}

export interface ISysAdminCondition {
  type: ConditionType.IsSysAdmin;
  value: boolean;
}

export interface IFeatureFlagCondition {
  type: ConditionType.HasFeatureFlag | ConditionType.DoesntHaveFeatureFlag;
  value: FeatureFlags;
}
export interface IFeatureFlagsCondition {
  type: ConditionType.HasOneOfFeatureFlags;
  value: FeatureFlags[];
}

export interface IAppModeCondition {
  type: ConditionType.AppMode;
  value: AppMode;
}

export interface IAccountOfTypeCondition {
  type: ConditionType.AccountOfType;
  value: AccountType;
}

export interface IAccountPlanTypeCondition {
  type: ConditionType.OneOfNotAccountPlan;
  value: PLAN_TYPE[];
}

export type IDisplayCondition =
  | IPermissionCondition
  | IUserRoleCondition
  | IOneOfRolesCondition
  | ISysAdminCondition
  | IFeatureFlagCondition
  | IFeatureFlagsCondition
  | IAppModeCondition
  | IAccountOfTypeCondition
  | IOneOfPermissionsCondition
  | IOsdVersionsEnumCondition
  | IAccountPlanTypeCondition;

export interface IExpandedNavItemConfig {
  icon?: IIconProps['icon'];
  text?: string;
  targetStateName?: string;
  targetStateParams?: IStateParams;
  externalUrl?: string;
  subItems?: IExpandedNavSubItemConfig[];
  onClick?: () => void;
  action?: {
    targetStateName?: string;
    displayConditions?: IDisplayCondition[];
    eventName: string;
    subject?: string;
  };
  innerStates?: string[];
  openInNewTab?: boolean;
  displayConditions?: IDisplayCondition[];
  component?: SidenavComponents;
  id?: string;
  productIcon?: Product;
  beta?: boolean;
}

export interface IExpandedNavGroup {
  title?: string;
  items: IExpandedNavItemConfig[];
  displayConditions?: IDisplayCondition[];
}

export interface ISidenavItemConfig {
  key: ProductKeys;
  fixedSidenavText?: string;
  expandedSidenavTitle: string;
  targetStateName: string;
  targetStateParams?: IStateParams;
  stateParams?: IStateParams;
  mode?: AppMode;
  logo: ILogoProps['logo'];
  expandedNavGroups: IExpandedNavGroup[];
  displayConditions?: IDisplayCondition[];
}

export enum ProductCollectionIds {
  Logs = 'log-sources',
  Metrics = 'prometheus-sources',
  Tracing = 'tracing-sources',
  Security = 'security-sources',
}

export enum SidenavComponents {
  ThemePickerButton = 'ThemePicker',
}

export const collectionIdToProduct = {
  [ProductCollectionIds.Logs]: ProductKeys.Logs,
  [ProductCollectionIds.Metrics]: ProductKeys.Metrics,
  [ProductCollectionIds.Tracing]: ProductKeys.Tracing,
  [ProductCollectionIds.Security]: ProductKeys.Siem,
};
