import { OperatingSystem } from '@logz-pkg/enums';
import { authService, logShippingTokenApiService, userService } from '@logz-pkg/frontend-services';
import { LogShippersApiService } from 'services/api/log-shippers/log-shippers.api.service';
import { ErrorHandlingService } from 'services/error-handling.service';
import { buildYamlFile } from 'ui/components/FilebeatConfWiz/build-yaml-file';
import { IFormValues } from 'ui/components/FilebeatConfWiz/form-config';

const UNEXPECTED_ERROR_GENERATE_YML_MESSAGE = 'Generate yml file unexpected error';

const generateYamlConfigurationTemplatesFlow = (os: OperatingSystem) =>
  LogShippersApiService.generateYamlConfigurationTemplates(os);

const getUserData = async () => {
  if (authService.isAuthenticated()) {
    let userToken = '{{API_TOKEN}}';

    if (await authService.isAdminRole()) {
      const { token } = await logShippingTokenApiService.getDefaultToken();

      userToken = token;
    }

    const {
      data: { listenerAddress },
    } = await userService.getSummary();

    return [userToken, listenerAddress];
  }

  return ['{{API_TOKEN}}', '{{LOGZ_LISTENER}}'];
};

const generateConfFile = async (values: IFormValues): Promise<any> => {
  try {
    const [token, listenerAddress] = await getUserData();

    let yamlConfigurationTemplates = await generateYamlConfigurationTemplatesFlow(values.os);

    yamlConfigurationTemplates = yamlConfigurationTemplates[values.os.toLowerCase()];

    const yamlLogTypeSection = await LogShippersApiService.generateYamlLogTypeSection();

    buildYamlFile(yamlConfigurationTemplates, yamlLogTypeSection, values, token, listenerAddress);
  } catch (error) {
    ErrorHandlingService.handleApiError({
      title: 'Could not generate yml',
      unexpectedErrorLogMessage: UNEXPECTED_ERROR_GENERATE_YML_MESSAGE,
      error,
    });
  }
};

export const filebeatService = {
  generateYamlConfigurationTemplatesFlow,
  getUserData,
  generateConfFile,
};
