export enum ProductType {
  KIBANA,
  GRAFANA,
  JAEGER,
}

export const iframeAttribute = {
  [ProductType.KIBANA]: 'kibana-iframe',
  [ProductType.GRAFANA]: 'grafana-iframe',
  [ProductType.JAEGER]: 'jaeger-iframe',
};
