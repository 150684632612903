import { LegendOptions, Options } from 'highcharts';
import { ExplorePageGraphYAxisScaleTypes, ExploreTimeInterval } from '@logz-pkg/enums';
import { Group, Link, Text } from '@logz-ui/styleguide';
import { GroupByIdSeries } from '../../state/graph-state.service';
import { ServicesSelect } from './VisualizationMenu/components/MenuPanel/components/ServicesSelect';
import { VisualizationMenuItem, VisualizationSettings } from './types';
import { GroupBySelect } from './VisualizationMenu/components/MenuPanel/components/GroupBySelect';
import { GraphScaleSelect } from './VisualizationMenu/components/MenuPanel/components/GraphScaleSelect';
import { FieldDictionary } from 'ui/components/Explore/types';
import { CompareToSelect } from 'ui/components/Explore/ContentPane/Graphs/VisualizationMenu/components/MenuPanel/components/CompareToSelect';
import { TimeIntervalSelect } from 'ui/components/Explore/ContentPane/Graphs/VisualizationMenu/components/MenuPanel/components/TimeIntervalSelect';

const getPercentage = (total, subset) => {
  if (total === 0) return '0%';

  const percentage = (subset / total) * 100;

  const roundedPercentage = Math.round(percentage);

  if (percentage === roundedPercentage) {
    return `${roundedPercentage}%`;
  }

  if (percentage < 0.05) {
    const formattedPercentage = (Math.round(percentage * 100) / 100).toFixed(2);

    return `${formattedPercentage}%`;
  }

  const formattedPercentage = (Math.round(percentage * 10) / 10).toFixed(1);

  return `${formattedPercentage}%`;
};
export const defaultLegendOptions: LegendOptions = { enabled: false };
export const defaultGroupByLegendOptions = (
  groupByState: GroupByIdSeries,
  fieldDictionary: FieldDictionary,
): LegendOptions => ({
  enabled: true,
  useHTML: true,
  align: 'right',
  verticalAlign: 'top',
  layout: 'vertical',
  labelFormatter() {
    const label = fieldDictionary?.valuesDictionary?.[this.name] ?? this.name;
    const relevantGroup = groupByState.find(group => group.key === label);
    const totalLogs = groupByState.reduce((acc, group) => acc + group.count, 0);

    return `<div style="width: 175px; justify-content:space-between; display:flex;">
    <div style="margin-right:4px; color:${
      this.color
    }; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${label}</div>
    ${relevantGroup && `<div>${getPercentage(totalLogs, relevantGroup.count)}</div>`}
    </div>`;
  },
});

export const customGraphStyle: Options = {
  chart: {
    style: {
      cursor: 'crosshair',
    },
  },
};

export const MENU_ITEMS_CONFIG: VisualizationMenuItem[] = [
  { label: 'Group by', settingKey: 'groupBy', configComponent: GroupBySelect },
  { label: 'Compare to', settingKey: 'compareTo', configComponent: CompareToSelect },
  { label: 'Time interval', settingKey: 'timeInterval', configComponent: TimeIntervalSelect },
  { label: 'Y Axis scale', settingKey: 'yAxisScale', configComponent: GraphScaleSelect },
  {
    label: 'Deployment markers',
    settingKey: 'deploymentMarkers',
    tooltip: (
      <Group vertical width={200}>
        <Text>Select a deployment to highlight its release date on the graph.</Text>
        <Link href="https://docs.logz.io/docs/user-guide/explore/deployment-markers">Learn more</Link>
      </Group>
    ),
    configComponent: ServicesSelect,
  },
];

export const DEFAULT_VISUALIZATION_SETTINGS: VisualizationSettings = {
  groupBy: null,
  showAllEvents: true,
  compareTo: '',
  timeInterval: ExploreTimeInterval.Auto,
  yAxisScale: ExplorePageGraphYAxisScaleTypes.Linear,
  deploymentMarkers: [],
};
