import React, { useState } from 'react';
import { Button, Link, NotificationService } from '@logz-ui/styleguide';
import { useAsyncAction } from '@logz-pkg/react-hooks';
import { isObject } from 'lodash';
import { SendYourDataTestSubject } from '@logz-pkg/enums';
import { grafanaDashboardsService } from './services/install-dashboards.api.service';

interface IGrafanaData {
  ids: Array<string>;
}

export enum ButtonLabels {
  Default = 'Install dashboard bundle',
  Installing = 'Installing dashboard bundle',
  PostInstallingSuccess = 'View in Metrics',
}

const failureMessage = (
  <>
    <div>Could not install the dashboard bundle.</div>
    <Link href="mailto:help@logz.io">Try again or Contact support</Link>
  </>
);

export const route = {
  manageDashboardsScreen: '#/dashboard/metrics/dashboards',
  dashboardScreen: '#/dashboard/metrics/d/',
};

export const InstallGrafanaDashboardsButton: React.FunctionComponent<IGrafanaData> = ({ ids }) => {
  const [installedSuccessfullyDashboards, setInstalledSuccessfullyDashboards] = useState([]);
  const [buttonLabel, setButtonLabel] = useState(ButtonLabels.Default);

  const { action: installDashboards, isPending } = useAsyncAction(grafanaDashboardsService.installDashboards, {
    onFailure: () => {
      NotificationService.danger({ message: failureMessage });
    },
    onSuccess: obj => {
      let publishContentResult;

      try {
        publishContentResult = obj.data.publishContentResult;

        if (!isObject(publishContentResult)) publishContentResult = {};
      } catch {
        publishContentResult = {};
      }

      const keys = Object.keys(publishContentResult);
      const installedSuccessfully = keys.filter(key => publishContentResult[key]);

      if (installedSuccessfully.length) {
        setInstalledSuccessfullyDashboards(installedSuccessfully);
        setButtonLabel(ButtonLabels.PostInstallingSuccess);

        NotificationService.success({ message: successMessage(installedSuccessfully) });
      } else {
        NotificationService.danger({ message: failureMessage });
      }
    },
  });

  const navigateToMetrics = installedDashboardsIds => {
    if (installedDashboardsIds.length > 1) {
      window.open(route.manageDashboardsScreen, '_blank');

      return;
    }

    window.open(`${route.dashboardScreen}${installedDashboardsIds[0]}`, '_blank');
  };

  const handleClick = () => {
    if (!installedSuccessfullyDashboards.length) {
      installDashboards(ids);

      return;
    }

    navigateToMetrics(installedSuccessfullyDashboards);
  };

  const successMessage = installedDashboards => (
    <>
      <div>Dashboard bundle installed successfully</div>
      <Link onClick={() => navigateToMetrics(installedDashboards)}>View in Metrics</Link>
    </>
  );

  return (
    <Button
      loading={isPending}
      loadingText={ButtonLabels.Installing}
      onClick={handleClick}
      subject={SendYourDataTestSubject.InstallGrafanaDashboards}
      variant={buttonLabel === ButtonLabels.PostInstallingSuccess ? 'secondary' : 'primary'}
    >
      {buttonLabel}
    </Button>
  );
};
