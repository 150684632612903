import { useState, useEffect } from 'react';
import { cookiesProvider, marketplaceApiService, searchableAccountsApiService } from '@logz-pkg/frontend-services';
import { AccountType, cookieKeys } from '@logz-pkg/enums';
import { SearchableAccountModel } from '@logz-pkg/models';
import { Link, NotificationService } from '@logz-ui/styleguide';
import { appRouter } from 'services/router/router.service';
import { settingsRoutesStates } from 'states/settings.routes.names';

export const LOCALSTORAGE_MARKETPLACE_SUCCESS_ACCOUNT_KEY = 'aws-marketplace-connected-success';
export const useMarketplaceActivation = () => {
  const awsMarketplaceToken = cookiesProvider.getCookie(cookieKeys.AWS_MARKETPLACE_TOKEN);
  const cloudMarketplaceToken = cookiesProvider.getCookie(cookieKeys.CLOUD_MARKETPLACE_TOKEN);
  const [accounts, setAccounts] = useState<SearchableAccountModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const activateSubscription = async (account: SearchableAccountModel) => {
    if (awsMarketplaceToken) {
      await marketplaceApiService.activateAwsSubscription(account.id, awsMarketplaceToken);
      cookiesProvider.removeCookie(cookieKeys.AWS_MARKETPLACE_TOKEN);
      localStorage.setItem(LOCALSTORAGE_MARKETPLACE_SUCCESS_ACCOUNT_KEY, account.name);
    }

    if (cloudMarketplaceToken) {
      await marketplaceApiService.activateAzureSubscription(account.id, cloudMarketplaceToken);
      cookiesProvider.removeCookie(cookieKeys.CLOUD_MARKETPLACE_TOKEN);
      localStorage.setItem(LOCALSTORAGE_MARKETPLACE_SUCCESS_ACCOUNT_KEY, account.name);
    }
  };

  useEffect(() => {
    if (cloudMarketplaceToken || awsMarketplaceToken) {
      searchableAccountsApiService
        .getAccountsByType(AccountType.Owner)
        .then(userMainAccounts => {
          setAccounts(userMainAccounts);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [cloudMarketplaceToken, awsMarketplaceToken]);

  return {
    isLoading,
    activateSubscription,
    accounts,
  };
};

export const useMarketplaceSuccessMessageActivation = (appReady: boolean) => {
  useEffect(() => {
    if (appReady) {
      const accountName = localStorage.getItem(LOCALSTORAGE_MARKETPLACE_SUCCESS_ACCOUNT_KEY);

      if (accountName) {
        const NOTIFICATION_KEY = 'aws-marketplace-connected';

        localStorage.removeItem(LOCALSTORAGE_MARKETPLACE_SUCCESS_ACCOUNT_KEY);
        NotificationService.success({
          title: `Connection successful!`,
          key: NOTIFICATION_KEY,
          closable: true,
          autoHide: false,
          message: (
            <>
              Account {accountName} connected. Next, create your Logz.io accounts in{` `}
              <Link
                onClick={() => {
                  NotificationService.closeByKey(NOTIFICATION_KEY);
                  appRouter.stateService.go(settingsRoutesStates.manageAccounts);
                }}
              >
                Manage accounts
              </Link>{' '}
              .
            </>
          ),
        });
      }
    }
  }, [appReady]);
};
