import { ManageTokensStates } from '@logz-pkg/enums';
import { redirectIfNotAdmin } from '../utils';
import { AppStateDeclaration } from '../AppStateDecleration';
import { manageTokensRouteConfig } from './manage-tokens.route-config';
import { AppModes } from 'services/identity/app-mode/app-modes.factory';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const manageTokens: AppStateDeclaration[] = [
  {
    name: ManageTokensStates.ManageTokens,
    url: manageTokensRouteConfig.url,
    component: props => (
      <LazyReact
        lazyImport={() => import('ui/components/ManageTokens/ManageTokens')}
        resolver={({ ManageTokens }) => ManageTokens}
        {...props}
      />
    ),
    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      title: 'Manage tokens',
      breadcrumb: { label: 'Manage tokens' },
    },
    redirectTo: ManageTokensStates.SharedTokens,
    resolve: [redirectIfNotAdmin],
  },
  {
    name: ManageTokensStates.ApiTokens,
    url: '/api',
    component: props => (
      <LazyReact
        {...props}
        lazyImport={() => import('ui/components/ManageTokens/ApiTokens/Container')}
        resolver={({ ApiTokensContainer }) => ApiTokensContainer}
      />
    ),

    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      breadcrumb: { label: 'Api tokens' },
    },
    resolve: [redirectIfNotAdmin],
  },
  {
    name: ManageTokensStates.SharedTokens,
    url: '/shared',
    component: props => (
      <LazyReact
        {...props}
        lazyImport={() => import('ui/components/ManageTokens/SharedTokens/Container')}
        resolver={({ SharedTokensContainer }) => SharedTokensContainer}
      />
    ),
    data: {
      authenticate: true,
      allowedAppModes: [AppModes.OPERATIONS?.value, AppModes.SECURITY?.value],
      breadcrumb: { label: 'Shared tokens' },
    },
    resolve: [redirectIfNotAdmin],
  },
  {
    name: ManageTokensStates.DataShippingTokens,
    url: `${manageTokensRouteConfig.dataShippingUrl}?product`,
    params: {
      product: 'logs',
    },
    data: { authenticate: true, allowedAppModes: [AppModes.OPERATIONS?.value], breadcrumb: { label: 'Data Shipping' } },
    component: props => (
      <LazyReact
        {...props}
        lazyImport={() => import('ui/components/ManageTokens/DataShippingTokens/DataShippingTokens')}
        resolver={({ DataShippingTokens }) => DataShippingTokens}
      />
    ),
    resolve: [redirectIfNotAdmin],
  },
];
