import { urlParser } from '@logz-pkg/utils';
import { isEqual } from 'lodash';
import { createRouteOptions } from '../../product/route-helpers/route-helpers';

// DEV-25025 remove alertAdvisor param
export const grafanaUrlQueryParams = [
  'schemaVersion',
  'panes',
  'from',
  'to',
  'orgId',
  'panelId',
  'edit',
  'editPanel',
  'fullscreen',
  'left',
  'tab',
  'viewPanel',
  'kiosk',
  'alertAdvisor',
  'id',
  'startsAt',
  'endsAt',
  'comment',
  'createdBy',
  'duration',
  'isRegex',
  'matcher',
  'matchers',
  'matcherName',
  'matcherValue',
  'timeZone',
  'alertmanager',
] as const;

export const routeOptions = createRouteOptions({
  shouldSync: true,
  viewUrlPrefix: '/dashboard/metrics',
  productPathPrefix: '/grafana-app',
  buildBasePath: () => '/grafana-app',
  shouldNavigate: (currIframeRoute, nextIframeRoute) => {
    const { parsedUrl: currentParsedUrl } = urlParser(currIframeRoute);
    const { parsedUrl: nextParsedUrl } = urlParser(nextIframeRoute);

    return !isEqual(currentParsedUrl.pathname, nextParsedUrl.pathname);
  },
  dynamicSearchParamsRegexp: 'var-*',
  productQueryParams: grafanaUrlQueryParams,
  listenOnHistoryRouting: true,
  hideBooleanParams: true,
  addSwitchToAccountId: true,
});
