import { ISelectOption } from '@logz-ui/styleguide';

const fieldTypes = {
  boolean: 'Boolean',
  date: 'Date (timestamp)',
  double: 'Double',
  ip: 'IP',
  keyword: 'Keyword (String)',
  long: 'Long',
  byte: 'Byte',
  geo_point: 'Geo Point',
  float: 'Float',
  integer: 'Integer',
  text: 'Text (Analysed Field)',
  short: 'Short',
  object: 'Object',
};

const fieldTypesOptions: ISelectOption<string>[] = Object.entries(fieldTypes).map(([esKey, esLabel]) => ({
  value: esKey,
  label: esLabel,
}));

export const elasticFieldTypeService = {
  getFieldTypes: () => fieldTypes,
  getLabelByValue: value => fieldTypes[value],
  getFieldTypesOptions: () => fieldTypesOptions,
  getFieldTypeOptionByValue: fieldType => fieldTypesOptions.find(option => option.value === fieldType),
};
