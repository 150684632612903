/* eslint-disable max-lines */
import { ExplorePageSubjects } from '@logz-pkg/enums';
import {
  ElasticsearchEnhancedFilterModel,
  ElasticsearchEnhancedFilterOperator,
  ElasticsearchFilterOperator,
  SavedObjectModel,
} from '@logz-pkg/models';
import { RelativeValue, dateToAbsolute } from '@logz-pkg/utils';
import { NotificationService } from '@logz-ui/styleguide';
import { classToPlain, plainToClass } from 'class-transformer';
import copy from 'copy-to-clipboard';
import { merge, partition } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { sessionStateService } from '@logz-pkg/frontend-services';
import { SURROUNDING_INITIAL_FETCH_AMOUNT } from '../constants';
import { exploreSearchParamsService, URLParams } from '../router/router';
import { urlParamsService } from '../services/url-params.service';
import { explorePreferencesStateService } from '../state/app-state/explore-preferences-states.service';
import { fieldMappingsService } from '../state/field-mappings.service';
import { filtersStateService } from '../state/filters-state.service';
import { exploreRouteHelpers } from '../router/router.helpers';
import { analyticsService } from 'services/analytics/analytics.service';
import { exploreOnboardingStatesService } from 'ui/components/Explore/state/app-state/explore-onboarding-states.service';
import { featureFlagStateService } from 'ui/state/feature-flag.state.service';
import { ExploreUrlParams } from 'states/explore/explore.route';

const reduceFiltersToFieldFilters = (filters: ElasticsearchEnhancedFilterModel[]) => {
  const reducedFilters: { [key: string]: ElasticsearchEnhancedFilterModel[] } = filters.reduce((acc, filter) => {
    const filterName = filter.field?.name;

    if (!filterName) return acc;

    acc[filterName] = acc[filterName] || [];
    acc[filterName].push(filter);

    return acc;
  }, {});

  return reducedFilters;
};
const groupBetweenFilters = (filters: ElasticsearchEnhancedFilterModel[]): ElasticsearchEnhancedFilterModel[] => {
  const reducedFilters = reduceFiltersToFieldFilters(filters);

  if (Object.keys(reducedFilters).length === 0) return [];

  const groupedFilters: ElasticsearchEnhancedFilterModel[] = [];

  Object.entries(reducedFilters).forEach(([key, val]) => {
    const regularFilters: any[] = [];
    const negateFilters: any[] = [];

    val.forEach(({ negate, value }) => {
      if (negate) {
        if (Array.isArray(value)) {
          negateFilters.push(...value);
        } else {
          negateFilters.push(value);
        }
      } else if (Array.isArray(value)) {
        regularFilters.push(...value);
      } else {
        regularFilters.push(value);
      }
    });

    if (negateFilters.length > 0) {
      const negateFilterModel = new ElasticsearchEnhancedFilterModel();

      negateFilterModel.field = val[0].field;
      negateFilterModel.negate = true;
      negateFilterModel.type = ElasticsearchFilterOperator.IsOneOf;
      negateFilterModel.value = negateFilters;
      groupedFilters.push(negateFilterModel);
    }

    if (regularFilters.length > 0) {
      const regularFilterModel = new ElasticsearchEnhancedFilterModel();

      regularFilterModel.field = val[0].field;
      regularFilterModel.negate = false;
      regularFilterModel.type = ElasticsearchFilterOperator.IsOneOf;
      regularFilterModel.value = regularFilters;
      groupedFilters.push(regularFilterModel);
    }
  });

  return groupedFilters;
};

type FilterExistsParams = {
  currentFilters: ElasticsearchEnhancedFilterModel[];
  filter?: ElasticsearchEnhancedFilterModel;
  id?: string;
  type?: ElasticsearchEnhancedFilterOperator;
  value?: string;
  negate?: boolean;
};

const filterExists = ({ currentFilters, filter, id, type, value, negate = false }: FilterExistsParams) => {
  if (id && type && value) {
    const foundFilter = currentFilters.find(
      currentFilter =>
        currentFilter.field?.name === id && currentFilter.type === type && currentFilter.negate === negate,
    );

    return foundFilter && (foundFilter.value as (string | number)[]).some(val => String(val) === value);
  }

  if (id && type) {
    return currentFilters.some(
      currentFilter =>
        currentFilter.field?.name === id && currentFilter.type === type && currentFilter.negate === negate,
    );
  }

  if (id) {
    return currentFilters.some(currentFilter => currentFilter.field?.name === id);
  }

  if (type) {
    return currentFilters.some(currentFilter => currentFilter.type === type);
  }

  if (!currentFilters || !filter) return false;

  return currentFilters.some(
    currentFilter =>
      currentFilter.field?.name === filter.field?.name &&
      currentFilter.type === filter.type &&
      currentFilter.negate === filter.negate,
  );
};
const removeFiltersById = (
  filters: ElasticsearchEnhancedFilterModel[],
  id: string,
): ElasticsearchEnhancedFilterModel[] => {
  return filters.filter(filter => filter.field?.name !== id);
};

type CombineFilters = {
  currentFilters: ElasticsearchEnhancedFilterModel[];
  newFilters: ElasticsearchEnhancedFilterModel[];
  currentId?: string;
  currentType?: ElasticsearchEnhancedFilterOperator;
};

const combineFilters = ({
  currentFilters,
  newFilters,
  currentId,
  currentType,
}: CombineFilters): ElasticsearchEnhancedFilterModel[] => {
  const idToCompare = newFilters[0]?.field?.name ?? currentId;
  const typeToCompare = newFilters[0]?.type ?? currentType;

  const areEqual = (filter: ElasticsearchEnhancedFilterModel) =>
    filter.field?.name === idToCompare && filter.type === typeToCompare;

  if (newFilters.length === 0) {
    return currentFilters.filter(cf => !areEqual(cf));
  }

  const filterMap = new Map<string, ElasticsearchEnhancedFilterModel>();
  const buildKey = (filter: ElasticsearchEnhancedFilterModel) => {
    const isOperatorFilter = filter.type === ElasticsearchFilterOperator.Is;

    return `${filter.field?.name}-${filter.type}-${filter.negate}${isOperatorFilter ? `-${filter.value}` : ''}`;
  };

  currentFilters.forEach(cf => {
    const key = buildKey(cf);

    if (!filterMap.has(key)) {
      filterMap.set(key, cf);
    }
  });

  if (newFilters.length === 1) {
    const [newFilter] = newFilters;
    const newFilterKey = buildKey(newFilter);
    const oppositeNegationFilterKey = `${newFilter.field?.name}-${newFilter.type}-${!newFilter.negate}`;

    // Remove filters with opposite negation
    filterMap.delete(oppositeNegationFilterKey);

    const modifiedFilters = Array.from(filterMap.values());

    if (filterMap.has(newFilterKey)) {
      const index = modifiedFilters.findIndex(areEqual);

      if (index !== -1) {
        modifiedFilters[index] = newFilter;
      }
    } else {
      modifiedFilters.unshift(newFilter);
    }

    return modifiedFilters;
  }

  newFilters.forEach(nf => {
    const nfKey = buildKey(nf);

    filterMap.set(nfKey, nf);
  });

  return Array.from(filterMap.values());
};

const getQueryUniqueId = (query: ElasticsearchEnhancedFilterModel[]) => {
  return plainToClass(ElasticsearchEnhancedFilterModel, query).reduce((a, r) => `${a}${r.id}`, '');
};

const buildElasticModel: (params: {
  field: ElasticsearchEnhancedFilterModel['field'];
  value?: string;
  negate?: boolean;
  type?: ElasticsearchEnhancedFilterModel['type'];
}) => ElasticsearchEnhancedFilterModel = ({
  field,
  value,
  negate = false,
  type = ElasticsearchFilterOperator.IsOneOf,
}) => {
  const singleElasticModel = new ElasticsearchEnhancedFilterModel();

  singleElasticModel.field = field;
  singleElasticModel.negate = negate;
  singleElasticModel.type = type;
  singleElasticModel.value = type === ElasticsearchFilterOperator.IsOneOf ? [value] : null;

  return singleElasticModel;
};

const addValueToField = (
  currentFilters: ElasticsearchEnhancedFilterModel[],
  newFilter: ElasticsearchEnhancedFilterModel,
  value: string,
  negate: boolean = false,
) => {
  const currentFilter = currentFilters.find(
    filter => filter.field.name === newFilter.field.name && filter.type === newFilter.type && filter.negate === negate,
  );

  if (!currentFilter) {
    return [newFilter];
  }

  const newValue = [...(currentFilter.value as string[]), value];

  return [plainToClass(ElasticsearchEnhancedFilterModel, { ...currentFilter, value: newValue })];
};

const removeValueFromField = (
  currentFilters: ElasticsearchEnhancedFilterModel[],
  newFilter: ElasticsearchEnhancedFilterModel,
  value: string,
  negate: boolean = false,
) => {
  const currentFilter = currentFilters.find(
    filter => filter.field.name === newFilter.field.name && filter.type === newFilter.type && filter.negate === negate,
  );

  if (!currentFilter) {
    return [];
  }

  const newValue = (currentFilter.value as string[]).filter(val => val !== value);

  if (newValue.length === 0) {
    return [];
  }

  return [plainToClass(ElasticsearchEnhancedFilterModel, { ...currentFilter, value: newValue })];
};

const isTimeRangeAbsolute = (from, to) => !isNaN(Number(from)) && !isNaN(Number(to));

type GenerateCacheKeyFromQuery = {
  from: number | RelativeValue;
  to: number | RelativeValue;
  filters: ElasticsearchEnhancedFilterModel[];
  accounts: string[];
};

const generateCacheKeyFromQuery = ({ from, to, filters, accounts }: GenerateCacheKeyFromQuery) => {
  const copiedFilters = [...filters];
  const copiedAccounts = [...accounts];

  copiedAccounts.sort((a, b) => a.localeCompare(b));
  copiedFilters.sort((a, b) => a.id.localeCompare(b.id));

  const isAbsolute = isTimeRangeAbsolute(from, to);

  const calculateRelativeTime = timestamp => {
    const momentObj = moment.unix(timestamp);
    const roundedMoment = momentObj.startOf('minute');
    const roundedTimestamp = roundedMoment.unix();

    return roundedTimestamp;
  };

  const newFrom = isAbsolute ? (from as number) : calculateRelativeTime(dateToAbsolute(from as RelativeValue));
  const newTo = isAbsolute ? (to as number) : calculateRelativeTime(dateToAbsolute(to as RelativeValue));
  const metaData = { copiedFilters, copiedAccounts, newFrom, newTo };

  return JSON.stringify(metaData);
};

const extractFieldsFromSortArr = (sort: string[]) => {
  const [timeStampFieldArr, nonTimestampFieldArr] = partition(sort, field => field.includes('@timestamp'));

  const timeStampField = timeStampFieldArr?.[0];
  const secondField = nonTimestampFieldArr?.[0];

  return { timeStampField, secondField };
};

const formatLogId = (logId: string): string => {
  if (!logId) return '';

  const [formattedId] = logId.split('.');

  return formattedId;
};

const copyUrl = () => {
  analyticsService.capture('Explore single log view', 'Copied log url');

  const rawUrl = new URL(window.location.href);

  const decodedUrl = decodeURIComponent(rawUrl.toString());

  copy(decodedUrl);

  NotificationService.success({
    title: 'URL copied to clipboard',
    subject: ExplorePageSubjects.QuickViewCopyNotification,
  });
};

const copyLogUrl = (logId: string) => {
  analyticsService.capture('Explore expanded log view', 'Copied log url');

  const { loggedInAccount } = sessionStateService.session.get();

  const formattedId = formatLogId(logId);

  const exploreUrl = exploreRouteHelpers.buildExploreUrl({
    routeName: 'single',
    params: { logId: formattedId, switchToAccountId: loggedInAccount.id },
  });

  copy(`${window.location.origin}/${exploreUrl}`);

  NotificationService.success({
    title: 'URL copied to clipboard',
    subject: ExplorePageSubjects.QuickViewCopyNotification,
  });
};

const handleSurroundingDocs = (logId: string) => {
  const openInNewTab = explorePreferencesStateService.state.get().generalSettings.openSurroundingLogsInNewTab;
  const currentFiltersDisabled = filtersStateService.state
    .get()
    .map(filter =>
      filter.type === 'LUCENE'
        ? filter
        : plainToClass(ElasticsearchEnhancedFilterModel, { ...filter, isDisabled: true }),
    );

  const query = urlParamsService.filtersToUrlParam(currentFiltersDisabled);
  const mode = exploreSearchParamsService.mode.get();

  const contextAfterAmount =
    mode === 'CLASSIC' || mode === 'WARM_TIER'
      ? SURROUNDING_INITIAL_FETCH_AMOUNT
      : exploreSearchParamsService.contextAfterAmount.get();

  const contextBeforeAmount =
    mode === 'CLASSIC' || mode === 'WARM_TIER'
      ? SURROUNDING_INITIAL_FETCH_AMOUNT
      : exploreSearchParamsService.contextBeforeAmount.get();

  const exploreParams: ExploreUrlParams = {
    mode: 'SURROUNDING_LOGS' as const,
    contextLogId: logId,
    contextAfterAmount,
    contextBeforeAmount,
    query,
    accounts: exploreSearchParamsService.accounts.get(),
    switchToAccountId: sessionStateService.session.get().loggedInAccount.id,
    isFilterOpen: false,
  };

  const exploreUrl = exploreRouteHelpers.buildExploreUrl({ params: exploreParams, routeName: 'context' });

  if (!openInNewTab) {
    window.open(exploreUrl, '_self');
  } else {
    window.open(exploreUrl, '_blank');
  }
};

function extractParamsFromQuery(queryString: string): Record<string, string | string[]> {
  const params = new URLSearchParams(queryString);
  const result: Record<string, string | string[]> = {};

  params.forEach((value, key) => {
    if (result.hasOwnProperty(key)) {
      if (!Array.isArray(result[key])) {
        result[key] = [result[key] as string];
      }

      (result[key] as string[]).push(value);
    } else {
      result[key] = value;
    }
  });

  return result;
}

const filterInOutValues = (fieldName: string, fieldValue: string | string[]) => {
  const currentFilters = filtersStateService.state.get();

  let models: ElasticsearchEnhancedFilterModel[] = [];

  if (Array.isArray(fieldValue)) {
    models = fieldValue.map(val => {
      return plainToClass(ElasticsearchEnhancedFilterModel, {
        field: classToPlain(fieldMappingsService.getField(fieldName)),
        type: 'IS',
        negate: false,
        value: val,
      } as ElasticsearchEnhancedFilterModel);
    });
  } else {
    models.push(
      plainToClass(ElasticsearchEnhancedFilterModel, {
        field: classToPlain(fieldMappingsService.getField(fieldName)),
        type: 'IS_ONE_OF',
        negate: false,
        value: [fieldValue],
      } as ElasticsearchEnhancedFilterModel),
    );
  }

  const newFilters = [];
  const regularFilterExists = currentFilters.find(
    filter =>
      filter.field &&
      filter.field.id === fieldName &&
      filter.negate === false &&
      filter.type === 'IS_ONE_OF' &&
      !Array.isArray(fieldValue),
  );

  const negateFilter = currentFilters.find(
    filter => filter.field.id === fieldName && filter.type === 'IS_ONE_OF' && filter.negate !== false,
  );

  if (negateFilter) {
    const negateFilterCopy = plainToClass(ElasticsearchEnhancedFilterModel, negateFilter);

    negateFilterCopy.value = (negateFilterCopy.value as string[]).filter(val => val !== fieldValue);

    if (negateFilterCopy.value.length > 0) {
      newFilters.push(negateFilterCopy);
    }
  }

  if (regularFilterExists) {
    const updatedFilter = plainToClass(ElasticsearchEnhancedFilterModel, regularFilterExists);

    const valueExistsInFilter = (regularFilterExists.value as string[]).some(val => val === fieldValue);

    if (valueExistsInFilter) {
      updatedFilter.value = (updatedFilter.value as string[]).filter(val => val !== fieldValue);

      if (updatedFilter.value.length > 0) {
        newFilters.push(updatedFilter);
      }
    } else {
      updatedFilter.value = [
        ...(updatedFilter.value as string[]),
        ...(Array.isArray(fieldValue) ? fieldValue : [fieldValue]),
      ];

      newFilters.push(updatedFilter);
    }
  } else {
    newFilters.push(...models);
  }

  filtersStateService.updateFilters(newFilters, fieldName, Array.isArray(fieldValue) ? undefined : 'IS_ONE_OF');
};

const excludeValue = (fieldName: string, fieldValue: string | string[]) => {
  const currentFilters = filtersStateService.state.get();

  const negateFilter = currentFilters.find(
    filter => filter.field.id === fieldName && filter.negate === true && filter.type === 'IS_ONE_OF',
  );

  let models: ElasticsearchEnhancedFilterModel[] = [];

  if (Array.isArray(fieldValue)) {
    models = fieldValue.map(val => {
      return plainToClass(ElasticsearchEnhancedFilterModel, {
        field: classToPlain(fieldMappingsService.getField(fieldName)),
        type: 'IS',
        negate: true,
        value: val,
      } as ElasticsearchEnhancedFilterModel);
    });
  } else {
    models.push(
      plainToClass(ElasticsearchEnhancedFilterModel, {
        field: fieldMappingsService.getField(fieldName),
        type: 'IS_ONE_OF',
        negate: true,
        value: [fieldValue],
      } as ElasticsearchEnhancedFilterModel),
    );
  }

  const newFilters = [];
  const [regularFilter] = currentFilters.filter(
    filter => filter.field.id === fieldName && filter.type === 'IS_ONE_OF' && filter.negate !== true,
  );

  if (regularFilter) {
    const regularFilterCopy = plainToClass(ElasticsearchEnhancedFilterModel, regularFilter);

    regularFilterCopy.value = (regularFilterCopy.value as string[]).filter(val => val !== fieldValue);

    if (regularFilterCopy.value.length > 0) {
      newFilters.push(regularFilterCopy);
    }
  }

  if (negateFilter) {
    const negateFilterCopy = plainToClass(ElasticsearchEnhancedFilterModel, negateFilter);
    const valueAlreadyExists = (negateFilter.value as string[]).some(val => val === fieldValue);

    if (valueAlreadyExists) {
      negateFilterCopy.value = (negateFilterCopy.value as string[]).filter(val => val !== fieldValue);
    } else {
      negateFilterCopy.value = [
        ...(negateFilterCopy.value as string[]),
        ...(Array.isArray(fieldValue) ? fieldValue : [fieldValue]),
      ];
    }

    if (negateFilterCopy.value.length > 0) {
      newFilters.push(negateFilterCopy);
    }
  } else {
    newFilters.push(...models);
  }

  filtersStateService.updateFilters(newFilters, fieldName, Array.isArray(fieldValue) ? undefined : 'IS_ONE_OF');
};

const filterForFieldExistence = ({ fieldName, negate = false }: { fieldName: string; negate?: boolean }) => {
  const currentFilters = filtersStateService.state.get();

  const isExist = filterExists({ currentFilters, id: fieldName, type: 'EXIST', negate });

  const model = plainToClass(ElasticsearchEnhancedFilterModel, {
    field: classToPlain(fieldMappingsService.getField(fieldName)),
    type: 'EXIST',
    negate,
    value: null,
  } as ElasticsearchEnhancedFilterModel);

  if (isExist) {
    filtersStateService.updateFilters([], fieldName, 'EXIST');
  } else {
    filtersStateService.updateFilters([model], fieldName, 'EXIST');
  }
};

const isExploreDefaultLogViewer = async () => {
  const { exploreAsDefault } = await exploreOnboardingStatesService.get();
  const isExploreAsDefaultFeatureFlagEnabled = featureFlagStateService.isFeatureEnabled('ExploreAsDefault');

  const isSecurityAccount = sessionStateService.session.get().loggedInAccount.type === 'SECURITY_ACCOUNT';

  return exploreAsDefault && isExploreAsDefaultFeatureFlagEnabled && !isSecurityAccount;
};

const buildSavedSearchParams = (savedSearch: SavedObjectModel, commonParams: URLParams = {}) => {
  const savedSearchParams = extractParamsFromQuery(savedSearch.url);

  return merge({}, savedSearchParams, commonParams, { savedSearchId: savedSearch.id });
};

const getFixedDate = (value: string | number | Date): Moment => {
  const numberRegex = /^\d+$/; // This is a regex pattern that matches a string that contains only digits

  const parsedValue = typeof value === 'string' && numberRegex.test(value) ? parseInt(value, 10) : value;

  return moment(parsedValue);
};

export const exploreUtils = {
  groupBetweenFilters,
  reduceFiltersToFieldFilters,
  combineFilters,
  filterExists,
  getQueryUniqueId,
  removeFiltersById,
  addValueToField,
  removeValueFromField,
  buildElasticModel,
  isTimeRangeAbsolute,
  generateCacheKeyFromQuery,
  extractFieldsFromSortArr,
  handleSurroundingDocs,
  formatLogId,
  copyLogUrl,
  copyUrl,
  extractParamsFromQuery,
  filterInOutValues,
  excludeValue,
  filterForFieldExistence,
  isExploreDefaultLogViewer,
  buildSavedSearchParams,
  getFixedDate,
};
