// To hold the singleton import so we won't import multiple times if we have 2+ highcharts components in the page
let highcharts$;

const loadHighcharts = async () => {
  if (highcharts$) return highcharts$;

  const Highcharts = await import('highcharts');

  Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      },
    },
    lang: {
      thousandsSep: ',',
    },
    global: {},
  });

  Highcharts.AST.allowedAttributes.push('viewBox');

  highcharts$ = Highcharts;

  return Highcharts;
};

const lazyLoadHighcharts = async (): Promise<typeof import('highcharts')> => {
  if ((window as any).Highcharts) return (window as any).Highcharts;

  if (!highcharts$) {
    // Call it through the service instance to make the tests work
    highcharts$ = highchartsLazyLoadService.loadHighcharts();
  }

  const highcharts = await highcharts$;

  (window as any).Highcharts = highcharts; // TODO: remove angular

  return highcharts;
};

export const highchartsLazyLoadService = {
  lazyLoadHighcharts,
  loadHighcharts,
};
