/* eslint-disable no-param-reassign */
import { sessionStateService } from '@logz-pkg/frontend-services';
import { Crumb } from '@logz-ui/styleguide';
import { StateDeclaration, UIRouterReact } from '@uirouter/react';
import { isNil } from 'lodash';
import { AppStateDeclaration } from 'states/AppStateDecleration';
import { breadcrumbsService, RouteStateParams } from 'ui/state/breadcrumbs.state.service';
import { landingPageAppStateService } from 'ui/state/landing-page/landing-page.app-state.service';

const maxNumberOfBreadcrumbs = 4;

type UpdateBreadcrumbsProps = {
  state: StateDeclaration;
  params: { [paramName: string]: any };
};

export const registerBreadcrumbTransitions = (appRouter: UIRouterReact) => {
  const getParentBreadcrumbs = async (
    currentCrumb: AppStateDeclaration['data']['breadcrumb'],
    routeStateParams: RouteStateParams,
  ): Promise<Crumb[]> => {
    let currentParent = currentCrumb.parent;

    const allStates = appRouter.stateRegistry.get();
    const breadcrumbPromises: Promise<Crumb>[] = [];

    for (let i = 0; i < maxNumberOfBreadcrumbs; i += 1) {
      if (isNil(currentParent) || currentCrumb.label === currentParent) break;

      const parentStateName = currentParent; // Store current parent before changing it
      const parentState = allStates.find(({ name }) => name === parentStateName);
      const parentStateCrumb = parentState?.data?.breadcrumb as AppStateDeclaration['data']['breadcrumb'];

      if (isNil(parentState?.name) || isNil(parentStateCrumb)) break;

      // Push promises instead of awaiting directly in loop
      breadcrumbPromises.push(
        (async () => {
          const label =
            (await parentStateCrumb.calculateLabel?.(routeStateParams?.[parentState.name])) ?? parentStateCrumb.label;

          return {
            label,
            icon: parentStateCrumb.icon,
            href: appRouter.stateService.href(parentState.name, routeStateParams?.[parentState.name]),
          };
        })(),
      );

      if (parentStateName === parentStateCrumb.parent) break;

      currentParent = parentStateCrumb.parent;
    }

    // Wait for all breadcrumbs to resolve in parallel
    const parentCrumbs = await Promise.all(breadcrumbPromises);

    return parentCrumbs;
  };

  const getLandingPageBreadcrumb = async (
    lastCrumbLabel: Crumb['label'],
    routeStateParams: RouteStateParams,
  ): Promise<Crumb | undefined> => {
    const user = sessionStateService.session.get().loggedInUser;
    const allStates = appRouter.stateRegistry.get();

    if (!user) return undefined;

    // get landing page
    const landingPage = await landingPageAppStateService.get();
    const landingPageState = allStates.find(state => state.name === landingPage?.state?.name) as AppStateDeclaration;

    // check last breadcrumb is the landing page, otherwise, add it
    if (!landingPageState?.name || lastCrumbLabel === landingPageState.data?.breadcrumb?.label) return undefined;

    return {
      icon: 'house-solid',
      href: appRouter.stateService.href(landingPageState.name, routeStateParams?.[landingPageState.name]),
    };
  };

  const updateBreadcrumbs = async ({ state, params }: UpdateBreadcrumbsProps) => {
    const currentCrumbDeclaration = state?.data?.breadcrumb as AppStateDeclaration['data']['breadcrumb'];

    if (!state?.name || !currentCrumbDeclaration) return;

    const crumbs: Crumb[] = [];
    const routeStateParams = await breadcrumbsService.getStateParams();

    const label = (await currentCrumbDeclaration.calculateLabel?.(params)) ?? currentCrumbDeclaration.label;

    crumbs.unshift({
      label,
      icon: currentCrumbDeclaration.icon,
      href: appRouter.stateService.href(state.name),
    });

    const parentCrumbs = await getParentBreadcrumbs(currentCrumbDeclaration, routeStateParams);

    crumbs.unshift(...parentCrumbs.reverse());

    const landingPageBreadcrumb = await getLandingPageBreadcrumb(crumbs[0].label, routeStateParams);

    if (landingPageBreadcrumb) {
      crumbs.unshift(landingPageBreadcrumb);
    }

    // set the breadcrumbs state
    breadcrumbsService.set(crumbs);
  };

  appRouter.transitionService.onStart({}, transition => {
    // Get transition origin
    const params = transition.params('from');
    const state = transition.from().name;

    // If it has a name (usually there are more redirects and no name transitions)
    if (state) {
      breadcrumbsService.setStateParams({ state, params });
    }
  });

  appRouter.transitionService.onSuccess({}, transition => {
    const currentStateParams = transition.params('to');
    const currentState = transition.to();

    updateBreadcrumbs({ state: currentState, params: currentStateParams });
  });

  appRouter.transitionService.onExit({}, () => {
    breadcrumbsService.setCustomBreadcrumb(null);
  });
};
