import { AppStateDeclaration } from 'states/AppStateDecleration';
import { LazyReact } from 'ui/components/shared/LazyReact';

export const cloudMarketplaceRouteNames = {
  marketplace: 'cloud-marketplace',
};

const LazyMarketplaceComponent = props => (
  <LazyReact
    lazyImport={() => import('ui/components/Auth/AwsAccountConnect/AwsAccountConnect')}
    resolver={({ AwsAccountConnect }) => AwsAccountConnect}
    {...props}
  />
);

export const cloudMarketplaceRoutes: AppStateDeclaration[] = [
  {
    name: cloudMarketplaceRouteNames.marketplace,
    url: '/marketplace?marketplaceToken',
    component: LazyMarketplaceComponent,
    data: {
      breadcrumb: { label: 'Cloud Marketplace' },
      title: 'Cloud Marketplace',
      onlyAnonymous: true,
    },
  },
];
