import { UIView } from '@uirouter/react';
import { securitySummaryRouteName } from './security/security.routes.names';
import { AppStateDeclaration } from './AppStateDecleration';

export const securityRoute: AppStateDeclaration = {
  name: 'dashboard.security',
  url: '/security',
  data: { authenticate: true, breadcrumb: { label: 'Security' } },
  component: UIView,
  redirectTo: securitySummaryRouteName,
};
