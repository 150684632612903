import { AiChatRoles, ChatResponse, RecordType, SearchChatRecord } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { aiApiService, serverStreamApiService, sessionIdService } from '@logz-pkg/frontend-services';
import { AIDataSource, IntegrationsIQ } from '@logz-pkg/enums';
import { abortControllerService } from '@logz-pkg/utils';
import { serverStreamUtils } from '../../../../../utils/server-stream';
import { aiHistoryService } from '../history/history.service';
import { aiStateService } from '../ai-state.service';
import { aiUtils } from '../../utils';
import { aiDataSourceService } from '../data-source/data-source.service';
import { abortTheRequest, handleResponse } from './utils';

class AiFetchDataService {
  readonly isLoading = new Observable<boolean>(false);
  private urls = {
    aiSearchSse: '/app-ai/services/bedrock-ai-sse',
    aiAgent: '/app-ai/services/bedrock-ai-agent',
  };
  private aiSearchAbortKey: string;
  private integrationsAiAbortKey: string;

  constructor() {
    this.aiSearchAbortKey = abortControllerService.createUniqueKey('aiSearch');
    this.integrationsAiAbortKey = abortControllerService.createUniqueKey('integrationsAi');
  }

  setLoading = (isLoading: boolean): void => {
    this.isLoading.set(isLoading);
  };
  agent = async ({
    prompt,
    context,
    agentType,
    componentType,
    sessionId,
  }: {
    prompt: string;
    context: unknown;
    agentType: AIDataSource;
    componentType?: RecordType;
    sessionId: string;
  }) => {
    try {
      const additionalData = {
        sessionId,
        tabSessionId: sessionIdService.getSessionId(),
        message: {
          role: AiChatRoles.User,
          userInput: prompt,
          ...(aiStateService.shouldSendContent.get() ? { content: context } : {}),
        },
        agentType,
      };

      this.setLoading(true);

      aiHistoryService.agentSetLoading({ prompt, context, componentType, dataSource: agentType });

      await new Promise<void>(async (resolve, reject) => {
        try {
          await serverStreamUtils.startPolling({
            handleResponse: ({ response, reqIndex, shouldStart }) =>
              handleResponse({
                additionalData,
                response,
                streamSseUrl: this.urls.aiSearchSse,
                reqIndex,
                shouldStart,
                onData: aiHistoryService.agentOnData,
                onError: error => {
                  aiUtils.loggerError('Agent failed (onError)', {
                    error,
                    sessionId,
                    dataSource: agentType,
                    prompt,
                    context,
                    componentType,
                  });
                  reject(error);
                },
                onEnd: (res?: ChatResponse) => {
                  if (res) aiHistoryService.agentOnDone(res);

                  resolve();
                },
              }),
            streamUrl: this.urls.aiAgent,
            streamSseUrl: this.urls.aiSearchSse,
            additionalData,
          });
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      aiHistoryService.agentOnError({ status: 'error', message: error.message });
      aiUtils.loggerError('Agent failed (catchError)', {
        error,
        sessionId,
        dataSource: agentType,
        prompt,
        context,
        componentType,
      });
    } finally {
      this.setLoading(false);
    }
  };
  assistant = async ({
    prompt,
    meta = [],
    context,
  }: {
    prompt: string;
    meta: SearchChatRecord[];
    context?: unknown;
  }) => {
    try {
      this.setLoading(true);

      const promptTokenSize = prompt.length / 4;

      if (promptTokenSize > 200) {
        aiHistoryService.assistantSetOutOfContext(prompt);
        throw new Error(`Your message is too long. Please shorten it and try again.`);
      }

      aiHistoryService.assistantSetLoading(prompt);

      const payload = aiHistoryService.assistantBuildReqContent({ meta, context });
      const abortSignal = abortTheRequest(this.aiSearchAbortKey);

      await serverStreamApiService.doSse({
        streamUrl: this.urls.aiSearchSse,
        payload,
        onData: aiHistoryService.assistantOnData,
        onDone: aiHistoryService.assistantOnDone,
        abortSignal,
      });
    } catch (error) {
      aiUtils.loggerError('Assistant failed (catchError)', {
        error,
        dataSource: aiDataSourceService.dataSource.get(),
        prompt,
        context,
        meta,
      });

      if (error.message === `Your message is too long. Please shorten it and try again.`) {
        return {
          status: error.status,
          content: error.message,
          type: 'Assistant',
          role: 'Ai',
        };
      }

      return {
        status: error.status,
        content: `Something went wrong, Please try again. If the problem persists, contact our support team for assistance.`,
        type: 'Assistant',
        role: 'Ai',
      };
    } finally {
      this.setLoading(false);
    }
  };
  knowledgeBase = async ({
    prompt,
    dataSource,
    sessionId,
  }: {
    prompt: string;
    dataSource: AIDataSource;
    sessionId: string;
  }) => {
    try {
      this.setLoading(true);

      const promptTokenSize = prompt.length / 4;

      if (promptTokenSize > 200) {
        throw new Error(IntegrationsIQ.UserPromptTooLong);
      }

      const isFirstRequest = aiHistoryService.knowlageBaseIsFirstRequset();

      aiHistoryService.assistantSetLoading(prompt);

      const abortSignal = abortTheRequest(this.integrationsAiAbortKey);

      const response = await aiApiService.integrationsAi(
        {
          dataSource: dataSource as 'shipping' | 'userGuide',
          text: prompt,
          sessionId: isFirstRequest ? undefined : sessionId,
        },
        abortSignal,
      );

      if (isFirstRequest) {
        aiStateService.setSessionId(response.sessionId);
      }

      aiHistoryService.assistantOnData({ status: 'data', message: response.text });
    } catch (error) {
      aiUtils.loggerError('Knowledge-Base failed (catchError)', {
        error,
        dataSource: aiDataSourceService.dataSource.get(),
        prompt,
        sessionId,
      });

      if (error.message === IntegrationsIQ.UserPromptTooLong) {
        return {
          content: `Your message is too long. Please shorten it and try again.`,
          type: 'Assistant',
          role: 'Ai',
        };
      }

      aiHistoryService.assistantOnData({
        status: 'error',
        message: `Something went wrong, Please try again. If the problem persists, contact our support team for assistance.`,
      });
    } finally {
      aiHistoryService.assistantOnDone();
      this.setLoading(false);
    }
  };
}

export const aiFetchDataService = new AiFetchDataService();
