/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { isNil } from 'lodash';
import { SearchHit } from '@opensearch-project/opensearch/api/types';
import { plainToFlattenObject } from '@logz-ui/styleguide';
import { queryTimeUtils } from 'ui/components/Explore/state/query/utils/time.utils';
import { queryBuilder } from 'ui/components/Explore/state/query/utils/query-builder';

const messageFirstSort = (field1: string, field2: string) => {
  if (field1 === 'message') {
    return -1;
  } else if (field2 === 'message') {
    return 1;
  }

  return 0;
};

const highlightFirstSort = (field1: string, field2: string, highlight: Record<string, string[]>) => {
  const isAHighlighted = highlight && highlight[field1];
  const isBHighlighted = highlight && highlight[field2];

  // Highlighted fields come first and are sorted alphabetically
  if (isAHighlighted && !isBHighlighted) return -1;

  if (!isAHighlighted && isBHighlighted) return 1;

  if (isAHighlighted && isBHighlighted) {
    return field1.localeCompare(field2);
  }

  return messageFirstSort(field1, field2);
};

const sortJsonKeys = (
  json: object,
  customSort: (val1: string, val2: string, highlight?: Record<string, string[]>) => number,
  highlight?: Record<string, string[]>,
) => {
  if (typeof json !== 'object' || Array.isArray(json) || json === null) {
    throw new Error('Input is not a valid JSON object');
  }

  const keys = Object.keys(json);

  const sortedKeys = keys.sort((a, b) => customSort(a, b, highlight));

  const sortedJson = {};

  sortedKeys.forEach(key => {
    sortedJson[key] = json[key];
  });

  return sortedJson;
};

const mapHitsToLogs = (hits: SearchHit<any>[]): SearchHit<any>[] => {
  return hits.reduce<SearchHit<any>[]>((acc, { _source, highlight, ...rest }) => {
    if (isNil(_source) || typeof _source !== 'object') return acc;

    const flattenedSource = plainToFlattenObject(_source, true);
    const sortedSource = queryUtils.sortJsonKeys(flattenedSource, queryUtils.highlightFirstSort, highlight);

    acc.push({ ...rest, highlight, _source: sortedSource });

    return acc;
  }, []);
};

export const queryUtils = {
  sortJsonKeys,
  messageFirstSort,
  getDslFilters: queryBuilder.getDslFilters,
  otherValuesPayloadBuilder: queryBuilder.otherValuesPayloadBuilder,
  termHistogramPayload: queryBuilder.termHistogramPayload,
  highlightFirstSort,
  formatTimeRange: queryTimeUtils.formatTimeRange,
  getStartEnd: queryTimeUtils.getStartEnd,
  searchWithDateHistogramPayload: queryBuilder.searchWithDateHistogramPayload,
  mapHitsToLogs,
  buildLogsQuerySearchPayload: queryBuilder.buildLogsQuerySearchPayload,
};
