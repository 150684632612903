import { clone } from 'lodash';
import { LogzioFileSaver } from 'services/file-saver/file-saver.service';

const replaceLogTypeSectionPlaceholders = (logType, token, section) => {
  const fieldConditions =
    logType.fieldConditions && logType.fieldConditions.length > 0 && logType.fieldConditions[0].fieldName
      ? logType.fieldConditions.map(condition => `    ${condition.fieldName}: ${condition.fieldValue}`).join('\r\n')
      : '';
  const multiline = logType.multiline
    ? `\r\n  multiline:\r\n    pattern: '${logType.multilineRegex}'\r\n    negate: true\r\n    match: after`
    : '';
  const format = logType.format.toLowerCase();
  const logTypeToReplace = logType.logTypeOther || logType.logType;

  return clone(section)
    .replace('[@path]', logType.path)
    .replace('[@outputType]', format)
    .replace('[@logType]', `${logTypeToReplace.toLowerCase()}${fieldConditions ? '\r\n' : ''}`)
    .replace('[@token]', token)
    .replace('[@additionalFields]', fieldConditions)
    .replace('[@multiline]', multiline);
};

export const buildYamlFile = (template, section, values, token, listenerAddress) => {
  const logTypeSectionYaml = values.logTypeArr
    .map(logType => replaceLogTypeSectionPlaceholders(logType, token, section))
    .join('\r\n');

  const yamlConfiguration = template
    .replace('[@prospectors]', `\r\n${logTypeSectionYaml}`)
    .replace('[@logzListener]', listenerAddress);

  LogzioFileSaver('filebeat.yml', yamlConfiguration);
};
