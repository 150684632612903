import _, { get } from 'lodash';
import { cacheProvider, httpService } from '@logz-pkg/frontend-services';

const API_TOKENS_ENDPOINT = '/api-tokens';
const SHARED_TOKENS_ENDPOINT = '/shared-tokens';
const SHARED_TOKENS_FILTERS_ENDPOINT = `${SHARED_TOKENS_ENDPOINT}/filters`;
const ANALYTICS_CATEGORY_SHARED_TOKENS = 'shared-tokens';
const errorMessages = {
  delete:
    'Failed to delete token.\nThis might indicate that the token was deleted already.\n' +
    ' Please refresh and try again. If the problem persist please contact support',
  create: 'Failed to create token.\n' + 'Please refresh and try again. If the problem persist please contact support',
  'get-all-tokens':
    'Failed to get tokens data.\n' + 'Please refresh and try again. If the problem persist please contact support',
  'attach-filter':
    'Failed to attach filter to token.\n' +
    'Please refresh and try again. If the problem persist please contact support',
  'detach-filter':
    'Failed to detach filter from token.\n' +
    'Please refresh and try again. If the problem persist please contact support',
  'create-filter':
    'Failed to create filter.\n' + 'Please refresh and try again. If the problem persist please contact support',
  'delete-filter':
    'Failed to delete filter.\n' + 'Please refresh and try again. If the problem persist please contact support',
};

const getQueryFilters = () =>
  cacheProvider.get(cacheProvider.CacheKeys.SHARED_TOKENS_FILTERS, () =>
    httpService.get(SHARED_TOKENS_FILTERS_ENDPOINT).then(res => res.data),
  );

const getUserTokens = () =>
  cacheProvider.get(cacheProvider.CacheKeys.SHARED_TOKENS, () =>
    httpService.get(SHARED_TOKENS_ENDPOINT).then(({ data }) => _addFiltersDataToTokens(data)),
  );

const createSharedToken = tokenName =>
  httpService.post(SHARED_TOKENS_ENDPOINT, { tokenName }).then(response => response.data);

const updateSharedToken = (tokenId, filters) =>
  httpService.put(`${SHARED_TOKENS_ENDPOINT}/${tokenId}`, { filters }).then(response => response);

const deleteSharedToken = tokenId => httpService.delete(`${SHARED_TOKENS_ENDPOINT}/${tokenId}`);

const createFilter = filter => httpService.post(SHARED_TOKENS_FILTERS_ENDPOINT, filter).then(response => response);

const deleteFilter = filterId => httpService.delete(`${SHARED_TOKENS_FILTERS_ENDPOINT}/${filterId}`);

const getAllApiTokens = () => httpService.get(API_TOKENS_ENDPOINT).then(res => res.data);

const createApiToken = tokenName => httpService.post(API_TOKENS_ENDPOINT, { tokenName }).then(response => response.data);

const deleteApiToken = tokenId =>
  httpService.delete(`${API_TOKENS_ENDPOINT}/${tokenId}`).then(response => response.data);

const getIndexPrefix = () => {
  const cacheValue = cacheProvider.getCached(
    httpService.generateHttpResponseCacheKey(cacheProvider.CacheKeys.SESSION_CONTEXT),
  );

  if (!cacheValue) {
    throw new Error(
      `${cacheProvider.LOGZ_CACHE_PREFIX}.${httpService.generateHttpResponseCacheKey(
        cacheProvider.CacheKeys.SESSION_CONTEXT,
      )} not present in cache`,
    );
  }

  const indexPrefix = get(cacheValue, 'data.userSummary.accountEsIndexPrefix');

  if (!indexPrefix) {
    throw new Error(
      `Failed to get Index Prefix from ${httpService.generateHttpResponseCacheKey(
        cacheProvider.CacheKeys.SESSION_CONTEXT,
      )} in SessionStorage`,
    );
  }

  return indexPrefix;
};

const _addFiltersDataToTokens = userTokens =>
  getQueryFilters().then(queryFilters =>
    _.map(userTokens, userToken => {
      userToken.filters = userToken.filters || [];
      userToken.fetchedFilters = _.map(userToken.filters, filterId => _.find(queryFilters, { id: filterId }));

      return userToken;
    }),
  );

const clearCache = () => {
  cacheProvider.clear(cacheProvider.CacheKeys.SHARED_TOKENS);
  cacheProvider.clear(cacheProvider.CacheKeys.SHARED_TOKENS_FILTERS);
};

export const userTokensService = {
  API_TOKENS_ENDPOINT,
  SHARED_TOKENS_ENDPOINT,
  SHARED_TOKENS_FILTERS_ENDPOINT,
  ANALYTICS_CATEGORY_SHARED_TOKENS,
  errorMessages,
  getQueryFilters,
  getUserTokens,
  createSharedToken,
  updateSharedToken,
  deleteSharedToken,
  createFilter,
  deleteFilter,
  getAllApiTokens,
  createApiToken,
  deleteApiToken,
  getIndexPrefix,
  clearCache,
};
