import React from 'react';
import { Button, Icon, Group, Text } from '@logz-ui/styleguide';

export const AiConversationsErrorState: React.FC<{ onRefetch: (any) => Promise<unknown> }> = ({ onRefetch }) => {
  return (
    <Group vertical fullWidth justifyContent="center" alignItems="center" mt={10} gap={5}>
      <Icon icon={'clock-regular'} size={40} color={'royalBlue.700'} />
      <Text color={'royalBlue.600'}>Unable to load history</Text>
      <Button onClick={onRefetch}>Reload</Button>
    </Group>
  );
};
